import { all, call, put, SagaReturnType, takeLatest } from "redux-saga/effects";
import SnackbarActions from "../../components/Snackbar/actions";
import { axiosInstance, GetBaseURL } from "../../Utils/network/axios";
import { TRANSACTION_API } from "../../Utils/network/axios/urls";
import { TransactionActions } from "./actions";
import { TransactionActionTypes } from "./actionTypes";
import { IGetTransactionsRequest, IGetTransactionsResponse } from "./types";

export default function* TransactionWatcher() {
  yield all([takeLatest(TransactionActionTypes.GET_TRANSACTIONS_REQUEST, GetTransactionWorker)]);
}

function* GetTransactionWorker({ request }: ReturnType<typeof TransactionActions.GetTransactionsRequest>) {
  try {
    const response: GetTransactionHistoryServiceResponse = yield call(getTransaction, request);
    if (response) {
      yield put(TransactionActions.GetTransactionsSuccess(response as IGetTransactionsResponse));
    } else {
      yield put(TransactionActions.GetTransactionsError());
      yield put(SnackbarActions.ShowError("Could not retrieve transaction history, please try again later."));
    }
  } catch (error) {
    yield put(TransactionActions.GetTransactionsError());
    yield put(SnackbarActions.ShowError("Could not retrieve transaction history, please try again later."));
  }
}

// API:S
export const getTransaction: GetTransactionHistoryService = async (request: IGetTransactionsRequest) => {
  axiosInstance.defaults.baseURL = GetBaseURL() + TRANSACTION_API;
  return await axiosInstance
    .get("", { params: { orderId: request.orderId, resellerContactId: request.resellerContactId, adminUserId: request.adminUserId } })
    .then((x) => x.data);
};

// SERVICES
export interface GetTransactionHistoryService {
  (request: IGetTransactionsRequest): Promise<IGetTransactionsResponse>;
}
// RESPONSE
export type GetTransactionHistoryServiceResponse = SagaReturnType<typeof getTransaction>;
