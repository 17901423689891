import { Button, ButtonGroup, Grid, Typography, useMediaQuery } from "@mui/material";
import React, { useEffect } from "react";
import { LabelKeys } from "../../Utils/enums/labelKeys";
import { StateNames, FieldNames, GetCurrentLanguageAsText } from "../../Utils/types";
import { SendBy, WhenToSend } from "../Delivery/types";
import { SystemPart } from "../../Utils/enums/enums";
import { IProduct } from "../../Modules/Products/types";
import { useDispatch, useSelector } from "react-redux";
import { ValidateDeliveryPhoneNumbers, ValidateDeliveryEmails, GetLitiumImageUrl } from "../../Utils/extensions";
import SendByEmail from "../Delivery/SubComponents/SendByEmail";
import SendByEmailPDF from "../Delivery/SubComponents/SendByEmailPDF";
import SendBySMS from "../Delivery/SubComponents/SendBySMS";
import CSFileUploader from "../FileUploader/CSFileUploader";
import CSDateTimePicker from "../Input/DateTimePicker/CSDateTimePicker";
import SnackbarActions from "../Snackbar/actions";
import * as XLSX from "xlsx";
import { theme } from "../../appTheme";
import { StepperFiveStyles } from "./styles";
import { useNavigate } from "react-router-dom";
import { RootState } from "../../store";
import { ImageKeys } from "../../Utils/enums/imageKeys";
import { useTranslation } from "react-i18next";
import { IsResellerAuth, IsAuth } from "../../Utils/authentication";
import { QueryParamKeys } from "../../Utils/enums/queryParams";
import SendByPhysicalCard from "../Delivery/SubComponents/SendByPhysicalCard";

interface ICSStepperStepFiveProps {
  id: string;
  receiverEmails: string;
  receiverPhoneNumbers: string;
  orderDate: Date;
  includeFreight: boolean;
  whenToSend: WhenToSend;
  sendToSameReceiver: boolean;
  sendBy: SendBy;
  systemPart: SystemPart;
  customMessage: boolean;
  amountOfMailings: number;
  frieghtProduct: IProduct;
  onStateChanged: (
    stateName: StateNames.stepperFiveState,
    value: string | Date | boolean | WhenToSend | string[],
    fieldName: FieldNames
  ) => void;
  name?: string;
  addressLine?: string;
  postalCode?: string;
  city?: string;
  country?: number;
  deliveryCustomerContactName?: string;
  receiverPhoneNumberHasError?: boolean;
  receiverEmailsHasError?: boolean;
  receiverEmailsPDFHasError?: boolean;
  receiverEmailsPDFSendToSameReceiverHasError?: boolean;
  amountOfMailingsHasError?: boolean;
  addressLineHasError?: boolean;
  postalCodeHasError?: boolean;
  cityHasError?: boolean;
  countryHasError?: boolean;
  nameHasError?: boolean;
}

export default function CSStepperStepFive(props: ICSStepperStepFiveProps) {
  const navigate = useNavigate();
  const { t: translate } = useTranslation();
  const classes = StepperFiveStyles();
  const dispatch = useDispatch();
  const isMobile = useMediaQuery(theme.breakpoints.only("xs"));
  const isAdmin =
    (IsResellerAuth() &&
      location.pathname.includes(
        `/${translate(LabelKeys.ResellerUrl)}/${translate(LabelKeys.AdminUrl)}/${translate(LabelKeys.OrderUrl)}`
      )) ||
    (IsAuth() && location.pathname.includes(`/${translate(LabelKeys.AdminUrl)}/${translate(LabelKeys.OrderUrl)}`));
  const reducerState = useSelector(
    (s: RootState) => {
      return {
        order: s.order,
      };
    },
    (prev, next) => prev.order.environmentState.systemPart === next.order.environmentState.systemPart
  );

  useEffect(() => {
    window.scroll(0, 0);
    if (reducerState.order.environmentState.systemPart === undefined && !isAdmin) {
      navigate(`/${GetCurrentLanguageAsText()}/`);
    }
  }, []);

  function GetMaxDate(): Date {
    let maxDate = new Date();
    maxDate.setDate(maxDate.getDate() + 175);
    return maxDate;
  }

  function handleSubComponentsChanged(
    stateName: StateNames.stepperFiveState,
    value: string | string[] | Date | number | boolean,
    fieldName: FieldNames
  ) {
    handleFieldOnChange(stateName, value, fieldName);
  }

  function handleFieldOnChange(
    stateName: StateNames.stepperFiveState,
    value: string | Date | boolean | WhenToSend | string[],
    fieldName: FieldNames
  ) {
    props.onStateChanged(stateName, value, fieldName);
  }

  interface IImportedReceiverData {
    data: string;
    multipleReceiversMode: boolean;
  }

  function ProcessReceiverData(dataString: string): IImportedReceiverData {
    const dataStringLines = dataString.split(/\r\n|\n/);
    let valid = true;

    let newDataStringLines = dataStringLines.filter((x) => x.length > 0 && x !== "," && x !== "\n");

    let returnValue: IImportedReceiverData = {
      multipleReceiversMode: newDataStringLines.length > 1,
      data: "",
    } as IImportedReceiverData;

    //SendBy.SMS
    if (props.sendBy == SendBy.SMS) {
      newDataStringLines.forEach((element, index) => {
        let splitedElement = element.split(",");
        if (!ValidateDeliveryPhoneNumbers(splitedElement[0])) {
          dispatch(SnackbarActions.ShowError(translate(LabelKeys.InvalidReceiverPhoneNumbers)));
          valid = false;
        }
        if (index === newDataStringLines.length - 1) {
          returnValue.data = returnValue?.data?.concat(element);
        } else {
          returnValue.data = returnValue?.data?.concat(element + "\n");
        }
      });
    } else {
      //SendBy.Email / SendBy.EmaildDF
      newDataStringLines.forEach((element, index) => {
        let splitedElement = element.split(",");
        if (!ValidateDeliveryEmails(splitedElement[0])) {
          dispatch(SnackbarActions.ShowError(translate(LabelKeys.InvalidReceiverEmailAddresses)));
          valid = false;
        }
        if (index === newDataStringLines.length - 1) {
          returnValue.data = returnValue?.data?.concat(element);
        } else {
          returnValue.data = returnValue?.data?.concat(element + "\n");
        }
      });
    }

    if (!valid) return {} as IImportedReceiverData;
    return returnValue;
  }

  async function handleReceiverFileUploaded(e: React.ChangeEvent<HTMLInputElement>) {
    let dataObject = await GetFileContent(e);
    if (dataObject) {
      handleFieldOnChange(
        StateNames.stepperFiveState,
        dataObject.data,
        props.sendBy == SendBy.SMS ? FieldNames.receiverPhoneNumbers : FieldNames.receiverEmails
      );
    } else {
      dispatch(SnackbarActions.ShowError(translate(LabelKeys.InvalidFileImport)));
    }
  }

  function GetFileContent(e: React.ChangeEvent<HTMLInputElement>): Promise<IImportedReceiverData> {
    const file = e.target.files[0];
    if (!file) return;

    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        const bstr = event.target.result;
        if (!bstr || bstr === "" || bstr === undefined) reject;
        const workbook = XLSX.read(bstr, { type: "binary" });
        const worksheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[worksheetName];
        const data: string = XLSX.utils.sheet_to_csv(worksheet, {});
        const dataObject = ProcessReceiverData(data);
        if (!dataObject || dataObject?.data === undefined || dataObject?.data === "") reject;
        else {
          resolve(dataObject);
        }
      };
      reader.onerror = reject;
      reader.readAsBinaryString(file);
    });
  }

  return (
    <Grid
      container
      item
      xs={12}
      sm={12}
      md={12}
      lg={12}
      xl={12}
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start",
        alignItems: "flex-start",
      }}
    >
      <Grid id="leftContent" container item xs={12} sm={6} md={6} lg={6} xl={6}>
        <Grid id="title" item xs={12} sm={11} md={11} lg={11} xl={11}>
          <Typography variant="h1" align="left">
            {props.sendBy != SendBy.PhysicalCard ? translate(LabelKeys.StepFiveTitle) : translate(LabelKeys.StepFiveTitlePhysicalCard)}
          </Typography>
        </Grid>
        <Grid id="description" item xs={12} sm={11} md={11} lg={11} xl={11} style={{ paddingTop: 20 }}>
          <Typography variant="body1" align="left">
            {props.systemPart == SystemPart.Private && !isAdmin
              ? translate(LabelKeys.StepFiveDescriptionPrivate)
              : props.sendBy == SendBy.PhysicalCard
              ? translate(LabelKeys.StepFiveDescriptionPhysicalCard)
              : translate(LabelKeys.StepFiveDescriptionCompany)}
          </Typography>
        </Grid>
        {props.sendBy != SendBy.PhysicalCard && (
          <Grid container item xs={12} sm={11} md={11} lg={11} xl={11} style={{ marginTop: 30 }}>
            <Grid
              container
              item
              xs={12}
              sm={11}
              md={11}
              lg={11}
              xl={11}
              style={{
                display: "flex",
                paddingTop: 20,
                width: "100%",
              }}
            >
              <ButtonGroup variant="outlined" style={{ width: "100%" }}>
                <Button
                  id="whenToSendNow"
                  startIcon={
                    <svg width="16" height="16" fill="currentColor" className="bi bi-clock" viewBox="0 0 16 16">
                      <path d="M8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71V3.5z" />
                      <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm7-8A7 7 0 1 1 1 8a7 7 0 0 1 14 0z" />
                    </svg>
                  }
                  className={classes.whenToSendBtn}
                  style={
                    props.whenToSend === WhenToSend.Now
                      ? {
                          color: "#fff",
                          height: isMobile ? 74 : 44,
                          width: isMobile ? "100%" : 114,
                          borderColor: "#000",
                          backgroundColor: "#000",
                          cursor: "pointer",
                          textTransform: "unset",
                        }
                      : {
                          color: "#000",
                          backgroundColor: "#fff",
                          cursor: "pointer",
                          width: isMobile ? "100%" : 114,
                          height: isMobile ? 74 : 44,
                          textTransform: "unset",
                        }
                  }
                  onClick={() => {
                    handleFieldOnChange(StateNames.stepperFiveState, WhenToSend.Now, FieldNames.whenToSend);
                  }}
                >
                  {translate(LabelKeys.Now)}
                </Button>
                <Button
                  id="whenToSendSpecificTime"
                  startIcon={
                    <svg width="16" height="16" fill="currentColor" className="bi bi-calendar3" viewBox="0 0 16 16">
                      <path d="M14 0H2a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zM1 3.857C1 3.384 1.448 3 2 3h12c.552 0 1 .384 1 .857v10.286c0 .473-.448.857-1 .857H2c-.552 0-1-.384-1-.857V3.857z" />
                      <path d="M6.5 7a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm-9 3a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm-9 3a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2z" />
                    </svg>
                  }
                  style={
                    props.whenToSend === WhenToSend.SpecificTime
                      ? {
                          color: "#fff",
                          borderColor: "#000",
                          height: isMobile ? 74 : 44,
                          width: isMobile ? "100%" : 151,
                          backgroundColor: "#000",
                          cursor: "pointer",
                          textTransform: "unset",
                        }
                      : {
                          color: "#000",
                          height: isMobile ? 74 : 44,
                          width: isMobile ? "100%" : 151,
                          backgroundColor: "#fff",
                          cursor: "pointer",
                          textTransform: "unset",
                        }
                  }
                  onClick={() => {
                    handleFieldOnChange(StateNames.stepperFiveState, WhenToSend.SpecificTime, FieldNames.whenToSend);
                  }}
                >
                  {translate(LabelKeys.SpecificTime)}
                </Button>
              </ButtonGroup>
            </Grid>
            {props.whenToSend === WhenToSend.SpecificTime && (
              <Grid item xs={12} sm={11} md={11} lg={11} xl={11} style={{ paddingTop: 20 }}>
                <CSDateTimePicker
                  id="orderDate"
                  startIcon={
                    <svg width="16" height="16" fill="currentColor" className="bi bi-calendar3" viewBox="0 0 16 16">
                      <path d="M14 0H2a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zM1 3.857C1 3.384 1.448 3 2 3h12c.552 0 1 .384 1 .857v10.286c0 .473-.448.857-1 .857H2c-.552 0-1-.384-1-.857V3.857z" />
                      <path d="M6.5 7a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm-9 3a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm-9 3a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2z" />
                    </svg>
                  }
                  fullWidth
                  value={props.orderDate}
                  maxDate={GetMaxDate()}
                  onChange={(date) => {
                    handleFieldOnChange(StateNames.stepperFiveState, date, FieldNames.orderDate);
                  }}
                  okLabel={translate(LabelKeys.Ok)}
                  cancelLabel={translate(LabelKeys.Cancel)}
                  disablePast
                  helperText={translate(LabelKeys.InvalidDateTimeFormat)}
                />
              </Grid>
            )}
          </Grid>
        )}
        <Grid container item xs={12} sm={12} md={12} lg={12} xl={12} style={{ paddingTop: 40 }}>
          {props.sendBy == SendBy.SMS && (
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <SendBySMS
                systemPart={props.systemPart}
                receiverPhoneNumbers={props.receiverPhoneNumbers}
                receiverPhoneNumberHasError={props.receiverPhoneNumberHasError}
                onStateChanged={(stateName, value, field) => {
                  handleSubComponentsChanged(stateName, value, field);
                }}
              />
            </Grid>
          )}
          {props.sendBy == SendBy.Email && (
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <SendByEmail
                systemPart={props.systemPart}
                receiverEmails={props.receiverEmails}
                receiverEmailsHasError={props.receiverEmailsHasError}
                onStateChanged={(stateName, value, field) => handleSubComponentsChanged(stateName, value, field)}
              />
            </Grid>
          )}
          {props.sendBy == SendBy.EmailPDF && (
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <SendByEmailPDF
                systemPart={props.systemPart}
                amountOfMailings={props.amountOfMailings}
                sendToSameReceiver={props.sendToSameReceiver}
                receiverEmails={props.receiverEmails}
                amountOfMailingsHasError={props.amountOfMailingsHasError}
                receiverEmailPDFsHasError={props.receiverEmailsPDFHasError}
                receiverEmailsPDFSendToSameReceiverHasError={props.receiverEmailsPDFSendToSameReceiverHasError}
                onStateChanged={(stateName, value, field) => handleSubComponentsChanged(stateName, value, field)}
              />
            </Grid>
          )}
          {props.sendBy == SendBy.PhysicalCard && (
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <SendByPhysicalCard
                systemPart={props.systemPart}
                amountOfMailings={props.amountOfMailings}
                addressLine={props.addressLine}
                postalCode={props.postalCode}
                city={props.city}
                name={props.name}
                country={props.country}
                deliveryCustomerContactName={props.deliveryCustomerContactName}
                amountOfMailingsHasError={props.amountOfMailingsHasError}
                onStateChanged={(stateName, value, field) => handleSubComponentsChanged(stateName, value, field)}
                addressLineHasError={props.addressLineHasError}
                postalCodeHasError={props.postalCodeHasError}
                cityHasError={props.cityHasError}
                countryHasError={props.countryHasError}
                nameHasError={props.nameHasError}
              />
            </Grid>
          )}
        </Grid>
        {!props.sendToSameReceiver &&
          props.customMessage &&
          props.sendBy != SendBy.PhysicalCard &&
          (props.systemPart != SystemPart.Private || isAdmin) && (
            <React.Fragment>
              <Grid item xs={12} sm={11} md={11} lg={11} xl={11} style={{ paddingTop: 10 }}>
                <div style={{ float: "left", paddingBottom: 10, width: "100%" }}>
                  <CSFileUploader
                    fileTypes="application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                    isImageUploaded={false}
                    isCSVFile={true}
                    btnHeight={60}
                    btnWidth={"100%"}
                    btnTitle={translate(LabelKeys.UploadReceivers)}
                    onChange={(e) => handleReceiverFileUploaded(e as React.ChangeEvent<HTMLInputElement>)}
                  />
                </div>
              </Grid>
              <Grid item xs={12} sm={10} md={10} lg={10} xl={10}>
                <Typography
                  variant="body2"
                  style={{
                    textAlign: "center",
                    whiteSpace: "pre-line",
                  }}
                >
                  {translate(LabelKeys.AllowedReceiverTypes)}
                </Typography>
              </Grid>
            </React.Fragment>
          )}
      </Grid>
      {!isMobile && (
        <Grid id="rightContent" container item xs={6} sm={6} md={6} lg={6} xl={6} justifyContent="right">
          <img
            style={{ objectFit: "cover", maxHeight: 600, maxWidth: "100%" }}
            src={GetLitiumImageUrl(ImageKeys.ReceiverPageDesktop, `${QueryParamKeys.MaxWidth}600`)}
          />
        </Grid>
      )}
    </Grid>
  );
}
