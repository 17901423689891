import React, { useState } from "react";
import Grid from "@mui/material/Grid/Grid";
import { useTranslation } from "react-i18next";
import CSTextField from "../../../../../components/Input/Textfield/CSTextField";
import { LabelKeys } from "../../../../../Utils/enums/labelKeys";
import { FieldNames } from "../../../../../Utils/types";
import Button from "@mui/material/Button/Button";
import { useMediaQuery } from "@mui/material";
import { theme } from "../../../../../appTheme";
import { IRefundOrderRequest } from "../../../../../Modules/Orders/types";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../store";
import SnackbarActions from "../../../../../components/Snackbar/actions";
import { PaymentMethod } from "../../../../../Utils/enums/enums";

interface IAddReseller {
  onSave: (refundData: IRefundOrderRequest) => void;
  onClose: () => void;
}

interface IRefundOrderState {
  orderId: number;
  amount: number;
}
interface IRefundOrderErrorState {
  orderId: boolean;
  amount: boolean;
}

export default function RefundOrder(props: IAddReseller) {
  const { t: translate } = useTranslation();
  const dispatch = useDispatch();
  const reducerState = useSelector(
    (s: RootState) => {
      return {
        order: s.order,
      };
    },
    (prev, next) => prev.order.isLoading === next.order.isLoading && prev.order.adminSelectedOrder === next.order.adminSelectedOrder
  );

  const [refundOrderState, setRefundOrderState] = useState({
    amount: reducerState.order.adminSelectedOrder?.totalOrderValue,
    orderId: reducerState.order.adminSelectedOrder?.id,
  } as IRefundOrderState);
  const [refundOrderErrorState, setRefundOrderErrorState] = useState({
    amount: false,
    orderId: false,
  } as IRefundOrderErrorState);
  const isMobile = useMediaQuery(theme.breakpoints.only("xs"));

  function handleRefundOrder() {
    const valid = ValidateAllFields();

    if (valid) {
      props.onSave({
        amount: refundOrderState.amount,
        orderId: refundOrderState.orderId,
      } as IRefundOrderRequest);
    }
  }

  function handleFieldOnChange(fieldName: FieldNames, value: string | Date | boolean | number | undefined) {
    setRefundOrderState((state) => ({
      ...state,
      [fieldName]: value,
    }));
  }

  function handleSetErrorState(fieldName: FieldNames, value: string | boolean) {
    setRefundOrderErrorState((state) => ({
      ...state,
      [fieldName]: value,
    }));
  }

  function ValidateAllFields(): boolean {
    let valid: boolean = true;

    if (
      reducerState.order.adminSelectedOrder.paymentMethod == PaymentMethod.Invoice ||
      reducerState.order.adminSelectedOrder.totalOrderValue <= 0
    ) {
      dispatch(SnackbarActions.ShowError(translate(LabelKeys.InvalidRefund)));
      return false;
    }

    if (
      refundOrderState.amount <= 0 ||
      refundOrderState.amount >
        reducerState.order.adminSelectedOrder?.totalOrderValue - reducerState.order.adminSelectedOrder.refundedAmount
    ) {
      handleSetErrorState(FieldNames.amount, true);
      valid = false;
    } else {
      handleSetErrorState(FieldNames.amount, false);
    }

    if (refundOrderState.orderId <= 0) {
      handleSetErrorState(FieldNames.orderId, true);
      valid = false;
      dispatch(SnackbarActions.ShowError(translate(LabelKeys.InvalidOrderId)));
    } else {
      handleSetErrorState(FieldNames.orderId, false);
    }
    return valid;
  }

  return (
    <Grid container item xs={12} sm={12} md={12} lg={12} xl={12} style={{ overflowY: "auto", width: "100%", backgroundColor: "#e3e1de" }}>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ paddingTop: 20 }}>
        <CSTextField
          id="refundAmount"
          label={translate(LabelKeys.RefundAmount)}
          type="text"
          placeholder={`${translate(LabelKeys.RefundAmount)}`}
          value={refundOrderState.amount ?? ""}
          onChange={(e) => {
            let value = e.target.value;
            value = value.replaceAll(" ", "");
            value = value.replaceAll(".", "");
            value = value.replaceAll(",", "");

            if (isNaN(Number(value))) {
              handleFieldOnChange(FieldNames.amount, refundOrderState.amount);
              return;
            }
            handleFieldOnChange(FieldNames.amount, e.target.value);
          }}
          error={refundOrderErrorState.amount}
          helperText={translate(LabelKeys.InvalidRefundAmount)}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={6} lg={6} xl={6} style={{ paddingTop: 31, paddingRight: isMobile ? 0 : 8 }}>
        <Button
          id="refundOrderBtn"
          style={{
            height: 46,
            width: "100%",
            cursor: "pointer",
            color: "#fff",
            borderRadius: 0,
            backgroundColor: "#000",
          }}
          onClick={() => handleRefundOrder()}
        >
          <div style={{ margin: "auto", textTransform: "none" }}>{`${translate(LabelKeys.RefundOrder)}`}</div>
        </Button>
      </Grid>
      <Grid item xs={12} sm={6} md={6} lg={6} xl={6} style={{ paddingTop: 31, paddingLeft: isMobile ? 0 : 8 }}>
        <Button
          id="cancelBtn"
          style={{
            height: 46,
            width: "100%",
            cursor: "pointer",
            color: "#fff",
            borderRadius: 0,
            backgroundColor: "#000",
          }}
          onClick={() => props.onClose()}
        >
          <div style={{ margin: "auto", textTransform: "none" }}>{`${translate(LabelKeys.Cancel)}`}</div>
        </Button>
      </Grid>
    </Grid>
  );
}
