import makeStyles from "@mui/styles/makeStyles";
import { theme } from "../../appTheme";

export const FooterStyles = makeStyles(() => ({
  footer: {
    height: 300,
    [theme.breakpoints.only("xs")]: { height: 570 },
    left: 0,
    bottom: 0,
    right: 0,
    background: "#bdb9b2",
    color: "#000",
    borderTop: "1px solid #bdb9b2",
    fontFamily: "Mulish",
  },
  paymentMethods: {
    [theme.breakpoints.down("sm")]: {
      paddingTop: 30,
    },
  },
  copyrightText: {
    paddingTop: 20,
    textAlign: "center",
  },
  linkContainer: {
    textAlign: "left",
    display: "flex",
    alignContent: "flex-start",
    [theme.breakpoints.only("xs")]: { paddingTop: 20 },
  },
  links: {
    textAlign: "left",
    color: "#000",
    float: "left",
    fontSize: "1rem",
    paddingLeft: 15,
    paddingRight: 15,
    paddingTop: 12,
    textDecoration: "none",
    [theme.breakpoints.only("xs")]: {
      lineHeight: "0.9rem",
      textAlign: "left",
      padding: 0,
      float: "left",
      paddingTop: 16,
    },
  },
}));
