import React, { useEffect, useState } from "react";
import { Button, Grid, IconButton, Tooltip, Typography, useMediaQuery, Zoom } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { theme } from "../../../appTheme";
import { RootState } from "../../../store";
import { LabelKeys } from "../../../Utils/enums/labelKeys";
import { ResellerAdminStyles } from "../styles";
import { CoupledResellerOrder, ResellerAdminSubview, resellerCoupledOrdersHeadCells } from "../types";
import { ResellerActions } from "../../../Modules/Reseller/actions";
import ProcessCircle from "../../../components/ProcessCircle/ProcessCircle";
import CSReadOnlyTable from "../../../components/Table/CSReadOnlyTable";
import { IGetResellerAdminContactInfoRequest } from "../../../Modules/Reseller/types";
import { LanguageCode } from "../../../Utils/enums/enums";
import { SendBy } from "../../../components/Delivery/types";
import { OrderStatusConverter } from "../../../Utils/extensions";
import { ICustomOrder } from "../../Admin/types";
import { OrderActions } from "../../../Modules/Orders/actions";
import ResellerOrderDetailsPopup from "../Popups/ResellerOrderDetailsPopup";
import ResellerAdminMenu from "../ResellerAdminMenu";
import ResellerSidebar from "./ResellerSidebar";
import { IsResellerAuth } from "../../../Utils/authentication";
import { GetCurrentLanguageAsCodeFromLocalStorage, GetCurrentLanguageAsText } from "../../../Utils/types";

export default function ResellerOrder() {
  const { t: translate } = useTranslation();
  const classes = ResellerAdminStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [currentTablePage, setCurrentTablePage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const isMobile = useMediaQuery(theme.breakpoints.only("xs"));
  const [clicked, setClicked] = useState(false);
  const [orderDetailsOpen, setOrderDetailsOpenState] = useState(false);

  const reducerState = useSelector(
    (s: RootState) => {
      return {
        reseller: s.reseller,
        authentication: s.authentication,
      };
    },
    (prev, next) =>
      prev.reseller.isLoading === next.reseller.isLoading &&
      prev.reseller.adminResellerContactInfo === next.reseller.adminResellerContactInfo &&
      prev.reseller.adminResellerIncomingOrderEmails === next.reseller.adminResellerIncomingOrderEmails &&
      prev.reseller.coupledResellerOrder === next.reseller.coupledResellerOrder
  );

  useEffect(() => {
    if (!IsResellerAuth()) {
      navigate(`/${GetCurrentLanguageAsText()}/${translate(LabelKeys.Reseller)}/${translate(LabelKeys.SignInUrl)}`);
    }
  }, [reducerState.authentication.hasBeenSignedOut]);

  useEffect(() => {
    dispatch(
      ResellerActions.SearchResellerCoupledOrdersRequest({
        skip: currentTablePage == 0 ? 0 : currentTablePage * rowsPerPage,
        take: rowsPerPage,
      })
    );
    dispatch(
      ResellerActions.GetResellerAdminContactInfoRequest({
        language: LanguageCode.SE,
      } as IGetResellerAdminContactInfoRequest)
    );
  }, []);

  useEffect(() => {
    const fromDate = new Date();
    fromDate.setDate(new Date().getDate() - 60);
    dispatch(
      ResellerActions.SearchResellerCoupledOrdersRequest({
        skip: currentTablePage == 0 ? 0 : currentTablePage * rowsPerPage,
        take: rowsPerPage,
      })
    );
  }, [currentTablePage]);

  useEffect(() => {
    const fromDate = new Date();
    fromDate.setDate(new Date().getDate() - 60);
    dispatch(
      ResellerActions.SearchResellerCoupledOrdersRequest({
        skip: currentTablePage == 0 ? 0 : currentTablePage * rowsPerPage,
        take: rowsPerPage,
      })
    );
  }, [rowsPerPage]);

  function handleSearchedOrdersClicked(selectedRow: ICustomOrder): void {
    if (selectedRow) dispatch(ResellerActions.SearchSingleOrderRequest({ orderId: selectedRow.id }));
    setOrderDetailsOpenState(true);
  }

  const handleRefreshMenuClick = () => {
    const fromDate = new Date();
    fromDate.setDate(new Date().getDate() - 60);
    dispatch(
      ResellerActions.SearchResellerCoupledOrdersRequest({
        skip: currentTablePage == 0 ? 0 : currentTablePage * rowsPerPage,
        take: rowsPerPage,
      })
    );
  };

  function GetTableBodyData(searchedOrders: CoupledResellerOrder[]): ICustomOrder[] {
    let customOrders: ICustomOrder[] = [];

    if (searchedOrders.length > 0) {
      searchedOrders.map((item) => {
        const order = {
          id: item.orderId,
          createdDate: item.createdDate,
          orderDate: item.orderDate,
          companyName: item.companyName,
          status: OrderStatusConverter(item.status, GetCurrentLanguageAsCodeFromLocalStorage()),
          sendBy: translate(SendBy[item.sendBy]?.toLowerCase()),
        };
        customOrders.push(order);
      });
    }
    return customOrders;
  }

  function ResetAllOrderValues() {
    dispatch(OrderActions.ResetStepperState());
    dispatch(OrderActions.ResetStepOneState());
    dispatch(OrderActions.ResetStepTwoState());
    dispatch(OrderActions.ResetStepThreeState());
    dispatch(OrderActions.ResetStepFourState());
    dispatch(OrderActions.ResetStepFiveState());
    dispatch(OrderActions.ResetStepSixState());
  }

  return (
    <div style={{ display: "flex" }}>
      {!isMobile && (
        <Grid item xs={2} sm={1} md={1} lg={1} xl={1}>
          <ResellerSidebar activeSubview={ResellerAdminSubview.Order} />
        </Grid>
      )}
      <Grid container item xs={12} sm={12} md={12} lg={12} xl={12} justifyContent={"center"}>
        {reducerState.reseller?.isLoading && <ProcessCircle />}
        {isMobile && (
          <Grid item xs={11}>
            <ResellerAdminMenu />
          </Grid>
        )}
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className={classes.subviewContent}>
          <Grid id="resellerAdminOrderContainer" container item xs={12} sm={12} md={12} lg={12} xl={12}>
            {reducerState.reseller?.isLoading && <ProcessCircle />}
            <Grid
              id="orderContainer"
              container
              item
              xs={12}
              sm={6}
              md={6}
              lg={6}
              xl={6}
              style={{ height: "100%", paddingRight: isMobile ? 0 : 20 }}
            >
              <Grid id="newOrderTitle" item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Typography variant="h2" style={{ textAlign: "left" }}>
                  {translate(LabelKeys.Order)}
                </Typography>
              </Grid>
              <Grid id="createNewAdminOrderBtn" item xs={12} sm={12} md={10} lg={5} xl={5}>
                <Button
                  onClick={() => {
                    ResetAllOrderValues();
                    navigate(
                      `/${GetCurrentLanguageAsText()}/${translate(LabelKeys.ResellerUrl)}/${translate(LabelKeys.AdminUrl)}/${translate(
                        LabelKeys.OrderUrl
                      )}`
                    );
                  }}
                  style={{
                    height: 56,
                    width: isMobile ? "100%" : "80%",
                    cursor: "pointer",
                    color: "#fff",
                    display: "flex",
                    textTransform: "none",
                    marginTop: 20,
                    borderRadius: 0,
                    backgroundColor: "#2eac77",
                  }}
                >
                  {translate(LabelKeys.CreateNewOrder)}
                </Button>
              </Grid>
            </Grid>
            <Grid container item xs={12} sm={6} md={6} lg={6} xl={6} style={{ height: "100%", paddingLeft: isMobile ? 0 : 20 }}>
              <Grid id="resellerUrlTitle" item xs={12} sm={6} md={6} lg={6} xl={6} style={{ marginTop: isMobile ? 40 : 0 }}>
                <Typography variant="h2" align="left">
                  {translate(LabelKeys.ResellerOrderUrl)}
                </Typography>
              </Grid>
              <Grid id="resellerUrlDescription" item xs={12} sm={12} md={12} lg={9} xl={9} style={{ marginTop: 20, marginBottom: 20 }}>
                <Typography variant="body1" style={{ textAlign: "left" }}>
                  {translate(LabelKeys.ResellerUrlDescription)}
                </Typography>
              </Grid>
              <Grid
                id="resellerUrl"
                item
                xs={12}
                sm={12}
                md={12}
                lg={9}
                xl={9}
                onMouseEnter={() => setClicked(false)}
                onClick={() => {
                  navigator.clipboard.writeText(reducerState.reseller.adminResellerContactInfo?.url);
                  setClicked(true);
                }}
                className={classes.resellerOrderUrlContainer}
              >
                <Tooltip
                  leaveDelay={100}
                  arrow
                  title={
                    <div style={{ display: "flex" }}>
                      {!clicked ? (
                        <svg
                          width="20"
                          height="20"
                          fill="currentColor"
                          className="bi bi-clipboard"
                          viewBox="0 0 16 16"
                          style={{ paddingRight: 10 }}
                        >
                          <path d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1v-1z" />
                          <path d="M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h3zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3z" />
                        </svg>
                      ) : (
                        <svg
                          width="20"
                          height="20"
                          fill="currentColor"
                          className="bi bi-clipboard-check"
                          viewBox="0 0 16 16"
                          style={{ paddingRight: 10 }}
                        >
                          <path
                            fillRule="evenodd"
                            d="M10.854 7.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7.5 9.793l2.646-2.647a.5.5 0 0 1 .708 0z"
                          />
                          <path d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1v-1z" />
                          <path d="M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h3zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3z" />
                        </svg>
                      )}
                      <Typography variant="body1" style={{ paddingLeft: 2, color: "#fff" }}>
                        {!clicked ? translate(LabelKeys.CopyToClipboard) : translate(LabelKeys.Copied)}
                      </Typography>
                    </div>
                  }
                  TransitionComponent={Zoom}
                  placement="top"
                >
                  <Typography
                    variant="body1"
                    sx={{
                      fontSize: {
                        xs: "0.7rem",
                        sm: "0.75rem",
                        md: "0.8rem",
                        lg: "1rem",
                        xl: "1rem",
                      },
                    }}
                    style={{ fontWeight: 600 }}
                    className={classes.resellerOrderUrl}
                    onClick={() => {
                      navigator.clipboard.writeText(reducerState.reseller.adminResellerContactInfo?.url);
                    }}
                  >
                    {reducerState.reseller.adminResellerContactInfo?.url ?? translate(LabelKeys.ResellerOrderUrl)}
                  </Typography>
                </Tooltip>
              </Grid>
            </Grid>
            <Grid
              id="orders"
              container
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              style={{ paddingTop: 10, paddingBottom: 30, textAlign: "left" }}
            >
              <Grid id="orders" item xs={11} sm={5} md={4} lg={3} xl={2} style={{ paddingTop: 10, paddingBottom: 30, textAlign: "left" }}>
                <Typography variant="h2">{translate(LabelKeys.MyOrders)}</Typography>
              </Grid>
              <Grid item xs={1} sm={1} md={1} lg={1} xl={1} style={{ marginTop: 15 }}>
                <IconButton onClick={() => handleRefreshMenuClick()} style={{ float: "left" }}>
                  <svg width="25" height="25" fill="currentColor" className="bi bi-arrow-counterclockwise" viewBox="0 0 16 16">
                    <path d="M8 3a5 5 0 1 1-4.546 2.914.5.5 0 0 0-.908-.417A6 6 0 1 0 8 2v1z" />
                    <path d="M8 4.466V.534a.25.25 0 0 0-.41-.192L5.23 2.308a.25.25 0 0 0 0 .384l2.36 1.966A.25.25 0 0 0 8 4.466z" />
                  </svg>
                </IconButton>
              </Grid>
              <CSReadOnlyTable
                columns={resellerCoupledOrdersHeadCells}
                rows={GetTableBodyData(reducerState.reseller.coupledResellerOrder)}
                onRowClick={(e, selectedRow, selectedRows) => handleSearchedOrdersClicked(selectedRow)}
                enableRowClick={true}
                maxHeight={600}
                totalItems={reducerState.reseller.coupledResellerOrderTotalItems}
                enablePagination
                rowsPerPage={rowsPerPage}
                currentPage={currentTablePage}
                onCurrentPageChanged={(e, page) => setCurrentTablePage(page)}
                onRowsPerPageChanged={(e) => {
                  setRowsPerPage(Number(e.target.value));
                  setCurrentTablePage(0);
                }}
                showTimeWithDate
              />
            </Grid>
            {orderDetailsOpen && <ResellerOrderDetailsPopup onGoBackToOrderPageClicked={(x) => setOrderDetailsOpenState(x)} />}
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}
