import React from "react";
import { Grid, Typography, useMediaQuery } from "@mui/material";
import { useTranslation } from "react-i18next";
import { theme } from "../../appTheme";
import { ProductCategory } from "../../Modules/Products/types";
import { SystemPart, Currency } from "../../Utils/enums/enums";
import { LabelKeys } from "../../Utils/enums/labelKeys";
import { IOrderSummaryRow } from "./types";

interface IOrderSummaryProps {
  id: string;
  quantity: number;
  discount?: number;
  values: IOrderSummaryRow[];
  systemPart: SystemPart;
  currency: Currency;
  isOrderConfirmation: boolean;
  isAdminOrder: boolean;
  isResellerAdminOrder: boolean;
}

const OrderSummary = (props: IOrderSummaryProps): any => {
  const isMobile = useMediaQuery(theme.breakpoints.only("xs"));
  const { t: translate } = useTranslation();

  function ConvertToReadableSumString(sum: number): string {
    let readableSum = sum.toString();
    let values = readableSum.split(".");
    return values[0].replace(/(?=(\d{3})+(?!\d))/g, " ") + (values?.length > 1 ? "." + values[1] : "");
  }

  function GetTotalProductsPrice(): number {
    if (props.isAdminOrder || props.isResellerAdminOrder || props.systemPart == SystemPart.Company) {
      return GetExcluseiveVatAmount();
    } else {
      return GetInclusiveVatAmount();
    }
  }

  function GetInclExclVatAmount(): number {
    if (props.isAdminOrder || props.isResellerAdminOrder || props.systemPart == SystemPart.Company) {
      return GetInclusiveVatAmount();
    } else {
      return GetExcluseiveVatAmount();
    }
  }

  function GetDiscountSumExcludingVat(): number {
    let productCost: number = 0;

    if (props.isAdminOrder) {
      props.values
        .filter(
          (x) =>
            x.category == ProductCategory.GiftCardGMG ||
            x.category == ProductCategory.DigitalProduct ||
            x.category == ProductCategory.GiftCardVH ||
            x.category == ProductCategory.Freight
        )
        .map((x) => {
          productCost += x.priceExcludingVat * x.quantity;
        });
    } else {
      props.values
        .filter(
          (x) =>
            x.category == ProductCategory.GiftCardGMG ||
            x.category == ProductCategory.DigitalProduct ||
            x.category == ProductCategory.GiftCardVH
        )
        .map((x) => {
          productCost += x.priceExcludingVat * x.quantity;
        });
    }

    return productCost * props.discount;
  }

  function GetDiscountSumIncludingVat(): number {
    let productCost: number = 0;

    if (props.isAdminOrder) {
      props.values
        .filter(
          (x) =>
            x.category == ProductCategory.GiftCardGMG ||
            x.category == ProductCategory.DigitalProduct ||
            x.category == ProductCategory.GiftCardVH ||
            x.category == ProductCategory.Freight
        )
        .map((x) => {
          productCost += x.priceIncludingVat * x.quantity;
        });
    } else {
      props.values
        .filter(
          (x) =>
            x.category == ProductCategory.GiftCardGMG ||
            x.category == ProductCategory.DigitalProduct ||
            x.category == ProductCategory.GiftCardVH
        )
        .map((x) => {
          productCost += x.priceIncludingVat * x.quantity;
        });
    }

    return productCost * props.discount;
  }

  function GetExcluseiveVatAmount() {
    let productCost: number = 0;

    if (props.systemPart == SystemPart.Private && !props.isAdminOrder && !props.isResellerAdminOrder) {
      props?.values
        ?.filter(
          (x) =>
            x.category == ProductCategory.GiftCardGMG ||
            x.category == ProductCategory.GiftCardVH ||
            x.category == ProductCategory.DigitalProduct ||
            x.category == ProductCategory.Freight
        )
        .map((x) => {
          productCost += x.priceExcludingVat * x.quantity;
        });
      return productCost;
    } else if (props.systemPart == SystemPart.Company && !props.isAdminOrder && !props.isResellerAdminOrder) {
      props?.values
        ?.filter(
          (x) =>
            x.category == ProductCategory.GiftCardGMG ||
            x.category == ProductCategory.GiftCardVH ||
            x.category == ProductCategory.DigitalProduct ||
            x.category == ProductCategory.Freight ||
            x.category == ProductCategory.CustomWebPage
        )
        .map((x) => {
          productCost += x.priceExcludingVat * x.quantity;
        });
      return productCost;
    } else if (props.isAdminOrder) {
      props?.values
        ?.filter(
          (x) =>
            x.category == ProductCategory.GiftCardGMG ||
            x.category == ProductCategory.GiftCardVH ||
            x.category == ProductCategory.DigitalProduct ||
            x.category == ProductCategory.Freight
        )
        .map((x) => {
          productCost += x.priceExcludingVat * x.quantity;
        });
      return productCost - GetDiscountSumExcludingVat();
    } else if (props.isResellerAdminOrder) {
      props?.values
        ?.filter(
          (x) =>
            x.category == ProductCategory.GiftCardGMG ||
            x.category == ProductCategory.GiftCardVH ||
            x.category == ProductCategory.DigitalProduct ||
            x.category == ProductCategory.Freight
        )
        .map((x) => {
          productCost += x.priceExcludingVat * x.quantity;
        });
      return productCost - GetDiscountSumExcludingVat();
    }

    return productCost;
  }

  function GetInclusiveVatAmount(): number {
    let productCost: number = 0;

    if (props.systemPart == SystemPart.Private && !props.isAdminOrder && !props.isResellerAdminOrder) {
      props?.values
        ?.filter(
          (x) =>
            x.category == ProductCategory.GiftCardGMG ||
            x.category == ProductCategory.GiftCardVH ||
            x.category == ProductCategory.DigitalProduct ||
            x.category == ProductCategory.Freight
        )
        .map((x) => {
          productCost += x.priceIncludingVat * x.quantity;
        });
      return productCost;
    } else if (props.systemPart == SystemPart.Company && !props.isAdminOrder && !props.isResellerAdminOrder) {
      props?.values
        ?.filter(
          (x) =>
            x.category == ProductCategory.GiftCardGMG ||
            x.category == ProductCategory.GiftCardVH ||
            x.category == ProductCategory.DigitalProduct ||
            x.category == ProductCategory.Freight ||
            x.category == ProductCategory.CustomWebPage
        )
        .map((x) => {
          productCost += x.priceIncludingVat * x.quantity;
        });
      return productCost;
    } else if (props.isAdminOrder) {
      props?.values
        ?.filter(
          (x) =>
            x.category == ProductCategory.GiftCardGMG ||
            x.category == ProductCategory.GiftCardVH ||
            x.category == ProductCategory.DigitalProduct ||
            x.category == ProductCategory.Freight
        )
        .map((x) => {
          productCost += x.priceIncludingVat * x.quantity;
        });
      return productCost - GetDiscountSumIncludingVat();
    } else if (props.isResellerAdminOrder) {
      props?.values
        ?.filter(
          (x) =>
            x.category == ProductCategory.GiftCardGMG ||
            x.category == ProductCategory.GiftCardVH ||
            x.category == ProductCategory.DigitalProduct ||
            x.category == ProductCategory.Freight
        )
        .map((x) => {
          productCost += x.priceIncludingVat * x.quantity;
        });
      return productCost - GetDiscountSumIncludingVat();
    }

    return productCost;
  }

  function GetVatAmount(): number {
    let productCost: number = 0;

    if (props.isAdminOrder) {
      props?.values
        ?.filter(
          (x) =>
            x.category == ProductCategory.GiftCardGMG ||
            x.category == ProductCategory.GiftCardVH ||
            x.category == ProductCategory.DigitalProduct ||
            x.category == ProductCategory.Freight
        )
        .map((_) => (productCost += 0));
    } else if (props.isResellerAdminOrder) {
      props?.values
        ?.filter(
          (x) =>
            x.category == ProductCategory.GiftCardGMG ||
            x.category == ProductCategory.GiftCardVH ||
            x.category == ProductCategory.DigitalProduct ||
            x.category == ProductCategory.Freight
        )
        .map((x) => {
          productCost +=
            (x.priceIncludingVat - x.priceExcludingVat) * (x.category == ProductCategory.Freight ? 1 : props.discount) * x.quantity;
        });
    } else if (props.systemPart == SystemPart.Private) {
      props?.values
        ?.filter(
          (x) =>
            x.category == ProductCategory.GiftCardGMG ||
            x.category == ProductCategory.GiftCardVH ||
            x.category == ProductCategory.DigitalProduct ||
            x.category == ProductCategory.Freight
        )
        .map((x) => {
          productCost += (x.priceIncludingVat - x.priceExcludingVat) * x.quantity;
        });
    } else if (props.systemPart == SystemPart.Company) {
      props?.values
        ?.filter(
          (x) =>
            x.category == ProductCategory.GiftCardGMG ||
            x.category == ProductCategory.GiftCardVH ||
            x.category == ProductCategory.DigitalProduct ||
            x.category == ProductCategory.Freight ||
            x.category == ProductCategory.CustomWebPage
        )
        .map((x) => {
          productCost += (x.priceIncludingVat - x.priceExcludingVat) * x.quantity;
        });
    }
    return productCost;
  }

  function GetVatLabel() {
    if (props.isAdminOrder || props.isResellerAdminOrder) {
      return translate(LabelKeys.ExcludingVat);
    } else if (props.systemPart == SystemPart.Private) {
      return translate(LabelKeys.IncludingVat);
    } else if (props.systemPart == SystemPart.Company) {
      return translate(LabelKeys.ExcludingVat);
    } else {
      return translate(LabelKeys.ExcludingVat);
    }
  }

  function GetReversedVatLabel() {
    if (props.isAdminOrder || props.isResellerAdminOrder) {
      return translate(LabelKeys.IncludingVat);
    } else if (props.systemPart == SystemPart.Private) {
      return translate(LabelKeys.ExcludingVat);
    } else if (props.systemPart == SystemPart.Company) {
      return translate(LabelKeys.IncludingVat);
    } else {
      return translate(LabelKeys.IncludingVat);
    }
  }

  return (
    <Grid id="outerContainer" container item xs={12} sm={12} md={12} lg={12} xl={12} style={{ paddingTop: 20 }}>
      <Grid id="paymentInfoPartSummery" container item xs={12} sm={12} md={12} lg={12} xl={12}>
        {!isMobile && (
          <Grid id="titleContainer" container item xs={12} sm={12} md={12} lg={12} xl={12} style={{ paddingLeft: 15, paddingRight: 15 }}>
            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
              <Typography variant="body1" style={{ textAlign: "left", fontWeight: "bold" }}>
                {translate(LabelKeys.Product)}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={2} md={2} lg={2} xl={2}>
              <Typography variant="body1" style={{ textAlign: "right", fontWeight: "bold" }}>
                {translate(LabelKeys.Amount)}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={2} md={2} lg={2} xl={2}>
              <Typography variant="body1" style={{ textAlign: "right", fontWeight: "bold" }}>
                {translate(LabelKeys.Price)}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={2} md={2} lg={2} xl={2}>
              <Typography
                variant="body1"
                style={{
                  textAlign: "right",
                  fontWeight: "bold",
                  paddingRight: 15,
                }}
              >
                {translate(LabelKeys.Sum)}
              </Typography>
            </Grid>
          </Grid>
        )}
        <Grid
          id="infoContainer"
          container
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          style={{
            padding: 15,
            backgroundColor: "#fff",
            border: "1px solid #3D374D33",
            marginTop: 17,
            minHeight: 100,
          }}
        >
          {props.values?.map((x, index) => {
            return (
              <Grid key={index} id="infoProduct" container item xs={12} sm={12} md={12} lg={12} xl={12}>
                {isMobile && (
                  <Grid id="infoAmountMobile" item xs={2} sm={2} md={2} lg={2} xl={2}>
                    <Typography variant="body1" style={{ textAlign: "left" }}>
                      {`${props?.quantity ?? 0}x`}
                    </Typography>
                  </Grid>
                )}
                <Grid key={index} id="infoProductName" item xs={7} sm={6} md={6} lg={6} xl={6}>
                  <Typography variant={"body1"} style={{ textAlign: "left" }}>
                    {x?.name ?? ""}
                  </Typography>
                </Grid>
                {!isMobile && (
                  <Grid id="infoAmount" item xs={6} sm={2} md={2} lg={2} xl={2}>
                    <Typography variant="body1" style={{ textAlign: "right" }}>
                      {x?.amount > 0 ? x?.amount : props?.quantity > 0 ? props?.quantity : 0}
                    </Typography>
                  </Grid>
                )}
                {!isMobile && (
                  <Grid id="infoPrice" item sm={2} md={2} lg={2} xl={2}>
                    <Typography variant="body1" style={{ textAlign: "right" }}>
                      {`${
                        props?.systemPart == SystemPart.Private && !props.isAdminOrder && !props.isResellerAdminOrder
                          ? x.priceIncludingVat
                          : x.priceExcludingVat
                      } ${Currency[props.currency] ?? Currency[Currency.SEK]}`}
                    </Typography>
                  </Grid>
                )}
                <Grid id="infoSum" item xs={3} sm={2} md={2} lg={2} xl={2}>
                  <Typography variant={"body1"} style={{ textAlign: "right" }}>
                    {`${(
                      (x?.amount > 0 ? x?.amount : props?.quantity) *
                      (props?.systemPart == SystemPart.Private && !props.isAdminOrder && !props.isResellerAdminOrder
                        ? x.priceIncludingVat
                        : x.priceExcludingVat)
                    ).toFixed(2)} ${Currency[props.currency] ?? Currency[Currency.SEK]}`}
                  </Typography>
                </Grid>
              </Grid>
            );
          })}
        </Grid>
      </Grid>
      <Grid
        id="paymentInfoSummaryCost"
        container
        item
        xs={12}
        sm={12}
        md={12}
        lg={12}
        xl={12}
        style={{
          padding: 15,
          backgroundColor: "#fff",
          border: "1px solid #3D374D33",
          marginTop: 16,
        }}
      >
        <Grid id="total" container item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
            <Typography
              variant="body1"
              style={{
                float: "left",
                fontWeight: "bold",
                fontSize: 20,
              }}
            >{`${translate(LabelKeys.Totaly)}:`}</Typography>
          </Grid>
          <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
            <Typography
              style={{
                float: "right",
                fontWeight: "bold",
                fontSize: 20,
              }}
            >{`${ConvertToReadableSumString(GetTotalProductsPrice())} ${Currency[props.currency] ?? Currency[Currency.SEK]}`}</Typography>
          </Grid>
        </Grid>
        <Grid container item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Typography variant="body2" style={{ float: "right", color: "#000" }}>
              {GetVatLabel()}
            </Typography>
          </Grid>
        </Grid>
        <Grid id="momsLabel" container item xs={12} sm={12} md={12} lg={12} xl={12} style={{ paddingTop: 15 }}>
          <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
            <Typography variant="body1" style={{ float: "left" }}>{`${translate(LabelKeys.Vat)}`}</Typography>
          </Grid>
          <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
            <Typography variant="body1" style={{ float: "right" }}>{`${Math.round(GetVatAmount() * 100) / 100} ${
              Currency[props.currency] ?? Currency[Currency.SEK]
            }`}</Typography>
          </Grid>
        </Grid>
        <Grid id="exklInklMomsLabel" container item xs={12} sm={12} md={12} lg={12} xl={12} style={{ paddingTop: 6 }}>
          <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
            <Typography variant="body1" style={{ float: "left" }}>{`${GetReversedVatLabel()}`}</Typography>
          </Grid>
          <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
            <Typography variant="body1" style={{ float: "right" }}>{`${GetInclExclVatAmount()} ${
              Currency[props.currency] ?? Currency[Currency.SEK]
            }`}</Typography>
          </Grid>
        </Grid>
        {(props.isAdminOrder || props.isResellerAdminOrder) && (
          <Grid id="discount" container item xs={12} sm={12} md={12} lg={12} xl={12} style={{ paddingTop: 6 }}>
            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
              <Typography variant="body1" style={{ float: "left" }}>{`${translate(LabelKeys.Discount)}`}</Typography>
            </Grid>
            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
              <Typography variant="body1" style={{ float: "right" }}>
                {`-${GetDiscountSumExcludingVat()} ${Currency[props.currency] ?? Currency[Currency.SEK]}`}
              </Typography>
            </Grid>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default OrderSummary;
