import React, { useEffect } from "react";
import { DialogContent, DialogContentText, Grid, IconButton, Typography, useMediaQuery } from "@mui/material";
import Dialog from "@mui/material/Dialog/Dialog";
import { useTranslation } from "react-i18next";
import { LabelKeys } from "../../Utils/enums/labelKeys";
import { Link, useNavigate } from "react-router-dom";
import { FieldNames, GetCurrentLanguageAsText } from "../../Utils/types";
import { CookieConstants } from "../../Utils/Labels/types";
import Cookies from "js-cookie";
import { useDispatch, useSelector } from "react-redux";
import { ProductActions } from "../../Modules/Products/actions";
import { OrderActions } from "../../Modules/Orders/actions";
import { theme } from "../../appTheme";
import { RootState } from "../../store";
import { AuthenticationActions } from "../../Modules/Authentication/actions";
import { SystemPart } from "../../Utils/enums/enums";
import { CheckSystemPartCookie } from "../../Utils/authentication";

export default function SystemPartDialog() {
  const { t: translate } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isMobile = useMediaQuery(theme.breakpoints.only("xs"));
  const systemPart: SystemPart = Number(Cookies.get(CookieConstants.SystemPart));
  let voucherPage = location.pathname.includes(`/${GetCurrentLanguageAsText()}/voucher`);
  let isSignInPage = location.pathname.includes("/signin");
  let isAdminPage = location.pathname.includes(`/${translate(LabelKeys.AdminUrl)}`);

  const reducerState = useSelector(
    (s: RootState) => {
      return {
        authentication: s.authentication,
      };
    },
    (prev, next) => prev.authentication.isSystemPartDialogOpen === next.authentication.isSystemPartDialogOpen
  );

  function handleSystemPartClicked(systemPart: SystemPart): void {
    if (systemPart != Number(Cookies.get(CookieConstants.SystemPart))) {
      dispatch(ProductActions.ResetReducer());
    }

    if (systemPart == SystemPart.Private) {
      setPrivateSystemPartCookie();
    } else {
      setCompanySystemPartCookie();
    }

    handleCloseDialog();
    navigate(`/${GetCurrentLanguageAsText()}/`);
  }

  function setPrivateSystemPartCookie() {
    const options: Cookies.CookieAttributes = {
      expires: 365,
    } as Cookies.CookieAttributes;
    Cookies.set(CookieConstants.SystemPart, SystemPart.Private.toString(), options);
    dispatch(
      OrderActions.SetEnvironmentStateRequest({
        fieldName: FieldNames.systemPart,
        value: SystemPart.Private,
      })
    );
  }

  function setCompanySystemPartCookie() {
    const options: Cookies.CookieAttributes = {
      expires: 365,
    } as Cookies.CookieAttributes;
    Cookies.set(CookieConstants.SystemPart, SystemPart.Company.toString(), options);
    dispatch(
      OrderActions.SetEnvironmentStateRequest({
        fieldName: FieldNames.systemPart,
        value: SystemPart.Company,
      })
    );
  }

  function handleCloseDialog() {
    dispatch(AuthenticationActions.SetIsSystemPartDialogOpenRequest(false));
  }
  //Triggas när du kommer från Voucher page till startsidan
  useEffect(() => {
    if (
      !voucherPage &&
      !isSignInPage &&
      !isAdminPage &&
      systemPart != SystemPart.Private &&
      systemPart != SystemPart.Company &&
      Cookies.get(CookieConstants.GBVReseller)?.length == 0
    ) {
      dispatch(AuthenticationActions.SetIsSystemPartDialogOpenRequest(true));
    }
  }, [voucherPage]);

  useEffect(() => {
    CheckSystemPartCookie();
  }, []);

  return (
    <Dialog
      open={reducerState.authentication.isSystemPartDialogOpen}
      disableEnforceFocus
      PaperProps={{ style: { borderRadius: 0, maxWidth: 460, padding: 0 } }}
    >
      <DialogContent style={{ borderRadius: 0, maxWidth: 530, padding: 10 }}>
        <DialogContentText>
          <Grid id="customerTypeTextContainer" container item xs={12} sm={12} md={12} lg={12} xl={12} justifyContent="center">
            <Grid id="customerTypeText" item xs={10} sm={10} md={10} lg={10} xl={10}>
              <Typography style={{ fontSize: isMobile ? 24 : 36, fontFamily: "Butler", width: "100%" }} textAlign="left">
                {translate(LabelKeys.WhoIsOrdering)}
              </Typography>
              <Grid id="customerTypeText" item xs={1} sm={1} md={1} lg={1} xl={1}>
                {Cookies.get(CookieConstants.SystemPart)?.length > 0 && (
                  <IconButton
                    onClick={() => handleCloseDialog()}
                    sx={{
                      position: "absolute",
                      right: isMobile ? 10 : 15,
                      top: isMobile ? 12 : 23,
                      padding: 0,
                      color: (theme) => theme.palette.grey[500],
                    }}
                  >
                    <svg style={{}} width="25" height="25" fill="currentColor" className="bi bi-x-circle" viewBox="0 0 16 16">
                      <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                      <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                    </svg>
                  </IconButton>
                )}
              </Grid>
            </Grid>
          </Grid>
        </DialogContentText>
        <Grid
          id="customerTypeContainer"
          container
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          direction="row"
          justifyContent="center"
          style={{ marginTop: 20 }}
        >
          <Grid id="customerTypePrivate" item xs={10} sm={10} md={10} lg={10} xl={10} justifyContent="center">
            <div
              style={{
                border: "1px solid #000",
                height: 50,
                display: "flex",
                cursor: "pointer",
              }}
              onClick={() => handleSystemPartClicked(SystemPart.Private)}
            >
              <Typography
                style={{
                  textAlign: isMobile ? "center" : "left",
                  paddingLeft: isMobile ? 0 : 15,
                  margin: "auto",
                  width: "100%",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                }}
                variant="body1"
              >
                {`${translate(LabelKeys.PrivatePage)} (${translate(LabelKeys.IncludingVat).toLowerCase()})`}
              </Typography>
              <div style={{ width: "50%" }}>
                <svg
                  style={{ float: "right", paddingRight: 18, paddingTop: 8 }}
                  width="35"
                  height="35"
                  fill="currentColor"
                  className="bi bi-arrow-right-short"
                  viewBox="0 0 16 16"
                >
                  <path
                    fillRule="evenodd"
                    d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z"
                  />
                </svg>
              </div>
            </div>
          </Grid>
          <Grid id="customerTypeCompany" item xs={10} sm={10} md={10} lg={10} xl={10} justifyContent="center" style={{ marginTop: 10 }}>
            <div
              style={{
                border: "1px solid #000",
                height: 50,
                display: "flex",
                cursor: "pointer",
              }}
              onClick={() => handleSystemPartClicked(SystemPart.Company)}
            >
              <Typography
                style={{
                  textAlign: isMobile ? "center" : "left",
                  paddingLeft: isMobile ? 0 : 15,
                  margin: "auto",
                  width: "100%",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                }}
                variant="body1"
                className=""
              >
                {`${translate(LabelKeys.CompanyPage)} (${translate(LabelKeys.ExcludingVat).toLowerCase()})`}
              </Typography>
              <div style={{ width: "35%" }}>
                <svg
                  style={{ float: "right", paddingRight: 18, paddingTop: 8 }}
                  width="35"
                  height="35"
                  fill="currentColor"
                  className="bi bi-arrow-right-short"
                  viewBox="0 0 16 16"
                >
                  <path
                    fillRule="evenodd"
                    d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z"
                  />
                </svg>
              </div>
            </div>
          </Grid>
          <Grid
            id="customerTypeLoginContainer"
            item
            xs={10}
            sm={10}
            md={10}
            lg={10}
            xl={10}
            justifyContent="center"
            style={{
              paddingTop: 25,
              paddingBottom: 20,
              display: "flex",
            }}
          >
            <div id="customerTypeTextContainer" style={{ fontSize: 16 }}>{`${translate(LabelKeys.Reseller)}?`}</div>
            <div id="customerTypeLinkContainer" style={{ paddingLeft: 5 }}>
              <Link
                to={`/${GetCurrentLanguageAsText()}/${translate(LabelKeys.ResellerUrl)}/${translate(LabelKeys.AdminUrl)}`}
                style={{ color: "#000", fontSize: 16 }}
                onClick={() => handleCloseDialog()}
              >
                {translate(LabelKeys.LoginHere)}
              </Link>
            </div>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}
