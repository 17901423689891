import { ProductActionTypes } from "./actionTypes";
import {
  ICreateMessageTemplateRequest,
  IDeleteMessageTemplateRequest,
  IGetMessageTemplatesRequest,
  IGetMessageTemplatesResponse,
  IGetLitiumImageBySystemIdRequest,
  IGetLitiumImageBySystemIdResponse,
  IGetLitiumImagesByFolderIdRequest,
  IGetLitiumImagesByFolderIdResponse,
  IGetPreviewPDFRequest,
  IGetProductByIdRequest,
  IGetProductByIdResponse,
  IGetProductCategoriesResponse,
  IGetProductsRequest,
  IGetProductsResponse,
  IGetVoucherRequest,
  IGetVoucherResponse,
  ISetSelectedProduct,
  ISetSelectedProductCategory,
  IUpdateMessageTemplateRequest,
} from "./types";

//Get product by id
interface Get_Product_By_Id_Request {
  type: ProductActionTypes.GET_PRODUCT_BY_ID_REQUEST;
  request: IGetProductByIdRequest;
}
interface Get_Product_By_Id_Success {
  type: ProductActionTypes.GET_PRODUCT_BY_ID_SUCCESS;
  response: IGetProductByIdResponse;
}
interface Get_Product_By_Id_Error {
  type: ProductActionTypes.GET_PRODUCT_BY_ID_ERROR;
}

//Get all products
interface Get_Products_Request {
  type: ProductActionTypes.GET_PRODUCTS_REQUEST;
  request: IGetProductsRequest;
}
interface Get_Products_Success {
  type: ProductActionTypes.GET_PRODUCTS_SUCCESS;
  response: IGetProductsResponse;
}
interface Get_Products_Error {
  type: ProductActionTypes.GET_PRODUCTS_ERROR;
}

//Get preview PDF
interface Get_Preview_PDF_Request {
  type: ProductActionTypes.GET_PREVIEW_PDF_REQUEST;
  request: IGetPreviewPDFRequest;
}
interface Get_Preview_PDF_Success {
  type: ProductActionTypes.GET_PREVIEW_PDF_SUCCESS;
  response: Blob;
}
interface Get_Preview_PDF_Error {
  type: ProductActionTypes.GET_PREVIEW_PDF_ERROR;
}

//Set
interface Set_Selected_Product {
  type: ProductActionTypes.SET_SELECTED_PRODUCT;
  request: ISetSelectedProduct;
}
interface Set_Selected_Product_Category {
  type: ProductActionTypes.SET_SELECTED_PRODUCT_CATEGORY;
  request: ISetSelectedProductCategory;
}

//Reset
interface Reset_Reducer {
  type: ProductActionTypes.RESET_REDUCER;
}

//Create message templates
interface Create_Message_Templates_Request {
  type: ProductActionTypes.CREATE_MESSAGE_TEMPLATES_REQUEST;
  request: ICreateMessageTemplateRequest;
}
interface Create_Message_Templates_Success {
  type: ProductActionTypes.CREATE_MESSAGE_TEMPLATES_SUCCESS;
}
interface Create_Message_Templates_Error {
  type: ProductActionTypes.CREATE_MESSAGE_TEMPLATES_ERROR;
}

//Get message templates
interface Get_Message_Templates_Request {
  type: ProductActionTypes.GET_MESSAGE_TEMPLATES_REQUEST;
  request: IGetMessageTemplatesRequest;
}
interface Get_Message_Templates_Success {
  type: ProductActionTypes.GET_MESSAGE_TEMPLATES_SUCCESS;
  response: IGetMessageTemplatesResponse;
}
interface Get_Message_Templates_Error {
  type: ProductActionTypes.GET_MESSAGE_TEMPLATES_ERROR;
}

//Update message templates
interface Update_Message_Templates_Request {
  type: ProductActionTypes.UPDATE_MESSAGE_TEMPLATES_REQUEST;
  request: IUpdateMessageTemplateRequest;
}
interface Update_Message_Templates_Success {
  type: ProductActionTypes.UPDATE_MESSAGE_TEMPLATES_SUCCESS;
}
interface Update_Message_Templates_Error {
  type: ProductActionTypes.UPDATE_MESSAGE_TEMPLATES_ERROR;
}

//Delete message templates
interface Delete_Message_Templates_Request {
  type: ProductActionTypes.DELETE_MESSAGE_TEMPLATES_REQUEST;
  request: IDeleteMessageTemplateRequest;
}
interface Delete_Message_Templates_Success {
  type: ProductActionTypes.DELETE_MESSAGE_TEMPLATES_SUCCESS;
}
interface Delete_Message_Templates_Error {
  type: ProductActionTypes.DELETE_MESSAGE_TEMPLATES_ERROR;
}

//Get voucher info
interface Get_Voucher_Request {
  type: ProductActionTypes.GET_VOUCHER_REQUEST;
  request: IGetVoucherRequest;
}
interface Get_Voucher_Success {
  type: ProductActionTypes.GET_VOUCHER_SUCCESS;
  response: IGetVoucherResponse;
}
interface Get_Voucher_Error {
  type: ProductActionTypes.GET_VOUCHER_ERROR;
}

//Get product categories
interface Get_Product_Categories_Request {
  type: ProductActionTypes.GET_PRODUCT_CATEGORIES_REQUEST;
}
interface Get_Product_Categories_Success {
  type: ProductActionTypes.GET_PRODUCT_CATEGORIES_SUCCESS;
  response: IGetProductCategoriesResponse;
}
interface Get_Product_Categories_Error {
  type: ProductActionTypes.GET_PRODUCT_CATEGORIES_ERROR;
}

//Physical card cover images
interface Get_Physical_Card_Cover_Images_Request {
  type: ProductActionTypes.GET_PHYSICAL_CARD_COVER_IMAGES_REQUEST;
  request: IGetLitiumImagesByFolderIdRequest;
}
interface Get_Physical_Card_Cover_Images_Success {
  type: ProductActionTypes.GET_PHYSICAL_CARD_COVER_IMAGES_SUCCESS;
  response: IGetLitiumImagesByFolderIdResponse;
}
interface Get_Physical_Card_Cover_Images_Error {
  type: ProductActionTypes.GET_PHYSICAL_CARD_COVER_IMAGES_ERROR;
}

//Physical card back image
interface Get_Physical_Card_Back_Image_Request {
  type: ProductActionTypes.GET_PHYSICAL_CARD_BACK_IMAGES_REQUEST;
  request: IGetLitiumImageBySystemIdRequest;
}
interface Get_Physical_Card_Back_Image_Success {
  type: ProductActionTypes.GET_PHYSICAL_CARD_BACK_IMAGES_SUCCESS;
  response: IGetLitiumImageBySystemIdResponse;
}
interface Get_Physical_Card_Back_Image_Error {
  type: ProductActionTypes.GET_PHYSICAL_CARD_BACK_IMAGES_ERROR;
}

//Physical card middle page image
interface Get_Physical_Card_Middle_Page_Image_Request {
  type: ProductActionTypes.GET_PHYSICAL_CARD_MIDDLE_PAGE_IMAGES_REQUEST;
  request: IGetLitiumImageBySystemIdRequest;
}
interface Get_Physical_Card_Middle_Page_Image_Success {
  type: ProductActionTypes.GET_PHYSICAL_CARD_MIDDLE_PAGE_IMAGES_SUCCESS;
  response: IGetLitiumImageBySystemIdResponse;
}
interface Get_Physical_Card_Middle_Page_Image_Error {
  type: ProductActionTypes.GET_PHYSICAL_CARD_MIDDLE_PAGE_IMAGES_ERROR;
}

//Physical website cover images
interface Get_Physical_Card_Website_Cover_Images_Request {
  type: ProductActionTypes.GET_PHYSICAL_CARD_WEBSITE_COVER_IMAGES_REQUEST;
  request: IGetLitiumImagesByFolderIdRequest;
}
interface Get_Physical_Card_Website_Cover_Images_Success {
  type: ProductActionTypes.GET_PHYSICAL_CARD_WEBSITE_COVER_IMAGES_SUCCESS;
  response: IGetLitiumImagesByFolderIdResponse;
}
interface Get_Physical_Card_Website_Cover_Images_Error {
  type: ProductActionTypes.GET_PHYSICAL_CARD_WEBSITE_COVER_IMAGES_ERROR;
}

//Physical website cover images
interface Get_Image_Selector_Images_Request {
  type: ProductActionTypes.GET_IMAGE_SELECTOR_IMAGES_REQUEST;
  request: IGetLitiumImagesByFolderIdRequest;
}
interface Get_Image_Selector_Images_Success {
  type: ProductActionTypes.GET_IMAGE_SELECTOR_IMAGES_SUCCESS;
  response: IGetLitiumImagesByFolderIdResponse;
}
interface Get_Image_Selector_Images_Error {
  type: ProductActionTypes.GET_IMAGE_SELECTOR_IMAGES_ERROR;
}

interface Download_Documentation_Request {
  type: ProductActionTypes.DOWNLOAD_DOCUMENTATION_REQUEST;
}
interface Download_Documentation_Success {
  type: ProductActionTypes.DOWNLOAD_DOCUMENTATION_SUCCESS;
  response: Blob;
}
interface Download_Documentation_Error {
  type: ProductActionTypes.DOWNLOAD_DOCUMENTATION_ERROR;
}

export const ProductActions = {
  //Get product by id
  GetProductByIdRequest: (request: IGetProductByIdRequest): Get_Product_By_Id_Request => ({
    type: ProductActionTypes.GET_PRODUCT_BY_ID_REQUEST,
    request,
  }),
  GetProductByIdSuccess: (response: IGetProductByIdResponse): Get_Product_By_Id_Success => ({
    type: ProductActionTypes.GET_PRODUCT_BY_ID_SUCCESS,
    response,
  }),
  GetProductByIdError: (): Get_Product_By_Id_Error => ({
    type: ProductActionTypes.GET_PRODUCT_BY_ID_ERROR,
  }),

  //Get all products
  GetProductsRequest: (request: IGetProductsRequest): Get_Products_Request => ({
    type: ProductActionTypes.GET_PRODUCTS_REQUEST,
    request,
  }),
  GetProductsSuccess: (response: IGetProductsResponse): Get_Products_Success => ({
    type: ProductActionTypes.GET_PRODUCTS_SUCCESS,
    response,
  }),
  GetProductsError: (): Get_Products_Error => ({
    type: ProductActionTypes.GET_PRODUCTS_ERROR,
  }),

  //Set
  SetSelectedProduct: (request: ISetSelectedProduct): Set_Selected_Product => ({
    type: ProductActionTypes.SET_SELECTED_PRODUCT,
    request,
  }),
  SetSelectedProductCategory: (request: ISetSelectedProductCategory): Set_Selected_Product_Category => ({
    type: ProductActionTypes.SET_SELECTED_PRODUCT_CATEGORY,
    request,
  }),

  ResetReducer: (): Reset_Reducer => ({
    type: ProductActionTypes.RESET_REDUCER,
  }),

  //Get preview PDF
  GetPreviewPDFRequest: (request: IGetPreviewPDFRequest): Get_Preview_PDF_Request => ({
    type: ProductActionTypes.GET_PREVIEW_PDF_REQUEST,
    request,
  }),
  GetPreviewPDFSuccess: (response: Blob): Get_Preview_PDF_Success => ({
    type: ProductActionTypes.GET_PREVIEW_PDF_SUCCESS,
    response,
  }),
  GetPreviewPDFError: (): Get_Preview_PDF_Error => ({
    type: ProductActionTypes.GET_PREVIEW_PDF_ERROR,
  }),

  //Create message templates
  CreateMessageTemplateRequest: (request: ICreateMessageTemplateRequest): Create_Message_Templates_Request => ({
    type: ProductActionTypes.CREATE_MESSAGE_TEMPLATES_REQUEST,
    request,
  }),
  CreateMessageTemplateSuccess: (): Create_Message_Templates_Success => ({
    type: ProductActionTypes.CREATE_MESSAGE_TEMPLATES_SUCCESS,
  }),
  CreateMessageTemplateError: (): Create_Message_Templates_Error => ({
    type: ProductActionTypes.CREATE_MESSAGE_TEMPLATES_ERROR,
  }),

  //Get message templates
  GetMessageTemplatesRequest: (request: IGetMessageTemplatesRequest): Get_Message_Templates_Request => ({
    type: ProductActionTypes.GET_MESSAGE_TEMPLATES_REQUEST,
    request,
  }),
  GetMessageTemplateSuccess: (response: IGetMessageTemplatesResponse): Get_Message_Templates_Success => ({
    type: ProductActionTypes.GET_MESSAGE_TEMPLATES_SUCCESS,
    response,
  }),
  GetMessageTemplatesError: (): Get_Message_Templates_Error => ({
    type: ProductActionTypes.GET_MESSAGE_TEMPLATES_ERROR,
  }),

  //Update message templates
  UpdateMessageTemplatesRequest: (request: IUpdateMessageTemplateRequest): Update_Message_Templates_Request => ({
    type: ProductActionTypes.UPDATE_MESSAGE_TEMPLATES_REQUEST,
    request,
  }),
  UpdateMessageTemplateSuccess: (): Update_Message_Templates_Success => ({
    type: ProductActionTypes.UPDATE_MESSAGE_TEMPLATES_SUCCESS,
  }),
  UpdateMessageTemplatesError: (): Update_Message_Templates_Error => ({
    type: ProductActionTypes.UPDATE_MESSAGE_TEMPLATES_ERROR,
  }),

  //Delete message templates
  DeleteMessageTemplateRequest: (request: IDeleteMessageTemplateRequest): Delete_Message_Templates_Request => ({
    type: ProductActionTypes.DELETE_MESSAGE_TEMPLATES_REQUEST,
    request,
  }),
  DeleteMessageTemplateSuccess: (): Delete_Message_Templates_Success => ({
    type: ProductActionTypes.DELETE_MESSAGE_TEMPLATES_SUCCESS,
  }),
  DeleteMessageTemplateError: (): Delete_Message_Templates_Error => ({
    type: ProductActionTypes.DELETE_MESSAGE_TEMPLATES_ERROR,
  }),

  //Get voucher
  GetVoucherRequest: (request: IGetVoucherRequest): Get_Voucher_Request => ({
    type: ProductActionTypes.GET_VOUCHER_REQUEST,
    request,
  }),
  GetVoucherSuccess: (response: IGetVoucherResponse): Get_Voucher_Success => ({
    type: ProductActionTypes.GET_VOUCHER_SUCCESS,
    response,
  }),
  GetVoucherError: (): Get_Voucher_Error => ({
    type: ProductActionTypes.GET_VOUCHER_ERROR,
  }),

  //Get product categories
  GetProductCategoriesRequest: (): Get_Product_Categories_Request => ({
    type: ProductActionTypes.GET_PRODUCT_CATEGORIES_REQUEST,
  }),
  GetProductCategoriesSuccess: (response: IGetProductCategoriesResponse): Get_Product_Categories_Success => ({
    type: ProductActionTypes.GET_PRODUCT_CATEGORIES_SUCCESS,
    response,
  }),
  GetProductCategoriesError: (): Get_Product_Categories_Error => ({
    type: ProductActionTypes.GET_PRODUCT_CATEGORIES_ERROR,
  }),

  //Physical card cover images
  GetPhysicalCardCoverImagesRequest: (request: IGetLitiumImagesByFolderIdRequest): Get_Physical_Card_Cover_Images_Request => ({
    type: ProductActionTypes.GET_PHYSICAL_CARD_COVER_IMAGES_REQUEST,
    request,
  }),
  GetPhysicalCardCoverImagesSuccess: (response: IGetLitiumImagesByFolderIdResponse): Get_Physical_Card_Cover_Images_Success => ({
    type: ProductActionTypes.GET_PHYSICAL_CARD_COVER_IMAGES_SUCCESS,
    response,
  }),
  GetPhysicalCardCoverImagesError: (): Get_Physical_Card_Cover_Images_Error => ({
    type: ProductActionTypes.GET_PHYSICAL_CARD_COVER_IMAGES_ERROR,
  }),

  //Physical card back image
  GetPhysicalCardBackImageRequest: (request: IGetLitiumImageBySystemIdRequest): Get_Physical_Card_Back_Image_Request => ({
    type: ProductActionTypes.GET_PHYSICAL_CARD_BACK_IMAGES_REQUEST,
    request,
  }),
  GetPhysicalCardBackImageSuccess: (response: IGetLitiumImageBySystemIdResponse): Get_Physical_Card_Back_Image_Success => ({
    type: ProductActionTypes.GET_PHYSICAL_CARD_BACK_IMAGES_SUCCESS,
    response,
  }),
  GetPhysicalCardBackImageError: (): Get_Physical_Card_Back_Image_Error => ({
    type: ProductActionTypes.GET_PHYSICAL_CARD_BACK_IMAGES_ERROR,
  }),

  //Physical card middle page image
  GetPhysicalCardMiddlePageImageRequest: (request: IGetLitiumImageBySystemIdRequest): Get_Physical_Card_Middle_Page_Image_Request => ({
    type: ProductActionTypes.GET_PHYSICAL_CARD_MIDDLE_PAGE_IMAGES_REQUEST,
    request,
  }),
  GetPhysicalCardMiddlePageImageSuccess: (response: IGetLitiumImageBySystemIdResponse): Get_Physical_Card_Middle_Page_Image_Success => ({
    type: ProductActionTypes.GET_PHYSICAL_CARD_MIDDLE_PAGE_IMAGES_SUCCESS,
    response,
  }),
  GetPhysicalCardMiddlePageImageError: (): Get_Physical_Card_Middle_Page_Image_Error => ({
    type: ProductActionTypes.GET_PHYSICAL_CARD_MIDDLE_PAGE_IMAGES_ERROR,
  }),

  //Physical card back images
  GetPhysicalCardWebsiteCoverImagesRequest: (
    request: IGetLitiumImagesByFolderIdRequest
  ): Get_Physical_Card_Website_Cover_Images_Request => ({
    type: ProductActionTypes.GET_PHYSICAL_CARD_WEBSITE_COVER_IMAGES_REQUEST,
    request,
  }),
  GetPhysicalCardWebsiteCoverImagesSuccess: (
    response: IGetLitiumImagesByFolderIdResponse
  ): Get_Physical_Card_Website_Cover_Images_Success => ({
    type: ProductActionTypes.GET_PHYSICAL_CARD_WEBSITE_COVER_IMAGES_SUCCESS,
    response,
  }),
  GetPhysicalCardWebsiteCoverImagesError: (): Get_Physical_Card_Website_Cover_Images_Error => ({
    type: ProductActionTypes.GET_PHYSICAL_CARD_WEBSITE_COVER_IMAGES_ERROR,
  }),

  //Message component image selector images
  GetImageSelectorImagesRequest: (request: IGetLitiumImagesByFolderIdRequest): Get_Image_Selector_Images_Request => ({
    type: ProductActionTypes.GET_IMAGE_SELECTOR_IMAGES_REQUEST,
    request,
  }),
  GetImageSelectorImagesSuccess: (response: IGetLitiumImagesByFolderIdResponse): Get_Image_Selector_Images_Success => ({
    type: ProductActionTypes.GET_IMAGE_SELECTOR_IMAGES_SUCCESS,
    response,
  }),
  GetImageSelectorImagesError: (): Get_Image_Selector_Images_Error => ({
    type: ProductActionTypes.GET_IMAGE_SELECTOR_IMAGES_ERROR,
  }),

  //Download documentation
  DownloadDocumentationRequest: (): Download_Documentation_Request => ({
    type: ProductActionTypes.DOWNLOAD_DOCUMENTATION_REQUEST,
  }),
  DownloadDocumentationSuccess: (response: Blob): Download_Documentation_Success => ({
    type: ProductActionTypes.DOWNLOAD_DOCUMENTATION_SUCCESS,
    response,
  }),
  DownloadDocumentationError: (): Download_Documentation_Error => ({
    type: ProductActionTypes.DOWNLOAD_DOCUMENTATION_ERROR,
  }),
};
