import { CompanyActionTypes } from './actionTypes';
import { IGetCompanyDetailsRequest, IGetCompanyDetailsResponse } from './types';

//Validate email for sign in
interface Get_Company_Details_Request {
  type: CompanyActionTypes.GET_COMPANY_DETAILS_REQUEST;
  request: IGetCompanyDetailsRequest;
}
interface Get_Company_Details_Success {
  type: CompanyActionTypes.GET_COMPANY_DETAILS_SUCCESS;
  response: IGetCompanyDetailsResponse;
}
interface Get_Company_Details_Error {
  type: CompanyActionTypes.GET_COMPANY_DETAILS_ERROR;
}

export const CompanyActions = {
  //Get company details
  GetCompanyDetailsRequest: (
    request: IGetCompanyDetailsRequest
  ): Get_Company_Details_Request => ({
    type: CompanyActionTypes.GET_COMPANY_DETAILS_REQUEST,
    request,
  }),
  GetCompanyDetailsSuccess: (
    response: IGetCompanyDetailsResponse
  ): Get_Company_Details_Success => ({
    type: CompanyActionTypes.GET_COMPANY_DETAILS_SUCCESS,
    response,
  }),
  GetCompanyDetailsError: (): Get_Company_Details_Error => ({
    type: CompanyActionTypes.GET_COMPANY_DETAILS_ERROR,
  }),
};
