import { makeStyles } from "@mui/styles";

export const VoucherStyle = makeStyles({
  image: {
    objectFit: "cover",
    width: "100vw",
    maxWidth: "100%",
    objectPosition: "50% 50%",
    backgroundRepeat: "no-repeat",
  },
  codeContainer: {
    height: 36,
    textAlign: "center",
    margin: "auto",
    backgroundColor: "rgba(255, 255, 255, 0.5)",
    border: "2px solid transparent",
    cursor: "pointer",
    "&:hover": {
      border: "2px solid orange",
    },
    fontFamily: "Mulish",
  },
  code: { lineHeight: 0.7, width: "100%", fontSize: "0.8rem", paddingTop: 6 },
  codeClickToCopy: {
    width: "100%",
    fontSize: "0.65rem",
  },
  expireDate: {
    top: 400,
    width: "100%",
    margin: "auto",
    paddingTop: 20,
  },
  url: {
    width: "100%",
    margin: "auto",
  },
  message: {
    paddingTop: 10,
    width: "95%",
    margin: "auto",
    whiteSpace: "pre-wrap",
    wordBreak: "break-word",
    fontSize: "0.8rem",
    fontFamily: "Mulish",
  },
});
