import { TransactionActionTypes } from "./actionTypes";
import { Reducer } from "redux";
import { TransactionRow } from "./types";

interface ITransactionInitState {
  isLoading: boolean;
  transactions: TransactionRow[];
}

const transactionInitState: ITransactionInitState = {
  isLoading: false,
  transactions: [] as TransactionRow[],
};

const TransactionReducer: Reducer<ITransactionInitState> = (state = transactionInitState, action) => {
  switch (action.type) {
    case TransactionActionTypes.GET_TRANSACTIONS_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case TransactionActionTypes.GET_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        transactions: action.response.transactions,
      };
    case TransactionActionTypes.GET_TRANSACTIONS_ERROR:
      return {
        ...state,
        isLoading: false,
      };
    default:
      return state;
  }
};

export default TransactionReducer;
