import { blue } from "@mui/material/colors";
import { createTheme, responsiveFontSizes } from "@mui/material/styles";
import "typeface-roboto";
import "typeface-mulish";

const globalTheme = createTheme({
  shape: {
    borderRadius: 0,
  },
  components: {
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: "#000",
          color: "#fff",
        },
        arrow: {
          color: "#000",
        },
      },
    },
    MuiButtonGroup: {
      styleOverrides: {
        grouped: {
          color: "#000",
          borderColor: "black",
          "&:hover": {
            borderColor: "#000",
          },
        },
      },
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
  palette: {
    primary: {
      main: blue[500],
    },
    secondary: {
      main: "#000",
    },
  },
  typography: {
    htmlFontSize: 16,
    fontFamily: "mulish",
    h1: {
      fontSize: "2.4rem", //38px
      fontWeight: "bold",
      fontFamily: "Butler",
      color: "#000",
    },
    h2: {
      fontSize: "2rem", //32px
      fontWeight: "bold",
      fontFamily: "Butler",
      color: "#000",
    },
    h3: {
      fontSize: "1.75rem", //28px
      fontWeight: "bold",
      fontFamily: "Butler",
      color: "#000",
    },
    h4: {
      fontSize: "1.5rem", //24px
      fontWeight: "bold",
      fontFamily: "Butler",
      color: "#000",
    },
    h5: {
      fontSize: "1.375rem", //22px
      fontWeight: "bold",
      fontFamily: "Butler",
      color: "#000",
    },
    h6: {
      fontSize: "1rem", //16px
      fontWeight: "bold",
      fontFamily: "Mulish",
      color: "#000",
    },
    body1: {
      fontSize: "1rem",
      color: "#000",
      fontFamily: ["mulish", "roboto"].join(","),
    },
    body2: {
      fontSize: "0.75rem",
      color: "#000",
      fontFamily: ["mulish", "roboto"].join(","),
    },
  },
});

export const theme = responsiveFontSizes(globalTheme);
