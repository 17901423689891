import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Grid, Typography, useMediaQuery } from "@mui/material";
import { RouteStyles } from "./styles";
import { Environment, LanguageCode, SystemPart } from "../../Utils/enums/enums";
import MenuBar from "../Menu/MenuBar";
import { useTranslation } from "react-i18next";
import { LabelKeys } from "../../Utils/enums/labelKeys";
import { theme } from "../../appTheme";
import { ResellerActions } from "../../Modules/Reseller/actions";
import { IGetResellerByCodeRequest, IGetResellerByCodeResponse, IGetResellerLogoByCodeRequest } from "../../Modules/Reseller/types";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store";
import { CookieConstants } from "../../Utils/Labels/types";
import Cookies from "js-cookie";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { IGetProductsRequest, IProduct, ProductCategory } from "../../Modules/Products/types";
import { GetLitiumImageUrl } from "../../Utils/extensions";
import { ImageKeys } from "../../Utils/enums/imageKeys";
import { ProductActions } from "../../Modules/Products/actions";
import { IsAuth, IsResellerAuth } from "../../Utils/authentication";
import { AuthenticationActions } from "../../Modules/Authentication/actions";
import { GetCurrentLanguageAsCodeFromLocalStorage, GetCurrentLanguageAsText } from "../../Utils/types";

function NavigationWrapper() {
  const classes = RouteStyles();
  const dispatch = useDispatch();
  const { t: translate } = useTranslation();
  // const [isGiftCardMenuOpen, setIsGiftCardMenuOpen] = useState(false);
  const systemPart: SystemPart = Number(Cookies.get(CookieConstants.SystemPart));
  const isMobile = useMediaQuery(theme.breakpoints.only("xs"));
  const location = useLocation();
  let transperantNavigation = location.pathname == `/se/` || location.pathname == `/se/${translate(LabelKeys.HowItWorksUrl)}`;
  const navigate = useNavigate();
  const [giftcards, setGiftcards] = useState([] as IProduct[]);
  const isSystemAdmin = IsAuth() && location.pathname.includes(`/${translate(LabelKeys.AdminUrl)}/${translate(LabelKeys.OrderUrl)}`);
  const isResellerAdmin =
    IsResellerAuth() &&
    location.pathname.includes(`/${translate(LabelKeys.ResellerUrl)}/${translate(LabelKeys.AdminUrl)}/${translate(LabelKeys.OrderUrl)}`);

  const isResellerConnected = Cookies.get(CookieConstants.GBVReseller)?.length > 0 && systemPart == SystemPart.Company;
  const [logo, setLogo] = useState("");

  const reducerState = useSelector(
    (s: RootState) => {
      return {
        reseller: s.reseller,
        order: s.order,
        product: s.product,
        authentication: s.authentication,
      };
    },
    (prev, next) =>
      prev.reseller.activeReseller === next.reseller.activeReseller &&
      prev.reseller.coupledResellerLogotype === next.reseller.coupledResellerLogotype &&
      prev.reseller.coupledResellerLogotypeFiletype === next.reseller.coupledResellerLogotypeFiletype &&
      prev.order.environmentState.systemPart === next.order.environmentState.systemPart &&
      prev.product.products === next.product.products &&
      prev.product.productCategories === next.product.productCategories &&
      prev.authentication.isResellerSignedIn === next.authentication.isResellerSignedIn
  );

  useEffect(() => {
    const resellerCodeCookieValue = Cookies.get(CookieConstants.GBVReseller);
    if (resellerCodeCookieValue != undefined && resellerCodeCookieValue?.length > 0 && systemPart == SystemPart.Company) {
      dispatch(ResellerActions.GetResellerLogoByCodeRequest({ resellerCode: resellerCodeCookieValue }));
      dispatch(
        ResellerActions.GetResellerByCodeRequest({
          code: Cookies.get(CookieConstants.GBVReseller),
        } as IGetResellerByCodeRequest)
      );
    } else {
      setLogo(GetLitiumImageUrl(ImageKeys.WhiteLogo, ""));
    }

    if (
      reducerState.product.products?.length <= 0 &&
      (systemPart == SystemPart.Private || systemPart == SystemPart.Company || isSystemAdmin || isResellerAdmin)
    ) {
      dispatch(
        ProductActions.GetProductsRequest({
          language: GetLanguage(),
          systemPart: isSystemAdmin || isResellerAdmin ? SystemPart.Company : systemPart,
          productCategoryId: ProductCategory.GiftCardGMG,
        } as IGetProductsRequest)
      );
    }
  }, []);

  useEffect(() => {
    const resellerCookie = Cookies.get(CookieConstants.GBVReseller);
    if (resellerCookie !== undefined && reducerState.reseller.coupledResellerLogotype?.length <= 0) {
      dispatch(
        ResellerActions.GetResellerLogoByCodeRequest({
          resellerCode: Cookies.get(CookieConstants.GBVReseller),
        } as IGetResellerLogoByCodeRequest)
      );
    }

    if (resellerCookie !== undefined && systemPart == SystemPart.Company) {
      dispatch(
        ResellerActions.GetResellerByCodeRequest({
          code: Cookies.get(CookieConstants.GBVReseller),
        } as IGetResellerByCodeRequest)
      );
    }
  }, [systemPart]);

  useEffect(() => {
    const resellerCookie = Cookies.get(CookieConstants.GBVReseller);

    if (resellerCookie !== undefined && systemPart == SystemPart.Company) {
      dispatch(
        ResellerActions.GetResellerByCodeRequest({
          code: Cookies.get(CookieConstants.GBVReseller),
        } as IGetResellerByCodeRequest)
      );
    }
  }, [isResellerConnected]);

  useEffect(() => {
    if (reducerState.reseller.coupledResellerLogotype?.length > 0) {
      setLogo(reducerState.reseller.coupledResellerLogotype);
    }
  }, [reducerState.reseller.coupledResellerLogotype]);

  useEffect(() => {
    if (giftcards?.length <= 0 && reducerState.product.products.find((x) => x.category == ProductCategory.GiftCardGMG)) {
      setGiftcards(reducerState.product.products);
    }
  }, [reducerState.product.products]);

  function GetLanguage() {
    if (isSystemAdmin) {
      return reducerState.reseller.adminSelectedResellerCountryOption?.value != LanguageCode.Unknown
        ? reducerState.reseller.adminSelectedResellerCountryOption?.value
        : LanguageCode.SE;
    } else return GetCurrentLanguageAsCodeFromLocalStorage();
  }

  function GetSystemPartLabel(): React.ReactNode {
    var type = Number(Cookies.get(CookieConstants.SystemPart));
    if (type === SystemPart.Private) return translate(LabelKeys.Private);
    else {
      return translate(LabelKeys.Company);
    }
  }

  return (
    <Grid
      container
      item
      xs={12}
      sm={12}
      md={12}
      lg={12}
      xl={12}
      className={transperantNavigation ? classes.containerTransparent : classes.container}
      justifyContent={"center"}
    >
      <div
        id="envIdentityBar"
        className={
          process.env.NODE_ENV == Environment.Development
            ? classes.development
            : process.env.REACT_APP_ENVIRONMENT == Environment.Test
            ? classes.test
            : classes.production
        }
        style={{ width: "100%" }}
      >
        <Typography variant="h2" style={{ color: "#000" }}>
          {process.env.NODE_ENV == Environment.Development ? "DEVELOPMENT" : process.env.REACT_APP_ENVIRONMENT?.toUpperCase()}
        </Typography>
      </div>
      <Grid item xs={12} sm={9} md={10} lg={9} xl={9} style={{ display: "flex" }} justifyContent={"center"}>
        {logo && (
          <div className={classes.logoContainer}>
            <Link to={`/${GetCurrentLanguageAsText()}/`}>
              <img height={isMobile ? 90 : 100} width={isMobile ? 180 : 200} className={classes.logo} src={logo} />
            </Link>
          </div>
        )}
        <div id="navLinksContainer" className={classes.linkbarContainer} style={{ width: "100%" }}>
          {!isMobile && (
            <ul
              className={classes.linkBar}
              style={{
                display: "inline-flex",
                margin: "auto",
              }}
            >
              <li
                id="contactLink"
                className={transperantNavigation ? classes.linksTransparent : classes.links}
                onClick={() => {
                  if (isResellerConnected) {
                    navigate(`/${GetCurrentLanguageAsText()}/${translate(LabelKeys.ContactUrl)}`);
                  }
                }}
                style={{ cursor: "pointer" }}
              >
                {isResellerConnected && (
                  <Typography variant="body1" style={{ color: transperantNavigation ? "#fff" : "#000" }}>
                    {translate(LabelKeys.Contact)}
                  </Typography>
                )}
                {!isResellerConnected && (
                  <a
                    style={{
                      color: transperantNavigation ? "#fff" : "#000",
                      textDecoration: "none",
                      fontSize: "1rem",
                      fontFamily: "mulish",
                    }}
                    href="https://support.giftsbyvinga.com/hc/sv/requests/new"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {translate(LabelKeys.Contact)}
                  </a>
                )}
              </li>
              <li
                id="faqPageLink"
                className={transperantNavigation ? classes.linksTransparent : classes.links}
                onClick={() =>
                  Cookies.get(CookieConstants.GBVReseller)?.length > 0 && systemPart == SystemPart.Company
                    ? navigate(`/${GetCurrentLanguageAsText()}/${translate(LabelKeys.FAQUrl)}`)
                    : window.open("https://support.giftsbyvinga.com/hc/sv", "_blank")
                }
                style={{ cursor: "pointer" }}
              >
                <Typography variant="body1" style={{ color: transperantNavigation ? "#fff" : "#000" }}>
                  {translate(LabelKeys.FAQPageTitle)}
                </Typography>
              </li>
            </ul>
          )}
        </div>
        {!isMobile && !isSystemAdmin && !isResellerAdmin && systemPart == SystemPart.Company
          ? Cookies.get(CookieConstants.GBVReseller) !== undefined &&
            Cookies.get(CookieConstants.GBVReseller)?.length > 0 && (
              <div style={{ display: "flex", paddingBottom: 20 }}>
                <div>
                  <div
                    className={transperantNavigation ? classes.resellerTransparent : classes.reseller}
                    onClick={() => {
                      Cookies.remove(CookieConstants.GBVReseller);
                      dispatch(ResellerActions.GetResellerByCodeSuccess({} as IGetResellerByCodeResponse));
                    }}
                  >
                    <div style={{ margin: "auto", display: "flex" }}>
                      <div>
                        <Typography color={transperantNavigation ? "#fff" : "#000"} style={{ fontFamily: "Mulish" }}>
                          Din återförsäljare: {reducerState.reseller?.activeReseller?.companyName}
                        </Typography>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )
          : ""}
        {!isMobile && !isSystemAdmin && !isResellerAdmin ? (
          <div style={{ display: "flex", paddingBottom: 20 }}>
            <div>
              <div
                className={transperantNavigation ? classes.customerTypeTransparent : classes.customerType}
                onClick={() => dispatch(AuthenticationActions.SetIsSystemPartDialogOpenRequest(true))}
              >
                <div style={{ margin: "auto", display: "flex" }}>
                  <div>
                    <Typography color={transperantNavigation ? "#fff" : "#000"} style={{ fontFamily: "Mulish" }}>
                      {GetSystemPartLabel()}
                    </Typography>
                  </div>
                  <div style={{ paddingTop: 4, paddingLeft: 3 }}>
                    <KeyboardArrowDownIcon style={{ color: transperantNavigation ? "#fff" : "#000" }} fontSize="small" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : isMobile && (isSystemAdmin || isResellerAdmin) ? (
          ""
        ) : (
          <div className={classes.menubar}>
            <MenuBar
              id="navMenubar"
              systemPart={reducerState.order.environmentState?.systemPart}
              contrastMode={transperantNavigation}
              setDialogIsOpen={() => dispatch(AuthenticationActions.SetIsSystemPartDialogOpenRequest(true))}
            />
          </div>
        )}
      </Grid>
    </Grid>
  );
}

export default NavigationWrapper;
