import React from "react";
import { useSelector, useDispatch } from "react-redux";
import SnackbarActions from "./actions";
import { RootState } from "../../store";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert, { AlertProps } from "@mui/material/Alert";
import { useMediaQuery } from "@mui/material";
import { theme } from "../../appTheme";

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export function CSSnackbar() {
  const dispatch = useDispatch();
  const isMobile = useMediaQuery(theme.breakpoints.only("xs"));
  const isSm = useMediaQuery(theme.breakpoints.only("sm"));
  const isMd = useMediaQuery(theme.breakpoints.only("md"));
  const { snackbar: snackbarState } = useSelector(
    (s: RootState) => {
      return {
        snackbar: s.snackbar,
      };
    },
    (prev, next) => prev.snackbar.isOpen === next.snackbar.isOpen
  );

  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }

    dispatch(SnackbarActions.Hide());
  };

  return (
    <Snackbar
      style={{ bottom: isMobile || isSm || isMd ? 100 : "" }}
      open={snackbarState.isOpen}
      autoHideDuration={6000}
      onClose={(e, reason) => handleClose(undefined, reason)}
    >
      <Alert onClose={handleClose} severity={snackbarState.severity} sx={{ width: "100%" }}>
        {snackbarState.message}
      </Alert>
    </Snackbar>
  );
}

export default CSSnackbar;
