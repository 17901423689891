import React, { useState } from "react";
import Grid from "@mui/material/Grid/Grid";
import { useTranslation } from "react-i18next";
import CSTextField from "../../../../../components/Input/Textfield/CSTextField";
import { LabelKeys } from "../../../../../Utils/enums/labelKeys";
import { FieldNames } from "../../../../../Utils/types";
import { IReseller } from "../../../types";
import Button from "@mui/material/Button/Button";
import { ValidateCustomerNumber as ValidateCustomerNumber, ValidateDeliveryEmails } from "../../../../../Utils/extensions";
import { IsLongerThanOrEqualToLength } from "../../../../../Utils/validations";
import { useMediaQuery } from "@mui/material";
import { theme } from "../../../../../appTheme";

interface IAddResellerState {
  companyName: string;
  customerNumber: string;
  discount: number;
  contactEmails: string;
}

interface IAddResellerErrorState {
  companyName: boolean;
  customerNumber: boolean;
  contactEmails: boolean;
}

interface IAddReseller {
  onSave: (reseller: IReseller) => void;
  onClose: () => void;
}

export default function AddReseller(props: IAddReseller) {
  const { t: translate } = useTranslation();
  const [addResellerState, setAddResellerState] = useState({
    companyName: "",
    contactEmails: "",
    customerNumber: "",
    discount: 0,
  } as IAddResellerState);
  const [addResellerErrorState, setAddResellerErrorState] = useState({} as IAddResellerErrorState);
  const isMobile = useMediaQuery(theme.breakpoints.only("xs"));

  function handleFieldOnChange(fieldName: FieldNames, value: string | number | string[]) {
    setAddResellerState((state) => ({
      ...state,
      [fieldName]: value,
    }));
  }

  function handleFieldErrorOnChange(fieldName: FieldNames, value: boolean) {
    setAddResellerErrorState((state) => ({
      ...state,
      [fieldName]: value,
    }));
  }

  function handleSaveResellers() {
    const valid = ValidateAllFields();

    if (valid) {
      props.onSave({
        customerNumber: addResellerState.customerNumber,
        companyName: addResellerState.companyName,
        discount: addResellerState.discount,
        contactEmails: addResellerState.contactEmails,
      } as IReseller);
    }
  }

  function ValidateAllFields() {
    let valid: boolean = true;
    if (!ValidateCustomerNumber(addResellerState.customerNumber)) {
      handleFieldErrorOnChange(FieldNames.customerNumber, true);
      valid = false;
    } else {
      handleFieldErrorOnChange(FieldNames.customerNumber, false);
    }

    if (!IsLongerThanOrEqualToLength(addResellerState.companyName, 1)) {
      handleFieldErrorOnChange(FieldNames.companyName, true);
      valid = false;
    } else {
      handleFieldErrorOnChange(FieldNames.companyName, false);
    }

    if (!ValidateDeliveryEmails(addResellerState.contactEmails)) {
      handleFieldErrorOnChange(FieldNames.contactEmails, true);
      valid = false;
    } else {
      handleFieldErrorOnChange(FieldNames.contactEmails, false);
    }

    return valid;
  }

  return (
    <Grid container item xs={12} sm={12} md={12} lg={12} xl={12} style={{ overflowY: "auto", width: "100%", backgroundColor: "#e3e1de" }}>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ paddingTop: 20 }}>
        <CSTextField
          id="customerNumber"
          label={translate(LabelKeys.CustomerNumber)}
          type="text"
          placeholder={`${translate(LabelKeys.CustomerNumber)}`}
          value={addResellerState.customerNumber ?? ""}
          onChange={(e) => handleFieldOnChange(FieldNames.customerNumber, e.target.value)}
          error={addResellerErrorState.customerNumber}
          helperText={translate(LabelKeys.InvalidCustomerNumber)}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ paddingTop: 20 }}>
        <CSTextField
          id="companyName"
          label={translate(LabelKeys.CompanyName)}
          type="text"
          placeholder={`${translate(LabelKeys.CompanyName)}`}
          value={addResellerState.companyName ?? ""}
          onChange={(e) => handleFieldOnChange(FieldNames.companyName, e.target.value)}
          error={addResellerErrorState.companyName}
          helperText={translate(LabelKeys.InvalidCompanyName)}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ paddingTop: 20 }}>
        <CSTextField
          id="discount"
          label={translate(LabelKeys.Discount)}
          placeholder={`${translate(LabelKeys.Discount)}`}
          value={addResellerState.discount ?? ""}
          type="text"
          onChange={(e) => {
            if (Number(e.target.value) > 100) {
              handleFieldOnChange(FieldNames.discount, addResellerState.discount);
              return;
            }

            if (isNaN(Number(e.target.value))) {
              handleFieldOnChange(FieldNames.discount, addResellerState.discount);
              return;
            }

            handleFieldOnChange(FieldNames.discount, e.target.value);
          }}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ paddingTop: 20 }}>
        <CSTextField
          id="contactList"
          label={translate(LabelKeys.ContactEmails)}
          placeholder={`${translate(LabelKeys.ContactEmails)}`}
          multiline
          rows={10}
          type="email"
          value={addResellerState.contactEmails ?? ""}
          onChange={(e) => {
            handleFieldOnChange(FieldNames.contactEmails, e.target.value);
          }}
          error={addResellerErrorState.contactEmails}
          helperText={translate(LabelKeys.InvalidEmailFormat)}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={6} lg={6} xl={6} style={{ paddingTop: 31, paddingRight: isMobile ? 0 : 8 }}>
        <Button
          id="saveBtn"
          style={{
            height: 46,
            width: "100%",
            cursor: "pointer",
            color: "#fff",
            borderRadius: 0,
            backgroundColor: "#000",
          }}
          onClick={() => handleSaveResellers()}
        >
          <div style={{ margin: "auto", textTransform: "none" }}>{`${translate(LabelKeys.Save)}`}</div>
        </Button>
      </Grid>
      <Grid item xs={12} sm={6} md={6} lg={6} xl={6} style={{ paddingTop: 31, paddingLeft: isMobile ? 0 : 8 }}>
        <Button
          id="cancelBtn"
          style={{
            height: 46,
            width: "100%",
            cursor: "pointer",
            color: "#fff",
            borderRadius: 0,
            backgroundColor: "#000",
          }}
          onClick={() => {
            props.onClose();
          }}
        >
          <div style={{ margin: "auto", textTransform: "none" }}>{`${translate(LabelKeys.Cancel)}`}</div>
        </Button>
      </Grid>
    </Grid>
  );
}
