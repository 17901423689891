import React, { useEffect, useState } from "react";
import { AppStyles } from "./AppStyles";
import { withTranslation, useTranslation } from "react-i18next";
import { LabelKeys } from "./Utils/enums/labelKeys";
import TermsOfUse from "./views/TermsOfUse/TermsOfUse";
import OrderConfirmation from "./views/OrderConfirmation/OrderConfirmation";
import FAQ from "./views/FAQ/FAQ";
import HowItWorks from "./views/HowItWorks/HowItWorks";
import Admin from "./views/Admin/Admin";
import SignIn from "./views/Admin/SignIn";
import { IsAuth, IsResellerAuth } from "./Utils/authentication";
import SomethingWentWrong from "./views/ErrorPages/SomethingWentWrong";
import { Environment } from "./Utils/enums/enums";
import Home from "./views/Home/Home";
import Voucher from "./views/Voucher/Voucher";
import ResellerSignin from "./views/Reseller/ResellerSignIn";
import ResellerAdmin from "./views/Reseller/ResellerAdmin";
import ResellerRedirect from "./views/Reseller/ResellerRedirect";
import CSCookieConsent from "./components/Cookie/CSCookieConsent";
import CSFooter from "./components/Footer/CSFooter";
import CSSnackbar from "./components/Snackbar/SnackBar";
import { Route, Routes, useLocation } from "react-router-dom";
import { Navigate } from "react-router-dom";
import Order from "./views/Order/Order";
import CSNextPrevStepMobile from "./components/Stepper/CSNextPrevStepMobile";
import { useMediaQuery } from "@mui/material";
import { theme } from "./appTheme";
import NavigationWrapper from "./components/Navigation/NavigationWrapper";
import Contact from "./views/Contact/Contact";
import SystemPartDialog from "./components/SystemPart/SystemPartDialog";
import TagManager from "react-gtm-module";
import { GTMID } from "./Utils/Labels/types";
import { useSelector } from "react-redux";
import { RootState } from "./store";
import ResellerBranding from "./views/Reseller/Subviews/ResellerBranding";
import ResellerOrder from "./views/Reseller/Subviews/ResellerOrder";
import ResellerContactPageInformation from "./views/Reseller/Subviews/ResellerContactPageInformation";
import ResellerSettings from "./views/Reseller/Subviews/ResellerSettings";
import AdminOrder from "./views/Admin/SubViews/Order/AdminOrder";
import AdminInvoice from "./views/Admin/SubViews/Invoice/AdminInvoice";
import AdminReseller from "./views/Admin/SubViews/Reseller/AdminReseller";
import AdminTemplate from "./views/Admin/SubViews/Template/AdminTemplate";
import AdminExternalSystem from "./views/Admin/SubViews/ExternalSystem/AdminExternalSystem";
import { GetCurrentLanguageAsText } from "./Utils/types";

function App() {
  const classes = AppStyles();
  const { t: translate } = useTranslation();
  const isMobile = useMediaQuery(theme.breakpoints.only("xs"));
  const isSm = useMediaQuery(theme.breakpoints.only("sm"));
  const isMd = useMediaQuery(theme.breakpoints.only("md"));
  const location = useLocation();
  const isAdminMenu =
    (IsResellerAuth() &&
      location.pathname.includes(`/${GetCurrentLanguageAsText()}/${translate(LabelKeys.ResellerUrl)}/${translate(LabelKeys.AdminUrl)}`)) ||
    (IsAuth() && location.pathname.includes(`/${GetCurrentLanguageAsText()}/${translate(LabelKeys.AdminUrl)}`));

  const showMobileStepper = location.pathname.endsWith(`/${translate(LabelKeys.OrderUrl)}`);

  const reducerState = useSelector(
    (s: RootState) => {
      return {
        order: s.order,
      };
    },
    (prev, next) =>
      prev.order.stepperThreeState.isPhysicalCardEditorPopupOpen === next.order.stepperThreeState.isPhysicalCardEditorPopupOpen
  );

  useEffect(() => {}, [showMobileStepper]);

  try {
    const tagManagerArgs = {
      gtmId: GTMID,
      dataLayerName: "PageDataLayer",
    };

    if (process.env.REACT_APP_ENVIRONMENT == Environment.Test || process.env.REACT_APP_ENVIRONMENT == Environment.Production) {
      TagManager.initialize(tagManagerArgs);
    }

    return (
      <div className={classes.app}>
        {isAdminMenu ? "" : <NavigationWrapper />}
        <div className={classes.content}>
          <Routes>
            <Route path={`/${GetCurrentLanguageAsText()}/${translate(LabelKeys.SignInUrl)}`} element={<SignIn />} />
            <Route path={`/${GetCurrentLanguageAsText()}/`} element={<Home />} />
            <Route
              path={`/${GetCurrentLanguageAsText()}/${translate(LabelKeys.ResellerUrl)}/${translate(LabelKeys.SignInUrl)}`}
              element={<ResellerSignin />}
            />
            <Route path={`/${GetCurrentLanguageAsText()}/${translate(LabelKeys.TermsOfUseUrl)}`} element={<TermsOfUse />} />
            <Route path={`/${GetCurrentLanguageAsText()}/${translate(LabelKeys.ResellerUrl)}/verify`} element={<ResellerRedirect />} />
            <Route path={`/${GetCurrentLanguageAsText()}/voucher/:claimcode`} element={<Voucher />} />
            <Route path={`/${GetCurrentLanguageAsText()}/${translate(LabelKeys.OrderUrl)}`} element={<Order />} />
            <Route path={`/${GetCurrentLanguageAsText()}/${translate(LabelKeys.OrderConfirmationUrl)}`} element={<OrderConfirmation />} />
            <Route path={`/${GetCurrentLanguageAsText()}/${translate(LabelKeys.FAQUrl)}`} element={<FAQ />} />
            <Route path={`/${GetCurrentLanguageAsText()}/${translate(LabelKeys.HowItWorksUrl)}`} element={<HowItWorks />} />
            <Route path={`/${GetCurrentLanguageAsText()}/${translate(LabelKeys.ContactUrl)}`} element={<Contact />} />

            {/* Admin */}
            <Route
              path={`/${GetCurrentLanguageAsText()}/${translate(LabelKeys.AdminUrl)}/${translate(LabelKeys.OrderUrl)}`}
              element={IsAuth() ? <Order /> : <Navigate to={`/${GetCurrentLanguageAsText()}/${translate(LabelKeys.SignInUrl)}`} />}
            />
            <Route
              path={`/${GetCurrentLanguageAsText()}/${translate(LabelKeys.AdminUrl)}/${translate(LabelKeys.OrderSubviewUrl)}`}
              element={IsAuth() ? <AdminOrder /> : <Navigate to={`/${GetCurrentLanguageAsText()}/${translate(LabelKeys.SignInUrl)}`} />}
            />
            <Route
              path={`/${GetCurrentLanguageAsText()}/${translate(LabelKeys.AdminUrl)}/${translate(LabelKeys.InvoiceUrl)}`}
              element={IsAuth() ? <AdminInvoice /> : <Navigate to={`/${GetCurrentLanguageAsText()}/${translate(LabelKeys.SignInUrl)}`} />}
            />
            <Route
              path={`/${GetCurrentLanguageAsText()}/${translate(LabelKeys.AdminUrl)}/${translate(LabelKeys.ResellerUrl)}`}
              element={IsAuth() ? <AdminReseller /> : <Navigate to={`/${GetCurrentLanguageAsText()}/${translate(LabelKeys.SignInUrl)}`} />}
            />
            <Route
              path={`/${GetCurrentLanguageAsText()}/${translate(LabelKeys.AdminUrl)}/${translate(LabelKeys.TemplateUrl)}`}
              element={IsAuth() ? <AdminTemplate /> : <Navigate to={`/${GetCurrentLanguageAsText()}/${translate(LabelKeys.SignInUrl)}`} />}
            />
            <Route
              path={`/${GetCurrentLanguageAsText()}/${translate(LabelKeys.AdminUrl)}/${translate(LabelKeys.ExternalSystemsUrl)}`}
              element={
                IsAuth() ? <AdminExternalSystem /> : <Navigate to={`/${GetCurrentLanguageAsText()}/${translate(LabelKeys.SignInUrl)}`} />
              }
            />
            <Route
              path={`/${GetCurrentLanguageAsText()}/${translate(LabelKeys.AdminUrl)}`}
              element={IsAuth() ? <Admin /> : <Navigate to={`/${GetCurrentLanguageAsText()}/${translate(LabelKeys.SignInUrl)}`} />}
            />

            {/* Reseller admin */}
            <Route
              path={`/${GetCurrentLanguageAsText()}/${translate(LabelKeys.ResellerUrl)}/${translate(LabelKeys.AdminUrl)}/${translate(
                LabelKeys.OrderUrl
              )}`}
              element={
                IsResellerAuth() ? (
                  <Order />
                ) : (
                  <Navigate to={`/${GetCurrentLanguageAsText()}/${translate(LabelKeys.ResellerUrl)}/${translate(LabelKeys.SignInUrl)}`} />
                )
              }
            />
            <Route
              path={`/${GetCurrentLanguageAsText()}/${translate(LabelKeys.ResellerUrl)}/${translate(LabelKeys.AdminUrl)}/${translate(
                LabelKeys.OrderSubviewUrl
              )}`}
              element={
                IsResellerAuth() ? (
                  <ResellerOrder />
                ) : (
                  <Navigate to={`/${GetCurrentLanguageAsText()}/${translate(LabelKeys.ResellerUrl)}/${translate(LabelKeys.SignInUrl)}`} />
                )
              }
            />
            <Route
              path={`/${GetCurrentLanguageAsText()}/${translate(LabelKeys.ResellerUrl)}/${translate(LabelKeys.AdminUrl)}/${translate(
                LabelKeys.BrandingUrl
              )}`}
              element={
                IsResellerAuth() ? (
                  <ResellerBranding />
                ) : (
                  <Navigate to={`/${GetCurrentLanguageAsText()}/${translate(LabelKeys.ResellerUrl)}/${translate(LabelKeys.SignInUrl)}`} />
                )
              }
            />
            <Route
              path={`/${GetCurrentLanguageAsText()}/${translate(LabelKeys.ResellerUrl)}/${translate(LabelKeys.AdminUrl)}/${translate(
                LabelKeys.ContactInformationUrl
              )}`}
              element={
                IsResellerAuth() ? (
                  <ResellerContactPageInformation />
                ) : (
                  <Navigate to={`/${GetCurrentLanguageAsText()}/${translate(LabelKeys.ResellerUrl)}/${translate(LabelKeys.SignInUrl)}`} />
                )
              }
            />
            <Route
              path={`/${GetCurrentLanguageAsText()}/${translate(LabelKeys.ResellerUrl)}/${translate(LabelKeys.AdminUrl)}/${translate(
                LabelKeys.SettingsUrl
              )}`}
              element={
                IsResellerAuth() ? (
                  <ResellerSettings />
                ) : (
                  <Navigate to={`/${GetCurrentLanguageAsText()}/${translate(LabelKeys.ResellerUrl)}/${translate(LabelKeys.SignInUrl)}`} />
                )
              }
            />
            <Route
              path={`/${GetCurrentLanguageAsText()}/${translate(LabelKeys.ResellerUrl)}/${translate(LabelKeys.AdminUrl)}`}
              element={
                IsResellerAuth() ? (
                  <ResellerAdmin />
                ) : (
                  <Navigate to={`/${GetCurrentLanguageAsText()}/${translate(LabelKeys.ResellerUrl)}/${translate(LabelKeys.SignInUrl)}`} />
                )
              }
            />
            <Route path={`*`} element={<Navigate to={`/${GetCurrentLanguageAsText()}/`} />} />
          </Routes>
        </div>
        {(isMobile || isSm || isMd) && showMobileStepper && (
          <CSNextPrevStepMobile hideStepper={reducerState.order.stepperThreeState.isPhysicalCardEditorPopupOpen} />
        )}
        <CSSnackbar />
        <CSFooter />
        <CSCookieConsent />
        <SystemPartDialog />
      </div>
    );
  } catch (error) {
    return (
      <Routes>
        <Route path={`/${GetCurrentLanguageAsText()}/error}`} element={<SomethingWentWrong />} />
      </Routes>
    );
  }
}

export default withTranslation()(App);
