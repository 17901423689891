import { makeStyles } from "@mui/styles";

export const AppStyles = makeStyles((theme) => ({
  app: {
    textAlign: "center",
    backgroundColor: "#e3e1de",
    fontFamily: "butler",
  },
  content: {
    minHeight: "calc(100vh - 60px)",
  },
}));
