import makeStyles from "@mui/styles/makeStyles";
import { theme } from "../../appTheme";

export const CSImageSelectorStyles = makeStyles(() => ({
  selected: {
    opacity: 0.6,
    zIndex: 1,
  },
  unselected: { opacity: 1 },
  imgHover: {
    cursor: "pointer",
  },
  dropDownMenuRoot: {
    float: "left",
    width: "100%",
  },
  dropDownInner: {
    width: 165,
  },
  selectedCoverPageImage: {
    borderColor: "1px solid #000",
  },
  fileUploaderRoot: {
    float: "left",
  },
  fileUploaderContainer: {
    float: "left",
    paddingTop: 10,
  },
  filterContainer: {
    [theme.breakpoints.only("md")]: { paddingRight: 5 },
  },
  fileName: {
    minWidth: 120,
    maxWidth: "100%",
    "& .MuiChip-root": {
      borderRadius: 0,
    },
    [theme.breakpoints.only("sm")]: { float: "left" },
    [theme.breakpoints.only("md")]: { float: "right" },
  },
  fileNameContainer: {
    display: "flex",
    [theme.breakpoints.only("xs")]: { paddingTop: 16 },
    [theme.breakpoints.only("sm")]: { paddingTop: 16 },
    [theme.breakpoints.only("md")]: { paddingTop: 16 },
    [theme.breakpoints.only("lg")]: { paddingTop: 16 },
    [theme.breakpoints.only("xl")]: { paddingTop: 16 },
  },
  allowedFileFormatInfo: {
    paddingTop: 10,
  },
  checkmarkSelectedImage: {
    zIndex: 2,
    color: "#fff",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    position: "absolute",
  },
}));
