import React from "react";
import { Typography } from "@mui/material";
import Grid from "@mui/material/Grid/Grid";
import { FieldNames, StateNames } from "../../Utils/types";
import { IProductCategory } from "../../Modules/Products/types";
import { useTranslation } from "react-i18next";
import { GetLitiumImageUrl } from "../../Utils/extensions";
import { QueryParamKeys } from "../../Utils/enums/queryParams";

interface IProductCategoryProps {
  id?: string;
  data: IProductCategory;
  className?: string;
  isMobile: boolean;
  sellingPoints: string[];
  onProductCategoryClicked: (stateName: StateNames.stepperOneState, value: IProductCategory, fieldName: FieldNames) => void;
}

export default function ProductCategoryItem(props: IProductCategoryProps) {
  const { t: translate } = useTranslation();

  return (
    <Grid
      container
      item
      xs={12}
      sm={12}
      md={12}
      lg={12}
      xl={12}
      id={props.id}
      justifyContent="flex-start"
      style={{
        backgroundColor: "#fff",
        cursor: "pointer",
        boxShadow: "10px 10px 15px rgba(0, 0, 0, 0.1)",
      }}
      className={props.className}
      onClick={() => {
        props.onProductCategoryClicked(StateNames.stepperOneState, props.data, FieldNames.selectedProductCategory);
      }}
    >
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <img
          width={"100%"}
          height={props?.isMobile ? 200 : 318}
          style={{ objectFit: "cover" }}
          src={GetLitiumImageUrl(props.data.imageUrl, `${QueryParamKeys.MaxWidth}753`)}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ margin: "auto", display: "flex", paddingTop: 30 }}>
        <div
          style={{
            width: "auto",
            margin: "auto",
            display: "flex",
            flexDirection: "row",
          }}
        >
          <Typography variant="h3">{translate(props.data.labelKeyName)}</Typography>
          <div>
            <svg
              style={{ paddingLeft: 10 }}
              width="35"
              height="35"
              fill="currentColor"
              className="bi bi-arrow-right-short"
              viewBox="0 0 16 16"
            >
              <path
                fillRule="evenodd"
                d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z"
              />
            </svg>
          </div>
        </div>
      </Grid>
      {!props?.isMobile && (
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ margin: "auto", paddingTop: 15 }}>
          <Typography variant="body1" style={{ width: "80%", margin: "auto", minHeight: 100 }}>
            {translate(props.data.labelKeyDescription)}
          </Typography>
        </Grid>
      )}
      <Grid container item xs={11} sm={11} md={11} lg={11} xl={11} style={{ margin: "auto", paddingTop: 15, paddingBottom: 30 }}>
        {props.sellingPoints?.map((x, index) => {
          return (
            <Grid key={index} item xs={4} sm={4} md={4} lg={4} xl={4} style={{ margin: "auto", paddingTop: 15 }}>
              <svg
                width="22"
                height="22"
                fill="currentColor"
                className="bi bi-check-circle-fill"
                viewBox="0 0 16 16"
                style={{ color: "#2EAC77" }}
              >
                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
              </svg>
              <Typography variant="body1" style={{ maxWidth: 120, margin: "auto" }}>
                {x}
              </Typography>
            </Grid>
          );
        })}
      </Grid>
    </Grid>
  );
}
