import React, { useEffect, useState } from "react";
import { Button, Grid, Typography, useMediaQuery } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import CSTextField from "../../../components/Input/Textfield/CSTextField";
import { RootState } from "../../../store";
import { LabelKeys } from "../../../Utils/enums/labelKeys";
import { FieldNames, GetCurrentLanguageAsText } from "../../../Utils/types";
import { ResellerActions } from "../../../Modules/Reseller/actions";
import { GetValuesAsArray } from "../../../Utils/extensions";
import { IGetResellerContactPageInformtaionRequest, IUpdateResellerContactPageInformtaionRequest } from "../../../Modules/Reseller/types";
import { IEditResellerContactPageDataState } from "../../Admin/types";
import { ResellerStyles } from "../../Admin/styles";
import { theme } from "../../../appTheme";
import ProcessCircle from "../../../components/ProcessCircle/ProcessCircle";
import ResellerAdminMenu from "../ResellerAdminMenu";
import { ResellerAdminSubview } from "../types";
import ResellerSidebar from "./ResellerSidebar";
import { ResellerAdminStyles } from "../styles";
import { IsResellerAuth } from "../../../Utils/authentication";
import { useNavigate } from "react-router";

export default function ResellerContactPageInformation() {
  const { t: translate } = useTranslation();
  const isMobile = useMediaQuery(theme.breakpoints.only("xs"));
  const classes = ResellerStyles();
  const classes2 = ResellerAdminStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const reducerState = useSelector(
    (s: RootState) => {
      return {
        reseller: s.reseller,
        authentication: s.authentication,
      };
    },
    (prev, next) =>
      prev.reseller.isLoading === next.reseller.isLoading &&
      prev.reseller.resellerContactPageInformation === next.reseller.resellerContactPageInformation
  );

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(ResellerActions.GetResellerContactPageInformationAuthRequest({} as IGetResellerContactPageInformtaionRequest));
  }, []);

  const [editResellerContactPageDataState, setEditResellerContactPageDataState] = useState({
    addressLine1: reducerState.reseller.resellerContactPageInformation?.addressLine1,
    postalCode: reducerState.reseller.resellerContactPageInformation?.postalCode,
    city: reducerState.reseller.resellerContactPageInformation?.city,
    country: reducerState.reseller.resellerContactPageInformation?.country,
    website: reducerState.reseller.resellerContactPageInformation?.website,
    contactEmails: reducerState.reseller.resellerContactPageInformation?.contactEmails?.join("\n"),
    contactPhoneNumbers: reducerState.reseller.resellerContactPageInformation?.contactPhoneNumbers?.join("\n"),
  } as IEditResellerContactPageDataState);

  useEffect(() => {
    setEditResellerContactPageDataState({
      addressLine1: reducerState.reseller.resellerContactPageInformation?.addressLine1,
      postalCode: reducerState.reseller.resellerContactPageInformation?.postalCode,
      city: reducerState.reseller.resellerContactPageInformation?.city,
      country: reducerState.reseller.resellerContactPageInformation?.country,
      website: reducerState.reseller.resellerContactPageInformation?.website,
      contactEmails: reducerState.reseller.resellerContactPageInformation?.contactEmails?.join("\n"),
      contactPhoneNumbers: reducerState.reseller.resellerContactPageInformation?.contactPhoneNumbers?.join("\n"),
    });
  }, [reducerState.reseller.resellerContactPageInformation]);

  useEffect(() => {}, [editResellerContactPageDataState]);

  useEffect(() => {
    if (!IsResellerAuth()) {
      navigate(`/${GetCurrentLanguageAsText()}/${translate(LabelKeys.Reseller)}/${translate(LabelKeys.SignInUrl)}`);
    }
  }, [reducerState.authentication.hasBeenSignedOut]);

  function handleFieldOnChange(fieldName: FieldNames, value: boolean | string | number) {
    setEditResellerContactPageDataState((state) => ({
      ...state,
      [fieldName]: value,
    }));
  }

  function handleSaveData() {
    dispatch(
      ResellerActions.UpdateResellerContactPageInformationRequest({
        resellerId: undefined,
        addressLine1: editResellerContactPageDataState?.addressLine1,
        postalCode: editResellerContactPageDataState?.postalCode,
        city: editResellerContactPageDataState?.city,
        country: editResellerContactPageDataState?.country,
        website: editResellerContactPageDataState?.website,
        contactEmails: GetValuesAsArray(editResellerContactPageDataState?.contactEmails),
        contactPhoneNumbers: GetValuesAsArray(editResellerContactPageDataState?.contactPhoneNumbers),
      } as IUpdateResellerContactPageInformtaionRequest)
    );
  }

  return (
    <div style={{ display: "flex" }}>
      {!isMobile && (
        <Grid item xs={2} sm={1} md={1} lg={1} xl={1}>
          <ResellerSidebar activeSubview={ResellerAdminSubview.Order} />
        </Grid>
      )}
      <Grid container item xs={12} sm={12} md={12} lg={12} xl={12} justifyContent={"center"}>
        {reducerState.reseller?.isLoading && <ProcessCircle />}
        {isMobile && (
          <Grid item xs={11}>
            <ResellerAdminMenu />
          </Grid>
        )}
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className={classes2.subviewContent}>
          <Grid container item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Grid container item xs={12} sm={12} md={8} lg={4} xl={4}>
              <Grid id="logoTitle" item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Typography variant="h2" style={{ textAlign: "left" }}>
                  {translate(LabelKeys.ContactInformation)}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={10} lg={12} xl={12} style={{ paddingTop: 30 }}>
                <CSTextField
                  className={classes.textfield}
                  label={translate(LabelKeys.AddressLine)}
                  placeholder={`${translate(LabelKeys.AddressLine)}`}
                  type="text"
                  value={editResellerContactPageDataState.addressLine1 ?? ""}
                  onChange={(e) => {
                    handleFieldOnChange(FieldNames.addressLine1, e.target.value);
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={10} lg={12} xl={12} style={{ paddingTop: 20 }}>
                <CSTextField
                  className={classes.textfield}
                  label={translate(LabelKeys.PostalCode)}
                  placeholder={`${translate(LabelKeys.PostalCode)}`}
                  value={editResellerContactPageDataState.postalCode ?? ""}
                  type="text"
                  onChange={(e) => {
                    handleFieldOnChange(FieldNames.postalCode, e.target.value);
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={10} lg={12} xl={12} style={{ paddingTop: 20 }}>
                <CSTextField
                  className={classes.textfield}
                  label={translate(LabelKeys.City)}
                  placeholder={`${translate(LabelKeys.City)}`}
                  value={editResellerContactPageDataState.city ?? ""}
                  type="text"
                  onChange={(e) => {
                    handleFieldOnChange(FieldNames.city, e.target.value);
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={10} lg={12} xl={12} style={{ paddingTop: 20 }}>
                <CSTextField
                  className={classes.textfield}
                  label={translate(LabelKeys.Country)}
                  placeholder={`${translate(LabelKeys.Country)}`}
                  value={editResellerContactPageDataState.country ?? ""}
                  type="text"
                  onChange={(e) => {
                    handleFieldOnChange(FieldNames.country, e.target.value);
                  }}
                />
              </Grid>
              <Grid container style={{ paddingTop: 15 }}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ paddingTop: 10 }}>
                  <Typography variant="body2" style={{ textAlign: "left", paddingBottom: 5, fontWeight: "bold" }}>
                    {translate(LabelKeys.Website)}
                  </Typography>
                </Grid>
                <Grid item xs={3} sm={3} md={2} lg={3} xl={2} style={{ paddingTop: 25, textAlign: "left" }}>
                  <Typography variant="body1">{"https://"}</Typography>
                </Grid>
                <Grid item xs={9} sm={9} md={8} lg={9} xl={10}>
                  <CSTextField
                    className={classes.textfield}
                    placeholder={`${translate(LabelKeys.Website)}`}
                    value={editResellerContactPageDataState.website ?? ""}
                    type="text"
                    onChange={(e) => {
                      handleFieldOnChange(FieldNames.website, e.target.value);
                    }}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12} sm={12} md={10} lg={12} xl={12} style={{ paddingTop: 20 }}>
                <CSTextField
                  id="editContactEmails"
                  multiline={true}
                  label={translate(LabelKeys.ContactEmails)}
                  rows={3}
                  type="email"
                  value={editResellerContactPageDataState.contactEmails ?? ""}
                  placeholder={`${translate(LabelKeys.ReceiverEmails)}`}
                  onChange={(e) => {
                    handleFieldOnChange(FieldNames.contactEmails, e.target.value);
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={10} lg={12} xl={12} style={{ paddingTop: 20 }}>
                <CSTextField
                  id="editContactPhoneNumbers"
                  multiline={true}
                  label={translate(LabelKeys.ContactPhoneNumbers)}
                  rows={3}
                  type="email"
                  value={editResellerContactPageDataState.contactPhoneNumbers ?? ""}
                  placeholder={`${translate(LabelKeys.ReceiverPhoneNumbers)}`}
                  onChange={(e) => {
                    handleFieldOnChange(FieldNames.contactPhoneNumbers, e.target.value);
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={5} lg={6} xl={6} style={{ paddingTop: 31 }}>
                <Button
                  id="saveBtn"
                  style={{
                    height: 46,
                    width: "100%",
                    cursor: "pointer",
                    color: "#fff",
                    borderRadius: 0,
                    backgroundColor: "#000",
                  }}
                  onClick={() => handleSaveData()}
                >
                  <div style={{ margin: "auto", textTransform: "none" }}>{`${translate(LabelKeys.Save)}`}</div>
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}
