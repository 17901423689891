import { SendBy } from "../../components/Delivery/types";
import { FileType } from "../../components/FileUploader/types";
import { HeadCell } from "../../components/Table/types";
import { LabelKeys } from "../../Utils/enums/labelKeys";
import { OrderStatus } from "../Admin/types";

export interface IResellerAdminState {
  subview: ResellerAdminSubview;
}

export interface CoupledResellerOrder {
  orderId: number;
  companyName: string;
  createdDate: Date;
  orderDate: Date;
  sendBy: SendBy;
  status: OrderStatus;
}

export interface IResellerAdminSettingsErrorState {
  resellerIncomingOrderEmails: boolean;
}

export interface IResellerAdminBrandingState {
  logotypeAsBase64: string;
  logoTypeFileType: FileType;
  homePageImageAsBase64: string;
  homePageImageFileType: FileType;
}

export interface IResellerAdminOrderState {
  resellerIncomingOrderEmails: string;
  coupledResellerOrder: CoupledResellerOrder[];
}

export enum ResellerAdminSubview {
  None = 0,
  Order = 1,
  Branding = 2,
  Settings = 3,
  ContactPageInformation = 4,
}

export interface ISetSelectedResellerCoupledOrderRequest {
  orderId: number;
  companyName: string;
  createdDate: Date;
}

export const resellerCoupledOrdersHeadCells: HeadCell[] = [
  {
    key: "id",
    labelKey: LabelKeys.OrderId,
    type: "number",
    width: "auto",
    numeric: true,
  },
  {
    key: "companyName",
    labelKey: LabelKeys.CompanyName,
    width: "auto",
    type: "text",
    numeric: false,
  },
  {
    key: "sendBy",
    labelKey: LabelKeys.SendBy,
    width: "auto",
    type: "text",
    numeric: false,
  },
  {
    key: "createdDate",
    labelKey: LabelKeys.CreatedDate,
    width: "auto",
    type: "date",
    numeric: false,
  },
  {
    key: "orderDate",
    labelKey: LabelKeys.OrderDate,
    width: "auto",
    type: "date",
    numeric: false,
  },
  {
    key: "status",
    labelKey: LabelKeys.OrderStatus,
    width: "auto",
    type: "text",
    numeric: false,
  },
];
