import { ErrorReason } from "./../../Utils/enums/enums";
import { INVOICE_API } from "./../../Utils/network/axios/urls";
import { axiosInstance, GetBaseURL } from "./../../Utils/network/axios/index";
import { all, call, put, SagaReturnType, takeLatest } from "redux-saga/effects";
import { InvoiceActionTypes } from "./actionTypes";
import { InvoiceActions } from "./actions";
import SnackbarActions from "../../components/Snackbar/actions";
import { ICreateInvoiceBasisRequest } from "./types";
import { IStandardResponse } from "../Orders/types";

export default function* InvoiceWatcher() {
  yield all([takeLatest(InvoiceActionTypes.CREATE_INVOICE_BASIS_REQUEST, CreateInvoiceWorker)]);
}

//WORKERS
function* CreateInvoiceWorker({ request }: ReturnType<typeof InvoiceActions.CreateInvoiceBasisRequest>) {
  try {
    const response: CreateInvoiceServiceResponse = yield call(createInvoice, request);
    if (response.error == ErrorReason.Unknown) {
      yield put(InvoiceActions.CreateInvoiceBasisSuccess());
      yield put(SnackbarActions.ShowSuccess("Fakturaunderlag skapat och skickat till angivna emailadresser."));
    } else {
      yield put(InvoiceActions.CreateInvoiceBasisError());
    }
  } catch (error) {
    yield put(SnackbarActions.ShowError("Något gick fel när fakturaunderlaget skulle skapas. Vänligen försök igen."));
    yield put(InvoiceActions.CreateInvoiceBasisError());
  }
}

// API:S
export const createInvoice: CreateInvoiceService = async (request: ICreateInvoiceBasisRequest) => {
  axiosInstance.defaults.baseURL = GetBaseURL() + INVOICE_API;
  return await axiosInstance.post("basis", request).then((resp) => resp.data);
};

// SERVICES
export interface CreateInvoiceService {
  (request: ICreateInvoiceBasisRequest): Promise<IStandardResponse>;
}

// SERVICE RESPONSE
export type CreateInvoiceServiceResponse = SagaReturnType<typeof createInvoice>;
