import React, { useEffect, useState } from "react";
import { SidebarStyles } from "../styles";
import { LabelKeys } from "../../../Utils/enums/labelKeys";
import { useTranslation } from "react-i18next";
import { ResellerAdminSubview } from "../types";
import { SignOut } from "../../../Utils/authentication";
import { useNavigate } from "react-router";
import Tooltip from "@mui/material/Tooltip/Tooltip";
import { useMediaQuery } from "@mui/material";
import { theme } from "../../../appTheme";
import { useDispatch, useSelector } from "react-redux";
import { ProductActions } from "../../../Modules/Products/actions";
import { RootState } from "../../../store";
import { GetCurrentLanguageAsText } from "../../../Utils/types";

interface IResellerSidebar {
  activeSubview: ResellerAdminSubview;
}

export default function ResellerSidebar(props: IResellerSidebar) {
  const classes = SidebarStyles();
  const [isActive, setIsActive] = useState(false);
  const { t: translate } = useTranslation();
  const navigate = useNavigate();
  const isMobile = useMediaQuery(theme.breakpoints.only("xs"));
  const isSm = useMediaQuery(theme.breakpoints.only("sm"));
  const dispatch = useDispatch();
  const [isDocumentationOpen, setIsDocumentationOpen] = useState(false);

  const reducerState = useSelector(
    (s: RootState) => {
      return {
        reseller: s.reseller,
        product: s.product,
      };
    },
    (prev, next) =>
      prev.product.isLoading === next.product.isLoading && prev.product.downloadedDocumentation === next.product.downloadedDocumentation
  );

  useEffect(() => {}, [props.activeSubview]);
  useEffect(() => {
    if (isDocumentationOpen) {
      let url = URL.createObjectURL(reducerState.product.downloadedDocumentation);
      window.open(url);
      setIsDocumentationOpen(false);
    }
  }, [reducerState.product.downloadedDocumentation]);

  function onOpenDocumentationClicked() {
    dispatch(ProductActions.DownloadDocumentationRequest());
    setIsDocumentationOpen(true);
  }

  function GetExpandIcon() {
    return (
      <svg width="25" height="25" fill="currentColor" className="bi bi-list" viewBox="0 0 16 16" style={{ marginTop: -1 }}>
        <path
          fillRule="evenodd"
          d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"
        />
      </svg>
    );
  }

  function GetCollapseIcon() {
    return (
      <svg width="25" height="25" fill="currentColor" className="bi bi-arrow-bar-left" viewBox="0 0 16 16" style={{ marginTop: -1 }}>
        <path
          fillRule="evenodd"
          d="M12.5 15a.5.5 0 0 1-.5-.5v-13a.5.5 0 0 1 1 0v13a.5.5 0 0 1-.5.5zM10 8a.5.5 0 0 1-.5.5H3.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L3.707 7.5H9.5a.5.5 0 0 1 .5.5z"
        />
      </svg>
    );
  }

  function GetOrderIcon(): React.ReactNode {
    return (
      <svg width="20" height="20" fill="currentColor" className="bi bi-receipt" viewBox="0 0 16 16" style={{ color: "#000" }}>
        <path d="M1.92.506a.5.5 0 0 1 .434.14L3 1.293l.646-.647a.5.5 0 0 1 .708 0L5 1.293l.646-.647a.5.5 0 0 1 .708 0L7 1.293l.646-.647a.5.5 0 0 1 .708 0L9 1.293l.646-.647a.5.5 0 0 1 .708 0l.646.647.646-.647a.5.5 0 0 1 .708 0l.646.647.646-.647a.5.5 0 0 1 .801.13l.5 1A.5.5 0 0 1 15 2v12a.5.5 0 0 1-.053.224l-.5 1a.5.5 0 0 1-.8.13L13 14.707l-.646.647a.5.5 0 0 1-.708 0L11 14.707l-.646.647a.5.5 0 0 1-.708 0L9 14.707l-.646.647a.5.5 0 0 1-.708 0L7 14.707l-.646.647a.5.5 0 0 1-.708 0L5 14.707l-.646.647a.5.5 0 0 1-.708 0L3 14.707l-.646.647a.5.5 0 0 1-.801-.13l-.5-1A.5.5 0 0 1 1 14V2a.5.5 0 0 1 .053-.224l.5-1a.5.5 0 0 1 .367-.27zm.217 1.338L2 2.118v11.764l.137.274.51-.51a.5.5 0 0 1 .707 0l.646.647.646-.646a.5.5 0 0 1 .708 0l.646.646.646-.646a.5.5 0 0 1 .708 0l.646.646.646-.646a.5.5 0 0 1 .708 0l.646.646.646-.646a.5.5 0 0 1 .708 0l.646.646.646-.646a.5.5 0 0 1 .708 0l.509.509.137-.274V2.118l-.137-.274-.51.51a.5.5 0 0 1-.707 0L12 1.707l-.646.647a.5.5 0 0 1-.708 0L10 1.707l-.646.647a.5.5 0 0 1-.708 0L8 1.707l-.646.647a.5.5 0 0 1-.708 0L6 1.707l-.646.647a.5.5 0 0 1-.708 0L4 1.707l-.646.647a.5.5 0 0 1-.708 0l-.509-.51z" />
        <path d="M3 4.5a.5.5 0 0 1 .5-.5h6a.5.5 0 1 1 0 1h-6a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h6a.5.5 0 1 1 0 1h-6a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h6a.5.5 0 1 1 0 1h-6a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 0 1h-6a.5.5 0 0 1-.5-.5zm8-6a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 0 1h-1a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 0 1h-1a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 0 1h-1a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 0 1h-1a.5.5 0 0 1-.5-.5z" />
      </svg>
    );
  }
  function GetBrandIcon(): React.ReactNode {
    return (
      <svg width="20" height="20" fill="currentColor" className="bi bi-badge-tm" viewBox="0 0 16 16" style={{ color: "#000" }}>
        <path d="M5.295 11V5.995H7V5H2.403v.994h1.701V11h1.19zm3.397 0V7.01h.058l1.428 3.239h.773l1.42-3.24h.057V11H13.5V5.001h-1.2l-1.71 3.894h-.039l-1.71-3.894H7.634V11h1.06z" />
        <path d="M14 3a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h12zM2 2a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2H2z" />
      </svg>
    );
  }
  function GetContactPageInformationIcon(): React.ReactNode {
    return (
      <svg width="20" height="20" fill="currentColor" className="bi bi-info-square" viewBox="0 0 16 16" style={{ color: "#000" }}>
        <path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z" />
        <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
      </svg>
    );
  }
  function GetSettingsIcon(): React.ReactNode {
    return (
      <svg width="20" height="20" fill="currentColor" className="bi bi-gear" viewBox="0 0 16 16" style={{ color: "#000" }}>
        <path d="M8 4.754a3.246 3.246 0 1 0 0 6.492 3.246 3.246 0 0 0 0-6.492zM5.754 8a2.246 2.246 0 1 1 4.492 0 2.246 2.246 0 0 1-4.492 0z" />
        <path d="M9.796 1.343c-.527-1.79-3.065-1.79-3.592 0l-.094.319a.873.873 0 0 1-1.255.52l-.292-.16c-1.64-.892-3.433.902-2.54 2.541l.159.292a.873.873 0 0 1-.52 1.255l-.319.094c-1.79.527-1.79 3.065 0 3.592l.319.094a.873.873 0 0 1 .52 1.255l-.16.292c-.892 1.64.901 3.434 2.541 2.54l.292-.159a.873.873 0 0 1 1.255.52l.094.319c.527 1.79 3.065 1.79 3.592 0l.094-.319a.873.873 0 0 1 1.255-.52l.292.16c1.64.893 3.434-.902 2.54-2.541l-.159-.292a.873.873 0 0 1 .52-1.255l.319-.094c1.79-.527 1.79-3.065 0-3.592l-.319-.094a.873.873 0 0 1-.52-1.255l.16-.292c.893-1.64-.902-3.433-2.541-2.54l-.292.159a.873.873 0 0 1-1.255-.52l-.094-.319zm-2.633.283c.246-.835 1.428-.835 1.674 0l.094.319a1.873 1.873 0 0 0 2.693 1.115l.291-.16c.764-.415 1.6.42 1.184 1.185l-.159.292a1.873 1.873 0 0 0 1.116 2.692l.318.094c.835.246.835 1.428 0 1.674l-.319.094a1.873 1.873 0 0 0-1.115 2.693l.16.291c.415.764-.42 1.6-1.185 1.184l-.291-.159a1.873 1.873 0 0 0-2.693 1.116l-.094.318c-.246.835-1.428.835-1.674 0l-.094-.319a1.873 1.873 0 0 0-2.692-1.115l-.292.16c-.764.415-1.6-.42-1.184-1.185l.159-.291A1.873 1.873 0 0 0 1.945 8.93l-.319-.094c-.835-.246-.835-1.428 0-1.674l.319-.094A1.873 1.873 0 0 0 3.06 4.377l-.16-.292c-.415-.764.42-1.6 1.185-1.184l.292.159a1.873 1.873 0 0 0 2.692-1.115l.094-.319z" />
      </svg>
    );
  }
  function GetSignOutIcon(): React.ReactNode {
    return (
      <svg width="20" height="20" fill="currentColor" className="bi bi-box-arrow-right" viewBox="0 0 16 16">
        <path
          fillRule="evenodd"
          d="M10 12.5a.5.5 0 0 1-.5.5h-8a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 .5.5v2a.5.5 0 0 0 1 0v-2A1.5 1.5 0 0 0 9.5 2h-8A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h8a1.5 1.5 0 0 0 1.5-1.5v-2a.5.5 0 0 0-1 0v2z"
        />
        <path
          fillRule="evenodd"
          d="M15.854 8.354a.5.5 0 0 0 0-.708l-3-3a.5.5 0 0 0-.708.708L14.293 7.5H5.5a.5.5 0 0 0 0 1h8.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3z"
        />
      </svg>
    );
  }
  function GetDocumentationIcon(): React.ReactNode {
    return (
      <svg width="20" height="20" fill="currentColor" className="bi bi-file-earmark-pdf" viewBox="0 0 16 16">
        <path d="M14 14V4.5L9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2zM9.5 3A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5v2z" />
        <path d="M4.603 14.087a.81.81 0 0 1-.438-.42c-.195-.388-.13-.776.08-1.102.198-.307.526-.568.897-.787a7.68 7.68 0 0 1 1.482-.645 19.697 19.697 0 0 0 1.062-2.227 7.269 7.269 0 0 1-.43-1.295c-.086-.4-.119-.796-.046-1.136.075-.354.274-.672.65-.823.192-.077.4-.12.602-.077a.7.7 0 0 1 .477.365c.088.164.12.356.127.538.007.188-.012.396-.047.614-.084.51-.27 1.134-.52 1.794a10.954 10.954 0 0 0 .98 1.686 5.753 5.753 0 0 1 1.334.05c.364.066.734.195.96.465.12.144.193.32.2.518.007.192-.047.382-.138.563a1.04 1.04 0 0 1-.354.416.856.856 0 0 1-.51.138c-.331-.014-.654-.196-.933-.417a5.712 5.712 0 0 1-.911-.95 11.651 11.651 0 0 0-1.997.406 11.307 11.307 0 0 1-1.02 1.51c-.292.35-.609.656-.927.787a.793.793 0 0 1-.58.029zm1.379-1.901c-.166.076-.32.156-.459.238-.328.194-.541.383-.647.547-.094.145-.096.25-.04.361.01.022.02.036.026.044a.266.266 0 0 0 .035-.012c.137-.056.355-.235.635-.572a8.18 8.18 0 0 0 .45-.606zm1.64-1.33a12.71 12.71 0 0 1 1.01-.193 11.744 11.744 0 0 1-.51-.858 20.801 20.801 0 0 1-.5 1.05zm2.446.45c.15.163.296.3.435.41.24.19.407.253.498.256a.107.107 0 0 0 .07-.015.307.307 0 0 0 .094-.125.436.436 0 0 0 .059-.2.095.095 0 0 0-.026-.063c-.052-.062-.2-.152-.518-.209a3.876 3.876 0 0 0-.612-.053zM8.078 7.8a6.7 6.7 0 0 0 .2-.828c.031-.188.043-.343.038-.465a.613.613 0 0 0-.032-.198.517.517 0 0 0-.145.04c-.087.035-.158.106-.196.283-.04.192-.03.469.046.822.024.111.054.227.09.346z" />
      </svg>
    );
  }

  return (
    <div
      id="sidebar"
      className={classes.root}
      style={{ width: isActive ? 175 : 50, height: "100%", backgroundColor: "#e3e1de", borderRight: "1px solid rgba(45, 45, 45, 0.2)" }}
    >
      <div
        id="sidebarExpander"
        style={{ width: isActive ? 175 : 50, backgroundColor: "#000", color: "#fff", height: 50, cursor: "pointer" }}
        onClick={() => {
          if ((!isMobile && !isSm) || isActive) {
            setIsActive(!isActive);
          }
        }}
      >
        <div
          key={"expandSidebar"}
          onClick={() => {
            if ((!isMobile && !isSm) || isActive) {
              setIsActive(!isActive);
            }
          }}
          style={{
            width: 50,
            transform: "translateY(50%)",
            cursor: "pointer",
            color: "#e3e1de",
            float: isActive ? "right" : "inherit",
          }}
        >
          {isActive ? GetCollapseIcon() : GetExpandIcon()}
        </div>
      </div>
      <div id="content" style={{ height: "100%", width: isActive ? 175 : 50 }}>
        <div id="body">
          <div
            id="order"
            className={classes.childElement}
            style={{ backgroundColor: props.activeSubview == ResellerAdminSubview.Order ? "#cccccc" : "" }}
            onClick={() => {
              navigate(
                `/${GetCurrentLanguageAsText()}/${translate(LabelKeys.ResellerUrl)}/${translate(LabelKeys.AdminUrl)}/${translate(
                  LabelKeys.OrderSubviewUrl
                )}`
              );
            }}
          >
            <Tooltip arrow title={translate(LabelKeys.Order)} placement={"right"}>
              <div style={{ transform: "translateY(50%)", paddingTop: 2 }}>
                {isActive ? (
                  <div style={{ paddingTop: 2, fontFamily: "Mulish", textAlign: "left", marginLeft: 20, color: "#000" }}>
                    {translate(LabelKeys.Order)}
                  </div>
                ) : (
                  GetOrderIcon()
                )}
              </div>
            </Tooltip>
          </div>
          <div
            id="branding"
            className={classes.childElement}
            style={{ backgroundColor: props.activeSubview == ResellerAdminSubview.Branding ? "#cccccc" : "" }}
            onClick={() => {
              navigate(
                `/${GetCurrentLanguageAsText()}/${translate(LabelKeys.ResellerUrl)}/${translate(LabelKeys.AdminUrl)}/${translate(
                  LabelKeys.BrandingUrl
                )}`
              );
            }}
          >
            <Tooltip arrow title={translate(LabelKeys.Branding)} placement={"right"}>
              <div style={{ transform: "translateY(50%)", paddingTop: 2 }}>
                {isActive ? (
                  <div style={{ paddingTop: 2, fontFamily: "Mulish", textAlign: "left", marginLeft: 20, color: "#000" }}>
                    {translate(LabelKeys.Branding)}
                  </div>
                ) : (
                  GetBrandIcon()
                )}
              </div>
            </Tooltip>
          </div>
          <div
            id="contactPageInformation"
            className={classes.childElement}
            style={{ backgroundColor: props.activeSubview == ResellerAdminSubview.ContactPageInformation ? "#cccccc" : "" }}
            onClick={() => {
              navigate(
                `/${GetCurrentLanguageAsText()}/${translate(LabelKeys.ResellerUrl)}/${translate(LabelKeys.AdminUrl)}/${translate(
                  LabelKeys.ContactInformationUrl
                )}`
              );
            }}
          >
            <Tooltip arrow title={translate(LabelKeys.ContactInformation)} placement={"right"}>
              <div style={{ transform: "translateY(50%)", paddingTop: 2 }}>
                {isActive ? (
                  <div style={{ paddingTop: 2, fontFamily: "Mulish", textAlign: "left", marginLeft: 20, color: "#000" }}>
                    {translate(LabelKeys.ContactInformation)}
                  </div>
                ) : (
                  GetContactPageInformationIcon()
                )}
              </div>
            </Tooltip>
          </div>
          <div
            id="settings"
            className={classes.childElement}
            style={{ backgroundColor: props.activeSubview == ResellerAdminSubview.Settings ? "#cccccc" : "" }}
            onClick={() => {
              navigate(
                `/${GetCurrentLanguageAsText()}/${translate(LabelKeys.ResellerUrl)}/${translate(LabelKeys.AdminUrl)}/${translate(
                  LabelKeys.SettingsUrl
                )}`
              );
            }}
          >
            <Tooltip arrow title={translate(LabelKeys.Settings)} placement={"right"}>
              <div style={{ transform: "translateY(50%)", paddingTop: 2 }}>
                {isActive ? (
                  <div style={{ paddingTop: 2, fontFamily: "Mulish", textAlign: "left", marginLeft: 20, color: "#000" }}>
                    {translate(LabelKeys.Settings)}
                  </div>
                ) : (
                  GetSettingsIcon()
                )}
              </div>
            </Tooltip>
          </div>
          <div id="documentation" className={classes.childElement} onClick={() => onOpenDocumentationClicked()}>
            <Tooltip arrow title={translate(LabelKeys.DownloadDocumentation)} placement={"right"}>
              <div style={{ transform: "translateY(50%)", paddingTop: 2 }}>
                {isActive ? (
                  <div style={{ paddingTop: 2, fontFamily: "Mulish", textAlign: "left", marginLeft: 20, color: "#000" }}>
                    {translate(LabelKeys.DownloadDocumentation)}
                  </div>
                ) : (
                  GetDocumentationIcon()
                )}
              </div>
            </Tooltip>
          </div>
          <div
            id="signOut"
            className={classes.childElement}
            onClick={() => {
              SignOut();
              navigate(`/${GetCurrentLanguageAsText()}/`);
            }}
          >
            <Tooltip arrow title={translate(LabelKeys.SignOut)} placement={"right"}>
              <div style={{ transform: "translateY(50%)", paddingTop: 2 }}>
                {isActive ? (
                  <div style={{ paddingTop: 2, fontFamily: "Mulish", textAlign: "left", marginLeft: 20, color: "#000" }}>
                    {translate(LabelKeys.SignOut)}
                  </div>
                ) : (
                  GetSignOutIcon()
                )}
              </div>
            </Tooltip>
          </div>
        </div>
      </div>
    </div>
  );
}
