export enum InvoiceActionTypes {
  //Create invoice
  CREATE_INVOICE_BASIS_REQUEST = "CREATE_INVOICE_BASIS_REQUEST",
  CREATE_INVOICE_BASIS_SUCCESS = "CREATE_INVOICE_BASIS_SUCCESS",
  CREATE_INVOICE_BASIS_ERROR = "CREATE_INVOICE_BASIS_ERROR",

  RESET_REDUCER = "RESET_REDUCER",

  //Accept order
  ACCEPT_INVOICE_REQUEST = "ACCEPT_INVOICE_REQUEST",
  ACCEPT_INVOICE_SUCCESS = "ACCEPT_INVOICE_SUCCESS",
  ACCEPT_INVOICE_ERROR = "ACCEPT_INVOICE_ERROR",

  //Decline order
  DECLINE_INVOICE_REQUEST = "DECLINE_INVOICE_REQUEST",
  DECLINE_INVOICE_SUCCESS = "DECLINE_INVOICE_SUCCESS",
  DECLINE_INVOICE_ERROR = "DECLINE_INVOICE_ERROR",
}
