import React from "react";
import Grid from "@mui/material/Grid";
import { SMSCustomPreviewStyles } from "./styles";
import SMSTemplate from "../../Utils/Images/SMSCustomPreview.png";
import { LabelKeys } from "../../Utils/enums/labelKeys";
import { useTranslation } from "react-i18next";
import { Typography } from "@mui/material";
import { QueryParamKeys } from "../../Utils/enums/queryParams";

export interface ISMSCustomPreviewProps {
  bodyText: string;
  imageUrl?: string;
  className?: string;
}

export default function SMSCustomPreview(props: ISMSCustomPreviewProps) {
  const classes = SMSCustomPreviewStyles();
  const { t: translate } = useTranslation();

  return (
    <Grid
      id="smsCustomPreview"
      container
      item
      xs={12}
      sm={12}
      md={12}
      lg={12}
      xl={12}
      justifyContent="center"
      className={classes.container}
    >
      <div>
        <img src={SMSTemplate} className={classes.previewBase} />
      </div>
      <div className={classes.previewSMSText}>
        <img
          className={classes.image}
          src={props.imageUrl && props.imageUrl.includes("base64") ? props.imageUrl : `${props.imageUrl + QueryParamKeys.MaxWidth + 500}`}
        />
        <Grid
          id="codeContainer"
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          container
          justifyContent="center"
          style={{
            backgroundColor: "rgb(220,220,220, 0.2)",
          }}
        >
          <Grid
            id="codeContainer"
            container
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            justifyContent="center"
            className={classes.codeContainer}
          >
            <Typography
              id="code"
              variant="body1"
              className={classes.code}
              onClick={() => {
                navigator.clipboard.writeText("xxxxx-xxxxx-xxxxx");
              }}
              style={{ height: 20, textAlign: "center", marginTop: -5 }}
            >
              {`xxxxx-xxxxx-xxxxx`}
            </Typography>
            <Typography variant="body2" id="clickToCopyText" style={{ width: "100%" }}>
              {`${translate(LabelKeys.ClickToCopy)}`}
            </Typography>
          </Grid>
        </Grid>
        <div className={classes.message}>
          <Typography variant="body2">{props.bodyText}</Typography>
        </div>
        <Grid id="expireDateContainer" item xs={12} sm={12} md={12} lg={12} xl={12} className={classes.expireDate}>
          <Typography variant="body2" style={{ paddingLeft: 5 }}>{`${translate(LabelKeys.ValidThrough)}: xxxx-xx-xx`}</Typography>
        </Grid>
      </div>
    </Grid>
  );
}
