import React, { useEffect, useState } from "react";
import { Grid, Button, useMediaQuery } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import CSTextField from "../../../../../components/Input/Textfield/CSTextField";
import ProcessCircle from "../../../../../components/ProcessCircle/ProcessCircle";
import { ResellerActions } from "../../../../../Modules/Reseller/actions";
import { RootState } from "../../../../../store";
import { LabelKeys } from "../../../../../Utils/enums/labelKeys";
import { FieldNames } from "../../../../../Utils/types";
import { AdminStyles } from "../../../styles";
import { GetValuesAsArray, ValidateDeliveryEmails } from "../../../../../Utils/extensions";
import { theme } from "../../../../../appTheme";

interface ISettingsState {
  resellerIncomingOrderEmails: string;
}

interface ISettingsErrorState {
  resellerIncomingOrderEmails: boolean;
}

interface IResellerSettingsProps {
  onClose: () => void;
}

export default function ResellerSettings(props: IResellerSettingsProps) {
  const { t: translate } = useTranslation();
  const classes = AdminStyles();
  const isMobile = useMediaQuery(theme.breakpoints.only("xs"));
  const dispatch = useDispatch();
  const reducerState = useSelector(
    (s: RootState) => {
      return {
        reseller: s.reseller,
      };
    },
    (prev, next) =>
      prev.reseller.isLoading === next.reseller.isLoading &&
      prev.reseller.adminResellerIncomingOrderEmails === next.reseller.adminResellerIncomingOrderEmails &&
      prev.reseller.selectedAdminResellerRow === next.reseller.selectedAdminResellerRow
  );
  const [settingsState, setSettingsState] = useState({
    resellerIncomingOrderEmails: GetEmailValues(),
  } as ISettingsState);
  const [settingsErrorState, setSettingsErrorState] = useState({ resellerIncomingOrderEmails: false } as ISettingsErrorState);

  useEffect(() => {
    if (reducerState.reseller.selectedAdminResellerRow?.id > 0) {
      dispatch(ResellerActions.GetResellerIncomingOrderEmailsRequest({ resellerId: reducerState.reseller.selectedAdminResellerRow?.id }));
    }
  }, []);

  useEffect(() => {
    if (reducerState.reseller.adminResellerIncomingOrderEmails?.length > 0) {
      let values = reducerState.reseller.adminResellerIncomingOrderEmails.join(",").replaceAll(" ", "").replaceAll(",", "\n");
      handleFieldOnChange(FieldNames.resellerIncomingOrderEmails, values);
    }
  }, [reducerState.reseller.adminResellerIncomingOrderEmails]);

  function GetEmailValues(): string {
    if (reducerState.reseller.adminResellerIncomingOrderEmails?.length > 0) {
      return reducerState.reseller.adminResellerIncomingOrderEmails.join("")?.replaceAll(",", "\n");
    }
    return "";
  }

  function handleFieldOnChange(fieldName: FieldNames, value: string | Date | boolean | number | undefined) {
    setSettingsState((state) => ({
      ...state,
      [fieldName]: value,
    }));
  }

  function handleSetErrorState(fieldName: FieldNames, value: string | boolean) {
    setSettingsErrorState((state) => ({
      ...state,
      [fieldName]: value,
    }));
  }

  function handleSaveResellerIncomingOrderEmails() {
    let isValid = ValidateAllFieldsResellerIncomingOrderEmails();
    if (isValid) {
      var values = GetValuesAsArray(settingsState.resellerIncomingOrderEmails);

      dispatch(
        ResellerActions.UpdateResellerIncomingOrderEmailsRequest({
          resellerId: reducerState.reseller.selectedAdminResellerRow?.id,
          emails: values,
        })
      );
      props.onClose();
    }
  }

  function ValidateAllFieldsResellerIncomingOrderEmails(): boolean {
    let valid: boolean = true;
    if (settingsState.resellerIncomingOrderEmails?.length == 0 || ValidateDeliveryEmails(settingsState.resellerIncomingOrderEmails)) {
      handleSetErrorState(FieldNames.resellerIncomingOrderEmails, false);
    } else {
      handleSetErrorState(FieldNames.resellerIncomingOrderEmails, true);
      valid = false;
    }

    return valid;
  }

  return (
    <Grid container item xs={12} sm={12} md={12} lg={12} xl={12}>
      {reducerState.reseller?.isLoading && <ProcessCircle />}
      <Grid
        container
        item
        xs={12}
        sm={12}
        md={12}
        lg={12}
        xl={12}
        style={{
          textAlign: "left",
          display: "flex",
        }}
        alignContent={"flex-start"}
        className={classes.leftContent}
      >
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ paddingBottom: 10, marginTop: 30 }}>
          <CSTextField
            id="resellerIncomingOrderEmails"
            type="email"
            rows={10}
            multiline
            placeholder={translate(LabelKeys.ResellerIncomingOrderEmails)}
            value={settingsState.resellerIncomingOrderEmails ?? ""}
            onChange={(e) => handleFieldOnChange(FieldNames.resellerIncomingOrderEmails, e.target.value)}
            error={settingsErrorState.resellerIncomingOrderEmails}
            helperText={translate(LabelKeys.InvalidEmailFormat)}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={6} xl={6} style={{ paddingTop: 31, paddingRight: isMobile ? 0 : 8 }}>
          <Button
            id="saveBtn"
            style={{
              height: 46,
              width: "100%",
              cursor: "pointer",
              color: "#fff",
              borderRadius: 0,
              backgroundColor: "#000",
            }}
            onClick={() => handleSaveResellerIncomingOrderEmails()}
          >
            <div style={{ margin: "auto", textTransform: "none" }}>{`${translate(LabelKeys.Save)}`}</div>
          </Button>
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={6} xl={6} style={{ paddingTop: 31, paddingLeft: isMobile ? 0 : 8 }}>
          <Button
            id="cancelBtn"
            style={{
              height: 46,
              width: "100%",
              cursor: "pointer",
              color: "#fff",
              borderRadius: 0,
              backgroundColor: "#000",
            }}
            onClick={() => props.onClose()}
          >
            <div style={{ margin: "auto", textTransform: "none" }}>{`${translate(LabelKeys.Cancel)}`}</div>
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
}
