import { InvoiceActionTypes } from "./actionTypes";
import {
  IAcceptInvoiceRequest,
  IAcceptInvoiceResponse,
  ICreateInvoiceBasisRequest,
  IDeclineInvoiceRequest,
  IDeclineInvoiceResponse,
} from "./types";

//Create invoice
interface Create_Invoice_Basis_Request {
  type: InvoiceActionTypes.CREATE_INVOICE_BASIS_REQUEST;
  request: ICreateInvoiceBasisRequest;
}
interface Create_Invoice_Basis_Success {
  type: InvoiceActionTypes.CREATE_INVOICE_BASIS_SUCCESS;
}
interface Create_Invoice_Basis_Error {
  type: InvoiceActionTypes.CREATE_INVOICE_BASIS_ERROR;
}

//Reset
interface Reset_Reducer {
  type: InvoiceActionTypes.RESET_REDUCER;
}

//Accept reseller order (by link)
interface Accept_Invoice_Request {
  type: InvoiceActionTypes.ACCEPT_INVOICE_REQUEST;
  request: IAcceptInvoiceRequest;
}
interface Accept_Invoice_Success {
  type: InvoiceActionTypes.ACCEPT_INVOICE_SUCCESS;
  response: IAcceptInvoiceResponse;
}
interface Accept_Invoice_Error {
  type: InvoiceActionTypes.ACCEPT_INVOICE_ERROR;
}

//Decline reseller order (by link)
interface Decline_Invoice_Request {
  type: InvoiceActionTypes.DECLINE_INVOICE_REQUEST;
  request: IDeclineInvoiceRequest;
}
interface Decline_Invoice_Success {
  type: InvoiceActionTypes.DECLINE_INVOICE_SUCCESS;
  response: IDeclineInvoiceResponse;
}
interface Decline_Invoice_Error {
  type: InvoiceActionTypes.DECLINE_INVOICE_ERROR;
}

export const InvoiceActions = {
  //Create invoice basis
  CreateInvoiceBasisRequest: (request: ICreateInvoiceBasisRequest): Create_Invoice_Basis_Request => ({
    type: InvoiceActionTypes.CREATE_INVOICE_BASIS_REQUEST,
    request,
  }),
  CreateInvoiceBasisSuccess: (): Create_Invoice_Basis_Success => ({
    type: InvoiceActionTypes.CREATE_INVOICE_BASIS_SUCCESS,
  }),
  CreateInvoiceBasisError: (): Create_Invoice_Basis_Error => ({
    type: InvoiceActionTypes.CREATE_INVOICE_BASIS_ERROR,
  }),

  //Reset
  ResetReducer: (): Reset_Reducer => ({
    type: InvoiceActionTypes.RESET_REDUCER,
  }),

  //Accept reseller invoice
  AcceptInvoiceRequest: (request: IAcceptInvoiceRequest): Accept_Invoice_Request => ({
    type: InvoiceActionTypes.ACCEPT_INVOICE_REQUEST,
    request,
  }),
  AcceptInvoiceSuccess: (response: IAcceptInvoiceResponse): Accept_Invoice_Success => ({
    type: InvoiceActionTypes.ACCEPT_INVOICE_SUCCESS,
    response,
  }),
  AcceptInvoiceError: (): Accept_Invoice_Error => ({
    type: InvoiceActionTypes.ACCEPT_INVOICE_ERROR,
  }),

  //Decline reseller invoice
  DeclineInvoiceRequest: (request: IAcceptInvoiceRequest): Decline_Invoice_Request => ({
    type: InvoiceActionTypes.DECLINE_INVOICE_REQUEST,
    request,
  }),
  DeclineInvoiceSuccess: (response: IAcceptInvoiceResponse): Decline_Invoice_Success => ({
    type: InvoiceActionTypes.DECLINE_INVOICE_SUCCESS,
    response,
  }),
  DeclineInvoiceError: (): Decline_Invoice_Error => ({
    type: InvoiceActionTypes.DECLINE_INVOICE_ERROR,
  }),
};
