import { makeStyles } from "@mui/styles";
import { theme } from "../../appTheme";

export const StepperStyles = makeStyles({
  root: {},
  label: { cursor: "pointer" },
  icon: { cursor: "pointer" },
});
export const StepperOneStyles = makeStyles({
  selectedProductCategory: {
    border: "2px solid orange",
    cursor: "pointer",
  },
  notSelectedProductCategory: {
    border: "2px solid #3D374D33",
    cursor: "pointer",
  },
});

export const StepperTwoStyles = makeStyles({
  selectedCard: {
    height: 140,
    width: 194,
    border: "1px solid #000",
    margin: 5,
    color: "#fff",
    backgroundColor: "#000",
    cursor: "pointer",
    [theme.breakpoints.only("xs")]: {
      height: 150,
      width: "100%",
    },
  },
  notSelectedCard: {
    height: 140,
    width: 194,
    border: "1px solid #000",
    margin: 5,
    color: "#000",
    backgroundColor: "#fff",
    cursor: "pointer",
    [theme.breakpoints.only("xs")]: {
      height: 150,
      width: "100%",
    },
  },
  selectedCardVH: {
    height: 40,
    width: 88,
    border: "1px solid #000",
    margin: 5,
    color: "#fff",
    backgroundColor: "#000",
    cursor: "pointer",
    [theme.breakpoints.only("xs")]: {
      height: 56,
      width: "100%",
    },
  },
  notSelectedCardVH: {
    height: 40,
    width: 88,
    border: "1px solid #000",
    margin: 5,
    color: "#000",
    backgroundColor: "#fff",
    cursor: "pointer",
    [theme.breakpoints.only("xs")]: {
      height: 56,
      width: "100%",
    },
  },
  giftcardContainer: {
    "&::-webkit-scrollbar": {
      display: "none",
    },
    [theme.breakpoints.only("xs")]: {
      height: 230,
      display: "flex",
      flexWrap: "nowrap",
      overflowX: "scroll",
      zoom: 0.8,
    },
    [theme.breakpoints.only("sm")]: {
      zoom: 0.817,
    },
    [theme.breakpoints.only("md")]: {
      zoom: 1.09,
    },
  },
  exampleAssortmentContainer: {
    "&::-webkit-scrollbar": {
      display: "none",
    },
    [theme.breakpoints.only("xs")]: {
      height: 190,
      display: "flex",
      flexWrap: "nowrap",
      overflowX: "scroll",
      zoom: 0.8,
    },
    [theme.breakpoints.only("sm")]: {
      zoom: 0.817,
    },
    [theme.breakpoints.only("md")]: {
      zoom: 1.09,
    },
  },
});

export const StepperThreeStyles = makeStyles({});
export const StepperFourStyles = makeStyles({
  contactResellerName: {
    border: "4px solid yellowgreen",
    backgroundColor: "rgba(141, 194, 41, 0.5)",
  },
  textfield: {
    paddingTop: 30,
  },
});
export const StepperFiveStyles = makeStyles({
  whenToSendBtn: { backgroundColor: "red", width: "100%" },
  fileUploaderRoot: {
    float: "left",
  },
  fileUploaderContainer: {
    float: "left",
    paddingTop: 10,
  },
  filterContainer: {
    [theme.breakpoints.only("md")]: { paddingRight: 5 },
  },
});
export const StepperSixStyles = makeStyles({
  selectedPaymentMethod: {
    border: "2px solid #2eac77",
    cursor: "pointer",
  },
  notSelectedPaymentMethod: {
    border: "2px solid #3D374D33",
    cursor: "pointer",
  },
});

export const PrevNextStepStyles = makeStyles({
  progressCircle: { height: 30, width: 30, backgroundColor: "e3e1de" },
  svg: { backgroundColor: "e3e1de", position: "absolute", zIndex: 2 },
  menuIconContainer: {
    position: "absolute",
    backgroundColor: "#red",
    height: "80%",
    width: "80%",
    zIndex: 100,
  },
  menuIcon: {},
});
