import makeStyles from "@mui/styles/makeStyles";
import { theme } from "../../appTheme";

export const OrderStyles = makeStyles({
  root: {
    minHeight: "70vh",
    position: "relative",
    paddingTop: 40,
    [theme.breakpoints.only("xs")]: {
      paddingTop: 35,
    },
  },
});
