import React, { useEffect, useState } from "react";
import { Dialog, DialogContent, DialogContentText, Typography, Button, IconButton } from "@mui/material";
import { LabelKeys } from "../../Utils/enums/labelKeys";
import { useTranslation } from "react-i18next";
import { StateNames, FieldNames } from "../../Utils/types";
import CSDateTimePicker from "../Input/DateTimePicker/CSDateTimePicker";
import { WhenToSend } from "../Delivery/types";
import { PaymentMethod } from "../../Utils/enums/enums";
import { AddBusinessDays, GetHolidays } from "../../Utils/extensions";

interface ICustomWebPagePopup {
  currentOrderDate: Date;
  enableCloseBtn: boolean;
  title: string;
  description: string;
  listItems: string[];
  btnTextLeft: string;
  btnTextRight: string;
  paymentMethod: PaymentMethod;
  customWebPage: boolean;
  onStateChanged: (
    stateName: StateNames.stepperFiveState | StateNames.stepperSixState | StateNames.stepperState,
    value: Date | WhenToSend | boolean,
    fieldName: FieldNames
  ) => void;
}

export default function CustomWebPagePopup(props: ICustomWebPagePopup) {
  const [currentOrderDate, setCurrentOrderDate] = useState(props.currentOrderDate);

  useEffect(() => {
    if (props.currentOrderDate < GetMinDate()) {
      setCurrentOrderDate(GetMinDate());
    } else {
      setCurrentOrderDate(props.currentOrderDate);
    }
  }, [props.currentOrderDate]);

  useEffect(() => {
    props.onStateChanged(StateNames.stepperState, false, FieldNames.readyToPay);
  }, []);

  const { t: translate } = useTranslation();
  function GetMinDate(): Date {
    const holidays = GetHolidays();

    return AddBusinessDays(new Date(), 4, holidays);

    // if (props.paymentMethod == PaymentMethod.invoice && !props.customWebPage) {
    //   return AddBusinessDays(new Date(), 3, holidays);
    // } else if (props.paymentMethod == PaymentMethod.invoice && props.customWebPage) {
    //   return AddBusinessDays(new Date(), 4, holidays);
    // }w
    // //props.paymentMethod != PaymentMethod.invoice && props.customWebPage
    // else {
    //   return AddBusinessDays(new Date(), 4, holidays);
    // }
  }

  function GetMaxDate(): Date {
    let maxDate = new Date();
    maxDate.setDate(new Date().getDate() + 175);
    return maxDate;
  }

  return (
    <Dialog open={true} PaperProps={{ style: { borderRadius: 0, maxWidth: 460 } }}>
      <DialogContent style={{ borderRadius: 0, maxWidth: 530 }}>
        <DialogContentText>
          <Typography variant="h1">{props.title}</Typography>
          {props.enableCloseBtn && (
            <IconButton
              aria-label="close"
              onClick={() => props.onStateChanged(StateNames.stepperSixState, false, FieldNames.customWebPageDialogOpen)}
              sx={{
                position: "absolute",
                right: 25,
                top: 35,
                padding: 0,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <svg style={{}} width="25" height="25" fill="currentColor" className="bi bi-x-circle" viewBox="0 0 16 16">
                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
              </svg>
            </IconButton>
          )}
        </DialogContentText>
        <Typography variant="body1" style={{ paddingTop: 30 }}>
          {props.description}
        </Typography>
        <ul style={{ textDecoration: "none" }}>
          {props.listItems?.map((x, index) => {
            return <li key={index}>{x}</li>;
          })}
        </ul>
        <div style={{ marginTop: 35 }}>
          <CSDateTimePicker
            id="orderDate"
            required
            label={translate(LabelKeys.NewDate)}
            startIcon={
              <svg width="16" height="16" fill="currentColor" className="bi bi-calendar3" viewBox="0 0 16 16">
                <path d="M14 0H2a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zM1 3.857C1 3.384 1.448 3 2 3h12c.552 0 1 .384 1 .857v10.286c0 .473-.448.857-1 .857H2c-.552 0-1-.384-1-.857V3.857z" />
                <path d="M6.5 7a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm-9 3a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm-9 3a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2z" />
              </svg>
            }
            fullWidth
            minDate={GetMinDate()}
            maxDate={GetMaxDate()}
            value={currentOrderDate}
            onChange={(date) => setCurrentOrderDate(date)}
            okLabel={translate(LabelKeys.Ok)}
            cancelLabel={translate(LabelKeys.Cancel)}
            disablePast
          />
        </div>
        <div style={{ display: "flex" }}>
          <Button
            id="confirmCustomWebPageTermsBtn"
            style={{
              height: 60,
              cursor: "pointer",
              display: "flex",
              marginTop: 30,
              marginRight: 5,
              borderRadius: 0,
              backgroundColor: "#000",
              width: "50%",
            }}
            onClick={() => {
              props.onStateChanged(StateNames.stepperFiveState, WhenToSend.SpecificTime, FieldNames.whenToSend);
              props.onStateChanged(StateNames.stepperSixState, true, FieldNames.acceptedCustomWebPage);
              props.onStateChanged(StateNames.stepperState, true, FieldNames.readyToPay);
              props.onStateChanged(StateNames.stepperSixState, false, FieldNames.customWebPageDialogOpen);
              props.onStateChanged(StateNames.stepperFiveState, currentOrderDate, FieldNames.orderDate);
            }}
          >
            <Typography
              variant="body1"
              style={{
                margin: "auto",
                textTransform: "none",
                color: "#fff",
              }}
            >
              {props.btnTextLeft}
            </Typography>
          </Button>
          <Button
            id="declinecustomWebPageTermsBtn"
            style={{
              height: 60,
              cursor: "pointer",
              display: "flex",
              marginTop: 30,
              marginLeft: 5,
              borderRadius: 0,
              backgroundColor: "#000",
              width: "50%",
            }}
            onClick={() => {
              props.onStateChanged(StateNames.stepperSixState, false, FieldNames.acceptedCustomWebPage);
              props.onStateChanged(StateNames.stepperSixState, false, FieldNames.customWebPageDialogOpen);
            }}
          >
            <Typography
              variant="body1"
              style={{
                margin: "auto",
                textTransform: "none",
                color: "#fff",
              }}
            >
              {props.btnTextRight}
            </Typography>
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
}
