import React, { useEffect } from "react";
import { useNavigate } from "react-router";
import { LabelKeys } from "../../Utils/enums/labelKeys";
import { useTranslation } from "react-i18next";
import { GetCurrentLanguageAsText } from "../../Utils/types";

export default function ResellerAdmin() {
  const navigate = useNavigate();
  const { t: translate } = useTranslation();

  useEffect(() => {
    navigate(
      `/${GetCurrentLanguageAsText()}/${translate(LabelKeys.ResellerUrl)}/${translate(LabelKeys.AdminUrl)}/${translate(
        LabelKeys.OrderSubviewUrl
      )}`
    );
  }, []);

  return <div></div>;
}
