import makeStyles from "@mui/styles/makeStyles";
import { theme } from "../../appTheme";

export const DeliveryStyles = makeStyles({
  selectedDeliveryOption: {},
  notSelectedDeliveryOption: {
    opacity: 0.3,
  },
  selectedWhenToSendOption: {},
  notSelectedWhenToSendOption: {
    opacity: 0.3,
  },
  receiverTooltip: {
    fontSize: "1.8rem",
    [theme.breakpoints.down("lg")]: {
      visibility: "hidden",
      height: 0,
    },
    [theme.breakpoints.up("lg")]: {
      visibility: "visible",
    },
  },
  receiverIconBtn: {
    [theme.breakpoints.down("lg")]: {
      visibility: "visible",
    },
    [theme.breakpoints.up("lg")]: {
      visibility: "hidden",
      height: 0,
    },
  },
  textfield: { paddingTop: 30 },
  amountOfReceivers: {
    float: "left",
    paddingTop: 6,
    paddingBottom: 15,
  },
  sendBySMSComponent: {},
  sendByEmailComponent: {},
  sendByEmailPDFComponent: {},
});
