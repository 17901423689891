import React, { useState } from "react";
import { Grid, Button, useMediaQuery } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import CSTextField from "../../../../../components/Input/Textfield/CSTextField";
import ProcessCircle from "../../../../../components/ProcessCircle/ProcessCircle";
import { RootState } from "../../../../../store";
import { LabelKeys } from "../../../../../Utils/enums/labelKeys";
import { FieldNames } from "../../../../../Utils/types";
import { ValidateEmail } from "../../../../../Utils/validations";
import { AdminStyles } from "../../../styles";
import { IAddResellerContact, IAddResellerContactState, IAddResellerContactErrorState, IAddResellerContactDto } from "../../../types";
import { GetValuesAsArray, ValidateDeliveryEmails } from "../../../../../Utils/extensions";
import { theme } from "../../../../../appTheme";
import { RequestFrom } from "../../../../../Modules/Reseller/types";

interface IAddResellerContactProps {
  onSave: (data: IAddResellerContact) => void;
  onClose: () => void;
}

export default function AddResellerContact(props: IAddResellerContactProps) {
  const { t: translate } = useTranslation();
  const isMobile = useMediaQuery(theme.breakpoints.only("xs"));
  const classes = AdminStyles();
  const reducerState = useSelector(
    (s: RootState) => {
      return {
        reseller: s.reseller,
      };
    },
    (prev, next) =>
      prev.reseller.isLoading === next.reseller.isLoading &&
      prev.reseller.selectedAdminResellerRow === next.reseller.selectedAdminResellerRow
  );

  const [addResellerContactState, setAddResellerContactState] = useState({
    customerNumber: reducerState.reseller.selectedAdminResellerRow.customerNumber,
    emails: "",
  } as IAddResellerContactState);
  const [addResellerContactErrorState, setAddResellerContactErrorState] = useState({ emails: false } as IAddResellerContactErrorState);

  function handleFieldOnChange(fieldName: FieldNames, value: string | Date | boolean | number | undefined) {
    setAddResellerContactState((state) => ({
      ...state,
      [fieldName]: value,
    }));
  }

  function handleSetErrorState(fieldName: FieldNames, value: string | boolean) {
    setAddResellerContactErrorState((state) => ({
      ...state,
      [fieldName]: value,
    }));
  }

  function handleSaveData() {
    const valid: boolean = ValidateDeliveryEmails(addResellerContactState.emails);

    if (valid) {
      handleSetErrorState(FieldNames.emails, false);
      const emails = GetValuesAsArray(addResellerContactState.emails);
      let contactList: IAddResellerContactDto[] = [];

      if (emails?.length > 0) {
        emails?.forEach((element) => {
          contactList.push({ email: element, isActive: true } as IAddResellerContactDto);
        });

        if (contactList?.length > 0) {
          props.onSave({
            customerNumber: addResellerContactState.customerNumber,
            resellerContacts: contactList,
            requestFrom: RequestFrom.UserAdmin,
          } as IAddResellerContact);
          props.onClose();
        }
      }
    } else {
      handleSetErrorState(FieldNames.emails, true);
    }
  }

  return (
    <Grid container item xs={12} sm={12} md={12} lg={12} xl={12}>
      {reducerState.reseller?.isLoading && <ProcessCircle />}
      <Grid
        container
        item
        xs={12}
        sm={12}
        md={12}
        lg={12}
        xl={12}
        style={{
          textAlign: "left",
          display: "flex",
        }}
        alignContent={"flex-start"}
        className={classes.leftContent}
      >
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ paddingBottom: 10, marginTop: 30 }}>
          <CSTextField
            id="addResellerContactEmail"
            type="email"
            multiline
            rows={10}
            placeholder={translate(LabelKeys.CreateResellerContactEmail)}
            value={addResellerContactState.emails ?? ""}
            onChange={(e) => handleFieldOnChange(FieldNames.emails, e.target.value)}
            error={addResellerContactErrorState.emails}
            helperText={translate(LabelKeys.InvalidEmailFormat)}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={6} xl={6} style={{ paddingTop: 31, paddingRight: isMobile ? 0 : 8 }}>
          <Button
            id="saveBtn"
            style={{
              height: 46,
              width: "100%",
              cursor: "pointer",
              color: "#fff",
              borderRadius: 0,
              backgroundColor: "#000",
            }}
            onClick={() => handleSaveData()}
          >
            <div style={{ margin: "auto", textTransform: "none" }}>{`${translate(LabelKeys.Save)}`}</div>
          </Button>
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={6} xl={6} style={{ paddingTop: 31, paddingLeft: isMobile ? 0 : 8 }}>
          <Button
            id="cancelBtn"
            style={{
              height: 46,
              width: "100%",
              cursor: "pointer",
              color: "#fff",
              borderRadius: 0,
              backgroundColor: "#000",
            }}
            onClick={() => props.onClose()}
          >
            <div style={{ margin: "auto", textTransform: "none" }}>{`${translate(LabelKeys.Cancel)}`}</div>
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
}
