import React, { useEffect } from "react";
import { Grid, Typography } from "@mui/material";
import { TermsOfUseStyles } from "./styles";
import { TermsOfUseDescription, TermsOfUseData } from "./data";
import { LabelKeys } from "../../Utils/enums/labelKeys";
import { useTranslation } from "react-i18next";
import Linkify from "linkify-react";

function TermsOfUse() {
  const classes = TermsOfUseStyles();
  const { t: translate } = useTranslation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const options = {
    target: "_blank",
  };

  return (
    <Grid container className={classes.root} justifyContent="center">
      <Grid container item xs={10} sm={9} md={10} lg={9} xl={9} justifyContent="center">
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} textAlign="left" className={classes.title}>
          <Typography variant="h1">{translate(LabelKeys.TermsOfUseFull)}</Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} textAlign="left">
          <Typography variant="body1" className={classes.description}>
            {TermsOfUseDescription}
          </Typography>
        </Grid>
        <Grid container item xs={12} sm={12} md={12} lg={12} xl={12} textAlign="left">
          <ol>
            {TermsOfUseData.map((termsOfUse: string, index: number) => {
              return (
                <Grid key={index} item xs={12} sm={12} md={12} lg={12} xl={12} className={classes.listItems}>
                  <li style={{ fontFamily: "Mulish" }}>
                    <Linkify tagName="span" options={options}>
                      {termsOfUse}
                    </Linkify>
                  </li>
                </Grid>
              );
            })}
          </ol>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default TermsOfUse;
