export enum ResellerActionTypes {
  //Create reseller
  CREATE_RESELLER_REQUEST = "CREATE_RESELLER_REQUEST",
  CREATE_RESELLER_SUCCESS = "CREATE_RESELLER_SUCCESS",
  CREATE_RESELLER_ERROR = "CREATE_RESELLER_ERROR",

  //Get reseller
  GET_RESELLER_REQUEST = "GET_RESELLER_REQUEST",
  GET_RESELLER_SUCCESS = "GET_RESELLER_SUCCESS",
  GET_RESELLER_ERROR = "GET_RESELLER_ERROR",

  //Get all reseller (for e.g. dropdown in step one)
  GET_ALL_RESELLERS_REQUEST = "GET_ALL_RESELLERS_REQUEST",
  GET_ALL_RESELLERS_SUCCESS = "GET_ALL_RESELLERS_SUCCESS",
  GET_ALL_RESELLERS_ERROR = "GET_ALL_RESELLERS_ERROR",

  //Set reseller for dropdown step one systemadmin(for e.g. dropdown in step one)
  SET_SELECTED_ADMIN_RESELLER_OPTION_REQUEST = "SET_SELECTED_ADMIN_RESELLER_OPTION_REQUEST",
  SET_SELECTED_ADMIN_RESELLER_COUNTRY_OPTION_REQUEST = "SET_SELECTED_ADMIN_RESELLER_COUNTRY_OPTION_REQUEST",

  //Update reseller
  UPDATE_RESELLER_REQUEST = "UPDATE_RESELLER_REQUEST",
  UPDATE_RESELLER_SUCCESS = "UPDATE_RESELLER_SUCCESS",
  UPDATE_RESELLER_ERROR = "UPDATE_RESELLER_ERROR",

  //Search single order by Id
  SEARCH_SINGLE_ORDER_REQUEST = "SEARCH_SINGLE_ORDER_REQUEST",
  SEARCH_SINGLE_ORDER_SUCCESS = "SEARCH_SINGLE_ORDER_SUCCESS",
  SEARCH_SINGLE_ORDER_ERROR = "SEARCH_SINGLE_ORDER_ERROR",

  //Create reseller contact
  CREATE_RESELLER_CONTACT_REQUEST = "CREATE_RESELLER_CONTACT_REQUEST",
  CREATE_RESELLER_CONTACT_SUCCESS = "CREATE_RESELLER_CONTACT_SUCCESS",
  CREATE_RESELLER_CONTACT_ERROR = "CREATE_RESELLER_CONTACT_ERROR",

  //Get reseller contact
  GET_RESELLER_CONTACT_REQUEST = "GET_RESELLER_CONTACT_REQUEST",
  GET_RESELLER_CONTACT_SUCCESS = "GET_RESELLER_CONTACT_SUCCESS",
  GET_RESELLER_CONTACT_ERROR = "GET_RESELLER_CONTACT_ERROR",

  //Update reseller contact
  UPDATE_RESELLER_CONTACT_REQUEST = "UPDATE_RESELLER_CONTACT_REQUEST",
  UPDATE_RESELLER_CONTACT_SUCCESS = "UPDATE_RESELLER_CONTACT_SUCCESS",
  UPDATE_RESELLER_CONTACT_ERROR = "UPDATE_RESELLER_CONTACT_ERROR",

  //Delete reseller contact
  DELETE_RESELLER_CONTACT_REQUEST = "DELETE_RESELLER_CONTACT_REQUEST",
  DELETE_RESELLER_CONTACT_SUCCESS = "DELETE_RESELLER_CONTACT_SUCCESS",
  DELETE_RESELLER_CONTACT_ERROR = "DELETE_RESELLER_CONTACT_ERROR",

  //Get reseller logo
  GET_RESELLER_LOGO_REQUEST = "GET_RESELLER_LOGO_REQUEST",
  GET_RESELLER_LOGO_SUCCESS = "GET_RESELLER_LOGO_SUCCESS",
  GET_RESELLER_LOGO_ERROR = "GET_RESELLER_LOGO_ERROR",

  //Get reseller home page image
  GET_RESELLER_HOME_PAGE_IMAGE_REQUEST = "GET_RESELLER_HOME_PAGE_IMAGE_REQUEST",
  GET_RESELLER_HOME_PAGE_IMAGE_SUCCESS = "GET_RESELLER_HOME_PAGE_IMAGE_SUCCESS",
  GET_RESELLER_HOME_PAGE_IMAGE_ERROR = "GET_RESELLER_HOME_PAGE_IMAGE_ERROR",

  //Get reseller home page image by reseller code
  GET_RESELLER_HOME_PAGE_IMAGE_BY_CODE_REQUEST = "GET_RESELLER_HOME_PAGE_IMAGE_BY_CODE_REQUEST",
  GET_RESELLER_HOME_PAGE_IMAGE_BY_CODE_SUCCESS = "GET_RESELLER_HOME_PAGE_IMAGE_BY_CODE_SUCCESS",
  GET_RESELLER_HOME_PAGE_IMAGE_BY_CODE_ERROR = "GET_RESELLER_HOME_PAGE_IMAGE_BY_CODE_ERROR",

  //Update reseller home page image
  UPDATE_RESELLER_HOME_PAGE_IMAGE_REQUEST = "UPDATE_RESELLER_HOME_PAGE_IMAGE_REQUEST",
  UPDATE_RESELLER_HOME_PAGE_IMAGE_SUCCESS = "UPDATE_RESELLER_HOME_PAGE_IMAGE_SUCCESS",
  UPDATE_RESELLER_HOME_PAGE_IMAGE_ERROR = "UPDATE_RESELLER_HOME_PAGE_IMAGE_ERROR",

  //Delete reseller home page image
  DELETE_RESELLER_HOME_PAGE_IMAGE_REQUEST = "DELETE_RESELLER_HOME_PAGE_IMAGE_REQUEST",
  DELETE_RESELLER_HOME_PAGE_IMAGE_SUCCESS = "DELETE_RESELLER_HOME_PAGE_IMAGE_SUCCESS",
  DELETE_RESELLER_HOME_PAGE_IMAGE_ERROR = "DELETE_RESELLER_HOME_PAGE_IMAGE_ERROR",

  //Delete reseller logo
  DELETE_RESELLER_LOGO_REQUEST = "DELETE_RESELLER_LOGO_REQUEST",
  DELETE_RESELLER_LOGO_SUCCESS = "DELETE_RESELLER_LOGO_SUCCESS",
  DELETE_RESELLER_LOGO_ERROR = "DELETE_RESELLER_LOGO_ERROR",

  //Get reseller admin contact info
  GET_RESELLER_ADMIN_CONTACT_INFO_REQUEST = "GET_RESELLER_ADMIN_CONTACT_INFO_REQUEST",
  GET_RESELLER_ADMIN_CONTACT_INFO_SUCCESS = "GET_RESELLER_ADMIN_CONTACT_INFO_SUCCESS",
  GET_RESELLER_ADMIN_CONTACT_INFO_ERROR = "GET_RESELLER_ADMIN_CONTACT_INFO_ERROR",

  //Update reseller logo
  UPDATE_RESELLER_LOGO_REQUEST = "UPDATE_RESELLER_LOGO_REQUEST",
  UPDATE_RESELLER_LOGO_SUCCESS = "UPDATE_RESELLER_LOGO_SUCCESS",
  UPDATE_RESELLER_LOGO_ERROR = "UPDATE_RESELLER_LOGO_ERRORback",

  //Update reseller logo by id
  GET_RESELLER_LOGO_BY_CODE_REQUEST = "GET_RESELLER_LOGO_BY_CODE_REQUEST",
  GET_RESELLER_LOGO_BY_CODE_SUCCESS = "GET_RESELLER_LOGO_BY_CODE_SUCCESS",
  GET_RESELLER_LOGO_BY_CODE_ERROR = "GET_RESELLER_LOGO_BY_CODE_ERROR",

  //Get reseller by reseller code
  GET_RESELLER_BY_CODE_REQUEST = "GET_RESELLER_BY_CODE_REQUEST",
  GET_RESELLER_BY_CODE_SUCCESS = "GET_RESELLER_BY_CODE_SUCCESS",
  GET_RESELLER_BY_CODE_ERROR = "GET_RESELLER_BY_CODE_ERROR",

  //Reset reducer
  RESET_REDUCER = "RESET_REDUCER",

  //Search single reseller by Id
  SEARCH_SINGLE_RESELLER_REQUEST = "SEARCH_SINGLE_RESELLER_REQUEST",
  SEARCH_SINGLE_RESELLER_SUCCESS = "SEARCH_SINGLE_RESELLER_SUCCESS",
  SEARCH_SINGLE_RESELLER_ERROR = "SEARCH_SINGLE_RESELLER_ERROR",

  //Get reseller
  GET_RESELLERS_REQUEST = "GET_RESELLERS_REQUEST",
  GET_RESELLERS_SUCCESS = "GET_RESELLERS_SUCCESS",
  GET_RESELLERS_ERROR = "GET_RESELLERS_ERROR",

  //Search reseller
  SEARCH_RESELLERS_REQUEST = "SEARCH_RESELLERS_REQUEST",
  SEARCH_RESELLERS_SUCCESS = "SEARCH_RESELLERS_SUCCESS",
  SEARCH_RESELLERS_ERROR = "SEARCH_RESELLERS_ERROR",

  //Get reseller incoming order emails
  GET_INCOMING_RESELLER_ORDER_EMAILS_REQUEST = "GET_INCOMING_RESELLER_ORDER_EMAILS_REQUEST",
  GET_INCOMING_RESELLER_ORDER_EMAILS_SUCCESS = "GET_INCOMING_RESELLER_ORDER_EMAILS_SUCCESS",
  GET_INCOMING_RESELLER_ORDER_EMAILS_ERROR = "GET_INCOMING_RESELLER_ORDER_EMAILS_ERROR",

  //Search reseller coupled order
  SEARCH_RESELLER_COUPLED_ORDERS_REQUEST = "SEARCH_RESELLER_COUPLED_ORDERS_REQUEST",
  SEARCH_RESELLER_COUPLED_ORDERS_SUCCESS = "SEARCH_RESELLER_COUPLED_ORDERS_SUCCESS",
  SEARCH_RESELLER_COUPLED_ORDERS_ERROR = "SEARCH_RESELLER_COUPLED_ORDERS_ERROR",

  //Accept coupled reseller order
  ACCEPT_RESELLER_ORDER_REQUEST = "UPDATE_COUPLED_RESELLER_ORDERS_REQUEST",
  ACCEPT_RESELLER_ORDER_SUCCESS = "UPDATE_COUPLED_RESELLER_ORDERS_SUCCESS",
  ACCEPT_RESELLER_ORDER_ERROR = "UPDATE_COUPLED_RESELLER_ORDERS_ERROR",

  //Decline coupled reseller order
  DECLINE_RESELLER_ORDER_REQUEST = "DECLINE_RESELLER_ORDER_REQUEST",
  DECLINE_RESELLER_ORDER_SUCCESS = "DECLINE_RESELLER_ORDER_SUCCESS",
  DECLINE_RESELLER_ORDER_ERROR = "DECLINE_RESELLER_ORDER_ERROR",

  //Update reseller incoming order emails
  UPDATE_INCOMING_RESELLER_ORDER_EMAILS_REQUEST = "UPDATE_INCOMING_RESELLER_ORDER_EMAILS_REQUEST",
  UPDATE_INCOMING_RESELLER_ORDER_EMAILS_SUCCESS = "UPDATE_INCOMING_RESELLER_ORDER_EMAILS_SUCCESS",
  UPDATE_INCOMING_RESELLER_ORDER_EMAILS_ERROR = "UPDATE_INCOMING_RESELLER_ORDER_EMAILS_ERROR",

  //Get reseller contact page information auth
  GET_RESELLER_CONTACT_PAGE_INFORMATION_AUTH_REQUEST = "GET_RESELLER_CONTACT_PAGE_INFORMATION_AUTH_REQUEST",
  GET_RESELLER_CONTACT_PAGE_INFORMATION_AUTH_SUCCESS = "GET_RESELLER_CONTACT_PAGE_INFORMATION_AUTH_SUCCESS",
  GET_RESELLER_CONTACT_PAGE_INFORMATION_AUTH_ERROR = "GET_RESELLER_CONTACT_PAGE_INFORMATION_AUTH_ERROR",

  //Get reseller contact page information
  GET_RESELLER_CONTACT_PAGE_INFORMATION_REQUEST = "GET_RESELLER_CONTACT_PAGE_INFORMATION_REQUEST",
  GET_RESELLER_CONTACT_PAGE_INFORMATION_SUCCESS = "GET_RESELLER_CONTACT_PAGE_INFORMATION_SUCCESS",
  GET_RESELLER_CONTACT_PAGE_INFORMATION_ERROR = "GET_RESELLER_CONTACT_PAGE_INFORMATION_ERROR",

  //Update reseller contact page information
  UPDATE_RESELLER_CONTACT_PAGE_INFORMATION_REQUEST = "UPDATE_RESELLER_CONTACT_PAGE_INFORMATION_REQUEST",
  UPDATE_RESELLER_CONTACT_PAGE_INFORMATION_SUCCESS = "UPDATE_RESELLER_CONTACT_PAGE_INFORMATION_SUCCESS",
  UPDATE_RESELLER_CONTACT_PAGE_INFORMATION_ERROR = "UPDATE_RESELLER_CONTACT_PAGE_INFORMATION_ERROR",

  //Get reseller home page texts
  GET_RESELLER_HOME_PAGE_TEXTS_REQUEST = "GET_RESELLER_HOME_PAGE_TEXTS_REQUEST",
  GET_RESELLER_HOME_PAGE_TEXTS_SUCCESS = "GET_RESELLER_HOME_PAGE_TEXTS_SUCCESS",
  GET_RESELLER_HOME_PAGE_TEXTS_ERROR = "GET_RESELLER_HOME_PAGE_TEXTS_ERROR",

  //Get reseller home page texts by code
  GET_RESELLER_HOME_PAGE_TEXTS_BY_CODE_REQUEST = "GET_RESELLER_HOME_PAGE_TEXTS_BY_CODE_REQUEST",
  GET_RESELLER_HOME_PAGE_TEXTS_BY_CODE_SUCCESS = "GET_RESELLER_HOME_PAGE_TEXTS_BY_CODE_SUCCESS",
  GET_RESELLER_HOME_PAGE_TEXTS_BY_CODE_ERROR = "GET_RESELLER_HOME_PAGE_TEXTS_BY_CODE_ERROR",

  //Update reseller home page texts
  UPDATE_RESELLER_HOME_PAGE_TEXTS_REQUEST = "UPDATE_RESELLER_HOME_PAGE_TEXTS_REQUEST",
  UPDATE_RESELLER_HOME_PAGE_TEXTS_SUCCESS = "UPDATE_RESELLER_HOME_PAGE_TEXTS_SUCCESS",
  UPDATE_RESELLER_HOME_PAGE_TEXTS_ERROR = "UPDATE_RESELLER_HOME_PAGE_TEXTS_ERROR",

  //Get reseller contacts
  GET_RESELLER_CONTACTS_REQUEST = "GET_RESELLER_CONTACTS_REQUEST",
  GET_RESELLER_CONTACTS_SUCCESS = "GET_RESELLER_CONTACTS_SUCCESS",
  GET_RESELLER_CONTACTS_ERROR = "GET_RESELLER_CONTACTS_ERROR",

  //Validate reseller email (Reseller)
  VALIDATE_RESELLER_EMAIL_REQUEST = "VALIDATE_RESELLER_EMAIL_REQUEST",
  VALIDATE_RESELLER_EMAIL_SUCCESS = "VALIDATE_RESELLER_EMAIL_SUCCESS",
  VALIDATE_RESELLER_EMAIL_ERROR = "VALIDATE_RESELLER_EMAIL_ERROR",
}
