export enum ActionTypes {
  SHOW_SNACKBAR = 'SHOW_SNACKBAR',
  HIDE_SNACKBAR = 'HIDE_SNACKBAR',
}

export type SnackbarColor = 'success' | 'info' | 'warning' | 'error';

export interface Snackbar_Success {
  message: string;
  type: ActionTypes.SHOW_SNACKBAR;
  severity: SnackbarColor;
}

export interface Snackbar_Info {
  message: string;
  type: ActionTypes.SHOW_SNACKBAR;
  severity: SnackbarColor;
}

export interface Snackbar_Warning {
  message: string;
  type: ActionTypes.SHOW_SNACKBAR;
  severity: SnackbarColor;
}

export interface Snackbar_Danger {
  message: string;
  type: ActionTypes.SHOW_SNACKBAR;
  severity: SnackbarColor;
}

export interface Hide_Snackbar {
  type: typeof ActionTypes.HIDE_SNACKBAR;
}
