import { OrderActionTypes } from "./actionTypes";
import {
  ICreatePrivateOrderRequest,
  ICreatePrivateOrderResponse,
  ICreateCompanyOrderRequest,
  ICreateOrderResponse,
  IResendOrderRequest,
  ISearchSingleOrderRequest,
  ISearchOrdersRequest,
  ISearchOrdersResponse,
  ISearchSingleOrderResponse,
  IResendReceiptsRequest,
  IUpdateOrderLineRequest,
  IStandardResponse,
  IResendOrderLineRequest,
  ISetStepState,
  IGetOrderConfirmationDetailsRequest,
  IGetOrderConfirmationDetailsResponse,
  IMarkOrderAsDoneRequest,
  IRefundOrderRequest,
  ICreateAdminOrderRequest,
  ICreateAdminOrderResponse,
  IResendGiftCardsToPrinterRequest,
  IResendOrderToNavRequest,
} from "./types";

//Create order (Private)
interface Create_Private_Order_Request {
  type: OrderActionTypes.CREATE_PRIVATE_ORDER_REQUEST;
  request: ICreatePrivateOrderRequest;
}
interface Create_Private_Order_Success {
  type: OrderActionTypes.CREATE_PRIVATE_ORDER_SUCCESS;
  response: ICreatePrivateOrderResponse;
}
interface Create_Private_Order_Error {
  type: OrderActionTypes.CREATE_PRIVATE_ORDER_ERROR;
}

//Create order (Company)
interface Create_Company_Order_Request {
  type: OrderActionTypes.CREATE_COMPANY_ORDER_REQUEST;
  request: ICreateCompanyOrderRequest;
}
interface Create_Company_Order_Success {
  type: OrderActionTypes.CREATE_COMPANY_ORDER_SUCCESS;
  response: ICreateOrderResponse;
}
interface Create_Company_Order_Error {
  type: OrderActionTypes.CREATE_COMPANY_ORDER_ERROR;
}

//Create order (Admin)
interface Create_Admin_Order_Request {
  type: OrderActionTypes.CREATE_ADMIN_ORDER_REQUEST;
  request: ICreateAdminOrderRequest;
}
interface Create_Admin_Order_Success {
  type: OrderActionTypes.CREATE_ADMIN_ORDER_SUCCESS;
  response: ICreateAdminOrderResponse;
}
interface Create_Admin_Order_Error {
  type: OrderActionTypes.CREATE_ADMIN_ORDER_ERROR;
}

//Refund order
interface Refund_Order_Request {
  type: OrderActionTypes.REFUND_ORDERS_REQUEST;
  request: IRefundOrderRequest;
}
interface Refund_Order_Success {
  type: OrderActionTypes.REFUND_ORDERS_SUCCESS;
}
interface Refund_Order_Error {
  type: OrderActionTypes.REFUND_ORDERS_ERROR;
}

//Resend orders
interface Resend_Orders_Request {
  type: OrderActionTypes.RESEND_ORDERS_REQUEST;
  request: IResendOrderRequest;
}
interface Resend_Orders_Success {
  type: OrderActionTypes.RESEND_ORDERS_SUCCESS;
}
interface Resend_Orders_Error {
  type: OrderActionTypes.RESEND_ORDERS_ERROR;
}

//Resend receipts
interface Resend_Receipts_Request {
  type: OrderActionTypes.RESEND_RECEIPTS_REQUEST;
  request: IResendReceiptsRequest;
}
interface Resend_Receipts_Success {
  type: OrderActionTypes.RESEND_RECEIPTS_SUCCESS;
}
interface Resend_Receipts_Error {
  type: OrderActionTypes.RESEND_RECEIPTS_ERROR;
}

//Search single order by Id
interface Search_Single_Order_Request {
  type: OrderActionTypes.SEARCH_SINGLE_ORDER_REQUEST;
  request: ISearchSingleOrderRequest;
}
interface Search_Single_Order_Success {
  type: OrderActionTypes.SEARCH_SINGLE_ORDER_SUCCESS;
  response: ISearchSingleOrderResponse;
}
interface Search_Single_Order_Error {
  type: OrderActionTypes.SEARCH_SINGLE_ORDER_ERROR;
}

//Reset
interface Reset_Reducer {
  type: OrderActionTypes.RESET_REDUCER;
}
interface Reset_Step_One_State {
  type: OrderActionTypes.RESET_STEP_ONE_STATE;
}
interface Reset_Step_Two_State {
  type: OrderActionTypes.RESET_STEP_TWO_STATE;
}
interface Reset_Step_Three_State {
  type: OrderActionTypes.RESET_STEP_THREE_STATE;
}
interface Reset_Step_Four_State {
  type: OrderActionTypes.RESET_STEP_FOUR_STATE;
}
interface Reset_Step_Five_State {
  type: OrderActionTypes.RESET_STEP_FIVE_STATE;
}
interface Reset_Step_Six_State {
  type: OrderActionTypes.RESET_STEP_SIX_STATE;
}
interface Reset_Stepper_State {
  type: OrderActionTypes.RESET_STEPPER_STATE;
}

//Search orders
interface Search_Orders_Request {
  type: OrderActionTypes.SEARCH_ORDERS_REQUEST;
  request: ISearchOrdersRequest;
}
interface Search_Orders_Success {
  type: OrderActionTypes.SEARCH_ORDERS_SUCCESS;
  response: ISearchOrdersResponse;
}
interface Search_Orders_Error {
  type: OrderActionTypes.SEARCH_ORDERS_ERROR;
}

//Update orderLine
interface Update_OrderLine_Request {
  type: OrderActionTypes.UPDATE_ORDERLINE_REQUEST;
  request: IUpdateOrderLineRequest;
}
interface Update_OrderLine_Success {
  type: OrderActionTypes.UPDATE_ORDERLINE_SUCCESS;
  response: IStandardResponse;
}
interface Update_OrderLine_Error {
  type: OrderActionTypes.UPDATE_ORDERLINE_ERROR;
}

//Resend orderLine
interface Resend_OrderLine_Request {
  type: OrderActionTypes.RESEND_ORDERLINE_REQUEST;
  request: IResendOrderLineRequest;
}
interface Resend_OrderLine_Success {
  type: OrderActionTypes.RESEND_ORDERLINE_SUCCESS;
  response: IStandardResponse;
}
interface Resend_OrderLine_Error {
  type: OrderActionTypes.RESEND_ORDERLINE_ERROR;
}

//Get order confirmation details
interface Get_Order_Confirmation_Details_Request {
  type: OrderActionTypes.GET_ORDER_CONFIRMATION_DETAILS_REQUEST;
  request: IGetOrderConfirmationDetailsRequest;
}
interface Get_Order_Confirmation_Details_Success {
  type: OrderActionTypes.GET_ORDER_CONFIRMATION_DETAILS_SUCCESS;
  response: IGetOrderConfirmationDetailsResponse;
}
interface Get_Order_Confirmation_Details_Error {
  type: OrderActionTypes.GET_ORDER_CONFIRMATION_DETAILS_ERROR;
}

//Step states
interface Set_Environment_State_Request {
  type: OrderActionTypes.SET_ENVIRONMENT_STATE_REQUEST;
  request: ISetStepState;
}
interface Set_Stepper_State_Request {
  type: OrderActionTypes.SET_STEPPER_STATE_REQUEST;
  request: ISetStepState;
}
interface Set_State_One_Request {
  type: OrderActionTypes.SET_STEP_ONE_STATE_REQUEST;
  request: ISetStepState;
}
interface Set_State_Two_Request {
  type: OrderActionTypes.SET_STEP_TWO_STATE_REQUEST;
  request: ISetStepState;
}
interface Set_State_Three_Request {
  type: OrderActionTypes.SET_STEP_THREE_STATE_REQUEST;
  request: ISetStepState;
}
interface Set_State_Four_Request {
  type: OrderActionTypes.SET_STEP_FOUR_STATE_REQUEST;
  request: ISetStepState;
}
interface Set_State_Five_Request {
  type: OrderActionTypes.SET_STEP_FIVE_STATE_REQUEST;
  request: ISetStepState;
}
interface Set_State_Six_Request {
  type: OrderActionTypes.SET_STEP_SIX_STATE_REQUEST;
  request: ISetStepState;
}

interface Mark_Order_As_Done_Request {
  type: OrderActionTypes.MARK_ORDER_AS_DONE_REQUEST;
  request: IMarkOrderAsDoneRequest;
}
interface Mark_Order_As_Done_Success {
  type: OrderActionTypes.MARK_ORDER_AS_DONE_SUCCESS;
  response: IStandardResponse;
}
interface Mark_Order_As_Done_Error {
  type: OrderActionTypes.MARK_ORDER_AS_DONE_ERROR;
}

interface Resend_Order_To_Nav_Request {
  type: OrderActionTypes.RESEND_ORDER_TO_NAV_REQUEST;
  request: IResendOrderToNavRequest;
}
interface Resend_Order_To_Nav_Success {
  type: OrderActionTypes.RESEND_ORDER_TO_NAV_SUCCESS;
  response: IStandardResponse;
}
interface Resend_Order_To_Nav_Error {
  type: OrderActionTypes.RESEND_ORDER_TO_NAV_ERROR;
}

interface Resend_Gift_Cards_To_Printer_Request {
  type: OrderActionTypes.RESEND_GIFT_CARDS_TO_PRINTER_REQUEST;
  request: IResendGiftCardsToPrinterRequest;
}
interface Resend_Gift_Cards_To_Printer_Success {
  type: OrderActionTypes.RESEND_GIFT_CARDS_TO_PRINTER_SUCCESS;
  response: IStandardResponse;
}
interface Resend_Gift_Cards_To_Printer_Error {
  type: OrderActionTypes.RESEND_GIFT_CARDS_TO_PRINTER_ERROR;
}

export const OrderActions = {
  //Create order (Private)
  CreatePrivateOrderRequest: (request: ICreatePrivateOrderRequest): Create_Private_Order_Request => ({
    type: OrderActionTypes.CREATE_PRIVATE_ORDER_REQUEST,
    request,
  }),
  CreatePrivateOrderSuccess: (response: ICreatePrivateOrderResponse): Create_Private_Order_Success => ({
    type: OrderActionTypes.CREATE_PRIVATE_ORDER_SUCCESS,
    response,
  }),
  CreatePrivateOrderError: (): Create_Private_Order_Error => ({
    type: OrderActionTypes.CREATE_PRIVATE_ORDER_ERROR,
  }),

  //Create order (Company)
  CreateCompanyOrderRequest: (request: ICreateCompanyOrderRequest): Create_Company_Order_Request => ({
    type: OrderActionTypes.CREATE_COMPANY_ORDER_REQUEST,
    request,
  }),
  CreateCompanyOrderSuccess: (response: ICreateOrderResponse): Create_Company_Order_Success => ({
    type: OrderActionTypes.CREATE_COMPANY_ORDER_SUCCESS,
    response,
  }),
  CreateCompanyOrderError: (): Create_Company_Order_Error => ({
    type: OrderActionTypes.CREATE_COMPANY_ORDER_ERROR,
  }),

  //Create order (admin)
  CreateAdminOrderRequest: (request: ICreateAdminOrderRequest): Create_Admin_Order_Request => ({
    type: OrderActionTypes.CREATE_ADMIN_ORDER_REQUEST,
    request,
  }),
  CreateAdminOrderSuccess: (response: ICreateAdminOrderResponse): Create_Admin_Order_Success => ({
    type: OrderActionTypes.CREATE_ADMIN_ORDER_SUCCESS,
    response,
  }),
  CreateAdminOrderError: (): Create_Admin_Order_Error => ({
    type: OrderActionTypes.CREATE_ADMIN_ORDER_ERROR,
  }),

  //Refund order
  RefundOrderRequest: (request: IRefundOrderRequest): Refund_Order_Request => ({
    type: OrderActionTypes.REFUND_ORDERS_REQUEST,
    request,
  }),
  RefundOrderSuccess: (): Refund_Order_Success => ({
    type: OrderActionTypes.REFUND_ORDERS_SUCCESS,
  }),
  RefundOrderError: (): Refund_Order_Error => ({
    type: OrderActionTypes.REFUND_ORDERS_ERROR,
  }),

  //Resend orders
  ResendOrdersRequest: (request: IResendOrderRequest): Resend_Orders_Request => ({
    type: OrderActionTypes.RESEND_ORDERS_REQUEST,
    request,
  }),
  ResendOrdersSuccess: (): Resend_Orders_Success => ({
    type: OrderActionTypes.RESEND_ORDERS_SUCCESS,
  }),
  ResendOrdersError: (): Resend_Orders_Error => ({
    type: OrderActionTypes.RESEND_ORDERS_ERROR,
  }),

  //Resend receipts
  ResendReceiptsRequest: (request: IResendReceiptsRequest): Resend_Receipts_Request => ({
    type: OrderActionTypes.RESEND_RECEIPTS_REQUEST,
    request,
  }),
  ResendReceiptsSuccess: (): Resend_Receipts_Success => ({
    type: OrderActionTypes.RESEND_RECEIPTS_SUCCESS,
  }),
  ResendReceiptsError: (): Resend_Receipts_Error => ({
    type: OrderActionTypes.RESEND_RECEIPTS_ERROR,
  }),

  //Search order by Id
  SearchSingleOrderRequest: (request: ISearchSingleOrderRequest): Search_Single_Order_Request => ({
    type: OrderActionTypes.SEARCH_SINGLE_ORDER_REQUEST,
    request,
  }),
  SearchSingleOrderSuccess: (response: ISearchSingleOrderResponse): Search_Single_Order_Success => ({
    type: OrderActionTypes.SEARCH_SINGLE_ORDER_SUCCESS,
    response,
  }),
  SearchSingleOrderError: (): Search_Single_Order_Error => ({
    type: OrderActionTypes.SEARCH_SINGLE_ORDER_ERROR,
  }),

  //Search orders
  SearchOrdersRequest: (request: ISearchOrdersRequest): Search_Orders_Request => ({
    type: OrderActionTypes.SEARCH_ORDERS_REQUEST,
    request,
  }),
  SearchOrdersSuccess: (response: ISearchOrdersResponse): Search_Orders_Success => ({
    type: OrderActionTypes.SEARCH_ORDERS_SUCCESS,
    response,
  }),
  SearchOrdersError: (): Search_Orders_Error => ({
    type: OrderActionTypes.SEARCH_ORDERS_ERROR,
  }),

  //Update orderLine
  UpdateOrderLineRequest: (request: IUpdateOrderLineRequest): Update_OrderLine_Request => ({
    type: OrderActionTypes.UPDATE_ORDERLINE_REQUEST,
    request,
  }),
  UpdateOrderLineSuccess: (response: IStandardResponse): Update_OrderLine_Success => ({
    type: OrderActionTypes.UPDATE_ORDERLINE_SUCCESS,
    response,
  }),
  UpdateOrderLineError: (): Update_OrderLine_Error => ({
    type: OrderActionTypes.UPDATE_ORDERLINE_ERROR,
  }),

  //Resend orderLine
  ResendOrderLineRequest: (request: IResendOrderLineRequest): Resend_OrderLine_Request => ({
    type: OrderActionTypes.RESEND_ORDERLINE_REQUEST,
    request,
  }),
  ResendOrderLineSuccess: (response: IStandardResponse): Resend_OrderLine_Success => ({
    type: OrderActionTypes.RESEND_ORDERLINE_SUCCESS,
    response,
  }),
  ResendOrderLineError: (): Resend_OrderLine_Error => ({
    type: OrderActionTypes.RESEND_ORDERLINE_ERROR,
  }),

  //Get order confirmation details
  GetOrderConfirmationDetailsRequest: (request: IGetOrderConfirmationDetailsRequest): Get_Order_Confirmation_Details_Request => ({
    type: OrderActionTypes.GET_ORDER_CONFIRMATION_DETAILS_REQUEST,
    request,
  }),
  GetOrderConfirmationDetailsSuccess: (response: IGetOrderConfirmationDetailsResponse): Get_Order_Confirmation_Details_Success => ({
    type: OrderActionTypes.GET_ORDER_CONFIRMATION_DETAILS_SUCCESS,
    response,
  }),
  GetOrderConfirmationDetailsError: (): Get_Order_Confirmation_Details_Error => ({
    type: OrderActionTypes.GET_ORDER_CONFIRMATION_DETAILS_ERROR,
  }),

  //Reset
  ResetReducer: (): Reset_Reducer => ({
    type: OrderActionTypes.RESET_REDUCER,
  }),
  ResetStepOneState: (): Reset_Step_One_State => ({
    type: OrderActionTypes.RESET_STEP_ONE_STATE,
  }),
  ResetStepTwoState: (): Reset_Step_Two_State => ({
    type: OrderActionTypes.RESET_STEP_TWO_STATE,
  }),
  ResetStepThreeState: (): Reset_Step_Three_State => ({
    type: OrderActionTypes.RESET_STEP_THREE_STATE,
  }),
  ResetStepFourState: (): Reset_Step_Four_State => ({
    type: OrderActionTypes.RESET_STEP_FOUR_STATE,
  }),
  ResetStepFiveState: (): Reset_Step_Five_State => ({
    type: OrderActionTypes.RESET_STEP_FIVE_STATE,
  }),
  ResetStepSixState: (): Reset_Step_Six_State => ({
    type: OrderActionTypes.RESET_STEP_SIX_STATE,
  }),
  ResetStepperState: (): Reset_Stepper_State => ({
    type: OrderActionTypes.RESET_STEPPER_STATE,
  }),

  //Step states
  SetEnvironmentStateRequest: (request: ISetStepState): Set_Environment_State_Request => ({
    type: OrderActionTypes.SET_ENVIRONMENT_STATE_REQUEST,
    request,
  }),
  SetStepperStateRequest: (request: ISetStepState): Set_Stepper_State_Request => ({
    type: OrderActionTypes.SET_STEPPER_STATE_REQUEST,
    request,
  }),
  SetStepOneStateRequest: (request: ISetStepState): Set_State_One_Request => ({
    type: OrderActionTypes.SET_STEP_ONE_STATE_REQUEST,
    request,
  }),
  SetStepTwoStateRequest: (request: ISetStepState): Set_State_Two_Request => ({
    type: OrderActionTypes.SET_STEP_TWO_STATE_REQUEST,
    request,
  }),
  SetStepThreeStateRequest: (request: ISetStepState): Set_State_Three_Request => ({
    type: OrderActionTypes.SET_STEP_THREE_STATE_REQUEST,
    request,
  }),
  SetStepFourStateRequest: (request: ISetStepState): Set_State_Four_Request => ({
    type: OrderActionTypes.SET_STEP_FOUR_STATE_REQUEST,
    request,
  }),
  SetStepFiveStateRequest: (request: ISetStepState): Set_State_Five_Request => ({
    type: OrderActionTypes.SET_STEP_FIVE_STATE_REQUEST,
    request,
  }),
  SetStepSixStateRequest: (request: ISetStepState): Set_State_Six_Request => ({
    type: OrderActionTypes.SET_STEP_SIX_STATE_REQUEST,
    request,
  }),
  MarkOrderAsDoneRequest: (request: IMarkOrderAsDoneRequest): Mark_Order_As_Done_Request => ({
    type: OrderActionTypes.MARK_ORDER_AS_DONE_REQUEST,
    request,
  }),
  MarkOrderAsDoneSuccess: (response: IStandardResponse): Mark_Order_As_Done_Success => ({
    type: OrderActionTypes.MARK_ORDER_AS_DONE_SUCCESS,
    response,
  }),
  MarkOrderAsDoneError: (): Mark_Order_As_Done_Error => ({
    type: OrderActionTypes.MARK_ORDER_AS_DONE_ERROR,
  }),

  ResendOrderToNavRequest: (request: IResendOrderToNavRequest): Resend_Order_To_Nav_Request => ({
    type: OrderActionTypes.RESEND_ORDER_TO_NAV_REQUEST,
    request,
  }),
  ResendOrderToNavSuccess: (response: IStandardResponse): Resend_Order_To_Nav_Success => ({
    type: OrderActionTypes.RESEND_ORDER_TO_NAV_SUCCESS,
    response,
  }),
  ResendOrderToNavError: (): Resend_Order_To_Nav_Error => ({
    type: OrderActionTypes.RESEND_ORDER_TO_NAV_ERROR,
  }),

  ResendGiftCardsToPrinterRequest: (request: IResendGiftCardsToPrinterRequest): Resend_Gift_Cards_To_Printer_Request => ({
    type: OrderActionTypes.RESEND_GIFT_CARDS_TO_PRINTER_REQUEST,
    request,
  }),
  ResendGiftCardsToPrinterSuccess: (response: IStandardResponse): Resend_Gift_Cards_To_Printer_Success => ({
    type: OrderActionTypes.RESEND_GIFT_CARDS_TO_PRINTER_SUCCESS,
    response,
  }),
  ResendGiftCardsToPrinterError: (): Resend_Gift_Cards_To_Printer_Error => ({
    type: OrderActionTypes.RESEND_GIFT_CARDS_TO_PRINTER_ERROR,
  }),
};
