import React from "react";
import { Menu, MenuItem } from "@mui/material";
import { LabelKeys } from "../../Utils/enums/labelKeys";
import { useTranslation } from "react-i18next";

interface IAdminOrderDetailsDotMenu {
  open: boolean;
  className?: string;
  onSetIsRefundOrderDialogOpen: () => void;
  resendReceipt: () => void;
  resendOrder: () => void;
  resendOrderToNav: () => void;
  resendToGiftCardPrinter: () => void;
  onClose: () => void;
  onEscapeKeyDown?: () => void;
  anchorEl: any;
  showRefundOption: boolean;
  disableResendNav: boolean;
  disableResendToPrinter: boolean;
  disableResendOrder: boolean;
  disableResendReceipt: boolean;
  disableRefund: boolean;
}

export default function AdminOrderDetailsDotMenu(props: IAdminOrderDetailsDotMenu) {
  const { t: translate } = useTranslation();

  return (
    <Menu
      anchorEl={props.anchorEl}
      disableScrollLock
      open={props.open}
      className={props.className}
      onBackdropClick={() => props.onClose()}
      onKeyDown={(e) => {
        if (e.key === "Escape") {
          props.onEscapeKeyDown();
        }
      }}
    >
      <MenuItem disabled={props.disableResendOrder} onClick={() => props.resendOrder()}>
        {translate(LabelKeys.ResendOrder)}
      </MenuItem>
      <MenuItem disabled={props.disableResendReceipt} onClick={() => props.resendReceipt()}>
        {translate(LabelKeys.ResendReceipt)}
      </MenuItem>
      {props.showRefundOption && (
        <MenuItem disabled={props.disableRefund} onClick={() => props.onSetIsRefundOrderDialogOpen()}>
          {translate(LabelKeys.RefundOrder)}
        </MenuItem>
      )}
      <MenuItem disabled={props.disableResendNav} onClick={() => props.resendOrderToNav()}>
        {translate(LabelKeys.ResendToNav)}
      </MenuItem>
      <MenuItem disabled={props.disableResendToPrinter} onClick={() => props.resendToGiftCardPrinter()}>
        {translate(LabelKeys.ResendGiftCardsToPrinter)}
      </MenuItem>
    </Menu>
  );
}
