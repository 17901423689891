import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import Message from "../Message/Message";
import { IImageOption, IProduct } from "../../Modules/Products/types";
import { StateNames, FieldNames, GetCurrentLanguageAsText } from "../../Utils/types";
import { SendBy, WhenToSend } from "../Delivery/types";
import { IFilterOption } from "../DropDown/types";
import { IUploadedFile } from "../FileUploader/types";
import { IMessageState } from "../Message/types";
import { SystemPart } from "../../Utils/enums/enums";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import { useTranslation } from "react-i18next";
import { IsResellerAuth, IsAuth } from "../../Utils/authentication";
import { LabelKeys } from "../../Utils/enums/labelKeys";

interface ICSStepperStepThreeProps {
  id: string;
  systemPart: SystemPart;
  sendBy: SendBy;
  selectedProduct: IProduct;
  smsMessage: string;
  emailMessage: string;
  emailPDFMessage: string;
  selectedMessageTemplate: IFilterOption;
  messageTemplates: IFilterOption[];
  emailSubject: string;
  messageSignature: string;
  orderDate: Date;
  customMessage: boolean;
  customWebPage: boolean;
  acceptedPDFPreview: boolean;
  selectedImage: IImageOption;
  uploadedImage: IUploadedFile;
  physicalCardSelectedCoverImage?: IImageOption;
  physicalCardMiddlePageImage?: string;
  physicalCardSelectedUploadedCoverImage?: IUploadedFile;
  physicalCardSelectedUploadedLogotype?: IUploadedFile;
  physicalCardHeading?: string;
  physicalCardCustomMessage?: string;
  onStateChanged: (
    stateName: StateNames.stepperThreeState | StateNames.stepperFourState | StateNames.stepperFiveState,
    value: string | boolean | number | IImageOption | IUploadedFile | SendBy | IMessageState | IFilterOption | WhenToSend | Date,
    fieldName: FieldNames
  ) => void;
  acceptedPDFPreviewHasError: boolean;
  physicalCardCoverImageHasError: boolean;
}

export default function CSStepperStepThree(props: ICSStepperStepThreeProps) {
  const navigate = useNavigate();
  const { t: translate } = useTranslation();
  const isAdmin =
    (IsResellerAuth() &&
      location.pathname.includes(
        `/${translate(LabelKeys.ResellerUrl)}/${translate(LabelKeys.AdminUrl)}/${translate(LabelKeys.OrderUrl)}`
      )) ||
    (IsAuth() && location.pathname.includes(`/${translate(LabelKeys.AdminUrl)}/${translate(LabelKeys.OrderUrl)}`));
  const reducerState = useSelector(
    (s: RootState) => {
      return {
        order: s.order,
      };
    },
    (prev, next) => prev.order.environmentState.systemPart === next.order.environmentState.systemPart
  );

  useEffect(() => {
    window.scroll(0, 0);
    if (reducerState.order.environmentState.systemPart === undefined && !isAdmin) {
      navigate(`/${GetCurrentLanguageAsText()}/`);
    }
  }, []);

  return (
    <Grid
      id={props.id}
      container
      item
      xs={12}
      sm={12}
      md={12}
      lg={12}
      xl={12}
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start",
        alignItems: "flex-start",
      }}
    >
      <Message
        id="messageComponent"
        sendBy={props.sendBy}
        smsMessage={props.smsMessage}
        emailMessage={props.emailMessage}
        emailPDFMessage={props.emailPDFMessage}
        selectedMessageTemplate={props.selectedMessageTemplate}
        messageTemplates={props.messageTemplates}
        selectedImage={props.selectedImage}
        orderDate={props.orderDate}
        uploadedImage={props.uploadedImage}
        customWebPage={props.customWebPage}
        emailSubject={props.emailSubject}
        customMessage={props.customMessage}
        acceptedPDFPreview={props.acceptedPDFPreview}
        messageSignature={props.messageSignature}
        onStateChanged={(stateName, value, fieldName) => props.onStateChanged(stateName, value, fieldName)}
        systemPart={props.systemPart}
        selectedProduct={props.selectedProduct}
        physicalCardSelectedCoverImage={props.physicalCardSelectedCoverImage}
        physicalCardMiddlePageImage={props.physicalCardMiddlePageImage}
        physicalCardSelectedUploadedCoverImage={props.physicalCardSelectedUploadedCoverImage}
        physicalCardSelectedUploadedLogotype={props.physicalCardSelectedUploadedLogotype}
        physicalCardHeading={props.physicalCardHeading}
        physicalCardCustomMessage={props.physicalCardCustomMessage}
        acceptedPDFPreviewHasError={props.acceptedPDFPreviewHasError}
        physicalCardCoverImageHasError={props.physicalCardCoverImageHasError}
      />
    </Grid>
  );
}
