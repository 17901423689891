import { TemplateType } from "./../../components/Message/types";
import { Currency } from "./../../Utils/enums/enums";
import { LabelKeys } from "./../../Utils/enums/labelKeys";
import { HeadCell } from "../../components/Table/types";
import { FileType } from "../../components/FileUploader/types";
import { PaymentMethod } from "../../Utils/enums/enums";
import { IExternalSystem } from "./SubViews/ExternalSystem/types";
import { SendBy } from "../../components/Delivery/types";
import { RequestFrom } from "../../Modules/Reseller/types";
import { OrderType } from "../Order/types";

export interface ISignInState {
  email: string;
  validationCode: string;
}

export interface ISignInErrorState {
  email: boolean;
  password: boolean;
}

export interface IAdminState {
  subview: AdminSubview;
}

export interface IAdminInvoiceState {
  createInvoiceFromDate: Date;
  createInvoiceToDate: Date;
  createInvoiceReceiverEmails: string;
  createInvoiceCustomerNumbers: string;
}
export interface IAdminExternalSystemState {
  externalSystemDetails: IExternalSystem;
  selectedExternalSystemRow: IExternalSystem;
  externalSystemRows: IExternalSystem[];
}

export interface IAddResellerContactState {
  customerNumber: string;
  emails: string;
}

export interface IAddResellerContactErrorState {
  emails: boolean;
}

export interface IEditResellerContactState {
  customerNumber: string;
  email: string;
  isActive: boolean;
}

export interface IAdminInvoiceErrorState {
  createInvoice: boolean;
  createInvoiceFromDate: boolean;
  createInvoiceToDate: boolean;
  createInvoiceCustomerNumbers: boolean;
  createInvoiceReceiverEmails: boolean;
}
export interface IAdminSearchOrderErrorState {
  searchedOrderId: boolean;
  searchedCustomerNumber: boolean;
}

export enum AdminSubview {
  None,
  Invoice,
  Order,
  Reseller,
  Media,
  Template,
  ExternalSystem,
}

export interface IAdminOrderSearchState {
  searchParameter: string;
  searchOrderFromDate: Date;
  searchOrderToDate: Date;
  orderRows: IOrderRow[];
  orderDetailsPopupOpen: boolean;
}

export interface IAdminOrderOrderDetailsState {
  customOrderLines: IOrderLine[];
}

export interface IOrderRow {
  id: number;
  createdDate: Date;
  orderDate: Date;
  name: string;
  status: OrderStatus;
  customWebPage: boolean;
  paymentMethod: PaymentMethod;
  sendBy: SendBy;
  orderType: OrderType;
}

export interface IOrderDetailsRow {
  id: number;
  internalOrderId: string;
  createdDate: Date;
  status: OrderStatus;
  orderDate: Date;
  orderLines: IOrderLine[];
  companyName: string;
  companyContactPhoneNumber: string;
  contactEmail: string;
  organisationNumber: string;
  resellerName: string;
  customWebPage: boolean;
  paymentMethod: PaymentMethod;
  totalOrderValue: number;
  currency: Currency;
  refundedAmount: number;
  paid: boolean;
  enableResendOrder: boolean;
  enableResendReceipt: boolean;
  sendBy: SendBy;
  name: string;
  addressLine: string;
  postalCode: string;
  city: string;
  country: string;
  enableResendNav: boolean;
  orderType: OrderType;
}

//First page, search order page
export interface IResellerRow {
  id: number;
  companyName: string;
  customerNumber: string;
  code: string;
}

//Reseller details
export interface IResellerDetailsRow {
  id: number;
  customerNumber: string;
  isActive: boolean;
  companyName: string;
  code: string;
  discount: number;
  url: string;
  resellerContacts: IResellerContactLine[];
}

export interface IResellerContactLine {
  id: number;
  email: string;
  isActive: boolean;
}

export interface ICustomResellerRow {
  id: number;
  companyName: string;
  customerNumber: string;
  code: string;
}

export interface IOrderLine {
  id: number;
  sentDate: Date;
  status: OrderLineStatus;
  receiver: string;
  number: string;
  claimCode: string;
}

export interface ICustomOrder {
  id: number;
  createdDate: Date;
  status: string;
  orderDate: Date;
}

export interface ICustomOrderLine {
  id: number;
  sentDate: Date;
  status: string;
  receiver: string;
}

export enum OrderStatus {
  Unknown = 0,
  Created = 1,
  Done = 2,
  Failed = 3,
  FailedPayment = 4,
  Refunded = 5,
  Canceled = 6,
  OrderCreatedNav = 7,
}

export enum OrderLineStatus {
  Unknown = 0,
  Created = 1,
  Sent = 2,
  Failed = 3,
  Canceled = 4,
  FailedPayment = 5,
  OrderCreatedNav = 6,
}

export const searchOrderHeaders: HeadCell[] = [
  {
    key: "id",
    labelKey: LabelKeys.OrderId,
    type: "number",
    width: "auto",
    numeric: true,
  },
  {
    key: "createdDate",
    labelKey: LabelKeys.CreatedDate,
    type: "date",
    width: "auto",
    numeric: false,
  },
  {
    key: "orderDate",
    labelKey: LabelKeys.OrderDate,
    type: "date",
    width: "auto",
    numeric: false,
  },
  {
    key: "name",
    labelKey: LabelKeys.Name,
    type: "text",
    width: "auto",
    numeric: false,
  },
  {
    key: "orderType",
    labelKey: LabelKeys.OrderType,
    type: "text",
    width: "auto",
    numeric: false,
  },
  {
    key: "paymentMethod",
    labelKey: LabelKeys.PaymentMethod,
    type: "text",
    width: "auto",
    numeric: false,
  },
  {
    key: "sendBy",
    labelKey: LabelKeys.SendBy,
    type: "text",
    width: "auto",
    numeric: false,
  },
  {
    key: "status",
    labelKey: LabelKeys.OrderStatus,
    type: "text",
    width: "auto",
    numeric: false,
  },
];
export const getTransactionsHeaders: HeadCell[] = [
  {
    key: "id",
    labelKey: LabelKeys.Id,
    type: "number",
    width: "auto",
    numeric: true,
  },
  {
    key: "created",
    labelKey: LabelKeys.Created,
    type: "date",
    width: "auto",
    numeric: false,
  },
  {
    key: "changedBy",
    labelKey: LabelKeys.ChangedBy,
    type: "text",
    width: "auto",
    numeric: false,
  },
  {
    key: "description",
    labelKey: LabelKeys.Description,
    type: "text",
    width: "auto",
    numeric: false,
  },
];

export const searchedOrderOrderLinesHeadCells: HeadCell[] = [
  {
    key: "id",
    labelKey: LabelKeys.OrderLineId,
    type: "number",
    width: "auto",
    numeric: true,
  },
  {
    key: "receiver",
    labelKey: LabelKeys.Receiver,
    width: "auto",
    type: "text",
    numeric: false,
  },
  {
    key: "claimCode",
    labelKey: LabelKeys.Code,
    width: "auto",
    type: "text",
    numeric: false,
  },
  {
    key: "sentDate",
    labelKey: LabelKeys.SentDate,
    width: "auto",
    type: "date",
    numeric: false,
  },
  {
    key: "status",
    labelKey: LabelKeys.OrderStatus,
    width: "auto",
    type: "orderStatus",
    numeric: false,
  },
];

export const searchResellersHeadCells: HeadCell[] = [
  {
    key: "id",
    labelKey: LabelKeys.Id,
    type: "number",
    width: "15%",
    numeric: true,
  },
  {
    key: "customerNumber",
    labelKey: LabelKeys.CustomerNumber,
    width: "25%",
    type: "text",
    numeric: false,
  },
  {
    key: "companyName",
    labelKey: LabelKeys.CompanyName,
    width: "35%",
    type: "text",
    numeric: false,
  },
  {
    key: "code",
    labelKey: LabelKeys.ResellerCode,
    width: "25%",
    type: "text",
    numeric: false,
  },
];

export const searchResellerContactsHeadCells: HeadCell[] = [
  {
    key: "id",
    labelKey: LabelKeys.Id,
    width: "auto",
    type: "number",
    numeric: true,
  },
  {
    key: "email",
    labelKey: LabelKeys.Email,
    width: "auto",
    type: "text",
    numeric: false,
  },
  {
    key: "isActive",
    labelKey: LabelKeys.IsActive,
    width: "auto",
    type: "boolean",
    numeric: false,
  },
  {
    key: "actions",
    width: "auto",
    type: "boolean",
    numeric: false,
  },
];

export interface ITableOrderRow {
  id: number;
  createdDate: string;
  orderDate: string;
  orderStatus: OrderStatus;
}

export const mediaImageUrlsHeadCells: HeadCell[] = [
  {
    key: "id",
    labelKey: LabelKeys.Id,
    type: "number",
    width: 10,
    numeric: true,
  },
  {
    key: "url",
    labelKey: LabelKeys.Url,
    width: 300,
    type: "text",
    numeric: false,
  },
  {
    key: "isStandard",
    labelKey: LabelKeys.IsStandard,
    width: "auto",
    type: "boolean",
    numeric: false,
  },
];

export interface IResellerDataSectionState {
  id: number;
  customerNumber: string;
  companyName: string;
  isActive: boolean;
  discount: number;
}
export interface IResellerLogoUrlSectionState {
  id: number;
  resellerLogoAsBase64: string;
  resellerLogoType: FileType;
  url: string;
}

export interface IReseller {
  companyName: string;
  customerNumber: string;
  discount: number;
  contactEmails: string;
}

export interface IEditResellerDataData {
  id: number;
  companyName: string;
  customerNumber: string;
  discount: number;
  isActive: boolean;
}
export interface IEditResellerContactPageInformationData {
  contactEmails: string;
  contactPhoneNumbers: string;
  addressLine1: string;
  postalCode: string;
  city: string;
  country: string;
  website: string;
}
// export interface IAddResellerContactData {
//   email: string;
//   customerNumber: string;
// }

export interface IAddResellerContact {
  resellerContacts: IAddResellerContactDto[];
  requestFrom: RequestFrom;
}

export interface IAddResellerContactDto {
  email: string;
  isActive: boolean;
}

export interface IReseller {
  id: number;
  companyName: string;
  customerNumber: string;
  discount: number;
  contactEmails: string;
}

export interface IEditResellerDataState {
  id: number;
  customerNumber: string;
  isActive: boolean;
  companyName: string;
  discount: number;
}

export interface IEditResellerContactPageDataState {
  contactEmails: string;
  contactPhoneNumbers: string;
  addressLine1: string;
  postalCode: string;
  city: string;
  country: string;
  website: string;
}

export interface IEditResellerDataErrorState {
  customerNumber: boolean;
  companyName: boolean;
}

export interface IEditResellerReferenceDataState {
  logotypeAsBase64: string;
  logotypeFileType: FileType;
  homePageImageAsBase64: string;
  homePageImageFileType: FileType;
  homePageHeaderText: string;
  homePageBodyText: string;
}
