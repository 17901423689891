import React, { useEffect } from "react";
import Cookies from "js-cookie";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import ProcessCircle from "../../components/ProcessCircle/ProcessCircle";
import { RootState } from "../../store";
import { CookieConstants } from "../../Utils/Labels/types";
import { ResellerActions } from "../../Modules/Reseller/actions";
import { IGetResellerLogoByCodeRequest } from "../../Modules/Reseller/types";
import { useLocation } from "react-router";
import { SystemPart } from "../../Utils/enums/enums";
import { GetCurrentLanguageAsText } from "../../Utils/types";

export default function ResellerRedirect() {
  function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
  }

  const navigate = useNavigate();
  const dispatch = useDispatch();
  let query = useQuery();
  const reducerState = useSelector(
    (s: RootState) => {
      return {
        reseller: s.reseller,
      };
    },
    (prev, next) => prev.reseller.isLoading === next.reseller.isLoading
  );

  useEffect(() => {
    let resellerCode = query.get("code").substring(0, 5);
    if (resellerCode?.length > 0) {
      Cookies.set(CookieConstants.GBVReseller, resellerCode, {
        expires: new Date().getDate() + 365,
        path: "/",
        secure: true,
      });
    }
  }, []);

  useEffect(() => {
    const options: Cookies.CookieAttributes = {
      expires: 365,
    } as Cookies.CookieAttributes;

    Cookies.set(CookieConstants.SystemPart, SystemPart.Company.toString(), options);

    const resellerCookie = Cookies.get(CookieConstants.GBVReseller);
    if (resellerCookie !== undefined) {
      dispatch(
        ResellerActions.GetResellerLogoByCodeRequest({
          resellerCode: Cookies.get(CookieConstants.GBVReseller),
        } as IGetResellerLogoByCodeRequest)
      );
    }

    navigate(`/${GetCurrentLanguageAsText()}/`);
  }, [Cookies.get(CookieConstants.GBVReseller)]);

  return <div>{reducerState.reseller?.isLoading && <ProcessCircle text="Redirecting..." />}</div>;
}
