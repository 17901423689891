import { ISearchSingleOrderRequest, ISearchSingleOrderResponse, IStandardResponse } from "../Orders/types";
import { ErrorReason } from "./../../Utils/enums/enums";
import { ResellerActionTypes } from "./actionTypes";
import {
  ICreateResellerContactRequest,
  ICreateResellerContactResponse,
  ICreateResellerRequest,
  ICreateResellerResponse,
  IDeleteResellerContactRequest,
  IDeleteResellerLogoRequest,
  IGetResellerAdminContactInfoResponse,
  IGetResellerAdminContactInfoRequest,
  IGetResellerByCodeRequest,
  IGetResellerByCodeResponse,
  IGetResellerContactResponse,
  IGetResellerLogoByCodeRequest,
  IGetResellerLogoResponse,
  IGetResellerRequest,
  IGetResellerResponse,
  ISearchResellersRequest,
  ISearchResellersResponse,
  ISearchSingleResellerRequest,
  ISearchSingleResellerResponse,
  IUpdateResellerContactRequest,
  IUpdateResellerContactResponse,
  IUpdateResellerLogoRequest,
  IUpdateResellerRequest,
  IUpdateResellerResponse,
  IGetResellerContactRequest,
  IGetResellerIncomingOrderEmailsRequest,
  IGetResellerIncomingOrderEmailsResponse,
  IUpdateResellerIncomingOrderEmailsRequest,
  IGetResellerHomePageImageResponse,
  IDeleteResellerHomePageImageRequest,
  IUpdateResellerHomePageImageRequest,
  IGetResellerHomePageImageRequest,
  IGetResellerHomePageImageByCodeRequest,
  IGetResellerHomePageImageByCodeResponse,
  IGetResellerLogoRequest,
  ISearchResellerOrdersRequest,
  ISearchResellerOrdersResponse,
  IGetResellersResponse,
  IGetResellersRequest,
  IGetResellerContactPageInformtaionRequest,
  IGetResellerContactPageInformtaionResponse,
  IUpdateResellerContactPageInformtaionRequest,
  IUpdateResellerHomePageTextsRequest,
  IGetResellerHomePageTextsByCodeRequest,
  IGetResellerHomePageTextsRequest,
  IGetResellerHomePageTextsResponse,
  IGetResellerHomePageTextsByCodeResponse,
  IGetResellerContactsRequest,
  IGetResellerContactsResponse,
  IValidateResellerEmailRequest,
  IValidateResellerEmailResponse,
  IGetAllResellersResponse,
  ISetSelectedAdminResellerOptionRequest,
  ISetSelectedAdminResellerCountryOptionRequest,
} from "./types";

//Create reseller
interface Create_Reseller_Request {
  type: ResellerActionTypes.CREATE_RESELLER_REQUEST;
  request: ICreateResellerRequest;
}
interface Create_Reseller_Success {
  type: ResellerActionTypes.CREATE_RESELLER_SUCCESS;
  response: ICreateResellerResponse;
}
interface Create_Reseller_Error {
  type: ResellerActionTypes.CREATE_RESELLER_ERROR;
}

//Get reseller
interface Get_Reseller_Request {
  type: ResellerActionTypes.GET_RESELLER_REQUEST;
  request: IGetResellerRequest;
}
interface Get_Reseller_Success {
  type: ResellerActionTypes.GET_RESELLER_SUCCESS;
  response: IGetResellerResponse;
}
interface Get_Reseller_Error {
  type: ResellerActionTypes.GET_RESELLER_ERROR;
}

//Get reseller logo
interface Get_Reseller_Logo_Request {
  type: ResellerActionTypes.GET_RESELLER_LOGO_REQUEST;
  request: IGetResellerLogoRequest;
}
interface Get_Reseller_Logo_Success {
  type: ResellerActionTypes.GET_RESELLER_LOGO_SUCCESS;
  response: IGetResellerLogoResponse;
}
interface Get_Reseller_Logo_Error {
  type: ResellerActionTypes.GET_RESELLER_LOGO_ERROR;
}

//Delete reseller logo
interface Delete_Reseller_Logo_Request {
  type: ResellerActionTypes.DELETE_RESELLER_LOGO_REQUEST;
  request: IDeleteResellerLogoRequest;
}
interface Delete_Reseller_Logo_Success {
  type: ResellerActionTypes.DELETE_RESELLER_LOGO_SUCCESS;
}
interface Delete_Reseller_Logo_Error {
  type: ResellerActionTypes.DELETE_RESELLER_LOGO_ERROR;
}

//Get reseller home page image
interface Get_Reseller_Start_Page_Image_Request {
  type: ResellerActionTypes.GET_RESELLER_HOME_PAGE_IMAGE_REQUEST;
  request: IGetResellerHomePageImageRequest;
}
interface Get_Reseller_Start_Page_Image_Success {
  type: ResellerActionTypes.GET_RESELLER_HOME_PAGE_IMAGE_SUCCESS;
  response: IGetResellerHomePageImageResponse;
}
interface Get_Reseller_Start_Page_Image_Error {
  type: ResellerActionTypes.GET_RESELLER_HOME_PAGE_IMAGE_ERROR;
}

//Get reseller home page image by reseller code
interface Get_Reseller_Home_Page_Image_By_Code_Request {
  type: ResellerActionTypes.GET_RESELLER_HOME_PAGE_IMAGE_BY_CODE_REQUEST;
  request: IGetResellerHomePageImageByCodeRequest;
}
interface Get_Reseller_Home_Page_Image_By_Code_Success {
  type: ResellerActionTypes.GET_RESELLER_HOME_PAGE_IMAGE_BY_CODE_SUCCESS;
  response: IGetResellerHomePageImageByCodeResponse;
}
interface Get_Reseller_Home_Page_Image_By_Code_Error {
  type: ResellerActionTypes.GET_RESELLER_HOME_PAGE_IMAGE_BY_CODE_ERROR;
}

//Update reseller home page image
interface Update_Reseller_Home_Page_Image_Request {
  type: ResellerActionTypes.UPDATE_RESELLER_HOME_PAGE_IMAGE_REQUEST;
  request: IUpdateResellerHomePageImageRequest;
}
interface Update_Reseller_Home_Page_Image_Success {
  type: ResellerActionTypes.UPDATE_RESELLER_HOME_PAGE_IMAGE_SUCCESS;
}
interface Update_Reseller_Home_Page_Image_Error {
  type: ResellerActionTypes.UPDATE_RESELLER_HOME_PAGE_IMAGE_ERROR;
}

//Delete reseller home page image
interface Delete_Reseller_Home_Page_Image_Request {
  type: ResellerActionTypes.DELETE_RESELLER_HOME_PAGE_IMAGE_REQUEST;
  request: IDeleteResellerHomePageImageRequest;
}
interface Delete_Reseller_Home_Page_Image_Success {
  type: ResellerActionTypes.DELETE_RESELLER_HOME_PAGE_IMAGE_SUCCESS;
}
interface Delete_Reseller_Home_Page_Image_Error {
  type: ResellerActionTypes.DELETE_RESELLER_HOME_PAGE_IMAGE_ERROR;
}

//Get reseller logo by id
interface Get_Reseller_Logo_By_Code_Request {
  type: ResellerActionTypes.GET_RESELLER_LOGO_BY_CODE_REQUEST;
  request: IGetResellerLogoByCodeRequest;
}
interface Get_Reseller_Logo_By_Code_Success {
  type: ResellerActionTypes.GET_RESELLER_LOGO_BY_CODE_SUCCESS;
  response: IGetResellerLogoResponse;
}
interface Get_Reseller_Logo_By_Code_Error {
  type: ResellerActionTypes.GET_RESELLER_LOGO_BY_CODE_ERROR;
}

//Update reseller
interface Update_Reseller_Request {
  type: ResellerActionTypes.UPDATE_RESELLER_REQUEST;
  request: IUpdateResellerRequest;
}
interface Update_Reseller_Success {
  type: ResellerActionTypes.UPDATE_RESELLER_SUCCESS;
  response: IUpdateResellerResponse;
}
interface Update_Reseller_Error {
  type: ResellerActionTypes.UPDATE_RESELLER_ERROR;
}

//-----------------------------------------
//Create reseller contact
interface Create_Reseller_Contact_Request {
  type: ResellerActionTypes.CREATE_RESELLER_CONTACT_REQUEST;
  request: ICreateResellerContactRequest;
}
interface Create_Reseller_Contact_Success {
  type: ResellerActionTypes.CREATE_RESELLER_CONTACT_SUCCESS;
  response: ICreateResellerContactResponse;
}
interface Create_Reseller_Contact_Error {
  type: ResellerActionTypes.CREATE_RESELLER_CONTACT_ERROR;
  response: ErrorReason;
}

//Get reseller contact
interface Get_Reseller_Contact_Request {
  type: ResellerActionTypes.GET_RESELLER_CONTACT_REQUEST;
  request: IGetResellerContactRequest;
}
interface Get_Reseller_Contact_Success {
  type: ResellerActionTypes.GET_RESELLER_CONTACT_SUCCESS;
  response: IGetResellerContactResponse;
}
interface Get_Reseller_Contact_Error {
  type: ResellerActionTypes.GET_RESELLER_CONTACT_ERROR;
}

//Update reseller contact
interface Update_Reseller_Contact_Request {
  type: ResellerActionTypes.UPDATE_RESELLER_CONTACT_REQUEST;
  request: IUpdateResellerContactRequest;
}
interface Update_Reseller_Contact_Success {
  type: ResellerActionTypes.UPDATE_RESELLER_CONTACT_SUCCESS;
  response: IUpdateResellerContactResponse;
}
interface Update_Reseller_Contact_Error {
  type: ResellerActionTypes.UPDATE_RESELLER_CONTACT_ERROR;
  response: ErrorReason;
}

//Delete reseller contact
interface Delete_Reseller_Contact_Request {
  type: ResellerActionTypes.DELETE_RESELLER_CONTACT_REQUEST;
  request: IDeleteResellerContactRequest;
}
interface Delete_Reseller_Contact_Success {
  type: ResellerActionTypes.DELETE_RESELLER_CONTACT_SUCCESS;
}
interface Delete_Reseller_Contact_Error {
  type: ResellerActionTypes.DELETE_RESELLER_CONTACT_ERROR;
}

//Generate reseller order url
interface Get_Reseller_Admin_Contact_Info_Request {
  type: ResellerActionTypes.GET_RESELLER_ADMIN_CONTACT_INFO_REQUEST;
  request: IGetResellerAdminContactInfoRequest;
}
interface Get_Reseller_Admin_Contact_Info_Success {
  type: ResellerActionTypes.GET_RESELLER_ADMIN_CONTACT_INFO_SUCCESS;
  response: IGetResellerAdminContactInfoResponse;
}
interface Get_Reseller_Admin_Contact_Info_Error {
  type: ResellerActionTypes.GET_RESELLER_ADMIN_CONTACT_INFO_ERROR;
}

//Update reseller logo
interface Update_Reseller_Logo_Request {
  type: ResellerActionTypes.UPDATE_RESELLER_LOGO_REQUEST;
  request: IUpdateResellerLogoRequest;
}
interface Update_Reseller_Logo_Success {
  type: ResellerActionTypes.UPDATE_RESELLER_LOGO_SUCCESS;
}
interface Update_Reseller_Logo_Error {
  type: ResellerActionTypes.UPDATE_RESELLER_LOGO_ERROR;
}

//Get reseller by reseller code
interface Get_Reseller_By_Code_Request {
  type: ResellerActionTypes.GET_RESELLER_BY_CODE_REQUEST;
  request: IGetResellerByCodeRequest;
}
interface Get_Reseller_By_Code_Success {
  type: ResellerActionTypes.GET_RESELLER_BY_CODE_SUCCESS;
  response: IGetResellerByCodeResponse;
}
interface Get_Reseller_By_Code_Error {
  type: ResellerActionTypes.GET_RESELLER_BY_CODE_ERROR;
}

//Reset
interface Reset_Reducer {
  type: ResellerActionTypes.RESET_REDUCER;
}

//Get resellers
interface Get_Resellers_Request {
  type: ResellerActionTypes.GET_RESELLERS_REQUEST;
  request: IGetResellersRequest;
}
interface Get_Resellers_Success {
  type: ResellerActionTypes.GET_RESELLERS_SUCCESS;
  response: IGetResellersResponse;
}
interface Get_Resellers_Error {
  type: ResellerActionTypes.GET_RESELLERS_ERROR;
}

//Search resellers
interface Search_Resellers_Request {
  type: ResellerActionTypes.SEARCH_RESELLERS_REQUEST;
  request: ISearchResellersRequest;
}
interface Search_Resellers_Success {
  type: ResellerActionTypes.SEARCH_RESELLERS_SUCCESS;
  response: ISearchResellersResponse;
}
interface Search_Resellers_Error {
  type: ResellerActionTypes.SEARCH_RESELLERS_ERROR;
}

//Search single resellers
interface Search_Single_Reseller_Request {
  type: ResellerActionTypes.SEARCH_SINGLE_RESELLER_REQUEST;
  request: ISearchSingleResellerRequest;
}
interface Search_Single_Reseller_Success {
  type: ResellerActionTypes.SEARCH_SINGLE_RESELLER_SUCCESS;
  response: ISearchSingleResellerResponse;
}
interface Search_Single_Resellers_Error {
  type: ResellerActionTypes.SEARCH_SINGLE_RESELLER_ERROR;
}

//Get reseller incoming order emails
interface Get_Reseller_Incoming_OrderEmails_Request {
  type: ResellerActionTypes.GET_INCOMING_RESELLER_ORDER_EMAILS_REQUEST;
  request: IGetResellerIncomingOrderEmailsRequest;
}
interface Get_Reseller_Incoming_OrderEmails_Success {
  type: ResellerActionTypes.GET_INCOMING_RESELLER_ORDER_EMAILS_SUCCESS;
  response: IGetResellerIncomingOrderEmailsResponse;
}
interface Get_Reseller_Incoming_OrderEmails_Error {
  type: ResellerActionTypes.GET_INCOMING_RESELLER_ORDER_EMAILS_ERROR;
}

//Get coupled reseller orders
interface Search_Orders_Reseller_Request {
  type: ResellerActionTypes.SEARCH_RESELLER_COUPLED_ORDERS_REQUEST;
  request: ISearchResellerOrdersRequest;
}
interface Search_Orders_Reseller_Success {
  type: ResellerActionTypes.SEARCH_RESELLER_COUPLED_ORDERS_SUCCESS;
  response: ISearchResellerOrdersResponse;
}
interface Search_Orders_Reseller_Error {
  type: ResellerActionTypes.SEARCH_RESELLER_COUPLED_ORDERS_ERROR;
}

//Update reseller incoming order emails
interface Update_Reseller_Incoming_OrderEmails_Request {
  type: ResellerActionTypes.UPDATE_INCOMING_RESELLER_ORDER_EMAILS_REQUEST;
  request: IUpdateResellerIncomingOrderEmailsRequest;
}
interface Update_Reseller_Incoming_OrderEmails_Success {
  type: ResellerActionTypes.UPDATE_INCOMING_RESELLER_ORDER_EMAILS_SUCCESS;
}
interface Update_Reseller_Incoming_OrderEmails_Error {
  type: ResellerActionTypes.UPDATE_INCOMING_RESELLER_ORDER_EMAILS_ERROR;
}

//Search single order by Id
interface Search_Single_Order_Request {
  type: ResellerActionTypes.SEARCH_SINGLE_ORDER_REQUEST;
  request: ISearchSingleOrderRequest;
}
interface Search_Single_Order_Success {
  type: ResellerActionTypes.SEARCH_SINGLE_ORDER_SUCCESS;
  response: ISearchSingleOrderResponse;
}
interface Search_Single_Order_Error {
  type: ResellerActionTypes.SEARCH_SINGLE_ORDER_ERROR;
}

//Get reseller contact page information
interface Get_Reseller_Contact_Page_Information_Auth_Request {
  type: ResellerActionTypes.GET_RESELLER_CONTACT_PAGE_INFORMATION_AUTH_REQUEST;
  request: IGetResellerContactPageInformtaionRequest;
}
interface Get_Reseller_Contact_Page_Information_Auth_Success {
  type: ResellerActionTypes.GET_RESELLER_CONTACT_PAGE_INFORMATION_AUTH_SUCCESS;
  response: IGetResellerContactPageInformtaionResponse;
}
interface Get_Reseller_Contact_Page_Information_Auth_Error {
  type: ResellerActionTypes.GET_RESELLER_CONTACT_PAGE_INFORMATION_AUTH_ERROR;
}

//Get reseller contact page information
interface Get_Reseller_Contact_Page_Information_Request {
  type: ResellerActionTypes.GET_RESELLER_CONTACT_PAGE_INFORMATION_REQUEST;
  request: IGetResellerContactPageInformtaionRequest;
}
interface Get_Reseller_Contact_Page_Information_Success {
  type: ResellerActionTypes.GET_RESELLER_CONTACT_PAGE_INFORMATION_SUCCESS;
  response: IGetResellerContactPageInformtaionResponse;
}
interface Get_Reseller_Contact_Page_Information_Error {
  type: ResellerActionTypes.GET_RESELLER_CONTACT_PAGE_INFORMATION_ERROR;
}

//Update reseller contact page information
interface Update_Reseller_Contact_Page_Information_Request {
  type: ResellerActionTypes.UPDATE_RESELLER_CONTACT_PAGE_INFORMATION_REQUEST;
  request: IUpdateResellerContactPageInformtaionRequest;
}
interface Update_Reseller_Contact_Page_Information_Success {
  type: ResellerActionTypes.UPDATE_RESELLER_CONTACT_PAGE_INFORMATION_SUCCESS;
  response: IStandardResponse;
}
interface Update_Reseller_Contact_Page_Information_Error {
  type: ResellerActionTypes.UPDATE_RESELLER_CONTACT_PAGE_INFORMATION_ERROR;
}

//Get reseller home page texts
interface Get_Reseller_Home_Page_Texts_Request {
  type: ResellerActionTypes.GET_RESELLER_HOME_PAGE_TEXTS_REQUEST;
  request: IGetResellerHomePageTextsRequest;
}
interface Get_Reseller_Home_Page_Texts_Success {
  type: ResellerActionTypes.GET_RESELLER_HOME_PAGE_TEXTS_SUCCESS;
  response: IGetResellerHomePageTextsResponse;
}
interface Get_Reseller_Home_Page_Texts_Error {
  type: ResellerActionTypes.GET_RESELLER_HOME_PAGE_TEXTS_ERROR;
}

//Get reseller home page texts by code
interface Get_Reseller_Home_Page_Texts_By_Code_Request {
  type: ResellerActionTypes.GET_RESELLER_HOME_PAGE_TEXTS_BY_CODE_REQUEST;
  request: IGetResellerHomePageTextsByCodeRequest;
}
interface Get_Reseller_Home_Page_Texts_By_Code_Success {
  type: ResellerActionTypes.GET_RESELLER_HOME_PAGE_TEXTS_BY_CODE_SUCCESS;
  response: IGetResellerHomePageTextsByCodeResponse;
}
interface Get_Reseller_Home_Page_Texts_By_Code_Error {
  type: ResellerActionTypes.GET_RESELLER_HOME_PAGE_TEXTS_BY_CODE_ERROR;
}

//Update reseller home page texts
interface Update_Reseller_Home_Page_Texts_Request {
  type: ResellerActionTypes.UPDATE_RESELLER_HOME_PAGE_TEXTS_REQUEST;
  request: IUpdateResellerHomePageTextsRequest;
}
interface Update_Reseller_Home_Page_Texts_Success {
  type: ResellerActionTypes.UPDATE_RESELLER_HOME_PAGE_TEXTS_SUCCESS;
  response: IStandardResponse;
}
interface Update_Reseller_Home_Page_Texts_Error {
  type: ResellerActionTypes.UPDATE_RESELLER_HOME_PAGE_TEXTS_ERROR;
}

//Get reseller contacts
interface Get_Reseller_Contacts_Request {
  type: ResellerActionTypes.GET_RESELLER_CONTACTS_REQUEST;
  request: IGetResellerContactsRequest;
}
interface Get_Reseller_Contacts_Success {
  type: ResellerActionTypes.GET_RESELLER_CONTACTS_SUCCESS;
  response: IGetResellerContactsResponse;
}
interface Get_Reseller_Contacts_Error {
  type: ResellerActionTypes.GET_RESELLER_CONTACTS_ERROR;
}

//Validate Reseller email for discount
interface Validate_Reseller_Email_Request {
  type: ResellerActionTypes.VALIDATE_RESELLER_EMAIL_REQUEST;
  request: IValidateResellerEmailRequest;
}
interface Validate_Reseller_Email_Success {
  type: ResellerActionTypes.VALIDATE_RESELLER_EMAIL_SUCCESS;
  response: IValidateResellerEmailResponse;
}
interface Validate_Reseller_Email_Error {
  type: ResellerActionTypes.VALIDATE_RESELLER_EMAIL_ERROR;
}

//Get all resellers
interface Get_All_Resellers_Request {
  type: ResellerActionTypes.GET_ALL_RESELLERS_REQUEST;
}
interface Get_All_Resellers_Success {
  type: ResellerActionTypes.GET_ALL_RESELLERS_SUCCESS;
  response: IGetAllResellersResponse;
}
interface Get_All_Resellers_Error {
  type: ResellerActionTypes.GET_ALL_RESELLERS_ERROR;
}

//Set selected admin reseller from dropdown option in systemadmin step one
interface Set_Selected_Admin_Reseller_Option_Request {
  type: ResellerActionTypes.SET_SELECTED_ADMIN_RESELLER_OPTION_REQUEST;
  request: ISetSelectedAdminResellerOptionRequest;
}

interface Set_Selected_Admin_Reseller_Country_Option_Request {
  type: ResellerActionTypes.SET_SELECTED_ADMIN_RESELLER_COUNTRY_OPTION_REQUEST;
  request: ISetSelectedAdminResellerCountryOptionRequest;
}

export const ResellerActions = {
  //Create reseller
  CreateResellerRequest: (request: ICreateResellerRequest): Create_Reseller_Request => ({
    type: ResellerActionTypes.CREATE_RESELLER_REQUEST,
    request,
  }),
  CreateResellerSuccess: (response: ICreateResellerResponse): Create_Reseller_Success => ({
    type: ResellerActionTypes.CREATE_RESELLER_SUCCESS,
    response,
  }),
  CreateResellerError: (): Create_Reseller_Error => ({
    type: ResellerActionTypes.CREATE_RESELLER_ERROR,
  }),

  //Set selected reseller from dropdown in systemadmin step one
  SetSelectedAdminResellerOptionRequest: (request: ISetSelectedAdminResellerOptionRequest): Set_Selected_Admin_Reseller_Option_Request => ({
    type: ResellerActionTypes.SET_SELECTED_ADMIN_RESELLER_OPTION_REQUEST,
    request,
  }),

  SetSelectedAdminResellerCountryOptionRequest: (
    request: ISetSelectedAdminResellerCountryOptionRequest
  ): Set_Selected_Admin_Reseller_Country_Option_Request => ({
    type: ResellerActionTypes.SET_SELECTED_ADMIN_RESELLER_COUNTRY_OPTION_REQUEST,
    request,
  }),

  //Get all resellers
  GetAllResellersRequest: (): Get_All_Resellers_Request => ({
    type: ResellerActionTypes.GET_ALL_RESELLERS_REQUEST,
  }),
  GetAllResellersSuccess: (response: IGetAllResellersResponse): Get_All_Resellers_Success => ({
    type: ResellerActionTypes.GET_ALL_RESELLERS_SUCCESS,
    response,
  }),
  GetAllResellersError: (): Get_All_Resellers_Error => ({
    type: ResellerActionTypes.GET_ALL_RESELLERS_ERROR,
  }),

  //Get reseller by code
  GetResellerByCodeRequest: (request: IGetResellerByCodeRequest): Get_Reseller_By_Code_Request => ({
    type: ResellerActionTypes.GET_RESELLER_BY_CODE_REQUEST,
    request,
  }),
  GetResellerByCodeSuccess: (response: IGetResellerByCodeResponse): Get_Reseller_By_Code_Success => ({
    type: ResellerActionTypes.GET_RESELLER_BY_CODE_SUCCESS,
    response,
  }),
  GetResellerByCodeError: (): Get_Reseller_By_Code_Error => ({
    type: ResellerActionTypes.GET_RESELLER_BY_CODE_ERROR,
  }),

  //Get reseller
  GetResellerRequest: (request: IGetResellerRequest): Get_Reseller_Request => ({
    type: ResellerActionTypes.GET_RESELLER_REQUEST,
    request,
  }),
  GetResellerSuccess: (response: IGetResellerResponse): Get_Reseller_Success => ({
    type: ResellerActionTypes.GET_RESELLER_SUCCESS,
    response,
  }),
  GetResellerError: (): Get_Reseller_Error => ({
    type: ResellerActionTypes.GET_RESELLER_ERROR,
  }),

  //Get reseller logo
  GetResellerLogoRequest: (request?: IGetResellerLogoRequest): Get_Reseller_Logo_Request => ({
    type: ResellerActionTypes.GET_RESELLER_LOGO_REQUEST,
    request,
  }),
  GetResellerLogoSuccess: (response: IGetResellerLogoResponse): Get_Reseller_Logo_Success => ({
    type: ResellerActionTypes.GET_RESELLER_LOGO_SUCCESS,
    response,
  }),
  GetResellerLogoError: (): Get_Reseller_Logo_Error => ({
    type: ResellerActionTypes.GET_RESELLER_LOGO_ERROR,
  }),

  //Delete reseller logo
  DeleteResellerLogoRequest: (request: IDeleteResellerLogoRequest): Delete_Reseller_Logo_Request => ({
    type: ResellerActionTypes.DELETE_RESELLER_LOGO_REQUEST,
    request,
  }),
  DeleteResellerLogoSuccess: (): Delete_Reseller_Logo_Success => ({
    type: ResellerActionTypes.DELETE_RESELLER_LOGO_SUCCESS,
  }),
  DeleteResellerLogoError: (): Delete_Reseller_Logo_Error => ({
    type: ResellerActionTypes.DELETE_RESELLER_LOGO_ERROR,
  }),

  //Search order by Id
  SearchSingleOrderRequest: (request: ISearchSingleOrderRequest): Search_Single_Order_Request => ({
    type: ResellerActionTypes.SEARCH_SINGLE_ORDER_REQUEST,
    request,
  }),
  SearchSingleOrderSuccess: (response: ISearchSingleOrderResponse): Search_Single_Order_Success => ({
    type: ResellerActionTypes.SEARCH_SINGLE_ORDER_SUCCESS,
    response,
  }),
  SearchSingleOrderError: (): Search_Single_Order_Error => ({
    type: ResellerActionTypes.SEARCH_SINGLE_ORDER_ERROR,
  }),

  //Get reseller home page image
  GetResellerHomePageImageRequest: (request?: IGetResellerHomePageImageRequest): Get_Reseller_Start_Page_Image_Request => ({
    type: ResellerActionTypes.GET_RESELLER_HOME_PAGE_IMAGE_REQUEST,
    request,
  }),
  GetResellerHomePageImageSuccess: (response: IGetResellerHomePageImageResponse): Get_Reseller_Start_Page_Image_Success => ({
    type: ResellerActionTypes.GET_RESELLER_HOME_PAGE_IMAGE_SUCCESS,
    response,
  }),
  GetResellerHomePageImageError: (): Get_Reseller_Start_Page_Image_Error => ({
    type: ResellerActionTypes.GET_RESELLER_HOME_PAGE_IMAGE_ERROR,
  }),

  //Get reseller home page image by reseller code
  GetResellerHomePageImageByCodeRequest: (
    request: IGetResellerHomePageImageByCodeRequest
  ): Get_Reseller_Home_Page_Image_By_Code_Request => ({
    type: ResellerActionTypes.GET_RESELLER_HOME_PAGE_IMAGE_BY_CODE_REQUEST,
    request,
  }),
  GetResellerHomePageImageByCodeSuccess: (
    response: IGetResellerHomePageImageByCodeResponse
  ): Get_Reseller_Home_Page_Image_By_Code_Success => ({
    type: ResellerActionTypes.GET_RESELLER_HOME_PAGE_IMAGE_BY_CODE_SUCCESS,
    response,
  }),
  GetResellerHomePageImageByCodeError: (): Get_Reseller_Home_Page_Image_By_Code_Error => ({
    type: ResellerActionTypes.GET_RESELLER_HOME_PAGE_IMAGE_BY_CODE_ERROR,
  }),

  //Update reseller home page image
  UpdateResellerHomePageImageRequest: (request: IUpdateResellerHomePageImageRequest): Update_Reseller_Home_Page_Image_Request => ({
    type: ResellerActionTypes.UPDATE_RESELLER_HOME_PAGE_IMAGE_REQUEST,
    request,
  }),
  UpdateResellerHomePageImageSuccess: (): Update_Reseller_Home_Page_Image_Success => ({
    type: ResellerActionTypes.UPDATE_RESELLER_HOME_PAGE_IMAGE_SUCCESS,
  }),
  UpdateResellerHomePageImageError: (): Update_Reseller_Home_Page_Image_Error => ({
    type: ResellerActionTypes.UPDATE_RESELLER_HOME_PAGE_IMAGE_ERROR,
  }),

  //Delete reseller home page image
  DeleteResellerHomePageImageRequest: (request: IDeleteResellerHomePageImageRequest): Delete_Reseller_Home_Page_Image_Request => ({
    type: ResellerActionTypes.DELETE_RESELLER_HOME_PAGE_IMAGE_REQUEST,
    request,
  }),
  DeleteResellerHomePageImageSuccess: (): Delete_Reseller_Home_Page_Image_Success => ({
    type: ResellerActionTypes.DELETE_RESELLER_HOME_PAGE_IMAGE_SUCCESS,
  }),
  DeleteResellerHomePageImageError: (): Delete_Reseller_Home_Page_Image_Error => ({
    type: ResellerActionTypes.DELETE_RESELLER_HOME_PAGE_IMAGE_ERROR,
  }),

  //Get reseller logo by reseller code
  GetResellerLogoByCodeRequest: (request: IGetResellerLogoByCodeRequest): Get_Reseller_Logo_By_Code_Request => ({
    type: ResellerActionTypes.GET_RESELLER_LOGO_BY_CODE_REQUEST,
    request,
  }),
  GetResellerLogoByCodeSuccess: (response: IGetResellerLogoResponse): Get_Reseller_Logo_By_Code_Success => ({
    type: ResellerActionTypes.GET_RESELLER_LOGO_BY_CODE_SUCCESS,
    response,
  }),
  GetResellerLogoByCodeError: (): Get_Reseller_Logo_By_Code_Error => ({
    type: ResellerActionTypes.GET_RESELLER_LOGO_BY_CODE_ERROR,
  }),

  //Get reseller admin contact info
  GetResellerAdminContactInfoRequest: (request: IGetResellerAdminContactInfoRequest): Get_Reseller_Admin_Contact_Info_Request => ({
    type: ResellerActionTypes.GET_RESELLER_ADMIN_CONTACT_INFO_REQUEST,
    request,
  }),
  GetResellerAdminContactInfoSuccess: (response: IGetResellerAdminContactInfoResponse): Get_Reseller_Admin_Contact_Info_Success => ({
    type: ResellerActionTypes.GET_RESELLER_ADMIN_CONTACT_INFO_SUCCESS,
    response,
  }),
  GetResellerAdminContactInfoError: (): Get_Reseller_Admin_Contact_Info_Error => ({
    type: ResellerActionTypes.GET_RESELLER_ADMIN_CONTACT_INFO_ERROR,
  }),

  //Update reseller
  UpdateResellerRequest: (request: IUpdateResellerRequest): Update_Reseller_Request => ({
    type: ResellerActionTypes.UPDATE_RESELLER_REQUEST,
    request,
  }),
  UpdateResellerSuccess: (response: IUpdateResellerResponse): Update_Reseller_Success => ({
    type: ResellerActionTypes.UPDATE_RESELLER_SUCCESS,
    response,
  }),
  UpdateResellerError: (): Update_Reseller_Error => ({
    type: ResellerActionTypes.UPDATE_RESELLER_ERROR,
  }),

  //Get reseller incoming order emails
  GetResellerIncomingOrderEmailsRequest: (request: IGetResellerIncomingOrderEmailsRequest): Get_Reseller_Incoming_OrderEmails_Request => ({
    type: ResellerActionTypes.GET_INCOMING_RESELLER_ORDER_EMAILS_REQUEST,
    request,
  }),
  GetResellerIncomingOrderEmailsSuccess: (
    response: IGetResellerIncomingOrderEmailsResponse
  ): Get_Reseller_Incoming_OrderEmails_Success => ({
    type: ResellerActionTypes.GET_INCOMING_RESELLER_ORDER_EMAILS_SUCCESS,
    response,
  }),
  GetResellerIncomingOrderEmailsError: (): Get_Reseller_Incoming_OrderEmails_Error => ({
    type: ResellerActionTypes.GET_INCOMING_RESELLER_ORDER_EMAILS_ERROR,
  }),

  //Update reseller incoming order emails
  UpdateResellerIncomingOrderEmailsRequest: (
    request: IUpdateResellerIncomingOrderEmailsRequest
  ): Update_Reseller_Incoming_OrderEmails_Request => ({
    type: ResellerActionTypes.UPDATE_INCOMING_RESELLER_ORDER_EMAILS_REQUEST,
    request,
  }),
  UpdateResellerIncomingOrderEmailsSuccess: (): Update_Reseller_Incoming_OrderEmails_Success => ({
    type: ResellerActionTypes.UPDATE_INCOMING_RESELLER_ORDER_EMAILS_SUCCESS,
  }),
  UpdateResellerIncomingOrderEmailsError: (): Update_Reseller_Incoming_OrderEmails_Error => ({
    type: ResellerActionTypes.UPDATE_INCOMING_RESELLER_ORDER_EMAILS_ERROR,
  }),

  //Update reseller logo
  UpdateResellerLogoRequest: (request: IUpdateResellerLogoRequest): Update_Reseller_Logo_Request => ({
    type: ResellerActionTypes.UPDATE_RESELLER_LOGO_REQUEST,
    request,
  }),
  UpdateResellerLogoSuccess: (): Update_Reseller_Logo_Success => ({
    type: ResellerActionTypes.UPDATE_RESELLER_LOGO_SUCCESS,
  }),
  UpdateResellerLogoError: (): Update_Reseller_Logo_Error => ({
    type: ResellerActionTypes.UPDATE_RESELLER_LOGO_ERROR,
  }),

  //Create reseller contact
  CreateResellerContactRequest: (request: ICreateResellerContactRequest): Create_Reseller_Contact_Request => ({
    type: ResellerActionTypes.CREATE_RESELLER_CONTACT_REQUEST,
    request,
  }),
  CreateResellerContactSuccess: (response: ICreateResellerContactResponse): Create_Reseller_Contact_Success => ({
    type: ResellerActionTypes.CREATE_RESELLER_CONTACT_SUCCESS,
    response,
  }),
  CreateResellerContactError: (response: ErrorReason): Create_Reseller_Contact_Error => ({
    type: ResellerActionTypes.CREATE_RESELLER_CONTACT_ERROR,
    response: response,
  }),

  //Get reseller contact
  GetResellerContactRequest: (request: IGetResellerContactRequest): Get_Reseller_Contact_Request => ({
    type: ResellerActionTypes.GET_RESELLER_CONTACT_REQUEST,
    request,
  }),
  GetResellerContactSuccess: (response: IGetResellerContactResponse): Get_Reseller_Contact_Success => ({
    type: ResellerActionTypes.GET_RESELLER_CONTACT_SUCCESS,
    response,
  }),
  GetResellerContactError: (): Get_Reseller_Contact_Error => ({
    type: ResellerActionTypes.GET_RESELLER_CONTACT_ERROR,
  }),

  //Update reseller contact
  UpdateResellerContactRequest: (request: IUpdateResellerContactRequest): Update_Reseller_Contact_Request => ({
    type: ResellerActionTypes.UPDATE_RESELLER_CONTACT_REQUEST,
    request,
  }),
  UpdateResellerContactSuccess: (response: IUpdateResellerContactResponse): Update_Reseller_Contact_Success => ({
    type: ResellerActionTypes.UPDATE_RESELLER_CONTACT_SUCCESS,
    response,
  }),
  UpdateResellerContactError: (response: ErrorReason): Update_Reseller_Contact_Error => ({
    type: ResellerActionTypes.UPDATE_RESELLER_CONTACT_ERROR,
    response: response,
  }),

  //Delete reseller contact
  DeleteResellerContactRequest: (request: IDeleteResellerContactRequest): Delete_Reseller_Contact_Request => ({
    type: ResellerActionTypes.DELETE_RESELLER_CONTACT_REQUEST,
    request,
  }),
  DeleteResellerContactSuccess: (): Delete_Reseller_Contact_Success => ({
    type: ResellerActionTypes.DELETE_RESELLER_CONTACT_SUCCESS,
  }),
  DeleteResellerContactError: (): Delete_Reseller_Contact_Error => ({
    type: ResellerActionTypes.DELETE_RESELLER_CONTACT_ERROR,
  }),

  //Reset
  ResetReducer: (): Reset_Reducer => ({
    type: ResellerActionTypes.RESET_REDUCER,
  }),

  //Get resellers
  GetResellersRequest: (request: IGetResellersRequest): Get_Resellers_Request => ({
    type: ResellerActionTypes.GET_RESELLERS_REQUEST,
    request,
  }),
  GetResellersSuccess: (response: IGetResellersResponse): Get_Resellers_Success => ({
    type: ResellerActionTypes.GET_RESELLERS_SUCCESS,
    response,
  }),
  GetResellersError: (): Get_Resellers_Error => ({
    type: ResellerActionTypes.GET_RESELLERS_ERROR,
  }),

  //Search resellers
  SearchResellersRequest: (request: ISearchResellersRequest): Search_Resellers_Request => ({
    type: ResellerActionTypes.SEARCH_RESELLERS_REQUEST,
    request,
  }),
  SearchResellersSuccess: (response: ISearchResellersResponse): Search_Resellers_Success => ({
    type: ResellerActionTypes.SEARCH_RESELLERS_SUCCESS,
    response,
  }),
  SearchResellersError: (): Search_Resellers_Error => ({
    type: ResellerActionTypes.SEARCH_RESELLERS_ERROR,
  }),

  //Search single reseller
  SearchSingleResellerRequest: (request: ISearchSingleResellerRequest): Search_Single_Reseller_Request => ({
    type: ResellerActionTypes.SEARCH_SINGLE_RESELLER_REQUEST,
    request,
  }),
  SearchSingleResellerSuccess: (response: ISearchSingleResellerResponse): Search_Single_Reseller_Success => ({
    type: ResellerActionTypes.SEARCH_SINGLE_RESELLER_SUCCESS,
    response,
  }),
  SearchSingleResellerError: (): Search_Single_Resellers_Error => ({
    type: ResellerActionTypes.SEARCH_SINGLE_RESELLER_ERROR,
  }),

  //Get coupled reseller ordersRequest
  SearchResellerCoupledOrdersRequest: (request?: ISearchResellerOrdersRequest): Search_Orders_Reseller_Request => ({
    type: ResellerActionTypes.SEARCH_RESELLER_COUPLED_ORDERS_REQUEST,
    request,
  }),
  SearchResellerCoupledOrdersSuccess: (response: ISearchResellerOrdersResponse): Search_Orders_Reseller_Success => ({
    type: ResellerActionTypes.SEARCH_RESELLER_COUPLED_ORDERS_SUCCESS,
    response,
  }),
  SearchResellerCoupledOrdersError: (): Search_Orders_Reseller_Error => ({
    type: ResellerActionTypes.SEARCH_RESELLER_COUPLED_ORDERS_ERROR,
  }),

  //Get reseller contact page information auth
  GetResellerContactPageInformationAuthRequest: (
    request?: IGetResellerContactPageInformtaionRequest
  ): Get_Reseller_Contact_Page_Information_Auth_Request => ({
    type: ResellerActionTypes.GET_RESELLER_CONTACT_PAGE_INFORMATION_AUTH_REQUEST,
    request,
  }),
  GetResellerContactPageInformationAuthSuccess: (
    response: IGetResellerContactPageInformtaionResponse
  ): Get_Reseller_Contact_Page_Information_Auth_Success => ({
    type: ResellerActionTypes.GET_RESELLER_CONTACT_PAGE_INFORMATION_AUTH_SUCCESS,
    response,
  }),
  GetResellerContactPageInformationAuthError: (): Get_Reseller_Contact_Page_Information_Auth_Error => ({
    type: ResellerActionTypes.GET_RESELLER_CONTACT_PAGE_INFORMATION_AUTH_ERROR,
  }),

  //Get reseller contact page information
  GetResellerContactPageInformationRequest: (
    request?: IGetResellerContactPageInformtaionRequest
  ): Get_Reseller_Contact_Page_Information_Request => ({
    type: ResellerActionTypes.GET_RESELLER_CONTACT_PAGE_INFORMATION_REQUEST,
    request,
  }),
  GetResellerContactPageInformationSuccess: (
    response: IGetResellerContactPageInformtaionResponse
  ): Get_Reseller_Contact_Page_Information_Success => ({
    type: ResellerActionTypes.GET_RESELLER_CONTACT_PAGE_INFORMATION_SUCCESS,
    response,
  }),
  GetResellerContactPageInformationError: (): Get_Reseller_Contact_Page_Information_Error => ({
    type: ResellerActionTypes.GET_RESELLER_CONTACT_PAGE_INFORMATION_ERROR,
  }),

  //Update reseller contact page information
  UpdateResellerContactPageInformationRequest: (
    request?: IUpdateResellerContactPageInformtaionRequest
  ): Update_Reseller_Contact_Page_Information_Request => ({
    type: ResellerActionTypes.UPDATE_RESELLER_CONTACT_PAGE_INFORMATION_REQUEST,
    request,
  }),
  UpdateResellerContactPageInformationSuccess: (response: IStandardResponse): Update_Reseller_Contact_Page_Information_Success => ({
    type: ResellerActionTypes.UPDATE_RESELLER_CONTACT_PAGE_INFORMATION_SUCCESS,
    response,
  }),
  UpdateResellerContactPageInformationError: (): Update_Reseller_Contact_Page_Information_Error => ({
    type: ResellerActionTypes.UPDATE_RESELLER_CONTACT_PAGE_INFORMATION_ERROR,
  }),

  //RESELLER TEXTS
  GetResellerHomePageTextsRequest: (request?: IGetResellerHomePageTextsRequest): Get_Reseller_Home_Page_Texts_Request => ({
    type: ResellerActionTypes.GET_RESELLER_HOME_PAGE_TEXTS_REQUEST,
    request,
  }),
  GetResellerHomePageTextsSuccess: (response: IGetResellerHomePageTextsResponse): Get_Reseller_Home_Page_Texts_Success => ({
    type: ResellerActionTypes.GET_RESELLER_HOME_PAGE_TEXTS_SUCCESS,
    response,
  }),
  GetResellerHomePageTextsError: (): Get_Reseller_Home_Page_Texts_Error => ({
    type: ResellerActionTypes.GET_RESELLER_HOME_PAGE_TEXTS_ERROR,
  }),

  GetResellerHomePageTextsByCodeRequest: (
    request?: IGetResellerHomePageTextsByCodeRequest
  ): Get_Reseller_Home_Page_Texts_By_Code_Request => ({
    type: ResellerActionTypes.GET_RESELLER_HOME_PAGE_TEXTS_BY_CODE_REQUEST,
    request,
  }),
  GetResellerHomePageTextsByCodeSuccess: (
    response: IGetResellerHomePageTextsByCodeResponse
  ): Get_Reseller_Home_Page_Texts_By_Code_Success => ({
    type: ResellerActionTypes.GET_RESELLER_HOME_PAGE_TEXTS_BY_CODE_SUCCESS,
    response,
  }),
  GetResellerHomePageTextsByCodeError: (): Get_Reseller_Home_Page_Texts_By_Code_Error => ({
    type: ResellerActionTypes.GET_RESELLER_HOME_PAGE_TEXTS_BY_CODE_ERROR,
  }),

  UpdateResellerHomePageTextsRequest: (request?: IUpdateResellerHomePageTextsRequest): Update_Reseller_Home_Page_Texts_Request => ({
    type: ResellerActionTypes.UPDATE_RESELLER_HOME_PAGE_TEXTS_REQUEST,
    request,
  }),
  UpdateResellerHomePageTextsSuccess: (response: IStandardResponse): Update_Reseller_Home_Page_Texts_Success => ({
    type: ResellerActionTypes.UPDATE_RESELLER_HOME_PAGE_TEXTS_SUCCESS,
    response,
  }),
  UpdateResellerHomePageTextsError: (): Update_Reseller_Home_Page_Texts_Error => ({
    type: ResellerActionTypes.UPDATE_RESELLER_HOME_PAGE_TEXTS_ERROR,
  }),

  //Get reseller contacts
  GetResellerContactsRequest: (request: IGetResellerContactsRequest): Get_Reseller_Contacts_Request => ({
    type: ResellerActionTypes.GET_RESELLER_CONTACTS_REQUEST,
    request,
  }),
  GetResellerContactsSuccess: (response: IGetResellerContactsResponse): Get_Reseller_Contacts_Success => ({
    type: ResellerActionTypes.GET_RESELLER_CONTACTS_SUCCESS,
    response,
  }),
  GetResellerContactsError: (): Get_Reseller_Contacts_Error => ({
    type: ResellerActionTypes.GET_RESELLER_CONTACTS_ERROR,
  }),

  //Validate Email (Reseller)
  ValidateResellerEmailRequest: (request: IValidateResellerEmailRequest): Validate_Reseller_Email_Request => ({
    type: ResellerActionTypes.VALIDATE_RESELLER_EMAIL_REQUEST,
    request,
  }),
  ValidateResellerEmailSuccess: (response: IValidateResellerEmailResponse): Validate_Reseller_Email_Success => ({
    type: ResellerActionTypes.VALIDATE_RESELLER_EMAIL_SUCCESS,
    response,
  }),
  ValidateResellerEmailError: (): Validate_Reseller_Email_Error => ({
    type: ResellerActionTypes.VALIDATE_RESELLER_EMAIL_ERROR,
  }),
};
