export const ValidateDateIsGreaterThan = (
  value: Date,
  shouldBegreaterThanValue: Date
): boolean =>
  value && value?.toDateString() > shouldBegreaterThanValue?.toDateString()
    ? true
    : false;

export const ValidateEmail = (email: string): boolean => {
  return /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
    email
  );
};

export const ValidateNumberStartWith = (
  number: string,
  validationString1: string,
  validationString2: string
): boolean =>
  (number && number?.startsWith(validationString1)) ||
  (number && number?.startsWith(validationString2))
    ? true
    : false;

export const IsLongerThanOrEqualToLength = (
  value: string,
  lengthCheck: number
) =>
  (value && value?.length > lengthCheck) ||
  (value && value?.length === lengthCheck)
    ? true
    : false;

export const IsHigherThanAllowedMaxValue = (
  value: number,
  allowedMaxValue: number
): boolean => (value && value > allowedMaxValue ? true : false);

export const ValidateExactStringLength = (
  value: string,
  exactLength: number
): boolean => (value && value?.length === exactLength ? true : false);

export const MatchValues = (value1: string, value2: string): boolean =>
  value1 === value2 ? true : false;
