import React from "react";
import Grid from "@mui/material/Grid/Grid";
import { useTranslation } from "react-i18next";
import { LabelKeys } from "../../../../../Utils/enums/labelKeys";
import { IResellerContactLine } from "../../../types";
import Button from "@mui/material/Button/Button";
import { Typography, useMediaQuery } from "@mui/material";
import { theme } from "../../../../../appTheme";

interface IAddReseller {
  resellerContact: IResellerContactLine;
  onSave: (resellerContact: IResellerContactLine) => void;
  onClose: () => void;
}

export default function DeleteResellerContact(props: IAddReseller) {
  const { t: translate } = useTranslation();
  const isMobile = useMediaQuery(theme.breakpoints.only("xs"));

  return (
    <Grid container item xs={12} sm={12} md={12} lg={12} xl={12} style={{ overflowY: "auto", width: "100%", backgroundColor: "#e3e1de" }}>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ paddingTop: 20 }}>
        <Typography variant="body1">{`${translate(LabelKeys.ConfirmDeleteResellerContact)}`}</Typography>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ paddingTop: 20 }}>
        <Typography variant="body1">{`${props.resellerContact.email}`}</Typography>
      </Grid>
      <Grid item xs={12} sm={6} md={6} lg={6} xl={6} style={{ paddingTop: 31, paddingRight: isMobile ? 0 : 8 }}>
        <Button
          id="saveBtn"
          style={{
            height: 46,
            width: "100%",
            cursor: "pointer",
            color: "#fff",
            borderRadius: 0,
            backgroundColor: "#000",
          }}
          onClick={() => props.onSave(props.resellerContact)}
        >
          <div style={{ margin: "auto", textTransform: "none" }}>{`${translate(LabelKeys.Yes)}`}</div>
        </Button>
      </Grid>
      <Grid item xs={12} sm={6} md={6} lg={6} xl={6} style={{ paddingTop: 31, paddingLeft: isMobile ? 0 : 8 }}>
        <Button
          id="cancelBtn"
          style={{
            height: 46,
            width: "100%",
            cursor: "pointer",
            color: "#fff",
            borderRadius: 0,
            backgroundColor: "#000",
          }}
          onClick={() => {
            props.onClose();
          }}
        >
          <div style={{ margin: "auto", textTransform: "none" }}>{`${translate(LabelKeys.No)}`}</div>
        </Button>
      </Grid>
    </Grid>
  );
}
