import React, { useEffect, useState } from "react";
import { Button, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import Cookies from "js-cookie";
import { CookieConstants } from "../../Utils/Labels/types";
import { LabelKeys } from "../../Utils/enums/labelKeys";

export default function CSCookieConsent() {
  const { t: translate } = useTranslation();
  const [cookieConsent, setCookieConsent] = useState(false);
  const [open, setOpen] = React.useState(false);

  useEffect(() => {
    const cookieValue = Cookies.get(CookieConstants.CookiesConsent);
    if (cookieConsent === undefined || Boolean(cookieConsent) === false) {
      setCookieConsent(false);
      setOpen(true);
    }

    if (Boolean(cookieValue) === true) {
      setCookieConsent(true);
    }
  }, []);

  useEffect(() => {}, [cookieConsent]);

  function handleUnderStandCookieClicked(): void {
    const options: Cookies.CookieAttributes = {
      expires: 365,
    } as Cookies.CookieAttributes;

    Cookies.set(CookieConstants.CookiesConsent, "true", options);
    setCookieConsent(true);
    setOpen(false);
  }

  return (
    !cookieConsent && (
      <div
        style={{
          bottom: 0,
          width: "100vw",
          position: "fixed",
          justifyContent: "center",
          backgroundColor: "black",
          color: "white",
          height: "auto",
          zIndex: 10000,
        }}
      >
        <div style={{ display: "flex", justifyContent: "center", padding: 10 }}>
          <Typography variant="body1" style={{ color: "#fff", width: 600, textAlign: "left", marginTop: 15, marginBottom: 15 }}>
            {translate(LabelKeys.CookieInfo)}
          </Typography>
          <div>
            <Button
              onClick={handleUnderStandCookieClicked}
              color="warning"
              style={{ backgroundColor: "orange", color: "#000", marginTop: 21, marginLeft: 20, textTransform: "unset" }}
            >
              {translate(LabelKeys.GotIt)}
            </Button>
          </div>
        </div>
      </div>
    )
  );
}
