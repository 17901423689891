import React, { useEffect, useState } from "react";
import { LabelKeys } from "../../Utils/enums/labelKeys";
import { useTranslation } from "react-i18next";
import { Button, Grid, Typography } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { OrderActions } from "../../Modules/Orders/actions";
import { RootState } from "../../store";
import { Currency, Environment, SystemPart } from "../../Utils/enums/enums";
import Cookies from "js-cookie";
import { CookieConstants, GTMID } from "../../Utils/Labels/types";
import OrderSummary from "../../components/OrderSummary/OrderSummary";
import { CheckSystemPartCookie } from "../../Utils/authentication";
import ProcessCircle from "../../components/ProcessCircle/ProcessCircle";
import { ProductCategory } from "../../Modules/Products/types";
import TagManager from "react-gtm-module";
import { IOrderLineConfirmationDetails } from "../../Modules/Orders/types";
import { GetCurrentLanguageAsText } from "../../Utils/types";

function OrderConfirmation() {
  const { t: translate } = useTranslation();
  const search = useLocation().search;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const systemPart: SystemPart = Number(Cookies.get(CookieConstants.SystemPart));
  const [currency, setCurrency] = useState(Currency.None);
  const [quantity, setQuantity] = useState(0);
  const reducerState = useSelector(
    (s: RootState) => {
      return {
        order: s.order,
      };
    },
    (prev, next) =>
      prev.order.orderConfirmationDetails === next.order.orderConfirmationDetails && prev.order.isLoading === next.order.isLoading
  );

  useEffect(() => {
    CheckSystemPartCookie();
    window.scrollTo(0, 0);
    const orderId = new URLSearchParams(search).get("orderId");

    if (orderId) {
      dispatch(
        OrderActions.GetOrderConfirmationDetailsRequest({
          internalOrderId: orderId,
        })
      );
    }
  }, []);

  useEffect(() => {
    let orderLine = reducerState.order.orderConfirmationDetails?.orderLines?.find((x) => x.productName != undefined);

    if (orderLine) {
      setCurrency(orderLine.currency);
      setQuantity(orderLine.quantity);
    }

    if (
      reducerState.order.orderConfirmationDetails?.paid &&
      (process.env.REACT_APP_ENVIRONMENT == Environment.Test || process.env.REACT_APP_ENVIRONMENT == Environment.Production)
    ) {
      const tagManagerArgs = {
        gtmId: GTMID,
        dataLayerName: "PageDataLayer",
        dataLayer: {
          event: "Order_confirmation_details",
          order: [GetOrderInfo()] as any,
        },
      };

      TagManager.initialize(tagManagerArgs);
    }
  }, [reducerState.order.orderConfirmationDetails]);

  function GetOrderInfo(): IGA4OrderItemInfo {
    let ga4Order = reducerState.order.orderConfirmationDetails;

    return {
      orderId: ga4Order.orderId,
      customerNumber: ga4Order.customerNumber,
      currency: Currency[ga4Order.orderLines[0]?.currency],
      totalValue: GetTotalValue(ga4Order.orderLines),
      vat: ga4Order.vat,
      orderLines: GetOrderLines(ga4Order.orderLines),
    };
  }

  function GetTotalValue(orderLines: IOrderLineConfirmationDetails[]) {
    let total: number = 0;
    orderLines.forEach((x) => (total += x.priceExcludingVat));

    return total;
  }

  function GetOrderLines(orderLines: IOrderLineConfirmationDetails[]): IGA4OrderLineItem[] {
    let lines: IGA4OrderLineItem[] = [];

    orderLines?.forEach((element) => {
      lines.push({
        productName: element.productName,
        quantity: element.quantity,
        priceExcludingVat: element.priceExcludingVat,
        category: ProductCategory[element.category],
      } as IGA4OrderLineItem);
    });

    return lines;
  }

  interface IGA4OrderItemInfo {
    orderId: number;
    vat: number;
    orderLines: IGA4OrderLineItem[];
    totalValue: number;
    currency: string;
    customerNumber?: string;
  }

  interface IGA4OrderLineItem {
    productName: string;
    quantity: number;
    priceExcludingVat: number;
    category: string;
  }

  function HandleGoToHomePageOnClick(): void {
    if (reducerState.order.orderConfirmationDetails?.isAdminOrder) {
      navigate(`/${GetCurrentLanguageAsText()}/${translate(LabelKeys.AdminUrl)}`);
    } else if (reducerState.order.orderConfirmationDetails?.isResellerAdminOrder) {
      navigate(`/${GetCurrentLanguageAsText()}/${translate(LabelKeys.ResellerUrl)}/${translate(LabelKeys.AdminUrl)}`);
    } else {
      navigate(`/${GetCurrentLanguageAsText()}/`);
    }
  }

  return (
    <React.Fragment>
      {reducerState.order.isLoading ? (
        <ProcessCircle />
      ) : reducerState.order.orderConfirmationDetails?.paid == true ? (
        <Grid item container xs={12} sm={12} md={12} lg={12} xl={12} justifyContent={"center"}>
          <Grid item sm={2} md={3} lg={4} xl={4} />
          <Grid
            item
            xs={11}
            sm={8}
            md={6}
            lg={4}
            xl={4}
            sx={{
              paddingTop: {
                xs: "50px",
                sm: "80px",
                md: "80px",
                lg: "80px",
                xl: "80px",
              },
            }}
          >
            <Typography variant={"h1"}>{translate(LabelKeys.ThankYouForYourOrder)}</Typography>
            <Typography variant={"body1"} style={{ paddingTop: 20 }}>
              {translate(LabelKeys.OrderConfirmationBody1)}
              {" #"}
              <strong>{reducerState.order.orderConfirmationDetails?.orderId?.toString()}</strong>{" "}
              {translate(LabelKeys.OrderConfirmationBody2)}
            </Typography>
            <Typography variant={"body1"} style={{ paddingTop: 20 }}>
              {translate(LabelKeys.OrderConfirmationBody3)} <strong>{reducerState.order.orderConfirmationDetails?.contactEmail}</strong>
            </Typography>
          </Grid>
          <Grid item sm={2} md={3} lg={4} xl={4} />
          {currency != Currency.None && quantity > 0 && (
            <Grid container item xs={11} sm={10} md={10} lg={6} xl={6}>
              <OrderSummary
                id="orderConfirmationOrderSummary"
                currency={currency}
                quantity={quantity}
                discount={reducerState.order.orderConfirmationDetails?.discount}
                isAdminOrder={
                  reducerState.order.orderConfirmationDetails?.isAdminOrder &&
                  !reducerState.order.orderConfirmationDetails?.isResellerAdminOrder
                }
                isResellerAdminOrder={reducerState.order.orderConfirmationDetails?.isResellerAdminOrder}
                isOrderConfirmation={true}
                values={reducerState.order.orderConfirmationDetails?.orderLines?.map((x) => {
                  return {
                    name: x?.productName,
                    priceExcludingVat: x?.priceExcludingVat,
                    priceIncludingVat: x?.priceIncludingVat,
                    amount: x?.quantity,
                    category: x?.category,
                    quantity: x?.category != ProductCategory.CustomWebPage ? quantity : 1,
                  };
                })}
                systemPart={systemPart}
              />
            </Grid>
          )}
          <Grid container item xs={12} sm={12} md={12} lg={12} xl={12} justifyContent="center">
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ marginTop: 20 }}>
              <Button
                id="goToHomePageBtn"
                style={{
                  height: 60,
                  width: 200,
                  cursor: "pointer",
                  margin: "auto",
                  display: "flex",
                  marginTop: 20,
                  borderRadius: 0,
                  backgroundColor: "#000",
                  minWidth: 120,
                }}
                onClick={() => HandleGoToHomePageOnClick()}
              >
                <Typography
                  variant="body1"
                  style={{
                    margin: "auto",
                    textTransform: "none",
                    color: "#fff",
                  }}
                >
                  {translate(LabelKeys.GoToHomePage)}
                </Typography>
              </Button>
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <Grid item container xs={12} sm={12} md={12} lg={12} xl={12} justifyContent={"center"}>
          <Grid
            item
            xs={11}
            sm={8}
            md={6}
            lg={4}
            xl={4}
            sx={{
              paddingTop: {
                xs: "50px",
                sm: "80px",
                md: "80px",
                lg: "80px",
                xl: "80px",
              },
            }}
          >
            <Typography variant={"h1"}>{translate(LabelKeys.OrderNotCompletedTitle)}</Typography>
            <Typography variant={"body1"} style={{ paddingTop: 20 }}>
              {translate(LabelKeys.OrderNotCompletedBody)}
            </Typography>
          </Grid>
          <Grid container item xs={12} sm={12} md={12} lg={12} xl={12} justifyContent="center">
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ marginTop: 20 }}>
              <Button
                id="goToHomePageBtn"
                style={{
                  height: 60,
                  width: 200,
                  cursor: "pointer",
                  margin: "auto",
                  display: "flex",
                  marginTop: 20,
                  borderRadius: 0,
                  backgroundColor: "#000",
                  minWidth: 120,
                }}
                onClick={() => HandleGoToHomePageOnClick()}
              >
                <Typography
                  variant="body1"
                  style={{
                    margin: "auto",
                    textTransform: "none",
                    color: "#fff",
                  }}
                >
                  {translate(LabelKeys.GoToHomePage)}
                </Typography>
              </Button>
            </Grid>
          </Grid>
        </Grid>
      )}
    </React.Fragment>
  );
}

export default OrderConfirmation;
