import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import { FieldNames, StateNames } from "../../Utils/types";
import { StepperStyles } from "./styles";
import { Typography } from "@mui/material";
import { useEffect } from "react";

interface ICSStepper {
  activeStep: number;
  errorSections: IErrorStep[];
  onStateChanged: (stateNames: StateNames.stepperState, value: number, fieldName: FieldNames) => void;
}

export interface IErrorStep {
  name: string;
}

export interface ICSStep {
  id: number;
  name: string;
  errorStateName: FieldNames;
}

const steps = [
  { id: 0, name: "Korttyp", errorStateName: FieldNames.stepOne },
  { id: 1, name: "Belopp", errorStateName: FieldNames.stepTwo },
  { id: 2, name: "Design", errorStateName: FieldNames.stepThree },
  { id: 3, name: "Avsändare", errorStateName: FieldNames.stepFour },
  { id: 4, name: "Mottagare", errorStateName: FieldNames.stepFive },
  { id: 5, name: "Betalning", errorStateName: FieldNames.stepSix },
] as ICSStep[];

export default function CSStepper(props: ICSStepper) {
  const classes = StepperStyles();

  useEffect(() => {}, [props.errorSections]);

  function handleOnStepClicked(step: ICSStep) {
    props.onStateChanged(StateNames.stepperState, step.id, FieldNames.activeStep);
  }

  return (
    <Box>
      <Stepper activeStep={props.activeStep} orientation="vertical">
        {steps?.map((x) => (
          <Step style={{ width: 120 }} key={x.id} onClick={() => handleOnStepClicked(x)}>
            <StepLabel
              StepIconComponent={() => {
                if (props.errorSections?.find((y) => y.name.toLowerCase() == x.errorStateName.toLowerCase())) {
                  return (
                    <svg
                      width="30"
                      height="30"
                      fill="currentColor"
                      className="bi bi-x-circle-fill"
                      viewBox="0 0 16 16"
                      style={{ fill: "red" }}
                    >
                      <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z" />
                    </svg>
                  );
                } else
                  return props.activeStep === x.id ? (
                    <div
                      style={{
                        height: 30,
                        width: 30,
                        backgroundColor: "#bdb9b2",
                        borderRadius: 30,
                      }}
                    >
                      <svg
                        width="16"
                        height="16"
                        fill="#000"
                        className="bi bi-pencil"
                        viewBox="0 0 16 16"
                        style={{
                          height: "50%",
                          width: "50%",
                          paddingLeft: 8,
                          paddingTop: 7,
                          color: "#fff",
                        }}
                      >
                        <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z" />
                      </svg>
                    </div>
                  ) : props.activeStep > x.id ? (
                    <div
                      style={{
                        height: 30,
                        width: 30,
                        backgroundColor: "#bdb9b2",
                        borderRadius: 30,
                      }}
                    >
                      <svg
                        width="16"
                        height="16"
                        fill="currentColor"
                        className="bi bi-check-lg"
                        viewBox="0 0 16 16"
                        style={{
                          height: "60%",
                          width: "60%",
                          paddingLeft: 6,
                          paddingTop: 6,
                          color: "#000",
                        }}
                      >
                        <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z" />
                      </svg>
                    </div>
                  ) : (
                    <div
                      className={classes.icon}
                      style={{
                        height: 30,
                        width: 30,
                        backgroundColor: "#bdb9b2",
                        borderRadius: 30,
                      }}
                    />
                  );
              }}
            >
              <Typography variant="body2" className={classes.label} style={{ fontWeight: props.activeStep === x.id ? "bold" : "" }}>
                {x.id + 1 + ". " + x.name}
              </Typography>
            </StepLabel>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
}
