import React, { useEffect } from "react";
import { Button, Grid, Typography, useMediaQuery } from "@mui/material";
import { FieldNames, StateNames } from "../../../Utils/types";
import { IProduct, ProductCategory } from "../../../Modules/Products/types";
import { StepperTwoStyles } from "../styles";
import { LabelKeys } from "../../../Utils/enums/labelKeys";
import { useTranslation } from "react-i18next";
import { Currency, SystemPart } from "../../../Utils/enums/enums";
import { theme } from "../../../appTheme";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store";
import ExampleAssortment from "../../ExampleAssortment/ExampleAssortment";
import { OrderActions } from "../../../Modules/Orders/actions";
import { IsResellerAuth, IsAuth } from "../../../Utils/authentication";
import { IFilterOption } from "../../DropDown/types";

interface IStepperStepTwoGiftCardProps {
  id: string;
  systemPart: SystemPart;
  options: IProduct[];
  selectedProductHasError: boolean;
  onStateChanged?: (stateName: StateNames.stepperTwoState, value: IProduct, fieldName: FieldNames) => void;
}

export default function StepperStepTwoGiftCard(props: IStepperStepTwoGiftCardProps) {
  const classes = StepperTwoStyles();
  const { t: translate } = useTranslation();
  const isMobile = useMediaQuery(theme.breakpoints.only("xs"));
  const dispatch = useDispatch();
  const isSystemAdmin = IsAuth() && location.pathname.includes(`/${translate(LabelKeys.AdminUrl)}/${translate(LabelKeys.OrderUrl)}`);
  const isResellerAdmin =
    IsResellerAuth() &&
    location.pathname.includes(`/${translate(LabelKeys.ResellerUrl)}/${translate(LabelKeys.AdminUrl)}/${translate(LabelKeys.OrderUrl)}`);

  const reducerState = useSelector(
    (s: RootState) => {
      return {
        order: s.order,
        product: s.product,
        reseller: s.reseller,
      };
    },
    (prev, next) =>
      prev.order.stepperOneState.selectedProductCategory === next.order.stepperOneState.selectedProductCategory &&
      prev.order.stepperTwoState.selectedProduct === next.order.stepperTwoState.selectedProduct &&
      prev.reseller.adminSelectedResellerCountryOption === next.reseller.adminSelectedResellerCountryOption &&
      prev.product.products === next.product.products
  );

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  useEffect(() => {
    scrollGiftCardContainer();
    if (reducerState.order.stepperTwoState.selectedProduct?.id === undefined && props.options?.length > 0) {
      const product = props.options.find((x) => x.category == ProductCategory.GiftCardGMG && x?.name.includes("Regal"));

      if (product?.id > 0) {
        dispatch(
          OrderActions.SetStepTwoStateRequest({
            fieldName: FieldNames.selectedProduct,
            value: product,
          })
        );
      }
    }
  }, [props.options]);

  function scrollGiftCardContainer() {
    if (reducerState.order.stepperTwoState.selectedProduct?.id === undefined) {
      let element = document.getElementById("giftcardsContainerStepTwo");
      if (element !== undefined) {
        element.scrollLeft = 375;
      }
    }
  }

  function GetTextToReplace(): string {
    const product: IProduct = props.options?.find((x) => x.category == ProductCategory.Freight);
    if (product?.id != undefined) {
      return `${
        props.systemPart == SystemPart.Private && !isSystemAdmin && !isResellerAdmin ? product.priceIncludingVat : product.priceExcludingVat
      } ${Currency[product.currency]}`;
    } else {
      return "x";
    }
  }

  return (
    <Grid container justifyContent="center" item xs={12} sm={12} md={12} lg={12} xl={12}>
      <Grid id="leftContent" container item xs={12} sm={12} md={6} lg={6} xl={6} justifyContent="center">
        <Grid id="title" item xs={12} sm={12} md={12} lg={12} xl={12} justifyContent="center">
          <Typography variant="h1" align="left">
            {translate(LabelKeys.ChooseGiftcard)}
          </Typography>
        </Grid>
        <Grid id="description" item xs={12} sm={12} md={12} lg={12} xl={12} justifyContent="center" style={{ paddingTop: 10 }}>
          <Typography variant="body1" align="left">
            {translate(LabelKeys.GiftcardGMGDescription)}{" "}
            {(props.systemPart == SystemPart.Company || isSystemAdmin || isResellerAdmin) && translate(LabelKeys.NoteAllExclVat)}
          </Typography>
        </Grid>
        {reducerState.order.stepperOneState?.selectedProductCategory?.id === undefined && (
          <Grid id="noSelectedCategory" item xs={12} sm={12} md={12} lg={12} xl={12} justifyContent="center" style={{ paddingTop: 40 }}>
            <Typography variant="body1" align="left" style={{ fontWeight: "bold" }}>
              {translate(LabelKeys.NoSelectedCategory)}
            </Typography>
          </Grid>
        )}
      </Grid>
      <Grid id="rightContent" container item xs={6} sm={6} md={6} lg={6} xl={6} justifyContent="center">
        <Grid id="title" item xs={12} sm={12} md={12} lg={12} xl={12} />
        <Grid id="description" item xs={12} sm={12} md={12} lg={12} xl={12} />
      </Grid>
      <Grid
        id="giftcardsContainerStepTwo"
        container
        item
        xs={12}
        sm={12}
        md={12}
        lg={12}
        xl={12}
        style={{
          paddingTop: 40,
          display: "flex",
          flexWrap: isMobile ? "nowrap" : "inherit",
          overflowX: isMobile ? "scroll" : "inherit",
        }}
        className={classes.giftcardContainer}
        spacing={1}
      >
        {props?.options
          .filter((x) => x.category == ProductCategory.GiftCardGMG)
          ?.map((x) => {
            return (
              <div
                id={x.name}
                key={x?.id}
                style={{ minWidth: 225, position: "relative", height: 135 }}
                className={
                  x?.id === reducerState.order.stepperTwoState.selectedProduct?.id ? classes.selectedCard : classes.notSelectedCard
                }
                onClick={() => {
                  props.onStateChanged(StateNames.stepperTwoState, x, FieldNames.selectedProduct);
                }}
              >
                {x?.isMostPopular ||
                  (x?.name.includes("Regal") && (
                    <div
                      style={{
                        position: "absolute",
                        zIndex: 2,
                        marginTop: -15,
                        marginLeft: 50,
                      }}
                    >
                      <Typography
                        variant="body1"
                        style={{
                          height: 27,
                          width: 127,
                          backgroundColor: "#2eac77",
                          color: "#fff",
                        }}
                      >
                        {translate(LabelKeys.PopularChoice)}
                      </Typography>
                    </div>
                  ))}
                <Typography
                  variant="h5"
                  style={{
                    paddingTop: 20,
                    color: x?.id === reducerState.order.stepperTwoState.selectedProduct?.id ? "#fff" : "#000",
                  }}
                >
                  {x?.name}
                </Typography>
                <Typography
                  variant="body1"
                  style={{
                    paddingTop: 20,
                    color: x?.id === reducerState.order.stepperTwoState.selectedProduct?.id ? "#fff" : "#000",
                  }}
                >{`${
                  props.systemPart == SystemPart.Company || isSystemAdmin || isResellerAdmin
                    ? x?.priceExcludingVat + ` ${Currency[x?.currency]}/st`
                    : x?.priceIncludingVat + ` ${Currency[x?.currency]}/st`
                }`}</Typography>
                <Typography variant="body2" style={{ color: "grey" }}>
                  {props.systemPart == SystemPart.Company || isSystemAdmin || isResellerAdmin
                    ? translate(LabelKeys.ExcludingVat).toLowerCase()
                    : translate(LabelKeys.IncludingVat).toLowerCase()}
                </Typography>
              </div>
            );
          })}
      </Grid>
      {reducerState.order.stepperOneState?.selectedProductCategory?.id > 0 && (
        <Grid
          id="freightCostWillBeAdded"
          container
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          style={{ paddingTop: 20, display: "flex" }}
          justifyContent="flex-start"
        >
          <Typography variant="body2">
            {translate(
              props.systemPart == SystemPart.Private && !isSystemAdmin && !isResellerAdmin
                ? LabelKeys.PriceWillAddVatPrivate
                : LabelKeys.PriceWillAddVat
            )?.replaceAll("{amount} {currency}", GetTextToReplace())}
          </Typography>
        </Grid>
      )}
      {(props.systemPart == SystemPart.Company || isSystemAdmin || isResellerAdmin) &&
        reducerState.order.stepperOneState?.selectedProductCategory?.id > 0 && (
          <Grid
            id="freightCostWillBeAdded"
            container
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            style={{ paddingTop: 5, display: "flex" }}
            justifyContent="flex-start"
          >
            <Typography variant="body2">{"** " + translate(LabelKeys.NoteAllExclVat)}</Typography>
          </Grid>
        )}
      {reducerState.order.stepperTwoState.selectedProduct?.id !== undefined && (
        <Grid id="exampleAssortment" container item xs={12} sm={12} md={12} lg={12} xl={12} style={{ paddingTop: 15 }}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ display: "flex" }}>
            <Typography
              variant="h2"
              style={{
                textAlign: "left",
                fontWeight: 650,
                width: "100%",
                margin: "auto",
              }}
            >
              {translate(LabelKeys.ExampleAssortment)}
            </Typography>
            {!isMobile && (
              <Button
                id="seeEntireCollection"
                style={{
                  height: 60,
                  width: isMobile ? "100%" : "auto",
                  cursor: "pointer",
                  color: "#000",
                  display: "flex",
                  marginTop: 20,
                  borderRadius: 0,
                  backgroundColor: "#e3e1de",
                  minWidth: 260,
                  border: "1px solid #000",
                }}
              >
                <Typography
                  variant="body1"
                  style={{
                    margin: "auto",
                    textTransform: "none",
                  }}
                >
                  <a
                    style={{ textDecoration: "none", color: "#000" }}
                    href={
                      reducerState?.order.stepperOneState.selectedProductCategory.category == ProductCategory.GiftCardVH
                        ? "https://vingahome.com/se/egendesignade-sidor/vh-demo"
                        : "https://getmygift.se/se/" +
                          reducerState?.order.stepperTwoState.selectedProduct?.name?.replace(" ", "").toLowerCase()
                    }
                    rel="noreferrer"
                    target={"_blank"}
                  >
                    {translate(LabelKeys.SeeWholeAssortment) + " " + reducerState?.order.stepperTwoState?.selectedProduct?.name}
                  </a>
                </Typography>
              </Button>
            )}
          </Grid>
          <ExampleAssortment id="exampleAssortmentId" productName={reducerState.order.stepperTwoState.selectedProduct?.name} />
          {isMobile && (
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ paddingTop: 40 }}>
              <Button
                id="seeExampleAssortmentBtn"
                style={{
                  height: 52,
                  width: 250,
                  border: "1px solid #000",
                  cursor: "pointer",
                  color: "#000",
                  display: "flex",
                  marginTop: 20,
                  borderRadius: 0,
                  margin: "auto",
                }}
                onClick={() => window.open("https://vinga.com/se/produkter", "_blank")}
              >
                <Typography
                  variant="body1"
                  style={{
                    paddingLeft: 10,
                    textTransform: "none",
                  }}
                >
                  <a
                    style={{ textDecoration: "none", color: "#000" }}
                    href={
                      reducerState?.order.stepperOneState.selectedProductCategory.category == ProductCategory.GiftCardVH
                        ? "https://vingahome.com/se/egendesignade-sidor/vh-demo"
                        : "https://getmygift.se/se/" + reducerState?.order.stepperTwoState.selectedProduct?.name?.toLowerCase()
                    }
                    rel="noreferrer"
                    target={"_blank"}
                  >
                    {translate(LabelKeys.SeeWholeAssortment) + " " + reducerState?.order.stepperTwoState?.selectedProduct?.name}
                  </a>
                </Typography>
              </Button>
            </Grid>
          )}
        </Grid>
      )}
    </Grid>
  );
}
