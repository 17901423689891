import * as React from "react";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import { CSImageSelectorStyles } from "./styles";
import { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import { IFilterOption } from "../DropDown/types";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import { IUploadedFile } from "../FileUploader/types";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { FieldNames } from "../../Utils/types";
import Button from "@mui/material/Button/Button";
import { LabelKeys } from "../../Utils/enums/labelKeys";
import { IImageOption } from "../../Modules/Products/types";

interface ICSImageSelectorPhysicalCardEditorProps {
  imageOptions: IImageOption[];
  rowHeight?: number;
  physicalCardStandardImage: IImageOption;
  physicalCardSelectedCoverImage?: IImageOption;
  physicalCardSelectedCategory?: IFilterOption;
  hasUploadedImage?: boolean;
  className?: string;
  height?: number;
  onStateChanged: (value: IImageOption | IUploadedFile, fieldName: FieldNames) => void;
}

export default function CSImageSelectorPhysicalCardEditor(props: ICSImageSelectorPhysicalCardEditorProps) {
  const classes = CSImageSelectorStyles();
  const { t: translate } = useTranslation();
  const [fileName, setFileName] = useState("");
  const [imageOptions, setImageOptions] = useState([]);

  useEffect(() => {
    if (
      (props.physicalCardSelectedCoverImage?.imageId == "" || props.physicalCardSelectedCoverImage?.imageId == undefined) &&
      !props.hasUploadedImage
    ) {
      setStandardImage();
    }
  }, []);

  useEffect(() => {
    setImageOptions(props.imageOptions);
  }, [props.imageOptions]);

  function setStandardImage() {
    let standardImage = props.physicalCardStandardImage;
    props.onStateChanged(standardImage, FieldNames.physicalCardSelectedCoverImage);
  }

  function handleImageClicked(item: IImageOption) {
    if (props.physicalCardSelectedCoverImage?.imageId !== item.imageId) {
      setFileName("");
      props.onStateChanged(item, FieldNames.physicalCardSelectedCoverImage);
    } else {
      setStandardImage();
    }
  }

  return (
    <Grid container item xs={12} sm={12} md={12} lg={12} xl={12}>
      {props.physicalCardSelectedCategory?.value != "upload" && (
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <ImageList
            cols={2}
            rowHeight={180}
            sx={{
              height: props.height ?? 198,
              backgroundColor: "#fff",
            }}
          >
            {imageOptions?.map((item) => (
              <ImageListItem
                key={item.imageId}
                style={{
                  height: 180,
                }}
              >
                {props.physicalCardSelectedCoverImage?.imageId == item?.imageId && (
                  <CheckCircleIcon
                    fontSize="large"
                    className={clsx(classes.imgHover, classes.checkmarkSelectedImage)}
                    onClick={() => {
                      handleImageClicked(item);
                    }}
                  />
                )}
                <img
                  onClick={() => handleImageClicked(item)}
                  className={clsx(
                    classes.imgHover,
                    props.physicalCardSelectedCoverImage?.imageId == item.imageId ? classes.selected : classes.unselected
                  )}
                  src={`${item.url}?maxWidth=150`}
                  alt={item.title}
                  style={{ height: "100%" }}
                />
              </ImageListItem>
            ))}
          </ImageList>
        </Grid>
      )}
      {fileName && props.physicalCardSelectedCategory?.value == "upload" && (
        <Grid item xs={6} sm={6} md={6} lg={4} xl={4} justifyContent="center" style={{ width: "100%", margin: "auto", marginTop: 20 }}>
          <Button
            style={{
              borderRadius: 0,
              borderColor: "#000",
              height: 40,
              border: "1px solid #000",
              display: "flex",
              margin: "auto",
              width: "100%",
              color: "#000",
            }}
            onClick={() => setStandardImage()}
          >
            <svg width="20" height="20" fill="currentColor" className="bi bi-trash" viewBox="0 0 16 16" style={{ paddingRight: 7 }}>
              <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
              <path
                fillRule="evenodd"
                d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"
              />
            </svg>
            <div style={{ textTransform: "none" }}>{translate(LabelKeys.Remove)}</div>
          </Button>
        </Grid>
      )}
    </Grid>
  );
}
