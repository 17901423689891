import makeStyles from "@mui/styles/makeStyles";
import { theme } from "../../../appTheme";

export const CSCheckboxStyles = makeStyles({
  root: {
    "& .MuiSvgIcon-root": {
      borderRadius: 0,
    },
  },
  checkbox: { [theme.breakpoints.only("xs")]: {} },
});
