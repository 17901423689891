import React from 'react';
import {
  Dialog,
  Typography,
  IconButton,
  DialogContent,
  DialogTitle,
  DialogActions,
  Grid,
} from '@mui/material';
import { DialogStyles } from './styles';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import CSButton from '../Button/CSButton';
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';

export interface DialogProps {
  id: string;
  open: boolean;
  title?: React.ReactNode;
  children?: React.ReactNode;
  enableSubmitButton?: boolean;
  enableCloseButton?: boolean;
  SubmitBtnText?: string;
  CloseBtnText?: string;
  onCloseClicked?(event: {}): void;
  onSubmitClicked?(
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ): void;
}

function CSDialog(props: DialogProps) {
  const classes = DialogStyles();
  return (
    <React.Fragment key={props.id}>
      <Dialog onClose={(e) => props.onCloseClicked(e)} open={props.open}>
        <DialogTitle className={classes.root}>
          <Grid container item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Grid
              item
              xs={10}
              sm={10}
              md={10}
              lg={10}
              xl={10}
              style={{ display: 'flex' }}
            >
              <WarningAmberOutlinedIcon
                fontSize="large"
                style={{ paddingRight: 10 }}
              />
              <Typography variant="h2" style={{ paddingTop: 5 }}>
                {props.title}
              </Typography>
            </Grid>
            <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
              <IconButton
                style={{ float: 'right' }}
                className={classes.closeButton}
                onClick={(e) => props.onCloseClicked(e)}
              >
                <CloseOutlinedIcon />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent dividers>
          <div
            style={{
              flexBasis: '33.33%',
              paddingRight: 10,
              float: 'left',
            }}
          >
            {props.children}
          </div>
        </DialogContent>
        <DialogActions>
          <DialogActions>
            {props.enableSubmitButton && (
              <CSButton
                id="dialogSubmitBtn"
                autoFocus
                height={40}
                label={props.SubmitBtnText}
                onClick={(e) => props.onSubmitClicked(e)}
                width={80}
              />
            )}
            {props.enableCloseButton && (
              <CSButton
                id="dialogCloseBtn"
                height={40}
                label={props.CloseBtnText}
                onClick={(e) => props.onCloseClicked(e)}
                width={80}
              />
            )}
          </DialogActions>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

export default CSDialog;
