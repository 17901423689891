import React, { useEffect, useState } from "react";
import { Grid, Tooltip, IconButton, Typography, Paper, Zoom, Menu, MenuItem, useMediaQuery } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import ProcessCircle from "../../../../components/ProcessCircle/ProcessCircle";
import EditableTable from "../../../../components/Table/EditableTable";
import { RootState } from "../../../../store";
import { LabelKeys } from "../../../../Utils/enums/labelKeys";
import { ResellerDetailStyles } from "../../styles";
import {
  IAddResellerContact,
  IEditResellerContactPageInformationData,
  IEditResellerDataData,
  IResellerContactLine,
  searchResellerContactsHeadCells,
} from "../../types";
import Popup from "../../../../components/Popup/Popup";
import EditResellerData from "./Popups/EditResellerData";
import EditResellerReferenceData from "./Popups/EditResellerReferenceData";
import { ResellerActions } from "../../../../Modules/Reseller/actions";
import {
  ICreateResellerContactRequest,
  IDeleteResellerContactRequest,
  IDeleteResellerHomePageImageRequest,
  IDeleteResellerLogoRequest,
  IGetResellerContactPageInformtaionRequest,
  IGetResellerHomePageImageRequest,
  IGetResellerHomePageTextsRequest,
  IGetResellerLogoRequest,
  IUpdateResellerContactPageInformtaionRequest,
  IUpdateResellerContactRequest,
  IUpdateResellerHomePageImageRequest,
  IUpdateResellerHomePageTextsRequest,
  IUpdateResellerLogoRequest,
  IUpdateResellerRequest,
  RequestFrom,
} from "../../../../Modules/Reseller/types";
import AddResellerContact from "./Popups/AddResellerContact";
import { FieldNames } from "../../../../Utils/types";
import SnackbarActions from "../../../../components/Snackbar/actions";
import DeleteResellerContact from "./Popups/DeleteResellerContact";
import ResellerSettings from "./Popups/ResellerIncomingOrderEmails";
import { theme } from "../../../../appTheme";
import { IUploadedFile } from "../../../../components/FileUploader/types";
import EditResellerContactPageData from "./Popups/EditResellerContactPageData";
import { GetValuesAsArray } from "../../../../Utils/extensions";

interface IResellerDetailsProps {
  onGoBackToResellerPageClicked?: (orderDetailsOpen: boolean) => void;
}

export default function ResellerDetails(props: IResellerDetailsProps) {
  const classes = ResellerDetailStyles();
  const dispatch = useDispatch();
  const { t: translate } = useTranslation();
  const [clicked, setClicked] = useState(false);
  const [isDataSectionDialogOpen, setIsDataSectionDialogOpen] = useState(false);
  const [isReferenceDataDialogOpen, setIsReferenceDataSectionDialogOpen] = useState(false);
  const [isContactPageInformationOpen, setIsContactPageInformationOpen] = useState(false);
  const [isAddResellerContactDialogOpen, setIsAddResellerContactDialogOpen] = useState(false);
  const [isDeleteResellerContactDialogOpen, setIsDeleteResellerContactDialogOpen] = useState(false);
  const [isResellerSettingsPopupOpen, setIsResellerSettingsPopupOpen] = useState(false);
  const [isResellerSettingsMenuOpen, setIsResellerSettingsMenuOpen] = useState(false);
  const [resellerContactLineToDelete, setResellerContactLineToDelete] = useState({} as IResellerContactLine);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const isMobile = useMediaQuery(theme.breakpoints.only("xs"));
  const isSm = useMediaQuery(theme.breakpoints.only("sm"));
  const isMd = useMediaQuery(theme.breakpoints.only("md"));
  const isLg = useMediaQuery(theme.breakpoints.only("lg"));
  const isXl = useMediaQuery(theme.breakpoints.only("xl"));

  const reducerState = useSelector(
    (s: RootState) => {
      return {
        Reseller: s.reseller,
      };
    },
    (prev, next) =>
      prev.Reseller.isLoading === next.Reseller.isLoading &&
      prev.Reseller.adminResellerRows === next.Reseller.adminResellerRows &&
      prev.Reseller.selectedAdminResellerRow === next.Reseller.selectedAdminResellerRow &&
      prev.Reseller.homePageImageUrl === next.Reseller.homePageImageUrl &&
      prev.Reseller.logotypeAsBase64 === next.Reseller.logotypeAsBase64 &&
      prev.Reseller.resellerContactPageInformation === next.Reseller.resellerContactPageInformation &&
      prev.Reseller.homePageHeaderText === next.Reseller.homePageHeaderText &&
      prev.Reseller.homePageBodyText === next.Reseller.homePageBodyText
  );

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (reducerState.Reseller.selectedAdminResellerRow?.id > 0) {
      dispatch(
        ResellerActions.GetResellerLogoRequest({
          resellerId: reducerState.Reseller.selectedAdminResellerRow?.id,
        } as IGetResellerLogoRequest)
      );
      dispatch(
        ResellerActions.GetResellerHomePageImageRequest({
          resellerId: reducerState.Reseller.selectedAdminResellerRow?.id,
        } as IGetResellerHomePageImageRequest)
      );
      dispatch(
        ResellerActions.GetResellerContactPageInformationAuthRequest({
          resellerId: reducerState.Reseller.selectedAdminResellerRow?.id,
        } as IGetResellerContactPageInformtaionRequest)
      );
      dispatch(
        ResellerActions.GetResellerHomePageTextsRequest({
          resellerId: reducerState.Reseller.selectedAdminResellerRow?.id,
        } as IGetResellerHomePageTextsRequest)
      );
    }
  }, [reducerState.Reseller.selectedAdminResellerRow]);

  function handleEditResellerDataSection() {
    setIsDataSectionDialogOpen(true);
  }

  function handleEditLogoUrlSection() {
    setIsReferenceDataSectionDialogOpen(true);
  }

  function handleContactPageEdit() {
    setIsContactPageInformationOpen(true);
  }

  function handleEditResellerDataSave(data: IEditResellerDataData): void {
    dispatch(
      ResellerActions.UpdateResellerRequest({
        id: data.id,
        companyName: data.companyName,
        customerNumber: data.customerNumber,
        discount: data.discount,
        isActive: data.isActive,
      } as IUpdateResellerRequest)
    );
  }

  function handleEditResellerContactPageDataSave(data: IEditResellerContactPageInformationData): void {
    dispatch(
      ResellerActions.UpdateResellerContactPageInformationRequest({
        resellerId: reducerState.Reseller.selectedAdminResellerRow?.id,
        addressLine1: data.addressLine1,
        city: data.city,
        postalCode: data.postalCode,
        country: data.country,
        website: data.website,
        contactEmails: GetValuesAsArray(data.contactEmails),
        contactPhoneNumbers: GetValuesAsArray(data.contactPhoneNumbers),
      } as IUpdateResellerContactPageInformtaionRequest)
    );
  }

  function handleOpenConfirmDeleteResellerContact(row: IResellerContactLine): void {
    if (row == undefined || row?.id <= 0) {
      dispatch(SnackbarActions.ShowError(translate(LabelKeys.InvalidResellerContactId)));
      return;
    }

    setResellerContactLineToDelete(row);
    setIsDeleteResellerContactDialogOpen(true);
  }

  function handleSaveLogotypeData(data: IUploadedFile): void {
    dispatch(
      ResellerActions.UpdateResellerLogoRequest({
        file: data.base64,
        fileType: data.type,
        resellerId: reducerState.Reseller.selectedAdminResellerRow?.id,
      } as IUpdateResellerLogoRequest)
    );
  }

  function handleSaveHomePageImageData(data: IUploadedFile): void {
    dispatch(
      ResellerActions.UpdateResellerHomePageImageRequest({
        file: data.base64,
        fileType: data.type,
        resellerId: reducerState.Reseller.selectedAdminResellerRow?.id,
      } as IUpdateResellerHomePageImageRequest)
    );
  }

  function handleSaveHomePageTexts(homePageHeaderText: string, homePageBodyText: string): void {
    dispatch(
      ResellerActions.UpdateResellerHomePageTextsRequest({
        homePageHeaderText: homePageHeaderText,
        homePageBodyText: homePageBodyText,
        resellerId: reducerState.Reseller.selectedAdminResellerRow?.id,
      } as IUpdateResellerHomePageTextsRequest)
    );
    setIsReferenceDataSectionDialogOpen(false);
  }

  function handleDeleteResellerContact(row: IResellerContactLine): void {
    if (row == undefined || row?.id <= 0) {
      dispatch(SnackbarActions.ShowError(translate(LabelKeys.InvalidResellerContactId)));
      return;
    }

    dispatch(
      ResellerActions.DeleteResellerContactRequest({
        resellerContactId: row.id,
        resellerId: reducerState.Reseller.selectedAdminResellerRow?.id,
        requestFrom: RequestFrom.UserAdmin,
      } as IDeleteResellerContactRequest)
    );
    setIsDeleteResellerContactDialogOpen(false);
  }

  function handleAddResellerContactSave(data: IAddResellerContact): void {
    dispatch(
      ResellerActions.CreateResellerContactRequest({
        resellerContacts: data.resellerContacts,
        resellerId: reducerState.Reseller.selectedAdminResellerRow?.id,
        requestFrom: RequestFrom.UserAdmin,
      } as ICreateResellerContactRequest)
    );
  }

  function handleConfirmEditedRowChanges(data: IResellerContactLine): void {
    dispatch(
      ResellerActions.UpdateResellerContactRequest({
        id: data.id,
        email: data.email,
        isActive: data.isActive,
        resellerId: reducerState.Reseller.selectedAdminResellerRow?.id,
        requestFrom: RequestFrom.UserAdmin,
      } as IUpdateResellerContactRequest)
    );
  }

  function GetPopupWidth(): string {
    if (isMobile) {
      return "90vw";
    } else if (isSm) {
      return "90vw";
    } else if (isMd) {
      return "70vw";
    } else if (isLg) {
      return "40vw";
    } else {
      return "30vw";
    }
  }

  function handleDeleteLogoType() {
    if (reducerState.Reseller.logotypeAsBase64?.length > 0 && reducerState.Reseller.selectedAdminResellerRow?.id > 0) {
      dispatch(
        ResellerActions.DeleteResellerLogoRequest({
          resellerId: reducerState.Reseller.selectedAdminResellerRow?.id,
        } as IDeleteResellerLogoRequest)
      );
    }
  }
  function handleDeleteHomePageImage() {
    if (reducerState.Reseller.homePageImageUrl?.length > 0 && reducerState.Reseller.selectedAdminResellerRow?.id > 0) {
      dispatch(
        ResellerActions.DeleteResellerHomePageImageRequest({
          resellerId: reducerState.Reseller.selectedAdminResellerRow?.id,
        } as IDeleteResellerHomePageImageRequest)
      );
    }
  }

  const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setIsResellerSettingsMenuOpen(!isResellerSettingsMenuOpen);
  };

  return (
    <Grid
      container
      item
      xs={12}
      sm={12}
      md={12}
      lg={12}
      xl={12}
      className={classes.root}
      style={{ backgroundColor: "#e3e1de", paddingTop: "25px" }}
      justifyContent="center"
    >
      {reducerState.Reseller?.isLoading && <ProcessCircle />}
      <Grid container item xs={11} sm={11} md={11} lg={11} xl={11}>
        <Grid container item xs={12} sm={12} md={12} lg={12} xl={12} style={{ textAlign: "left", paddingBottom: 20 }}>
          <Grid item xs={2} sm={1} md={1} lg={1} xl={1} style={{ paddingTop: 10 }}>
            <Tooltip
              arrow
              leaveDelay={100}
              title={
                <span
                  style={{
                    whiteSpace: "pre-line",
                    fontSize: "1rem",
                    fontFamily: "Mulish",
                  }}
                >
                  {translate(LabelKeys.GoBack)}
                </span>
              }
            >
              <IconButton id="goBackToResellerPage" onClick={() => props.onGoBackToResellerPageClicked(false)}>
                <svg width="20" height="20" fill="currentColor" className="bi bi-arrow-left" viewBox="0 0 16 16">
                  <path
                    fillRule="evenodd"
                    d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"
                  />
                </svg>
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid item xs={9} sm={5} md={5} lg={5} xl={5}>
            <Typography variant="h2" style={{ paddingTop: 13 }}>
              {translate(LabelKeys.ResellerDetails)}
            </Typography>
          </Grid>
          <Grid item xs={1} sm={6} md={6} lg={6} xl={6}>
            <Tooltip arrow title={translate(LabelKeys.Settings)} style={{ float: "right" }}>
              <IconButton onClick={handleMenuClick}>
                <svg width="24" height="24" fill="currentColor" className="bi bi-gear" viewBox="0 0 16 16">
                  <path d="M8 4.754a3.246 3.246 0 1 0 0 6.492 3.246 3.246 0 0 0 0-6.492zM5.754 8a2.246 2.246 0 1 1 4.492 0 2.246 2.246 0 0 1-4.492 0z" />
                  <path d="M9.796 1.343c-.527-1.79-3.065-1.79-3.592 0l-.094.319a.873.873 0 0 1-1.255.52l-.292-.16c-1.64-.892-3.433.902-2.54 2.541l.159.292a.873.873 0 0 1-.52 1.255l-.319.094c-1.79.527-1.79 3.065 0 3.592l.319.094a.873.873 0 0 1 .52 1.255l-.16.292c-.892 1.64.901 3.434 2.541 2.54l.292-.159a.873.873 0 0 1 1.255.52l.094.319c.527 1.79 3.065 1.79 3.592 0l.094-.319a.873.873 0 0 1 1.255-.52l.292.16c1.64.893 3.434-.902 2.54-2.541l-.159-.292a.873.873 0 0 1 .52-1.255l.319-.094c1.79-.527 1.79-3.065 0-3.592l-.319-.094a.873.873 0 0 1-.52-1.255l.16-.292c.893-1.64-.902-3.433-2.541-2.54l-.292.159a.873.873 0 0 1-1.255-.52l-.094-.319zm-2.633.283c.246-.835 1.428-.835 1.674 0l.094.319a1.873 1.873 0 0 0 2.693 1.115l.291-.16c.764-.415 1.6.42 1.184 1.185l-.159.292a1.873 1.873 0 0 0 1.116 2.692l.318.094c.835.246.835 1.428 0 1.674l-.319.094a1.873 1.873 0 0 0-1.115 2.693l.16.291c.415.764-.42 1.6-1.185 1.184l-.291-.159a1.873 1.873 0 0 0-2.693 1.116l-.094.318c-.246.835-1.428.835-1.674 0l-.094-.319a1.873 1.873 0 0 0-2.692-1.115l-.292.16c-.764.415-1.6-.42-1.184-1.185l.159-.291A1.873 1.873 0 0 0 1.945 8.93l-.319-.094c-.835-.246-.835-1.428 0-1.674l.319-.094A1.873 1.873 0 0 0 3.06 4.377l-.16-.292c-.415-.764.42-1.6 1.185-1.184l.292.159a1.873 1.873 0 0 0 2.692-1.115l.094-.319z" />
                </svg>
              </IconButton>
            </Tooltip>
            {isResellerSettingsMenuOpen && (
              <Menu
                id="resellerSettingsMenu"
                anchorEl={anchorEl}
                disableScrollLock
                open={true}
                onClose={() => setIsResellerSettingsMenuOpen(false)}
                onBackdropClick={() => setIsResellerSettingsMenuOpen(false)}
              >
                <MenuItem
                  onClick={(e) => {
                    setIsResellerSettingsPopupOpen(true);
                  }}
                >
                  {translate(LabelKeys.ResellerIncomingOrderEmails)}
                </MenuItem>
              </Menu>
            )}
          </Grid>
        </Grid>
        <Paper style={{ width: "100%", borderRadius: 0, marginTop: 20 }}>
          <Grid
            id="ResellerDetailsOverView"
            container
            item
            justifyContent="center"
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            style={{
              paddingTop: 10,
              paddingBottom: 30,
              paddingLeft: isMobile ? 0 : 20,
              textAlign: "left",
              fontFamily: "Mulish",
            }}
          >
            {isMobile && (
              <Grid item xs={12} sm={1} md={1} lg={1} xl={1}>
                <Tooltip
                  leaveDelay={100}
                  arrow
                  title={translate(LabelKeys.Edit)}
                  TransitionComponent={Zoom}
                  placement="left"
                  style={{ float: "right", marginRight: 15 }}
                >
                  <IconButton onClick={() => handleEditResellerDataSection()}>
                    <svg width="20" height="20" fill="currentColor" className="bi bi-pencil" viewBox="0 0 16 16">
                      <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z" />
                    </svg>
                  </IconButton>
                </Tooltip>
              </Grid>
            )}
            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
              <div className={classes.resellerDetailOverviewRow}>
                <b className={classes.titleText}>{translate(LabelKeys.Id)}</b>: {reducerState.Reseller.selectedAdminResellerRow?.id}
              </div>
              <div className={classes.resellerDetailOverviewRow}>
                <b className={classes.titleText}>{translate(LabelKeys.CustomerNumber)}</b>:{" "}
                {reducerState.Reseller.selectedAdminResellerRow?.customerNumber}
              </div>
              <div className={classes.resellerDetailOverviewRow} style={{ display: "flex" }}>
                <b className={classes.titleText}>{translate(LabelKeys.IsActive)}</b>:{" "}
                {reducerState.Reseller.selectedAdminResellerRow?.isActive ? (
                  <div
                    style={{
                      height: 18,
                      width: 18,
                      marginLeft: 10,
                      backgroundColor: "#2eac77",
                      borderRadius: "50%",
                      display: "inlineBlock",
                    }}
                  />
                ) : (
                  <div
                    style={{
                      height: 18,
                      width: 18,
                      marginLeft: 10,
                      backgroundColor: "red",
                      borderRadius: "50%",
                      display: "inlineBlock",
                    }}
                  />
                )}
              </div>
              <div className={classes.resellerDetailOverviewRow}>
                <b className={classes.titleText}>{translate(LabelKeys.CompanyName)}</b>:{" "}
                {reducerState.Reseller.selectedAdminResellerRow?.companyName}
              </div>
            </Grid>
            <Grid item xs={12} sm={5} md={5} lg={5} xl={5}>
              <div className={classes.resellerDetailOverviewRow}>
                <b className={classes.titleText}>{translate(LabelKeys.Code)}</b>: {reducerState.Reseller.selectedAdminResellerRow?.code}
              </div>
              <div className={classes.resellerDetailOverviewRow}>
                <b className={classes.titleText}>{translate(LabelKeys.Discount)}</b>:{" "}
                {reducerState.Reseller.selectedAdminResellerRow?.discount}
              </div>
              <div style={{ cursor: "pointer" }}>
                <div
                  onMouseEnter={() => setClicked(false)}
                  className={classes.resellerDetailOverviewRow}
                  onClick={() => {
                    navigator.clipboard.writeText(reducerState.Reseller.selectedAdminResellerRow?.url);
                    setClicked(true);
                  }}
                  style={{ position: "relative" }}
                >
                  <Tooltip
                    leaveDelay={100}
                    arrow
                    title={
                      <div style={{ display: "flex" }}>
                        {!clicked ? (
                          <svg
                            width="20"
                            height="20"
                            fill="currentColor"
                            className="bi bi-clipboard"
                            viewBox="0 0 16 16"
                            style={{ paddingRight: 10 }}
                          >
                            <path d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1v-1z" />
                            <path d="M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h3zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3z" />
                          </svg>
                        ) : (
                          <svg
                            width="20"
                            height="20"
                            fill="currentColor"
                            className="bi bi-clipboard-check"
                            viewBox="0 0 16 16"
                            style={{ paddingRight: 10 }}
                          >
                            <path
                              fillRule="evenodd"
                              d="M10.854 7.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7.5 9.793l2.646-2.647a.5.5 0 0 1 .708 0z"
                            />
                            <path d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1v-1z" />
                            <path d="M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h3zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3z" />
                          </svg>
                        )}
                        <Typography style={{ color: "#fff" }}>
                          {!clicked ? translate(LabelKeys.CopyToClipboard) : translate(LabelKeys.Copied)}
                        </Typography>
                      </div>
                    }
                    TransitionComponent={Zoom}
                    placement="top"
                    style={{ position: "absolute" }}
                  >
                    <div style={{ wordWrap: "break-word" }}>
                      <b className={classes.titleText}>{translate(LabelKeys.Url)}</b>: {reducerState.Reseller.selectedAdminResellerRow?.url}
                    </div>
                  </Tooltip>
                </div>
              </div>
            </Grid>
            {!isMobile && (
              <Grid item xs={12} sm={1} md={1} lg={1} xl={1}>
                <Tooltip
                  leaveDelay={100}
                  arrow
                  title={translate(LabelKeys.Edit)}
                  TransitionComponent={Zoom}
                  placement="left"
                  style={{ float: "right", marginRight: 15 }}
                >
                  <IconButton onClick={() => handleEditResellerDataSection()}>
                    <svg width="20" height="20" fill="currentColor" className="bi bi-pencil" viewBox="0 0 16 16">
                      <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z" />
                    </svg>
                  </IconButton>
                </Tooltip>
              </Grid>
            )}
          </Grid>
        </Paper>
        <Grid container item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Grid container item xs={12} sm={6} md={6} lg={6} xl={6}>
            <Paper style={{ width: "100%", borderRadius: 0, marginTop: 20, marginRight: isMobile ? 0 : 10 }}>
              <Grid
                id="resellerDetailsReferenceData"
                container
                item
                justifyContent="center"
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                style={{
                  paddingTop: 10,
                  paddingBottom: 30,
                  paddingLeft: isMobile ? 0 : 20,
                  textAlign: "left",
                  fontFamily: "Mulish",
                }}
              >
                {isMobile && (
                  <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                    <Tooltip
                      leaveDelay={100}
                      arrow
                      title={translate(LabelKeys.Edit)}
                      TransitionComponent={Zoom}
                      placement="left"
                      style={{ float: "right", marginRight: 15 }}
                    >
                      <IconButton onClick={() => handleEditLogoUrlSection()}>
                        <svg width="20" height="20" fill="currentColor" className="bi bi-pencil" viewBox="0 0 16 16">
                          <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z" />
                        </svg>
                      </IconButton>
                    </Tooltip>
                  </Grid>
                )}
                <Grid container item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                    <div className={classes.resellerDetailOverviewRow}>
                      <b className={classes.titleText}>{translate(LabelKeys.Logotype)}</b>:
                    </div>
                    <div className={classes.resellerDetailOverviewRow}>
                      <img width={isMobile ? "100%" : 150} src={reducerState.Reseller.logotypeAsBase64 ?? ""} />
                    </div>
                  </Grid>
                  {!isMobile && (
                    <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                      <Tooltip
                        leaveDelay={100}
                        arrow
                        title={translate(LabelKeys.Edit)}
                        TransitionComponent={Zoom}
                        placement="left"
                        style={{ float: "right", marginRight: 15 }}
                      >
                        <IconButton onClick={() => handleEditLogoUrlSection()}>
                          <svg width="20" height="20" fill="currentColor" className="bi bi-pencil" viewBox="0 0 16 16">
                            <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z" />
                          </svg>
                        </IconButton>
                      </Tooltip>
                    </Grid>
                  )}
                  <Grid item xs={12} sm={6} md={6} lg={6} xl={6} style={{ marginTop: isMobile ? 40 : 0 }}>
                    <div className={classes.resellerDetailOverviewRow}>
                      <b className={classes.titleText}>{translate(LabelKeys.HomePage)}</b>:
                    </div>
                    <div className={classes.resellerDetailOverviewRow}>
                      <img
                        width={isMobile ? "100%" : 320}
                        style={{ paddingRight: isMobile ? 10 : 0 }}
                        src={reducerState.Reseller.homePageImageUrl ?? ""}
                      />
                    </div>
                  </Grid>
                  <Grid container item xs={12} sm={12} md={12} lg={12} xl={12} style={{ marginTop: 20 }}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <b className={classes.titleText}>{translate(LabelKeys.HomePageHeaderText)}</b>
                      <Typography variant="body1" style={{ paddingLeft: isMobile ? 10 : 0 }}>
                        {reducerState.Reseller.homePageHeaderText ?? "-"}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ marginTop: 20 }}>
                      <b className={classes.titleText}>{translate(LabelKeys.HomePageBodyText)}</b>
                      <Typography variant="body1" style={{ paddingLeft: isMobile ? 10 : 0 }}>
                        {reducerState.Reseller.homePageBodyText ?? "-"}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          <Grid container item xs={12} sm={6} md={6} lg={6} xl={6}>
            <Paper style={{ width: "100%", borderRadius: 0, marginTop: 20, marginLeft: isMobile ? 0 : 10 }}>
              <Grid
                id="resellerDetailsContactPageInformation"
                container
                item
                justifyContent="center"
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                style={{
                  paddingTop: 10,
                  paddingBottom: 30,
                  paddingLeft: isMobile ? 0 : 20,
                  textAlign: "left",
                  fontFamily: "Mulish",
                }}
              >
                {isMobile && (
                  <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                    <Tooltip
                      leaveDelay={100}
                      arrow
                      title={translate(LabelKeys.Edit)}
                      TransitionComponent={Zoom}
                      placement="left"
                      style={{ float: "right", marginRight: 15 }}
                    >
                      <IconButton onClick={() => handleContactPageEdit()}>
                        <svg width="20" height="20" fill="currentColor" className="bi bi-pencil" viewBox="0 0 16 16">
                          <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z" />
                        </svg>
                      </IconButton>
                    </Tooltip>
                  </Grid>
                )}
                <Grid container item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                    <div className={classes.resellerDetailOverviewRow}>
                      <b className={classes.titleText}>{translate(LabelKeys.AddressLine)}</b>:{" "}
                      {reducerState.Reseller.resellerContactPageInformation?.addressLine1}
                    </div>
                    <div className={classes.resellerDetailOverviewRow}>
                      <b className={classes.titleText}>{translate(LabelKeys.PostalCode)}</b>:{" "}
                      {reducerState.Reseller.resellerContactPageInformation?.postalCode}
                    </div>
                    <div className={classes.resellerDetailOverviewRow}>
                      <b className={classes.titleText}>{translate(LabelKeys.City)}</b>:{" "}
                      {reducerState.Reseller.resellerContactPageInformation?.city}
                    </div>
                    <div className={classes.resellerDetailOverviewRow}>
                      <b className={classes.titleText}>{translate(LabelKeys.Country)}</b>:{" "}
                      {reducerState.Reseller.resellerContactPageInformation?.country}
                    </div>
                    <div className={classes.resellerDetailOverviewRow}>
                      <b className={classes.titleText}>{translate(LabelKeys.Website)}</b>:{" "}
                      {reducerState.Reseller.resellerContactPageInformation?.website}
                    </div>
                    <div className={classes.resellerDetailOverviewRow}>
                      <b className={classes.titleText}>{translate(LabelKeys.Email)}</b>:{" "}
                      {reducerState.Reseller.resellerContactPageInformation?.contactEmails?.join(", ")}
                    </div>
                    <div className={classes.resellerDetailOverviewRow}>
                      <b className={classes.titleText}>{translate(LabelKeys.PhoneNumber)}</b>:{" "}
                      {reducerState.Reseller.resellerContactPageInformation?.contactPhoneNumbers?.join(", ")}
                    </div>
                  </Grid>
                  {!isMobile && (
                    <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                      <Tooltip
                        leaveDelay={100}
                        arrow
                        title={translate(LabelKeys.Edit)}
                        TransitionComponent={Zoom}
                        placement="left"
                        style={{ float: "right", marginRight: 15 }}
                      >
                        <IconButton onClick={() => handleContactPageEdit()}>
                          <svg width="20" height="20" fill="currentColor" className="bi bi-pencil" viewBox="0 0 16 16">
                            <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z" />
                          </svg>
                        </IconButton>
                      </Tooltip>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Typography align="left" variant="h3" style={{ paddingTop: 30 }}>
            {translate(LabelKeys.Contacts)}
          </Typography>
        </Grid>
        <Grid
          id="resellerContactLines"
          container
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          style={{ paddingTop: 10, paddingBottom: 30, textAlign: "left" }}
        >
          <EditableTable
            columns={searchResellerContactsHeadCells}
            rows={reducerState.Reseller.selectedAdminResellerRow.resellerContacts}
            enableToolbar
            enableAddMode
            enableInlineEditMode
            enableInlineDeleteMode
            toolbarAddTooltipText={translate(LabelKeys.AddResellerContact)}
            onToolbarAddClicked={() => setIsAddResellerContactDialogOpen(true)}
            onInlineRowDeleteClicked={(row: IResellerContactLine) => handleOpenConfirmDeleteResellerContact(row)}
            editableColumns={[FieldNames.email, FieldNames.isActive]}
            onInlineConfirmRowChangesClicked={(row) => handleConfirmEditedRowChanges(row as IResellerContactLine)}
            totalItems={reducerState.Reseller.adminResellerRows?.length}
          />
        </Grid>
      </Grid>
      {isDataSectionDialogOpen && (
        <Popup
          onEscapeKeyDown={() => {
            setIsDataSectionDialogOpen(false);
          }}
          width={GetPopupWidth()}
          content={
            <EditResellerData
              key="editResellerData"
              onSave={(data: IEditResellerDataData) => handleEditResellerDataSave(data)}
              onClose={() => setIsDataSectionDialogOpen(false)}
            />
          }
          onClose={() => setIsDataSectionDialogOpen(false)}
          enableCloseIcon={true}
          dialogTitle={translate(LabelKeys.EditResellerData)}
        />
      )}
      {isReferenceDataDialogOpen && (
        <Popup
          onEscapeKeyDown={() => {
            setIsReferenceDataSectionDialogOpen(false);
          }}
          width={GetPopupWidth()}
          content={
            <EditResellerReferenceData
              key={"editResellerReferenceData"}
              onDeleteHomePageImage={() => handleDeleteHomePageImage()}
              onDeleteLogotype={() => handleDeleteLogoType()}
              onSaveLogotype={(data: IUploadedFile) => handleSaveLogotypeData(data)}
              onSaveHomePageImage={(data: IUploadedFile) => handleSaveHomePageImageData(data)}
              onSaveHomePageTexts={(homePageHeader: string, homePageBody: string) => handleSaveHomePageTexts(homePageHeader, homePageBody)}
              onClose={() => setIsReferenceDataSectionDialogOpen(false)}
            />
          }
          onClose={() => setIsReferenceDataSectionDialogOpen(false)}
          enableCloseIcon={true}
        />
      )}
      {isContactPageInformationOpen && (
        <Popup
          onEscapeKeyDown={() => {
            setIsContactPageInformationOpen(false);
          }}
          width={GetPopupWidth()}
          content={
            <EditResellerContactPageData
              key="editResellerContactPageData"
              onSave={(data: IEditResellerContactPageInformationData) => handleEditResellerContactPageDataSave(data)}
              onClose={() => setIsContactPageInformationOpen(false)}
            />
          }
          onClose={() => setIsContactPageInformationOpen(false)}
          enableCloseIcon={true}
          dialogTitle={translate(LabelKeys.EditResellerContactPageData)}
        />
      )}
      {isAddResellerContactDialogOpen && (
        <Popup
          onEscapeKeyDown={() => {
            setIsAddResellerContactDialogOpen(false);
          }}
          width={GetPopupWidth()}
          content={
            <AddResellerContact
              key={"addResellerContact"}
              onSave={(data: IAddResellerContact) => handleAddResellerContactSave(data)}
              onClose={() => setIsAddResellerContactDialogOpen(false)}
            />
          }
          onClose={() => setIsAddResellerContactDialogOpen(false)}
          enableCloseIcon={true}
          dialogTitle={translate(LabelKeys.AddResellerContact)}
        />
      )}
      {isDeleteResellerContactDialogOpen && (
        <Popup
          onEscapeKeyDown={() => {
            setIsDeleteResellerContactDialogOpen(false);
          }}
          width={GetPopupWidth()}
          content={
            <DeleteResellerContact
              key={"deleteResellerContact"}
              resellerContact={resellerContactLineToDelete}
              onSave={(data: IResellerContactLine) => handleDeleteResellerContact(data)}
              onClose={() => setIsDeleteResellerContactDialogOpen(false)}
            />
          }
          onClose={() => setIsDeleteResellerContactDialogOpen(false)}
          enableCloseIcon={true}
          dialogTitle={translate(LabelKeys.DeleteResellerContact)}
        />
      )}
      {isResellerSettingsPopupOpen && (
        <Popup
          onEscapeKeyDown={() => {
            setIsResellerSettingsMenuOpen(false);
            setIsResellerSettingsPopupOpen(false);
          }}
          width={GetPopupWidth()}
          content={
            <ResellerSettings
              key={"resellerSettings"}
              onClose={() => {
                setIsResellerSettingsMenuOpen(false);
                setIsResellerSettingsPopupOpen(false);
              }}
            />
          }
          onClose={() => {
            setIsResellerSettingsMenuOpen(false);
            setIsResellerSettingsPopupOpen(false);
          }}
          enableCloseIcon={true}
          dialogTitle={translate(LabelKeys.ResellerIncomingOrderEmails)}
        />
      )}
    </Grid>
  );
}
