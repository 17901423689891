import { CookieConstants } from "./Labels/types";
import { AuthenticationActions } from "./../Modules/Authentication/actions";
import Cookies from "js-cookie";
import store from "../store";
import SnackbarActions from "../components/Snackbar/actions";
import { GetCurrentLanguageAsText, UserSession } from "./types";
import { LocalStorageKeys } from "./history/history";
import jwt_decode from "jwt-decode";
import { SystemPart } from "./enums/enums";

// Service to check authentication for user and to signOut
export function SignOut() {
  store.dispatch(AuthenticationActions.SignOutResellerRequest());
  store.dispatch(AuthenticationActions.SignOutRequest());
  Cookies.remove(CookieConstants.GBVJwt);
  Cookies.remove(CookieConstants.GBVRefreshToken);
  localStorage.removeItem(LocalStorageKeys.userSession);

  store.dispatch(SnackbarActions.ShowInfo("You have been signed out!"));
}

export function IsAuth() {
  const jwt = Cookies.get(CookieConstants.GBVJwt);
  if (jwt == undefined) return false;

  const decodedJwt = jwt_decode(jwt) as any;
  if (decodedJwt == undefined) return false;

  if (decodedJwt?.AdminUserId > 0) {
    return true;
  } else {
    return false;
  }
}

export function IsResellerAuth() {
  const jwt = Cookies.get(CookieConstants.GBVJwt);
  if (jwt == undefined) return false;

  const decodedJwt = jwt_decode(jwt) as any;
  if (decodedJwt == undefined) return false;

  if (decodedJwt?.ResellerContactId > 0) {
    return true;
  } else {
    return false;
  }
}

export function RefreshToken() {
  return Cookies.get(CookieConstants.GBVRefreshToken);
}

export default function SetUserSession(userSession: UserSession) {
  localStorage.setItem(LocalStorageKeys.userSession, JSON.stringify(userSession));
}

export function CheckSystemPartCookie() {
  let voucherPage = location.pathname.includes(`/${GetCurrentLanguageAsText()}/voucher`);
  let isSignInPage = location.pathname.includes(`/signin`);
  let isAdminPage = location.pathname.includes(`/admin`);

  if (
    (Cookies.get(CookieConstants.SystemPart)?.length <= 0 || Cookies.get(CookieConstants.SystemPart) === undefined) &&
    Cookies.get(CookieConstants.SystemPart) != SystemPart.Company.toString() &&
    Cookies.get(CookieConstants.GBVReseller) == undefined &&
    !voucherPage &&
    !isSignInPage &&
    !isAdminPage
  ) {
    store.dispatch(AuthenticationActions.SetIsSystemPartDialogOpenRequest(true));
  }
}
