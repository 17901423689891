import React from "react";
import { Grid, useMediaQuery } from "@mui/material";
import { PhysicalCardPreviewStyles } from "./styles";
import clsx from "clsx";
import { GetLitiumImageUrl } from "../../Utils/extensions";
import { theme } from "../../appTheme";

export interface IPhysicalCardPreviewProps {
  physicalCardSelectedCoverImage: string;
  physicalCardUploadedCoverImage: string;
  physicalCardMiddlePageImage: string;
  className?: string;
}

export default function PhysicalCardPreview(props: IPhysicalCardPreviewProps) {
  const classes = PhysicalCardPreviewStyles();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isSm = useMediaQuery(theme.breakpoints.only("sm"));
  const isMd = useMediaQuery(theme.breakpoints.only("md"));

  return (
    <Grid
      id="physicalCardPreview"
      container
      item
      className={clsx(classes.previewContainer, props.className)}
      xs={12}
      sm={12}
      md={12}
      lg={11}
      xl={11}
      style={{ zoom: isMobile || isSm || isMd ? 0.6 : 0.8, background: "#e3e1de" }}
    >
      <div className={classes.root}>
        <div className={classes.cardFrontContainer}>
          <img
            className={classes.coverImage}
            src={
              props.physicalCardSelectedCoverImage != undefined
                ? `${props.physicalCardSelectedCoverImage}?maxWidth=450`
                : props.physicalCardUploadedCoverImage
            }
          />
        </div>
        <div className={classes.cardInsideContainer}>
          <img className={classes.insideFrontPageImage} src={`${GetLitiumImageUrl(props.physicalCardMiddlePageImage, "")}?maxWidth=450`} />
        </div>
      </div>
    </Grid>
  );
}
