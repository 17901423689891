import { StateNames } from "./../../Utils/types";
import { IOrderDetailsRow, IOrderRow } from "./../../views/Admin/types";
import { OrderActionTypes } from "./actionTypes";
import { Reducer } from "redux";
import { SendBy, WhenToSend } from "../../components/Delivery/types";
import { IFilterOption } from "../../components/DropDown/types";
import { IUploadedFile } from "../../components/FileUploader/types";
import { PaymentMethod } from "../../Utils/enums/enums";
import { GetDateAndTime } from "../../Utils/extensions";
import {
  IEnvironmentState,
  IStepperState,
  IStepperOneState,
  IStepperTwoState,
  IStepperThreeState,
  IStepperFourState,
  IStepperFiveState,
  IStepperSixState,
} from "../../views/Order/types";
import { IProduct, IImageOption, ProductCategory } from "../Products/types";
import { IGetOrderConfirmationDetailsResponse } from "./types";
import { IErrorStep } from "../../components/Stepper/CSStepper";

interface IOrderInitState {
  isLoading: boolean;
  internalOrderId: string;
  redirectUrl: string;
  discount: number;
  resellerCompanyName: string;
  validationCodeIsValid: boolean;
  adminOrderRows: IOrderRow[];
  adminOrderRowsTotalItems: number;
  adminSelectedOrder: IOrderDetailsRow;
  environmentState: IEnvironmentState;
  stepperState: IStepperState;
  stepperOneState: IStepperOneState;
  stepperTwoState: IStepperTwoState;
  stepperThreeState: IStepperThreeState;
  stepperFourState: IStepperFourState;
  stepperFiveState: IStepperFiveState;
  stepperSixState: IStepperSixState;
  orderConfirmationDetails: IGetOrderConfirmationDetailsResponse;
}

const orderInitState: IOrderInitState = {
  isLoading: false,
  internalOrderId: "",
  redirectUrl: "",
  discount: 0.0,
  resellerCompanyName: "",
  validationCodeIsValid: false,
  adminOrderRows: [] as IOrderRow[], //Array of many orders
  adminOrderRowsTotalItems: 0,
  adminSelectedOrder: {} as IOrderDetailsRow,
  environmentState: {} as IEnvironmentState,
  stepperState: {
    activeStep: 0,
    readyToPay: false,
    errorSections: [] as IErrorStep[],
  } as IStepperState,
  stepperOneState: {} as IStepperOneState,
  stepperTwoState: { selectedProduct: {} as IProduct } as IStepperTwoState,
  stepperThreeState: {
    sendBy: SendBy.SMS,
    emailSubject: "",
    smsMessage: "",
    messageSignature: "",
    emailMessage: "",
    emailPDFMessage: "",
    customMessage: false,
    selectedMessageTemplate: {} as IFilterOption,
    messageTemplates: [] as IFilterOption[],
    selectedImage: {} as IImageOption,
    uploadedImage: {} as IUploadedFile,
    acceptedPDFPreview: false,
    openPDF: false,
    customWebPage: false,
    physicalCardSelectedCoverImage: {} as IImageOption,
    physicalCardUploadedCoverImage: {} as IUploadedFile,
    physicalCardUploadedLogoImage: {} as IUploadedFile,
    physicalCardHeading: "",
    physicalCardCustomMessage: "",
  } as IStepperThreeState,
  stepperFourState: {
    companyName: "",
    companyContactPhoneNumber: "",
    organisationNumber: "",
    contactResellerName: "",
    isAutomaticlySet: false,
    senderEmail: "",
    contactEmail: "",
    marking: "",
  } as IStepperFourState,
  stepperFiveState: {
    includeFreight: false,
    amountOfMailings: undefined,
    orderDate: GetDateAndTime(),
    amountOfReceivers: "",
    receiverEmails: "",
    receiverPhoneNumbers: "",
    sendToSameReceiver: false,
    whenToSend: WhenToSend.Now,
    hasMultipleEntries: false,
    addressLine: "",
    postalCode: "",
    city: "",
    country: 1,
  } as IStepperFiveState,
  stepperSixState: {
    paymentMethod: PaymentMethod.Swish,
    acceptedTermsOfUse: false,
  } as IStepperSixState,
  orderConfirmationDetails: {} as IGetOrderConfirmationDetailsResponse,
};

const OrderReducer: Reducer<IOrderInitState> = (state = orderInitState, action) => {
  switch (action.type) {
    case OrderActionTypes.CREATE_PRIVATE_ORDER_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case OrderActionTypes.CREATE_PRIVATE_ORDER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        internalOrderId: action.response.internalOrderId,
        redirectUrl: action.response.redirectUrl,
      };
    case OrderActionTypes.CREATE_PRIVATE_ORDER_ERROR:
      return {
        ...state,
        isLoading: false,
      };
    case OrderActionTypes.CREATE_COMPANY_ORDER_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case OrderActionTypes.CREATE_COMPANY_ORDER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        internalOrderId: action.response.internalOrderId,
        redirectUrl: action.response.redirectUrl,
      };
    case OrderActionTypes.CREATE_COMPANY_ORDER_ERROR:
      return {
        ...state,
        isLoading: false,
      };
    case OrderActionTypes.CREATE_ADMIN_ORDER_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case OrderActionTypes.CREATE_ADMIN_ORDER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        internalOrderId: action.response.internalOrderId,
        redirectUrl: action.response.redirectUrl,
      };
    case OrderActionTypes.CREATE_ADMIN_ORDER_ERROR:
      return {
        ...state,
        isLoading: false,
      };
    case OrderActionTypes.RESEND_ORDERS_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case OrderActionTypes.RESEND_ORDERS_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case OrderActionTypes.RESEND_ORDERS_ERROR:
      return {
        ...state,
        isLoading: false,
      };
    case OrderActionTypes.RESEND_RECEIPTS_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case OrderActionTypes.RESEND_RECEIPTS_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case OrderActionTypes.RESEND_RECEIPTS_ERROR:
      return {
        ...state,
        isLoading: false,
      };
    case OrderActionTypes.SEARCH_SINGLE_ORDER_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case OrderActionTypes.SEARCH_SINGLE_ORDER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        adminSelectedOrder: action.response,
      };
    case OrderActionTypes.SEARCH_SINGLE_ORDER_ERROR:
      return {
        ...state,
        isLoading: false,
      };
    case OrderActionTypes.SEARCH_ORDERS_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case OrderActionTypes.SEARCH_ORDERS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        adminOrderRows: action.response.orders,
        adminOrderRowsTotalItems: action.response.totalItems,
      };
    case OrderActionTypes.SEARCH_ORDERS_ERROR:
      return {
        ...state,
        isLoading: false,
      };
    case OrderActionTypes.UPDATE_ORDERLINE_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case OrderActionTypes.UPDATE_ORDERLINE_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case OrderActionTypes.UPDATE_ORDERLINE_ERROR:
      return {
        ...state,
        isLoading: false,
      };
    case OrderActionTypes.RESEND_ORDERLINE_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case OrderActionTypes.RESEND_ORDERLINE_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case OrderActionTypes.RESEND_ORDERLINE_ERROR:
      return {
        ...state,
        isLoading: false,
      };

    case OrderActionTypes.SET_ENVIRONMENT_STATE_REQUEST:
      return {
        ...state,
        [StateNames.environmentState]: {
          ...state[StateNames.environmentState],
          [action.request.fieldName]: action.request.value,
        },
      };
    case OrderActionTypes.SET_STEPPER_STATE_REQUEST:
      return {
        ...state,
        [StateNames.stepperState]: {
          ...state[StateNames.stepperState],
          [action.request.fieldName]: action.request.value,
        },
      };
    case OrderActionTypes.SET_STEP_ONE_STATE_REQUEST:
      return {
        ...state,
        [StateNames.stepperOneState]: {
          ...state[StateNames.stepperOneState],
          [action.request.fieldName]: action.request.value,
        },
      };
    case OrderActionTypes.SET_STEP_TWO_STATE_REQUEST:
      return {
        ...state,
        [StateNames.stepperTwoState]: {
          ...state[StateNames.stepperTwoState],
          [action.request.fieldName]: action.request.value,
        },
      };
    case OrderActionTypes.SET_STEP_THREE_STATE_REQUEST:
      return {
        ...state,
        [StateNames.stepperThreeState]: {
          ...state[StateNames.stepperThreeState],
          [action.request.fieldName]: action.request.value,
        },
      };
    case OrderActionTypes.SET_STEP_FOUR_STATE_REQUEST:
      return {
        ...state,
        [StateNames.stepperFourState]: {
          ...state[StateNames.stepperFourState],
          [action.request.fieldName]: action.request.value,
        },
      };
    case OrderActionTypes.SET_STEP_FIVE_STATE_REQUEST:
      return {
        ...state,
        [StateNames.stepperFiveState]: {
          ...state[StateNames.stepperFiveState],
          [action.request.fieldName]: action.request.value,
        },
      };
    case OrderActionTypes.SET_STEP_SIX_STATE_REQUEST:
      return {
        ...state,
        [StateNames.stepperSixState]: {
          ...state[StateNames.stepperSixState],
          [action.request.fieldName]: action.request.value,
        },
      };
    case OrderActionTypes.GET_ORDER_CONFIRMATION_DETAILS_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case OrderActionTypes.GET_ORDER_CONFIRMATION_DETAILS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        orderConfirmationDetails: action.response,
      };
    case OrderActionTypes.GET_ORDER_CONFIRMATION_DETAILS_ERROR:
      return {
        ...state,
        isLoading: false,
      };
    case OrderActionTypes.MARK_ORDER_AS_DONE_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case OrderActionTypes.MARK_ORDER_AS_DONE_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case OrderActionTypes.MARK_ORDER_AS_DONE_ERROR:
      return {
        ...state,
        isLoading: false,
      };
    case OrderActionTypes.RESET_REDUCER:
      return (state = orderInitState);
    case OrderActionTypes.RESET_STEP_ONE_STATE:
      return {
        ...state,
        stepperOneState: orderInitState.stepperOneState,
      };
    case OrderActionTypes.RESET_STEP_TWO_STATE:
      return {
        ...state,
        stepperTwoState: orderInitState.stepperTwoState,
      };
    case OrderActionTypes.RESET_STEP_THREE_STATE:
      return {
        ...state,
        stepperThreeState: orderInitState.stepperThreeState,
      };
    case OrderActionTypes.RESET_STEP_FOUR_STATE:
      return {
        ...state,
        stepperFourState: orderInitState.stepperFourState,
      };
    case OrderActionTypes.RESET_STEP_FIVE_STATE:
      return {
        ...state,
        stepperFiveState: orderInitState.stepperFiveState,
      };
    case OrderActionTypes.RESET_STEP_SIX_STATE:
      return {
        ...state,
        stepperSixState: orderInitState.stepperSixState,
      };
    case OrderActionTypes.RESET_STEPPER_STATE:
      return {
        ...state,
        stepperState: orderInitState.stepperState,
      };
    default:
      return state;
    case OrderActionTypes.RESEND_ORDER_TO_NAV_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case OrderActionTypes.RESEND_ORDER_TO_NAV_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case OrderActionTypes.RESEND_ORDER_TO_NAV_ERROR:
      return {
        ...state,
        isLoading: false,
      };
    case OrderActionTypes.RESEND_GIFT_CARDS_TO_PRINTER_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case OrderActionTypes.RESEND_GIFT_CARDS_TO_PRINTER_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case OrderActionTypes.RESEND_GIFT_CARDS_TO_PRINTER_ERROR:
      return {
        ...state,
        isLoading: false,
      };
  }
};

export default OrderReducer;
