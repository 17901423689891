import { LabelKeys } from "./../../Utils/enums/labelKeys";
import { FileType } from "../../components/FileUploader/types";
import { Currency, LanguageCode, PhysicalCardImage, SystemPart } from "./../../Utils/enums/enums";
import { TemplateType as TemplateType } from "../../components/Message/types";

export interface IGetProductsRequest {
  language: LanguageCode;
  systemPart: SystemPart;
  productCategoryId: ProductCategory;
}

export interface IGetProductsResponse {
  products: IProduct[];
}

export interface ISetSelectedProduct {
  product: IProduct;
}

export interface ISetSelectedProductCategory {
  productCategory: IProductCategory;
}

export interface IGetProductByIdRequest {
  productId: number;
  systemPart: SystemPart;
}

export interface IGetProductByIdResponse {
  product: IProduct;
}

export interface IProduct {
  id: number;
  name: string;
  priceIncludingVat: number;
  description: string;
  currency: Currency;
  language: LanguageCode;
  category: ProductCategory;
  imageUrl: string;
  isMostPopular: boolean;
  priceExcludingVat: number;
  physicalCardInsideImageId: string;
  physicalCardBackgroundImageId: string;
}

export enum ProductCategory {
  Unknown = 0,
  CustomSMSMessageFee = 1,
  CustomEmailMessageFee = 2,
  GiftCardVH = 3,
  DigitalProduct = 4,
  Freight = 5,
  GiftCardGMG = 6,
  CustomWebPage = 7,
}

export interface ITag {
  id: number;
  name: string;
}

export interface IImageOption {
  url: string;
  isStandard?: boolean;
  gbvImageTags?: string[];
  imageId?: string;
  giftCardCoverCategories?: string[];
}

export interface IGetPreviewPDFRequest {
  message: string; //Includes HTML
  emailHeaderImageId?: string;
  customEmailHeaderImageFile?: string;
  customEmailHeaderImageFileType?: FileType;
  productId?: number;
  language: LanguageCode;
}

export interface IGetMessageTemplatesResponse {
  messageTemplates: IMessageTemplate[];
}

export interface IMessageTemplate {
  id: number;
  name: string;
  message: string;
  isStandard: boolean;
  type: TemplateType;
}

export interface ICreateMessageTemplateRequest {
  name: string;
  message: string;
  isStandard: boolean;
  type: TemplateType;
}

export interface IUpdateMessageTemplateRequest {
  id: number;
  title: string;
  message: string;
  isStandard: boolean;
  type: TemplateType;
}

export interface IDeleteMessageTemplateRequest {
  id: number;
}

export interface IGetVoucherRequest {
  claimCode: string;
}

export interface IGetVoucherResponse {
  claimCode: string;
  expireDate: Date;
  imageBase64: string;
  imageUrl: string;
  message: string;
  voucherUrl: string;
}

export interface IVoucher {
  message: string;
  claimCode: string;
  voucherUrl: string;
  expireDate: Date;
  imageBase64: string;
  imageUrl: string;
}

export interface IGetProductCategoriesResponse {
  productCategories: IProductCategory;
}

export interface IProductCategory {
  id: number;
  imageUrl: string;
  labelKeyDescription: LabelKeys;
  labelKeyName: LabelKeys;
  category: ProductCategory;
}

export interface IGetLitiumImagesByFolderIdRequest {
  folderId: string;
}
export interface IGetLitiumImagesByFolderIdResponse {}
export interface IGetLitiumImageBySystemIdRequest {
  productId: number;
  pysicalCardImage: PhysicalCardImage;
}
export interface IGetLitiumImageBySystemIdResponse {
  url: string;
}

export interface IGetMessageTemplatesRequest {
  templateTypes?: TemplateType[];
}

export interface IPhysicalCardImage {
  imageId: string;
  url: string;
  isStandard: boolean;
  gbvImageTags: string[];
  giftCardCoverCategories: string[];
}
