import React, { useState } from "react";
import { Grid, Button, useMediaQuery } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import CSCheckbox from "../../../../../components/Input/Forms/CSCheckbox";
import CSTextField from "../../../../../components/Input/Textfield/CSTextField";
import { RootState } from "../../../../../store";
import { LabelKeys } from "../../../../../Utils/enums/labelKeys";
import { FieldNames } from "../../../../../Utils/types";
import { ResellerStyles } from "../../../styles";
import { IEditResellerDataData, IEditResellerDataErrorState, IEditResellerDataState } from "../../../types";
import { IsLongerThanOrEqualToLength } from "../../../../../Utils/validations";
import { ValidateCustomerNumber } from "../../../../../Utils/extensions";
import { theme } from "../../../../../appTheme";

interface IEditResellerData {
  onSave: (data: IEditResellerDataData) => void;
  onClose: () => void;
}

export default function EditResellerData(props: IEditResellerData) {
  const { t: translate } = useTranslation();
  const isMobile = useMediaQuery(theme.breakpoints.only("xs"));
  const classes = ResellerStyles();
  const reducerState = useSelector(
    (s: RootState) => {
      return {
        Reseller: s.reseller,
      };
    },
    (prev, next) =>
      prev.Reseller.isLoading === next.Reseller.isLoading &&
      prev.Reseller.selectedAdminResellerRow === next.Reseller.selectedAdminResellerRow
  );

  const [editResellerDataState, setEditResellerDataState] = useState({
    id: reducerState.Reseller.selectedAdminResellerRow?.id,
    customerNumber: reducerState.Reseller.selectedAdminResellerRow?.customerNumber,
    isActive: reducerState.Reseller.selectedAdminResellerRow?.isActive,
    companyName: reducerState.Reseller.selectedAdminResellerRow?.companyName,
    discount: reducerState.Reseller.selectedAdminResellerRow?.discount,
  } as IEditResellerDataState);

  const [editResellerDataErrorState, setEditResellerDataErrorState] = useState({
    companyName: false,
    customerNumber: false,
  } as IEditResellerDataErrorState);

  function handleFieldOnChange(fieldName: FieldNames, value: boolean | string | number) {
    setEditResellerDataState((state) => ({
      ...state,
      [fieldName]: value,
    }));
  }

  function handleFieldErrorOnChange(fieldName: FieldNames, value: boolean) {
    setEditResellerDataErrorState((state) => ({
      ...state,
      [fieldName]: value,
    }));
  }

  function handleSaveData() {
    const valid = ValidateAllFields();
    if (valid) {
      props.onSave({
        id: editResellerDataState.id,
        companyName: editResellerDataState.companyName,
        customerNumber: editResellerDataState.customerNumber,
        discount: editResellerDataState.discount,
        isActive: editResellerDataState.isActive,
      } as IEditResellerDataData);
      props.onClose();
    }
  }

  function ValidateAllFields() {
    let valid: boolean = true;
    if (!ValidateCustomerNumber(editResellerDataState.customerNumber)) {
      handleFieldErrorOnChange(FieldNames.customerNumber, true);
      valid = false;
    } else {
      handleFieldErrorOnChange(FieldNames.customerNumber, false);
    }

    if (!IsLongerThanOrEqualToLength(editResellerDataState.companyName, 1)) {
      handleFieldErrorOnChange(FieldNames.companyName, true);
      valid = false;
    } else {
      handleFieldErrorOnChange(FieldNames.companyName, false);
    }

    return valid;
  }

  return (
    <Grid
      key={reducerState.Reseller.selectedAdminResellerRow?.id.toString()}
      container
      item
      xs={12}
      sm={12}
      md={12}
      lg={12}
      xl={12}
      style={{ maxHeight: 650 }}
    >
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ paddingTop: 30 }}>
        <CSTextField
          className={classes.textfield}
          label={translate(LabelKeys.CustomerNumber)}
          placeholder={`${translate(LabelKeys.CustomerNumber)}`}
          type="text"
          value={editResellerDataState.customerNumber ?? ""}
          onChange={(e) => {
            handleFieldOnChange(FieldNames.customerNumber, e.target.value);
          }}
          error={editResellerDataErrorState.customerNumber}
          helperText={translate(LabelKeys.InvalidCustomerNumber)}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ paddingTop: 20 }}>
        <CSCheckbox
          label={translate(LabelKeys.IsActive)}
          checked={editResellerDataState.isActive}
          onChange={(e) => {
            handleFieldOnChange(FieldNames.isActive, e.target.checked);
          }}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ paddingTop: 20 }}>
        <CSTextField
          className={classes.textfield}
          label={translate(LabelKeys.CompanyName)}
          placeholder={`${translate(LabelKeys.CompanyNamePlaceholder)}`}
          value={editResellerDataState.companyName ?? ""}
          type="text"
          onChange={(e) => {
            handleFieldOnChange(FieldNames.companyName, e.target.value);
          }}
          error={editResellerDataErrorState.companyName}
          helperText={translate(LabelKeys.InvalidCompanyName)}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ paddingTop: 20 }}>
        <CSTextField
          className={classes.textfield}
          label={translate(LabelKeys.Discount)}
          placeholder={`${translate(LabelKeys.Discount)}`}
          value={editResellerDataState.discount ?? ""}
          type="text"
          onChange={(e) => {
            if (Number(e.target.value) > 100) {
              handleFieldOnChange(FieldNames.discount, editResellerDataState.discount);
              return;
            }

            if (isNaN(Number(e.target.value))) {
              handleFieldOnChange(FieldNames.discount, editResellerDataState.discount);
              return;
            }

            handleFieldOnChange(FieldNames.discount, e.target.value);
          }}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={6} lg={6} xl={6} style={{ paddingTop: 31, paddingRight: isMobile ? 0 : 8 }}>
        <Button
          id="saveBtn"
          style={{
            height: 46,
            width: "100%",
            cursor: "pointer",
            color: "#fff",
            borderRadius: 0,
            backgroundColor: "#000",
          }}
          onClick={() => handleSaveData()}
        >
          <div style={{ margin: "auto", textTransform: "none" }}>{`${translate(LabelKeys.Save)}`}</div>
        </Button>
      </Grid>
      <Grid item xs={12} sm={6} md={6} lg={6} xl={6} style={{ paddingTop: 31, paddingLeft: isMobile ? 0 : 8 }}>
        <Button
          id="cancelBtn"
          style={{
            height: 46,
            width: "100%",
            cursor: "pointer",
            color: "#fff",
            borderRadius: 0,
            backgroundColor: "#000",
          }}
          onClick={() => props.onClose()}
        >
          <div style={{ margin: "auto", textTransform: "none" }}>{`${translate(LabelKeys.Cancel)}`}</div>
        </Button>
      </Grid>
    </Grid>
  );
}
