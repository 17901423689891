import React, { useEffect, useState } from "react";
import { WarningAmberOutlined } from "@mui/icons-material";
import { Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { LabelKeys } from "../../../Utils/enums/labelKeys";
import { GetValuesAsArray } from "../../../Utils/extensions";
import { FieldNames, StateNames } from "../../../Utils/types";
import CSTextField from "../../Input/Textfield/CSTextField";
import { DeliveryStyles } from "../styles";
import { SystemPart } from "../../../Utils/enums/enums";
import CSInfo from "../../Helper/CSInfo";
import { IsResellerAuth, IsAuth } from "../../../Utils/authentication";

interface ISendBySMSProps {
  systemPart: SystemPart;
  receiverPhoneNumbers: string;
  receiverPhoneNumberHasError: boolean;
  onStateChanged: (
    stateName: StateNames.stepperFiveState,
    value: string | string[] | Date | number | boolean,
    fieldName: FieldNames
  ) => void;
}

export default function SendBySMS(props: ISendBySMSProps) {
  const { t: translate } = useTranslation();
  const classes = DeliveryStyles();
  const [tooltipDialogIsOpen, setTooltipDialogIsOpen] = useState(false);
  const [hasMultipleEntries, setHasMultipleEntries] = useState(false);
  const [amountOfReceivers, setAmountOfReceivers] = useState(0);
  const isAdmin =
    (IsResellerAuth() &&
      location.pathname.includes(
        `/${translate(LabelKeys.ResellerUrl)}/${translate(LabelKeys.AdminUrl)}/${translate(LabelKeys.OrderUrl)}`
      )) ||
    (IsAuth() && location.pathname.includes(`/${translate(LabelKeys.AdminUrl)}/${translate(LabelKeys.OrderUrl)}`));

  useEffect(() => {
    CheckMultipleEntries(props.receiverPhoneNumbers);
    let amount = GetAmountOfReceivers(props.receiverPhoneNumbers);
    setAmountOfReceivers(amount);
  }, [props.receiverPhoneNumbers]);

  function CheckMultipleEntries(value: string): void {
    setHasMultipleEntries(false);
    const values: string[] = [];
    const valueAsArray = GetValuesAsArray(value);

    if (valueAsArray?.length > 0)
      valueAsArray?.forEach((el) => {
        if (values?.find((val) => val === el)) {
          setHasMultipleEntries(true);
          return;
        } else {
          values.push(el);
        }
      });
  }

  function GetAmountOfReceivers(value: string): number {
    let amount = GetValuesAsArray(value)?.length;

    if (amount) {
      return amount;
    } else {
      return 0;
    }
  }

  return (
    <Grid
      id="sendBySMSComponent"
      container
      item
      xs={12}
      sm={12}
      md={12}
      lg={12}
      xl={12}
      className={classes.sendBySMSComponent}
      justifyContent="center"
    >
      <Grid container item xs={12} sm={12} md={12} lg={12} xl={12}>
        <div>
          <Typography align="left" variant="body2" style={{ fontWeight: "bold", paddingTop: 3, width: "auto" }}>
            {`${translate(LabelKeys.Receiver)}*`}
          </Typography>
        </div>
        <div style={{ paddingLeft: 14 }}>
          <CSInfo
            hoverTooltopText={
              props.systemPart == SystemPart.Private && !isAdmin
                ? translate(LabelKeys.DeliverReceiverPhoneNumberTooltipPrivate)
                : translate(LabelKeys.DeliverReceiverPhoneNumberTooltip)
            }
            popupTooltipText={
              props.systemPart == SystemPart.Private && !isAdmin
                ? translate(LabelKeys.DeliverReceiverPhoneNumberTooltipPrivate)
                : translate(LabelKeys.DeliverReceiverPhoneNumberTooltip)
            }
            tooltipDialogIsOpen={tooltipDialogIsOpen}
            onSetTooltipDialogIsOpen={(value) => setTooltipDialogIsOpen(value)}
          />
        </div>
        <Grid id="receiverPhoneNumbersContainer" item xs={12} sm={11} md={11} lg={11} xl={11}>
          <CSTextField
            id="receiverPhoneNumbers"
            multiline={true}
            rows={10}
            type="tel"
            value={props.receiverPhoneNumbers}
            placeholder={`${translate(LabelKeys.ReceiverPhoneNumbers)}`}
            onChange={(e) => {
              let value = e.target.value.replaceAll(" ", "");
              value = value.replaceAll("-", "");
              props.onStateChanged(StateNames.stepperFiveState, value, FieldNames.receiverPhoneNumbers);
            }}
            error={props.receiverPhoneNumberHasError}
            helperText={translate(LabelKeys.InvalidReceiverPhoneNumbers)}
          />
        </Grid>
      </Grid>
      {hasMultipleEntries && (
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ display: "flex", paddingTop: 3 }}>
          <WarningAmberOutlined style={{ color: "#FFA500" }} />
          <Typography
            variant="body2"
            style={{
              paddingLeft: 10,
              paddingTop: 3,
              color: "#FFA500",
              textAlign: "left",
            }}
          >
            {translate(LabelKeys.HasMultipleEntries)}
          </Typography>
        </Grid>
      )}
      <Grid id="amountOfReceiversContainer" container item xs={12} sm={12} md={12} lg={12} xl={12}>
        <Typography variant="body2" className={classes.amountOfReceivers} style={{ fontWeight: "bold" }}>
          {translate(LabelKeys.AmountOfReceivers)}
          {": "}
          {amountOfReceivers}
        </Typography>
      </Grid>
    </Grid>
  );
}
