import React from "react";
import Checkbox from "@mui/material/Checkbox";
import clsx from "clsx";
import { CSCheckboxStyles } from "./styles";
import { Typography } from "@mui/material";

interface ICSCheckbox {
  id?: string;
  label?: string;
  checked?: boolean;
  defaultChecked?: boolean;
  disabled?: boolean;
  indeterminate?: boolean;
  className?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  error?: boolean;
}

function CSCheckbox(props: ICSCheckbox) {
  const classes = CSCheckboxStyles();

  return (
    <div style={{ display: "flex", textAlign: "left" }}>
      <Checkbox
        disableRipple
        id={props.id && props.id}
        defaultChecked={props.defaultChecked && props.defaultChecked}
        indeterminate={props.indeterminate && props.indeterminate}
        color="default"
        className={clsx(classes.root, props.className)}
        style={{
          padding: 0,
          margin: 0,
          color: "#000",
          borderRadius: 0,
          transform: "scale(1.1)",
          border: props.error && props.error ? "1px solid red" : "",
        }}
        checked={props.checked && props.checked}
        disabled={props.disabled && props.disabled}
        onChange={(e) => props.onChange && props.onChange(e)}
      />
      <div style={{ paddingLeft: 5 }} className={classes.checkbox}>
        <Typography variant="body1">{props.label && props.label}</Typography>
      </div>
    </div>
  );
}

export default CSCheckbox;
