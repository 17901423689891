import makeStyles from "@mui/styles/makeStyles";
import { theme } from "../../appTheme";

export const TermsOfUseStyles = makeStyles({
  root: { paddingBottom: 50 },
  title: {
    paddingTop: 30,
  },
  description: {
    paddingTop: 20,
    fontWeight: 600,
    fontSize: "1.1rem",
  },
  listItems: {
    paddingTop: 20,
    fontSize: "1rem",
    [theme.breakpoints.only("xs")]: {
      paddingLeft: 0,
    },
  },
});
