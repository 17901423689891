import React from "react";
import { MenuBarStyles } from "./styles";
import { LabelKeys } from "../../Utils/enums/labelKeys";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import IconButton from "@mui/material/IconButton/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { Divider } from "@mui/material";
import { SystemPart } from "../../Utils/enums/enums";
import Cookies from "js-cookie";
import { CookieConstants } from "../../Utils/Labels/types";
import { GetCurrentLanguageAsText } from "../../Utils/types";

interface IMenuBarProps {
  id: string;
  systemPart: SystemPart;
  contrastMode: boolean;
  setDialogIsOpen?: () => void;
}

function MenuBar(props: IMenuBarProps) {
  const { t: translate } = useTranslation();
  const classes = MenuBarStyles();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const isResellerConnected = Cookies.get(CookieConstants.GBVReseller)?.length > 0 && props.systemPart == SystemPart.Company;

  function handleClose() {
    setAnchorEl(null);
  }

  function handleOpenFAQPage(): void {
    Cookies.get(CookieConstants.GBVReseller)?.length > 0 && props.systemPart == SystemPart.Company
      ? navigate(`/${GetCurrentLanguageAsText()}/${translate(LabelKeys.FAQUrl)}`)
      : window.open("https://support.giftsbyvinga.com/hc/sv", "_blank");
    handleClose();
  }

  function handleOpenSignInPage(): void {
    navigate(`/${GetCurrentLanguageAsText()}/${translate(LabelKeys.ResellerUrl)}/${translate(LabelKeys.SignInUrl)}`);
    handleClose();
  }

  return (
    <div id={props.id} className={classes.menubarContainer}>
      <IconButton onClick={handleClick} style={{ padding: 0 }}>
        <svg
          width="20"
          height="20"
          fill="currentColor"
          className="bi bi-list"
          viewBox="0 0 16 16"
          style={{
            color: props.contrastMode ? "#fff" : "#000",
            width: 35,
            height: 35,
          }}
        >
          <path
            fillRule="evenodd"
            d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"
          />
        </svg>
      </IconButton>
      <Menu id="menu" disableScrollLock anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        <MenuItem
          id="contactLink"
          onClick={() => {
            if (isResellerConnected) {
              navigate(`/${GetCurrentLanguageAsText()}/${translate(LabelKeys.ContactUrl)}`);
            } else {
              window.open("https://support.giftsbyvinga.com/hc/sv/requests/new", "_blank");
            }
          }}
        >
          <svg
            width="20"
            height="20"
            fill="currentColor"
            className="bi bi-telephone"
            viewBox="0 0 16 16"
            style={{ paddingBottom: 5, paddingRight: 10 }}
          >
            <path d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.568 17.568 0 0 0 4.168 6.608 17.569 17.569 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.678.678 0 0 0-.58-.122l-2.19.547a1.745 1.745 0 0 1-1.657-.459L5.482 8.062a1.745 1.745 0 0 1-.46-1.657l.548-2.19a.678.678 0 0 0-.122-.58L3.654 1.328zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z" />
          </svg>
          {translate(LabelKeys.Contact)}
        </MenuItem>
        <MenuItem onClick={handleOpenFAQPage}>
          <svg
            width="20"
            height="20"
            fill="currentColor"
            className="bi bi-question-circle"
            viewBox="0 0 16 16"
            style={{ paddingBottom: 5, paddingRight: 10 }}
          >
            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
            <path d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286zm1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94z" />
          </svg>
          {translate(LabelKeys.FAQPageTitle)}
        </MenuItem>
        <Divider component={"div"} />
        {(Cookies.get(CookieConstants.GBVReseller) == undefined || Cookies.get(CookieConstants.GBVReseller)?.length == 0) && (
          <div style={{ display: "flex", paddingTop: 10, paddingBottom: 10 }} onClick={() => props.setDialogIsOpen()}>
            <svg width="20" height="20" fill="currentColor" className="bi bi-cart" viewBox="0 0 16 16" style={{ paddingLeft: 18 }}>
              <path d="M0 1.5A.5.5 0 0 1 .5 1H2a.5.5 0 0 1 .485.379L2.89 3H14.5a.5.5 0 0 1 .491.592l-1.5 8A.5.5 0 0 1 13 12H4a.5.5 0 0 1-.491-.408L2.01 3.607 1.61 2H.5a.5.5 0 0 1-.5-.5zM3.102 4l1.313 7h8.17l1.313-7H3.102zM5 12a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm7 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm-7 1a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm7 0a1 1 0 1 1 0 2 1 1 0 0 1 0-2z" />
            </svg>
            <div style={{ paddingLeft: 10, display: "flex" }}>
              {`${translate(LabelKeys.OrderingAs)}: `}
              <div style={{ fontWeight: "bold", paddingLeft: 5 }}>
                {`${translate(props.systemPart == SystemPart.Private ? LabelKeys.Private : LabelKeys.Company)}`}
              </div>
            </div>
          </div>
        )}
        <MenuItem onClick={handleOpenSignInPage}>
          <svg
            width="20"
            height="20"
            fill="currentColor"
            className="bi bi-shop"
            viewBox="0 0 16 16"
            style={{ paddingBottom: 5, paddingRight: 10 }}
          >
            <path d="M2.97 1.35A1 1 0 0 1 3.73 1h8.54a1 1 0 0 1 .76.35l2.609 3.044A1.5 1.5 0 0 1 16 5.37v.255a2.375 2.375 0 0 1-4.25 1.458A2.371 2.371 0 0 1 9.875 8 2.37 2.37 0 0 1 8 7.083 2.37 2.37 0 0 1 6.125 8a2.37 2.37 0 0 1-1.875-.917A2.375 2.375 0 0 1 0 5.625V5.37a1.5 1.5 0 0 1 .361-.976l2.61-3.045zm1.78 4.275a1.375 1.375 0 0 0 2.75 0 .5.5 0 0 1 1 0 1.375 1.375 0 0 0 2.75 0 .5.5 0 0 1 1 0 1.375 1.375 0 1 0 2.75 0V5.37a.5.5 0 0 0-.12-.325L12.27 2H3.73L1.12 5.045A.5.5 0 0 0 1 5.37v.255a1.375 1.375 0 0 0 2.75 0 .5.5 0 0 1 1 0zM1.5 8.5A.5.5 0 0 1 2 9v6h1v-5a1 1 0 0 1 1-1h3a1 1 0 0 1 1 1v5h6V9a.5.5 0 0 1 1 0v6h.5a.5.5 0 0 1 0 1H.5a.5.5 0 0 1 0-1H1V9a.5.5 0 0 1 .5-.5zM4 15h3v-5H4v5zm5-5a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1h-2a1 1 0 0 1-1-1v-3zm3 0h-2v3h2v-3z" />
          </svg>
          {`${translate(LabelKeys.Reseller)}? ${translate(LabelKeys.SignIn)}`}
        </MenuItem>
      </Menu>
    </div>
  );
}

export default MenuBar;
