import { ICountryOption, IFilterOption } from "./../../components/DropDown/types";
import { CoupledResellerOrder } from "./../../views/Reseller/types";
import { FileType } from "./../../components/FileUploader/types";
import { IOrderDetailsRow, IResellerDetailsRow, IResellerRow } from "./../../views/Admin/types";
import { ErrorReason } from "./../../Utils/enums/enums";
import { Reducer } from "redux";
import { ResellerActionTypes } from "./actionTypes";
import {
  IActiveReseller,
  IReseller as IReseller2,
  IGetResellerAdminContactInfoResponse,
  IGetResellerContactResponse,
  IGetResellerResponse,
  IGetResellerContactPageInformtaionResponse,
  IResellerContact,
  IResellerOption,
} from "./types";

interface IResellerInitState {
  isLoading: boolean;
  selectedReseller: IGetResellerResponse;
  selectedResellerContact: IGetResellerContactResponse;
  createResellerContactErrorReason: ErrorReason;
  updateResellerContactErrorReason: ErrorReason;
  logotypeAsBase64: string;
  logotypeFileType: FileType;
  coupledResellerLogotype: string;
  coupledResellerLogotypeFiletype: FileType;
  homePageImageUrl: string;
  homePageImageFileType: FileType;
  activeReseller: IActiveReseller;
  adminResellerRows: IResellerRow[];
  adminResellerRowsTotalItems: number;
  selectedAdminResellerRow: IResellerDetailsRow;
  adminResellerContactInfo: IGetResellerAdminContactInfoResponse;
  adminResellerIncomingOrderEmails: string[];
  coupledResellerOrder: CoupledResellerOrder[];
  coupledResellerOrderTotalItems: number;
  selectedResellerCoupledOrder: IOrderDetailsRow;
  resellers: IReseller2[];
  resellerContactPageInformation: IGetResellerContactPageInformtaionResponse;
  homePageHeaderText: string;
  homePageBodyText: string;
  resellerContacts: IResellerContact[];
  allResellerOptions: IResellerOption[];
  adminSelectedResellerOption: IFilterOption;
  allReceiverCountryOptions: ICountryOption[];
  adminSelectedResellerCountryOption: ICountryOption;
}

const resellerInitState: IResellerInitState = {
  isLoading: false,
  resellers: [] as IReseller2[],
  selectedReseller: {} as IGetResellerResponse,
  selectedResellerContact: {} as IGetResellerContactResponse,
  createResellerContactErrorReason: ErrorReason.Unknown,
  updateResellerContactErrorReason: ErrorReason.Unknown,
  logotypeAsBase64: "",
  logotypeFileType: FileType.unknown,
  homePageImageUrl: "",
  homePageImageFileType: FileType.unknown,
  coupledResellerLogotype: "",
  coupledResellerLogotypeFiletype: FileType.unknown,
  activeReseller: {} as IActiveReseller,
  adminResellerRows: [] as IResellerRow[],
  adminResellerRowsTotalItems: 0,
  selectedAdminResellerRow: {} as IResellerDetailsRow,
  adminResellerContactInfo: {} as IGetResellerAdminContactInfoResponse,
  adminResellerIncomingOrderEmails: [] as string[],
  coupledResellerOrder: [] as CoupledResellerOrder[],
  coupledResellerOrderTotalItems: 0,
  selectedResellerCoupledOrder: {} as IOrderDetailsRow,
  resellerContactPageInformation: {} as IGetResellerContactPageInformtaionResponse,
  homePageHeaderText: "",
  homePageBodyText: "",
  resellerContacts: [] as IResellerContact[],
  allResellerOptions: [] as IResellerOption[],
  adminSelectedResellerOption: {} as IFilterOption,
  allReceiverCountryOptions: [] as ICountryOption[],
  adminSelectedResellerCountryOption: {} as ICountryOption,
};

const ResellerReducer: Reducer<IResellerInitState> = (state = resellerInitState, action) => {
  switch (action.type) {
    case ResellerActionTypes.CREATE_RESELLER_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case ResellerActionTypes.CREATE_RESELLER_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case ResellerActionTypes.CREATE_RESELLER_ERROR:
      return {
        ...state,
        isLoading: false,
      };
    case ResellerActionTypes.GET_RESELLER_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case ResellerActionTypes.GET_RESELLER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        selectedReseller: action.response,
      };
    case ResellerActionTypes.GET_RESELLER_ERROR:
      return {
        ...state,
        isLoading: false,
      };
    case ResellerActionTypes.GET_RESELLER_LOGO_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case ResellerActionTypes.GET_RESELLER_LOGO_SUCCESS:
      return {
        ...state,
        isLoading: false,
        logotypeAsBase64: action.response.file,
        logotypeFileType: action.response.fileType,
      };
    case ResellerActionTypes.GET_RESELLER_LOGO_ERROR:
      return {
        ...state,
        isLoading: false,
      };
    case ResellerActionTypes.GET_RESELLER_HOME_PAGE_IMAGE_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case ResellerActionTypes.GET_RESELLER_HOME_PAGE_IMAGE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        homePageImageUrl: action.response.url,
      };
    case ResellerActionTypes.GET_RESELLER_HOME_PAGE_IMAGE_ERROR:
      return {
        ...state,
        isLoading: false,
      };
    case ResellerActionTypes.UPDATE_RESELLER_HOME_PAGE_IMAGE_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case ResellerActionTypes.UPDATE_RESELLER_HOME_PAGE_IMAGE_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case ResellerActionTypes.UPDATE_RESELLER_HOME_PAGE_IMAGE_ERROR:
      return {
        ...state,
        isLoading: false,
      };
    case ResellerActionTypes.DELETE_RESELLER_HOME_PAGE_IMAGE_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case ResellerActionTypes.DELETE_RESELLER_HOME_PAGE_IMAGE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        homePageImageUrl: "",
        homePageImageFileType: FileType.unknown,
      };
    case ResellerActionTypes.DELETE_RESELLER_HOME_PAGE_IMAGE_ERROR:
      return {
        ...state,
        isLoading: false,
      };
    case ResellerActionTypes.GET_RESELLER_HOME_PAGE_IMAGE_BY_CODE_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case ResellerActionTypes.GET_RESELLER_HOME_PAGE_IMAGE_BY_CODE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        homePageImageUrl: action.response.url,
      };
    case ResellerActionTypes.GET_RESELLER_HOME_PAGE_IMAGE_BY_CODE_ERROR:
      return {
        ...state,
        isLoading: false,
      };
    case ResellerActionTypes.UPDATE_RESELLER_LOGO_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case ResellerActionTypes.UPDATE_RESELLER_LOGO_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case ResellerActionTypes.UPDATE_RESELLER_LOGO_ERROR:
      return {
        ...state,
        isLoading: false,
      };
    case ResellerActionTypes.DELETE_RESELLER_LOGO_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case ResellerActionTypes.DELETE_RESELLER_LOGO_SUCCESS:
      return {
        ...state,
        isLoading: false,
        logotypeAsBase64: "",
        logotypeFileType: FileType.unknown,
      };
    case ResellerActionTypes.DELETE_RESELLER_LOGO_ERROR:
      return {
        ...state,
        isLoading: false,
      };
    case ResellerActionTypes.GET_RESELLER_LOGO_BY_CODE_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case ResellerActionTypes.GET_RESELLER_LOGO_BY_CODE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        coupledResellerLogotype: action.response.file,
        coupledResellerLogotypeFiletype: action.response.fileType,
      };
    case ResellerActionTypes.GET_RESELLER_LOGO_BY_CODE_ERROR:
      return {
        ...state,
        isLoading: false,
      };
    case ResellerActionTypes.UPDATE_RESELLER_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case ResellerActionTypes.UPDATE_RESELLER_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case ResellerActionTypes.UPDATE_RESELLER_ERROR:
      return {
        ...state,
        isLoading: false,
      };

    case ResellerActionTypes.CREATE_RESELLER_CONTACT_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case ResellerActionTypes.CREATE_RESELLER_CONTACT_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case ResellerActionTypes.CREATE_RESELLER_CONTACT_ERROR:
      return {
        ...state,
        isLoading: false,
        createResellerContactErrorReason: action.response,
      };
    case ResellerActionTypes.GET_RESELLER_CONTACT_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case ResellerActionTypes.GET_RESELLER_CONTACT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        selectedResellerContact: action.response,
      };
    case ResellerActionTypes.GET_RESELLER_CONTACT_ERROR:
      return {
        ...state,
        isLoading: false,
      };
    case ResellerActionTypes.GET_RESELLER_ADMIN_CONTACT_INFO_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case ResellerActionTypes.GET_RESELLER_ADMIN_CONTACT_INFO_SUCCESS:
      return {
        ...state,
        isLoading: false,
        adminResellerContactInfo: action.response,
      };
    case ResellerActionTypes.GET_RESELLER_ADMIN_CONTACT_INFO_ERROR:
      return {
        ...state,
        isLoading: false,
      };
    case ResellerActionTypes.UPDATE_RESELLER_CONTACT_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case ResellerActionTypes.UPDATE_RESELLER_CONTACT_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case ResellerActionTypes.UPDATE_RESELLER_CONTACT_ERROR:
      return {
        ...state,
        isLoading: false,
        updateResellerContactErrorReason: action.response,
      };
    case ResellerActionTypes.GET_RESELLER_BY_CODE_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case ResellerActionTypes.GET_RESELLER_BY_CODE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        activeReseller: action.response,
      };
    case ResellerActionTypes.GET_RESELLER_BY_CODE_ERROR:
      return {
        ...state,
        isLoading: false,
      };
    case ResellerActionTypes.RESET_REDUCER:
      return (state = resellerInitState);
    case ResellerActionTypes.GET_RESELLERS_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case ResellerActionTypes.GET_RESELLERS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        resellers: action.response.resellers,
      };
    case ResellerActionTypes.GET_RESELLERS_ERROR:
      return {
        ...state,
        isLoading: false,
      };
    case ResellerActionTypes.SEARCH_RESELLERS_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case ResellerActionTypes.SEARCH_RESELLERS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        adminResellerRows: action.response.resellers,
        adminResellerRowsTotalItems: action.response.totalItems,
      };
    case ResellerActionTypes.SEARCH_RESELLERS_ERROR:
      return {
        ...state,
        isLoading: false,
      };
    case ResellerActionTypes.SEARCH_SINGLE_RESELLER_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case ResellerActionTypes.SEARCH_SINGLE_RESELLER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        selectedAdminResellerRow: action.response,
      };
    case ResellerActionTypes.SEARCH_SINGLE_RESELLER_ERROR:
      return {
        ...state,
        isLoading: false,
      };
    case ResellerActionTypes.DELETE_RESELLER_CONTACT_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case ResellerActionTypes.DELETE_RESELLER_CONTACT_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case ResellerActionTypes.DELETE_RESELLER_CONTACT_ERROR:
      return {
        ...state,
        isLoading: false,
      };
    case ResellerActionTypes.GET_INCOMING_RESELLER_ORDER_EMAILS_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case ResellerActionTypes.GET_INCOMING_RESELLER_ORDER_EMAILS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        adminResellerIncomingOrderEmails: action.response.emails,
      };
    case ResellerActionTypes.GET_INCOMING_RESELLER_ORDER_EMAILS_ERROR:
      return {
        ...state,
        isLoading: false,
      };
    case ResellerActionTypes.UPDATE_INCOMING_RESELLER_ORDER_EMAILS_REQUEST:
      return {
        ...state,
        isLoading: true,
        adminResellerIncomingOrderEmails: action.request,
      };
    case ResellerActionTypes.UPDATE_INCOMING_RESELLER_ORDER_EMAILS_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case ResellerActionTypes.UPDATE_INCOMING_RESELLER_ORDER_EMAILS_ERROR:
      return {
        ...state,
        isLoading: false,
      };
    case ResellerActionTypes.SEARCH_RESELLER_COUPLED_ORDERS_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case ResellerActionTypes.SEARCH_RESELLER_COUPLED_ORDERS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        coupledResellerOrder: action.response.orders,
        coupledResellerOrderTotalItems: action.response.totalItems,
      };
    case ResellerActionTypes.SEARCH_RESELLER_COUPLED_ORDERS_ERROR:
      return {
        ...state,
        isLoading: false,
      };
    case ResellerActionTypes.ACCEPT_RESELLER_ORDER_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case ResellerActionTypes.ACCEPT_RESELLER_ORDER_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case ResellerActionTypes.ACCEPT_RESELLER_ORDER_ERROR:
      return {
        ...state,
        isLoading: false,
      };
    case ResellerActionTypes.DECLINE_RESELLER_ORDER_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case ResellerActionTypes.DECLINE_RESELLER_ORDER_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case ResellerActionTypes.DECLINE_RESELLER_ORDER_ERROR:
      return {
        ...state,
        isLoading: false,
      };
    case ResellerActionTypes.SEARCH_SINGLE_ORDER_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case ResellerActionTypes.SEARCH_SINGLE_ORDER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        selectedResellerCoupledOrder: action.response,
      };
    case ResellerActionTypes.SEARCH_SINGLE_ORDER_ERROR:
      return {
        ...state,
        isLoading: false,
      };
    case ResellerActionTypes.GET_RESELLER_CONTACT_PAGE_INFORMATION_AUTH_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case ResellerActionTypes.GET_RESELLER_CONTACT_PAGE_INFORMATION_AUTH_SUCCESS:
      return {
        ...state,
        isLoading: false,
        resellerContactPageInformation: action.response,
      };
    case ResellerActionTypes.GET_RESELLER_CONTACT_PAGE_INFORMATION_AUTH_ERROR:
      return {
        ...state,
        isLoading: false,
      };
    case ResellerActionTypes.GET_RESELLER_CONTACT_PAGE_INFORMATION_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case ResellerActionTypes.GET_RESELLER_CONTACT_PAGE_INFORMATION_SUCCESS:
      return {
        ...state,
        isLoading: false,
        resellerContactPageInformation: action.response,
      };
    case ResellerActionTypes.GET_RESELLER_CONTACT_PAGE_INFORMATION_ERROR:
      return {
        ...state,
        isLoading: false,
      };
    case ResellerActionTypes.UPDATE_RESELLER_CONTACT_PAGE_INFORMATION_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case ResellerActionTypes.UPDATE_RESELLER_CONTACT_PAGE_INFORMATION_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case ResellerActionTypes.UPDATE_RESELLER_CONTACT_PAGE_INFORMATION_ERROR:
      return {
        ...state,
        isLoading: false,
      };

    case ResellerActionTypes.GET_RESELLER_HOME_PAGE_TEXTS_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case ResellerActionTypes.GET_RESELLER_HOME_PAGE_TEXTS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        homePageHeaderText: action.response.homePageHeaderText,
        homePageBodyText: action.response.homePageBodyText,
      };
    case ResellerActionTypes.GET_RESELLER_HOME_PAGE_TEXTS_ERROR:
      return {
        ...state,
        isLoading: false,
      };
    case ResellerActionTypes.GET_RESELLER_HOME_PAGE_TEXTS_BY_CODE_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case ResellerActionTypes.GET_RESELLER_HOME_PAGE_TEXTS_BY_CODE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        homePageHeaderText: action.response.homePageHeaderText,
        homePageBodyText: action.response.homePageBodyText,
      };
    case ResellerActionTypes.GET_RESELLER_HOME_PAGE_TEXTS_BY_CODE_ERROR:
      return {
        ...state,
        isLoading: false,
      };
    case ResellerActionTypes.UPDATE_RESELLER_HOME_PAGE_TEXTS_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case ResellerActionTypes.UPDATE_RESELLER_HOME_PAGE_TEXTS_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case ResellerActionTypes.UPDATE_RESELLER_HOME_PAGE_TEXTS_ERROR:
      return {
        ...state,
        isLoading: false,
      };
    case ResellerActionTypes.GET_RESELLER_CONTACTS_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case ResellerActionTypes.GET_RESELLER_CONTACTS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        resellerContacts: action.response.contacts,
      };
    case ResellerActionTypes.GET_RESELLER_CONTACTS_ERROR:
      return {
        ...state,
        isLoading: false,
      };
    case ResellerActionTypes.VALIDATE_RESELLER_EMAIL_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case ResellerActionTypes.VALIDATE_RESELLER_EMAIL_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case ResellerActionTypes.VALIDATE_RESELLER_EMAIL_ERROR:
      return {
        ...state,
        isLoading: false,
      };
    case ResellerActionTypes.GET_ALL_RESELLERS_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case ResellerActionTypes.GET_ALL_RESELLERS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        allResellerOptions: action.response.resellers,
      };
    case ResellerActionTypes.GET_ALL_RESELLERS_ERROR:
      return {
        ...state,
        isLoading: false,
      };
    case ResellerActionTypes.SET_SELECTED_ADMIN_RESELLER_OPTION_REQUEST:
      return {
        ...state,
        isLoading: false,
        adminSelectedResellerOption: action.request.reseller,
      };
    case ResellerActionTypes.SET_SELECTED_ADMIN_RESELLER_COUNTRY_OPTION_REQUEST:
      return {
        ...state,
        isLoading: false,
        adminSelectedResellerCountryOption: action.request.country,
      };
    default:
      return state;
  }
};

export default ResellerReducer;
