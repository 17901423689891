import { TransactionActionTypes } from "./actionTypes";
import { IGetTransactionsRequest, IGetTransactionsResponse } from "./types";

//Validate email for sign in
interface Get_Transactions_Request {
  type: TransactionActionTypes.GET_TRANSACTIONS_REQUEST;
  request: IGetTransactionsRequest;
}
interface Get_Transactions_Success {
  type: TransactionActionTypes.GET_TRANSACTIONS_SUCCESS;
  response: IGetTransactionsResponse;
}
interface Get_Transactions_Error {
  type: TransactionActionTypes.GET_TRANSACTIONS_ERROR;
}

export const TransactionActions = {
  GetTransactionsRequest: (request: IGetTransactionsRequest): Get_Transactions_Request => ({
    type: TransactionActionTypes.GET_TRANSACTIONS_REQUEST,
    request,
  }),
  GetTransactionsSuccess: (response: IGetTransactionsResponse): Get_Transactions_Success => ({
    type: TransactionActionTypes.GET_TRANSACTIONS_SUCCESS,
    response,
  }),
  GetTransactionsError: (): Get_Transactions_Error => ({
    type: TransactionActionTypes.GET_TRANSACTIONS_ERROR,
  }),
};
