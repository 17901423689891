import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { IProduct, ProductCategory } from "../../Modules/Products/types";
import { RootState } from "../../store";
import { SystemPart } from "../../Utils/enums/enums";
import { StateNames, FieldNames, GetCurrentLanguageAsText } from "../../Utils/types";
import ProcessCircle from "../ProcessCircle/ProcessCircle";
import StepperStepTwoGiftCard from "./StepperStepTwoAlternatives/StepperStepTwoGiftCard";
import StepperStepTwoVH from "./StepperStepTwoAlternatives/StepperStepTwoVH";
import { useTranslation } from "react-i18next";
import { IsResellerAuth, IsAuth } from "../../Utils/authentication";
import { LabelKeys } from "../../Utils/enums/labelKeys";

interface ICSStepperStepTwoProps {
  id: string;
  systemPart: SystemPart;
  selectedProductHasError: boolean;
  options: IProduct[];
  onStateChanged: (stateName: StateNames.stepperTwoState, value: IProduct, fieldName: FieldNames) => void;
}

export default function CSStepperStepTwo(props: ICSStepperStepTwoProps) {
  const { t: translate } = useTranslation();
  const navigate = useNavigate();
  const isAdmin =
    (IsResellerAuth() &&
      location.pathname.includes(
        `/${translate(LabelKeys.ResellerUrl)}/${translate(LabelKeys.AdminUrl)}/${translate(LabelKeys.OrderUrl)}`
      )) ||
    (IsAuth() && location.pathname.includes(`/${translate(LabelKeys.AdminUrl)}/${translate(LabelKeys.OrderUrl)}`));
  const reducerState = useSelector(
    (s: RootState) => {
      return {
        product: s.product,
        order: s.order,
      };
    },
    (prev, next) =>
      prev.product.products === next.product.products &&
      prev.product.isLoading === next.product.isLoading &&
      prev.order.isLoading === next.order.isLoading &&
      prev.product.selectedProductCategory === next.product.selectedProductCategory &&
      prev.product.selectedProduct === next.product.selectedProduct &&
      prev.order.stepperOneState.selectedProductCategory === next.order.stepperOneState.selectedProductCategory &&
      prev.order.stepperTwoState.selectedProduct === next.order.stepperTwoState.selectedProduct &&
      prev.order.stepperState.errorSections === next.order.stepperState.errorSections
  );

  useEffect(() => {
    window.scroll(0, 0);
    if (reducerState.order.environmentState.systemPart === undefined && !isAdmin) {
      navigate(`/${GetCurrentLanguageAsText()}/`);
    }
  }, []);

  return (
    <React.Fragment>
      {reducerState.product.isLoading ? (
        <ProcessCircle />
      ) : reducerState.order.stepperOneState.selectedProductCategory.category == ProductCategory.GiftCardVH ? (
        <StepperStepTwoVH
          id={props.id}
          systemPart={props.systemPart}
          options={props.options}
          selectedProductHasError={props.selectedProductHasError}
          onStateChanged={(stateName, x, fieldName) => props.onStateChanged(stateName, x, fieldName)}
        />
      ) : (
        <StepperStepTwoGiftCard
          id={props.id}
          systemPart={props.systemPart}
          options={props.options}
          selectedProductHasError={props.selectedProductHasError}
          onStateChanged={(stateName, x, fieldName) => props.onStateChanged(stateName, x, fieldName)}
        />
      )}
    </React.Fragment>
  );
}
