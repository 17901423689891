import { IStandardResponse } from "./../Orders/types";
import { CookieConstants } from "./../../Utils/Labels/types";
import { axiosInstance, GetBaseURL } from "./../../Utils/network/axios/index";
import { all, call, put, SagaReturnType, takeLatest } from "redux-saga/effects";
import { AuthenticationActions } from "./actions";
import {
  IAddExternalSystemRequest,
  IGetExternalSystemDetailsRequest,
  IGetExternalSystemDetailsResponse,
  IGetExternalSystemsResponse,
  ISignInRequest,
  ISignInResellerRequest,
  ISignInResponse,
  IUpdateExternalSystemRequest,
  IValidateSignInEmailRequest,
} from "./types";
import { AuthenticationActionTypes } from "./actionTypes";
import { AUTHENTICATION_API } from "../../Utils/network/axios/urls";
import SetUserSession from "../../Utils/authentication";
import SnackbarActions from "../../components/Snackbar/actions";
import Cookies from "js-cookie";
import { ErrorReason } from "../../Utils/enums/enums";

export default function* AuthenticationWatcher() {
  yield all([
    takeLatest(AuthenticationActionTypes.SIGN_IN_REQUEST, SignInWorker),
    takeLatest(AuthenticationActionTypes.VALIDATE_SIGN_IN_EMAIL_REQUEST, ValidateEmailWorker),
    takeLatest(AuthenticationActionTypes.SIGN_IN_RESELLER_REQUEST, SignInResellerWorker),
    takeLatest(AuthenticationActionTypes.GET_EXTERNAL_SYSTEMS_REQUEST, GetExternalSystemsWorker),
    takeLatest(AuthenticationActionTypes.GET_EXTERNAL_SYSTEM_DETAILS_REQUEST, GetExternalSystemDetailsWorker),
    takeLatest(AuthenticationActionTypes.ADD_EXTERNAL_SYSTEM_REQUEST, AddExternalSystemWorker),
    takeLatest(AuthenticationActionTypes.UPDATE_EXTERNAL_SYSTEM_REQUEST, UpdateExternalSystemWorker),
  ]);
}

export function SetSignInValues(response: ISignInResponse) {
  const jwtOptions: Cookies.CookieAttributes = {
    expires: 2,
    sameSite: "None",
    secure: true,
  } as Cookies.CookieAttributes;

  const refreshTokenOptions: Cookies.CookieAttributes = {
    expires: 2,
    sameSite: "None",
    secure: true,
  } as Cookies.CookieAttributes;

  Cookies.set(CookieConstants.GBVJwt, response.jwt, jwtOptions);
  Cookies.set(CookieConstants.GBVRefreshToken, response.refreshToken.refreshToken, refreshTokenOptions);
  SetUserSession(response.userSession);
}

//WORKERS
function* SignInWorker({ request }: ReturnType<typeof AuthenticationActions.SignInRequest>) {
  try {
    const response: SignInServiceResponse = yield call(signIn, request);
    yield put(AuthenticationActions.SignInSuccess());
    SetSignInValues(response);
  } catch (error) {
    yield put(AuthenticationActions.SignInError());
    yield put(SnackbarActions.ShowError("Något gick fel vid inloggning. Vänligen försök igen."));
  }
}

function* SignInResellerWorker({ request }: ReturnType<typeof AuthenticationActions.SignInResellerRequest>) {
  try {
    const response: SignInResellerServiceResponse = yield call(signInReseller, request);
    yield put(AuthenticationActions.SignInResellerSuccess());
    SetSignInValues(response);
  } catch (error) {
    yield put(AuthenticationActions.SignInResellerError());
    yield put(SnackbarActions.ShowError("Något gick fel vid inloggning. Vänligen försök igen."));
  }
}

function* ValidateEmailWorker({ request }: ReturnType<typeof AuthenticationActions.ValidateSignInEmailRequest>) {
  try {
    yield call(validateEmail, request);
    yield put(AuthenticationActions.ValidateSignInEmailSuccess());
    yield put(SnackbarActions.ShowSuccess("Valideringskod skickad till angiven emailadress"));
  } catch (error) {
    yield put(AuthenticationActions.ValidateSignInEmailError());
    yield put(SnackbarActions.ShowError("Något gick fel vid valideringen av emailadressen. Vänligen försök igen."));
  }
}

function* GetExternalSystemsWorker({}: ReturnType<typeof AuthenticationActions.GetExternalSystemsRequest>) {
  try {
    let response: GetExternalSystemsServiceResponse = yield call(getExternalSystems);
    yield put(AuthenticationActions.GetExternalSystemsSuccess(response));
  } catch (error) {
    yield put(AuthenticationActions.GetExternalSystemsError());
    yield put(SnackbarActions.ShowError("Kunde inte hämta externa system. Vänligen försök igen."));
  }
}

function* GetExternalSystemDetailsWorker({ request }: ReturnType<typeof AuthenticationActions.GetExternalSystemDetailsRequest>) {
  try {
    let response: GetExternalSystemDetailsServiceResponse = yield call(getExternalSystemDetails, request);
    if (response.error == ErrorReason.Unknown) {
      yield put(AuthenticationActions.GetExternalSystemDetailsSuccess(response));
    } else {
      yield put(AuthenticationActions.GetExternalSystemDetailsError());
      yield put(SnackbarActions.ShowError("Kunde inte hämta detaljer för extert system. Vänligen försök igen."));
    }
  } catch (error) {
    yield put(AuthenticationActions.GetExternalSystemDetailsError());
    yield put(SnackbarActions.ShowError("Kunde inte hämta detaljer för extert system. Vänligen försök igen."));
  }
}

function* AddExternalSystemWorker({ request }: ReturnType<typeof AuthenticationActions.AddExternalSystemRequest>) {
  try {
    let response: AddExternalSystemServiceResponse = yield call(addExternalSystem, request);
    if (response.error == ErrorReason.Unknown) {
      yield put(AuthenticationActions.AddExternalSystemSuccess());
      yield put(SnackbarActions.ShowSuccess("Extern system tillagt."));
      yield put(AuthenticationActions.GetExternalSystemsRequest());
    } else {
      yield put(AuthenticationActions.AddExternalSystemError());
      yield put(SnackbarActions.ShowError("Något gick fel vid tillägg av externt system. Vänligen försök igen."));
    }
  } catch (error) {
    yield put(AuthenticationActions.AddExternalSystemError());
    yield put(SnackbarActions.ShowError("Något gick fel vid tillägg av externt system. Vänligen försök igen."));
  }
}

function* UpdateExternalSystemWorker({ request }: ReturnType<typeof AuthenticationActions.UpdateExternalSystemRequest>) {
  try {
    let response: UpdateExternalSystemServiceResponse = yield call(updateExternalSystem, request);
    if (response.error == ErrorReason.Unknown) {
      yield put(AuthenticationActions.UpdateExternalSystemSuccess());
      yield put(SnackbarActions.ShowSuccess("Externt system uppdaterades."));
      yield put(AuthenticationActions.GetExternalSystemsRequest());
    } else {
      yield put(AuthenticationActions.UpdateExternalSystemError());
      yield put(SnackbarActions.ShowError("Något gick fel vid uppdateringen av externt system. Vänligen försök igen."));
    }
  } catch (error) {
    yield put(AuthenticationActions.UpdateExternalSystemError());
    yield put(SnackbarActions.ShowError("Något gick fel vid uppdateringen av externt system. Vänligen försök igen."));
  }
}

// API:S
export const signIn: SignInService = async (request: ISignInRequest) => {
  axiosInstance.defaults.baseURL = GetBaseURL() + AUTHENTICATION_API;
  return await axiosInstance.post(`admin/signIn`, request).then((x) => x.data);
};

export const signInReseller: SignInResellerService = async (request: ISignInResellerRequest) => {
  axiosInstance.defaults.baseURL = GetBaseURL() + AUTHENTICATION_API;
  return await axiosInstance.post(`reseller/signin`, request).then((x) => x.data);
};

export const validateEmail: ValidateEmailService = async (request: IValidateSignInEmailRequest) => {
  axiosInstance.defaults.baseURL = GetBaseURL() + AUTHENTICATION_API;
  return await axiosInstance.post(`admin/validate`, request);
};

export const getExternalSystems: GetExternalSystemsService = async () => {
  axiosInstance.defaults.baseURL = GetBaseURL() + AUTHENTICATION_API;
  return await axiosInstance.get(`externalSystem`).then((x) => x.data);
};

export const getExternalSystemDetails: GetExternalSystemDetailsService = async (request: IGetExternalSystemDetailsRequest) => {
  axiosInstance.defaults.baseURL = GetBaseURL() + AUTHENTICATION_API;
  return await axiosInstance.get(`externalSystem/details`, { params: { id: request.id } }).then((x) => x.data);
};

export const addExternalSystem: AddExternalSystemService = async (request: IAddExternalSystemRequest) => {
  axiosInstance.defaults.baseURL = GetBaseURL() + AUTHENTICATION_API;
  return await axiosInstance.post(`externalSystem`, request).then((x) => x.data);
};

export const updateExternalSystem: UpdateExternalSystemService = async (request: IUpdateExternalSystemRequest) => {
  axiosInstance.defaults.baseURL = GetBaseURL() + AUTHENTICATION_API;
  return await axiosInstance.put(`externalSystem`, request).then((x) => x.data);
};

// SERVICES
export interface SignInService {
  (request: ISignInRequest): Promise<ISignInResponse>;
}
export interface ValidateEmailService {
  (request: IValidateSignInEmailRequest): Promise<void>;
}
export interface SignInResellerService {
  (request: ISignInResellerRequest): Promise<ISignInResponse>;
}
export interface GetExternalSystemsService {
  (): Promise<IGetExternalSystemsResponse>;
}
export interface GetExternalSystemDetailsService {
  (request: IGetExternalSystemDetailsRequest): Promise<IGetExternalSystemDetailsResponse>;
}
export interface AddExternalSystemService {
  (request: IAddExternalSystemRequest): Promise<IStandardResponse>;
}
export interface UpdateExternalSystemService {
  (request: IUpdateExternalSystemRequest): Promise<IStandardResponse>;
}

// RESPONSE
export type SignInServiceResponse = SagaReturnType<typeof signIn>;
export type SignInResellerServiceResponse = SagaReturnType<typeof signInReseller>;
export type ValidateEmailServiceResponse = SagaReturnType<typeof validateEmail>;
export type GetExternalSystemsServiceResponse = SagaReturnType<typeof getExternalSystems>;
export type GetExternalSystemDetailsServiceResponse = SagaReturnType<typeof getExternalSystemDetails>;
export type AddExternalSystemServiceResponse = SagaReturnType<typeof addExternalSystem>;
export type UpdateExternalSystemServiceResponse = SagaReturnType<typeof updateExternalSystem>;
