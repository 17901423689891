import * as React from "react";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { IFilterOption } from "./types";
import { CSDropDownStyles } from "./styles";
import clsx from "clsx";
import { useEffect, useState } from "react";
import { Typography } from "@mui/material";

interface ICSDropDownProps {
  id?: string;
  filterOptions: IFilterOption[];
  className?: string;
  label?: string;
  value: string;
  defaultValue?: string;
  classNameRoot?: string;
  disabled?: boolean;
  classNameInner?: string;
  placeholder?: string;
  required?: boolean;
  useFlags?: boolean;
  onStateChanged: (value: IFilterOption) => void;
}

export default function CSDropDown(props: ICSDropDownProps) {
  const [selectedItem, setSelectedItem] = useState(
    (props.filterOptions?.find((x) => x.name?.toLowerCase() == props.value?.toLowerCase()) as IFilterOption) ?? ({} as IFilterOption)
  );
  const classes = CSDropDownStyles();

  useEffect(() => {
    let value = props.filterOptions?.find((x) => x?.name?.toLowerCase() == props.value?.toLowerCase()) as IFilterOption;
    if (value) {
      setSelectedItem(value);
    }
  }, [props]);

  return (
    <div className={props.classNameRoot}>
      <Typography variant="body2" style={{ textAlign: "left", paddingBottom: 14, fontWeight: "bold" }}>
        {props.label ? (props.required ? `${props.label}*` : `${props.label}`) : ""}
      </Typography>
      <FormControl
        id={props.id}
        disabled={props.disabled}
        className={clsx(classes.root, props.classNameInner, props.className)}
        sx={{ width: "100%" }}
      >
        <Select
          style={{ borderRadius: 0, backgroundColor: "#fff", textAlign: "left", height: 46 }}
          value={selectedItem?.name ?? ""}
          MenuProps={{
            sx: { height: { xs: 250, sm: 200, md: 300, lg: 300, xl: 500 } },
          }}
          defaultValue={props.defaultValue ?? props.defaultValue}
          renderValue={() => {
            if (
              selectedItem == undefined ||
              selectedItem?.name?.length <= 0 ||
              selectedItem?.name == undefined ||
              props.value == undefined
            ) {
              return <em style={{ color: "#ccc", fontWeight: "lighter" }}>{props?.placeholder}</em>;
            } else {
              return props?.value;
            }
          }}
        >
          {props.filterOptions?.map((opt, index) => (
            <MenuItem
              key={index}
              value={opt.name}
              onClick={() => {
                setSelectedItem(opt);
                props.onStateChanged(opt);
              }}
            >
              {opt.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}
