// export enum Language {
//   Unknown = "",
//   Swedish = "SE",
//   English = "EN",
//   Finnish = "FI",
//   Norwegian = "NO",
// }

export enum LanguageCode {
  Unknown = 0,
  SE = 1,
  EN = 2,
  FI = 3,
  NO = 4,
}

export enum Currency {
  None = 0,
  SEK = 1,
  EUR = 2,
  USD = 3,
  NOK = 4,
}

export enum PaymentStatus {
  None = 0,
  Pending = 1,
  Succeeded = 2,
  Canceled = 3,
}

export enum LanguagePath {
  Unknown = "",
  Swedish = "src/Utils/Labels/Swedish.json",
  English = "src/Utils/Labels/English.json",
}

export enum PaymentMethod {
  Card = 1,
  Swish = 2,
  Invoice = 3,
}

export enum ErrorReason {
  Unknown = 0,
  ResellerValidationFail = 1,
  SwedbankPayCreatePurchaseFail = 2,
  ResellerAlreadyExists = 3,
  ResellerNull = 4,
  InvalidEmail = 5,
  ResellerContactAlreadyExists = 6,
  ResellerContactNull = 7,
  InvalidImageFormat = 8,
  InvalidImageSize = 9,
  ImageNotFound = 10,
  OrderLineNotFound = 11,
  InvalidPhoneNumber = 12,
  NoIsStandardImageUrl = 13,
  ObjectNotFoundInDatabase = 14,
  RefundNotPossible = 15,
  OrderAlreadyHandled = 16,
  InvalidOrganisationNumber = 17,
  OrderNotCreatedInNav = 18,
  EmailNotExists = 19,
}

export enum Environment {
  Development = "development",
  Test = "test",
  Production = "production",
}

export enum SystemPart {
  Unknown = 0,
  Private = 1,
  Company = 2,
}

export enum ImageSystemPart {
  Unknown = 0,
  EmailSmsMailings = 1,
}

export enum Resolution {
  Logo = "600x300",
  LogoExpanded = "600x300",
  HD = "720x1280",
  FullHD = "1920x1080",
  QHD2K = "2560x1440",
  UlraHD4K = "3840x2160",
  PhysicalCardCoverPage = "800x1100",
}

export enum AspectRatio {
  PhysicalCardCoverPage = 0.8,
}

export enum MaxFileSize {
  HalfMB = 525000,
  OneMB = 1050000,
  TwoMB = 2100000,
  ThreeMB = 3200000,
  FourMB = 4250000,
  FiveMB = 5250000,
}

export enum PhysicalCardCategory {
  ChristmasCards = "Christmas cards",
  ThankYouCards = "Thank you cards",
}

//Try to keep these variables in sync
export const ReceiversLimit = 200;
export const AmountOfMailingsLimit = 200;

export enum PhysicalCardImage {
  Unknown = 0,
  InsideImage = 1,
  BackgroundImage = 2,
}

export enum PhysicalCardCoverImageCategory {
  Unknown = 0,
  InsideImage = 1,
  BackgroundImage = 2,
}

export enum LitiumImageTagNameConstants {
  Birthday = "birthday",
  Easter = "easter",
  Vacation = "vacation",
  Summer = "summer",
  Spring = "spring",
  Autumn = "autumn",
  Winter = "winter",
  Christmas = "christmas",
  NewYear = "newYear",
}

export enum TemplateType {
  Unknown = 0,
  Heading = 1,
  General = 2,
  PhysicalCardHeading = 3,
  PhysicalCardCustomMessage = 4,
}

export enum Country {
  Unknown = 0,
  SE = 1,
  FI = 2,
  NO = 3,
}
