import { IImageOption, IPhysicalCardImage } from "../../Modules/Products/types";
import { SendBy } from "../Delivery/types";
import { IFilterOption } from "../DropDown/types";
import { IUploadedFile } from "../FileUploader/types";

export interface IMessageState {
  emailSubject?: string;
  smsMessage: string;
  emailMessage: string;
  emailPDFMessage: string;
  messageSignature: string;
  customMessage: boolean;
  selectedImage?: IImageOption;
  uploadedImage?: IUploadedFile;
  openPDF?: boolean;
  sendBy: SendBy;
  selectedMessageTemplate?: IFilterOption;
}

export enum TemplateType {
  Unknown = 0,
  Heading = 1,
  General = 2,
  PhysicalCardHeading = 3,
  PhysicalCardCustomMessage = 4,
}

export interface PhysicalCardState {
  physicalCardHeadingSuggestion?: IFilterOption;
  physicalCardCustomMessageSuggestion?: IFilterOption;
  categoryFilterOptions?: IFilterOption[];

  //IMAGE AND DROP DOWN OPTION ALTERNATIVES
  physicalCardHeadingTemplates?: IFilterOption[];
  physicalCardCustomMessageTemplates?: IFilterOption[];
  physicalCardFilteredCoverImages?: IImageOption[];

  //SELECTED IMAGES, CATEGORY; OPTION
  physicalCardSelectedCategory?: IFilterOption;
  physicalCardSelectedUploadedLogotype: IUploadedFile;
  physicalCardSelectedUploadedCoverImage?: IUploadedFile;
  physicalCardSelectedCoverImage?: IImageOption;
  physicalCardSelectedMiddlePageImage?: IPhysicalCardImage;
  physicalCardSelectedBackImage?: IPhysicalCardImage;
}
