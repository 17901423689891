export enum ProductActionTypes {
  //Get product by id
  GET_PRODUCT_BY_ID_REQUEST = "GET_PRODUCT_BY_ID_REQUEST",
  GET_PRODUCT_BY_ID_SUCCESS = "GET_PRODUCT_BY_ID_SUCCESS",
  GET_PRODUCT_BY_ID_ERROR = "GET_PRODUCT_BY_ID_ERROR",

  //Get all products
  GET_PRODUCTS_REQUEST = "GET_PRODUCTS_REQUEST",
  GET_PRODUCTS_SUCCESS = "GET_PRODUCTS_SUCCESS",
  GET_PRODUCTS_ERROR = "GET_PRODUCTS_ERROR",

  //Get preview PDF
  GET_PREVIEW_PDF_REQUEST = "GET_PREVIEW_PDF_REQUEST",
  GET_PREVIEW_PDF_SUCCESS = "GET_PREVIEW_PDF_SUCCESS",
  GET_PREVIEW_PDF_ERROR = "GET_PREVIEW_PDF_ERROR",

  //Create message templates
  CREATE_MESSAGE_TEMPLATES_REQUEST = "CREATE_MESSAGE_TEMPLATES_REQUEST",
  CREATE_MESSAGE_TEMPLATES_SUCCESS = "CREATE_MESSAGE_TEMPLATES_SUCCESS",
  CREATE_MESSAGE_TEMPLATES_ERROR = "CREATE_MESSAGE_TEMPLATES_ERROR",

  //Get message templates
  GET_MESSAGE_TEMPLATES_REQUEST = "GET_MESSAGE_TEMPLATES_REQUEST",
  GET_MESSAGE_TEMPLATES_SUCCESS = "GET_MESSAGE_TEMPLATES_SUCCESS",
  GET_MESSAGE_TEMPLATES_ERROR = "GET_MESSAGE_TEMPLATES_ERROR",

  //Update message templates
  UPDATE_MESSAGE_TEMPLATES_REQUEST = "UPDATE_MESSAGE_TEMPLATES_REQUEST",
  UPDATE_MESSAGE_TEMPLATES_SUCCESS = "UPDATE_MESSAGE_TEMPLATES_SUCCESS",
  UPDATE_MESSAGE_TEMPLATES_ERROR = "UPDATE_MESSAGE_TEMPLATES_ERROR",

  //Delete message templates
  DELETE_MESSAGE_TEMPLATES_REQUEST = "DELETE_MESSAGE_TEMPLATES_REQUEST",
  DELETE_MESSAGE_TEMPLATES_SUCCESS = "DELETE_MESSAGE_TEMPLATES_SUCCESS",
  DELETE_MESSAGE_TEMPLATES_ERROR = "DELETE_MESSAGE_TEMPLATES_ERROR",

  //Get voucher
  GET_VOUCHER_REQUEST = "GET_VOUCHER_REQUEST",
  GET_VOUCHER_SUCCESS = "GET_VOUCHER_SUCCESS",
  GET_VOUCHER_ERROR = "GET_VOUCHER_ERROR",

  //Get product categories
  GET_PRODUCT_CATEGORIES_REQUEST = "GET_PRODUCT_CATEGORIES_REQUEST",
  GET_PRODUCT_CATEGORIES_SUCCESS = "GET_PRODUCT_CATEGORIES_SUCCESS",
  GET_PRODUCT_CATEGORIES_ERROR = "GET_PRODUCT_CATEGORIES_ERROR",

  //Set
  SET_SELECTED_PRODUCT = "SET_SELECTED_PRODUCT",
  SET_SELECTED_CUSTOM_MESSAGE_PRODUCT = "SET_SELECTED_CUSTOM_MESSAGE_PRODUCT",
  SET_SELECTED_PRODUCT_CATEGORY = "SET_SELECTED_PRODUCT_CATEGORY",

  //PhysicalCard conver images
  GET_PHYSICAL_CARD_COVER_IMAGES_REQUEST = "GET_PHYSICAL_CARD_COVER_IMAGES_REQUEST",
  GET_PHYSICAL_CARD_COVER_IMAGES_SUCCESS = "GET_PHYSICAL_CARD_COVER_IMAGES_SUCCESS",
  GET_PHYSICAL_CARD_COVER_IMAGES_ERROR = "GET_PHYSICAL_CARD_COVER_IMAGES_ERROR",

  //PhysicalCard back image
  GET_PHYSICAL_CARD_BACK_IMAGES_REQUEST = "GET_PHYSICAL_CARD_BACK_IMAGES_REQUEST",
  GET_PHYSICAL_CARD_BACK_IMAGES_SUCCESS = "GET_PHYSICAL_CARD_BACK_IMAGES_SUCCESS",
  GET_PHYSICAL_CARD_BACK_IMAGES_ERROR = "GET_PHYSICAL_CARD_BACK_IMAGES_ERROR",

  //PhysicalCard middle page image
  GET_PHYSICAL_CARD_MIDDLE_PAGE_IMAGES_REQUEST = "GET_PHYSICAL_CARD_MIDDLE_PAGE_IMAGES_REQUEST",
  GET_PHYSICAL_CARD_MIDDLE_PAGE_IMAGES_SUCCESS = "GET_PHYSICAL_CARD_MIDDLE_PAGE_IMAGES_SUCCESS",
  GET_PHYSICAL_CARD_MIDDLE_PAGE_IMAGES_ERROR = "GET_PHYSICAL_CARD_MIDDLE_PAGE_IMAGES_ERROR",

  //PhysicalCard website cover images
  GET_PHYSICAL_CARD_WEBSITE_COVER_IMAGES_REQUEST = "GET_PHYSICAL_CARD_WEBSITE_COVER_IMAGES_REQUEST",
  GET_PHYSICAL_CARD_WEBSITE_COVER_IMAGES_SUCCESS = "GET_PHYSICAL_CARD_WEBSITE_COVER_IMAGES_SUCCESS",
  GET_PHYSICAL_CARD_WEBSITE_COVER_IMAGES_ERROR = "GET_PHYSICAL_CARD_WEBSITE_COVER_IMAGES_ERROR",

  //Reset
  RESET_REDUCER = "RESET_REDUCER",

  //Message component image selector images
  GET_IMAGE_SELECTOR_IMAGES_REQUEST = "GET_IMAGE_SELECTOR_IMAGES_REQUEST",
  GET_IMAGE_SELECTOR_IMAGES_SUCCESS = "GET_IMAGE_SELECTOR_IMAGES_SUCCESS",
  GET_IMAGE_SELECTOR_IMAGES_ERROR = "GET_IMAGE_SELECTOR_IMAGES_ERROR",

  //Download documentation
  DOWNLOAD_DOCUMENTATION_REQUEST = "DOWNLOAD_DOCUMENTATION_REQUEST",
  DOWNLOAD_DOCUMENTATION_SUCCESS = "DOWNLOAD_DOCUMENTATION_SUCCESS",
  DOWNLOAD_DOCUMENTATION_ERROR = "DOWNLOAD_DOCUMENTATION_ERROR",
}
