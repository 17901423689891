import { AuthenticationActionTypes } from "./actionTypes";
import {
  IAddExternalSystemRequest,
  IGetExternalSystemDetailsRequest,
  IGetExternalSystemDetailsResponse,
  IGetExternalSystemsResponse,
  ISetSignedInUserEmailRequest,
  ISignInRequest,
  ISignInResellerRequest,
  IUpdateExternalSystemRequest,
  IValidateSignInEmailRequest,
} from "./types";

//Validate email for sign in
interface Validate_Sign_In_Email_Request {
  type: AuthenticationActionTypes.VALIDATE_SIGN_IN_EMAIL_REQUEST;
  request: IValidateSignInEmailRequest;
}
interface Validate_Sign_In_Email_Success {
  type: AuthenticationActionTypes.VALIDATE_SIGN_IN_EMAIL_SUCCESS;
}
interface Validate_Sign_In_Email_Error {
  type: AuthenticationActionTypes.VALIDATE_SIGN_IN_EMAIL_ERROR;
}

//Get external systems
interface Get_External_Systems_Request {
  type: AuthenticationActionTypes.GET_EXTERNAL_SYSTEMS_REQUEST;
}
interface Get_External_Systems_Success {
  type: AuthenticationActionTypes.GET_EXTERNAL_SYSTEMS_SUCCESS;
  response: IGetExternalSystemsResponse;
}
interface Get_External_Systems_Error {
  type: AuthenticationActionTypes.GET_EXTERNAL_SYSTEMS_ERROR;
}

//Get external system details
interface Get_External_System_Details_Request {
  type: AuthenticationActionTypes.GET_EXTERNAL_SYSTEM_DETAILS_REQUEST;
  request: IGetExternalSystemDetailsRequest;
}
interface Get_External_System_Details_Success {
  type: AuthenticationActionTypes.GET_EXTERNAL_SYSTEM_DETAILS_SUCCESS;
  response: IGetExternalSystemDetailsResponse;
}
interface Get_External_System_Details_Error {
  type: AuthenticationActionTypes.GET_EXTERNAL_SYSTEM_DETAILS_ERROR;
}

//Add external system
interface Add_External_System_Request {
  type: AuthenticationActionTypes.ADD_EXTERNAL_SYSTEM_REQUEST;
  request: IAddExternalSystemRequest;
}
interface Add_External_System_Success {
  type: AuthenticationActionTypes.ADD_EXTERNAL_SYSTEM_SUCCESS;
}
interface Add_External_System_Error {
  type: AuthenticationActionTypes.ADD_EXTERNAL_SYSTEM_ERROR;
}
//Update external system
interface Update_External_System_Request {
  type: AuthenticationActionTypes.UPDATE_EXTERNAL_SYSTEM_REQUEST;
  request: IUpdateExternalSystemRequest;
}
interface Update_External_System_Success {
  type: AuthenticationActionTypes.UPDATE_EXTERNAL_SYSTEM_SUCCESS;
}
interface Update_External_System_Error {
  type: AuthenticationActionTypes.UPDATE_EXTERNAL_SYSTEM_ERROR;
}

//Sign in (with validation code and email)
interface Sign_In_Request {
  type: AuthenticationActionTypes.SIGN_IN_REQUEST;
  request: ISignInRequest;
}
interface Sign_In_Success {
  type: AuthenticationActionTypes.SIGN_IN_SUCCESS;
}
interface Sign_In_Error {
  type: AuthenticationActionTypes.SIGN_IN_ERROR;
}

//Sign out
interface Sign_Out_Request {
  type: AuthenticationActionTypes.SIGN_OUT_REQUEST;
}
interface Sign_Out_Success {
  type: AuthenticationActionTypes.SIGN_OUT_SUCCESS;
}
interface Sign_Out_Error {
  type: AuthenticationActionTypes.SIGN_OUT_ERROR;
}

//Sign out reseller
interface Sign_Out_Reseller_Request {
  type: AuthenticationActionTypes.SIGN_OUT_RESELLER_REQUEST;
}

//Sign in reseller
interface Sign_In_Reseller_Request {
  type: AuthenticationActionTypes.SIGN_IN_RESELLER_REQUEST;
  request: ISignInResellerRequest;
}
interface Sign_In_Reseller_Success {
  type: AuthenticationActionTypes.SIGN_IN_RESELLER_SUCCESS;
}
interface Sign_In_Reseller_Error {
  type: AuthenticationActionTypes.SIGN_IN_RESELLER_ERROR;
}

interface Set_Is_System_Part_Dialog_Open_Request {
  type: AuthenticationActionTypes.SET_IS_SYSTEMPART_DIALOG_OPEN_REQUEST;
  request: boolean;
}

interface Set_Signed_In_User_Email_Request {
  type: AuthenticationActionTypes.SET_SIGNED_IN_USER_EMAIL_REQUEST;
  request: ISetSignedInUserEmailRequest;
}

//Reset
interface Reset_Reducer {
  type: AuthenticationActionTypes.RESET_REDUCER;
}

export const AuthenticationActions = {
  ValidateSignInEmailRequest: (request: IValidateSignInEmailRequest): Validate_Sign_In_Email_Request => ({
    type: AuthenticationActionTypes.VALIDATE_SIGN_IN_EMAIL_REQUEST,
    request,
  }),
  ValidateSignInEmailSuccess: (): Validate_Sign_In_Email_Success => ({
    type: AuthenticationActionTypes.VALIDATE_SIGN_IN_EMAIL_SUCCESS,
  }),
  ValidateSignInEmailError: (): Validate_Sign_In_Email_Error => ({
    type: AuthenticationActionTypes.VALIDATE_SIGN_IN_EMAIL_ERROR,
  }),
  SignInRequest: (request: ISignInRequest): Sign_In_Request => ({
    type: AuthenticationActionTypes.SIGN_IN_REQUEST,
    request,
  }),
  SignInSuccess: (): Sign_In_Success => ({
    type: AuthenticationActionTypes.SIGN_IN_SUCCESS,
  }),
  SignInError: (): Sign_In_Error => ({
    type: AuthenticationActionTypes.SIGN_IN_ERROR,
  }),
  SignOutRequest: (): Sign_Out_Request => ({
    type: AuthenticationActionTypes.SIGN_OUT_REQUEST,
  }),
  SignOutSuccess: (): Sign_Out_Success => ({
    type: AuthenticationActionTypes.SIGN_OUT_SUCCESS,
  }),
  SignOutError: (): Sign_Out_Error => ({
    type: AuthenticationActionTypes.SIGN_OUT_ERROR,
  }),

  //System part dialog
  SetIsSystemPartDialogOpenRequest: (request: boolean): Set_Is_System_Part_Dialog_Open_Request => ({
    type: AuthenticationActionTypes.SET_IS_SYSTEMPART_DIALOG_OPEN_REQUEST,
    request,
  }),

  SignOutResellerRequest: (): Sign_Out_Reseller_Request => ({
    type: AuthenticationActionTypes.SIGN_OUT_RESELLER_REQUEST,
  }),

  SignInResellerRequest: (request: ISignInResellerRequest): Sign_In_Reseller_Request => ({
    type: AuthenticationActionTypes.SIGN_IN_RESELLER_REQUEST,
    request,
  }),
  SignInResellerSuccess: (): Sign_In_Reseller_Success => ({
    type: AuthenticationActionTypes.SIGN_IN_RESELLER_SUCCESS,
  }),
  SignInResellerError: (): Sign_In_Reseller_Error => ({
    type: AuthenticationActionTypes.SIGN_IN_RESELLER_ERROR,
  }),

  ResetReducer: (): Reset_Reducer => ({
    type: AuthenticationActionTypes.RESET_REDUCER,
  }),

  GetExternalSystemsRequest: (): Get_External_Systems_Request => ({
    type: AuthenticationActionTypes.GET_EXTERNAL_SYSTEMS_REQUEST,
  }),
  GetExternalSystemsSuccess: (response: IGetExternalSystemsResponse): Get_External_Systems_Success => ({
    type: AuthenticationActionTypes.GET_EXTERNAL_SYSTEMS_SUCCESS,
    response,
  }),
  GetExternalSystemsError: (): Get_External_Systems_Error => ({
    type: AuthenticationActionTypes.GET_EXTERNAL_SYSTEMS_ERROR,
  }),

  GetExternalSystemDetailsRequest: (request: IGetExternalSystemDetailsRequest): Get_External_System_Details_Request => ({
    type: AuthenticationActionTypes.GET_EXTERNAL_SYSTEM_DETAILS_REQUEST,
    request,
  }),
  GetExternalSystemDetailsSuccess: (response: IGetExternalSystemDetailsResponse): Get_External_System_Details_Success => ({
    type: AuthenticationActionTypes.GET_EXTERNAL_SYSTEM_DETAILS_SUCCESS,
    response,
  }),
  GetExternalSystemDetailsError: (): Get_External_System_Details_Error => ({
    type: AuthenticationActionTypes.GET_EXTERNAL_SYSTEM_DETAILS_ERROR,
  }),

  AddExternalSystemRequest: (request: IAddExternalSystemRequest): Add_External_System_Request => ({
    type: AuthenticationActionTypes.ADD_EXTERNAL_SYSTEM_REQUEST,
    request,
  }),
  AddExternalSystemSuccess: (): Add_External_System_Success => ({
    type: AuthenticationActionTypes.ADD_EXTERNAL_SYSTEM_SUCCESS,
  }),
  AddExternalSystemError: (): Add_External_System_Error => ({
    type: AuthenticationActionTypes.ADD_EXTERNAL_SYSTEM_ERROR,
  }),

  UpdateExternalSystemRequest: (request: IUpdateExternalSystemRequest): Update_External_System_Request => ({
    type: AuthenticationActionTypes.UPDATE_EXTERNAL_SYSTEM_REQUEST,
    request,
  }),
  UpdateExternalSystemSuccess: (): Update_External_System_Success => ({
    type: AuthenticationActionTypes.UPDATE_EXTERNAL_SYSTEM_SUCCESS,
  }),
  UpdateExternalSystemError: (): Update_External_System_Error => ({
    type: AuthenticationActionTypes.UPDATE_EXTERNAL_SYSTEM_ERROR,
  }),

  SetSignedInUserEmail: (request: ISetSignedInUserEmailRequest): Set_Signed_In_User_Email_Request => ({
    type: AuthenticationActionTypes.SET_SIGNED_IN_USER_EMAIL_REQUEST,
    request,
  }),
};
