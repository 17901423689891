export const TermsOfUseData = [
  "Tjänsten innebär att du skickar en SMS- eller mailhälsning och samtidigt har möjlighet att ge bort ett presentkort eller digital upplevelse. Mottagaren av din hälsning får en inlösningskod som denne sedan kan lösa in mot en gåva eller digital upplevelse.",
  "Du är ansvarig för att det du skickar inte strider mot lag eller förordning som till exempel marknadsföringslagen eller personuppgiftslagen.",
  "Som användare av tjänsten ansvarar du för materialets innehåll och utformning. Du har inte rätt att göra intrång i upphovsrätt eller varumärke.",
  "Ditt material får inte medföra skada eller olägenhet för Gifts By Vinga.",
  "Ditt material får inte väcka förargelse, innehålla pornografi, våld eller hets mot folkgrupp.",
  "Gifts By Vinga och våra partners tar inget ansvar för hur innehållet – bilder samt texter/personliga hälsningar - utformas. ",
  "Gifts By Vinga och dess partners förbehåller sig rätten att inte vidarebefordra hälsningar och bilder vars innehåll eller utformning strider mot dessa tjänstevillkor.",
  "Gifts By Vinga förbehåller sig rätten att inte producera eller distribuera hälsningar vars innehåll eller utformning strider mot dessa användarvillkor.",
  "Gifts By Vinga och våra partners kommer att behandla personuppgifter enligt PUL och dataskyddsförordningen (Läs mer här: www.vinga.com/integritetspolicy)",
  "Gifts By Vinga tar inte ansvar för eventuella trafikkostnader som uppkommer vid betalning av tjänster via sms.",
  "Gifts By Vinga kontrollerar ej att angivet telefonnummer eller mailadress är riktig.",
  "Tjänsten fungerar just nu bara för distribution i Sverige.",
  "Tjänsten är mobilanpassad och fungerar på de flesta typer av enheter såsom PC, Laptop, tablets och smartphones. Dock kan Gifts By Vinga tyvärr inte garantera att tjänsten fungerar på alla modeller. ",
  "Gifts By Vinga ansvarar för att leverera hälsningar till SMS- och maildistributören vid rätt tidpunkt. Därefter har Gifts By Vinga uppfyllt sin del av avtalet och reserverar sig för eventuella förseningar hos SMS distributören eller nätoperatören. ",
  "Genom att använda tjänsten har du godkänt villkoren för tjänsten och du förbinder dig att hålla Gifts By Vinga skadelös för alla kostnader och övrig skada som ditt utnyttjande av tjänsten i strid med dessa villkor har åsamkat Gifts By Vinga eller tredje man.",
  "Gifts by Vinga återbetalar inga oinlösta eller utgånga/ogiltiga kort.",
];

export const TermsOfUseDescription =
  "Köpvillkoren grundar sig på den tekniska plattformen levererad av Vinga of Sweden AB (556676-4063), nedan kallad ”Gifts By Vinga”. Genom att använda tjänsten har du godkänt villkoren för tjänsten.";
