import React, { useState } from "react";
import { Grid, Button, Typography, useMediaQuery } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import CSTextField from "../../../../../components/Input/Textfield/CSTextField";
import { RootState } from "../../../../../store";
import { LabelKeys } from "../../../../../Utils/enums/labelKeys";
import { FieldNames } from "../../../../../Utils/types";
import { ResellerStyles } from "../../../styles";
import { IEditResellerContactPageDataState, IEditResellerContactPageInformationData } from "../../../types";
import { theme } from "../../../../../appTheme";

interface IEditResellerContactPageData {
  onSave: (data: IEditResellerContactPageInformationData) => void;
  onClose: () => void;
}

export default function EditResellerContactPageData(props: IEditResellerContactPageData) {
  const { t: translate } = useTranslation();
  const isMobile = useMediaQuery(theme.breakpoints.only("xs"));
  const classes = ResellerStyles();
  const reducerState = useSelector(
    (s: RootState) => {
      return {
        Reseller: s.reseller,
      };
    },
    (prev, next) =>
      prev.Reseller.isLoading === next.Reseller.isLoading &&
      prev.Reseller.resellerContactPageInformation === next.Reseller.resellerContactPageInformation
  );

  const [editResellerContactPageDataState, setEditResellerContactPageDataState] = useState({
    addressLine1: reducerState.Reseller.resellerContactPageInformation?.addressLine1,
    postalCode: reducerState.Reseller.resellerContactPageInformation?.postalCode,
    city: reducerState.Reseller.resellerContactPageInformation?.city,
    country: reducerState.Reseller.resellerContactPageInformation?.country,
    website: reducerState.Reseller.resellerContactPageInformation?.website,
    contactEmails: reducerState.Reseller.resellerContactPageInformation?.contactEmails?.join("\n"),
    contactPhoneNumbers: reducerState.Reseller.resellerContactPageInformation?.contactPhoneNumbers?.join("\n"),
  } as IEditResellerContactPageDataState);

  function handleFieldOnChange(fieldName: FieldNames, value: boolean | string | number) {
    setEditResellerContactPageDataState((state) => ({
      ...state,
      [fieldName]: value,
    }));
  }

  function handleSaveData() {
    props.onSave({
      addressLine1: editResellerContactPageDataState?.addressLine1,
      postalCode: editResellerContactPageDataState?.postalCode,
      city: editResellerContactPageDataState?.city,
      country: editResellerContactPageDataState?.country,
      website: editResellerContactPageDataState?.website,
      contactEmails: editResellerContactPageDataState?.contactEmails,
      contactPhoneNumbers: editResellerContactPageDataState?.contactPhoneNumbers,
    } as IEditResellerContactPageInformationData);
    props.onClose();
  }

  return (
    <Grid
      key={reducerState.Reseller.selectedAdminResellerRow?.id.toString()}
      container
      item
      xs={12}
      sm={12}
      md={12}
      lg={12}
      xl={12}
      //style={{ maxHeight: 850 }}
    >
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ paddingTop: 30 }}>
        <CSTextField
          className={classes.textfield}
          label={translate(LabelKeys.AddressLine)}
          placeholder={`${translate(LabelKeys.AddressLine)}`}
          type="text"
          value={editResellerContactPageDataState.addressLine1 ?? ""}
          onChange={(e) => {
            handleFieldOnChange(FieldNames.addressLine1, e.target.value);
          }}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ paddingTop: 20 }}>
        <CSTextField
          className={classes.textfield}
          label={translate(LabelKeys.PostalCode)}
          placeholder={`${translate(LabelKeys.PostalCode)}`}
          value={editResellerContactPageDataState.postalCode ?? ""}
          type="text"
          onChange={(e) => {
            handleFieldOnChange(FieldNames.postalCode, e.target.value);
          }}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ paddingTop: 20 }}>
        <CSTextField
          className={classes.textfield}
          label={translate(LabelKeys.City)}
          placeholder={`${translate(LabelKeys.City)}`}
          value={editResellerContactPageDataState.city ?? ""}
          type="text"
          onChange={(e) => {
            handleFieldOnChange(FieldNames.city, e.target.value);
          }}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ paddingTop: 20 }}>
        <CSTextField
          className={classes.textfield}
          label={translate(LabelKeys.Country)}
          placeholder={`${translate(LabelKeys.Country)}`}
          value={editResellerContactPageDataState.country ?? ""}
          type="text"
          onChange={(e) => {
            handleFieldOnChange(FieldNames.country, e.target.value);
          }}
        />
      </Grid>
      <Grid container style={{ paddingTop: 20 }}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ paddingTop: 10 }}>
          <Typography variant="body2" style={{ textAlign: "left", paddingBottom: 5, fontWeight: "bold" }}>
            {translate(LabelKeys.Website)}
          </Typography>
        </Grid>
        <Grid item xs={3} sm={2} md={2} lg={3} xl={2} style={{ paddingTop: 25 }}>
          <Typography variant="body1">{"https://"}</Typography>
        </Grid>
        <Grid item xs={9} sm={10} md={10} lg={9} xl={10}>
          <CSTextField
            className={classes.textfield}
            placeholder={`${translate(LabelKeys.Website)}`}
            value={editResellerContactPageDataState.website ?? ""}
            type="text"
            onChange={(e) => {
              handleFieldOnChange(FieldNames.website, e.target.value);
            }}
          />
        </Grid>
      </Grid>
      <Grid id="editContactPageContactEmails" item xs={12} sm={12} md={12} lg={12} xl={12} style={{ marginTop: 30 }}>
        <CSTextField
          id="editContactEmails"
          multiline={true}
          label={translate(LabelKeys.ContactEmails)}
          rows={3}
          type="email"
          value={editResellerContactPageDataState.contactEmails ?? ""}
          placeholder={`${translate(LabelKeys.ReceiverEmails)}`}
          onChange={(e) => {
            handleFieldOnChange(FieldNames.contactEmails, e.target.value);
          }}
        />
      </Grid>
      <Grid id="editContactPageContactPhoneNumbers" item xs={12} sm={12} md={12} lg={12} xl={12} style={{ marginTop: 30 }}>
        <CSTextField
          id="editContactPhoneNumbers"
          multiline={true}
          label={translate(LabelKeys.ContactPhoneNumbers)}
          rows={3}
          type="email"
          value={editResellerContactPageDataState.contactPhoneNumbers ?? ""}
          placeholder={`${translate(LabelKeys.ReceiverPhoneNumbers)}`}
          onChange={(e) => {
            handleFieldOnChange(FieldNames.contactPhoneNumbers, e.target.value);
          }}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={6} lg={6} xl={6} style={{ paddingTop: 31, paddingRight: isMobile ? 0 : 8 }}>
        <Button
          id="saveBtn"
          style={{
            height: 46,
            width: "100%",
            cursor: "pointer",
            color: "#fff",
            borderRadius: 0,
            backgroundColor: "#000",
          }}
          onClick={() => handleSaveData()}
        >
          <div style={{ margin: "auto", textTransform: "none" }}>{`${translate(LabelKeys.Save)}`}</div>
        </Button>
      </Grid>
      <Grid item xs={12} sm={6} md={6} lg={6} xl={6} style={{ paddingTop: 31, paddingLeft: isMobile ? 0 : 8 }}>
        <Button
          id="cancelBtn"
          style={{
            height: 46,
            width: "100%",
            cursor: "pointer",
            color: "#fff",
            borderRadius: 0,
            backgroundColor: "#000",
          }}
          onClick={() => props.onClose()}
        >
          <div style={{ margin: "auto", textTransform: "none" }}>{`${translate(LabelKeys.Cancel)}`}</div>
        </Button>
      </Grid>
    </Grid>
  );
}
