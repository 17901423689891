import React from "react";
import svLocale from "date-fns/locale/sv";
import engLocale from "date-fns/locale/en-US";
import { LanguageCode } from "../../../Utils/enums/enums";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import TextField, { TextFieldProps } from "@mui/material/TextField/TextField";
import { Typography } from "@mui/material";
import { GetCurrentLanguageAsCodeFromLocalStorage } from "../../../Utils/types";

export interface ICSDatePicker {
  id?: string;
  label?: string;
  defaultValue?: Date;
  disabled?: boolean;
  error?: boolean;
  value?: Date;
  helperText?: string;
  okLabel?: string;
  required?: boolean;
  cancelLabel?: string;
  disableFuture?: boolean;
  disablePast?: boolean;
  minDate?: Date;
  maxDate?: Date;
  fullWidth?: boolean;
  placeholder?: string;
  showToolbar?: boolean;
  showTodayBtn?: boolean;
  todayLabel?: string;
  className?: string;
  onChange?: (value: Date) => void;
  onBlur?: () => void;
  onLoad?: () => void;
}

function CSDatePicker(props: ICSDatePicker) {
  function GetLocale(): Locale {
    switch (GetCurrentLanguageAsCodeFromLocalStorage()) {
      case LanguageCode.SE:
        return svLocale;
      case LanguageCode.EN:
        return engLocale;
      default:
        return svLocale;
    }
  }

  function getErrorMessage(): React.ReactNode {
    return (
      <Typography component={"span"} style={{ fontSize: "0.8rem", display: "flex" }}>
        <Typography component={"span"} style={{ fontSize: "0.8rem", marginLeft: -14 }}>
          <svg
            width="20"
            height="20"
            fill="currentColor"
            className="bi bi-exclamation-diamond-fill"
            viewBox="0 0 16 16"
            style={{
              color: "red",
              margin: 0,
              paddingTop: 5,
            }}
          >
            <path d="M9.05.435c-.58-.58-1.52-.58-2.1 0L.436 6.95c-.58.58-.58 1.519 0 2.098l6.516 6.516c.58.58 1.519.58 2.098 0l6.516-6.516c.58-.58.58-1.519 0-2.098L9.05.435zM8 4c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995A.905.905 0 0 1 8 4zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z" />
          </svg>
        </Typography>
        <Typography component={"span"} style={{ fontSize: "0.8rem", paddingTop: 7, paddingLeft: 5 }}>
          {props.helperText && props.helperText}
        </Typography>
      </Typography>
    );
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={GetLocale()}>
      <Typography variant="body2" style={{ textAlign: "left", paddingBottom: 14, fontWeight: "bold" }}>
        {props.label ? (props.required ? `${props.label}*` : `${props.label}`) : ""}
      </Typography>
      <MobileDatePicker
        className={props.className}
        // todayText={props.todayLabel}
        // showTodayButton={props.showTodayBtn}
        disableFuture={props.disableFuture}
        disablePast={props.disablePast}
        disabled={props.disabled}
        inputFormat="dd-MM-yyyy"
        disableMaskedInput
        minDate={props.minDate}
        maxDate={props.maxDate}
        // okText={props.okLabel}
        // cancelText={props.cancelLabel}
        showToolbar={props.showToolbar}
        value={props.value ? props.value : new Date()}
        onChange={(newValue: Date) => {
          props.onChange(newValue);
        }}
        renderInput={(params: TextFieldProps) => (
          <TextField
            placeholder={props.placeholder}
            size={"small"}
            onLoad={() => props.onLoad()}
            {...params}
            InputLabelProps={{
              style: { height: 46 },
              shrink: true,
            }}
            style={{ backgroundColor: "#fff" }}
            InputProps={{
              style: { height: 46 },
            }}
            fullWidth={props.fullWidth}
            error={props.error}
            helperText={props.error && getErrorMessage()}
          />
        )}
      />
    </LocalizationProvider>
  );
}

export default CSDatePicker;
