import React from "react";
import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import { LabelKeys } from "../../Utils/enums/labelKeys";
import { SendBy } from "../Delivery/types";
import { MessageStyles } from "./styles";
import { StateNames, FieldNames } from "../../Utils/types";
import SMSTextEditor from "../Editors/SMSTextEditor";
import CSTextField from "../Input/Textfield/CSTextField";

interface IMessageTextEditorWrapperProps {
  id: string;
  sendBy: SendBy;
  smsMessage: string;
  emailMessage: string;
  emailPDFMessage: string;
  onStateChanged: (stateName: StateNames.stepperThreeState, value: string | number, fieldName: FieldNames) => void;
}

export default function MessageTextEditorWrapper(props: IMessageTextEditorWrapperProps) {
  const { t: translate } = useTranslation();
  const classes = MessageStyles();
  const maxLengthCustomMessageSMS = 180;

  function handleFieldOnChange(stateName: StateNames.stepperThreeState, value: string | number, fieldName: FieldNames) {
    props.onStateChanged(stateName, value, fieldName);
  }

  return (
    <Grid item xs={12} sm={12} md={12} className={classes.messageBox}>
      {props.sendBy == SendBy.SMS ? (
        <SMSTextEditor
          id="smsTextEditor"
          className={classes.editor}
          value={props.smsMessage}
          maxLengthSMS={maxLengthCustomMessageSMS}
          onChange={(value) => handleFieldOnChange(StateNames.stepperThreeState, value, FieldNames.smsMessage)}
        />
      ) : props.sendBy == SendBy.Email ? (
        <CSTextField
          id="emailTextEditor"
          className={classes.editor}
          value={props.emailMessage}
          placeholder={translate(LabelKeys.WriteMessageHere)}
          multiline
          rows={8}
          onChange={(e) => handleFieldOnChange(StateNames.stepperThreeState, e.target.value, FieldNames.emailMessage)}
        />
      ) : (
        <Grid item xs={12} md={12} id="emailPDFTextEditor">
          <CSTextField
            id="emailPDFTextEditor"
            className={classes.editor}
            value={props.emailPDFMessage}
            placeholder={translate(LabelKeys.WriteMessageHere)}
            multiline
            rows={8}
            onChange={(e) => handleFieldOnChange(StateNames.stepperThreeState, e.target.value, FieldNames.emailPDFMessage)}
          />
        </Grid>
      )}
    </Grid>
  );
}
