import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import { useTranslation } from "react-i18next";
import { Typography, useMediaQuery } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import ProcessCircle from "../../../../components/ProcessCircle/ProcessCircle";
import { RootState } from "../../../../store";
import { LabelKeys } from "../../../../Utils/enums/labelKeys";
import { FieldNames } from "../../../../Utils/types";
import { AdminSubview, IAdminExternalSystemState } from "../../types";
import CSReadOnlyTable from "../../../../components/Table/CSReadOnlyTable";
import CSToolbar from "../../../../components/Toolbar/CSToolbar";
import Popup from "../../../../components/Popup/Popup";
import AddEditExternalSystem from "./Popups/AddEditExternalSystem";
import { ExternalSystemStyles } from "./styles";
import { externalSystemHeaders, IExternalSystem } from "./types";
import { AuthenticationActions } from "../../../../Modules/Authentication/actions";
import {
  IAddExternalSystemRequest,
  IGetExternalSystemDetailsRequest,
  ISetSignedInUserEmailRequest,
  IUpdateExternalSystemRequest,
} from "../../../../Modules/Authentication/types";
import { theme } from "../../../../appTheme";
import AdminMenu from "../../../../components/Menu/AdminMenu";
import AdminSidebar from "../../AdminSidebar";
import { AdminStyles } from "../../styles";
import { CookieConstants } from "../../../../Utils/Labels/types";
import Cookies from "js-cookie";
import jwt_decode from "jwt-decode";

export default function AdminExternalSystem() {
  const classes = ExternalSystemStyles();
  const classes2 = AdminStyles();
  const dispatch = useDispatch();

  const [externalSystemState, setExternalSystemState] = useState({
    externalSystemDetails: {},
    selectedExternalSystemRow: {},
    externalSystemRows: [],
  } as IAdminExternalSystemState);
  const [isAddEditExternalSystemPopupOpen, setIsAddEditExternalSystemPopupOpen] = useState(false);
  const [isAddMode, setIsAddMode] = useState(false);
  const { t: translate } = useTranslation();
  const isMobile = useMediaQuery(theme.breakpoints.only("xs"));
  const reducerState = useSelector(
    (s: RootState) => {
      return {
        authentication: s.authentication,
      };
    },
    (prev, next) =>
      prev.authentication.isLoading === next.authentication.isLoading &&
      prev.authentication.externalSystemRows === next.authentication.externalSystemRows &&
      prev.authentication.externalSystemDetails === next.authentication.externalSystemDetails
  );

  useEffect(() => {
    dispatch(AuthenticationActions.GetExternalSystemsRequest());

    if (reducerState.authentication.signedInUserEmail?.length <= 0) {
      const jwt = Cookies.get(CookieConstants.GBVJwt);
      if (jwt == undefined) return;

      const decodedJwt = jwt_decode(jwt) as any;
      if (decodedJwt == undefined) return;
      if (decodedJwt?.Email?.length > 0) {
        dispatch(AuthenticationActions.SetSignedInUserEmail({ email: decodedJwt?.Email } as ISetSignedInUserEmailRequest));
      }
    }
  }, []);

  useEffect(() => {
    if (
      reducerState.authentication.externalSystemDetails?.id != undefined &&
      externalSystemState.selectedExternalSystemRow?.id != undefined
    ) {
      setIsAddEditExternalSystemPopupOpen(true);
      handleFieldOnChange(FieldNames.externalSystemDetails, reducerState.authentication.externalSystemDetails);
    }
  }, [reducerState.authentication.externalSystemDetails]);

  function handleFieldOnChange(fieldName: FieldNames, value: IExternalSystem) {
    setExternalSystemState((state) => ({
      ...state,
      [fieldName]: value,
    }));
  }

  function handleOnExternalSystemRowClicked(row: IExternalSystem) {
    setIsAddMode(false);
    handleFieldOnChange(FieldNames.selectedExternalSystemRow, row);
    dispatch(AuthenticationActions.GetExternalSystemDetailsRequest({ id: row.id } as IGetExternalSystemDetailsRequest));
  }

  function handleSaveExternalSystem(externalSystem: IExternalSystem, isAddMode: boolean) {
    if (isAddMode) {
      dispatch(
        AuthenticationActions.AddExternalSystemRequest({
          name: externalSystem.name,
          isActive: externalSystem.isActive,
        } as IAddExternalSystemRequest)
      );
    } else {
      dispatch(
        AuthenticationActions.UpdateExternalSystemRequest({
          id: externalSystem.id,
          name: externalSystem.name,
          isActive: externalSystem.isActive,
        } as IUpdateExternalSystemRequest)
      );
    }
    setIsAddEditExternalSystemPopupOpen(false);
  }

  return (
    <div style={{ display: "flex" }}>
      {!isMobile && (
        <Grid item xs={2} sm={1} md={1} lg={1} xl={1}>
          <AdminSidebar activeSubview={AdminSubview.ExternalSystem} />
        </Grid>
      )}
      {reducerState.authentication?.isLoading && <ProcessCircle />}
      <Grid container item xs={12} sm={12} md={12} lg={12} xl={12} justifyContent={"center"}>
        {isMobile && (
          <Grid item xs={11}>
            <AdminMenu />
          </Grid>
        )}
        <Grid item xs={11} sm={11} md={11} lg={11} xl={11} className={classes2.subviewContent} style={{ paddingTop: isMobile ? 0 : 50 }}>
          <Grid container item xs={12} sm={12} md={12} lg={12} xl={12}>
            {reducerState.authentication?.isLoading && <ProcessCircle />}
            <Grid item xs={10} sm={11} md={11} lg={11} xl={11} style={{ paddingBottom: 10, float: "left" }}>
              <Typography variant="h2" style={{ paddingBottom: 10, float: "left" }}>
                {translate(LabelKeys.ExternalSystemTitle)}
              </Typography>
            </Grid>
            <Grid item xs={1} sm={1} md={1} lg={1} xl={1} style={{ paddingBottom: 10 }}>
              <CSToolbar
                className={classes.externalSystemToolbar}
                enableAdd
                disableAdd={false}
                iconSize={30}
                addTooltipText={translate(LabelKeys.AddExternalSystem)}
                onAddClicked={() => {
                  setIsAddMode(true);
                  setIsAddEditExternalSystemPopupOpen(true);
                }}
                onEditClicked={() => {
                  setIsAddMode(false);
                  setIsAddEditExternalSystemPopupOpen(true);
                }}
              />
            </Grid>
            <Grid container item xs={12} sm={12} md={12} lg={12} xl={12} style={{ paddingTop: 20 }}>
              <CSReadOnlyTable
                columns={externalSystemHeaders}
                rows={reducerState.authentication.externalSystemRows}
                onRowClick={(e, selectedRow) => handleOnExternalSystemRowClicked(selectedRow)}
                enableRowClick={true}
                maxHeight={600}
              />
            </Grid>
            {isAddEditExternalSystemPopupOpen && (
              <Popup
                onEscapeKeyDown={() => {
                  setIsAddEditExternalSystemPopupOpen(false);
                  handleFieldOnChange(FieldNames.externalSystemDetails, {} as IExternalSystem);
                }}
                content={
                  <AddEditExternalSystem
                    isAddMode={isAddMode}
                    externalSystemDetails={externalSystemState.externalSystemDetails}
                    onSave={(e: IExternalSystem, isAddMode: boolean) => handleSaveExternalSystem(e, isAddMode)}
                    onClose={() => {
                      setIsAddEditExternalSystemPopupOpen(false);
                      handleFieldOnChange(FieldNames.externalSystemDetails, {} as IExternalSystem);
                    }}
                  />
                }
                onClose={() => {
                  setIsAddEditExternalSystemPopupOpen(false);
                  handleFieldOnChange(FieldNames.externalSystemDetails, {} as IExternalSystem);
                }}
                enableCloseIcon={true}
                dialogTitle={isAddMode ? translate(LabelKeys.AddExternalSystem) : translate(LabelKeys.EditExternalSystem)}
              />
            )}
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}
