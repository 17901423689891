import { Country, LanguageCode } from "./../../Utils/enums/enums";
import { PhysicalCardImage } from "../../Utils/enums/enums";
import { TemplateType } from "./../Message/types";

export interface IFilterOption {
  id: number;
  name: string;
  value?: string | number;
  checked?: boolean;
  templateType?: TemplateType;
  physicalCardImageCategory?: PhysicalCardImage;
}

export interface ICountryOption {
  id: number;
  name: string;
  value: LanguageCode;
  country: Country;
}

export const StandardTemplateId = 100;
export const DropDownStandardTemplateId = 10000;
