export interface IDeliveryState {
  orderDate: Date;
  includeFreight: boolean;
  whenToSend: WhenToSend;
  receiverEmails: string;
  receiverPhoneNumbers: string;
  multipleReceiverMode?: boolean;
  hasMultipleEntries?: boolean;
  amountOfMailings: number;
  amountOfReceivers: number;
  sendToSameReceiver: boolean;
}

export enum SendBy {
  Unknown = 0,
  Email = 1,
  SMS = 2,
  EmailPDF = 3,
  PhysicalCard = 4,
}

export enum WhenToSend {
  Now = 0,
  SpecificTime = 1,
}
