import React from "react";
import Grid from "@mui/material/Grid";

interface ITextImageContentBlockProps {
  id: string;
  leftSideContent?: React.ReactNode;
  rightSideContent?: React.ReactNode;
  classNameLeft?: string;
  classNameRight?: string;
  swapOrder?: boolean;
}

function TextImageContentBlock(props: ITextImageContentBlockProps) {
  return (
    <Grid container item xs={12} sm={12} md={12} lg={12} xl={12} justifyContent="center">
      <Grid
        item
        xs={11}
        sm={12}
        md={12}
        lg={5}
        xl={5}
        className={props.classNameLeft}
        alignItems={"center"}
        style={{ textAlign: "left", display: "flex" }}
        sx={{ paddingRight: { lg: 4, xl: 4 }, paddingTop: { xs: "20px" } }}
        order={props.swapOrder && { xs: 2, sm: 2, md: 2, lg: 2, xl: 2 }}
      >
        {props.leftSideContent}
      </Grid>
      <Grid
        item
        container
        xs={12}
        sm={12}
        md={12}
        lg={7}
        xl={7}
        className={props.classNameRight}
        style={{ display: "flex" }}
        justifyContent={"flex-end"}
        order={props.swapOrder && { xs: 1, sm: 1, md: 1, lg: 1, xl: 1 }}
      >
        {props.rightSideContent}
      </Grid>
    </Grid>
  );
}

export default TextImageContentBlock;
