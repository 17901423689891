import React, { useState } from "react";
import { Grid, IconButton, Typography, useMediaQuery } from "@mui/material";
import Button from "@mui/material/Button/Button";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store";
import { LabelKeys } from "../../Utils/enums/labelKeys";
import { OrderActions } from "../../Modules/Orders/actions";
import { FieldNames } from "../../Utils/types";
import { ICSNextPrevStepMobileProps } from "./types";
import MenuIcon from "@mui/icons-material/Menu";
import CSStepper from "./CSStepper";
import { ISetStepState } from "../../Modules/Orders/types";
import CircularProgress from "@mui/material/CircularProgress";
import { theme } from "../../appTheme";
import { IsResellerAuth, IsAuth } from "../../Utils/authentication";
import { CookieConstants } from "../../Utils/Labels/types";
import Cookies from "js-cookie";

interface CSNextPrevStepMobileProps {
  hideStepper: boolean;
}

export default function CSNextPrevStepMobile(props1: CSNextPrevStepMobileProps) {
  const dispatch = useDispatch();
  const { t: translate } = useTranslation();
  const isAdmin =
    (IsResellerAuth() &&
      location.pathname.includes(
        `/${translate(LabelKeys.ResellerUrl)}/${translate(LabelKeys.AdminUrl)}/${translate(LabelKeys.OrderUrl)}`
      )) ||
    (IsAuth() && location.pathname.includes(`/${translate(LabelKeys.AdminUrl)}/${translate(LabelKeys.OrderUrl)}`));

  const reducerState = useSelector(
    (s: RootState) => {
      return {
        order: s.order,
      };
    },
    (prev, next) =>
      prev.order.stepperState === next.order.stepperState &&
      prev.order.stepperState.errorSections === next.order.stepperState.errorSections &&
      prev.order.stepperThreeState.isPhysicalCardEditorPopupOpen === next.order.stepperThreeState.isPhysicalCardEditorPopupOpen
  );

  function onPrevStepClicked(): void {
    dispatch(
      OrderActions.SetStepperStateRequest({
        fieldName: FieldNames.activeStep,
        value: reducerState.order.stepperState.activeStep - 1,
      })
    );
  }
  function onNextStepClicked(): void {
    dispatch(
      OrderActions.SetStepperStateRequest({
        fieldName: FieldNames.activeStep,
        value: reducerState.order.stepperState.activeStep + 1,
      })
    );
  }

  return (
    <>
      {!props1.hideStepper && (
        <MobileStepper
          activeStep={reducerState.order.stepperState.activeStep}
          onNextStepClicked={() => onNextStepClicked()}
          onPrevStepClicked={() => onPrevStepClicked()}
        />
      )}
    </>
  );
}

function MobileStepper(props: ICSNextPrevStepMobileProps) {
  const { t: translate } = useTranslation();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const isSm = useMediaQuery(theme.breakpoints.only("sm"));
  const isMd = useMediaQuery(theme.breakpoints.only("md"));
  const dispatch = useDispatch();
  const isAdmin =
    (IsResellerAuth() &&
      location.pathname.includes(
        `/${translate(LabelKeys.ResellerUrl)}/${translate(LabelKeys.AdminUrl)}/${translate(LabelKeys.OrderUrl)}`
      )) ||
    (IsAuth() && location.pathname.includes(`/${translate(LabelKeys.AdminUrl)}/${translate(LabelKeys.OrderUrl)}`));

  const reducerState = useSelector(
    (s: RootState) => {
      return {
        order: s.order,
      };
    },
    (prev, next) => prev.order.stepperState === next.order.stepperState
  );

  return (
    <Grid
      id="stepperContainer"
      container
      style={{
        display: "flex",
        position: "fixed",
        bottom: 0,
        left: 0,
        backgroundColor: "#fff",
        width: "100vw",
        borderTop: "1px solid #000",
        zIndex: 100000,
      }}
    >
      <Grid
        id="previousBtn"
        item
        xs={4}
        style={{
          cursor: "pointer",
          backgroundColor: "#e3e1de",
          height: 80,
          padding: 1,
          width: "auto",
          margin: "auto",
        }}
      >
        <Button style={{ width: "100%", height: "100%" }} disabled={props.activeStep === 0} onClick={() => props.onPrevStepClicked()}>
          <div
            style={{
              color: "#000",
              paddingTop: 5,
            }}
          >
            {translate(LabelKeys.Previous)}
          </div>
        </Button>
      </Grid>
      <Grid
        id="middleMobileMenuIcon"
        container
        item
        xs={4}
        justifyContent="center"
        style={{
          cursor: "pointer",
          backgroundColor: "#fff",
          height: 80,
          padding: 2,
          width: "auto",
          margin: "auto",
        }}
        onClick={() => setIsMenuOpen(true)}
      >
        <Grid
          container
          item
          xs={6}
          style={{
            position: "relative",
            zIndex: 1,
            height: "100%",
            width: "100%",
            paddingTop: 20,
          }}
        >
          <CircularProgress
            variant="determinate"
            value={(100 * (props.activeStep + 1)) / 6}
            style={{
              color: "#000",
              position: "absolute",
              zIndex: 2,
              backgroundColor: "#e3e1de",
              borderRadius: "50%",
              margin: "auto",
              marginLeft: isSm || isMd ? 60 : "",
            }}
          />
          <div
            style={{
              position: "absolute",
              left: isSm ? 67 : isMd ? 67 : 7,
              top: 27,
              zIndex: 3,
            }}
          >
            <MenuIcon style={{ fontSize: 25, color: "#000" }} />
          </div>
        </Grid>
        <Grid item xs={6} style={{ paddingTop: 30 }}>
          <Typography variant="body2" align="left" style={{ fontWeight: "bold", margin: "auto" }}>
            {props.activeStep + 1}
            {" av "}
            {6}
          </Typography>
        </Grid>
      </Grid>
      {props.activeStep <= 4 ? (
        <Grid
          id="nextBtn"
          item
          xs={4}
          style={{
            cursor: "pointer",
            backgroundColor: "#2eac77",
            height: 80,
            padding: 2,
            width: "auto",
          }}
        >
          <Button style={{ width: "100%", height: "100%" }} disabled={props.activeStep === 5} onClick={() => props.onNextStepClicked()}>
            <div
              style={{
                color: "#fff",
                paddingTop: 5,
              }}
            >
              {translate(LabelKeys.Next)}
            </div>
          </Button>
        </Grid>
      ) : (
        <Grid
          id="payMobileBtn"
          item
          xs={4}
          style={{
            cursor: "pointer",
            backgroundColor: "#2eac77",
            height: 80,
            padding: 2,
          }}
        >
          <Button
            style={{ width: "100%", height: "100%" }}
            onClick={() =>
              dispatch(
                OrderActions.SetStepperStateRequest({
                  fieldName: FieldNames.readyToPay,
                  value: true,
                })
              )
            }
          >
            <div
              style={{
                color: "#fff",
                paddingTop: 5,
              }}
            >
              {isAdmin || Cookies.get(CookieConstants.GBVReseller)?.length > 0 ? translate(LabelKeys.OrderBtn) : translate(LabelKeys.Pay)}
            </div>
          </Button>
        </Grid>
      )}
      {isMenuOpen && (
        <div
          style={{
            position: "fixed",
            bottom: 0,
            zIndex: 1000,
            height: 450,
            width: "100vw",
            backgroundColor: "#fff",
          }}
        >
          <IconButton style={{ float: "right", padding: 20 }} onClick={() => setIsMenuOpen(false)}>
            <svg width="25" height="25" fill="currentColor" className="bi bi-x-circle" viewBox="0 0 16 16">
              <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
              <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
            </svg>
          </IconButton>
          <div style={{ paddingLeft: 50, paddingTop: 20 }}>
            <CSStepper
              key={"mobileStepper"}
              activeStep={props.activeStep}
              onStateChanged={(stateName, value, fieldName) => {
                dispatch(
                  OrderActions.SetStepperStateRequest({
                    fieldName: fieldName,
                    value: value,
                  } as ISetStepState)
                );
                setIsMenuOpen(false);
              }}
              errorSections={reducerState.order.stepperState.errorSections}
            />
          </div>
        </div>
      )}
    </Grid>
  );
}
