import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { theme } from "../../../../../appTheme";
import CSFileUploader from "../../../../../components/FileUploader/CSFileUploader";
import { FileType, IUploadedFile } from "../../../../../components/FileUploader/types";
import { RootState } from "../../../../../store";
import { MaxFileSize } from "../../../../../Utils/enums/enums";
import { LabelKeys } from "../../../../../Utils/enums/labelKeys";
import { FieldNames } from "../../../../../Utils/types";
import { ResellerStyles } from "../../../styles";
import { IEditResellerReferenceDataState } from "../../../types";
import SnackbarActions from "../../../../../components/Snackbar/actions";
import { isNullOrEmpty } from "../../../../../Utils/extensions";
import { useMediaQuery, Grid, Typography, Button, Box, Tab } from "@mui/material";
import { useTranslation } from "react-i18next";
import CSTextField from "../../../../../components/Input/Textfield/CSTextField";
import { TabContext, TabList, TabPanel } from "@mui/lab";

interface IEditResellerReferenceData {
  onSaveLogotype: (data: IUploadedFile) => void;
  onSaveHomePageImage: (data: IUploadedFile) => void;
  onSaveHomePageTexts: (homePageheader: string, homePageBody: string) => void;
  onDeleteLogotype: () => void;
  onDeleteHomePageImage: () => void;
  onClose: () => void;
}

export default function EditResellerReferenceData(props: IEditResellerReferenceData) {
  const { t: translate } = useTranslation();
  const classes = ResellerStyles();
  const dispatch = useDispatch();
  const isMobile = useMediaQuery(theme.breakpoints.only("xs"));
  const isSm = useMediaQuery(theme.breakpoints.only("sm"));
  const homePageHeaderTextMaxLength = 50;
  const homePageBodyTextMaxLength = 200;

  const reducerState = useSelector(
    (s: RootState) => {
      return {
        Reseller: s.reseller,
      };
    },
    (prev, next) =>
      prev.Reseller.isLoading === next.Reseller.isLoading &&
      prev.Reseller.selectedAdminResellerRow === next.Reseller.selectedAdminResellerRow &&
      prev.Reseller.homePageImageUrl === next.Reseller.homePageImageUrl &&
      prev.Reseller.logotypeAsBase64 === next.Reseller.logotypeAsBase64 &&
      prev.Reseller.homePageHeaderText === next.Reseller.homePageHeaderText &&
      prev.Reseller.homePageBodyText === next.Reseller.homePageBodyText
  );

  const [editResellerReferenceDataState, setEditResellerReferenceDataState] = useState({
    logotypeAsBase64: reducerState.Reseller.logotypeAsBase64,
    logotypeFileType: reducerState.Reseller.logotypeFileType,
    homePageImageAsBase64: reducerState.Reseller.homePageImageUrl,
    homePageImageFileType: reducerState.Reseller.homePageImageFileType,
    homePageHeaderText: reducerState.Reseller.homePageHeaderText ?? "",
    homePageBodyText: reducerState.Reseller.homePageBodyText ?? "",
  } as IEditResellerReferenceDataState);

  function handleFieldOnChange(fieldName: FieldNames, value: string | FileType) {
    setEditResellerReferenceDataState((state) => ({
      ...state,
      [fieldName]: value,
    }));
  }

  function handleOnLogotypeFileUpload(e: IUploadedFile | React.ChangeEvent<HTMLInputElement>) {
    const file = e as IUploadedFile;
    handleFieldOnChange(FieldNames.logotypeAsBase64, file.base64);
    handleFieldOnChange(FieldNames.logotypeFileType, file.type);

    if (!isNullOrEmpty(file?.base64) && file.type != FileType.unknown) {
      props.onSaveLogotype(file);
    } else {
      dispatch(
        SnackbarActions.ShowError(`${translate(LabelKeys.InvalidLogoFileFormat)} ${translate(LabelKeys.AllowedFileFormatResellerLogo)}`)
      );
    }
  }

  function handleOnHomePageImageFileUpload(e: IUploadedFile | React.ChangeEvent<HTMLInputElement>) {
    const file = e as IUploadedFile;
    handleFieldOnChange(FieldNames.homePageImageAsBase64, file.base64);
    handleFieldOnChange(FieldNames.homePageImageFileType, file.type);

    if (!isNullOrEmpty(file?.base64) && file.type != FileType.unknown) {
      props.onSaveHomePageImage(file);
    } else {
      dispatch(
        SnackbarActions.ShowError(
          `${translate(LabelKeys.InvalidHomePageImageFileFormat)} ${translate(LabelKeys.AllowedFileFormatResellerHomePageImage)}`
        )
      );
    }
  }

  function handleDeleteLogotype() {
    handleFieldOnChange(FieldNames.logotypeAsBase64, "");
    handleFieldOnChange(FieldNames.logotypeFileType, FileType.unknown);
    props.onDeleteLogotype();
  }

  function handleDeleteHomePageImage() {
    handleFieldOnChange(FieldNames.homePageImageAsBase64, "");
    handleFieldOnChange(FieldNames.homePageImageFileType, FileType.unknown);
    props.onDeleteHomePageImage();
  }

  function handleSaveHomePageTexts() {
    props.onSaveHomePageTexts(editResellerReferenceDataState.homePageHeaderText, editResellerReferenceDataState.homePageBodyText);
  }

  useEffect(() => {}, [reducerState.Reseller.logotypeAsBase64, reducerState.Reseller.homePageImageUrl]);

  const [value, setValue] = React.useState("1");

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  return (
    <TabContext value={value}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <TabList
          orientation={isMobile ? "vertical" : "horizontal"}
          textColor="secondary"
          indicatorColor="secondary"
          onChange={handleChange}
          style={{ width: isMobile ? "100%" : "80%" }}
        >
          <Tab label={translate(LabelKeys.HomepageImage)} value="1" />
          <Tab label={translate(LabelKeys.Logo)} value="2" />
          <Tab label={translate(LabelKeys.Texts)} value="3" />
        </TabList>
      </Box>
      <TabPanel value="1">
        <Grid
          id="editResellerHomePageImageContainer"
          container
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          style={{ paddingRight: isMobile ? 0 : 20, height: "70vh" }}
        >
          <Grid
            container
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            className={classes.allowedFileFormatInfo}
            style={{ marginTop: isMobile ? 30 : 10 }}
            alignContent="flex-start"
          >
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ height: 30 }}>
              <Typography variant="h3">{translate(LabelKeys.HomepageImage)}</Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ marginTop: 15 }}>
              {editResellerReferenceDataState.homePageImageAsBase64?.length > 0 ? (
                <img width="100%" src={editResellerReferenceDataState.homePageImageAsBase64} />
              ) : (
                translate(LabelKeys.NoUploadedHomePageImage)
              )}
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ marginTop: 20, height: "auto" }}>
              <Typography
                align={isMobile ? "center" : "left"}
                style={{
                  whiteSpace: "pre-line",
                  fontSize: "0.85rem",
                }}
              >
                {translate(LabelKeys.AllowedFileFormatResellerHomePageImage)}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ height: "auto" }}>
              <Typography
                align={isMobile ? "center" : "left"}
                style={{
                  whiteSpace: "pre-line",
                  fontSize: "0.85rem",
                }}
              >
                {translate(LabelKeys.AllowedFileTypesResellerHomePageImage)}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ height: "auto" }}>
              <Typography
                align={isMobile ? "center" : "left"}
                style={{
                  whiteSpace: "pre-line",
                  fontSize: "0.85rem",
                }}
              >
                {translate(LabelKeys.AllowedFileMaxSizeResellerHomePageImage)}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            justifyContent="flex-start"
            style={{ height: isMobile ? "auto" : 56, paddingBottom: isMobile ? 30 : 0, paddingTop: isMobile ? 50 : 0 }}
          >
            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
              <CSFileUploader
                classNameRoot={classes.fileUploader}
                key={"resellerHomePageUploader"}
                onChange={(e) => {
                  handleOnHomePageImageFileUpload(e);
                }}
                isCSVFile={false}
                btnHeight={56}
                btnWidth={"100%"}
                isImageUploaded={true}
                btnTitle={translate(LabelKeys.Upload)}
                fileTypes=".jpeg,.jpg,.png"
                maxFileSizeInBytes={MaxFileSize.FiveMB}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6} xl={6} style={{ marginTop: isMobile ? 10 : 0, paddingLeft: isMobile ? 0 : 8 }}>
              <Button
                id="deleteHomePageImageBtn"
                style={{
                  height: 56,
                  width: "100%",
                  cursor: "pointer",
                  color: "#fff",
                  borderRadius: 0,
                  backgroundColor: "#000",
                }}
                onClick={() => handleDeleteHomePageImage()}
              >
                <div style={{ margin: "auto", textTransform: "none" }}>{`${translate(LabelKeys.Delete)}`}</div>
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </TabPanel>
      <TabPanel value="2">
        <Grid id="editResellerLogoContainer" container item xs={12} sm={12} md={12} lg={12} xl={12} style={{ height: "70vh" }}>
          <Grid
            container
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            className={classes.allowedFileFormatInfo}
            style={{ marginTop: isMobile ? 30 : 10 }}
            alignContent="flex-start"
          >
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ height: 30 }}>
              <Typography variant="h3">{translate(LabelKeys.Logo)}</Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ marginTop: 15 }}>
              {editResellerReferenceDataState.logotypeAsBase64?.length > 0 ? (
                <img width={isMobile ? "100%" : "50%"} src={editResellerReferenceDataState.logotypeAsBase64 ?? ""} />
              ) : (
                translate(LabelKeys.NoUploadedLogo)
              )}
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ marginTop: 20 }}>
              <Typography
                align={"left"}
                style={{
                  whiteSpace: "pre-line",
                  fontSize: "0.85rem",
                }}
              >
                {translate(LabelKeys.AllowedFileFormatResellerLogo)}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Typography
                align={"left"}
                style={{
                  whiteSpace: "pre-line",
                  fontSize: "0.85rem",
                }}
              >
                {translate(LabelKeys.AllowedFileTypesResellerLogo)}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Typography
                align={"left"}
                style={{
                  whiteSpace: "pre-line",
                  fontSize: "0.85rem",
                }}
              >
                {translate(LabelKeys.AllowedFileMaxSizeResellerLogo)}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            style={{ paddingBottom: isMobile ? 10 : 0, paddingTop: isMobile ? 10 : 0 }}
          >
            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
              <CSFileUploader
                classNameRoot={classes.fileUploader}
                key={"resellerLogoUploader"}
                onChange={(e) => handleOnLogotypeFileUpload(e)}
                isCSVFile={false}
                btnHeight={56}
                btnWidth={"100%"}
                isImageUploaded={true}
                btnTitle={translate(LabelKeys.Upload)}
                fileTypes=".jpeg,.jpg,.png"
                maxFileSizeInBytes={MaxFileSize.OneMB}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6} xl={6} style={{ marginTop: isMobile ? 10 : 0, paddingLeft: isMobile ? 0 : 8 }}>
              <Button
                id="deleteLogotypeBtn"
                style={{
                  height: 56,
                  width: "100%",
                  cursor: "pointer",
                  color: "#fff",
                  borderRadius: 0,
                  backgroundColor: "#000",
                }}
                onClick={() => handleDeleteLogotype()}
              >
                <div style={{ margin: "auto", textTransform: "none" }}>{`${translate(LabelKeys.Delete)}`}</div>
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </TabPanel>
      <TabPanel value="3">
        <Grid container item xs={12} sm={12} md={12} lg={12} xl={12} style={{ height: "70vh" }} alignContent="flex-start">
          <Grid container item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Typography variant="h3" style={{ marginBottom: 10 }}>
              {translate(LabelKeys.HomePageHeaderText)}
            </Typography>
            <CSTextField
              id="homePageHeaderText"
              type="text"
              multiline
              rows={10}
              maxLength={homePageHeaderTextMaxLength}
              value={editResellerReferenceDataState.homePageHeaderText}
              onChange={(e: any) => handleFieldOnChange(FieldNames.homePageHeaderText, e.target.value)}
            />
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Typography variant="body2" align="right">
                {`${editResellerReferenceDataState.homePageHeaderText?.length}/${homePageHeaderTextMaxLength}`}
              </Typography>
            </Grid>
          </Grid>
          <Grid container item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Typography variant="h3" style={{ marginBottom: 10 }}>
              {translate(LabelKeys.HomePageBodyText)}
            </Typography>
            <CSTextField
              id="homePageBodyText"
              type="text"
              multiline
              rows={10}
              value={editResellerReferenceDataState.homePageBodyText}
              maxLength={homePageBodyTextMaxLength}
              onChange={(e: any) => handleFieldOnChange(FieldNames.homePageBodyText, e.target.value)}
            />
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Typography variant="body2" align="right">
                {`${editResellerReferenceDataState.homePageBodyText?.length}/${homePageBodyTextMaxLength}`}
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6} xl={6} style={{ marginTop: 20, paddingBottom: isMobile ? 30 : 0 }}>
            <Button
              id="saveHomePageTextsBtn"
              style={{
                height: 56,
                width: "100%",
                cursor: "pointer",
                color: "#fff",
                borderRadius: 0,
                backgroundColor: "#000",
              }}
              onClick={() => handleSaveHomePageTexts()}
            >
              <div style={{ margin: "auto", textTransform: "none" }}>{`${translate(LabelKeys.SaveTexts)}`}</div>
            </Button>
          </Grid>
        </Grid>
      </TabPanel>
    </TabContext>
  );
}
