import React from "react";
import { Dialog, DialogContent, DialogContentText, Typography, Button, IconButton } from "@mui/material";

interface IPopupProps {
  content?: JSX.Element;
  dialogTitle?: string;
  enableCloseIcon?: boolean;
  width?: number | string;
  onClose: () => void;
  onEscapeKeyDown: () => void;
}

export default function Popup(props: IPopupProps) {
  return (
    <Dialog
      open={true}
      PaperProps={{ style: { borderRadius: 0, minWidth: props.width ?? "90vw", backgroundColor: "#e3e1de" } }}
      onKeyDown={(e) => {
        if (e.key === "Escape") {
          props.onEscapeKeyDown();
        }
      }}
    >
      <DialogContent style={{ borderRadius: 0 }}>
        <DialogContentText>
          <Typography variant="h1">{props.dialogTitle}</Typography>
          {props.enableCloseIcon && (
            <IconButton
              aria-label="close"
              onClick={() => props.onClose()}
              sx={{
                position: "absolute",
                right: 25,
                top: 25,
                padding: 0,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <svg width="25" height="25" fill="currentColor" className="bi bi-x-circle" viewBox="0 0 16 16">
                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
              </svg>
            </IconButton>
          )}
        </DialogContentText>
        {props.content}
      </DialogContent>
    </Dialog>
  );
}
