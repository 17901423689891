import { createBrowserHistory } from "history";
export let customHistory = createBrowserHistory();

export type RouterParams = {
  id: string;
  claimcode: string;
  resellercode: string;
};

export enum LocalStorageKeys {
  localizationCode = "giftsbyvinga.localization",
  userSession = "userSession",
}
