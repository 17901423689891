import React from "react";
import { TextFieldStyles } from "./styles";
import clsx from "clsx";
import TextField from "@mui/material/TextField";
import { Typography } from "@mui/material";

interface ICSTextField {
  id?: string;
  autoComplete?: string;
  autoFocus?: boolean;
  placeholder?: string;
  label?: string;
  value?: string | number | Date;
  error?: boolean;
  errorMessage?: string;
  type?: "text" | "number" | "tel" | "email";
  defaultValue?: string | number | Date;
  helperText?: string;
  disabled?: boolean;
  multiline?: boolean;
  rows?: number;
  maxLength?: number;
  className?: string;
  required?: boolean;
  onChange?: (value: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  onBlur?: (value: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  onClick?: () => void;
  onEnterKeyDown?: () => void;
}

function CSTextField(props: ICSTextField) {
  const classes = TextFieldStyles();

  function getErrorMessage(): React.ReactNode {
    return (
      <Typography component={"span"} style={{ fontSize: "0.8rem", display: "flex" }}>
        <Typography component={"span"} style={{ fontSize: "0.8rem", marginLeft: -14 }}>
          <svg
            width="20"
            height="20"
            fill="currentColor"
            className="bi bi-exclamation-diamond-fill"
            viewBox="0 0 16 16"
            style={{
              color: "red",
              margin: 0,
              paddingTop: 5,
            }}
          >
            <path d="M9.05.435c-.58-.58-1.52-.58-2.1 0L.436 6.95c-.58.58-.58 1.519 0 2.098l6.516 6.516c.58.58 1.519.58 2.098 0l6.516-6.516c.58-.58.58-1.519 0-2.098L9.05.435zM8 4c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995A.905.905 0 0 1 8 4zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z" />
          </svg>
        </Typography>
        <Typography component={"span"} style={{ fontSize: "0.8rem", paddingTop: 7, paddingLeft: 5 }}>
          {props.helperText && props.helperText}
        </Typography>
      </Typography>
    );
  }

  return (
    <React.Fragment>
      <Typography variant="body2" style={{ textAlign: "left", paddingBottom: 14, fontWeight: "bold" }}>
        {props.label ? (props.required ? `${props.label}*` : `${props.label}`) : ""}
      </Typography>
      <TextField
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            props.onEnterKeyDown();
          }
        }}
        required={props.required}
        id={props.id}
        type={props.type}
        defaultValue={props.defaultValue && props.defaultValue}
        autoFocus={props.autoFocus}
        autoComplete={props.autoComplete}
        placeholder={props.placeholder}
        className={clsx(classes.root, props.className)}
        onChange={(e) => {
          if (e.target.value.match(/(\p{Emoji_Presentation}|\p{Extended_Pictographic})/gu)) {
            return;
          }
          props.onChange && props.onChange(e);
        }}
        size="small"
        sx={{
          borderRadius: 0,
          input: {
            "&::placeholder": {
              opacity: 0.6,
            },
          },
        }}
        onClick={() => props.onClick && props.onClick()}
        value={props.value && props.value}
        onBlur={(e) => props.onBlur && props.onBlur(e)}
        fullWidth
        disabled={props.disabled}
        multiline={props.multiline}
        variant="outlined"
        rows={props.rows}
        InputLabelProps={{
          style: { height: props?.multiline === true ? "auto" : 46 },
          shrink: true,
        }}
        InputProps={{
          style: { height: props?.multiline === true ? "auto" : 46, backgroundColor: "white" },
          classes: {
            input: classes.resize,
          },
        }}
        inputProps={{
          style: { height: props?.multiline === true ? "auto" : 46 },
          maxLength: props?.maxLength,
        }}
        error={props.error}
        helperText={props.error && getErrorMessage()}
      />
    </React.Fragment>
  );
}

export default CSTextField;
