export enum ImageKeys {
  HomePageHeaderDesktop = "0cae55cd-e934-4796-be81-d1c7a020a758",
  HomePageHeaderMobile = "b7e946c0-a908-4506-9a14-9592b227567a",
  DeliveryPageDesktop = "f2d3a917-108f-4211-8503-a20d37a3772f",
  ReceiverPageDesktop = "fa44863a-d965-4ba4-8c1a-285eb062155b",
  VHAmountPageDesktop = "48372cd2-6629-4123-ae72-2bb43f8a17ae",
  WhiteLogo = "04fa9bd5-b9d6-4c6c-84eb-c5991ff76661",
  BlackLogo = "f2aff769-fa0b-460d-9aba-17d6eac3bccc",
  HopePageDesktop1 = "81cc2fca-d6ae-4708-a367-e994f1c09f6f",
  HopePageDesktop2 = "64b8c169-def7-4d83-8a40-5188cb0fc839",
  HopePageDesktop3 = "13be3747-9bd6-413d-b1a8-70163815cc1d",
  HopePageDesktop4 = "e4982265-fc42-46bf-acaa-de257ab62718",
  HopePageDesktop5 = "5d7a84d0-472c-490f-80ca-48a366f3b940",
  HopePageDesktop6 = "e403bdd7-b789-442d-b177-7b4082e2afe4",
  HopePageDesktop7 = "97dabcdb-49c2-49a7-98bb-4679e22cab4d",
  EmailHeader = "b6193091-57de-462a-b670-43f6fd7cba48",
  PdfGmgInfo = "2dfe2e8a-4bce-47c7-a02a-0f7f84915964",
  PdfVhInfo = "ea10ec6c-4d2f-4b89-acc2-69c9408eb109",
  HowItWorksPage = "23326efd-1da7-411f-988e-a74c29c929cd",
  ContactPageDesktop = "df8b0319-6af3-4a5c-913c-6419f69cf518",
}

export enum LitiumFolderKeys {
  PhysicalCardCoverImages = "1439e08a-6cd7-49f7-b306-749d7a52d51d",
  CustomWebPageHeaderImages = "a44f1eac-8000-48d7-a130-d644b68a39d8",
  ImageSelectorImages = "104d705c-34bd-48d3-8aff-e68a17057516",
}

//Downloadable
export enum LitiumFileKeys {
  Documentation = "GiftsByVinga_Documentation_External.pdf",
}
