import React, { useState } from "react";
import { Button, Grid, Tooltip, Typography, useMediaQuery, Zoom } from "@mui/material";
import CSTextField from "../../../../../components/Input/Textfield/CSTextField";
import { IExternalSystem } from "../types";
import CSCheckbox from "../../../../../components/Input/Forms/CSCheckbox";
import { useTranslation } from "react-i18next";
import { LabelKeys } from "../../../../../Utils/enums/labelKeys";
import { FieldNames } from "../../../../../Utils/types";
import CSDatePicker from "../../../../../components/Input/DatePicker/CSDatePicker";
import { theme } from "../../../../../appTheme";

interface IAddEditExternalSystem {
  isAddMode: boolean;
  externalSystemDetails?: IExternalSystem;
  onSave: (externalSystem: IExternalSystem, isAddMode: boolean) => void;
  onClose: () => void;
}

export default function AddEditExternalSystem(props: IAddEditExternalSystem) {
  const [externalSystemDetailsState, setExternalSystemDetailsState] = useState({
    ...props.externalSystemDetails,
    isActive: props.isAddMode ? true : props.externalSystemDetails.isActive,
  } as IExternalSystem);
  const { t: translate } = useTranslation();
  const isMobile = useMediaQuery(theme.breakpoints.only("xs"));
  const [clicked, setClicked] = useState(false);

  function handleFieldOnChange(fieldName: FieldNames, value: IExternalSystem | boolean | string) {
    setExternalSystemDetailsState((state) => ({
      ...state,
      [fieldName]: value,
    }));
  }

  return (
    <Grid container item xs={12} sm={3} md={3} lg={3} xl={3}>
      {!props.isAddMode && (
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ marginTop: 20 }}>
          <CSTextField id="externalSystemId" disabled label={translate(LabelKeys.Id)} value={props.externalSystemDetails?.id} />
        </Grid>
      )}
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ marginTop: 20 }}>
        <CSTextField
          id="externalSystemName"
          defaultValue={externalSystemDetailsState.name}
          label={translate(LabelKeys.Name)}
          onChange={(e) => handleFieldOnChange(FieldNames.name, e.target.value)}
        />
      </Grid>
      {!props.isAddMode && (
        <Grid container item xs={12} sm={12} md={12} lg={12} xl={12} style={{ marginTop: 20 }}>
          <Grid item xs={10} sm={10} md={10} lg={10} xl={10}>
            <CSTextField
              id="externalSystemToken"
              defaultValue={externalSystemDetailsState.token}
              disabled
              label={translate(LabelKeys.Token)}
            />
          </Grid>
          <Grid id="copyExternalSystemToken" item xs={2} sm={2} md={2} lg={2} xl={2}>
            <div style={{ cursor: "pointer", marginLeft: 30, marginTop: 42 }}>
              <div
                onMouseEnter={() => setClicked(false)}
                onClick={() => {
                  navigator.clipboard.writeText(props.externalSystemDetails.token);
                  setClicked(true);
                }}
                style={{ position: "relative" }}
              >
                <Tooltip
                  leaveDelay={100}
                  arrow
                  title={
                    <div style={{ display: "flex" }}>
                      {!clicked ? (
                        <svg
                          width="20"
                          height="20"
                          fill="currentColor"
                          className="bi bi-clipboard"
                          viewBox="0 0 16 16"
                          style={{ paddingRight: 10 }}
                        >
                          <path d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1v-1z" />
                          <path d="M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h3zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3z" />
                        </svg>
                      ) : (
                        <svg
                          width="20"
                          height="20"
                          fill="currentColor"
                          className="bi bi-clipboard-check"
                          viewBox="0 0 16 16"
                          style={{ paddingRight: 10 }}
                        >
                          <path
                            fillRule="evenodd"
                            d="M10.854 7.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7.5 9.793l2.646-2.647a.5.5 0 0 1 .708 0z"
                          />
                          <path d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1v-1z" />
                          <path d="M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h3zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3z" />
                        </svg>
                      )}
                      <Typography style={{ color: "#fff" }}>
                        {!clicked ? translate(LabelKeys.CopyToClipboard) : translate(LabelKeys.Copied)}
                      </Typography>
                    </div>
                  }
                  TransitionComponent={Zoom}
                  placement="top"
                  style={{ position: "absolute" }}
                >
                  <div style={{ wordWrap: "break-word" }}>
                    <svg
                      width="20"
                      height="20"
                      fill="currentColor"
                      className="bi bi-clipboard"
                      viewBox="0 0 16 16"
                      style={{ paddingRight: 10 }}
                    >
                      <path d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1v-1z" />
                      <path d="M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h3zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3z" />
                    </svg>
                  </div>
                </Tooltip>
              </div>
            </div>
          </Grid>
        </Grid>
      )}
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ marginTop: 20 }}>
        <CSCheckbox
          id="externalSystemIsActive"
          label={translate(LabelKeys.IsActive)}
          checked={externalSystemDetailsState.isActive}
          onChange={(e) => handleFieldOnChange(FieldNames.isActive, e.target.checked)}
        />
      </Grid>
      {!props.isAddMode && (
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ marginTop: 20 }}>
          <CSDatePicker
            id="externalSystemCreatedDate"
            disabled
            defaultValue={externalSystemDetailsState.createdDate}
            label={translate(LabelKeys.CreatedDate)}
          />
        </Grid>
      )}
      <Grid item xs={12} sm={6} md={5} lg={6} xl={5} style={{ paddingTop: 31, paddingRight: isMobile ? 0 : 8 }}>
        <Button
          id="saveAddEditExternalSystemBtn"
          style={{
            height: 46,
            width: "100%",
            cursor: "pointer",
            color: "#f5f5f5",
            borderRadius: 0,
            backgroundColor: "#000",
          }}
          onClick={() => props.onSave(externalSystemDetailsState, props.isAddMode)}
        >
          <div style={{ margin: "auto", textTransform: "none" }}>{`${translate(LabelKeys.Save)}`}</div>
        </Button>
      </Grid>
      <Grid item xs={12} sm={6} md={5} lg={6} xl={5} style={{ paddingTop: 31, paddingLeft: isMobile ? 0 : 8 }}>
        <Button
          id="cancelAddEditExternalSystemBtn"
          style={{
            height: 46,
            width: "100%",
            cursor: "pointer",
            color: "#f5f5f5",
            borderRadius: 0,
            backgroundColor: "#000",
          }}
          onClick={() => props.onClose()}
        >
          <div style={{ margin: "auto", textTransform: "none" }}>{`${translate(LabelKeys.Cancel)}`}</div>
        </Button>
      </Grid>
    </Grid>
  );
}
