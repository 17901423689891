import React, { useEffect, useState } from "react";
import { Button, Grid, Typography, useMediaQuery } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import CSTextField from "../../../components/Input/Textfield/CSTextField";
import { RootState } from "../../../store";
import { LabelKeys } from "../../../Utils/enums/labelKeys";
import { FieldNames, GetCurrentLanguageAsText, UserSession } from "../../../Utils/types";
import { CoupledResellerOrder, IResellerAdminSettingsErrorState, IResellerAdminOrderState, ResellerAdminSubview } from "../types";
import { ResellerActions } from "../../../Modules/Reseller/actions";
import { ValidateDeliveryEmails, GetValuesAsArray } from "../../../Utils/extensions";
import {
  ICreateResellerContactRequest,
  IDeleteResellerContactRequest,
  IGetResellerAdminContactInfoRequest,
  IGetResellerContactsRequest,
  IGetResellerIncomingOrderEmailsRequest,
  IUpdateResellerContactRequest,
  RequestFrom,
} from "../../../Modules/Reseller/types";
import { LanguageCode } from "../../../Utils/enums/enums";
import ProcessCircle from "../../../components/ProcessCircle/ProcessCircle";
import EditableTable from "../../../components/Table/EditableTable";
import { IAddResellerContact, IResellerContactLine, searchResellerContactsHeadCells } from "../../Admin/types";
import SnackbarActions from "../../../components/Snackbar/actions";
import Popup from "../../../components/Popup/Popup";
import AddResellerContact from "../../Admin/SubViews/Reseller/Popups/AddResellerContact";
import DeleteResellerContact from "../../Admin/SubViews/Reseller/Popups/DeleteResellerContact";
import { LocalStorageKeys } from "../../../Utils/history/history";
import { theme } from "../../../appTheme";
import ResellerAdminMenu from "../ResellerAdminMenu";
import ResellerSidebar from "./ResellerSidebar";
import { ResellerAdminStyles } from "../styles";
import { useNavigate } from "react-router";
import { IsResellerAuth } from "../../../Utils/authentication";

export default function ResellerSettings() {
  const { t: translate } = useTranslation();
  const dispatch = useDispatch();
  const [isAddResellerContactDialogOpen, setIsAddResellerContactDialogOpen] = useState(false);
  const [isDeleteResellerContactDialogOpen, setIsDeleteResellerContactDialogOpen] = useState(false);
  const [resellerContactLineToDelete, setResellerContactLineToDelete] = useState({} as IResellerContactLine);
  const [orderState, setOrderState] = useState({
    coupledResellerOrder: [] as CoupledResellerOrder[],
  } as IResellerAdminOrderState);
  const [resellerAdminErrorState, setResellerAdminErrorState] = useState({
    resellerIncomingOrderEmails: false,
  } as IResellerAdminSettingsErrorState);
  const userSession = JSON.parse(localStorage.getItem(LocalStorageKeys.userSession)) as UserSession;
  const isMobile = useMediaQuery(theme.breakpoints.only("xs"));
  const isSm = useMediaQuery(theme.breakpoints.only("sm"));
  const isMd = useMediaQuery(theme.breakpoints.only("md"));
  const isLg = useMediaQuery(theme.breakpoints.only("lg"));
  const classes = ResellerAdminStyles();
  const navigate = useNavigate();

  const reducerState = useSelector(
    (s: RootState) => {
      return {
        reseller: s.reseller,
        authentication: s.authentication,
      };
    },
    (prev, next) => prev.reseller.isLoading === next.reseller.isLoading && prev.reseller.resellerContacts === next.reseller.resellerContacts
  );

  useEffect(() => {
    if (!IsResellerAuth()) {
      navigate(`/${GetCurrentLanguageAsText()}/${translate(LabelKeys.Reseller)}/${translate(LabelKeys.SignInUrl)}`);
    }
  }, [reducerState.authentication.hasBeenSignedOut]);

  function handleErrorFieldOnChange(fieldName: FieldNames, value: boolean) {
    setResellerAdminErrorState((state) => ({
      ...state,
      [fieldName]: value,
    }));
  }

  function handleDeleteResellerContact(row: IResellerContactLine): void {
    if (row == undefined || row?.id <= 0) {
      dispatch(SnackbarActions.ShowError(translate(LabelKeys.InvalidResellerContactId)));
      return;
    }

    dispatch(
      ResellerActions.DeleteResellerContactRequest({
        resellerContactId: row.id,
        resellerId: userSession.resellerId,
        requestFrom: RequestFrom.ResellerAdmin,
      } as IDeleteResellerContactRequest)
    );
    setIsDeleteResellerContactDialogOpen(false);
  }

  function GetPopupWidth(): string {
    if (isMobile) {
      return "90vw";
    } else if (isSm) {
      return "90vw";
    } else if (isMd) {
      return "70vw";
    } else if (isLg) {
      return "40vw";
    } else {
      return "30vw";
    }
  }

  function handleOpenConfirmDeleteResellerContact(row: IResellerContactLine): void {
    if (row == undefined || row?.id <= 0) {
      dispatch(SnackbarActions.ShowError(translate(LabelKeys.InvalidResellerContactId)));
      return;
    }

    setResellerContactLineToDelete(row);
    setIsDeleteResellerContactDialogOpen(true);
  }

  function handleConfirmEditedRowChanges(data: IResellerContactLine): void {
    dispatch(
      ResellerActions.UpdateResellerContactRequest({
        id: data.id,
        email: data.email,
        isActive: data.isActive,
        resellerId: userSession.resellerId,
        requestFrom: RequestFrom.ResellerAdmin,
      } as IUpdateResellerContactRequest)
    );
  }

  useEffect(() => {
    dispatch(
      ResellerActions.GetResellerAdminContactInfoRequest({
        language: LanguageCode.SE,
      } as IGetResellerAdminContactInfoRequest)
    );

    dispatch(
      ResellerActions.GetResellerContactsRequest({
        resellerId: userSession.resellerId,
      } as IGetResellerContactsRequest)
    );
  }, []);

  useEffect(() => {
    if (reducerState.reseller.adminResellerContactInfo?.resellerId > 0) {
      dispatch(
        ResellerActions.GetResellerIncomingOrderEmailsRequest({
          resellerId: reducerState.reseller.adminResellerContactInfo?.resellerId,
        } as IGetResellerIncomingOrderEmailsRequest)
      );
    }
  }, [reducerState.reseller.adminResellerContactInfo]);

  useEffect(() => {
    if (reducerState.reseller.adminResellerIncomingOrderEmails?.length > 0) {
      const value = reducerState.reseller.adminResellerIncomingOrderEmails.join(",").replaceAll(",", "\n");
      handleFieldOnChange(FieldNames.resellerIncomingOrderEmails, value);
    }
  }, [reducerState.reseller.adminResellerIncomingOrderEmails]);

  function handleSaveResellerIncomingOrderEmails(): void {
    const valid = ValidateDeliveryEmails(orderState.resellerIncomingOrderEmails);
    if (valid) {
      handleErrorFieldOnChange(FieldNames.resellerIncomingOrderEmails, false);
      dispatch(
        ResellerActions.UpdateResellerIncomingOrderEmailsRequest({
          resellerId: reducerState.reseller.adminResellerContactInfo.resellerId,
          emails: GetValuesAsArray(orderState.resellerIncomingOrderEmails),
        })
      );
    } else {
      handleErrorFieldOnChange(FieldNames.resellerIncomingOrderEmails, true);
    }
  }

  function handleAddResellerContactSave(data: IAddResellerContact): void {
    dispatch(
      ResellerActions.CreateResellerContactRequest({
        resellerContacts: data.resellerContacts,
        resellerId: userSession.resellerId,
        requestFrom: RequestFrom.ResellerAdmin,
      } as ICreateResellerContactRequest)
    );
  }

  function handleFieldOnChange(fieldName: FieldNames, value: string) {
    setOrderState((state) => ({
      ...state,
      [fieldName]: value,
    }));
  }

  return (
    <div style={{ display: "flex" }}>
      {!isMobile && (
        <Grid item xs={2} sm={1} md={1} lg={1} xl={1}>
          <ResellerSidebar activeSubview={ResellerAdminSubview.Settings} />
        </Grid>
      )}
      <Grid container item xs={12} sm={12} md={12} lg={12} xl={12} justifyContent={"center"}>
        {reducerState.reseller?.isLoading && <ProcessCircle />}
        {isMobile && (
          <Grid item xs={11}>
            <ResellerAdminMenu />
          </Grid>
        )}
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className={classes.subviewContent}>
          <Grid id="resellerAdminSettingsContainer" container item xs={12} sm={12} md={8} lg={4} xl={4}>
            {reducerState.reseller?.isLoading && <ProcessCircle />}
            <Grid id="resellerIncomingOrderEmailsContainer" item xs={12} sm={12} md={10} lg={12} xl={12} style={{ height: "100%" }}>
              <Grid id="resellerIncomingOrderEmailsTitle" item xs={12} sm={12} md={12} lg={12} xl={12} style={{ height: "100%" }}>
                <Typography variant="h2" style={{ textAlign: "left" }}>
                  {translate(LabelKeys.SettingsIncomingOrdersTitle)}
                </Typography>
              </Grid>
              <Grid id="resellerIncomingOrderEmailsDescription" item xs={12} sm={12} md={12} lg={12} xl={12} style={{ marginTop: 20 }}>
                <Typography variant="body1" style={{ textAlign: "left" }}>
                  {translate(LabelKeys.SettingsIncomingOrdersDescription)}
                </Typography>
              </Grid>
              <Grid id="resellerIncomingOrderEmailsContainer" item xs={12} sm={12} md={12} lg={12} xl={12} style={{ marginTop: 30 }}>
                <CSTextField
                  id="resellerIncomingOrderEmails"
                  multiline={true}
                  rows={10}
                  type="email"
                  value={orderState.resellerIncomingOrderEmails}
                  placeholder={`${translate(LabelKeys.ReceiverEmails)}`}
                  onChange={(e) => {
                    handleFieldOnChange(FieldNames.resellerIncomingOrderEmails, e.target.value.replaceAll(" ", "").replaceAll(",", ""));
                  }}
                  error={resellerAdminErrorState.resellerIncomingOrderEmails}
                  helperText={translate(LabelKeys.InvalidEmailFormat)}
                />
              </Grid>
              <Grid container item xs={12} sm={12} md={12} lg={12} xl={12} style={{ marginTop: 20 }}>
                <Grid item xs={12} sm={6} md={5} lg={6} xl={6}>
                  <Button
                    id="Spara"
                    style={{
                      height: 56,
                      width: "100%",
                      cursor: "pointer",
                      color: "#fff",
                      display: "flex",
                      borderRadius: 0,
                      backgroundColor: "#000",
                    }}
                    onClick={() => handleSaveResellerIncomingOrderEmails()}
                  >
                    <div style={{ margin: "auto", textTransform: "none" }}>{`${translate(LabelKeys.Save)}`}</div>
                  </Button>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Typography align="left" variant="h3" style={{ paddingTop: 30 }}>
                  {translate(LabelKeys.Contacts)}
                </Typography>
              </Grid>
              <Grid
                id="resellerContactLines"
                container
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                style={{ paddingTop: 10, paddingBottom: 30, textAlign: "left" }}
              >
                <EditableTable
                  columns={searchResellerContactsHeadCells}
                  rows={reducerState.reseller.resellerContacts}
                  enableToolbar
                  enableAddMode
                  enableInlineEditMode
                  enableInlineDeleteMode
                  toolbarAddTooltipText={translate(LabelKeys.AddResellerContact)}
                  onToolbarAddClicked={() => setIsAddResellerContactDialogOpen(true)}
                  onInlineRowDeleteClicked={(row: IResellerContactLine) => handleOpenConfirmDeleteResellerContact(row)}
                  editableColumns={[FieldNames.email, FieldNames.isActive]}
                  onInlineConfirmRowChangesClicked={(row) => handleConfirmEditedRowChanges(row as IResellerContactLine)}
                  totalItems={reducerState.reseller.resellerContacts?.length}
                />
              </Grid>
            </Grid>
            {isAddResellerContactDialogOpen && (
              <Popup
                onEscapeKeyDown={() => {
                  setIsAddResellerContactDialogOpen(false);
                }}
                width={GetPopupWidth()}
                content={
                  <AddResellerContact
                    key={"addResellerContact"}
                    onSave={(data: IAddResellerContact) => handleAddResellerContactSave(data)}
                    onClose={() => setIsAddResellerContactDialogOpen(false)}
                  />
                }
                onClose={() => setIsAddResellerContactDialogOpen(false)}
                enableCloseIcon={true}
                dialogTitle={translate(LabelKeys.AddResellerContact)}
              />
            )}
            {isDeleteResellerContactDialogOpen && (
              <Popup
                onEscapeKeyDown={() => {
                  setIsDeleteResellerContactDialogOpen(false);
                }}
                width={GetPopupWidth()}
                content={
                  <DeleteResellerContact
                    key={"deleteResellerContact"}
                    resellerContact={resellerContactLineToDelete}
                    onSave={(data: IResellerContactLine) => handleDeleteResellerContact(data)}
                    onClose={() => setIsDeleteResellerContactDialogOpen(false)}
                  />
                }
                onClose={() => setIsDeleteResellerContactDialogOpen(false)}
                enableCloseIcon={true}
                dialogTitle={translate(LabelKeys.DeleteResellerContact)}
              />
            )}
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}
