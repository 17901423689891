import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import { useDispatch, useSelector } from "react-redux";
import ProcessCircle from "../../../../components/ProcessCircle/ProcessCircle";
import { ResellerActions } from "../../../../Modules/Reseller/actions";
import { ISearchResellersRequest, ISearchSingleResellerRequest } from "../../../../Modules/Reseller/types";
import { RootState } from "../../../../store";
import { FieldNames } from "../../../../Utils/types";
import { AdminSubview, IResellerRow, ICustomResellerRow, searchResellersHeadCells, IReseller } from "../../types";
import CSReadOnlyTable from "../../../../components/Table/CSReadOnlyTable";
import { Button, Typography, useMediaQuery } from "@mui/material";
import CSTextField from "../../../../components/Input/Textfield/CSTextField";
import { LabelKeys } from "../../../../Utils/enums/labelKeys";
import { useTranslation } from "react-i18next";
import { AdminStyles, ResellerStyles } from "../../styles";
import CSToolbar from "../../../../components/Toolbar/CSToolbar";
import Popup from "../../../../components/Popup/Popup";
import AddReseller from "./Popups/AddReseller";
import { GetValuesAsArray } from "../../../../Utils/extensions";
import ResellerDetailsPopup from "./Popups/ResellerDetailsPopup";
import { theme } from "../../../../appTheme";
import AdminMenu from "../../../../components/Menu/AdminMenu";
import AdminSidebar from "../../AdminSidebar";
import Cookies from "js-cookie";
import { AuthenticationActions } from "../../../../Modules/Authentication/actions";
import { ISetSignedInUserEmailRequest } from "../../../../Modules/Authentication/types";
import { CookieConstants } from "../../../../Utils/Labels/types";
import jwt_decode from "jwt-decode";

interface IAdminReseller {
  onChangeSubviewTriggered?: (subview: AdminSubview) => void;
}

function AdminReseller(props: IAdminReseller) {
  const dispatch = useDispatch();
  const { t: translate } = useTranslation();
  const classes = ResellerStyles();
  const classes2 = AdminStyles();
  const [currentTablePage, setCurrentTablePage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [adminState, setAdminState] = useState({
    searchParameter: "",
    resellerDetailsPopupOpen: false,
  });

  const [isAddResellerDialogOpen, setIsAddResellerDialogOpen] = useState(false);
  const isMobile = useMediaQuery(theme.breakpoints.only("xs"));
  const isSm = useMediaQuery(theme.breakpoints.only("sm"));
  const isMd = useMediaQuery(theme.breakpoints.only("md"));
  const isLg = useMediaQuery(theme.breakpoints.only("lg"));

  const reducerState = useSelector(
    (s: RootState) => {
      return {
        reseller: s.reseller,
        authentication: s.authentication,
      };
    },
    (prev, next) =>
      prev.reseller.isLoading === next.reseller.isLoading &&
      prev.reseller.adminResellerRows === next.reseller.adminResellerRows &&
      prev.reseller.selectedAdminResellerRow === next.reseller.selectedAdminResellerRow &&
      prev.reseller.adminResellerContactInfo === next.reseller.adminResellerContactInfo
  );

  useEffect(() => {
    dispatch(ResellerActions.SearchResellersRequest({ searchParameter: "", isActive: false } as ISearchResellersRequest));

    if (reducerState.authentication.signedInUserEmail?.length <= 0) {
      const jwt = Cookies.get(CookieConstants.GBVJwt);
      if (jwt == undefined) return;

      const decodedJwt = jwt_decode(jwt) as any;
      if (decodedJwt == undefined) return;
      if (decodedJwt?.Email?.length > 0) {
        dispatch(AuthenticationActions.SetSignedInUserEmail({ email: decodedJwt?.Email } as ISetSignedInUserEmailRequest));
      }
    }
  }, []);

  useEffect(() => {
    const fromDate = new Date();
    fromDate.setDate(new Date().getDate() - 60);
    dispatch(
      ResellerActions.SearchResellersRequest({
        searchParameter: adminState.searchParameter,
        isActive: false,
        skip: currentTablePage == 0 ? 0 : currentTablePage * rowsPerPage,
        take: rowsPerPage,
      })
    );
  }, [currentTablePage]);

  useEffect(() => {
    const fromDate = new Date();
    fromDate.setDate(new Date().getDate() - 60);
    dispatch(
      ResellerActions.SearchResellersRequest({
        searchParameter: adminState.searchParameter,
        isActive: false,
        skip: 0,
        take: rowsPerPage,
      })
    );
  }, [rowsPerPage]);

  function handleFieldOnChange(fieldName: FieldNames, value: string | Date | boolean | number | undefined) {
    setAdminState((state) => ({
      ...state,
      [fieldName]: value,
    }));
  }

  function GetPopupWidth(): string {
    if (isMobile) {
      return "90vw";
    } else if (isSm) {
      return "90vw";
    } else if (isMd) {
      return "70vw";
    } else if (isLg) {
      return "40vw";
    } else {
      return "20vw";
    }
  }

  function handleSearchedResellerClicked(selectedRow: ICustomResellerRow): void {
    if (selectedRow) dispatch(ResellerActions.SearchSingleResellerRequest({ resellerId: selectedRow.id } as ISearchSingleResellerRequest));
    setAdminState((state) => ({
      ...state,
      ["resellerDetailsPopupOpen"]: true,
    }));
  }

  function handleSearchResellers() {
    dispatch(
      ResellerActions.SearchResellersRequest({
        searchParameter: adminState.searchParameter,
        isActive: false,
        skip: 0,
        take: rowsPerPage,
      })
    );
  }

  function onAddResellerClicked(): void {
    setIsAddResellerDialogOpen(true);
  }

  function handleAddResellerSave(reseller: IReseller): void {
    dispatch(
      ResellerActions.CreateResellerRequest({
        companyName: reseller.companyName,
        customerNumber: reseller.customerNumber,
        discount: reseller.discount,
        emails: GetValuesAsArray(reseller.contactEmails),
      })
    );
    setIsAddResellerDialogOpen(false);
  }

  function GetTableBodyData(searchedResellers: IResellerRow[]): ICustomResellerRow[] {
    let customResellers: ICustomResellerRow[] = [];

    if (searchedResellers?.length > 0) {
      searchedResellers.map((item) => {
        const reseller = {
          id: item.id,
          companyName: item.companyName,
          customerNumber: item.customerNumber,
          code: item.code,
        };
        customResellers.push(reseller);
      });
    }
    return customResellers;
  }

  return (
    <div style={{ display: "flex" }}>
      {!isMobile && (
        <Grid item xs={2} sm={1} md={1} lg={1} xl={1}>
          <AdminSidebar activeSubview={AdminSubview.Reseller} />
        </Grid>
      )}
      {reducerState.authentication?.isLoading && <ProcessCircle />}
      <Grid container item xs={12} sm={12} md={12} lg={12} xl={12} justifyContent={"center"}>
        {isMobile && (
          <Grid item xs={11}>
            <AdminMenu />
          </Grid>
        )}
        <Grid item xs={11} sm={11} md={11} lg={11} xl={11} className={classes2.subviewContent} style={{ paddingTop: isMobile ? 0 : 50 }}>
          <Grid container item justifyContent="center" xs={12} sm={12} md={12} lg={12} xl={12}>
            {reducerState.reseller?.isLoading && <ProcessCircle />}
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ paddingBottom: 10, float: "left" }}>
              <Typography variant="h2" style={{ paddingBottom: 10, float: "left" }}>
                {translate(LabelKeys.SearchResellerTitle)}
              </Typography>
            </Grid>
            <Grid container item xs={12} sm={12} md={12} lg={12} xl={12} className={classes.leftContent}>
              <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                <CSTextField
                  id="searchOrdersString"
                  label={translate(LabelKeys.SearchString)}
                  type="text"
                  placeholder={`${translate(LabelKeys.SearchResellerPlaceHolder)}`}
                  onChange={(e) => handleFieldOnChange(FieldNames.searchParameter, e.target.value)}
                  onEnterKeyDown={() => handleSearchResellers()}
                />
              </Grid>
              <Grid item xs={12} sm={3} md={3} lg={2} xl={2} style={{ paddingTop: 31 }} className={classes.searchResellerBtn}>
                <Button
                  id="searchResellersBtn"
                  style={{
                    height: 46,
                    width: "100%",
                    cursor: "pointer",
                    color: "#fff",
                    borderRadius: 0,
                    backgroundColor: "#000",
                  }}
                  onClick={() => handleSearchResellers()}
                >
                  <div style={{ margin: "auto", textTransform: "none" }}>{`${translate(LabelKeys.SearchResellersBtn)}`}</div>
                </Button>
              </Grid>
              <Grid item xs={12} sm={3} md={3} lg={4} xl={4} className={classes.toolbar}>
                <CSToolbar
                  className={classes.toolbar2}
                  enableAdd
                  iconSize={30}
                  addTooltipText={translate(LabelKeys.AddReseller)}
                  onAddClicked={() => onAddResellerClicked()}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ paddingTop: 20 }}>
                <CSReadOnlyTable
                  columns={searchResellersHeadCells}
                  showTimeWithDate={true}
                  rows={GetTableBodyData(reducerState.reseller.adminResellerRows)}
                  onRowClick={(e, selectedRow, selectedRows) => handleSearchedResellerClicked(selectedRow)}
                  enableRowClick={true}
                  maxHeight={600}
                  totalItems={reducerState.reseller.adminResellerRowsTotalItems}
                  enablePagination
                  rowsPerPage={rowsPerPage}
                  currentPage={currentTablePage}
                  onCurrentPageChanged={(e, page) => setCurrentTablePage(page)}
                  onRowsPerPageChanged={(e) => {
                    setRowsPerPage(Number(e.target.value));
                    setCurrentTablePage(0);
                  }}
                />
              </Grid>
            </Grid>
            {isAddResellerDialogOpen && (
              <Popup
                onEscapeKeyDown={() => {
                  setIsAddResellerDialogOpen(false);
                }}
                width={GetPopupWidth()}
                content={
                  <AddReseller onSave={(e: IReseller) => handleAddResellerSave(e)} onClose={() => setIsAddResellerDialogOpen(false)} />
                }
                onClose={() => setIsAddResellerDialogOpen(false)}
                enableCloseIcon={true}
                dialogTitle={translate(LabelKeys.AddReseller)}
              />
            )}
            {adminState.resellerDetailsPopupOpen && (
              <ResellerDetailsPopup
                onGoBackToResellerPageClicked={(x) =>
                  setAdminState((state) => ({
                    ...state,
                    ["resellerDetailsPopupOpen"]: x,
                  }))
                }
              />
            )}
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}

export default AdminReseller;
