import makeStyles from "@mui/styles/makeStyles";
import { theme } from "../../appTheme";

export const RouteStyles = makeStyles({
  containerTransparent: {
    position: "absolute",
    zIndex: 100,
    width: "100%",
  },
  container: {
    width: "100%",
    backgroundColor: "#e3e1de",
  },
  logoContainer: { width: "auto" },
  logo: {
    // height: 100,
    // width: 200,
    paddingTop: 15,
    objectFit: "contain",
    objectPosition: "center",
    [theme.breakpoints.only("xs")]: {
      margin: "auto",
      float: "left",
      paddingTop: 26,
      paddingLeft: 16,
    },
  },
  linkBar: {
    listStyleType: "none",
    listStyle: "none",
    margin: "auto",
    paddingLeft: 0,
  },
  linksTransparent: {
    color: "#fff",
    width: "auto",
    textDecoration: "none",
    marginLeft: 30,
    marginRight: 30,
    fontSize: "1.2rem",
    fontFamily: "Mulish",
    textTransform: "none",
    margin: "auto",
    [theme.breakpoints.only("sm")]: {
      marginLeft: 15,
      marginRight: 15,
      fontSize: "0.9rem",
    },
  },
  links: {
    color: "#000",
    width: "auto",
    textDecoration: "none",
    marginLeft: 30,
    marginRight: 30,
    fontSize: "1.2rem",
    fontFamily: "Mulish",
    textTransform: "none",
    margin: "auto",
    [theme.breakpoints.only("sm")]: {
      marginLeft: 15,
      marginRight: 15,
      fontSize: "0.9rem",
    },
  },
  menubar: {
    height: 0,
    width: 0,
    visibility: "hidden",
    [theme.breakpoints.down("md")]: {
      visibility: "visible",
      height: "auto",
      width: 40,
      paddingTop: 35,
      paddingRight: 25,
    },
  },
  linkbarContainer: {
    paddingTop: 50,
    visibility: "visible",
    zIndex: 10,
    [theme.breakpoints.down("sm")]: {
      visibility: "hidden",
      height: 0,
      width: 0,
    },
  },
  menuflagbar: {
    visibility: "visible",
    [theme.breakpoints.down("md")]: {
      visibility: "hidden",
      height: 0,
      width: 0,
    },
  },
  development: {
    backgroundColor: "green",
    height: 50,
    textAlign: "center",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  test: {
    backgroundColor: "yellow",
    height: 50,
    textAlign: "center",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  production: { visibility: "hidden", height: 0 },
  customerTypeTransparent: {
    display: "flex",
    border: "1px solid #fff",
    height: 48,
    width: 115,
    marginTop: 38,
    //right: 57,
    cursor: "pointer",
  },
  customerType: {
    display: "flex",
    border: "1px solid #000",
    height: 48,
    width: 115,
    marginTop: 38,
    //right: 57,
    cursor: "pointer",
  },
  resellerTransparent: {
    display: "flex",
    height: 48,
    marginTop: 38,
    //right: 57,
    cursor: "pointer",
  },
  reseller: {
    display: "flex",
    height: 48,
    marginTop: 38,
    //right: 57,
    cursor: "pointer",
  },
});
