import { Country, LanguageCode } from "./../../Utils/enums/enums";
import { IProduct } from "../../Modules/Products/types";
import { ICountryOption, IFilterOption } from "../DropDown/types";

export interface ISampleAssortment {
  imageUrl: string;
  redirectUrl: string;
  title: string;
}

export interface ICSNextPrevStepMobileProps {
  activeStep: number;
  onNextStepClicked: () => void;
  onPrevStepClicked: () => void;
}

export interface ICSNextPrevStepDesktopProps {
  activeStep: number;
  onNextStepClicked: () => void;
  onPrevStepClicked: () => void;
}

export interface ICustomProduct extends IProduct {
  amount: number;
}

export const AdminResellerCountries = [
  {
    id: 1,
    name: "Sverige",
    value: LanguageCode.SE,
    country: Country.SE,
  },
  {
    id: 2,
    name: "Finland",
    value: LanguageCode.FI,
    country: Country.FI,
  },
  {
    id: 3,
    name: "Norge",
    value: LanguageCode.NO,
    country: Country.NO,
  },
] as ICountryOption[];
