import React, { useEffect } from "react";
import Typography from "@mui/material/Typography";
import { CheckSystemPartCookie } from "../../Utils/authentication";

function SomethingWentWrong() {
  useEffect(() => {
    CheckSystemPartCookie();
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="text-center">
      <Typography variant="h1">{`Ops... something went wrong!`}</Typography>
      <Typography variant="body1">{`If the error proceeds, please contact support.`}</Typography>
    </div>
  );
}

export default SomethingWentWrong;
