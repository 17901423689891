import makeStyles from "@mui/styles/makeStyles";
import { theme } from "../../appTheme";

export const MessageStyles = makeStyles({
  editor: { backgroundColor: "#fff" },
  checkbox: {
    [theme.breakpoints.down("sm")]: {
      paddingTop: 25,
    },
    [theme.breakpoints.up("sm")]: {
      paddingTop: 25,
    },
  },
  textfield: {
    [theme.breakpoints.down("sm")]: {
      paddingTop: 20,
    },
    [theme.breakpoints.up("sm")]: {
      paddingTop: 20,
    },
  },
  messageBox: {
    [theme.breakpoints.down("sm")]: {
      paddingTop: 20,
    },
    [theme.breakpoints.up("sm")]: {
      paddingTop: 20,
    },
  },
  messageTags: { paddingTop: 20 },
  messageFieldsContainer: {
    [theme.breakpoints.only("xs")]: { paddingBottom: 0 },
    [theme.breakpoints.only("sm")]: { paddingBottom: 0 },
  },
  smsCharactersLeft: { textAlign: "left" },
  customMessage: {
    float: "left",
    "&.Mui-checked": {
      color: "#000",
    },
  },
  customMessageInner: {
    "&.Mui-checked": {
      color: "#000",
    },
  },
  textFieldBackground: { backgroundColor: "#fff" },
  preview: { backgroundColor: "#fff" },
  previewVariationInformation: { textAlign: "left", fontSize: "0.7rem" },
  previewContainer: {
    paddingTop: 30,
    [theme.breakpoints.down("sm")]: {
      paddingBottom: 30,
      paddingTop: 40,
    },
    [theme.breakpoints.only("md")]: {
      padding: 0,
      float: "left",
    },
  },
  selectedDeliveryOption: {},
  notSelectedDeliveryOption: {
    //opacity: 0.5,
  },
  selectedWhenToSendOption: {},
  notSelectedWhenToSendOption: {
    //opacity: 0.5,
  },
  receiverTooltip: {
    fontSize: "1.8rem",
    [theme.breakpoints.down("lg")]: {
      visibility: "hidden",
      height: 0,
    },
    [theme.breakpoints.up("lg")]: {
      visibility: "visible",
    },
  },
  receiverIconBtn: {
    [theme.breakpoints.down("lg")]: {
      visibility: "visible",
    },
    [theme.breakpoints.up("lg")]: {
      visibility: "hidden",
      height: 0,
    },
  },

  amountOfReceivers: {
    float: "right",
    paddingRight: 55,
    paddingTop: 6,
    [theme.breakpoints.only("xs")]: {
      paddingRight: 25,
      float: "left",
      paddingLeft: 10,
    },
    [theme.breakpoints.only("sm")]: {
      paddingRight: 55,
    },
    [theme.breakpoints.only("md")]: {
      paddingRight: 30,
    },
    [theme.breakpoints.only("lg")]: {
      paddingRight: 47,
    },
    [theme.breakpoints.only("xl")]: {
      paddingRight: 55,
    },
  },
  sendBySMSComponent: { paddingTop: 20 },
  sendByEmailComponent: { paddingTop: 20 },
  sendByEmailPDFComponent: { paddingTop: 20 },
  csInfoCustomMessage: {},
  csInfoTempaltes: { left: 0, paddingLeft: 10 },
});

export const PhysicalCardStyles = makeStyles({
  root: {
    position: "relative",
    transformStyle: "preserve-3d",
    transform: "rotateX(-10deg)",
    "&:hover": {
      "& $cardFrontContainer": { transform: "perspective(2500px) rotateY(-55deg) translateZ(3px)" },
      "& $insideFrontPageImage": {
        filter: "brightness(92%)",
      },
    },
  },
  cardFrontContainer: {
    position: "absolute",
    backgroundColor: "#fff",
    width: 350,
    height: 494,
    top: 39,
    overflow: "hidden",
    cursor: "pointer",
    transformOrigin: "left",
    transform: "perspective(2500px) rotateY(-45deg) translateZ(3px)",
    transformStyle: "preserve-3d",
    zIndex: 1,
    [theme.breakpoints.only("md")]: {
      left: 150,
    },
  },
  cardFrontCoverImage: {
    objectFit: "cover",
    objectPosition: "center",
    width: "100%",
    height: "100%",
  },
  cardInsideImageContainer: {
    position: "absolute",
    backgroundColor: "#ccc",
    transformStyle: "preserve-3d",
    left: 10,
    top: 40,
    width: 350,
    height: 494,
    cursor: "pointer",
    transform: "perspective(5000px) rotateY(20deg) translateZ(-65px)",
    boxShadow: "inset 0px 0px 100px rgba(0,0,0,0.2), 10px 0px 20px rgba(0,0,0,0.3)",
    zIndex: -1,
    [theme.breakpoints.only("md")]: {
      left: 160,
    },
  },
  insideFrontPageImage: { objectFit: "cover", objectPosition: "center", width: "100%", height: "100%", filter: "brightness(80%)" },
  rootMiddle: {
    position: "relative",
    transformStyle: "preserve-3d",
    transform: "rotateX(-10deg)",
    cursor: "pointer",
    height: 494,
    top: 40,
    "&:hover": {
      "& $cardFrontContainer": { transform: "perspective(2500px) rotateY(-55deg) translateZ(3px)" },
      "& $insideFrontPageImage": {
        filter: "brightness(92%)",
      },
    },
    [theme.breakpoints.only("md")]: {
      height: 395,
    },
  },
  cardMiddleLeft: {
    backgroundColor: "#fff",
    transformStyle: "preserve-3d",
    width: 350,
    height: 494,
    cursor: "pointer",
    "&:before": {
      content: '""',
      position: "absolute",
      left: 0,
      bottom: 0,
      borderStyle: "solid",
      width: 0,
      borderWidth: 0,
      borderColor: "#FFF #FFF #e3e1de #e3e1de",
      borderRadius: "0 0 0px 0",
      transition: "border-width .5s",
    },
    "&:hover": {
      "&:before": {
        borderWidth: 30,
        borderColor: "#FFF #FFF #e3e1de #e3e1de",
        boxShadow: "2px -2px 3px rgba(0,0,0,0.3)",
      },
      "&:after": {
        borderWidth: 0,
        boxShadow: "none",
      },
    },
    [theme.breakpoints.only("md")]: {
      height: 395,
      width: 280,
    },
  },
  cardMiddleRight: {
    backgroundColor: "#fff",
    transformStyle: "preserve-3d",
    width: 350,
    height: 494,
    cursor: "pointer",
    "&:before": {
      content: '""',
      position: "absolute",
      right: 0,
      bottom: 0,
      borderStyle: "solid",
      width: 0,
      borderWidth: 0,
      borderColor: "#fff #e3e1de #e3e1de #fff",
      borderRadius: "0 0 1px 0",
      transition: "border-width .5s",
    },
    "&:hover": {
      "&:before": {
        borderWidth: 30,
        borderColor: "#FFF #e3e1de #e3e1de #fff",
        boxShadow: "-2px -2px 3px rgba(0,0,0,0.3)",
      },
    },
    [theme.breakpoints.only("md")]: {
      height: 395,
      width: 280,
    },
  },
  cardMiddleLeftLogotype: { marginTop: 20, marginBottom: 20, left: 0, top: 0, width: "100%", position: "absolute" },
  rootBack: {
    display: "flex",
    justifyContent: "center",
    transformStyle: "preserve-3d",
    transform: "rotateX(-10deg)",
    height: 494,
    width: "40%",
    margin: "auto",
    "&:hover": {
      "& $cardBackImageContainer": { transform: "perspective(2500px) rotateY(55deg) translateZ(3px)" },
      "& $cardBackInsideContentContainer": {
        filter: "brightness(92%)",
      },
    },
  },
  cardBackInsideContentContainer: {
    position: "absolute",
    backgroundColor: "#fff",
    transformStyle: "preserve-3d",
    width: 350,
    height: 494,
    top: 60,
    cursor: "pointer",
    transform: "perspective(5000px) rotateY(-20deg) translateZ(-65px)",
    boxShadow: "inset 0px 0px 100px rgba(0,0,0,0.2), 10px 0px 20px rgba(0,0,0,0.3)",
    zIndex: -1,
    filter: "brightness(85%)",
    overflow: "hidden",
  },
  cardBackImageContainer: {
    position: "absolute",
    backgroundColor: "#fff",
    width: 350,
    height: 494,
    top: 60,
    marginLeft: 18,
    cursor: "pointer",
    overflow: "hidden",
    transformOrigin: "right",
    transform: "perspective(2500px) rotateY(45deg) translateZ(3px)",
    transformStyle: "preserve-3d",
    zIndex: 1,
  },

  noLogotypeSelected: { width: "100%", height: "100%", border: "1px dashed #000" },
  pageIconImage: {
    margin: "auto",
  },
  selectedPageIconImage: { margin: "auto" },
  showPageListItem: {
    paddingLeft: 10,
    paddingRight: 10,
    fontSize: "0.8rem",
    color: "#9d9fa6",
  },
  showPageSelectedListItem: {
    paddingLeft: 10,
    paddingRight: 10,
    fontSize: "0.8rem",
    position: "relative",
    "&:after": {
      content: '""',
      position: "absolute",
      background: "#fff",
      height: 25,
      width: 25,
      top: "calc(100% + 5px)",
      left: "50%",
      transform: "translateX(-50%) rotate(45deg)",
    },
  },
  physicalCardImageSelector: { width: "100%" },
  dropDownMenuRoot: {
    float: "left",
    width: "100%",
  },
  dropDownInner: {
    width: 180,
  },
  physicalCardHeadingSuggestionDropDown: { marginTop: 10 },
  physicalCardCustomMessageSuggestionDropDown: { marginTop: 10 },
});

export const MessageTagStyles = makeStyles({
  onHover: {
    border: "1px solid #000",
    "&:hover": {
      border: "1px solid orange",
    },
  },
});
