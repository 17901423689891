import React, { useState } from "react";
import { Tooltip, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { LabelKeys } from "../../Utils/enums/labelKeys";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import Zoom from "@mui/material/Zoom";
import { MessageTagStyles } from "./styles";
import CSInfo from "../Helper/CSInfo";

export default function MessageTags() {
  const { t: translate } = useTranslation();
  const [clicked, setClicked] = useState(false);
  const classes = MessageTagStyles();
  const [tooltipDialogIsOpen, setTooltipDialogIsOpen] = useState(false);

  return (
    <div>
      <div style={{ display: "flex", fontFamily: "Mulish" }}>
        <Typography id="tagText" variant="body1" style={{ textAlign: "left", fontWeight: "bold" }}>{`${translate(
          LabelKeys.Tags
        )}:`}</Typography>
        <div style={{ paddingLeft: 10, paddingTop: 3 }}>
          <CSInfo
            hoverTooltopText={translate(LabelKeys.MesageTagInfo)}
            popupTooltipText={translate(LabelKeys.MesageTagInfo)}
            tooltipDialogIsOpen={tooltipDialogIsOpen}
            onSetTooltipDialogIsOpen={(value) => setTooltipDialogIsOpen(value)}
          />
        </div>
      </div>
      <div style={{ paddingTop: 10, fontFamily: "Mulish" }}>
        <div style={{ display: "flex" }}>
          <Tooltip
            leaveDelay={100}
            arrow
            title={
              <div style={{ display: "flex" }}>
                <ContentCopyIcon fontSize="small" />
                <Typography style={{ paddingLeft: 2, color: "#fff" }}>
                  {!clicked ? translate(LabelKeys.CopyToClipboard) : translate(LabelKeys.Copied)}
                </Typography>
              </div>
            }
            TransitionComponent={Zoom}
            placement="top"
          >
            <div
              id="firstnameTag"
              onMouseEnter={() => setClicked(false)}
              style={{ cursor: "pointer", maxWidth: "100%" }}
              onClick={() => {
                navigator.clipboard.writeText("[first-name]");
                setClicked(true);
              }}
            >
              <div style={{ height: 31, width: 96, paddingTop: 3 }} className={classes.onHover}>
                <Typography variant="body2" style={{ paddingTop: 5 }}>
                  [first-name]
                </Typography>
              </div>
            </div>
          </Tooltip>
        </div>
      </div>
    </div>
  );
}
