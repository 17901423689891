import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import { useTranslation } from "react-i18next";
import { Button, Typography, useMediaQuery } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import CSDatePicker from "../../../../components/Input/DatePicker/CSDatePicker";
import CSTextField from "../../../../components/Input/Textfield/CSTextField";
import ProcessCircle from "../../../../components/ProcessCircle/ProcessCircle";
import { InvoiceActions } from "../../../../Modules/Invoice/actions";
import { RootState } from "../../../../store";
import { LabelKeys } from "../../../../Utils/enums/labelKeys";
import { GetValuesAsArray } from "../../../../Utils/extensions";
import { FieldNames } from "../../../../Utils/types";
import { ValidateEmail } from "../../../../Utils/validations";
import { AdminStyles } from "../../styles";
import { AdminSubview, IAdminInvoiceErrorState, IAdminInvoiceState } from "../../types";
import CSInfo from "../../../../components/Helper/CSInfo";
import { theme } from "../../../../appTheme";
import AdminMenu from "../../../../components/Menu/AdminMenu";
import AdminSidebar from "../../AdminSidebar";
import Cookies from "js-cookie";
import { AuthenticationActions } from "../../../../Modules/Authentication/actions";
import { ISetSignedInUserEmailRequest } from "../../../../Modules/Authentication/types";
import { CookieConstants } from "../../../../Utils/Labels/types";
import jwt_decode from "jwt-decode";

function AdminInvoice() {
  const classes = AdminStyles();
  const dispatch = useDispatch();
  const [adminState, setAdminState] = useState({
    createInvoiceFromDate: new Date(),
    createInvoiceToDate: new Date(),
    createInvoiceReceiverEmails: "",
    createInvoiceCustomerNumbers: "",
  } as IAdminInvoiceState);
  const [receiversTooltipDialogIsOpen, setReceiversTooltipDialogIsOpen] = useState(false);
  const isMobile = useMediaQuery(theme.breakpoints.only("xs"));
  const [adminErrorState, setAdminErrorState] = useState({
    createInvoice: false,
    createInvoiceFromDate: false,
    createInvoiceToDate: false,
    createInvoiceCustomerNumbers: false,
    createInvoiceReceiverEmails: false,
  } as IAdminInvoiceErrorState);
  const { t: translate } = useTranslation();

  const reducerState = useSelector(
    (s: RootState) => {
      return {
        invoice: s.invoice,
        authentication: s.authentication,
      };
    },
    (prev, next) => prev.invoice.isLoading === next.invoice.isLoading
  );

  useEffect(() => {
    if (reducerState.authentication.signedInUserEmail?.length <= 0) {
      const jwt = Cookies.get(CookieConstants.GBVJwt);
      if (jwt == undefined) return;

      const decodedJwt = jwt_decode(jwt) as any;
      if (decodedJwt == undefined) return;
      if (decodedJwt?.Email?.length > 0) {
        dispatch(AuthenticationActions.SetSignedInUserEmail({ email: decodedJwt?.Email } as ISetSignedInUserEmailRequest));
      }
    }
  }, []);

  function handleFieldOnChange(fieldName: FieldNames, value: string | Date | boolean | number | undefined) {
    setAdminState((state) => ({
      ...state,
      [fieldName]: value,
    }));
  }

  function handleSetErrorState(fieldName: FieldNames, value: string | boolean) {
    setAdminErrorState((state) => ({
      ...state,
      [fieldName]: value,
    }));
  }

  function handleCreateInvoices() {
    let isValid = ValidateAllFieldsCreateInvoices();
    if (!isValid) return;

    let emails = GetValuesAsArray(adminState.createInvoiceReceiverEmails);

    dispatch(
      InvoiceActions.CreateInvoiceBasisRequest({
        from: adminState.createInvoiceFromDate,
        to: adminState.createInvoiceToDate,
        receiverEmails: emails,
      })
    );
    handleFieldOnChange(FieldNames.createInvoiceCustomerNumbers, "");
    handleFieldOnChange(FieldNames.createInvoiceReceiverEmails, "");
    handleFieldOnChange(FieldNames.createInvoiceFromDate, new Date());
    handleFieldOnChange(FieldNames.createInvoiceToDate, new Date());
  }

  function ValidateAllFieldsCreateInvoices(): boolean {
    let isValid: boolean = true;

    if (!ValidateEmail(adminState.createInvoiceReceiverEmails)) {
      handleSetErrorState(FieldNames.createInvoiceReceiverEmails, true);
      isValid = false;
    } else {
      handleSetErrorState(FieldNames.createInvoiceReceiverEmails, false);
    }
    if (adminState.createInvoiceFromDate > adminState.createInvoiceToDate) {
      handleSetErrorState(FieldNames.createInvoiceFromDate, true);
      handleSetErrorState(FieldNames.createInvoiceToDate, true);
      isValid = false;
    } else {
      handleSetErrorState(FieldNames.createInvoiceFromDate, false);
      handleSetErrorState(FieldNames.createInvoiceToDate, false);
    }

    return isValid;
  }

  return (
    <div style={{ display: "flex" }}>
      {!isMobile && (
        <Grid item xs={2} sm={1} md={1} lg={1} xl={1}>
          <AdminSidebar activeSubview={AdminSubview.Invoice} />
        </Grid>
      )}
      {reducerState.authentication?.isLoading && <ProcessCircle />}
      <Grid container item xs={12} sm={12} md={12} lg={12} xl={12} justifyContent={"center"}>
        {isMobile && (
          <Grid item xs={11}>
            <AdminMenu />
          </Grid>
        )}
        <Grid item xs={11} sm={11} md={11} lg={11} xl={11} className={classes.subviewContent} style={{ paddingTop: isMobile ? 0 : 50 }}>
          <Grid container item justifyContent="center" xs={12} sm={12} md={12} lg={12} xl={12}>
            {reducerState.invoice?.isLoading && <ProcessCircle />}
            <Grid
              container
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              style={{
                paddingBottom: 30,
                textAlign: "left",
              }}
            >
              <Grid container item xs={12} sm={12} md={6} lg={6} xl={6}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ textAlign: "left" }}>
                  <Typography variant="h2">{translate(LabelKeys.CreateInvoiceBasis)}</Typography>
                </Grid>
                <Grid item xs={12} sm={5} md={5} lg={5} xl={5} className={classes.fromDateCreateInvoice}>
                  <CSDatePicker
                    id="fromDateCreateInvoice"
                    label={translate(LabelKeys.From)}
                    showToolbar={false}
                    fullWidth
                    value={adminState.createInvoiceFromDate}
                    onChange={(date) => {
                      handleFieldOnChange(FieldNames.createInvoiceFromDate, date);
                    }}
                    maxDate={adminState.createInvoiceToDate}
                    okLabel={translate(LabelKeys.Ok)}
                    cancelLabel={translate(LabelKeys.Cancel)}
                    error={adminErrorState.createInvoiceFromDate}
                    helperText={translate(LabelKeys.CreateInvoiceInvalidDateFormat)}
                  />
                </Grid>
                <Grid item xs={12} sm={5} md={5} lg={5} xl={5} className={classes.toDateCreateInvoice}>
                  <CSDatePicker
                    label={translate(LabelKeys.To)}
                    id="toDateCreateInvoice"
                    fullWidth
                    showToolbar={false}
                    value={adminState.createInvoiceToDate ? adminState.createInvoiceToDate : undefined}
                    onChange={(date) => {
                      handleFieldOnChange(FieldNames.createInvoiceToDate, date);
                    }}
                    minDate={adminState.createInvoiceFromDate}
                    okLabel={translate(LabelKeys.Ok)}
                    cancelLabel={translate(LabelKeys.Cancel)}
                    error={adminErrorState.createInvoiceToDate}
                    helperText={translate(LabelKeys.CreateInvoiceInvalidDateFormat)}
                  />
                </Grid>
                <Grid item xs={11} sm={10} md={10} lg={10} xl={10} style={{ textAlign: "left", paddingTop: 10 }}>
                  <CSTextField
                    id="receiverEmails"
                    type="email"
                    multiline={true}
                    rows={10}
                    value={adminState.createInvoiceReceiverEmails}
                    placeholder={`${translate(LabelKeys.ReceiverEmails)} *`}
                    onChange={(e) => {
                      let value = e.target.value.replaceAll(" ", "");
                      handleFieldOnChange(FieldNames.createInvoiceReceiverEmails, value);
                    }}
                    error={adminErrorState.createInvoiceReceiverEmails}
                    helperText={translate(LabelKeys.InvalidEmailFormat)}
                  />
                </Grid>
                <Grid item xs={1} md={1} style={{ margin: "auto", paddingTop: 15 }}>
                  <CSInfo
                    hoverTooltopText={translate(LabelKeys.ReceiverEmailTooltip)}
                    popupTooltipText={translate(LabelKeys.ReceiverEmailTooltip)}
                    tooltipDialogIsOpen={receiversTooltipDialogIsOpen}
                    onSetTooltipDialogIsOpen={(value) => setReceiversTooltipDialogIsOpen(value)}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4} xl={4} style={{ paddingTop: "10px", float: "left" }}>
                  <Button
                    id="createInvoice"
                    style={{
                      height: "100%",
                      width: "100%",
                      cursor: "pointer",
                      color: "#fff",
                      display: "flex",
                      marginTop: 20,
                      borderRadius: 0,
                      backgroundColor: "#000",
                    }}
                    onClick={() => handleCreateInvoices()}
                  >
                    <div style={{ margin: "auto", textTransform: "none" }}>{`${translate(LabelKeys.Create)}`}</div>
                  </Button>
                </Grid>
              </Grid>
              <Grid item lg={6} xl={6}></Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}

export default AdminInvoice;
