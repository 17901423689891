import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import MessagePhysicalCardWrapper from "../MessagePhysicalCardWrapper";
import { IImageOption, IProduct } from "../../../Modules/Products/types";
import { FieldNames, StateNames } from "../../../Utils/types";
import { IUploadedFile } from "../../FileUploader/types";
import { IFilterOption } from "../../DropDown/types";

interface IPhysicalCardEditorPopupProps {
  id: string;
  selectedProduct: IProduct;
  physicalCardSelectedCoverImage: IImageOption;
  physicalCardSelectedUploadedCoverImage: IUploadedFile;
  physicalCardSelectedUploadedLogotype: IUploadedFile;
  physicalCardHeading: string;
  physicalCardCustomMessage: string;
  onStateChanged: (
    stateName: StateNames.stepperThreeState,
    value: string | number | IUploadedFile | IFilterOption | IImageOption,
    fieldName: FieldNames
  ) => void;
  onExitClicked: () => void;
  onDoneClicked: () => void;
  physicalCardCoverImageHasError: boolean;
}

export default function PhysicalCardEditorPopup(props: IPhysicalCardEditorPopupProps) {
  return (
    <Dialog
      id={props.id}
      open={true}
      PaperProps={{ style: { borderRadius: 0, maxWidth: "100vw" } }}
      onKeyDown={(e) => {
        if (e.key === "Escape") {
          props.onExitClicked();
        }
      }}
    >
      <DialogContent style={{ padding: 0 }}>
        <MessagePhysicalCardWrapper
          id="PhysicalCardWrapperEditor"
          selectedProduct={props.selectedProduct}
          physicalCardSelectedCoverImage={props.physicalCardSelectedCoverImage}
          physicalCardSelectedUploadedCoverImage={props.physicalCardSelectedUploadedCoverImage}
          physicalCardSelectedUploadedLogotype={props.physicalCardSelectedUploadedLogotype}
          physicalCardHeading={props.physicalCardHeading}
          physicalCardCustomMessage={props.physicalCardCustomMessage}
          onStateChanged={(stateName, value, fieldName) => props.onStateChanged(stateName, value, fieldName)}
          onExitClicked={() => props.onExitClicked()}
          onDoneClicked={() => props.onDoneClicked()}
          physicalCardCoverImageHasError={props.physicalCardCoverImageHasError}
        />
      </DialogContent>
    </Dialog>
  );
}
