import React, { useEffect, useState } from "react";
import { Grid, Typography, useMediaQuery } from "@mui/material";
import { useTranslation } from "react-i18next";
import { LabelKeys } from "../../Utils/enums/labelKeys";
import { StateNames, FieldNames, GetCurrentLanguageAsCodeFromLocalStorage, GetCurrentLanguageAsText } from "../../Utils/types";
import { StepperSixStyles } from "./styles";
import { IProduct, ProductCategory } from "../../Modules/Products/types";
import { Currency, LanguageCode, PaymentMethod, SystemPart } from "../../Utils/enums/enums";
import mastercard_logo from "../../Utils/Images/PaymentLogos/mastercard_logo.svg";
import swish_logo from "../../Utils/Images/PaymentLogos/swish_logo.svg";
import visa_logo from "../../Utils/Images/PaymentLogos/visa_logo.png";
import CSCheckbox from "../Input/Forms/CSCheckbox";
import { Link, useNavigate } from "react-router-dom";
import { theme } from "../../appTheme";
import OrderSummary from "../OrderSummary/OrderSummary";
import { ICustomProduct } from "./types";
import { WhenToSend } from "../Delivery/types";
import CustomWebPagePopup from "../CustomWebPage/CustomWebPagePopup";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store";
import { IsResellerAuth, IsAuth } from "../../Utils/authentication";
import { ResellerActions } from "../../Modules/Reseller/actions";
import { IGetResellerAdminContactInfoRequest } from "../../Modules/Reseller/types";
import Popup from "../Popup/Popup";

interface ICSStepperStepSixProps {
  rows: ICustomProduct[];
  selectedProduct: IProduct;
  receiversAmount: number;
  freightPrice: number;
  orderDate: Date;
  currency: Currency;
  includeFreight?: boolean;
  paymentMethod: PaymentMethod;
  customMessage: boolean;
  acceptedTermsOfUse: boolean;
  systemPart: SystemPart;
  onStateChanged: (
    stateName: StateNames.stepperSixState | StateNames.stepperFiveState | StateNames.stepperState,
    value: string | boolean | number | Date | WhenToSend,
    fieldName: FieldNames
  ) => void;
  acceptedTermsOfUseHasError: boolean;
}

export default function CSStepperStepSix(props: ICSStepperStepSixProps) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const classes = StepperSixStyles();
  const { t: translate } = useTranslation();
  const isMobile = useMediaQuery(theme.breakpoints.only("xs"));
  const [popupIsOpen, setPopupIsOpen] = useState(false);
  const isSystemAdmin = IsAuth() && location.pathname.includes(`/${translate(LabelKeys.AdminUrl)}/${translate(LabelKeys.OrderUrl)}`);
  const isResellerAdmin =
    IsResellerAuth() &&
    location.pathname.includes(`/${translate(LabelKeys.ResellerUrl)}/${translate(LabelKeys.AdminUrl)}/${translate(LabelKeys.OrderUrl)}`);

  useEffect(() => {
    window.scroll(0, 0);
    if (reducerState.order.environmentState.systemPart === undefined && !isSystemAdmin && !isResellerAdmin) {
      navigate(`/${GetCurrentLanguageAsText()}/`);
    }
  }, []);

  const reducerState = useSelector(
    (s: RootState) => {
      return {
        order: s.order,
        reseller: s.reseller,
      };
    },
    (prev, next) =>
      prev.order.stepperSixState.acceptedCustomWebPage === next.order.stepperSixState.acceptedCustomWebPage &&
      prev.order.stepperSixState.acceptedTermsOfUse === next.order.stepperSixState.acceptedTermsOfUse &&
      prev.order.stepperSixState.customWebPageDialogOpen === next.order.stepperSixState.customWebPageDialogOpen &&
      prev.reseller.adminResellerContactInfo === next.reseller.adminResellerContactInfo &&
      prev.order.stepperSixState.paymentMethod === next.order.stepperSixState.paymentMethod &&
      prev.reseller.allResellerOptions === next.reseller.allResellerOptions &&
      prev.reseller.adminSelectedResellerOption === next.reseller.adminSelectedResellerOption &&
      prev.reseller.adminSelectedResellerCountryOption === next.reseller.adminSelectedResellerCountryOption
  );

  useEffect(() => {
    if (isResellerAdmin && reducerState.reseller.adminResellerContactInfo?.discount == undefined) {
      dispatch(ResellerActions.GetResellerAdminContactInfoRequest({ language: LanguageCode.SE } as IGetResellerAdminContactInfoRequest));
    }
  }, []);

  function GetDialogListItems(): string[] {
    let values: string[] = [];
    if (reducerState.order.stepperThreeState.customWebPage && reducerState.order.stepperSixState.paymentMethod != PaymentMethod.Invoice) {
      values.push(translate(LabelKeys.CustomWebPageAgreement1));
    }

    if (reducerState.order.stepperThreeState.customWebPage && reducerState.order.stepperSixState.paymentMethod == PaymentMethod.Invoice) {
      values.push(translate(LabelKeys.InvoiceAgreement1));
      values.push(translate(LabelKeys.CustomWebPageAgreement1));
    } else if (reducerState.order.stepperSixState.paymentMethod == PaymentMethod.Invoice) {
      values.push(translate(LabelKeys.InvoiceAgreement1));
      values.push(translate(LabelKeys.InvoiceAgreement2));
    }
    return values;
  }

  function GetDialogDescription(): string {
    if (reducerState.order.stepperThreeState.customWebPage && reducerState.order.stepperSixState.paymentMethod != PaymentMethod.Invoice) {
      return translate(LabelKeys.CustomWebPageHeader);
    } else if (
      reducerState.order.stepperThreeState.customWebPage &&
      reducerState.order.stepperSixState.paymentMethod == PaymentMethod.Invoice
    ) {
      return translate(LabelKeys.CustomWebPageHeader);
    } else if (
      !reducerState.order.stepperThreeState.customWebPage &&
      reducerState.order.stepperSixState.paymentMethod == PaymentMethod.Invoice
    ) {
      return translate(LabelKeys.InvoiceAgreementHeader);
    }

    return translate(LabelKeys.YouAcceptTheFollowing);
  }

  function handleFieldOnChange(stateName: StateNames.stepperSixState, value: string | PaymentMethod, fieldName: FieldNames) {
    props.onStateChanged(stateName, value, fieldName);
  }

  function handleChangePaymentMethod(paymentMethod: PaymentMethod): void {
    handleFieldOnChange(StateNames.stepperSixState, paymentMethod, FieldNames.paymentMethod);
  }

  return (
    <Grid
      container
      item
      xs={12}
      sm={12}
      md={12}
      lg={12}
      xl={12}
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start",
        alignItems: "flex-start",
      }}
    >
      <Grid id="leftContent" container item xs={12} sm={12} md={12} lg={12} xl={12}>
        <Grid id="title" item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Typography variant="h1" align="left">
            {translate(LabelKeys.StepSixTitle)}
          </Typography>
        </Grid>
        <OrderSummary
          id="stepSixOrderSummary"
          currency={props.selectedProduct?.currency}
          quantity={props.receiversAmount}
          discount={
            isResellerAdmin
              ? reducerState.reseller.adminResellerContactInfo?.discount
              : isSystemAdmin && reducerState.reseller.adminSelectedResellerOption?.id != undefined
              ? reducerState.reseller.allResellerOptions?.find((x) => x.id == reducerState.reseller.adminSelectedResellerOption?.id)
                  ?.discount
              : isSystemAdmin
              ? 1
              : 0
          }
          isAdminOrder={isSystemAdmin && reducerState.reseller.adminSelectedResellerOption?.id == undefined}
          isResellerAdminOrder={isResellerAdmin || reducerState.reseller.adminSelectedResellerOption?.id != undefined}
          isOrderConfirmation={false}
          systemPart={props.systemPart}
          values={props.rows.map((x) => {
            return {
              name: x.name,
              priceExcludingVat: x.priceExcludingVat,
              priceIncludingVat: x.priceIncludingVat,
              amount: x.amount,
              category: x.category,
              quantity: x.category != ProductCategory.CustomWebPage ? props.receiversAmount : 1,
            };
          })}
        />
        <Grid container item xs={12} sm={12} md={12} lg={12} xl={12} style={{ marginTop: 30 }}>
          <CSCheckbox
            id="acceptedTermsOfUse"
            checked={props.acceptedTermsOfUse}
            onChange={(e) => props.onStateChanged(StateNames.stepperSixState, e.target.checked, FieldNames.acceptedTermsOfUse)}
            error={props.acceptedTermsOfUseHasError}
          />
          <Typography variant={isMobile ? "body2" : "body1"} style={{ paddingTop: isMobile ? 3 : "" }}>
            {translate(LabelKeys.AcceptsTermsOfUse)}{" "}
            <Link target="_blank" to={`/${GetCurrentLanguageAsText()}/${translate(LabelKeys.TermsOfUseUrl)}`}>
              {translate(LabelKeys.TermsOfUse)}
            </Link>{" "}
            {translate(LabelKeys.ForThisService)}
          </Typography>
        </Grid>
        {!isResellerAdmin && !isSystemAdmin && (
          <Grid id="paymentMethod" container item xs={12} sm={12} md={12} lg={12} xl={12} style={{ marginTop: 60 }}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Typography
                variant="body1"
                style={{
                  float: "left",
                  fontWeight: "bold",
                  paddingRight: 5,
                }}
              >{`${translate(LabelKeys.ChoosePaymentMethod)}:`}</Typography>
              <Typography variant="body1" style={{ float: "left" }}>{`${
                props.paymentMethod == PaymentMethod.Invoice
                  ? translate(LabelKeys.PrePaidInvoice)
                  : translate(PaymentMethod[props.paymentMethod].toLowerCase())
              }`}</Typography>
            </Grid>

            <Grid container item xs={12} sm={12} md={12} lg={12} xl={12} style={{ marginTop: 10 }}>
              <div
                style={{
                  display: isMobile ? "" : "flex",
                  width: "100%",
                }}
              >
                {!isSystemAdmin && !isResellerAdmin && (
                  <div
                    id="swish"
                    style={{
                      height: 114,
                      width: isMobile ? "100%" : 297,
                      background: "#fff",
                      marginRight: isMobile ? "" : 8,
                    }}
                    className={
                      props.paymentMethod === PaymentMethod.Swish ? classes.selectedPaymentMethod : classes.notSelectedPaymentMethod
                    }
                    onClick={() => handleChangePaymentMethod(PaymentMethod.Swish)}
                  >
                    <img height={"40%"} src={swish_logo} style={{ paddingTop: 35 }} />
                  </div>
                )}
                {!isSystemAdmin && !isResellerAdmin && (
                  <div
                    id="card"
                    style={{
                      height: 114,
                      width: isMobile ? "100%" : 297,
                      display: "flex",
                      background: "#fff",
                      marginTop: isMobile ? 16 : 0,
                      marginLeft: isMobile ? "" : 8,
                      marginRight: isMobile ? "" : 8,
                      justifyContent: "center",
                    }}
                    className={
                      props.paymentMethod === PaymentMethod.Card ? classes.selectedPaymentMethod : classes.notSelectedPaymentMethod
                    }
                    onClick={() => handleChangePaymentMethod(PaymentMethod.Card)}
                  >
                    <img
                      height={"30%"}
                      src={visa_logo}
                      style={{
                        paddingTop: 45,
                        paddingRight: 17,
                        float: "right",
                      }}
                    />
                    <img
                      height={"45%"}
                      src={mastercard_logo}
                      style={{
                        paddingTop: 35,
                        paddingLeft: 17,
                        float: "left",
                      }}
                    />
                  </div>
                )}
                {/* {props.systemPart == SystemPart.Company && (
                  <div
                    id="invoice"
                    style={{
                      height: 114,
                      width: isMobile ? "100%" : 297,
                      background: "#fff",
                      marginLeft: isMobile ? "" : orderHasResellerCookieCoupling ? "" : 8,
                    }}
                    className={
                      props.paymentMethod == PaymentMethod.Invoice ? classes.selectedPaymentMethod : classes.notSelectedPaymentMethod
                    }
                    onClick={() => handleChangePaymentMethod(PaymentMethod.Invoice)}
                  >
                    <svg
                      style={{ marginTop: 30 }}
                      width="35"
                      height="35"
                      fill="currentColor"
                      className="bi bi-receipt-cutoff"
                      viewBox="0 0 16 16"
                    >
                      <path d="M3 4.5a.5.5 0 0 1 .5-.5h6a.5.5 0 1 1 0 1h-6a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h6a.5.5 0 1 1 0 1h-6a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h6a.5.5 0 1 1 0 1h-6a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 0 1h-6a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 0 1h-6a.5.5 0 0 1-.5-.5zM11.5 4a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1h-1zm0 2a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1h-1zm0 2a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1h-1zm0 2a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1h-1zm0 2a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1h-1z" />
                      <path d="M2.354.646a.5.5 0 0 0-.801.13l-.5 1A.5.5 0 0 0 1 2v13H.5a.5.5 0 0 0 0 1h15a.5.5 0 0 0 0-1H15V2a.5.5 0 0 0-.053-.224l-.5-1a.5.5 0 0 0-.8-.13L13 1.293l-.646-.647a.5.5 0 0 0-.708 0L11 1.293l-.646-.647a.5.5 0 0 0-.708 0L9 1.293 8.354.646a.5.5 0 0 0-.708 0L7 1.293 6.354.646a.5.5 0 0 0-.708 0L5 1.293 4.354.646a.5.5 0 0 0-.708 0L3 1.293 2.354.646zm-.217 1.198.51.51a.5.5 0 0 0 .707 0L4 1.707l.646.647a.5.5 0 0 0 .708 0L6 1.707l.646.647a.5.5 0 0 0 .708 0L8 1.707l.646.647a.5.5 0 0 0 .708 0L10 1.707l.646.647a.5.5 0 0 0 .708 0L12 1.707l.646.647a.5.5 0 0 0 .708 0l.509-.51.137.274V15H2V2.118l.137-.274z" />
                    </svg>
                    <Typography variant="body1">{translate(LabelKeys.PrePaidInvoice)}</Typography>
                  </div>
                )} */}
              </div>
            </Grid>
          </Grid>
        )}
      </Grid>
      {reducerState.order.stepperSixState.customWebPageDialogOpen && (
        <CustomWebPagePopup
          currentOrderDate={props.orderDate}
          customWebPage={reducerState.order.stepperThreeState.customWebPage}
          title={translate(LabelKeys.OneMoment)}
          description={GetDialogDescription()}
          listItems={GetDialogListItems()}
          btnTextLeft={translate(LabelKeys.IUnderStand)}
          btnTextRight={translate(LabelKeys.Decline)}
          enableCloseBtn={false}
          onStateChanged={(stateName, value, fieldName) => props.onStateChanged(stateName, value, fieldName)}
          paymentMethod={props.paymentMethod}
        />
      )}
      {/* {isSystemAdmin && popupIsOpen && (
        <Popup
          onClose={() => setPopupIsOpen(false)}
          onEscapeKeyDown={() => setPopupIsOpen(false)}
          width={isMobile ? "90vw" : "50vw"}
          dialogTitle="Varning"
          content={
            <div>
              Den återförsäljare som är vald har ett land som inte matchar det land där korten skall användas. Detta innebär att ordern
              kommer skapas i NAV men måste korrigeras manuellt i NAV, summan på orderraden och på gåvokorten kommer inte att matcha. Detta
              MÅSTE korrigeras omgående, innan ordern bokförs. 
            </div>
          }
          enableCloseIcon
        ></Popup>
      )} */}
    </Grid>
  );
}
