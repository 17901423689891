import React, { useEffect } from "react";
import { Typography, useMediaQuery } from "@mui/material";
import Grid from "@mui/material/Grid/Grid";
import { FieldNames, GetCurrentLanguageAsText, StateNames } from "../../Utils/types";
import ProductCategoryItem from "../ProductCategory/ProductCategoryItem";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store";
import { IProductCategory, ProductCategory } from "../../Modules/Products/types";
import { theme } from "../../appTheme";
import { LabelKeys } from "../../Utils/enums/labelKeys";
import { StepperOneStyles } from "./styles";
import { OrderActions } from "../../Modules/Orders/actions";
import { useNavigate } from "react-router";
import { IsResellerAuth, IsAuth } from "../../Utils/authentication";
import { useTranslation } from "react-i18next";
import CSDropdownAutocomplete from "../DropDown/CSDropdownAutocomplete";
import { ResellerActions } from "../../Modules/Reseller/actions";
import { IFilterOption } from "../DropDown/types";
import { LanguageCode } from "../../Utils/enums/enums";
import { AdminResellerCountries } from "./types";
import CSCountryDropDown from "../DropDown/CSCountryDropDown";

interface ICSStepperStepOneProps {
  id: string;
  selectedProductCategoryHasError: boolean;
  onStateChanged: (
    stateName: StateNames.stepperOneState | StateNames.stepperFourState,
    value: IProductCategory | boolean,
    fieldName: FieldNames
  ) => void;
}

export default function CSStepperStepOne(props: ICSStepperStepOneProps) {
  const isMobile = useMediaQuery(theme.breakpoints.only("xs"));
  const isSm = useMediaQuery(theme.breakpoints.only("sm"));
  const isMd = useMediaQuery(theme.breakpoints.only("md"));
  const dispatch = useDispatch();
  const classes = StepperOneStyles();
  const navigate = useNavigate();
  const { t: translate } = useTranslation();
  const isSystemAdmin = IsAuth() && location.pathname.includes(`/${translate(LabelKeys.AdminUrl)}/${translate(LabelKeys.OrderUrl)}`);
  const isResellerAdmin =
    IsResellerAuth() &&
    location.pathname.includes(`/${translate(LabelKeys.ResellerUrl)}/${translate(LabelKeys.AdminUrl)}/${translate(LabelKeys.OrderUrl)}`);

  const reducerState = useSelector(
    (s: RootState) => {
      return {
        product: s.product,
        order: s.order,
        reseller: s.reseller,
      };
    },
    (prev, next) =>
      prev.product.isLoading === next.product.isLoading &&
      prev.product.productCategories === next.product.productCategories &&
      prev.order.stepperOneState.selectedProductCategory === next.order.stepperOneState.selectedProductCategory &&
      prev.reseller.allResellerOptions === next.reseller.allResellerOptions &&
      prev.reseller.adminSelectedResellerOption === next.reseller.adminSelectedResellerOption &&
      prev.reseller.adminSelectedResellerCountryOption === next.reseller.adminSelectedResellerCountryOption
  );

  useEffect(() => {
    window.scroll(0, 0);
    if (reducerState.order.environmentState.systemPart === undefined && !isSystemAdmin && !isResellerAdmin) {
      navigate(`/${GetCurrentLanguageAsText()}/`);
    }
    if (isSystemAdmin && reducerState.reseller.allResellerOptions?.length <= 0) {
      dispatch(ResellerActions.GetAllResellersRequest());
    }
    if (isSystemAdmin && reducerState.reseller.adminSelectedResellerCountryOption?.id == undefined) {
      dispatch(
        ResellerActions.SetSelectedAdminResellerCountryOptionRequest({
          country: AdminResellerCountries.find((x) => x.value == LanguageCode.SE),
        })
      );
    }
  }, []);

  useEffect(() => {
    if (
      reducerState.product.productCategories?.length > 0 &&
      reducerState.order.stepperOneState?.selectedProductCategory?.id === undefined
    ) {
      dispatch(
        OrderActions.SetStepOneStateRequest({
          fieldName: FieldNames.selectedProductCategory,
          value: reducerState.product.productCategories?.find((x) => x.category == ProductCategory.GiftCardGMG),
        })
      );
    }
  }, [reducerState.product.productCategories]);

  function GetAllResellerOptions(): IFilterOption[] {
    return reducerState.reseller.allResellerOptions?.map(
      (x) =>
        ({
          id: x.id,
          name: x.name + ` (${x.customerNumber})`,
          value: x.customerNumber,
        } as IFilterOption)
    );
  }

  return (
    <Grid container justifyContent="center" item xs={12} sm={12} md={12} lg={12} xl={12}>
      <Grid id="leftContent" container item xs={12} sm={12} md={12} lg={12} xl={12} justifyContent="left">
        <Grid id="title" item xs={12} sm={12} md={12} lg={12} xl={12} justifyContent="left">
          <Typography variant="h1" align="left">
            {isSystemAdmin ? translate(LabelKeys.ResellerRepresent) : translate(LabelKeys.WhatToGiveAway)}
          </Typography>
        </Grid>
        {!isSystemAdmin && (
          <Grid id="description" item xs={12} sm={6} md={6} lg={6} xl={6} justifyContent="left" style={{ paddingTop: 10 }}>
            <Typography variant="body1" align="left">
              {translate(LabelKeys.StepOneDescription)}
            </Typography>
          </Grid>
        )}
        {isSystemAdmin && (
          <Grid id="rightContent" container item xs={12} sm={8} md={6} lg={6} xl={6} justifyContent={"left"}>
            <Grid id="resellerDropDown" item xs={12} sm={12} md={12} lg={12} xl={12} style={{ marginRight: 15 }}>
              <CSDropdownAutocomplete
                key={"resellerDropdownAutocomplete"}
                label={translate(LabelKeys.Reseller)}
                groupHeaderColor={"#bdb9b2"}
                options={GetAllResellerOptions()}
                onFilterOptionClicked={(value) => dispatch(ResellerActions.SetSelectedAdminResellerOptionRequest({ reseller: value }))}
                value={reducerState.reseller.adminSelectedResellerOption ?? GetAllResellerOptions()?.find((x) => x.id === -1)}
              />
              <Typography textAlign={"left"} variant="body2" style={{ marginTop: 10 }}>
                {"* Lämnas fältet tomt läggs ordern mot kundnummer 'VINGA'"}
              </Typography>
            </Grid>
            <Grid id="languageDropDown" item xs={12} sm={12} md={12} lg={12} xl={12} style={{ marginRight: 15, marginTop: 30 }}>
              <CSCountryDropDown
                key={"resellerCountryDropdown"}
                label={translate(LabelKeys.ReceiverCountry)}
                // defaultValue={
                //   reducerState.reseller.adminSelectedResellerCountryOption?.name ??
                //   AdminResellerCountries.find((x) => x.value == LanguageCode.SE)?.name
                // }
                options={AdminResellerCountries}
                onStateChanged={(value) => dispatch(ResellerActions.SetSelectedAdminResellerCountryOptionRequest({ country: value }))}
                value={
                  reducerState.reseller.adminSelectedResellerCountryOption?.value != undefined
                    ? reducerState.reseller.adminSelectedResellerCountryOption
                    : AdminResellerCountries.find((x) => x.value == LanguageCode.SE)
                }
              />
            </Grid>
          </Grid>
        )}
      </Grid>
      <Grid id="rightContent" container item xs={6} sm={6} md={6} lg={6} xl={6} justifyContent="center">
        <Grid id="title" item xs={12} sm={12} md={12} lg={12} xl={12}>
          <div style={{ textAlign: "left" }}>{""}</div>
        </Grid>
        <Grid id="description" item xs={12} sm={12} md={12} lg={12} xl={12}>
          <div style={{ textAlign: "left" }}>{""}</div>
        </Grid>
      </Grid>
      <Grid id="productCategoryContainer" container item xs={12} sm={12} md={12} lg={12} xl={12} style={{ marginTop: 30 }} spacing={3}>
        {reducerState.product.productCategories?.map((x) => (
          <Grid key={x.id} item xs={12} sm={6} md={6} lg={6} xl={6}>
            <ProductCategoryItem
              data={x}
              isMobile={isMobile || isSm || isMd}
              onProductCategoryClicked={(stateNames, value, fieldName) => {
                props.onStateChanged(stateNames, value, fieldName);
              }}
              className={
                x.category == reducerState.order.stepperOneState.selectedProductCategory?.category
                  ? classes.selectedProductCategory
                  : classes.notSelectedProductCategory
              }
              sellingPoints={
                x.category == ProductCategory.GiftCardVH
                  ? ["Mottagare ser summa", "Val från hela sortimentet", "Disponerar gåvan fritt"]
                  : ["Mottagare ser inte summa", "Val ur utvalt sortiment", "Diskret och snyggt"]
              }
            />
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
}
