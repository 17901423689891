import { theme } from './../../appTheme';
import { makeStyles } from '@mui/styles';

export const CSInfoStyles = makeStyles({
  tooltip: {
    fontSize: '1.8rem',
    [theme.breakpoints.down('lg')]: {
      visibility: 'hidden',
      height: 0,
      width: 0,
    },
    [theme.breakpoints.up('lg')]: {
      visibility: 'visible',
    },
  },
  iconBtn: {
    [theme.breakpoints.down('lg')]: {
      visibility: 'visible',
    },
    [theme.breakpoints.up('lg')]: {
      visibility: 'hidden',
      height: 0,
      width: 0,
    },
  },
});
