import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

interface IProcessCircleProps {
  text?: string;
}

function ProcessCircle(props: IProcessCircleProps) {
  return (
    <div style={{ display: 'block' }}>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={true}
      >
        <CircularProgress color="inherit" />
        <div style={{ paddingLeft: 10 }}>{props.text}</div>
      </Backdrop>
    </div>
  );
}

export default ProcessCircle;
