import React from "react";
import { IconButton } from "@mui/material";
import { Tooltip } from "@mui/material";

interface ICSToolbar {
  iconSize?: number;
  className?: string;
  enableAdd?: boolean;
  enableDelete?: boolean;
  enableRefresh?: boolean;
  enableEdit?: boolean;
  disableAdd?: boolean;
  disableEdit?: boolean;
  disableDelete?: boolean;
  disableRefresh?: boolean;
  addTooltipText?: string;
  editTooltipText?: string;
  deleteTooltipText?: string;
  refreshTooltipText?: string;
  onAddClicked?: () => void;
  onEditClicked?: () => void;
  onDeleteClicked?: () => void;
  onRefreshClicked?: () => void;
}

export default function CSToolbar(props: ICSToolbar) {
  return (
    <div className={props.className}>
      {props.enableAdd && (
        <Tooltip arrow title={props.addTooltipText} placement="left">
          <IconButton disabled={props.disableAdd} onClick={() => props.onAddClicked()}>
            <svg
              width={props.iconSize ?? 20}
              height={props.iconSize ?? 20}
              fill="currentColor"
              color={props.disableAdd ? "" : "#000"}
              className="bi bi-plus-circle"
              viewBox="0 0 16 16"
            >
              <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
              <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
            </svg>
          </IconButton>
        </Tooltip>
      )}
      {props.enableEdit && (
        <Tooltip arrow title={props.editTooltipText} placement="left">
          <IconButton disabled={props.disableEdit} onClick={() => props.onEditClicked()}>
            <svg
              width={props.iconSize ?? 20}
              height={props.iconSize ?? 20}
              fill="currentColor"
              color={props.disableEdit ? "" : "#000"}
              className="bi bi-pencil-square"
              viewBox="0 0 16 16"
            >
              <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
              <path
                fillRule="evenodd"
                d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
              />
            </svg>
          </IconButton>
        </Tooltip>
      )}
      {props.enableDelete && (
        <Tooltip arrow title={props.deleteTooltipText} placement="left">
          <IconButton disabled={props.disableDelete} onClick={() => props.onDeleteClicked()}>
            <svg
              width={props.iconSize ?? 20}
              height={props.iconSize ?? 20}
              fill="currentColor"
              color={props.disableDelete ? "" : "#000"}
              className="bi bi-trash"
              viewBox="0 0 16 16"
            >
              <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
              <path
                fillRule="evenodd"
                d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"
              />
            </svg>
          </IconButton>
        </Tooltip>
      )}
      {props.enableRefresh && (
        <Tooltip arrow title={props.refreshTooltipText} placement="left">
          <IconButton disabled={props.disableRefresh} onClick={() => props.onRefreshClicked()}>
            <svg
              width={props.iconSize ?? 20}
              height={props.iconSize ?? 20}
              fill="currentColor"
              color={props.disableRefresh ? "" : "#000"}
              className="bi bi-arrow-clockwise"
              viewBox="0 0 16 16"
            >
              <path fillRule="evenodd" d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2v1z" />
              <path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466z" />
            </svg>
          </IconButton>
        </Tooltip>
      )}
    </div>
  );
}
