import React from "react";
import { Grid, Typography, useMediaQuery } from "@mui/material";
import { LabelKeys } from "../../Utils/enums/labelKeys";
import { useTranslation } from "react-i18next";
import { theme } from "../../appTheme";
function HowItWorksHomePage() {
  const { t: translate } = useTranslation();
  const isMobile = useMediaQuery(theme.breakpoints.only("xs"));
  return (
    <Grid container item xs={12} sm={12} md={12} lg={12} xl={12} justifyContent="center" style={{ paddingBottom: 30 }} spacing={5}>
      <Grid item container xs={12} sm={12} md={12} lg={12} xl={12} style={{ paddingBottom: 10 }} justifyContent="center">
        <Typography id="howItWorksTitle" variant="h1">
          {translate(LabelKeys.SimpleAndPersonal)}
        </Typography>
      </Grid>
      <Grid container item xs={12} sm={4} md={4} lg={4} xl={4} style={{ height: isMobile ? 170 : 45 }}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ height: 45 }}>
          <svg width="45" height="45" fill="currentColor" className="bi bi-stopwatch" viewBox="0 0 16 16">
            <path d="M8.5 5.6a.5.5 0 1 0-1 0v2.9h-3a.5.5 0 0 0 0 1H8a.5.5 0 0 0 .5-.5V5.6z" />
            <path d="M6.5 1A.5.5 0 0 1 7 .5h2a.5.5 0 0 1 0 1v.57c1.36.196 2.594.78 3.584 1.64a.715.715 0 0 1 .012-.013l.354-.354-.354-.353a.5.5 0 0 1 .707-.708l1.414 1.415a.5.5 0 1 1-.707.707l-.353-.354-.354.354a.512.512 0 0 1-.013.012A7 7 0 1 1 7 2.071V1.5a.5.5 0 0 1-.5-.5zM8 3a6 6 0 1 0 .001 12A6 6 0 0 0 8 3z" />
          </svg>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ paddingTop: 25 }}>
          <Typography id="howItWorksLeftTitle" variant="h4">
            {translate(LabelKeys.HowItWorksHomePageLeftTitle)}
          </Typography>
          <Typography id="howItWorksLeftBody" variant="body1">
            {translate(LabelKeys.HowItWorksHomePageLeftBody)}
          </Typography>
        </Grid>
      </Grid>
      <Grid container item xs={12} sm={4} md={4} lg={4} xl={4}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <svg width="45" height="45" fill="currentColor" className="bi bi-phone" viewBox="0 0 16 16">
            <path d="M11 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h6zM5 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H5z" />
            <path d="M8 14a1 1 0 1 0 0-2 1 1 0 0 0 0 2z" />
          </svg>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ paddingTop: 25 }}>
          <Typography id="howItWorksMiddleTitle" variant="h4">
            {translate(LabelKeys.HowItWorksHomePageMiddleTitle)}
          </Typography>
          <Typography id="howItWorksMiddleBody" variant="body1">
            {translate(LabelKeys.HowItWorksHomePageMiddleBody)}
          </Typography>
        </Grid>
      </Grid>
      <Grid container item xs={12} sm={4} md={4} lg={4} xl={4}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <svg width="45" height="45" fill="currentColor" className="bi bi-star" viewBox="0 0 16 16">
            <path d="M2.866 14.85c-.078.444.36.791.746.593l4.39-2.256 4.389 2.256c.386.198.824-.149.746-.592l-.83-4.73 3.522-3.356c.33-.314.16-.888-.282-.95l-4.898-.696L8.465.792a.513.513 0 0 0-.927 0L5.354 5.12l-4.898.696c-.441.062-.612.636-.283.95l3.523 3.356-.83 4.73zm4.905-2.767-3.686 1.894.694-3.957a.565.565 0 0 0-.163-.505L1.71 6.745l4.052-.576a.525.525 0 0 0 .393-.288L8 2.223l1.847 3.658a.525.525 0 0 0 .393.288l4.052.575-2.906 2.77a.565.565 0 0 0-.163.506l.694 3.957-3.686-1.894a.503.503 0 0 0-.461 0z" />
          </svg>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ paddingTop: 25 }}>
          <Typography id="howItWorksRightTitle" variant="h4">
            {translate(LabelKeys.HowItWorksHomePageRightTitle)}
          </Typography>
          <Typography id="howItWorksRightBody" variant="body1">
            {translate(LabelKeys.HowItWorksHomePageRightBody)}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default HowItWorksHomePage;
