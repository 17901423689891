import makeStyles from "@mui/styles/makeStyles";
import { theme } from "../../../../appTheme";

export const TemplateStyles = makeStyles({
  selectedDeliveryOption: {},
  notSelectedDeliveryOption: {
    opacity: 0.3,
  },
  saveBtn: {},
  cancelBtn: {
    [theme.breakpoints.only("xs")]: { paddingTop: 20 },
  },
  sendMethod: {
    paddingTop: 20,
    width: "100%",
  },
  toolbar: {
    paddingTop: 20,
    float: "right",
  },
  toolbar2: {
    float: "left",
  },
  buttonContainer: {
    paddingTop: 20,
  },
  mailPreview: {
    backgroundColor: "#fff",
  },
  pdfPreview: {
    backgroundColor: "#fff",
  },
});
