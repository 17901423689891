import { TransactionActions } from "./../Transaction/actions";
import { IOrderRow } from "./../../views/Admin/types";
import {
  ICreateCompanyOrderRequest,
  ICreatePrivateOrderRequest,
  ICreatePrivateOrderResponse,
  IResendOrderRequest,
  IResendReceiptsRequest,
  ISearchOrdersRequest,
  ISearchSingleOrderRequest,
  IUpdateOrderLineRequest,
  IStandardResponse,
  IResendOrderLineRequest,
  IGetOrderConfirmationDetailsRequest,
  IGetOrderConfirmationDetailsResponse,
  IMarkOrderAsDoneRequest,
  IRefundOrderRequest,
  ICreateAdminOrderRequest,
  ICreateOrderResponse,
  IResendGiftCardsToPrinterRequest,
  IResendOrderToNavRequest,
} from "./types";
import { ORDER_API } from "./../../Utils/network/axios/urls";
import { axiosInstance, GetBaseURL } from "./../../Utils/network/axios/index";
import { all, call, delay, put, SagaReturnType, takeLatest } from "redux-saga/effects";
import { OrderActionTypes } from "./actionTypes";
import { OrderActions } from "./actions";
import SnackbarActions from "../../components/Snackbar/actions";
import { ErrorReason } from "../../Utils/enums/enums";

export default function* OrderWatcher() {
  yield all([
    takeLatest(OrderActionTypes.CREATE_PRIVATE_ORDER_REQUEST, CreatePrivateOrderWorker),
    takeLatest(OrderActionTypes.CREATE_COMPANY_ORDER_REQUEST, CreateCompanyOrderWorker),
    takeLatest(OrderActionTypes.CREATE_ADMIN_ORDER_REQUEST, CreateAdminOrderWorker),
    takeLatest(OrderActionTypes.RESEND_ORDERS_REQUEST, ResendOrdersWorker),
    takeLatest(OrderActionTypes.RESEND_RECEIPTS_REQUEST, ResendReceiptsWorker),
    takeLatest(OrderActionTypes.SEARCH_SINGLE_ORDER_REQUEST, SearchSingleOrderWorker),
    takeLatest(OrderActionTypes.SEARCH_ORDERS_REQUEST, SearchOrdersWorker),
    takeLatest(OrderActionTypes.UPDATE_ORDERLINE_REQUEST, UpdateOrderLineWorker),
    takeLatest(OrderActionTypes.RESEND_ORDERLINE_REQUEST, ResendOrderLineWorker),
    takeLatest(OrderActionTypes.GET_ORDER_CONFIRMATION_DETAILS_REQUEST, GetOrderConfirmationDetailsWorker),
    takeLatest(OrderActionTypes.MARK_ORDER_AS_DONE_REQUEST, MarkOrderAsDoneWorker),
    takeLatest(OrderActionTypes.REFUND_ORDERS_REQUEST, RefundOrderWorker),
    takeLatest(OrderActionTypes.RESEND_ORDER_TO_NAV_REQUEST, ResendOrderToNavWorker),
    takeLatest(OrderActionTypes.RESEND_GIFT_CARDS_TO_PRINTER_REQUEST, ResendGiftCardsToPrinterWorker),
  ]);
}

//WORKERS
//Private
function* CreatePrivateOrderWorker({ request }: ReturnType<typeof OrderActions.CreatePrivateOrderRequest>) {
  try {
    const response: CreatePrivateOrderServiceResponse = yield call(createPrivateOrder, request);
    if (response && response.error === ErrorReason.Unknown) {
      yield put(OrderActions.CreatePrivateOrderSuccess(response as ICreatePrivateOrderResponse));
    } else {
      if (response.error === ErrorReason.SwedbankPayCreatePurchaseFail) {
        yield put(SnackbarActions.ShowError("Något gick fel när ordern skulle skapas"));
        yield put(OrderActions.CreatePrivateOrderError());
      } else {
        yield put(SnackbarActions.ShowError("Något gick fel när ordern skulle skapas, vänligen försök igen"));
        yield put(OrderActions.CreatePrivateOrderError());
      }
    }
  } catch (error) {
    yield put(OrderActions.CreatePrivateOrderError());
    yield put(SnackbarActions.ShowError("Något gick fel när ordern skulle skapas, vänligen försök igen"));
  }
}

//Company
function* CreateCompanyOrderWorker({ request }: ReturnType<typeof OrderActions.CreateCompanyOrderRequest>) {
  try {
    const response: CreateCompanyOrderServiceResponse = yield call(createCompanyOrder, request);
    if (response && response.error === ErrorReason.Unknown) {
      yield put(OrderActions.CreateCompanyOrderSuccess(response as ICreateOrderResponse));
    } else {
      if (response.error === ErrorReason.SwedbankPayCreatePurchaseFail) {
        yield put(SnackbarActions.ShowError("Något gick fel när ordern skulle skapas"));
        yield put(OrderActions.CreateCompanyOrderError());
      } else {
        yield put(SnackbarActions.ShowError("Något gick fel när ordern skulle skapas, vänligen försök igen"));
        yield put(OrderActions.CreateCompanyOrderError());
      }
    }
  } catch (error) {
    yield put(OrderActions.CreateCompanyOrderError());
    yield put(SnackbarActions.ShowError("Något gick fel när ordern skulle skapas, vänligen försök igen"));
  }
}

//Create order Admin
function* CreateAdminOrderWorker({ request }: ReturnType<typeof OrderActions.CreateAdminOrderRequest>) {
  try {
    const response: CreateAdminOrderServiceResponse = yield call(createAdminOrder, request);
    if (response && response.error === ErrorReason.Unknown) {
      yield put(OrderActions.CreateAdminOrderSuccess(response as ICreateOrderResponse));
    } else {
      if (response.error === ErrorReason.ResellerValidationFail) {
        yield put(SnackbarActions.ShowError("Valideringskoden är felaktig"));
        yield put(OrderActions.CreateAdminOrderError());
      } else {
        yield put(SnackbarActions.ShowError("Något gick fel när ordern skulle skapas, vänligen försök igen"));
        yield put(OrderActions.CreateAdminOrderError());
      }
    }
  } catch (error) {
    yield put(OrderActions.CreateAdminOrderError());
    yield put(SnackbarActions.ShowError("Något gick fel när ordern skulle skapas, vänligen försök igen"));
  }
}

function* ResendOrdersWorker({ request }: ReturnType<typeof OrderActions.ResendOrdersRequest>) {
  try {
    yield call(resendOrders, request);
    yield put(OrderActions.ResendOrdersSuccess());
    yield put(SnackbarActions.ShowSuccess("Angivna orders har skickats om"));
    yield put(OrderActions.SearchSingleOrderRequest({ orderId: request.orderIds[0] }));
    yield put(TransactionActions.GetTransactionsRequest({ orderId: request.orderIds[0] }));
  } catch (error) {
    yield put(SnackbarActions.ShowError("Något gick fel när ordern skulle skickas om. Vänligen försök igen."));
    yield put(OrderActions.ResendOrdersError());
  }
}

function* ResendReceiptsWorker({ request }: ReturnType<typeof OrderActions.ResendReceiptsRequest>) {
  try {
    yield call(resendReceipts, request);
    yield put(OrderActions.ResendReceiptsSuccess());
    yield put(SnackbarActions.ShowSuccess("Angivna kvitton har skickats om"));
    yield put(TransactionActions.GetTransactionsRequest({ orderId: request.orderIds[0] }));
  } catch (error) {
    yield put(SnackbarActions.ShowError("Något gick fel när kvittot skulle skickas om. Vänligen försök igen."));
    yield put(OrderActions.ResendReceiptsError());
  }
}

//Admin
function* SearchSingleOrderWorker({ request }: ReturnType<typeof OrderActions.SearchSingleOrderRequest>) {
  try {
    const response: SearchSingleOrderServiceResponse = yield call(searchSingleOrder, request);
    if (response) {
      yield put(OrderActions.SearchSingleOrderSuccess(response));
    } else {
      yield put(OrderActions.SearchSingleOrderError());
    }
  } catch (error) {
    yield put(OrderActions.SearchSingleOrderError());
  }
}

function* SearchOrdersWorker({ request }: ReturnType<typeof OrderActions.SearchOrdersRequest>) {
  try {
    const response: SearchOrdersServiceResponse = yield call(searchOrders, request);
    if (response) {
      yield put(OrderActions.SearchOrdersSuccess(response));
    } else {
      yield put(OrderActions.SearchOrdersError());
    }
  } catch (error) {
    yield put(OrderActions.SearchOrdersError());
  }
}

function* MarkOrderAsDoneWorker({ request }: ReturnType<typeof OrderActions.MarkOrderAsDoneRequest>) {
  try {
    const response: MarkOrderAsDoneServiceResponse = yield call(markOrderAsDone, request);
    if (response) {
      yield put(OrderActions.MarkOrderAsDoneSuccess(response));
      yield put(SnackbarActions.ShowSuccess("Marked as done!"));
      yield put(OrderActions.SearchSingleOrderRequest({ orderId: request.orderId }));
      yield put(TransactionActions.GetTransactionsRequest({ orderId: request.orderId }));
    } else {
      yield put(OrderActions.MarkOrderAsDoneError());
    }
  } catch (error) {
    yield put(OrderActions.MarkOrderAsDoneError());
    yield put(SnackbarActions.ShowError("Unable to mark order as done, please try again later..."));
  }
}

function* UpdateOrderLineWorker({ request }: ReturnType<typeof OrderActions.UpdateOrderLineRequest>) {
  try {
    const response: UpdateOrderLineResponse = yield call(updateOrderLine, request);
    if (response) {
      yield put(OrderActions.UpdateOrderLineSuccess(response));
      yield put(OrderActions.SearchSingleOrderRequest({ orderId: request.orderId }));
    } else {
      yield put(OrderActions.UpdateOrderLineError());
    }
  } catch (error) {
    yield put(OrderActions.UpdateOrderLineError());
  }
}

function* RefundOrderWorker({ request }: ReturnType<typeof OrderActions.RefundOrderRequest>) {
  try {
    const response: RefundOrderServiceResponse = yield call(refundOrder, request);
    if (response && response.error == ErrorReason.Unknown) {
      yield put(OrderActions.RefundOrderSuccess());
      yield put(OrderActions.SearchSingleOrderRequest({ orderId: request.orderId }));
      yield put(SnackbarActions.ShowSuccess("Order refunded successfully"));
      yield put(TransactionActions.GetTransactionsRequest({ orderId: request.orderId }));
    } else {
      yield put(OrderActions.RefundOrderError());
      yield put(SnackbarActions.ShowSuccess("Could not refund order, please try again later!"));
    }
  } catch (error) {
    yield put(OrderActions.RefundOrderError());
    yield put(SnackbarActions.ShowSuccess("Could not refund order, please try again later!"));
  }
}

function* ResendOrderLineWorker({ request }: ReturnType<typeof OrderActions.ResendOrderLineRequest>) {
  try {
    const response: ResendOrderLineResponse = yield call(resendOrderLine, request);
    if (response) {
      yield put(OrderActions.ResendOrderLineSuccess(response));
      yield put(SnackbarActions.ShowSuccess("Raden har skickats om."));
      if (request.orderId) {
        yield put(TransactionActions.GetTransactionsRequest({ orderId: request.orderId }));
      }
    } else {
      yield put(OrderActions.ResendOrderLineError());
      yield put(SnackbarActions.ShowError("Ett fel uppstod, vänligen försök igen."));
    }
  } catch (error) {
    yield put(OrderActions.ResendOrderLineError());
    yield put(SnackbarActions.ShowError("Ett fel uppstod, vänligen försök igen."));
  }
}
function* ResendOrderToNavWorker({ request }: ReturnType<typeof OrderActions.ResendOrderToNavRequest>) {
  try {
    const response: ResendOrderToNavServiceResponse = yield call(resendOrderToNav, request);
    if (response) {
      yield put(OrderActions.ResendOrderToNavSuccess(response));
      yield put(SnackbarActions.ShowSuccess("Ordern har skickats om till Navision."));
      yield put(OrderActions.SearchSingleOrderRequest({ orderId: request.orderId }));
      yield put(TransactionActions.GetTransactionsRequest({ orderId: request.orderId }));
    } else {
      yield put(OrderActions.ResendOrderToNavError());
      yield put(SnackbarActions.ShowError("Ett fel uppstod, vänligen försök igen."));
    }
  } catch (error) {
    yield put(OrderActions.ResendOrderToNavError());
    yield put(SnackbarActions.ShowError("Ett fel uppstod, vänligen försök igen."));
  }
}
function* ResendGiftCardsToPrinterWorker({ request }: ReturnType<typeof OrderActions.ResendGiftCardsToPrinterRequest>) {
  try {
    const response: ResendGiftCardsToPrinterServiceResponse = yield call(resendGiftCardsToPrinter, request);
    if (response) {
      yield put(OrderActions.ResendGiftCardsToPrinterSuccess(response));
      yield put(SnackbarActions.ShowSuccess("Ordern har skickats om till skrivaren."));
    } else {
      yield put(OrderActions.ResendGiftCardsToPrinterError());
      yield put(SnackbarActions.ShowError("Ett fel uppstod, vänligen försök igen."));
    }
  } catch (error) {
    yield put(OrderActions.ResendGiftCardsToPrinterError());
    yield put(SnackbarActions.ShowError("Ett fel uppstod, vänligen försök igen."));
  }
}

function* GetOrderConfirmationDetailsWorker({ request }: ReturnType<typeof OrderActions.GetOrderConfirmationDetailsRequest>) {
  try {
    yield delay(1500);
    const response: GetOrderConfirmationDetailsResponse = yield call(getOrderConfirmationDetails, request);
    if (response) {
      yield put(OrderActions.GetOrderConfirmationDetailsSuccess(response));
    } else {
      yield put(OrderActions.GetOrderConfirmationDetailsError());
    }
  } catch (error) {
    yield put(OrderActions.GetOrderConfirmationDetailsError());
  }
}

// API:S
export const createPrivateOrder: CreatePrivateOrderService = async (request: ICreatePrivateOrderRequest) => {
  axiosInstance.defaults.baseURL = GetBaseURL() + ORDER_API;
  return await axiosInstance.post("private", request).then((resp) => resp.data);
};

export const createCompanyOrder: CreateCompanyOrderService = async (request: ICreateCompanyOrderRequest) => {
  axiosInstance.defaults.baseURL = GetBaseURL() + ORDER_API;
  return await axiosInstance.post("company", request).then((resp) => resp.data);
};

export const createAdminOrder: CreateAdminOrderService = async (request: ICreateAdminOrderRequest) => {
  axiosInstance.defaults.baseURL = GetBaseURL() + ORDER_API;
  return await axiosInstance.post("admin", request).then((resp) => resp.data);
};

export const resendOrders: ResendOrdersService = async (request: IResendOrderRequest) => {
  axiosInstance.defaults.baseURL = GetBaseURL() + ORDER_API;
  return await axiosInstance.post("resend", request).then((resp) => resp.data);
};

export const resendReceipts: ResendReceiptsService = async (request: IResendReceiptsRequest) => {
  axiosInstance.defaults.baseURL = GetBaseURL() + ORDER_API;
  return await axiosInstance.post("resend/receipts", request).then((resp) => resp.data);
};

export const searchOrders: SearchOrdersService = async (request: ISearchOrdersRequest) => {
  axiosInstance.defaults.baseURL = GetBaseURL() + ORDER_API;
  return await axiosInstance.post(`search`, request).then((resp) => resp.data);
};

export const searchSingleOrder: SearchSingleOrderService = async (request: ISearchSingleOrderRequest) => {
  axiosInstance.defaults.baseURL = GetBaseURL() + ORDER_API;
  return await axiosInstance.get(`details/${request.orderId}`).then((resp) => resp.data);
};

export const updateOrderLine: UpdateOrderLineService = async (request: IUpdateOrderLineRequest) => {
  axiosInstance.defaults.baseURL = GetBaseURL() + ORDER_API;
  return await axiosInstance.put(`orderLine`, request).then((resp) => resp.data);
};

export const resendOrderLine: ResendOrderLineService = async (request: IResendOrderLineRequest) => {
  axiosInstance.defaults.baseURL = GetBaseURL() + ORDER_API;
  return await axiosInstance.post(`orderLine/resend/${request.orderLineId}`).then((resp) => resp.data);
};

export const getOrderConfirmationDetails: GetOrderConfirmationDetailsService = async (request: IGetOrderConfirmationDetailsRequest) => {
  axiosInstance.defaults.baseURL = GetBaseURL() + ORDER_API;
  return await axiosInstance.get(`confirmation/details/${request.internalOrderId}`).then((resp) => resp.data);
};

export const markOrderAsDone: MarkOrderAsDoneService = async (request: IMarkOrderAsDoneRequest) => {
  axiosInstance.defaults.baseURL = GetBaseURL() + ORDER_API;
  return await axiosInstance.put(`complete`, request).then((resp) => resp.data);
};

export const refundOrder: RefundOrderService = async (request: IRefundOrderRequest) => {
  axiosInstance.defaults.baseURL = GetBaseURL() + ORDER_API;
  return await axiosInstance.post("refund", request).then((resp) => resp.data);
};

export const resendOrderToNav: ResendOrderToNavService = async (request: IResendOrderToNavRequest) => {
  axiosInstance.defaults.baseURL = GetBaseURL() + ORDER_API;
  return await axiosInstance.post(`nav/resend`, request.orderId).then((resp) => resp.data);
};

export const resendGiftCardsToPrinter: ResendGiftCardsToPrinterService = async (request: IResendGiftCardsToPrinterRequest) => {
  axiosInstance.defaults.baseURL = GetBaseURL() + ORDER_API;
  return await axiosInstance.post("physicalCard/resend", request.orderId).then((resp) => resp.data);
};

// SERVICES
export interface CreatePrivateOrderService {
  (request: ICreatePrivateOrderRequest): Promise<ICreateOrderResponse>;
}
export interface CreateCompanyOrderService {
  (request: ICreateCompanyOrderRequest): Promise<ICreateOrderResponse>;
}
export interface CreateAdminOrderService {
  (request: ICreateAdminOrderRequest): Promise<ICreateOrderResponse>;
}
export interface ResendOrdersService {
  (request: IResendOrderRequest): Promise<void>;
}
export interface ResendReceiptsService {
  (request: IResendReceiptsRequest): Promise<void>;
}
export interface SearchOrdersService {
  (request: ISearchOrdersRequest): Promise<IOrderRow[]>;
}
export interface SearchSingleOrderService {
  (request: ISearchSingleOrderRequest): Promise<IOrderRow>;
}
export interface UpdateOrderLineService {
  (request: IUpdateOrderLineRequest): Promise<IStandardResponse>;
}
export interface ResendOrderLineService {
  (request: IResendOrderLineRequest): Promise<IStandardResponse>;
}
export interface GetOrderConfirmationDetailsService {
  (request: IGetOrderConfirmationDetailsRequest): Promise<IGetOrderConfirmationDetailsResponse>;
}
export interface MarkOrderAsDoneService {
  (request: IMarkOrderAsDoneRequest): Promise<IStandardResponse>;
}
export interface RefundOrderService {
  (request: IRefundOrderRequest): Promise<IStandardResponse>;
}
export interface ResendOrderToNavService {
  (request: IResendOrderToNavRequest): Promise<IStandardResponse>;
}
export interface ResendGiftCardsToPrinterService {
  (request: IResendGiftCardsToPrinterRequest): Promise<IStandardResponse>;
}

// RESPONSE
export type CreatePrivateOrderServiceResponse = SagaReturnType<typeof createPrivateOrder>;
export type CreateCompanyOrderServiceResponse = SagaReturnType<typeof createCompanyOrder>;
export type CreateAdminOrderServiceResponse = SagaReturnType<typeof createAdminOrder>;
export type ResendOrdersServiceResponse = SagaReturnType<typeof resendOrders>;
export type ResendReceiptsServiceResponse = SagaReturnType<typeof resendReceipts>;
export type SearchSingleOrderServiceResponse = SagaReturnType<typeof searchSingleOrder>;
export type SearchOrdersServiceResponse = SagaReturnType<typeof searchOrders>;
export type UpdateOrderLineResponse = SagaReturnType<typeof updateOrderLine>;
export type ResendOrderLineResponse = SagaReturnType<typeof resendOrderLine>;
export type GetOrderConfirmationDetailsResponse = SagaReturnType<typeof getOrderConfirmationDetails>;
export type MarkOrderAsDoneServiceResponse = SagaReturnType<typeof markOrderAsDone>;
export type RefundOrderServiceResponse = SagaReturnType<typeof refundOrder>;
export type ResendOrderToNavServiceResponse = SagaReturnType<typeof resendOrderToNav>;
export type ResendGiftCardsToPrinterServiceResponse = SagaReturnType<typeof resendGiftCardsToPrinter>;
