import React from "react";
import svLocale from "date-fns/locale/sv";
import engLocale from "date-fns/locale/en-US";
import { LanguageCode } from "../../../Utils/enums/enums";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { InputAdornment, TextField, TextFieldProps, Typography } from "@mui/material";
import { MobileDateTimePicker } from "@mui/x-date-pickers/MobileDateTimePicker";
import { CSDateTimePickerStyles } from "./styles";
import { LabelKeys } from "../../../Utils/enums/labelKeys";
import { useTranslation } from "react-i18next";
import { GetCurrentLanguageAsCodeFromLocalStorage } from "../../../Utils/types";

export interface ICSDateTimePicker {
  id: string;
  label?: string;
  defaultValue?: Date;
  disabled?: boolean;
  error?: boolean;
  value: Date;
  helperText?: string;
  okLabel: string;
  required?: boolean;
  cancelLabel: string;
  disableFuture?: boolean;
  disablePast?: boolean;
  minDate?: Date;
  maxDate?: Date;
  startIcon?: React.ReactNode;
  fullWidth?: boolean;
  placeholder?: string;
  onChange?: (value: Date) => void;
  onBlur?: () => void;
  onLoad?: () => void;
}

function CSDateTimePicker(props: ICSDateTimePicker) {
  const classes = CSDateTimePickerStyles();
  const { t: translate } = useTranslation();

  function GetLocale(): Locale {
    switch (GetCurrentLanguageAsCodeFromLocalStorage()) {
      case LanguageCode.SE:
        return svLocale;
      case LanguageCode.EN:
        return engLocale;
      default:
        return svLocale;
    }
  }

  function getErrorMessage(): React.ReactNode {
    return (
      <Typography component={"span"} style={{ fontSize: "0.8rem", display: "flex" }}>
        <Typography component={"span"} style={{ fontSize: "0.8rem", marginLeft: -14 }}>
          <svg
            width="20"
            height="20"
            fill="currentColor"
            className="bi bi-exclamation-diamond-fill"
            viewBox="0 0 16 16"
            style={{
              color: "red",
              margin: 0,
              paddingTop: 5,
            }}
          >
            <path d="M9.05.435c-.58-.58-1.52-.58-2.1 0L.436 6.95c-.58.58-.58 1.519 0 2.098l6.516 6.516c.58.58 1.519.58 2.098 0l6.516-6.516c.58-.58.58-1.519 0-2.098L9.05.435zM8 4c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995A.905.905 0 0 1 8 4zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z" />
          </svg>
        </Typography>
        <Typography component={"span"} style={{ fontSize: "0.8rem", paddingTop: 7, paddingLeft: 5 }}>
          {props.helperText && props.helperText}
        </Typography>
      </Typography>
    );
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={GetLocale()}>
      <Typography variant="body2" style={{ textAlign: "left", paddingBottom: 14, fontWeight: "bold" }}>
        {props.label ? (props.required ? `${props.label}*` : `${props.label}`) : ""}
      </Typography>
      <MobileDateTimePicker
        disablePast={props.disablePast}
        disableFuture={props.disableFuture}
        // okText={props.okLabel}
        // cancelText={props.cancelLabel}
        showToolbar={false}
        disableMaskedInput
        value={props.value}
        minutesStep={15}
        OpenPickerButtonProps={{ color: "primary" }}
        rightArrowButtonText={translate(LabelKeys.NextMonth)}
        leftArrowButtonText={translate(LabelKeys.PreviousMonth)}
        maxDate={props.maxDate}
        minDate={props.minDate}
        inputFormat={`yyyy-MM-dd HH:mm`}
        onChange={(newValue: Date) => props.onChange(newValue)}
        className={classes.root}
        renderInput={(params: TextFieldProps) => (
          <TextField
            className={classes.root}
            placeholder={props.placeholder}
            size={"small"}
            onLoad={() => props.onLoad()}
            {...params}
            fullWidth={props.fullWidth}
            InputLabelProps={{
              style: { height: 46 },
              shrink: true,
            }}
            sx={{
              svg: { color: "#000" },
              input: { color: "#000" },
              color: { color: "#000" },
            }}
            InputProps={{
              style: { height: 46 },
              startAdornment: (
                <InputAdornment style={{ color: "#000" }} position="start">
                  {props.startIcon}
                </InputAdornment>
              ),
            }}
            error={props.error}
            helperText={props.error && getErrorMessage()}
          />
        )}
      />
    </LocalizationProvider>
  );
}

export default CSDateTimePicker;
