import * as React from "react";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import { CSImageSelectorStyles } from "./styles";
import { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import { IFilterOption } from "../DropDown/types";
import { IImageOption } from "../../Modules/Products/types";
import clsx from "clsx";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import { LabelKeys } from "../../Utils/enums/labelKeys";
import useMediaQuery from "@mui/material/useMediaQuery";
import { theme } from "../../appTheme";
import CSFileUploader from "../FileUploader/CSFileUploader";
import CSMSDropDown from "../DropDown/CSMSDropDown";
import { IUploadedFile } from "../FileUploader/types";
import Chip from "@mui/material/Chip";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { MaxFileSize } from "../../Utils/enums/enums";
import { FieldNames, StateNames } from "../../Utils/types";
import CSInfo from "../Helper/CSInfo";
import { QueryParamKeys } from "../../Utils/enums/queryParams";
import { SendBy } from "../Delivery/types";

interface ICSImageSelectorProps {
  filterOptions?: IFilterOption[];
  imageOptions: IImageOption[];
  rowHeight?: number;
  selectedImage?: IImageOption;
  hasUploadedImage?: boolean;
  className?: string;
  verticalMode?: boolean;
  resetComponent?: string; //To change the state, with bool the state "true" always will be the same
  onStateChanged: (
    stateName: StateNames.stepperThreeState,
    value: IImageOption | IUploadedFile | IFilterOption,
    fieldName: FieldNames,
    updateExternalState: boolean
  ) => void;
  showTooltip?: boolean;
  hoverTooltopText?: string;
  popupTooltipText?: string;
  sendBy: SendBy;
}

function CSImageSelector(props: ICSImageSelectorProps) {
  const classes = CSImageSelectorStyles();
  const { t: translate } = useTranslation();
  const [fileName, setFileName] = useState("");
  const [filteredImages, setFilteredImages] = useState([] as IImageOption[]);
  const isMobile = useMediaQuery(theme.breakpoints.only("xs"));
  const isIpad = useMediaQuery(theme.breakpoints.between("sm", "md"));
  const isIpadPro = useMediaQuery(theme.breakpoints.only("md"));
  const isLg = useMediaQuery(theme.breakpoints.only("lg"));
  const isXl = useMediaQuery(theme.breakpoints.only("xl"));
  const [tooltipDialogIsOpen, setTooltipDialogIsOpen] = useState(false);

  useEffect(() => {
    setFileName("");
  }, [props.resetComponent]);

  useEffect(() => {
    if (props.selectedImage?.imageId?.length <= 0 && !props.hasUploadedImage) {
      setStandardImage();
    }
  }, []);

  useEffect(() => {
    setFilteredImages(props.imageOptions);
  }, [props.imageOptions]);

  function handleDeselectFile() {
    setFileName("");
    props.onStateChanged(StateNames.stepperThreeState, {} as IUploadedFile, FieldNames.uploadedImage, true);
    setStandardImage();
  }

  function setStandardImage() {
    let standardImage = props.imageOptions?.find((x) => x.isStandard);
    props.onStateChanged(StateNames.stepperThreeState, standardImage, FieldNames.selectedImage, true);
  }

  function handleOnFileUpload(e: IUploadedFile | React.ChangeEvent<HTMLInputElement>) {
    const file = e as IUploadedFile;
    if (file !== undefined) {
      setFileName(file.name);
      props.onStateChanged(StateNames.stepperThreeState, file, FieldNames.uploadedImage, true);
      props.onStateChanged(StateNames.stepperThreeState, {} as IImageOption, FieldNames.selectedImage, true);
    }
  }

  function handleImageClicked(item: IImageOption) {
    if (props.selectedImage?.imageId !== item.imageId) {
      setFileName("");
      props.onStateChanged(StateNames.stepperThreeState, item, FieldNames.selectedImage, true);
      props.onStateChanged(StateNames.stepperThreeState, {} as IUploadedFile, FieldNames.uploadedImage, true);
    } else {
      setStandardImage();
    }
  }

  function handleFilterImagesOnOptionClicked(selectedOptions: IFilterOption[]): void {
    const selectedTagIds = selectedOptions.map((x) => (x.value as string)?.toLowerCase());
    const filteredImages = props.imageOptions?.filter((t) => {
      return (
        t.gbvImageTags?.some((x) => {
          return selectedTagIds.includes(x.toLowerCase());
        }) === true
      );
    });

    if (selectedOptions?.length === 0) {
      setFilteredImages(props.imageOptions);
    } else {
      setFilteredImages(filteredImages);
    }
  }

  function getImageSelectorHeight(): number {
    if (props.verticalMode) {
      return 400;
    } else if (isMobile) {
      return filteredImages?.length <= 3 ? 140 : filteredImages?.length > 3 && filteredImages?.length <= 6 ? 285 : 300;
    } else if (isIpad) {
      return filteredImages?.length <= 3 ? 157 : filteredImages?.length > 3 && filteredImages?.length <= 6 ? 317 : 350;
    } else if (isIpadPro) {
      return filteredImages?.length <= 3 ? 148 : filteredImages?.length > 3 && filteredImages?.length <= 6 ? 300 : 350;
    } else if (isLg) {
      return filteredImages?.length <= 3 ? 163 : filteredImages?.length > 3 && filteredImages?.length <= 6 ? 330 : 350;
    } else return filteredImages?.length <= 3 ? 190 : filteredImages?.length > 3 && filteredImages?.length <= 6 ? 384 : 400;
  }

  return (
    <Grid container item xs={12} sm={12} md={12} lg={12} xl={12}>
      {props.showTooltip && (
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ paddingTop: 30, display: "flex" }}>
          <div style={{ display: "flex" }}>
            <Typography id="tagText" variant="body1" style={{ textAlign: "left", fontWeight: "bold" }}>{`${translate(
              LabelKeys.ChooseImage
            )}:`}</Typography>
            <div style={{ paddingLeft: 10 }}>
              <CSInfo
                hoverTooltopText={props.hoverTooltopText ? props.hoverTooltopText : translate(LabelKeys.ChooseImageInfo)}
                popupTooltipText={props.popupTooltipText ? props.popupTooltipText : translate(LabelKeys.ChooseImageInfo)}
                tooltipDialogIsOpen={tooltipDialogIsOpen}
                onSetTooltipDialogIsOpen={(value) => setTooltipDialogIsOpen(value)}
              />
            </div>
          </div>
        </Grid>
      )}
      <Grid container item xs={12} sm={12} md={12} lg={12} xl={12}>
        {props.filterOptions && (
          <Grid item xs={12} sm={4} md={6} lg={4} xl={5} style={{ paddingTop: 10, width: "100%" }} className={classes.filterContainer}>
            <CSMSDropDown
              size="small"
              placeholder="Filter"
              className={props.className}
              classNameRoot={classes.dropDownMenuRoot}
              classNameInner={classes.dropDownInner}
              filterOptions={props.filterOptions}
              onFilterOptionClicked={(selectedOptions) => handleFilterImagesOnOptionClicked(selectedOptions)}
            />
          </Grid>
        )}
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <ImageList
          cols={
            props.verticalMode
              ? 1
              : isMobile
              ? 2
              : isIpad
              ? 3
              : isIpadPro
              ? props.sendBy == SendBy.EmailPDF
                ? 3
                : 2
              : isLg
              ? 2
              : isXl
              ? 2
              : 3
          }
          sx={{
            height: getImageSelectorHeight(),
            backgroundColor: "#e3e1de",
            minWidth: "100%",
            maxWidth: "100%",
          }}
        >
          {filteredImages?.map((item: IImageOption) => (
            <ImageListItem
              key={item.imageId}
              sx={{ height: isMobile ? 136 : isIpad ? 157 : isIpadPro ? 185 : isLg ? 163 : isXl ? 190 : 157 }}
              style={{
                paddingTop: isMobile ? 5 : "",
              }}
            >
              {props.selectedImage?.imageId === item?.imageId && (
                <CheckCircleIcon
                  fontSize="large"
                  className={clsx(classes.imgHover, classes.checkmarkSelectedImage)}
                  onClick={() => handleImageClicked(item)}
                />
              )}
              <img
                onClick={() => handleImageClicked(item)}
                className={clsx(classes.imgHover, props.selectedImage?.imageId === item.imageId ? classes.selected : classes.unselected)}
                src={`${item.url + QueryParamKeys.MaxWidth + 400}`}
                loading="lazy"
              />
            </ImageListItem>
          ))}
        </ImageList>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className={classes.fileUploaderContainer}>
        <CSFileUploader
          key={"previewImageFileUploader"}
          onChange={(e) => handleOnFileUpload(e)}
          isCSVFile={false}
          btnHeight={60}
          btnWidth={"100%"}
          isImageUploaded={true}
          btnTitle={translate(LabelKeys.UploadPreviewImageBtn)}
          fileTypes=".jpeg,.jpg,.png"
          maxFileSizeInBytes={MaxFileSize.TwoMB}
          classNameRoot={classes.fileUploaderRoot}
        />
      </Grid>
      {fileName && (
        <Grid item xs={6} sm={6} md={6} lg={4} xl={4} className={classes.fileNameContainer} justifyContent="flex-start">
          <Chip
            className={classes.fileName}
            label={fileName}
            variant="outlined"
            style={{ borderRadius: 0, borderColor: "#000" }}
            deleteIcon={
              <svg width="20" height="20" fill="currentColor" className="bi bi-trash" viewBox="0 0 16 16">
                <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                <path
                  fillRule="evenodd"
                  d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"
                />
              </svg>
            }
            onDelete={() => handleDeselectFile()}
          />
        </Grid>
      )}
      <Grid container item xs={12} sm={12} md={12} lg={12} xl={12} justifyContent="center" className={classes.allowedFileFormatInfo}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Typography
            variant="body2"
            style={{
              whiteSpace: "pre-line",
              textAlign: "center",
            }}
          >
            {translate(LabelKeys.AllowedFileTypes)}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Typography
            variant="body2"
            style={{
              whiteSpace: "pre-line",
              textAlign: "center",
            }}
          >
            {translate(LabelKeys.AllowedFileMaxSize)}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default CSImageSelector;
