import { LanguageCode } from "./enums/enums";
import { LocalStorageKeys } from "./history/history";

export type GEResponse<T> = {
  items: T;
  totalItems: number;
};

export function GetCurrentLanguageAsCodeFromLocalStorage(): LanguageCode {
  return (Number(localStorage.getItem(LocalStorageKeys.localizationCode)) as LanguageCode) ?? LanguageCode.SE;
}

export function GetCurrentLanguageAsText(): string {
  switch (GetCurrentLanguageAsCodeFromLocalStorage()) {
    case LanguageCode.SE:
      return "se";
    case LanguageCode.EN:
      return "en";
  }
}

export function GetLanguageAsText(languageCode: LanguageCode): string {
  switch (languageCode) {
    case LanguageCode.SE:
      return "se";
    case LanguageCode.EN:
      return "en";
    case LanguageCode.NO:
      return "no";
    case LanguageCode.FI:
      return "fi";
  }
}

export enum FieldNames {
  senderEmail = "senderEmail",
  whenToSend = "whenToSend",
  acceptedTermsOfUse = "acceptedTermsOfUse",
  emailSubject = "emailSubject",
  smsMessage = "smsMessage",
  emailMessage = "emailMessage",
  emailPDFMessage = "emailPDFMessage",
  customMessage = "customMessage",
  receiverPhoneNumbers = "receiverPhoneNumbers",
  receiverEmails = "receiverEmails",
  orderDate = "orderDate",
  sendBy = "sendBy",
  orderLines = "orderLines",
  customOrderLines = "customOrderLines",
  companyName = "companyName",
  organisationNumber = "organisationNumber",
  marking = "marking",
  includeFreight = "includeFreight",
  validationCode = "validationCode",
  contactEmail = "contactEmail",
  contactSubject = "contactSubject",
  contactMessage = "contactMessage",
  email = "email",
  password = "password",
  orderIds = "orderIds",
  orderId = "orderId",
  uploadedImage = "uploadedImage",
  amountOfReceivers = "amountOfReceivers",
  createInvoiceCustomerNumbers = "createInvoiceCustomerNumbers",
  resendReceiptsOrderIds = "resendReceiptsOrderIds",
  hasMultipleEntries = "hasMultipleEntries",
  hasMultipleCustomerNumbers = "hasMultipleCustomerNumbers",
  createInvoice = "createInvoice",
  createInvoiceFromDate = "createInvoiceFromDate",
  createInvoiceToDate = "createInvoiceToDate",
  createInvoiceReceiverEmails = "createInvoiceReceiverEmails",
  searchedOrder = "searchedOrder",
  searchedOrders = "searchedOrders",
  imageUrls = "imageUrls",
  imageTags = "imageTags",
  sendToSameReceiver = "sendToSameReceiver",
  amountOfMailings = "amountOfMailings",
  receiverEmailsPDF = "receiverEmailsPDF",
  acceptedPDFPreview = "acceptedPDFPreview",
  multipleReceiversMode = "multipleReceiversMode",
  message = "message",
  selectedMessageTemplate = "selectedMessageTemplate",
  openPDF = "openPDF",
  template = "template",
  isAddMode = "isAddMode",
  contactResellerName = "contactResellerName",
  messageSignature = "messageSignature",
  selectedImage = "selectedImage",
  subview = "subview",
  showValidationText = "showValidationText",
  templateTitleError = "templateTitleError",
  templateError = "templateError",
  clickedOnSent = "clickedOnSent",
  searchParameter = "searchParameter",
  orderRows = "orderRows",
  searchOrderFromDate = "searchOrderFromDate",
  searchOrderToDate = "searchOrderToDate",
  isStandard = "isStandard",
  templateOptions = "templateOptions",
  isEditMode = "isEditMode",
  resellerUrl = "resellerUrl",
  isAutomaticlySet = "isAutomaticlySet",
  activeStep = "activeStep",
  systemPart = "systemPart",
  productCategory = "productCategory",
  stepperState = "stepperState",
  selectedProduct = "selectedProduct",
  paymentMethod = "paymentMethod",
  selectedProductCategory = "selectedProductCategory",
  stepOne = "stepOne",
  stepTwo = "stepTwo",
  stepThree = "stepThree",
  stepFour = "stepFour",
  stepFive = "stepFive",
  stepSix = "stepSix",
  errorSections = "errorSections",
  readyToPay = "readyToPay",
  messageTemplates = "messageTemplates",
  previewImageOptions = "previewImageOptions",
  companyContactPhoneNumber = "companyContactPhoneNumber",
  customWebPage = "customWebPage",
  customWebPageDialogOpen = "customWebPageDialogOpen",
  acceptedCustomWebPage = "acceptedCustomWebPage",
  isActive = "isActive",
  customerNumber = "customerNumber",
  discount = "discount",
  contactEmails = "contactEmails",
  amount = "amount",
  resellerIncomingOrderEmails = "resellerIncomingOrderEmails",
  logotypeAsBase64 = "logotypeAsBase64",
  logotypeFileType = "logotypeFileType",
  homePageImageAsBase64 = "homePageImageAsBase64",
  homePageImageFileType = "homePageImageFileType",
  receiverEmailsPDFSendToSameReceiver = "receiverEmailsPDFSendToSameReceiver",
  addressLine1 = "addressLine1",
  postalCode = "postalCode",
  city = "city",
  country = "country",
  website = "website",
  contactPhoneNumbers = "contactPhoneNumbers",
  emails = "emails",
  homePageHeaderText = "homePageHeaderText",
  homePageBodyText = "homePageBodyText",
  title = "title",
  physicalCardCustomMessage = "physicalCardCustomMessage",
  physicalCardHeading = "physicalCardHeading",
  physicalCardSelectedCategory = "physicalCardSelectedCategory",
  physicalCardHeadingSuggestion = "physicalCardHeadingSuggestion",
  physicalCardCustomMessageSuggestion = "physicalCardCustomMessageSuggestion",
  physicalCardHeadingTemplates = "physicalCardHeadingTemplates",
  physicalCardCustomMessageTemplates = "physicalCardCustomMessageTemplates",
  physicalCardFilteredCoverImages = "physicalCardFilteredCoverImages",
  physicalCardSelectedBackImage = "physicalCardSelectedBackImage",
  physicalCardSelectedMiddlePageImage = "physicalCardSelectedMiddlePageImage",
  physicalCardSelectedUploadedCoverImage = "physicalCardSelectedUploadedCoverImage",
  physicalCardSelectedUploadedLogotype = "physicalCardSelectedUploadedLogotype",
  physicalCardSelectedCoverImage = "physicalCardSelectedCoverImage",
  categoryFilterOptions = "categoryFilterOptions",
  addressLine = "addressLine",
  isPhysicalCardEditorPopupOpen = "isPhysicalCardEditorPopupOpen",
  externalSystemDetails = "externalSystemDetails",
  selectedExternalSystemRow = "selectedExternalSystemRow",
  selectedPreviewImageCategory = "selectedPreviewImageCategory",
  templateType = "templateType",
  selectedTemplateType = "selectedTemplateType",
  name = "name",
  id = "id",
  adminSelectedResellerCountryOption = "adminSelectedResellerCountryOption",
  deliveryCustomerContactName = "deliveryCustomerContactName",
}

export enum StateNames {
  resellerInformationState = "resellerInformationState",
  messageState = "messageState",
  deliveryState = "deliveryState",
  termsOfUseState = "termsOfUseState",
  resellerState = "resellerState",
  privateState = "privateState",
  companyState = "companyState",
  stepperState = "stepperState",
  environmentState = "environmentState",
  stepperOneState = "stepperOneState",
  stepperTwoState = "stepperTwoState",
  stepperThreeState = "stepperThreeState",
  stepperFourState = "stepperFourState",
  stepperFiveState = "stepperFiveState",
  stepperSixState = "stepperSixState",
}

export interface UserSession {
  adminUserId?: number;
  resellerContactId?: number;
  resellerId?: number;
}
