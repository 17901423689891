import { ICountryOption, IFilterOption } from "./../../components/DropDown/types";
import { Country, LanguageCode } from "./../../Utils/enums/enums";
import { ErrorReason } from "../../Utils/enums/enums";
import { FileType } from "../../components/FileUploader/types";
import { CoupledResellerOrder } from "../../views/Reseller/types";
import { IAddResellerContactDto } from "../../views/Admin/types";

export interface ICreateResellerRequest {
  customerNumber: string;
  companyName: string;
  discount: number;
  emails: string[];
}

export interface ICreateResellerResponse {
  error: ErrorReason;
}

export interface IGetResellerRequest {
  customerNumber: string;
}

export interface IGetResellerResponse {
  id: number;
  customerNumber: string;
  discount: number;
  companyName: string;
  isActive: boolean;
}

export interface IUpdateResellerRequest {
  id: number;
  customerNumber: string;
  discount: number;
  companyName: string;
  isActive: boolean;
}

export interface IUpdateResellerResponse {
  error: ErrorReason;
}
export interface IGetResellerLogoRequest {
  resellerId?: number;
}
export interface IGetResellerLogoResponse {
  file: string;
  fileType: FileType;
}

export interface IDeleteResellerLogoRequest {
  resellerId?: number;
}

export interface IGetResellerLogoByCodeRequest {
  resellerCode: string;
}
export interface IGetResellerLogoByCodeResponse {
  file: string;
  fileType: FileType;
}

export interface IGetResellerAdminContactInfoRequest {
  language: LanguageCode;
}

export interface IGetResellerAdminContactInfoResponse {
  url: string;
  discount: number;
  resellerId: number;
  code: string;
}

export interface ICreateResellerContactRequest {
  resellerId: number;
  resellerContacts: IAddResellerContactDto[];
  requestFrom: RequestFrom;
}

export interface ICreateResellerContactResponse {
  error: ErrorReason;
}

export interface IGetResellerContactRequest {
  email: string;
}

export interface IGetResellerContactResponse {
  id: number;
  email: string;
  isActive: boolean;
}

export interface IUpdateResellerContactRequest {
  id: number;
  email: string;
  isActive: boolean;
  resellerId: number;
  requestFrom: RequestFrom;
}

export interface IUpdateResellerContactResponse {
  error: ErrorReason;
}

export interface IUpdateResellerLogoRequest {
  file: string;
  fileType: FileType;
  resellerId?: number;
}

export interface IActiveReseller {
  id: number;
  companyName: string;
  code: string;
}

export interface IGetResellerByCodeRequest {
  code: string;
}

export interface IGetResellerByCodeResponse {
  id: number;
  companyName: string;
  code: string;
}

export interface ISearchResellersRequest {
  searchParameter: string;
  isActive: boolean;
  skip: number;
  take: number;
}
export interface IGetResellersRequest {
  searchParameter: string;
  isActive: boolean;
}

export interface IGetResellersResponse {
  resellers: IReseller[];
}
export interface ISearchResellersResponse {}

export interface ISearchSingleOrderRequest {
  orderId: number;
}
export interface ISearchSingleOrderResponse {}

export interface ISearchSingleResellerRequest {
  resellerId: number;
}

export interface ISearchSingleResellerResponse {}

export interface IDeleteResellerContactRequest {
  resellerContactId: number;
  resellerId: number;
  requestFrom: RequestFrom;
}

//Get incoming reseller order emails
export interface IGetResellerIncomingOrderEmailsRequest {
  resellerId: number;
}
export interface IGetResellerIncomingOrderEmailsResponse {
  emails: string[];
}

//Update incoming reseller order emails
export interface IUpdateResellerIncomingOrderEmailsRequest {
  resellerId: number;
  emails: string[];
}

//Get reseller home page image
export interface IGetResellerHomePageImageRequest {
  resellerId?: number;
}
export interface IGetResellerHomePageImageResponse {
  url: string;
}

//Get reseller home page image by reseller code
export interface IGetResellerHomePageImageByCodeRequest {
  code: string;
  isMobile: boolean;
}
export interface IGetResellerHomePageImageByCodeResponse {
  url: string;
}

//Update reseller home page image
export interface IUpdateResellerHomePageImageRequest {
  file: string;
  fileType: FileType;
  resellerId?: number;
}
export interface IUpdateResellerHomePageImageResponse {}

//Delete reseller home page image
export interface IDeleteResellerHomePageImageRequest {
  resellerId: number;
}
export interface IDeleteResellerHomePageImageResponse {}

//Search coupled reseller orders
export interface ISearchResellerOrdersRequest {
  resellerId?: number;
  skip: number;
  take: number;
}
export interface ISearchResellerOrdersResponse {
  orders: CoupledResellerOrder;
  error: ErrorReason;
}

export interface IReseller {
  id: number;
  companyName: string;
  customerNumber: string;
}

export interface IResellerOption {
  id: number;
  name: string;
  customerNumber: string;
  discount: number;
}

export interface IResellerCountryOption {
  id: number;
  name: string;
  value: LanguageCode;
}

export interface ISetSelectedAdminResellerOptionRequest {
  reseller: IFilterOption;
}

export interface ISetSelectedAdminResellerCountryOptionRequest {
  country: ICountryOption;
}

export interface IGetResellerContactPageInformtaionRequest {
  resellerId?: number;
  resellerCode?: string;
}
export interface IGetResellerContactPageInformtaionResponse {
  contactEmails: string[];
  contactPhoneNumbers: string[];
  addressLine1: string;
  postalCode: string;
  city: string;
  country: string;
  website: string;
}
export interface IUpdateResellerContactPageInformtaionRequest {
  resellerId?: number;
  contactEmails: string[];
  contactPhoneNumbers: string[];
  addressLine1: string;
  postalCode: string;
  city: string;
  country: string;
  website: string;
}

export interface IGetResellerHomePageTextsRequest {
  resellerId?: number;
}

export interface IGetResellerHomePageTextsByCodeRequest {
  code: string;
}

export interface IGetResellerHomePageTextsByCodeResponse {
  homePageHeaderText: string;
  homePageBodyText: string;
}

export interface IGetResellerHomePageTextsResponse {
  homePageHeaderText: string;
  homePageBodyText: string;
}

export interface IUpdateResellerHomePageTextsRequest {
  resellerId?: number;
  homePageHeaderText: string;
  homePageBodyText: string;
}

export interface IGetResellerContactsResponse {
  contacts: IResellerContact[];
}

export interface IResellerContact {
  id: number;
  email: string;
  isActive: boolean;
}

export interface IGetResellerContactsRequest {
  resellerId: number;
}

export enum RequestFrom {
  UserAdmin = 0,
  ResellerAdmin = 1,
}

export interface IValidateResellerEmailRequest {
  email: string;
}
export interface IValidateResellerEmailResponse {
  error: ErrorReason;
}
export interface IGetAllResellersResponse {
  resellers: IResellerOption[];
}
