import { ISampleAssortment } from "./types";

export function GetSampleAssortmentArray(key: string): ISampleAssortment[] {
  let valueArray: ISampleAssortment[] = [];
  if (key.includes("Nobel")) {
    valueArray = GetNobelSampleAssortmentArray();
  }

  if (key.includes("Classic")) {
    valueArray = GetClassicSampleAssortmentArray();
  }

  if (key.includes("Regal")) {
    valueArray = GetRegalSampleAssortmentArray();
  }

  if (key.includes("Extravagans")) {
    valueArray = GetExtravagansSampleAssortmentArray();
  }

  if (key.includes("Imperial")) {
    valueArray = GetImperialSampleAssortmentArray();
  }

  if (key.includes("Prestige")) {
    valueArray = GetPrestigeSampleAssortmentArray();
  }

  if (key.includes("Crystal")) {
    valueArray = GetCrystalSampleAssortmentArray();
  }

  if (key.includes("Low impact")) {
    valueArray = GetLowImpactSampleAssortmentArray();
  }

  if (key.includes("Vinga Home 100")) {
    valueArray = GetVingaHome100();
  }
  if (key.includes("Vinga Home 150")) {
    valueArray = GetVingaHome150();
  }
  if (key.includes("Vinga Home 300")) {
    valueArray = GetVingaHome300();
  }
  if (key.includes("Vinga Home 500")) {
    valueArray = GetVingaHome500();
  }
  if (key.includes("Vinga Home 1000")) {
    valueArray = GetVingaHome1000();
  }
  if (key.includes("Vinga Home 1250")) {
    valueArray = GetVingaHome1250();
  }
  if (key.includes("Vinga Home 2500")) {
    valueArray = GetVingaHome2500();
  }

  return valueArray;
}

function GetNobelSampleAssortmentArray(): ISampleAssortment[] {
  return [
    {
      imageUrl: "f2632cd0-ff30-4f78-8e98-70a864e7642b",
      redirectUrl: "https://getmygift.se/se/nobel",
      title: "",
    },
    {
      imageUrl: "41f9d46e-6492-4010-a8b8-4157015de6f5",
      redirectUrl: "https://getmygift.se/se/nobel",
      title: "",
    },
    {
      imageUrl: "b751c2eb-013d-4cb2-b846-cba2ebd0b97b",
      redirectUrl: "https://getmygift.se/se/nobel",
      title: "",
    },
    {
      imageUrl: "f26d896c-79b1-46ba-9a0b-1e2601ea743c",
      redirectUrl: "https://getmygift.se/se/nobel",
      title: "",
    },
  ] as ISampleAssortment[];
}

function GetClassicSampleAssortmentArray(): ISampleAssortment[] {
  return [
    {
      imageUrl: "88667fda-53f1-4e29-aa95-998c7bbe3e59",
      redirectUrl: "https://getmygift.se/se/classic",
      title: "",
    },
    {
      imageUrl: "7665c049-e5df-42ae-ab96-9043154cc5a8",
      redirectUrl: "https://getmygift.se/se/classic",
      title: "",
    },
    {
      imageUrl: "835f2c23-385d-4cb8-85a5-3c66694e788b",
      redirectUrl: "https://getmygift.se/se/classic",
      title: "",
    },
    {
      imageUrl: "e492b934-e225-4f0d-a913-63451f021349",
      redirectUrl: "https://getmygift.se/se/classic",
      title: "",
    },
  ] as ISampleAssortment[];
}

function GetRegalSampleAssortmentArray(): ISampleAssortment[] {
  return [
    {
      imageUrl: "31309c6f-a166-4073-9f30-ef74eec1fdac",
      redirectUrl: "https://getmygift.se/se/regal",
      title: "",
    },
    {
      imageUrl: "180577f7-f9e4-4cbe-af80-80a8d2c534c5",
      redirectUrl: "https://getmygift.se/se/regal",
      title: "",
    },
    {
      imageUrl: "01fc58c5-2f55-4fdd-a75c-fee95934212e",
      redirectUrl: "https://getmygift.se/se/regal",
      title: "",
    },
    {
      imageUrl: "29d276f7-9f4c-42cd-a4f5-1e798ba07514",
      redirectUrl: "https://getmygift.se/se/regal",
      title: "",
    },
  ] as ISampleAssortment[];
}

function GetExtravagansSampleAssortmentArray(): ISampleAssortment[] {
  return [
    {
      imageUrl: "c36e1f37-34a2-4ff3-8510-aa61772b30a7",
      redirectUrl: "https://getmygift.se/se/extravagans",
      title: "",
    },
    {
      imageUrl: "c1728aa7-203e-42c7-b2b7-6547579b1ea1",
      redirectUrl: "https://getmygift.se/se/extravagans",
      title: "",
    },
    {
      imageUrl: "b662c6b2-d9a5-4cba-8447-c1019a21b4f8",
      redirectUrl: "https://getmygift.se/se/extravagans",
      title: "",
    },
    {
      imageUrl: "73da9df9-b040-41c9-8417-06fcce0c9eb3",
      redirectUrl: "https://getmygift.se/se/extravagans",
      title: "",
    },
  ] as ISampleAssortment[];
}

function GetImperialSampleAssortmentArray(): ISampleAssortment[] {
  return [
    {
      imageUrl: "544bc24a-5a80-47a0-bd2b-c6a13a80db06",
      redirectUrl: "https://getmygift.se/se/imperial",
      title: "",
    },
    {
      imageUrl: "882dc264-26bc-4d83-92aa-f65cb579ea30",
      redirectUrl: "https://getmygift.se/se/imperial",
      title: "",
    },
    {
      imageUrl: "f7efca4a-c731-43aa-ad59-5a079e41d0ad",
      redirectUrl: "https://getmygift.se/se/imperial",
      title: "",
    },
    {
      imageUrl: "e68c3c2c-36e7-4d6e-9064-a7539ab1767e",
      redirectUrl: "https://getmygift.se/se/imperial",
      title: "",
    },
  ] as ISampleAssortment[];
}

function GetPrestigeSampleAssortmentArray(): ISampleAssortment[] {
  return [
    {
      imageUrl: "9b96b9f5-7a8c-48df-9e54-b29d79732703",
      redirectUrl: "https://getmygift.com/se/prestige",
      title: "",
    },
    {
      imageUrl: "e28148d2-a01e-478c-b158-90066351971c",
      redirectUrl: "https://getmygift.com/se/prestige",
      title: "",
    },
    {
      imageUrl: "65527914-7ec0-47d6-93a4-bb2b96363366",
      redirectUrl: "https://getmygift.com/se/prestige",
      title: "",
    },
    {
      imageUrl: "2ffb4a9b-1394-4c94-a035-f9bbc4f5c3bf",
      redirectUrl: "https://getmygift.com/se/prestige",
      title: "",
    },
  ] as ISampleAssortment[];
}

function GetCrystalSampleAssortmentArray(): ISampleAssortment[] {
  return [
    {
      imageUrl: "717c5a2a-45bf-4c00-95f7-9d8b2f9595a7",
      redirectUrl: "https://getmygift.se/se/crystal",
      title: "",
    },
    {
      imageUrl: "668352ea-9a3b-4cef-975c-a5bd739e6258",
      redirectUrl: "https://getmygift.se/se/crystal",
      title: "",
    },
    {
      imageUrl: "92848349-e6fc-4c56-949a-8b5e9585e203",
      redirectUrl: "https://getmygift.se/se/crystal",
      title: "",
    },
    {
      imageUrl: "cfb48aaa-1b6f-4136-813e-33e794a54f84",
      redirectUrl: "https://getmygift.se/se/crystal",
      title: "",
    },
  ] as ISampleAssortment[];
}

function GetLowImpactSampleAssortmentArray(): ISampleAssortment[] {
  return [
    {
      imageUrl: "6eb34d8a-e1ef-4bc7-ac9a-9a16e6af1438",
      redirectUrl: "https://getmygift.se/se/lowimpact",
      title: "",
    },
    {
      imageUrl: "b3e7a2cb-2d27-45d5-9568-284869549e3e",
      redirectUrl: "https://getmygift.se/se/lowimpact",
      title: "",
    },
    {
      imageUrl: "44e53a7f-9a1b-46cf-975d-65a40e2159d5",
      redirectUrl: "https://getmygift.se/se/lowimpact",
      title: "",
    },
    {
      imageUrl: "7f0ee668-6f9a-4e59-9e42-098f3449e779",
      redirectUrl: "https://getmygift.se/se/lowimpact",
      title: "",
    },
  ] as ISampleAssortment[];
}

function GetVingaHome100(): ISampleAssortment[] {
  return [
    {
      imageUrl: "5cf58c75-cca9-45e5-9163-5f490f53a29e",
      redirectUrl: "",
      title: "",
    },
    {
      imageUrl: "2fff3560-b93d-4ffa-a9e5-d986a50b29c3",
      redirectUrl: "",
      title: "",
    },
    {
      imageUrl: "1e410564-9ecb-48ec-acea-6e2a513f957c",
      redirectUrl: "",
      title: "",
    },
    {
      imageUrl: "52fbb363-5a60-477f-b6d4-d909e35a5465",
      redirectUrl: "",
      title: "",
    },
  ] as ISampleAssortment[];
}

function GetVingaHome150(): ISampleAssortment[] {
  return [
    {
      imageUrl: "f2632cd0-ff30-4f78-8e98-70a864e7642b",
      redirectUrl: "",
      title: "",
    },
    {
      imageUrl: "41f9d46e-6492-4010-a8b8-4157015de6f5",
      redirectUrl: "",
      title: "",
    },
    {
      imageUrl: "b751c2eb-013d-4cb2-b846-cba2ebd0b97b",
      redirectUrl: "",
      title: "",
    },
    {
      imageUrl: "f26d896c-79b1-46ba-9a0b-1e2601ea743c",
      redirectUrl: "",
      title: "",
    },
  ] as ISampleAssortment[];
}

function GetVingaHome300(): ISampleAssortment[] {
  return [
    {
      imageUrl: "5fbd8875-469f-4ea3-b409-9a40d1c06a67",
      redirectUrl: "",
      title: "",
    },
    {
      imageUrl: "f9527447-9e0d-445c-8b35-6d3cd93b0566",
      redirectUrl: "",
      title: "",
    },
    {
      imageUrl: "e9c6bd5e-d6a5-4ca0-b820-2bdbdef02241",
      redirectUrl: "",
      title: "",
    },
    {
      imageUrl: "7967270b-abeb-4e07-a0ea-89d25bbb5531",
      redirectUrl: "",
      title: "",
    },
  ] as ISampleAssortment[];
}

function GetVingaHome500(): ISampleAssortment[] {
  return [
    {
      imageUrl: "aedf5272-a44a-442e-97b5-d4cfd166b0e8",
      redirectUrl: "",
      title: "",
    },
    {
      imageUrl: "a8b5fcc6-07aa-4b95-b89d-d99dde8f1713",
      redirectUrl: "",
      title: "",
    },
    {
      imageUrl: "99f023fd-87c7-4628-8f38-82e7b9c6b467",
      redirectUrl: "",
      title: "",
    },
    {
      imageUrl: "ecc5a803-66b7-4eea-bdd8-7488facd99a6",
      redirectUrl: "",
      title: "",
    },
  ] as ISampleAssortment[];
}

function GetVingaHome1000(): ISampleAssortment[] {
  return [
    {
      imageUrl: "8cb5c682-80cd-4548-bf61-8e8ee98d7fe9",
      redirectUrl: "",
      title: "",
    },
    {
      imageUrl: "81688b92-ab87-46ad-af0d-7302ea71ea8e",
      redirectUrl: "",
      title: "",
    },
    {
      imageUrl: "144075dd-6631-404d-8d7a-a72557ab4b33",
      redirectUrl: "",
      title: "",
    },
    {
      imageUrl: "8fb1859f-05b0-4f5c-93fe-609dd750cf0a",
      redirectUrl: "",
      title: "",
    },
  ] as ISampleAssortment[];
}

function GetVingaHome1250(): ISampleAssortment[] {
  return [
    {
      imageUrl: "e4cf8a1f-442f-4558-9d41-f8653cf3dc31",
      redirectUrl: "",
      title: "",
    },
    {
      imageUrl: "9032612d-a757-4649-b7dc-5249a50aa9bd",
      redirectUrl: "",
      title: "",
    },
    {
      imageUrl: "3ed1a58e-3577-4eaf-960f-8835d0b0cea8",
      redirectUrl: "",
      title: "",
    },
    {
      imageUrl: "278f4819-e742-48ea-a214-9e318c424eee",
      redirectUrl: "",
      title: "",
    },
  ] as ISampleAssortment[];
}

function GetVingaHome2500(): ISampleAssortment[] {
  return [
    {
      imageUrl: "13a0f179-4040-4f9f-9fee-32e5cdd710c7",
      redirectUrl: "",
      title: "",
    },
    {
      imageUrl: "cbe97fa2-1d51-4592-b897-5c55516bade9",
      redirectUrl: "",
      title: "",
    },
    {
      imageUrl: "da686665-ccb4-4cc7-ab7c-c4779d29c77e",
      redirectUrl: "",
      title: "",
    },
    {
      imageUrl: "03f411e8-4c90-4e22-83d0-cdf797b841c4",
      redirectUrl: "",
      title: "",
    },
  ] as ISampleAssortment[];
}
