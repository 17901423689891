import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store";
import { Button, Grid, Typography, useMediaQuery } from "@mui/material";
import { ProductActions } from "../../Modules/Products/actions";
import { useNavigate } from "react-router";
import { LabelKeys } from "../../Utils/enums/labelKeys";
import { useTranslation } from "react-i18next";
import { PrivateStyles } from "./styles";
import AboutSummary from "../../components/Information/AboutSummary";
import { theme } from "../../appTheme";
import ProductCategoryItem from "../../components/ProductCategory/ProductCategoryItem";
import { IGetProductsRequest, IProduct, IProductCategory, ProductCategory } from "../../Modules/Products/types";
import HowItWorksHomePage from "../HowItWorks/HowItWorksHomePage";
import { Currency, LanguageCode, SystemPart } from "../../Utils/enums/enums";
import ImageTextContentBlock from "../../components/LeftSideRightSideContentBlock/ImageTextContentBlock";
import TextImageContentBlock from "../../components/LeftSideRightSideContentBlock/TextImageContentBlock";
import Cookies from "js-cookie";
import { CookieConstants } from "../../Utils/Labels/types";
import { OrderActions } from "../../Modules/Orders/actions";
import { FieldNames, GetCurrentLanguageAsCodeFromLocalStorage, GetCurrentLanguageAsText } from "../../Utils/types";
import ExampleAssortment from "../../components/ExampleAssortment/ExampleAssortment";
import { GetLitiumImageUrl } from "../../Utils/extensions";
import { ImageKeys } from "../../Utils/enums/imageKeys";
import { QueryParamKeys } from "../../Utils/enums/queryParams";
import { CheckSystemPartCookie, IsAuth, IsResellerAuth } from "../../Utils/authentication";
import Linkify from "linkify-react";
import { ResellerActions } from "../../Modules/Reseller/actions";
import { IGetResellerHomePageTextsByCodeRequest } from "../../Modules/Reseller/types";

function Home() {
  const dispatch = useDispatch();
  const classes = PrivateStyles();
  const navigate = useNavigate();
  const { t: translate } = useTranslation();
  const reducerState = useSelector(
    (s: RootState) => {
      return {
        product: s.product,
        order: s.order,
        reseller: s.reseller,
      };
    },
    (prev, next) =>
      prev.product.products === next.product.products &&
      prev.product.productCategories === next.product.productCategories &&
      prev.product.selectedProduct === next.product.selectedProduct &&
      prev.product.selectedProductCategory === next.product.selectedProductCategory &&
      prev.order.environmentState.systemPart === next.order.environmentState.systemPart &&
      prev.reseller.homePageImageUrl === next.reseller.homePageImageUrl
  );
  const isMobile = useMediaQuery(theme.breakpoints.only("xs"));
  const isSm = useMediaQuery(theme.breakpoints.only("sm"));
  const isMd = useMediaQuery(theme.breakpoints.only("md"));
  const gettingStartedScrollToRef = useRef(null);
  const systemPart: SystemPart = Number(Cookies.get(CookieConstants.SystemPart));
  const isSystemAdmin = IsAuth() && location.pathname.includes(`/${translate(LabelKeys.AdminUrl)}/${translate(LabelKeys.OrderUrl)}`);
  const isResellerAdmin =
    IsResellerAuth() &&
    location.pathname.includes(`/${translate(LabelKeys.ResellerUrl)}/${translate(LabelKeys.AdminUrl)}/${translate(LabelKeys.OrderUrl)}`);

  const [selectedProduct, setSelectedProduct] = useState({} as IProduct);
  const [selectedProductCategory, setSelectedProductCategory] = useState({} as IProductCategory);
  const [homePageImage, setHomePageImage] = useState("");

  //UseEffects
  useEffect(() => {
    if (reducerState.product.productCategories?.length <= 0 && reducerState.order.environmentState?.systemPart !== undefined) {
      dispatch(ProductActions.GetProductCategoriesRequest());
    }
  }, [Cookies.get(CookieConstants.SystemPart)]);

  useEffect(() => {
    CheckSystemPartCookie();
    // window.scroll({ top: 0, behavior: "smooth" });
    if (systemPart != reducerState.order.environmentState.systemPart) {
      dispatch(OrderActions.SetEnvironmentStateRequest({ fieldName: FieldNames.systemPart, value: systemPart }));
    }
  }, [systemPart]);

  useEffect(() => {
    // window.scroll(0, 0);
    if (reducerState.product.productCategories?.length <= 0 && Cookies.get(CookieConstants.SystemPart)?.length > 0) {
      dispatch(ProductActions.GetProductCategoriesRequest());
    }

    //Get reseller home page if customer has resellerCode cookie otherwise get standard
    const resellerCodeCookieValue = Cookies.get(CookieConstants.GBVReseller);
    if (resellerCodeCookieValue != undefined && resellerCodeCookieValue?.length > 0 && systemPart == SystemPart.Company) {
      dispatch(ResellerActions.GetResellerHomePageImageByCodeRequest({ code: resellerCodeCookieValue, isMobile: isMobile }));
    } else {
      setHomePageImage(
        GetLitiumImageUrl(
          isMobile ? ImageKeys.HomePageHeaderMobile : ImageKeys.HomePageHeaderDesktop,
          isMobile ? `${QueryParamKeys.MaxWidth}2002` : `${QueryParamKeys.MaxWidth}3502`
        )
      );
    }
    if (
      (reducerState.reseller.homePageHeaderText?.length <= 0 || reducerState.reseller.homePageBodyText?.length <= 0) &&
      systemPart == SystemPart.Company &&
      Cookies.get(CookieConstants.GBVReseller)?.length > 0
    ) {
      dispatch(
        ResellerActions.GetResellerHomePageTextsByCodeRequest({
          code: Cookies.get(CookieConstants.GBVReseller),
        } as IGetResellerHomePageTextsByCodeRequest)
      );
    }
  }, []);

  useEffect(() => {
    if (reducerState.reseller.homePageImageUrl?.length > 0) {
      setHomePageImage(reducerState.reseller.homePageImageUrl);
    }
  }, [reducerState.reseller.homePageImageUrl]);

  useEffect(() => {
    if (reducerState.product.productCategories?.length > 0) {
      setSelectedProductCategory(reducerState.product.productCategories.find((x) => x.category == ProductCategory.GiftCardGMG));
    }
  }, [reducerState.product.productCategories]);

  useEffect(() => {
    if (selectedProductCategory?.id > 0) {
      dispatch(
        ProductActions.GetProductsRequest({
          language: GetLanguage(),
          systemPart: systemPart,
          productCategoryId: selectedProductCategory?.id,
        } as IGetProductsRequest)
      );
    }
  }, [selectedProductCategory]);

  useEffect(() => {
    scrollGiftCardContainer();

    if (reducerState.product.products?.length > 0) {
      const product = reducerState.product.products.find((x) => x.category == ProductCategory.GiftCardGMG && x?.name.includes("Regal"));
      if (product?.id > 0) setSelectedProduct(product);
    }
  }, [reducerState.product.products]);

  function GetLanguage() {
    if (isSystemAdmin) {
      return reducerState.reseller.adminSelectedResellerCountryOption?.value != LanguageCode.Unknown
        ? reducerState.reseller.adminSelectedResellerCountryOption?.value
        : LanguageCode.SE;
    } else return GetCurrentLanguageAsCodeFromLocalStorage();
  }

  function scrollGiftCardContainer() {
    let element = document.getElementById("giftcardsContainer");
    if (element !== undefined) {
      element.scrollLeft = 375;
    }
  }

  function setOrderState(selectedProduct: IProduct, selectedProductCategory: IProductCategory) {
    if (selectedProductCategory?.id !== undefined) {
      dispatch(
        OrderActions.SetStepOneStateRequest({
          fieldName: FieldNames.selectedProductCategory,
          value: selectedProductCategory,
        })
      );
    } else {
      dispatch(
        OrderActions.SetStepOneStateRequest({
          fieldName: FieldNames.selectedProductCategory,
          value: {} as IProductCategory,
        })
      );
    }
    if (selectedProduct?.id !== undefined) {
      dispatch(
        OrderActions.SetStepTwoStateRequest({
          fieldName: FieldNames.selectedProduct,
          value: selectedProduct,
        })
      );
    } else {
      dispatch(
        OrderActions.SetStepTwoStateRequest({
          fieldName: FieldNames.selectedProduct,
          value: {} as IProduct,
        })
      );
    }

    if (selectedProduct?.id > 0 && selectedProductCategory?.id > 0) {
      dispatch(
        OrderActions.SetStepperStateRequest({
          fieldName: FieldNames.activeStep,
          value: 2,
        })
      );
    } else if (selectedProductCategory?.id > 0) {
      dispatch(
        OrderActions.SetStepperStateRequest({
          fieldName: FieldNames.activeStep,
          value: 1,
        })
      );
    }
  }

  //Functions
  function onProductCategoryClicked(selectedProductCategory: IProductCategory) {
    if (selectedProductCategory) {
      ResetAllOrderValues();
      setOrderState(undefined, selectedProductCategory);
      navigate(`/${GetCurrentLanguageAsText()}/${translate(LabelKeys.OrderUrl)}`);
    }
  }

  function GetTextSection(labelKeyTitle: LabelKeys | string, labelKeyBody: LabelKeys | string): React.ReactNode {
    const options = {
      target: "_blank",
    };

    return (
      <div>
        <Typography variant="h2"> {translate(labelKeyTitle)}</Typography>
        <Linkify options={options}>
          <Typography
            style={{
              paddingTop: 20,
              fontSize: 20,
              whiteSpace: "pre-wrap",
              color: "#000",
            }}
          >
            {translate(labelKeyBody)}
          </Typography>
        </Linkify>
      </div>
    );
  }

  function GetImageSection(src: string, height?: number, width?: number, imageMaxWidth?: number): React.ReactNode {
    return (
      <img
        style={{
          backgroundRepeat: "no-repeat",
          objectFit: isMobile ? "none" : "contain",
          width: width ?? "100%",
        }}
        src={`${GetLitiumImageUrl(src, "")}${imageMaxWidth > 0 ? "?maxWidth=" + imageMaxWidth : ""}`}
      />
    );
  }

  function ResetAllOrderValues() {
    dispatch(OrderActions.ResetStepperState());
    dispatch(OrderActions.ResetStepOneState());
    dispatch(OrderActions.ResetStepTwoState());
    dispatch(OrderActions.ResetStepThreeState());
    dispatch(OrderActions.ResetStepFourState());
    dispatch(OrderActions.ResetStepFiveState());
    dispatch(OrderActions.ResetStepSixState());
  }

  function ResetProductValues() {
    setSelectedProduct({} as IProduct);
    setSelectedProductCategory({} as IProductCategory);
  }

  return (
    <Grid container justifyContent="center">
      <Grid container item xs={12} sm={12} md={12} lg={12} xl={12}>
        <div style={{ backgroundColor: "#000", height: "100vh", width: "100vw" }}>
          {homePageImage && (
            <div style={{ zIndex: 1, position: "relative" }}>
              <img style={{ opacity: 0.7 }} src={homePageImage} className={classes.homePageImg} />
            </div>
          )}
          <Grid item xs={12} sm={9} md={10} lg={9} xl={9} justifyContent="center" style={{ margin: "auto" }}>
            <div className={classes.imageTitleText}>
              <div className={classes.imageBodyText}>
                <Typography
                  style={{
                    fontSize: isMobile || isSm ? 38 : 55,
                    color: "#fff",
                    fontFamily: "Butler",
                    paddingTop: systemPart == SystemPart.Private ? 90 : 0,
                  }}
                >
                  {systemPart == SystemPart.Private
                    ? translate(LabelKeys.TheFinestYouCouldGivePrivate)
                    : systemPart == SystemPart.Company &&
                      (Cookies.get(CookieConstants.GBVReseller)?.length <= 0 || Cookies.get(CookieConstants.GBVReseller) == undefined)
                    ? translate(LabelKeys.TheFinestYouCouldGiveCompany)
                    : reducerState.reseller.homePageHeaderText}
                </Typography>
              </div>
              <Typography
                variant="h5"
                style={{
                  textAlign: "left",
                  paddingTop: 20,
                  maxWidth: 500,
                  fontWeight: 200,
                  textTransform: "none",
                  fontFamily: "Mulish",
                  color: "#fff",
                }}
              >
                {systemPart == SystemPart.Private
                  ? translate(LabelKeys.TheFinestYouCouldGivePrivateDescription)
                  : systemPart == SystemPart.Company &&
                    (Cookies.get(CookieConstants.GBVReseller)?.length <= 0 || Cookies.get(CookieConstants.GBVReseller) == undefined)
                  ? translate(LabelKeys.TheFinestYouCouldGiveCompanyDescription)
                  : reducerState.reseller.homePageBodyText}
              </Typography>
              <Button
                id="gettingStartedBtn"
                style={{
                  height: 60,
                  border: "1px solid #fff",
                  cursor: "pointer",
                  color: "#fff",
                  display: "flex",
                  marginTop: 20,
                  borderRadius: 0,
                }}
                className={classes.gettingStartedBtn}
                onClick={() => {
                  gettingStartedScrollToRef.current.scrollIntoView({
                    behavior: "smooth",
                  });
                }}
              >
                <div style={{ display: "flex", margin: "auto" }}>
                  <div
                    style={{
                      paddingLeft: 50,
                      paddingTop: 9,
                      minWidth: 100,
                      textTransform: "none",
                    }}
                  >
                    <Typography variant="body1" style={{ color: "#fff" }}>
                      {translate(LabelKeys.GettingStarted)}
                    </Typography>
                  </div>
                  <div
                    style={{
                      paddingRight: 18,
                      paddingTop: 5,
                    }}
                  >
                    <svg width="35" height="35" fill="currentColor" className="bi bi-arrow-right-short" viewBox="0 0 16 16">
                      <path
                        fillRule="evenodd"
                        d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z"
                      />
                    </svg>
                  </div>
                </div>
              </Button>
            </div>
          </Grid>
        </div>
      </Grid>
      <Grid container item xs={12} sm={9} md={10} lg={9} xl={9} justifyContent="center" style={{ marginTop: 30 }}>
        <Grid item xs={11} sm={10} md={6} lg={6} xl={6} ref={gettingStartedScrollToRef}>
          <AboutSummary
            id="privatePageAboutSummarySection"
            title={translate(LabelKeys.PrivatePageInfoTitleText)}
            bodyText={translate(LabelKeys.PrivatePageInfoBodyText)}
          />
        </Grid>
        <Grid container justifyContent="center" item xs={12} sm={12} md={12} lg={12} xl={12} style={{ marginTop: 20 }}>
          <Grid id="productCategoryContainer" container item xs={11} sm={12} md={12} lg={12} xl={12} justifyContent="center" spacing={3}>
            {reducerState.product.productCategories?.map((x) => (
              <Grid id={`category${x.id}`} key={x.id} item xs={12} sm={6} md={6} lg={6} xl={6} justifyContent="center">
                <ProductCategoryItem
                  id={`category${x.id}`}
                  data={x}
                  isMobile={isMobile || isSm || isMd}
                  onProductCategoryClicked={(stateNames, value, fieldName) => {
                    onProductCategoryClicked(value);
                  }}
                  sellingPoints={
                    x.category == ProductCategory.GiftCardVH
                      ? ["Mottagare ser summa", "Val från hela sortimentet", "Disponerar gåvan fritt"]
                      : ["Mottagare ser inte summa", "Val ur utvalt sortiment", "Diskret och snyggt"]
                  }
                />
              </Grid>
            ))}
          </Grid>
          <Grid container item xs={12} sm={12} md={12} lg={12} xl={12} justifyContent="center" style={{ paddingTop: 80 }}>
            <Grid container item xs={11} sm={12} md={12} lg={12} xl={12}>
              <HowItWorksHomePage />
            </Grid>
            <Grid
              item
              xs={10}
              sm={6}
              md={6}
              lg={6}
              xl={6}
              style={{ display: "flex" }}
              justifyContent={isMobile ? "center" : "flex-end"}
              sx={{ paddingRight: { sm: 1, md: 1, lg: 1, xl: 1 } }}
            >
              <Button
                id="gettingStartedBtn2"
                style={{
                  height: 60,
                  width: isMobile ? "100%" : "auto",
                  cursor: "pointer",
                  display: "flex",
                  marginTop: 20,
                  borderRadius: 0,
                  backgroundColor: "#000",
                  minWidth: 120,
                }}
                onClick={() => {
                  ResetProductValues();
                  ResetAllOrderValues();
                  navigate(`/${GetCurrentLanguageAsText()}/${translate(LabelKeys.OrderUrl)}`);
                }}
              >
                <Typography
                  variant="body1"
                  style={{
                    margin: "auto",
                    textTransform: "none",
                    color: "#fff",
                  }}
                >
                  {translate(LabelKeys.GettingStarted)}
                </Typography>
              </Button>
            </Grid>
            <Grid
              item
              xs={10}
              sm={6}
              md={6}
              lg={6}
              xl={6}
              style={{ display: "flex" }}
              justifyContent={isMobile ? "center" : "flex-start"}
              sx={{ paddingLeft: { sm: 1, md: 1, lg: 1, xl: 1 } }}
            >
              <Button
                id="faqBtn"
                style={{
                  height: 60,
                  width: isMobile ? "100%" : "auto",
                  cursor: "pointer",
                  color: "#000",
                  display: "flex",
                  marginTop: 20,
                  borderRadius: 0,
                  backgroundColor: "#e3e1de",
                  minWidth: 120,
                  border: "1px solid #000",
                }}
                onClick={() =>
                  Cookies.get(CookieConstants.GBVReseller)?.length > 0 && systemPart == SystemPart.Company
                    ? navigate(`/${GetCurrentLanguageAsText()}/${translate(LabelKeys.FAQUrl)}`)
                    : window.open("https://support.giftsbyvinga.com/hc/sv", "_blank")
                }
              >
                <Typography style={{ margin: "auto", textTransform: "none" }}>{translate(LabelKeys.FrequentlyAskedQuestions)}</Typography>
              </Button>
            </Grid>
          </Grid>
          <Grid container item xs={12} sm={12} md={12} lg={12} xl={12} justifyContent="center" style={{ paddingTop: 80 }}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Typography id="giftcardSelectionTitle" variant="h1">
                {translate(LabelKeys.GiftCardSelectionHomePageTitle)}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ marginTop: 30 }}>
              <Typography id="giftcardSelectionDescription" variant="body1" style={{ maxWidth: isMobile ? "90%" : 540, margin: "auto" }}>
                {translate(LabelKeys.GiftCardSelectionHomePageDescription)}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            id="giftcardsContainer"
            container
            item
            xs={11}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            style={{
              paddingTop: 50,
              display: "flex",
              flexWrap: isMobile ? "nowrap" : "inherit",
              overflowX: isMobile ? "scroll" : "inherit",
            }}
            className={classes.giftcardContainer}
            justifyContent={isSm || isMd ? "start " : "space-between"}
            spacing={1}
          >
            {reducerState.product.products
              ?.filter(
                (x) =>
                  x.category == ProductCategory.GiftCardGMG ||
                  x.category == ProductCategory.GiftCardVH ||
                  x.category == ProductCategory.DigitalProduct
              )
              ?.map((x) => {
                return (
                  <div
                    id={x.name}
                    key={x?.id}
                    style={{ minWidth: 225 }}
                    className={x?.id === selectedProduct?.id ? classes.selectedCard : classes.notSelectedCard}
                    onClick={() => {
                      setSelectedProduct(x);
                    }}
                  >
                    {x?.isMostPopular ||
                      (x?.name.includes("Regal") && (
                        <div
                          style={{
                            position: "absolute",
                            zIndex: 2,
                            marginTop: -15,
                            marginLeft: 49,
                          }}
                        >
                          <Typography
                            variant="body1"
                            style={{
                              height: 27,
                              width: 127,
                              backgroundColor: "#2eac77",
                              color: "#fff",
                            }}
                          >
                            {translate(LabelKeys.PopularChoice)}
                          </Typography>
                        </div>
                      ))}
                    <Typography
                      variant="h5"
                      style={{
                        paddingTop: 20,
                        color: x?.id === selectedProduct?.id ? "#fff" : "#000",
                      }}
                    >
                      {x?.name}
                    </Typography>
                    <Typography
                      variant="body2"
                      style={{
                        paddingTop: 20,
                        fontWeight: "bold",
                        color: x?.id === selectedProduct?.id ? "#fff" : "#000",
                      }}
                    >{`${
                      systemPart == SystemPart.Company || isSystemAdmin || isResellerAdmin
                        ? x?.priceExcludingVat + ` ${Currency[x?.currency].toLowerCase()}/st`
                        : x?.priceIncludingVat + ` ${Currency[x?.currency].toLowerCase()}/st`
                    }`}</Typography>
                    <Typography variant="body2" style={{ color: "grey" }}>
                      {systemPart == SystemPart.Company || isSystemAdmin || isResellerAdmin
                        ? translate(LabelKeys.ExcludingVat).toLowerCase()
                        : translate(LabelKeys.IncludingVat).toLowerCase()}
                    </Typography>
                  </div>
                );
              })}
          </Grid>
          {selectedProduct?.id !== undefined && (
            <Grid container item xs={11} sm={12} md={12} lg={12} xl={12} justifyContent="center" style={{ paddingTop: 20 }} spacing={5}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Typography variant="h5" style={{ paddingTop: 20 }}>
                  {translate(LabelKeys.ExampleAssortment)} {selectedProduct?.name}
                </Typography>
              </Grid>
              <ExampleAssortment id="exampleAssortment" productName={selectedProduct?.name} />
            </Grid>
          )}
          <Grid container item xs={12} sm={12} md={12} lg={12} xl={12} justifyContent="center" style={{ paddingTop: 40 }}>
            <Grid
              item
              xs={11}
              sm={6}
              md={6}
              lg={6}
              xl={6}
              style={{ display: "flex" }}
              justifyContent={isMobile ? "center" : "flex-end"}
              sx={{ paddingRight: { sm: 1, md: 1, lg: 1, xl: 1 } }}
            >
              <Button
                id="seeEntireCollection"
                style={{
                  height: 60,
                  width: isMobile ? "100%" : "auto",
                  cursor: "pointer",
                  color: "#000",
                  display: "flex",
                  marginTop: 20,
                  borderRadius: 0,
                  backgroundColor: "#e3e1de",
                  minWidth: 120,
                  border: "1px solid #000",
                }}
              >
                <Typography
                  variant="body1"
                  style={{
                    margin: "auto",
                    textTransform: "none",
                  }}
                >
                  <a
                    style={{ textDecoration: "none", color: "#000" }}
                    href={"https://getmygift.se/se/" + selectedProduct?.name?.toLowerCase()}
                    rel="noreferrer"
                    target={"_blank"}
                  >
                    {translate(LabelKeys.SeeWholeAssortment) + " " + selectedProduct?.name}
                  </a>
                </Typography>
              </Button>
            </Grid>
            <Grid
              item
              xs={11}
              sm={6}
              md={6}
              lg={6}
              xl={6}
              style={{ display: "flex" }}
              sx={{ paddingRight: { sm: 1, md: 1, lg: 1, xl: 1 } }}
              justifyContent={isMobile ? "center" : "flex-start"}
            >
              <Button
                id="giveAwayGiftCardBtn"
                style={{
                  height: 60,
                  width: isMobile ? "100%" : "auto",
                  cursor: "pointer",
                  color: "#fff",
                  display: "flex",
                  marginTop: 20,
                  borderRadius: 0,
                  backgroundColor: "#000",
                  minWidth: 120,
                }}
                onClick={() => {
                  ResetAllOrderValues();
                  setOrderState(selectedProduct, selectedProductCategory);
                  navigate(`/${GetCurrentLanguageAsText()}/${translate(LabelKeys.OrderUrl)}`);
                }}
              >
                <Typography
                  variant="body1"
                  style={{
                    margin: "auto",
                    textTransform: "none",
                    color: "#fff",
                  }}
                >
                  {`${translate(LabelKeys.GiveAwayGiftcard)}${selectedProduct?.name}`}
                </Typography>
              </Button>
            </Grid>
          </Grid>
          <Grid
            container
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            justifyContent="center"
            sx={{
              paddingTop: {
                xs: "50px",
                sm: "50px",
                md: "50px",
                lg: "100px",
                xl: "100px",
              },
            }}
          >
            <ImageTextContentBlock
              id="leftSideRightSideContentBlockHomePage1"
              leftSideContent={GetImageSection(ImageKeys.HopePageDesktop1, 480, undefined, 1000)}
              rightSideContent={GetTextSection(LabelKeys.HomePageContentTitle1, LabelKeys.HomePageContentBody1)}
            />
          </Grid>
          <Grid
            container
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            justifyContent="center"
            sx={{
              paddingTop: {
                xs: "50px",
                sm: "50px",
                md: "50px",
                lg: "100px",
                xl: "100px",
              },
            }}
          >
            <TextImageContentBlock
              id="leftSideRightSideContentBlockHomePage1"
              rightSideContent={GetImageSection(ImageKeys.HopePageDesktop2, 480, undefined, 1000)}
              leftSideContent={GetTextSection(LabelKeys.HomePageContentTitle2, LabelKeys.HomePageContentBody2)}
              swapOrder={isMobile || isSm || isMd}
            />
          </Grid>
          <Grid
            container
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            justifyContent="center"
            sx={{
              paddingTop: {
                xs: "50px",
                sm: "50px",
                md: "50px",
                lg: "100px",
                xl: "100px",
              },
            }}
          >
            <ImageTextContentBlock
              id="leftSideRightSideContentBlockHomePage1"
              leftSideContent={GetImageSection(ImageKeys.HopePageDesktop3, 480, undefined, 1000)}
              rightSideContent={GetTextSection(LabelKeys.HomePageContentTitle3, LabelKeys.HomePageContentBody3)}
            />
          </Grid>

          <Grid
            container
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            justifyContent="center"
            sx={{
              paddingTop: {
                xs: "50px",
                sm: "50px",
                md: "50px",
                lg: "100px",
                xl: "100px",
              },
            }}
          >
            <TextImageContentBlock
              id="leftSideRightSideContentBlockHomePage1"
              rightSideContent={GetImageSection(ImageKeys.HopePageDesktop4, 480, undefined, 1000)}
              leftSideContent={GetTextSection(LabelKeys.HomePageContentTitle4, LabelKeys.HomePageContentBody4)}
              swapOrder={isMobile || isSm || isMd}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default Home;
