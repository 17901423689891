import { IGetCompanyDetailsRequest, IGetCompanyDetailsResponse } from './types';
import { axiosInstance, GetBaseURL } from './../../Utils/network/axios/index';
import { all, call, put, SagaReturnType, takeLatest } from 'redux-saga/effects';
import { COMPANY_API } from '../../Utils/network/axios/urls';
import { CompanyActions } from './actions';
import { CompanyActionTypes } from './actionTypes';

export default function* CompanyWatcher() {
  yield all([
    takeLatest(
      CompanyActionTypes.GET_COMPANY_DETAILS_REQUEST,
      GetCompanyDetailsWorker
    ),
  ]);
}

//WORKERS
//Private
function* GetCompanyDetailsWorker({
  request,
}: ReturnType<typeof CompanyActions.GetCompanyDetailsRequest>) {
  try {
    const response: GetCompanyDetailsServiceResponse = yield call(
      getCompanyDetails,
      request
    );
    if (response) {
      yield put(
        CompanyActions.GetCompanyDetailsSuccess(
          response as IGetCompanyDetailsResponse
        )
      );
    }
  } catch (error) {
    yield put(CompanyActions.GetCompanyDetailsError());
  }
}

// API:S
export const getCompanyDetails: GetCompanyDetailsService = async (
  request: IGetCompanyDetailsRequest
) => {
  axiosInstance.defaults.baseURL = GetBaseURL() + COMPANY_API;
  return await axiosInstance
    .get(`details`, {
      params: { organisationNumber: request.organisationNumber },
    })
    .then((resp) => resp.data);
};

// SERVICES
export interface GetCompanyDetailsService {
  (request: IGetCompanyDetailsRequest): Promise<IGetCompanyDetailsResponse>;
}

// RESPONSE
export type GetCompanyDetailsServiceResponse = SagaReturnType<
  typeof getCompanyDetails
>;
