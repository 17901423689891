import { axiosInstance, GetBaseURL } from "./../../Utils/network/axios/index";
import { all, call, put, SagaReturnType, takeLatest } from "redux-saga/effects";
import { ProductActions } from "./actions";
import { PRODUCT_API } from "../../Utils/network/axios/urls";
import {
  ICreateMessageTemplateRequest,
  IDeleteMessageTemplateRequest,
  IGetMessageTemplatesRequest,
  IGetMessageTemplatesResponse,
  IGetLitiumImageBySystemIdRequest,
  IGetLitiumImageBySystemIdResponse,
  IGetLitiumImagesByFolderIdRequest,
  IGetLitiumImagesByFolderIdResponse,
  IGetPreviewPDFRequest,
  IGetProductByIdRequest,
  IGetProductByIdResponse,
  IGetProductCategoriesResponse,
  IGetProductsRequest,
  IGetProductsResponse,
  IGetVoucherRequest,
  IGetVoucherResponse,
  IProduct,
  IUpdateMessageTemplateRequest,
} from "./types";
import { ProductActionTypes } from "./actionTypes";
import SnackbarActions from "../../components/Snackbar/actions";
import { AxiosRequestConfig } from "axios";

export default function* ProductWatcher() {
  yield all([
    takeLatest(ProductActionTypes.GET_PRODUCTS_REQUEST, GetProductsWorker),
    takeLatest(ProductActionTypes.GET_PRODUCT_BY_ID_REQUEST, GetProductByIdWorker),
    takeLatest(ProductActionTypes.GET_PREVIEW_PDF_REQUEST, GetPreviewPDFWorker),
    takeLatest(ProductActionTypes.CREATE_MESSAGE_TEMPLATES_REQUEST, CreateMessageTemplatesWorker),
    takeLatest(ProductActionTypes.GET_MESSAGE_TEMPLATES_REQUEST, GetMessageTemplatesWorker),
    takeLatest(ProductActionTypes.UPDATE_MESSAGE_TEMPLATES_REQUEST, UpdateMessageTemplatesWorker),
    takeLatest(ProductActionTypes.DELETE_MESSAGE_TEMPLATES_REQUEST, DeleteMessageTemplatesWorker),
    takeLatest(ProductActionTypes.GET_VOUCHER_REQUEST, GetVoucherWorker),
    takeLatest(ProductActionTypes.GET_PRODUCT_CATEGORIES_REQUEST, GetProductCategoriesWorker),
    takeLatest(ProductActionTypes.GET_PHYSICAL_CARD_COVER_IMAGES_REQUEST, GetPhysicalCardCoverImagesWorker),
    takeLatest(ProductActionTypes.GET_PHYSICAL_CARD_BACK_IMAGES_REQUEST, GetPhysicalCardBackImagesWorker),
    takeLatest(ProductActionTypes.GET_PHYSICAL_CARD_MIDDLE_PAGE_IMAGES_REQUEST, GetPhysicalCardMiddlePageImagesWorker),
    takeLatest(ProductActionTypes.GET_PHYSICAL_CARD_WEBSITE_COVER_IMAGES_REQUEST, GetPhysicalCardWebsiteCoverImagesWorker),
    takeLatest(ProductActionTypes.GET_IMAGE_SELECTOR_IMAGES_REQUEST, GetImageSelectorImagesWorker),
    takeLatest(ProductActionTypes.DOWNLOAD_DOCUMENTATION_REQUEST, DownloadDocumentationWorker),
  ]);
}

//WORKERS
function* GetProductByIdWorker({ request }: ReturnType<typeof ProductActions.GetProductByIdRequest>) {
  try {
    const response: GetProductByIdServiceResponse = yield call(getProductById, request);

    if (response) {
      yield put(ProductActions.GetProductByIdSuccess(response));
    } else {
      yield put(SnackbarActions.ShowError("Could not load selected product. Please, refresh page and try again."));
    }
  } catch (error) {
    yield put(SnackbarActions.ShowError("Kunde inte ladda vald produkt. Ladda om sidan och försök igen."));
    yield put(ProductActions.GetProductByIdError());
  }
}

function* GetProductsWorker({ request }: ReturnType<typeof ProductActions.GetProductsRequest>) {
  try {
    const response: GetProductsServiceResponse = yield call(getProducts, request);

    if (response.products?.length > 0) {
      yield put(
        ProductActions.GetProductsSuccess({
          products: response.products as IProduct[],
        })
      );
    } else {
      yield put(SnackbarActions.ShowError("Kunde inte ladda produkter. Ladda om sidan och försök igen."));
    }
  } catch (error) {
    yield put(SnackbarActions.ShowError("Kunde inte ladda produkter. Ladda om sidan och försök igen."));
    yield put(ProductActions.GetProductsError());
  }
}

function* GetPhysicalCardCoverImagesWorker({ request }: ReturnType<typeof ProductActions.GetPhysicalCardCoverImagesRequest>) {
  try {
    const response: GetLitiumImagesByFolderIdServiceResponse = yield call(getLitiumImagesByFolderId, request);

    if (response) {
      yield put(ProductActions.GetPhysicalCardCoverImagesSuccess(response));
    }
  } catch (error) {
    yield put(SnackbarActions.ShowError("Kunde inte ladda valbara bilder. Vänligen försök igen."));
    yield put(ProductActions.GetPhysicalCardCoverImagesError());
  }
}

function* GetPhysicalCardBackImagesWorker({ request }: ReturnType<typeof ProductActions.GetPhysicalCardBackImageRequest>) {
  try {
    const response: GetLitiumImageBySystemIdServiceResponse = yield call(getLitiumImageBySystemId, request);

    if (response) {
      yield put(ProductActions.GetPhysicalCardBackImageSuccess(response));
    }
  } catch (error) {
    yield put(SnackbarActions.ShowError("Kunde inte ladda bild för baksidan. Vänligen försök igen."));
    yield put(ProductActions.GetPhysicalCardBackImageError());
  }
}

function* GetPhysicalCardMiddlePageImagesWorker({ request }: ReturnType<typeof ProductActions.GetPhysicalCardMiddlePageImageRequest>) {
  try {
    const response: GetLitiumImageBySystemIdServiceResponse = yield call(getLitiumImageBySystemId, request);

    if (response) {
      yield put(ProductActions.GetPhysicalCardMiddlePageImageSuccess(response));
    }
  } catch (error) {
    yield put(SnackbarActions.ShowError("Kunde inte ladda bild för mittuppslag. Vänligen försök igen."));
    yield put(ProductActions.GetPhysicalCardMiddlePageImageError());
  }
}

function* GetPhysicalCardWebsiteCoverImagesWorker({ request }: ReturnType<typeof ProductActions.GetPhysicalCardWebsiteCoverImagesRequest>) {
  try {
    const response: GetLitiumImagesByFolderIdServiceResponse = yield call(getLitiumImagesByFolderId, request);

    if (response) {
      yield put(ProductActions.GetPhysicalCardWebsiteCoverImagesSuccess(response));
    }
  } catch (error) {
    yield put(SnackbarActions.ShowError("Kunde inte ladda valbara bilder. Vänligen försök igen."));
    yield put(ProductActions.GetPhysicalCardWebsiteCoverImagesError());
  }
}

function* GetPreviewPDFWorker({ request }: ReturnType<typeof ProductActions.GetPreviewPDFRequest>) {
  try {
    const response: GetPreviewPDFServiceResponse = yield call(getPreviewPDF, request);

    yield put(ProductActions.GetPreviewPDFSuccess(response));
  } catch (error) {
    yield put(SnackbarActions.ShowError("Kunde inte läsa in PDF. Vänligen försök igen!"));
    yield put(ProductActions.GetPreviewPDFError());
  }
}

function* CreateMessageTemplatesWorker({ request }: ReturnType<typeof ProductActions.CreateMessageTemplateRequest>) {
  try {
    yield call(createMessageTemplates, request);
    yield put(ProductActions.CreateMessageTemplateSuccess());
    yield put(ProductActions.GetMessageTemplatesRequest({ templateTypes: undefined } as IGetMessageTemplatesRequest));
    yield put(SnackbarActions.ShowSuccess("Mallen har skapats!"));
  } catch (error) {
    yield put(SnackbarActions.ShowError("Kunde inte hämta mallar. Vänligen försök igen!"));
    yield put(ProductActions.CreateMessageTemplateError());
  }
}

function* GetMessageTemplatesWorker({ request }: ReturnType<typeof ProductActions.GetMessageTemplatesRequest>) {
  try {
    const response: GetMessageTemplatesServiceResponse = yield call(getMessageTemplates, request);

    yield put(
      ProductActions.GetMessageTemplateSuccess({
        messageTemplates: response.messageTemplates,
      })
    );
  } catch (error) {
    yield put(SnackbarActions.ShowError("Kunde inte hämta mallar. Vänligen försök igen!"));
    yield put(ProductActions.GetMessageTemplatesError());
  }
}

function* UpdateMessageTemplatesWorker({ request }: ReturnType<typeof ProductActions.UpdateMessageTemplatesRequest>) {
  try {
    yield call(updateMessageTemplates, request);

    yield put(ProductActions.UpdateMessageTemplateSuccess());
    yield put(ProductActions.GetMessageTemplatesRequest({ templateTypes: undefined } as IGetMessageTemplatesRequest));
    yield put(SnackbarActions.ShowSuccess("Mallen har uppdaterats!"));
  } catch (error) {
    yield put(SnackbarActions.ShowError("Kunde inte hämta mallar. Vänligen försök igen!"));
    yield put(ProductActions.UpdateMessageTemplatesError());
  }
}

function* DeleteMessageTemplatesWorker({ request }: ReturnType<typeof ProductActions.DeleteMessageTemplateRequest>) {
  try {
    yield call(deleteMessageTemplates, request);
    yield put(ProductActions.DeleteMessageTemplateSuccess());
    yield put(ProductActions.GetMessageTemplatesRequest({ templateTypes: undefined } as IGetMessageTemplatesRequest));
    yield put(SnackbarActions.ShowSuccess("Mallen har tagits bort!"));
  } catch (error) {
    yield put(SnackbarActions.ShowError("Kunde inte hämta mallar. Vänligen försök igen!"));
    yield put(ProductActions.DeleteMessageTemplateError());
  }
}

function* GetVoucherWorker({ request }: ReturnType<typeof ProductActions.GetVoucherRequest>) {
  try {
    const response: GetVoucherServiceResponse = yield call(getVoucher, request);
    if (response) {
      yield put(ProductActions.GetVoucherSuccess(response));
    } else {
      yield put(SnackbarActions.ShowError("Kunde inte hämta utskicket. Vänligen kontrollera att hela länken används och försök igen."));
    }
  } catch (error) {
    yield put(SnackbarActions.ShowError("Kunde inte hämta kupong. Vänligen försök igen."));
    yield put(ProductActions.GetVoucherError());
  }
}

function* GetProductCategoriesWorker({}: ReturnType<typeof ProductActions.GetProductCategoriesRequest>) {
  try {
    const response: GetProductCategpriesServiceResponse = yield call(getProductCategories);
    yield put(ProductActions.GetProductCategoriesSuccess(response));
  } catch (error) {
    yield put(SnackbarActions.ShowError("Kunde inte hämta produktkategorier. Vänligen försök igen."));
    yield put(ProductActions.GetProductCategoriesError());
  }
}

function* GetImageSelectorImagesWorker({ request }: ReturnType<typeof ProductActions.GetImageSelectorImagesRequest>) {
  try {
    const response: GetLitiumImagesByFolderIdServiceResponse = yield call(getLitiumImagesByFolderId, request);
    yield put(ProductActions.GetImageSelectorImagesSuccess(response));
  } catch (error) {
    yield put(SnackbarActions.ShowError("Kunde inte hämta bilder för bildväljaren. Vänligen försök igen."));
    yield put(ProductActions.GetImageSelectorImagesError());
  }
}

//Download documentation
function* DownloadDocumentationWorker({}: ReturnType<typeof ProductActions.DownloadDocumentationRequest>) {
  try {
    const response: DownloadDocumentationServiceResponse = yield call(getDocumentation);
    if (response) {
      yield put(ProductActions.DownloadDocumentationSuccess(response));
    }
  } catch (error) {
    yield put(ProductActions.DownloadDocumentationError());
    yield put(SnackbarActions.ShowError("Något gick fel när filens skulle laddas ned, vänligen försök igen"));
  }
}

// API:S
export const getProductById: GetProductByIdService = async (request: IGetProductByIdRequest) => {
  axiosInstance.defaults.baseURL = GetBaseURL() + PRODUCT_API;
  return await axiosInstance
    .get(`${request.productId}`, {
      params: {
        SystemPart: request.systemPart,
      },
    })
    .then((resp) => resp.data);
};

export const getProducts: GetProductsService = async (request: IGetProductsRequest) => {
  axiosInstance.defaults.baseURL = GetBaseURL() + PRODUCT_API;
  return await axiosInstance
    .get("", {
      params: {
        language: request.language,
        systemPart: request.systemPart,
        productCategoryId: request.productCategoryId,
      },
    })
    .then((resp) => resp.data);
};

export const getPreviewPDF: GetPreviewPDFService = async (request: IGetPreviewPDFRequest) => {
  axiosInstance.defaults.baseURL = GetBaseURL() + PRODUCT_API;
  const config: AxiosRequestConfig = { responseType: "blob" };
  return await axiosInstance.post(`preview/pdf`, request, config).then((resp) => resp.data);
};

//Message templates
export const createMessageTemplates: CreateMessageTemplatesService = async (request: ICreateMessageTemplateRequest) => {
  axiosInstance.defaults.baseURL = GetBaseURL() + PRODUCT_API;
  return await axiosInstance.post(`messageTemplate`, request);
};
export const getMessageTemplates: GetMessageTemplatesService = async (request: IGetMessageTemplatesRequest) => {
  axiosInstance.defaults.baseURL = GetBaseURL() + PRODUCT_API;
  return await axiosInstance.post(`messageTemplates`, request).then((resp) => resp.data);
};
export const updateMessageTemplates: UpdateMessageTemplatesService = async (request: IUpdateMessageTemplateRequest) => {
  axiosInstance.defaults.baseURL = GetBaseURL() + PRODUCT_API;
  return await axiosInstance.put(`messageTemplate`, request);
};
export const deleteMessageTemplates: DeleteMessageTemplatesService = async (request: IDeleteMessageTemplateRequest) => {
  axiosInstance.defaults.baseURL = GetBaseURL() + PRODUCT_API;
  return await axiosInstance.delete(`messageTemplate/${request.id}`);
};
export const getVoucher: GetVoucherService = async (request: IGetVoucherRequest) => {
  axiosInstance.defaults.baseURL = GetBaseURL() + PRODUCT_API;
  return await axiosInstance.get(`voucherInformation/${request.claimCode}`).then((resp) => resp.data);
};
export const getProductCategories: GetProductCategoriesService = async () => {
  axiosInstance.defaults.baseURL = GetBaseURL() + PRODUCT_API;
  return await axiosInstance.get(`category`).then((resp) => resp.data);
};
export const getLitiumImagesByFolderId: GetLitiumImagesByFolderIdService = async (request: IGetLitiumImagesByFolderIdRequest) => {
  axiosInstance.defaults.baseURL = GetBaseURL() + PRODUCT_API;
  return await axiosInstance.get(`litium/folderimages/${request.folderId}`).then((resp) => resp.data);
};
export const getLitiumImageBySystemId: GetLitiumImageBySystemIdService = async (request: IGetLitiumImageBySystemIdRequest) => {
  axiosInstance.defaults.baseURL = GetBaseURL() + PRODUCT_API;
  return await axiosInstance
    .get(`imagedetails/${request.productId}`, {
      params: {
        imageKey: request.pysicalCardImage,
      },
    })
    .then((resp) => resp.data);
};

export const getDocumentation: GetDocumentationService = async () => {
  axiosInstance.defaults.baseURL = GetBaseURL() + PRODUCT_API;
  const config: AxiosRequestConfig = { responseType: "blob" };
  return await axiosInstance.get(`documentation`, config).then((resp) => resp.data);
};

// SERVICES
export interface GetProductByIdService {
  (request: IGetProductByIdRequest): Promise<IGetProductByIdResponse>;
}
export interface GetProductsService {
  (request: IGetProductsRequest): Promise<IGetProductsResponse>;
}
export interface DeleteImageUrlService {
  (request: number): Promise<void>;
}
export interface GetPreviewPDFService {
  (request: IGetPreviewPDFRequest): Promise<Blob>;
}
export interface CreateMessageTemplatesService {
  (request: ICreateMessageTemplateRequest): Promise<void>;
}
export interface GetMessageTemplatesService {
  (request: IGetMessageTemplatesRequest): Promise<IGetMessageTemplatesResponse>;
}
export interface UpdateMessageTemplatesService {
  (request: IUpdateMessageTemplateRequest): Promise<void>;
}
export interface DeleteMessageTemplatesService {
  (request: IDeleteMessageTemplateRequest): Promise<void>;
}
export interface GetVoucherService {
  (request: IGetVoucherRequest): Promise<IGetVoucherResponse>;
}
export interface GetProductCategoriesService {
  (): Promise<IGetProductCategoriesResponse>;
}
export interface GetLitiumImagesByFolderIdService {
  (request: IGetLitiumImagesByFolderIdRequest): Promise<IGetLitiumImagesByFolderIdResponse>;
}
export interface GetLitiumImageBySystemIdService {
  (request: IGetLitiumImageBySystemIdRequest): Promise<IGetLitiumImageBySystemIdResponse>;
}
export interface GetDocumentationService {
  (): Promise<Blob>;
}

// RESPONSE
export type GetProductByIdServiceResponse = SagaReturnType<typeof getProductById>;
export type GetProductsServiceResponse = SagaReturnType<typeof getProducts>;
export type GetPreviewPDFServiceResponse = SagaReturnType<typeof getPreviewPDF>;
export type GetMessageTemplatesServiceResponse = SagaReturnType<typeof getMessageTemplates>;
export type GetVoucherServiceResponse = SagaReturnType<typeof getVoucher>;
export type GetProductCategpriesServiceResponse = SagaReturnType<typeof getProductCategories>;
export type GetLitiumImagesByFolderIdServiceResponse = SagaReturnType<typeof getLitiumImagesByFolderId>;
export type GetLitiumImageBySystemIdServiceResponse = SagaReturnType<typeof getLitiumImageBySystemId>;
export type DownloadDocumentationServiceResponse = SagaReturnType<typeof getDocumentation>;
