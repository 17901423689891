import { TableRow, TableCell, Paper, TableContainer, Table, TableHead, Checkbox, TableBody, TablePagination } from "@mui/material";
import clsx from "clsx";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { shallowEqual } from "react-redux";
import { GetDateWithTime, WithoutTime } from "../../Utils/extensions";
import { TableStyles } from "./styles";
import { HeadCell } from "./types";

interface ICSReadOnlyTableProps<T> {
  onRowClick?(e: React.MouseEvent<HTMLTableRowElement, MouseEvent>, selectedRow: T, selectedRows?: T[]): void;
  columns: HeadCell[];
  rows: T[];
  maxHeight?: number;
  maxWidth?: number | string;
  enablePagination?: boolean;
  totalItems?: number;
  currentPage?: number;
  rowsPerPage?: number;
  rowsPerPageOptions?: number[];
  rowsPerPageLabel?: string;
  useCheckBox?: boolean;
  enableRowClick: boolean;
  showTimeWithDate?: boolean;
  onCurrentPageChanged?(e: React.MouseEvent<HTMLButtonElement> | null, newPage: number): void;
  onRowsPerPageChanged?(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void;
}

interface ICSReadOnlyTableCell {
  key: string;
  name: string;
  value: string | number | Date | boolean;
  hide?: boolean;
}

function CSReadOnlyTable<T>(props: ICSReadOnlyTableProps<T>) {
  const classes = TableStyles();
  const { t: translate } = useTranslation();
  const [selectedRows, setSelectedRows] = useState<typeof props.rows>([]);

  useEffect(() => {}, [props.currentPage]);
  useEffect(() => {}, [props]);

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      setSelectedRows(props.rows);
      return;
    }
    setSelectedRows([]);
  };

  const isSelected = (row: T) => {
    return selectedRows.some((item) => shallowEqual(item, row));
  };

  const handleClick = (row: T) => {
    const rowToSelect = props.rows.find((rowInList) => rowInList === row);
    let newSelection: typeof props.rows = [];
    if (isSelected(row)) {
      const newValue = selectedRows.filter((item) => item !== row);
      setSelectedRows(newValue);
      return;
    } else {
      setSelectedRows(newSelection.concat(selectedRows, rowToSelect));
      return;
    }
  };

  function ConvertBoolToString(value: string | number | boolean | Date): string {
    if (value === true) {
      return "True";
    } else {
      return "False";
    }
  }

  function handleTrue(): boolean {
    return props.currentPage == 0;
  }

  return (
    <Paper style={{ width: "100%" }}>
      <TableContainer
        style={{
          width: "100%",
          maxHeight: props.maxHeight ?? 400,
          display: "block",
        }}
      >
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              {props.useCheckBox && (
                <TableCell key="GroupTableCheckBox" width={30} align="left" style={{ overflowX: "hidden" }}>
                  <Checkbox
                    disableRipple
                    indeterminate={selectedRows?.length > 0 && selectedRows?.length < props.rows?.length}
                    checked={props.rows?.length > 0 && selectedRows?.length === props.rows?.length}
                    onChange={handleSelectAllClick}
                  />
                </TableCell>
              )}
              {props.columns?.map((column) => (
                <TableCell key={column.key} align="left">
                  {translate(column.labelKey)}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {props.rows?.length > 0 &&
              (props.rows as []) &&
              props.rows?.map((row, index) => {
                let convertedCells: ICSReadOnlyTableCell[] = [];
                const keyValues = Object.entries(row);
                let key: string = "";
                convertedCells = keyValues?.map((keyValue) => {
                  key = keyValue[0] + index;
                  return {
                    key: keyValue[0],
                    name: keyValue[0],
                    value: keyValue[1],
                    hide: false,
                  } as ICSReadOnlyTableCell;
                });

                const isItemSelected = isSelected(row);

                return (
                  <TableRow
                    key={key}
                    style={{ width: "100%" }}
                    hover
                    className={clsx(classes.tableRow, index % 2 ? classes.evenClassName : classes.oddClassName)}
                    onClick={(e) => {
                      if (props.enableRowClick) {
                        handleClick(row);
                        props.onRowClick(e, row, selectedRows);
                      }
                    }}
                  >
                    {props.useCheckBox && (
                      <TableCell key="GroupTableRowCheckBox" style={{ maxWidth: 30 }} suppressContentEditableWarning={true} align="left">
                        <Checkbox checked={isItemSelected} disableRipple />
                      </TableCell>
                    )}
                    {props.columns?.map((column) => {
                      if (column) {
                        const cell = convertedCells.find((x) => x.key == column.key);
                        if (cell) {
                          return (
                            <TableCell
                              key={cell.key}
                              suppressContentEditableWarning={true}
                              align="left"
                              width={column.width}
                              style={{ wordBreak: "break-word" }}
                            >
                              {column.type == "date"
                                ? props.showTimeWithDate
                                  ? GetDateWithTime(cell.value as Date)
                                  : WithoutTime(cell.value as Date)
                                : column.type == "boolean"
                                ? ConvertBoolToString(cell.value)
                                : cell.value}
                            </TableCell>
                          );
                        }
                      }
                    })}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      {props.enablePagination && (
        <React.Fragment>
          <TablePagination
            rowsPerPageOptions={[10, 20, 30]}
            component="div"
            count={props.totalItems}
            rowsPerPage={props.rowsPerPage ? props.rowsPerPage : 10}
            page={props.currentPage}
            onPageChange={props.onCurrentPageChanged}
            onRowsPerPageChange={props.onRowsPerPageChanged}
          />
        </React.Fragment>
      )}
    </Paper>
  );
}

export default CSReadOnlyTable;
