import React, { useEffect } from "react";
import { Grid, Typography } from "@mui/material";
import { MailPreviewStyles } from "./styles";
import clsx from "clsx";
import { QueryParamKeys } from "../../Utils/enums/queryParams";

interface IMailPreviewProps {
  emailMessage: string;
  imageUrl?: string;
  className?: string;
}

export default function MailPreview(props: IMailPreviewProps) {
  const classes = MailPreviewStyles();
  useEffect(() => {}, [props?.emailMessage]);
  const generateHTMLTemplate = () => {
    return {
      __html: props.emailMessage,
    };
  };

  return (
    <Grid
      container
      item
      xs={12}
      sm={12}
      md={12}
      lg={12}
      xl={12}
      id="mailPreview"
      className={clsx(classes.previewContainer, props.className)}
    >
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <img
          className={classes.previewHeaderImg}
          width="100%"
          src={props.imageUrl && props.imageUrl.includes("base64") ? props.imageUrl : `${props.imageUrl + QueryParamKeys.MaxWidth + 900}`}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className={classes.previewMessage} style={{ paddingTop: 30 }}>
        <Typography
          variant="body2"
          style={{
            whiteSpace: "pre-wrap",
            wordBreak: "break-word",
          }}
          dangerouslySetInnerHTML={generateHTMLTemplate()}
        />
      </Grid>
    </Grid>
  );
}
