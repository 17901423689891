export const PAYMENT_API = "api/payment/";
export const PRODUCT_API = "api/product/";
export const ORDER_API = "api/order/";
export const INVOICE_API = "api/invoice/";
export const RESELLER_API = "api/reseller/";
export const TRANSACTION_API = "api/transaction/";
export const MAIL_API = "api/mail/";
export const COMPANY_API = "api/company/";
export const AUTHENTICATION_API = "api/authentication/";
export const DEVELOPMENT_BACKEND_URL = "http://localhost:5000/"; //"TEST SP -> https://localhost:5000/";
export const TEST_BACKEND_URL = "https://api.test.giftsbyvinga.com/";
export const PRODUCTION_BACKEND_URL = "https://api.giftsbyvinga.com/";
export const DEVELOPMENT_FRONTEND_URL = "http://localhost:3000/"; //"TEST SP -> https://localhost:3000/";
export const TEST_FRONTEND_URL = "https://test.giftsbyvinga.com/";
export const PRODUCTION_FRONTEND_URL = "https://giftsbyvinga.com/";
export const LITIUM_IMAGE_API_URL = "https://litium.vinga.com/api/media/image/";
