import React, { useEffect, useState } from "react";
import { Button, Grid, Typography, useMediaQuery } from "@mui/material";
import { useTranslation } from "react-i18next";
import { LabelKeys } from "../../Utils/enums/labelKeys";
import { PhysicalCardStyles } from "./styles";
import { IUploadedFile } from "../FileUploader/types";
import { StateNames, FieldNames } from "../../Utils/types";
import CSTextField from "../Input/Textfield/CSTextField";
import {
  IGetLitiumImageBySystemIdRequest,
  IGetLitiumImagesByFolderIdRequest,
  IImageOption,
  IPhysicalCardImage,
  IProduct,
} from "../../Modules/Products/types";
import CSImageSelectorPhysicalCardEditor from "../ImageSelector/CSImageSelectorPhysicalCardEditor";
import { IFilterOption } from "../DropDown/types";
import CSDropDown from "../DropDown/CSDropDown";
import CSFileUploader from "../FileUploader/CSFileUploader";
import { AspectRatio, MaxFileSize, PhysicalCardImage, Resolution } from "../../Utils/enums/enums";
import { PhysicalCardState, TemplateType } from "./types";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store";
import { ProductActions } from "../../Modules/Products/actions";
import { LitiumFolderKeys } from "../../Utils/enums/imageKeys";
import { theme } from "../../appTheme";

interface IMessagePhysicalCardWrapperProps {
  id: string;
  selectedProduct: IProduct;
  physicalCardSelectedCoverImage: IImageOption;
  physicalCardSelectedUploadedCoverImage: IUploadedFile;
  physicalCardSelectedUploadedLogotype: IUploadedFile;
  physicalCardHeading: string;
  physicalCardCustomMessage: string;
  onStateChanged: (
    stateName: StateNames.stepperThreeState,
    value: string | number | IImageOption | IUploadedFile,
    fieldName: FieldNames
  ) => void;
  onDoneClicked: () => void;
  onExitClicked: () => void;
  physicalCardCoverImageHasError: boolean;
}

function MessagePhysicalCardWrapper(props: IMessagePhysicalCardWrapperProps) {
  const { t: translate } = useTranslation();
  const dispatch = useDispatch();
  const classes = PhysicalCardStyles();
  const [showPage, setShowPage] = useState(1);
  const physicalCardTitleMaxLength: number = 25;
  const physicalCardCustomMessageMaxLength: number = 150;
  const [isHeaderSuggestionVisible, setIsHeaderSuggestionVisible] = useState(false);
  const [isCustomMessageSuggestionVisible, setIsCustomMessageSuggestionVisible] = useState(false);
  const isMobile = useMediaQuery(theme.breakpoints.only("xs"));
  const isSm = useMediaQuery(theme.breakpoints.only("sm"));
  const isMd = useMediaQuery(theme.breakpoints.only("md"));
  const StandardCoverCategoryItem: IFilterOption = { id: 0, name: translate(LabelKeys.All), value: "all" } as IFilterOption;
  const UploadCoverCategoryItem: IFilterOption = {
    id: 10,
    name: translate(LabelKeys.UploadYourOwnImage),
    value: "upload",
  } as IFilterOption;

  const [physicalCardState, setPhysicalCardState] = useState({
    physicalCardSelectedUploadedLogotype: props.physicalCardSelectedUploadedLogotype ?? ({} as IUploadedFile),
    physicalCardSelectedCoverImage: props.physicalCardSelectedCoverImage ?? ({} as IImageOption),
    physicalCardSelectedUploadedCoverImage: props.physicalCardSelectedUploadedCoverImage ?? ({} as IUploadedFile),
    physicalCardSelectedCategory: {} as IFilterOption,
    categoryFilterOptions: [] as IFilterOption[],
    physicalCardHeadingSuggestion: {} as IFilterOption,
    physicalCardCustomMessageSuggestion: {} as IFilterOption,
    physicalCardHeadingTemplates: [] as IFilterOption[],
    physicalCardCustomMessageTemplates: [] as IFilterOption[],
    physicalCardFilteredCoverImages: [] as IImageOption[],
    physicalCardSelectedMiddlePageImage: {} as IPhysicalCardImage,
    physicalCardSelectedBackImage: {} as IPhysicalCardImage,
  } as PhysicalCardState);

  const reducerState = useSelector(
    (s: RootState) => {
      return {
        product: s.product,
        order: s.order,
      };
    },
    (prev, next) =>
      prev.product.isLoading === next.product.isLoading &&
      prev.product.messageTemplates === next.product.messageTemplates &&
      prev.order.stepperThreeState.messageTemplates === next.order.stepperThreeState.messageTemplates &&
      prev.product.physicalCardCoverImages === next.product.physicalCardCoverImages &&
      prev.product.physicalCardMiddlePageImage === next.product.physicalCardMiddlePageImage &&
      prev.product.physicalCardBackImage === next.product.physicalCardBackImage &&
      prev.order.stepperThreeState.physicalCardHeading === next.order.stepperThreeState.physicalCardHeading &&
      prev.order.stepperThreeState.physicalCardCustomMessage === next.order.stepperThreeState.physicalCardCustomMessage
  );

  useEffect(() => {
    if (
      reducerState.product.messageTemplates?.length <= 0 ||
      reducerState.product.messageTemplates?.some(
        (x) => x.type == TemplateType.PhysicalCardHeading || x.type == TemplateType.PhysicalCardCustomMessage
      ) == false
    ) {
      dispatch(
        ProductActions.GetMessageTemplatesRequest({
          templateTypes: [TemplateType.PhysicalCardHeading, TemplateType.PhysicalCardCustomMessage],
        })
      );
    } else {
      SetPhysicalCardTemplates();
    }

    if (reducerState.product.physicalCardCoverImages?.length <= 0) {
      dispatch(
        ProductActions.GetPhysicalCardCoverImagesRequest({
          folderId: LitiumFolderKeys.PhysicalCardCoverImages,
        } as IGetLitiumImagesByFolderIdRequest)
      );
    }

    //Cover image exists
    if (props.physicalCardSelectedCoverImage?.url?.length > 0) {
      handleFieldOnChange(props.physicalCardSelectedCoverImage, FieldNames.physicalCardSelectedCoverImage);
    } else if (props.physicalCardSelectedUploadedCoverImage?.base64?.length > 0) {
      handleFieldOnChange(props.physicalCardSelectedUploadedCoverImage, FieldNames.physicalCardSelectedUploadedCoverImage);
    }

    //Middle page logo exists
    if (props.physicalCardSelectedUploadedLogotype?.base64?.length > 0) {
      handleFieldOnChange(props.physicalCardSelectedUploadedLogotype, FieldNames.physicalCardSelectedUploadedLogotype);
    }

    //Get middle page
    dispatch(
      ProductActions.GetPhysicalCardMiddlePageImageRequest({
        productId: props.selectedProduct.id,
        pysicalCardImage: PhysicalCardImage.InsideImage,
      } as IGetLitiumImageBySystemIdRequest)
    );

    //Get back page
    dispatch(
      ProductActions.GetPhysicalCardBackImageRequest({
        productId: props.selectedProduct.id,
        pysicalCardImage: PhysicalCardImage.BackgroundImage,
      } as IGetLitiumImageBySystemIdRequest)
    );
  }, []);

  //Set cover images
  useEffect(() => {
    if (reducerState.product.physicalCardCoverImages?.length > 0 || physicalCardState.physicalCardFilteredCoverImages?.length <= 0) {
      FilterCoverImageByCategory("");
      //Set all categories
      handleFieldOnChange(GetAllCoverCategories(reducerState.product.physicalCardCoverImages), FieldNames.categoryFilterOptions);
    }
  }, [reducerState.product.physicalCardCoverImages]);

  useEffect(() => {
    if (
      physicalCardState.categoryFilterOptions?.length > 0 &&
      reducerState.order.stepperThreeState.physicalCardSelectedUploadedCoverImage?.base64 != undefined
    ) {
      SetUploadedCategoryAsStandard();
    } else {
      if (physicalCardState.categoryFilterOptions?.length > 0) {
        SetStandardCategory();
      }
    }
  }, [physicalCardState.categoryFilterOptions]);

  function SetStandardCategory() {
    if (physicalCardState.categoryFilterOptions?.length > 0) {
      handleFieldOnChange(
        physicalCardState.categoryFilterOptions?.find((x) => x.value == StandardCoverCategoryItem.value),
        FieldNames.physicalCardSelectedCategory
      );
    }
  }

  function SetUploadedCategoryAsStandard() {
    handleFieldOnChange(
      physicalCardState.categoryFilterOptions?.find((x) => x.value == UploadCoverCategoryItem.value),
      FieldNames.physicalCardSelectedCategory
    );
  }

  useEffect(() => {
    if (
      (physicalCardState.physicalCardSelectedCoverImage?.url?.length <= 0 ||
        physicalCardState.physicalCardSelectedCoverImage?.url == undefined) &&
      (physicalCardState.physicalCardSelectedUploadedCoverImage?.base64?.length <= 0 ||
        physicalCardState.physicalCardSelectedUploadedCoverImage?.base64 == undefined)
    ) {
      SetStandardCoverImage();
    }
  }, [physicalCardState.physicalCardFilteredCoverImages]);

  function SetStandardCoverImage() {
    if (physicalCardState.physicalCardFilteredCoverImages?.length > 0) {
      handleFieldOnChange(physicalCardState.physicalCardFilteredCoverImages[0], FieldNames.physicalCardSelectedCoverImage);
      props.onStateChanged(
        StateNames.stepperThreeState,
        physicalCardState.physicalCardFilteredCoverImages[0],
        FieldNames.physicalCardSelectedCoverImage
      );
    }
  }

  useEffect(() => {
    if (
      reducerState.product.messageTemplates?.length > 0 &&
      (physicalCardState.physicalCardHeadingTemplates?.length <= 0 || physicalCardState.physicalCardCustomMessageTemplates?.length <= 0)
    ) {
      SetPhysicalCardTemplates();
    }
  }, [reducerState.product.messageTemplates]);

  useEffect(() => {
    if (reducerState.product.physicalCardMiddlePageImage?.url != physicalCardState.physicalCardSelectedMiddlePageImage?.url) {
      handleFieldOnChange(reducerState.product.physicalCardMiddlePageImage, FieldNames.physicalCardSelectedMiddlePageImage);
    }
    if (reducerState.product.physicalCardBackImage?.url != physicalCardState.physicalCardSelectedBackImage?.url) {
      handleFieldOnChange(reducerState.product.physicalCardBackImage, FieldNames.physicalCardSelectedBackImage);
    }
  }, [reducerState.product.physicalCardMiddlePageImage, reducerState.product.physicalCardBackImage]);

  function GetAllCoverCategories(physicalCardCoverImages: IPhysicalCardImage[]) {
    let categories: IFilterOption[] = [];
    categories.push(StandardCoverCategoryItem);
    physicalCardCoverImages?.forEach((x) => {
      x.giftCardCoverCategories?.forEach((z, index) => {
        if (categories?.some((g) => g.value == z)) {
          return;
        } else {
          categories?.push({ id: index + 1, name: translate("physicalCard" + z), value: z } as IFilterOption);
        }
      });
    });
    //Upload cover page
    categories?.push(UploadCoverCategoryItem);
    return categories;
  }

  function handleFieldOnChange(
    value: IImageOption | IImageOption[] | string | boolean | number | IUploadedFile | IFilterOption | IFilterOption[] | IPhysicalCardImage,
    fieldName: FieldNames
  ) {
    setPhysicalCardState((state) => ({
      ...state,
      [fieldName]: value,
    }));
  }

  function SetPhysicalCardTemplates() {
    let templates = reducerState.product.messageTemplates.filter(
      (x) => x.type == TemplateType.PhysicalCardHeading || x.type == TemplateType.PhysicalCardCustomMessage
    );

    let listHeadings: IFilterOption[] = [];
    templates
      .filter((x) => x.type == TemplateType.PhysicalCardHeading)
      .forEach((element) => {
        listHeadings.push({
          id: element?.id,
          name: element?.name,
          value: element?.message,
          checked: false,
          type: element.type,
        } as IFilterOption);
      });
    if (listHeadings?.length > 0) handleFieldOnChange(listHeadings, FieldNames.physicalCardHeadingTemplates);

    let listCustomMessages: IFilterOption[] = [];
    templates
      .filter((x) => x.type == TemplateType.PhysicalCardCustomMessage)
      .forEach((element) => {
        listCustomMessages.push({
          id: element?.id,
          name: element?.name,
          value: element?.message,
          checked: false,
          type: element.type,
        } as IFilterOption);
      });
    if (listCustomMessages?.length > 0) handleFieldOnChange(listCustomMessages, FieldNames.physicalCardCustomMessageTemplates);
  }

  function handleOnLogotypeUpload(e: IUploadedFile | React.ChangeEvent<HTMLInputElement>) {
    const file = e as IUploadedFile;
    if (file != undefined) {
      handleFieldOnChange(file, FieldNames.physicalCardSelectedUploadedLogotype);
      props.onStateChanged(StateNames.stepperThreeState, file, FieldNames.physicalCardSelectedUploadedLogotype);
    }
  }

  function handleOnCoverPageUpload(e: IUploadedFile | React.ChangeEvent<HTMLInputElement>) {
    const file = e as IUploadedFile;
    if (file != undefined) {
      handleFieldOnChange({} as IImageOption, FieldNames.physicalCardSelectedCoverImage);
      handleFieldOnChange(file, FieldNames.physicalCardSelectedUploadedCoverImage);
      props.onStateChanged(StateNames.stepperThreeState, file, FieldNames.physicalCardSelectedUploadedCoverImage);
      props.onStateChanged(StateNames.stepperThreeState, {} as IUploadedFile, FieldNames.physicalCardSelectedCoverImage);
    }
  }

  function handleDeselectCoverPageFile() {
    handleFieldOnChange({} as IUploadedFile, FieldNames.physicalCardSelectedUploadedCoverImage);
    props.onStateChanged(StateNames.stepperThreeState, {} as IUploadedFile, FieldNames.physicalCardSelectedUploadedCoverImage);
    SetStandardCoverImage();
    SetStandardCategory();
  }

  function handleDeselectLogotypeFile() {
    handleFieldOnChange({} as IUploadedFile, FieldNames.physicalCardSelectedUploadedLogotype);
    props.onStateChanged(StateNames.stepperThreeState, {} as IUploadedFile, FieldNames.physicalCardSelectedUploadedLogotype);
  }

  function FilterCoverImageByCategory(category: string) {
    let filteredCategory = physicalCardState.categoryFilterOptions.filter(
      (x) => (x?.value as string)?.toLowerCase() == category?.toLowerCase()
    )[0];
    handleFieldOnChange(filteredCategory, FieldNames.physicalCardSelectedCategory);
    if (
      filteredCategory == undefined ||
      filteredCategory?.name?.length <= 0 ||
      category == StandardCoverCategoryItem.value ||
      category == ""
    ) {
      let imageListWithNoCategorySelected: IImageOption[] = [];
      reducerState.product.physicalCardCoverImages?.forEach((element) => {
        imageListWithNoCategorySelected.push({
          imageId: element.imageId,
          url: element.url,
          isStandard: element.isStandard,
          gbvImageTags: element.gbvImageTags,
          giftCardCoverCategories: element.giftCardCoverCategories,
        } as IImageOption);
      });

      handleFieldOnChange(imageListWithNoCategorySelected, FieldNames.physicalCardFilteredCoverImages);
    } else {
      let values = reducerState.product.physicalCardCoverImages?.filter((x) => {
        return x.giftCardCoverCategories?.some((y) => {
          return y.toLowerCase() == (filteredCategory?.value as string)?.toLowerCase();
        });
      });

      let imageListWithCategoriesSelected: IImageOption[] = [];
      values.forEach((element) => {
        imageListWithCategoriesSelected.push({
          imageId: element.imageId,
          ...element,
        } as IImageOption);
      });

      handleFieldOnChange(imageListWithCategoriesSelected, FieldNames.physicalCardFilteredCoverImages);
    }
  }

  function GetStandardImageForSelector(): IImageOption {
    const image: IPhysicalCardImage = reducerState.product.physicalCardCoverImages[0];

    if (image != undefined) {
      return {
        imageId: image.imageId,
        url: image.url,
        isStandard: image.isStandard,
        gbvImageTags: image.gbvImageTags,
        giftCardCoverCategories: image.giftCardCoverCategories,
      } as IImageOption;
    }
  }

  return (
    <Grid
      id="container"
      container
      item
      xs={12}
      sm={12}
      md={12}
      lg={12}
      xl={12}
      style={{ width: "100vw", position: "relative", zIndex: 1, padding: 0, height: "90vh" }}
      alignContent="flex-start"
    >
      <Grid
        id="topBanner"
        container
        item
        xs={12}
        sm={12}
        md={12}
        lg={12}
        xl={12}
        style={{
          width: "100%",
          backgroundColor: "#fff",
          height: 122,
          padding: 20,
        }}
      >
        {!isSm && (
          <Grid id="exitBtnContainer" item xs={6} sm={3} md={4} lg={3} xl={2} style={{ paddingLeft: isMd ? 5 : 30 }}>
            <Button
              id="exitBtn"
              style={{
                cursor: "pointer",
                border: "1px solid #000",
                width: 118,
                height: 48,
                padding: 1,
                float: "left",
              }}
              onClick={() => props.onExitClicked()}
            >
              <svg
                width="35"
                height="35"
                fill="currentColor"
                className="bi bi-arrow-left-short"
                viewBox="0 0 16 16"
                style={{ color: "#000" }}
              >
                <path
                  fillRule="evenodd"
                  d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5z"
                />
              </svg>
              <Typography
                style={{
                  color: "#000",
                  textTransform: "none",
                  paddingLeft: 10,
                  paddingRight: 10,
                }}
              >
                {translate(LabelKeys.Exit)}
              </Typography>
            </Button>
          </Grid>
        )}
        <Grid container item xs={12} sm={12} md={4} lg={6} xl={8} justifyContent="center">
          <Grid container item xs={12} sm={12} md={12} lg={12} xl={12} justifyContent="center">
            <Grid item xs={12} sm={8} md={12} lg={12} xl={12}>
              <div style={{ height: isSm || isMd ? 120 : 100, backgroundColor: "#fff", cursor: "pointer", justifyContent: "center" }}>
                <ul style={{ listStyle: "none", padding: 0, display: "flex", margin: "auto", width: "100%", justifyContent: "center" }}>
                  <li
                    className={showPage == 1 ? classes.showPageSelectedListItem : classes.showPageListItem}
                    onClick={() => setShowPage(1)}
                  >
                    <svg width="70" height="61" viewBox="0 0 70 61" fill="none">
                      <rect x="17.5" y="8.93335" width="34.3972" height="45.7243" stroke={showPage == 1 ? "#000" : "#9d9fa6"} />
                      <path d="M17.708 8.78739L46.3798 1V8.78739" stroke={showPage == 1 ? "#000" : "#9d9fa6"} />
                    </svg>
                    <div style={{ paddingLeft: 7 }}>{translate(LabelKeys.CoverPage)}</div>
                  </li>
                  <li
                    className={showPage == 2 ? classes.showPageSelectedListItem : classes.showPageListItem}
                    onClick={() => setShowPage(2)}
                  >
                    <svg width="72" height="60" viewBox="0 0 72 60" fill="none">
                      <rect x="0.772461" y="7.93359" width="69.7944" height="45.7243" stroke={showPage == 2 ? "#000" : "#9d9fa6"} />
                      <line
                        x1="36.1699"
                        y1="7.43335"
                        x2="36.1699"
                        y2="54.1577"
                        stroke={showPage == 2 ? "#000" : "#9d9fa6"}
                        strokeDasharray="6.55 6.55"
                      />
                    </svg>
                    <div>{translate(LabelKeys.MiddlePage)}</div>
                  </li>
                  <li
                    className={showPage == 3 ? classes.showPageSelectedListItem : classes.showPageListItem}
                    onClick={() => setShowPage(3)}
                  >
                    <svg width="70" height="61" viewBox="0 0 70 61" fill="none" style={{ transform: "rotateY(180deg)" }}>
                      <rect x="17.5" y="8.93335" width="34.3972" height="45.7243" stroke={showPage == 3 ? "#000" : "#9d9fa6"} />
                      <path d="M17.708 8.78739L46.3798 1V8.78739" stroke={showPage == 3 ? "#000" : "#9d9fa6"} />
                    </svg>
                    <div style={{ paddingLeft: 15 }}>{translate(LabelKeys.BackPage)}</div>
                  </li>
                </ul>
              </div>
            </Grid>
          </Grid>
        </Grid>
        {!isSm && (
          <Grid container justifyContent="right" item xs={12} sm={4} md={4} lg={3} xl={2} style={{ paddingRight: isMd ? 5 : 30 }}>
            <Button
              id="previousBtn"
              disabled={showPage == 1}
              style={{
                cursor: "pointer",
                border: showPage == 1 ? "1px solid gray" : "1px solid #000",
                width: 118,
                height: 48,
                padding: 1,
                marginRight: 3,
              }}
              onClick={() => setShowPage(showPage - 1)}
            >
              <svg
                width="35"
                height="35"
                fill="currentColor"
                className="bi bi-arrow-left-short"
                viewBox="0 0 16 16"
                style={{ color: showPage == 1 ? "gray" : "#000" }}
              >
                <path
                  fillRule="evenodd"
                  d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5z"
                />
              </svg>
              <Typography
                style={{
                  color: showPage == 1 ? "gray" : "#000",
                  textTransform: "none",
                  paddingLeft: 10,
                  paddingRight: 10,
                }}
              >
                {translate(LabelKeys.Previous)}
              </Typography>
            </Button>
            <Button
              id="nextBtn"
              style={{
                cursor: "pointer",
                backgroundColor: "#2eac77",
                width: 118,
                height: 48,
                padding: 1,
                marginLeft: 3,
              }}
              onClick={() => {
                if (showPage == 3) {
                  props.onDoneClicked();
                } else setShowPage(showPage + 1);
              }}
            >
              <Typography
                style={{
                  color: "#fff",
                  textTransform: "none",
                  paddingLeft: 10,
                  paddingRight: 10,
                }}
              >
                {showPage == 3 ? translate(LabelKeys.Done) : translate(LabelKeys.Next)}
              </Typography>
              <svg
                width="35"
                height="35"
                fill="currentColor"
                className="bi bi-arrow-right-short"
                viewBox="0 0 16 16"
                style={{ color: "#fff" }}
              >
                <path
                  fillRule="evenodd"
                  d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z"
                />
              </svg>
            </Button>
          </Grid>
        )}
      </Grid>
      <Grid
        id="sidebarPreviewContainer"
        container
        item
        xs={12}
        sm={12}
        md={12}
        lg={12}
        xl={12}
        style={{ backgroundColor: "#e3e1de", height: isMd ? "90%" : "85%" }}
        justifyContent="center"
      >
        {(showPage == 1 || showPage == 2) && (
          <Grid id="leftSidebarContainer" item xs={12} sm={4} md={4} lg={3} xl={3}>
            <div
              style={{
                width: isSm ? "100%" : isMd ? 250 : 300,
                left: isSm ? 0 : isMd ? 25 : 50,
                top: isSm ? 150 : 100,
                overflowY: "scroll",
                position: "absolute",
                backgroundColor: "#fff",
                padding: isMd ? 10 : 15,
                boxShadow: "6px 9px 42px 0px rgba(0,0,0,0.4)",
              }}
            >
              {showPage == 1 && (
                <>
                  <Typography variant="body1" style={{ fontSize: "1.2rem", marginTop: 20 }}>
                    {translate(LabelKeys.ChooseCoverDesign)}
                  </Typography>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ paddingTop: 26, margin: "auto" }} justifyContent="center">
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <div style={{ display: "flex" }}>
                        <Typography id="categories" variant="body1" style={{ textAlign: "left", fontWeight: "bold" }}>{`${translate(
                          LabelKeys.Category
                        )}:`}</Typography>
                      </div>
                      <div style={{ paddingTop: 10, width: "100%" }}>
                        <CSDropDown
                          id="categoriesDropDown"
                          classNameRoot={classes.dropDownMenuRoot}
                          classNameInner={classes.dropDownInner}
                          filterOptions={physicalCardState.categoryFilterOptions}
                          value={physicalCardState.physicalCardSelectedCategory?.name}
                          onStateChanged={(selectedOptions) => FilterCoverImageByCategory(selectedOptions?.value as string)}
                        />
                      </div>
                    </Grid>
                  </Grid>
                  <div style={{ marginTop: 30 }}>
                    <CSImageSelectorPhysicalCardEditor
                      height={510}
                      className={classes.physicalCardImageSelector}
                      physicalCardStandardImage={GetStandardImageForSelector()}
                      physicalCardSelectedCoverImage={physicalCardState.physicalCardSelectedCoverImage}
                      physicalCardSelectedCategory={physicalCardState.physicalCardSelectedCategory}
                      onStateChanged={(value, fieldName) => {
                        handleFieldOnChange({} as IUploadedFile, FieldNames.physicalCardSelectedUploadedCoverImage);
                        handleFieldOnChange(value, fieldName);
                        props.onStateChanged(StateNames.stepperThreeState, value, fieldName);
                        props.onStateChanged(
                          StateNames.stepperThreeState,
                          {} as IUploadedFile,
                          FieldNames.physicalCardSelectedUploadedCoverImage
                        );
                      }}
                      imageOptions={physicalCardState.physicalCardFilteredCoverImages}
                      hasUploadedImage={physicalCardState.physicalCardSelectedUploadedCoverImage?.base64?.length > 0}
                    />
                    {physicalCardState.physicalCardSelectedCategory?.value == UploadCoverCategoryItem.value && (
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ paddingTop: 40 }}>
                        <CSFileUploader
                          key={"leftSideCoverPageUploader"}
                          onChange={(e) => handleOnCoverPageUpload(e)}
                          isCSVFile={false}
                          btnHeight={40}
                          btnWidth={"100%"}
                          isImageUploaded={true}
                          btnTitle={translate(LabelKeys.UploadPreviewImageBtn)}
                          fileTypes=".jpeg,.jpg,.png"
                          maxResolution={Resolution.PhysicalCardCoverPage}
                          aspectRatio={AspectRatio.PhysicalCardCoverPage}
                          maxFileSizeInBytes={MaxFileSize.FiveMB}
                        />
                        {physicalCardState.physicalCardSelectedCategory?.value == UploadCoverCategoryItem.value && (
                          <Typography
                            variant="body2"
                            style={{
                              whiteSpace: "pre-line",
                              textAlign: "center",
                              marginTop: 10,
                            }}
                          >
                            {`${translate(LabelKeys.RecommendedSize)}: ${Resolution.PhysicalCardCoverPage}`}
                          </Typography>
                        )}
                        {physicalCardState.physicalCardSelectedCategory?.value == UploadCoverCategoryItem.value &&
                          (physicalCardState.physicalCardSelectedUploadedCoverImage.base64?.length <= 0 ||
                            physicalCardState.physicalCardSelectedUploadedCoverImage?.base64 == undefined) && (
                            <Typography variant="body2" style={{ textAlign: "center", marginTop: 10, fontStyle: "italic", color: "grey" }}>
                              {`${translate(LabelKeys.NoUploadedCoverImage)}`}
                            </Typography>
                          )}
                        {physicalCardState.physicalCardSelectedUploadedCoverImage?.base64?.length > 0 && showPage == 1 && (
                          <Grid item xs={6} sm={6} md={6} lg={4} xl={4} justifyContent="center" style={{ margin: "auto", marginTop: 20 }}>
                            <Button
                              style={{
                                borderRadius: 0,
                                borderColor: "#000",
                                height: 40,
                                border: "1px solid #000",
                                display: "flex",
                                margin: "auto",
                                cursor: "pointer",
                                width: "100%",
                                color: "#000",
                              }}
                              onClick={() => handleDeselectCoverPageFile()}
                            >
                              <svg
                                width="20"
                                height="20"
                                fill="currentColor"
                                className="bi bi-trash"
                                viewBox="0 0 16 16"
                                style={{ paddingRight: 7 }}
                              >
                                <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                                <path
                                  fillRule="evenodd"
                                  d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"
                                />
                              </svg>
                              <div style={{ textTransform: "none" }}>{translate(LabelKeys.Remove)}</div>
                            </Button>
                          </Grid>
                        )}
                      </Grid>
                    )}
                  </div>
                </>
              )}
              {showPage == 2 && (
                <div style={{ width: "100%", margin: "auto" }}>
                  <Typography variant="body1" style={{ fontSize: "1.2rem", marginTop: 20 }}>
                    {translate(LabelKeys.MakeItPersonaly)}
                  </Typography>
                  <div style={{ display: "flex", marginTop: 20 }}>
                    {!isHeaderSuggestionVisible ? (
                      <svg
                        width="20"
                        height="20"
                        fill="currentColor"
                        className="bi bi-plus"
                        viewBox="0 0 16 16"
                        style={{ marginTop: 3, paddingRight: 5 }}
                      >
                        <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                      </svg>
                    ) : (
                      <svg
                        width="20"
                        height="20"
                        fill="currentColor"
                        className="bi bi-dash"
                        viewBox="0 0 16 16"
                        style={{ marginTop: 3, paddingRight: 5 }}
                      >
                        <path d="M4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8z" />
                      </svg>
                    )}
                    <Typography
                      variant="body1"
                      style={{ textDecoration: "underline", cursor: "pointer" }}
                      onClick={() => setIsHeaderSuggestionVisible(!isHeaderSuggestionVisible)}
                    >
                      {translate(LabelKeys.PhysicalCardHeadingSuggestions)}
                    </Typography>
                  </div>
                  {isHeaderSuggestionVisible && (
                    <CSDropDown
                      id="physicalCardHeadingSuggestionDropDown"
                      classNameRoot={classes.physicalCardHeadingSuggestionDropDown}
                      filterOptions={physicalCardState.physicalCardHeadingTemplates}
                      value={physicalCardState.physicalCardHeadingSuggestion?.name}
                      placeholder={translate(LabelKeys.PhysicalCardHeadingSuggestions)}
                      onStateChanged={(e) => {
                        handleFieldOnChange(e, FieldNames.physicalCardHeadingSuggestion);
                        props.onStateChanged(StateNames.stepperThreeState, e.value, FieldNames.physicalCardHeading);
                      }}
                    />
                  )}
                  <Typography id="physicalCardHeadingText" variant="body2" style={{ textAlign: "left", marginTop: 20 }}>
                    {`${translate(LabelKeys.Header)}:`}
                  </Typography>
                  <CSTextField
                    id="physicalCardHeading"
                    type="text"
                    placeholder={`${translate(LabelKeys.Heading)}`}
                    value={props.physicalCardHeading}
                    onChange={(e: any) =>
                      props.onStateChanged(StateNames.stepperThreeState, e.target.value, FieldNames.physicalCardHeading)
                    }
                    maxLength={physicalCardTitleMaxLength}
                  />
                  <Typography variant="body2" style={{ float: "right", marginTop: 5, opacity: 0.4, fontSize: "0.8rem" }}>
                    {`${props.physicalCardHeading?.length?.toString()}/${physicalCardTitleMaxLength?.toString()}`}
                  </Typography>
                  <div style={{ display: "flex", marginTop: 20 }}>
                    {!isCustomMessageSuggestionVisible ? (
                      <svg
                        width="20"
                        height="20"
                        fill="currentColor"
                        className="bi bi-plus"
                        viewBox="0 0 16 16"
                        style={{ marginTop: 3, paddingRight: 5 }}
                      >
                        <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                      </svg>
                    ) : (
                      <svg
                        width="20"
                        height="20"
                        fill="currentColor"
                        className="bi bi-dash"
                        viewBox="0 0 16 16"
                        style={{ marginTop: 3, paddingRight: 5 }}
                      >
                        <path d="M4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8z" />
                      </svg>
                    )}
                    <Typography
                      variant="body1"
                      style={{ textDecoration: "underline", cursor: "pointer" }}
                      onClick={() => setIsCustomMessageSuggestionVisible(!isCustomMessageSuggestionVisible)}
                    >
                      {translate(LabelKeys.PhysicalCardCustomMessageSuggestions)}
                    </Typography>
                  </div>
                  {isCustomMessageSuggestionVisible && (
                    <CSDropDown
                      id="physicalCardCustomMessageSuggestionDropDown"
                      classNameRoot={classes.physicalCardCustomMessageSuggestionDropDown}
                      filterOptions={physicalCardState.physicalCardCustomMessageTemplates}
                      value={physicalCardState.physicalCardCustomMessageSuggestion?.name}
                      placeholder={translate(LabelKeys.PhysicalCardCustomMessageSuggestions)}
                      onStateChanged={(e) => {
                        handleFieldOnChange(e, FieldNames.physicalCardCustomMessageSuggestion);
                        props.onStateChanged(StateNames.stepperThreeState, e.value, FieldNames.physicalCardCustomMessage);
                      }}
                    />
                  )}
                  <Typography id="physicalCardCustomMessageText" variant="body2" style={{ textAlign: "left", marginTop: 20 }}>{`${translate(
                    LabelKeys.CustomMessage
                  )}:`}</Typography>
                  <CSTextField
                    id="physicalCardCustomMessage"
                    type="text"
                    multiline
                    rows={10}
                    placeholder={`${translate(LabelKeys.PhysicalCardCustomMessage)}`}
                    maxLength={physicalCardCustomMessageMaxLength}
                    value={props.physicalCardCustomMessage}
                    onChange={(e: any) =>
                      props.onStateChanged(StateNames.stepperThreeState, e.target.value, FieldNames.physicalCardCustomMessage)
                    }
                  />
                  <Typography variant="body2" style={{ float: "right", marginTop: 5, opacity: 0.4, fontSize: "0.8rem" }}>
                    {`${props.physicalCardCustomMessage?.length?.toString()}/${physicalCardCustomMessageMaxLength?.toString()}`}
                  </Typography>
                </div>
              )}
              {showPage == 2 && (
                <>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ marginTop: 40 }}>
                    <CSFileUploader
                      key={"cardMiddleLeftLogotypeUploader"}
                      onChange={(e) => handleOnLogotypeUpload(e)}
                      isCSVFile={false}
                      btnHeight={40}
                      btnWidth={"100%"}
                      isImageUploaded={true}
                      btnTitle={translate(LabelKeys.UploadLogotype)}
                      fileTypes=".jpeg,.jpg,.png"
                      maxResolution={Resolution.LogoExpanded}
                      maxFileSizeInBytes={MaxFileSize.TwoMB}
                    />
                  </Grid>
                  <Typography variant="body2" style={{ textAlign: "center", marginTop: 10 }}>
                    {`${translate(LabelKeys.RecommendedSize)}: ${Resolution.LogoExpanded}`}
                  </Typography>
                  {(physicalCardState.physicalCardSelectedUploadedLogotype.base64?.length <= 0 ||
                    physicalCardState.physicalCardSelectedUploadedLogotype?.base64 == undefined) && (
                    <Typography variant="body2" style={{ textAlign: "center", marginTop: 10, fontStyle: "italic", color: "grey" }}>
                      {`${translate(LabelKeys.NoUploadedLogo)}`}
                    </Typography>
                  )}
                </>
              )}
              {physicalCardState.physicalCardSelectedUploadedLogotype?.base64?.length > 0 && showPage == 2 && (
                <Grid item xs={6} sm={6} md={6} lg={4} xl={4} justifyContent="center" style={{ margin: "auto", marginTop: 20 }}>
                  <Button
                    style={{
                      borderRadius: 0,
                      borderColor: "#000",
                      height: 40,
                      border: "1px solid #000",
                      display: "flex",
                      margin: "auto",
                      cursor: "pointer",
                      width: "100%",
                      color: "#000",
                    }}
                    onClick={() => handleDeselectLogotypeFile()}
                  >
                    <svg width="20" height="20" fill="currentColor" className="bi bi-trash" viewBox="0 0 16 16" style={{ paddingRight: 7 }}>
                      <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                      <path
                        fillRule="evenodd"
                        d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"
                      />
                    </svg>
                    <div style={{ textTransform: "none" }}>{translate(LabelKeys.Remove)}</div>
                  </Button>
                </Grid>
              )}
            </div>
          </Grid>
        )}
        <Grid id="preview" container item xs={12} sm={9} md={8} lg={9} xl={9} style={{ width: "100%" }} justifyContent="center">
          {!isMobile && !isSm && (
            <>
              {showPage == 1 && (
                <div
                  className={classes.root}
                  onClick={() => setShowPage(2)}
                  style={{ zoom: isSm ? 0.7 : 1, height: "100%", width: isMd ? "100%" : "40%" }}
                >
                  <div
                    className={classes.cardFrontContainer}
                    style={{ borderColor: props.physicalCardCoverImageHasError ? "1px solid red" : "" }}
                  >
                    <img
                      className={classes.cardFrontCoverImage}
                      src={
                        physicalCardState.physicalCardSelectedCoverImage?.imageId == undefined
                          ? physicalCardState.physicalCardSelectedUploadedCoverImage?.base64
                          : `${physicalCardState.physicalCardSelectedCoverImage?.url}?maxWidth=450`
                      }
                    />
                  </div>
                  <div className={classes.cardInsideImageContainer}>
                    <img
                      className={classes.insideFrontPageImage}
                      src={`${physicalCardState.physicalCardSelectedMiddlePageImage?.url}?maxWidth=450`}
                    />
                  </div>
                </div>
              )}
              {showPage == 2 && (
                <div
                  className={classes.rootMiddle}
                  style={{
                    display: "flex",
                    overflow: "hidden",
                  }}
                >
                  <div className={classes.cardMiddleLeft} onClick={() => setShowPage(1)}>
                    <div
                      style={{
                        flexDirection: "column",
                        display: "flex",
                        overflow: "hidden",
                        alignItems: "center",
                        textAlign: "center",
                        paddingTop: 60,
                        paddingLeft: 40,
                        paddingRight: 40,
                        paddingBottom: 40,
                      }}
                    >
                      <Typography
                        id="middleLeftTitle"
                        variant={"h2"}
                        style={{
                          fontSize: isMd ? 26 : "",
                          whiteSpace: "pre-wrap",
                          width: "95%",
                          margin: "auto",
                          overflow: "hidden",
                          wordWrap: "break-word",
                        }}
                      >
                        {props.physicalCardHeading}
                      </Typography>
                      <div id="customMessageTextLogoContainer" style={{ flexDirection: "column", display: "flex" }}>
                        <Typography
                          id="middleLeftCustomMessage"
                          variant={"body1"}
                          style={{
                            marginTop: 15,
                            marginBottom: 15,
                            height: isMd ? 175 : 200,
                            width: 200,
                            overflowWrap: "break-word",
                            whiteSpace: "pre-wrap",
                            overflow: "hidden",
                            fontSize: 12,
                          }}
                        >
                          {props.physicalCardCustomMessage}
                        </Typography>
                        <div
                          id="logotype"
                          style={{
                            height: isMd ? 40 : 60,
                            width: isMd ? "80%" : "100%",
                            position: "relative",
                            //marginTop: 5,
                            margin: "auto",
                          }}
                        >
                          {physicalCardState.physicalCardSelectedUploadedLogotype?.base64?.length == undefined ||
                          physicalCardState.physicalCardSelectedUploadedLogotype?.base64?.length <= 0 ? (
                            <div className={classes.noLogotypeSelected}>
                              <div id="noLogotypeSelectedText" style={{ textAlign: "center", marginTop: isMd ? 7 : 20 }}>
                                {translate(LabelKeys.Logotype)}
                              </div>
                            </div>
                          ) : (
                            <img
                              className={classes.cardMiddleLeftLogotype}
                              src={physicalCardState.physicalCardSelectedUploadedLogotype?.base64}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={classes.cardMiddleRight} onClick={() => setShowPage(3)}>
                    <img
                      style={{ maxHeight: "99.5%" }}
                      width="100%"
                      src={`${physicalCardState.physicalCardSelectedMiddlePageImage?.url}?maxWidth=450`}
                      loading="lazy"
                    />
                  </div>
                </div>
              )}
              {showPage == 3 && (
                <div style={{ width: "100%", height: 650 }}>
                  <div className={classes.rootBack} onClick={() => setShowPage(2)} style={{ height: "100%" }}>
                    <div className={classes.cardBackImageContainer}>
                      <img width="100%" src={`${physicalCardState.physicalCardSelectedBackImage?.url}?maxWidth=451`} loading="lazy" />
                    </div>
                    <div className={classes.cardBackInsideContentContainer}>
                      <Typography variant="h1" align={"center"} style={{ paddingTop: 135, paddingLeft: 35 }}>
                        {props.physicalCardHeading ?? ""}
                      </Typography>
                      <Typography variant="body2" align={"center"} style={{ paddingTop: 25, paddingLeft: 50 }}>
                        {props.physicalCardCustomMessage ?? ""}
                      </Typography>
                    </div>
                  </div>
                </div>
              )}
            </>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
}

export default React.memo(MessagePhysicalCardWrapper);
