import { Grid, Typography, useMediaQuery } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { LabelKeys } from "../../Utils/enums/labelKeys";
import { FieldNames, GetCurrentLanguageAsText, StateNames } from "../../Utils/types";
import CSTextField from "../Input/Textfield/CSTextField";
import { StepperFourStyles } from "./styles";
import CSInfo from "../Helper/CSInfo";
import { useDispatch, useSelector } from "react-redux";
import { CompanyActions } from "../../Modules/Company/actions";
import { SystemPart } from "../../Utils/enums/enums";
import { theme } from "../../appTheme";
import { RootState } from "../../store";
import { ResellerActions } from "../../Modules/Reseller/actions";
import { IGetResellerByCodeRequest } from "../../Modules/Reseller/types";
import Cookies from "js-cookie";
import { CookieConstants } from "../../Utils/Labels/types";
import { GetLitiumImageUrl, ValidateOrganisationNumber } from "../../Utils/extensions";
import { ImageKeys } from "../../Utils/enums/imageKeys";
import { useNavigate } from "react-router";
import { IsResellerAuth, IsAuth } from "../../Utils/authentication";
import { QueryParamKeys } from "../../Utils/enums/queryParams";

interface ICSStepperStepFourProps {
  id: string;
  contactResellerName: string;
  companyName: string;
  companyContactPhoneNumber: string;
  organisationNumber: string;
  marking: string;
  contactEmail: string;
  systemPart: SystemPart;
  senderEmail: string;
  isAutomaticlySet: boolean;
  companyNameHasError?: boolean;
  organisationNumberHasError?: boolean;
  contactEmailHasError?: boolean;
  senderEmailHasError: boolean;
  contactResellerNameHasError?: boolean;
  companyContactPhoneNumberHasError?: boolean;
  markingHasError?: boolean;
  onStateChanged: (stateName: StateNames.stepperFourState, value: string | boolean | number, fieldName: FieldNames) => void;
}

export default function CSStepperStepFour(props: ICSStepperStepFourProps) {
  const classes = StepperFourStyles();
  const dispatch = useDispatch();
  const { t: translate } = useTranslation();
  const [resellerTooltipDialogIsOpen, setResellerTooltipDialogIsOpen] = useState(false);
  const [orgNrTooltipDialogIsOpen, setOrgNrTooltipDialogIsOpen] = useState(false);
  const [markingTooltipDialogIsOpen, setMarkingTooltipDialogIsOpen] = useState(false);
  const [phoneNumberTooltipDialogIsOpen, setPhoneNumberTooltipDialogIsOpen] = useState(false);
  const [emailTooltipDialogIsOpen, setEmailTooltipDialogIsOpen] = useState(false);
  const [companyEmailTooltipDialogIsOpen, setCompanyEmailTooltipDialogIsOpen] = useState(false);
  const isMobile = useMediaQuery(theme.breakpoints.only("xs"));
  const navigate = useNavigate();
  const isResellerConnected = Cookies.get(CookieConstants.GBVReseller)?.length > 0 && props.systemPart == SystemPart.Company;
  const isAdmin =
    (IsResellerAuth() &&
      location.pathname.includes(
        `/${translate(LabelKeys.ResellerUrl)}/${translate(LabelKeys.AdminUrl)}/${translate(LabelKeys.OrderUrl)}`
      )) ||
    (IsAuth() && location.pathname.includes(`/${translate(LabelKeys.AdminUrl)}/${translate(LabelKeys.OrderUrl)}`));

  const reducerState = useSelector(
    (s: RootState) => {
      return {
        order: s.order,
        company: s.company,
        reseller: s.reseller,
      };
    },
    (prev, next) =>
      prev.order.environmentState.systemPart === next.order.environmentState.systemPart &&
      prev.company.companyDetails === next.company.companyDetails &&
      prev.reseller.activeReseller === next.reseller.activeReseller
  );

  useEffect(() => {
    window.scroll(0, 0);
    if (Cookies.get(CookieConstants.GBVReseller)?.length > 0) {
      dispatch(
        ResellerActions.GetResellerByCodeRequest({
          code: Cookies.get(CookieConstants.GBVReseller),
        } as IGetResellerByCodeRequest)
      );
    }
    if (reducerState.order.environmentState.systemPart === undefined && !isAdmin) {
      navigate(`/${GetCurrentLanguageAsText()}/`);
    }
  }, []);

  function handleFieldOnChange(stateName: StateNames.stepperFourState, value: string, fieldName: FieldNames) {
    props.onStateChanged(stateName, value, fieldName);
  }

  function handleOrganisationNumberOnBlur() {
    if (ValidateOrganisationNumber(props?.organisationNumber)) {
      dispatch(
        CompanyActions.GetCompanyDetailsRequest({
          organisationNumber: props.organisationNumber,
        })
      );
    }
  }

  useEffect(() => {
    if (props.companyName?.length === 0 && reducerState.company.companyDetails?.organisationNumber?.length > 0) {
      handleFieldOnChange(
        StateNames.stepperFourState,
        reducerState.company.companyDetails?.organisationNumber,
        FieldNames.organisationNumber
      );
    }
    if (props.companyName?.length === 0 && reducerState.company.companyDetails?.name?.length > 0) {
      handleFieldOnChange(StateNames.stepperFourState, reducerState.company.companyDetails?.name, FieldNames.companyName);
    }
    if (props.contactEmail?.length === 0 && reducerState.company.companyDetails?.email?.length > 0) {
      handleFieldOnChange(StateNames.stepperFourState, reducerState.company.companyDetails?.email, FieldNames.senderEmail);
    }
  }, [reducerState.company.companyDetails]);

  useEffect(() => {
    if (props.contactResellerName?.length === 0 && reducerState.reseller?.activeReseller?.companyName?.length > 0) {
      handleFieldOnChange(StateNames.stepperFourState, reducerState.reseller?.activeReseller?.companyName, FieldNames.contactResellerName);
    }
  }, [reducerState.reseller?.activeReseller]);

  return (
    <Grid
      container
      item
      xs={12}
      sm={12}
      md={12}
      lg={12}
      xl={12}
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start",
        alignItems: "flex-start",
      }}
    >
      <Grid id="leftContent" container item xs={12} sm={6} md={6} lg={6} xl={6}>
        <Grid id="title" item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Typography variant="h1" align="left">
            {translate(LabelKeys.StepFourTitle)}
          </Typography>
        </Grid>
        <Grid id="description" item xs={12} sm={12} md={12} lg={12} xl={12} style={{ paddingTop: 20 }}>
          <Typography variant="body1" align="left">
            {props.systemPart == SystemPart.Company && !isAdmin
              ? translate(LabelKeys.StepFourDescriptionPrivate)
              : translate(LabelKeys.StepFourDescriptionCompany)}
          </Typography>
        </Grid>
        {props.systemPart == SystemPart.Company && !isAdmin && (
          <Grid id="companyInformation" container item xs={12} sm={12} md={12} lg={12} xl={12} style={{ paddingTop: 50 }}>
            <Grid container item xs={12} sm={12} md={12} lg={12} xl={12} style={{ margin: "auto" }}>
              <Grid container item xs={12} sm={12} md={11} lg={11} xl={11} alignContent="flex-start">
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Typography align="left" variant="h3">
                    {translate(LabelKeys.CompanyInformation)}
                  </Typography>
                </Grid>
                <div style={{ display: "flex", paddingTop: 30 }}>
                  <div>
                    <Typography
                      align="left"
                      variant="body2"
                      sx={{ paddingTop: { xs: "5px", sm: "5px", md: "5px", lg: "3px", xl: "3px" } }}
                      style={{ fontWeight: "bold" }}
                    >
                      {`${translate(LabelKeys.OrganisationNumber)}*`}
                    </Typography>
                  </div>
                  <div style={{ padding: 0, margin: 0, bottom: 0, paddingLeft: 14 }}>
                    <CSInfo
                      hoverTooltopText={`${translate(LabelKeys.CorrectOrganisationNumberFormats)}`}
                      popupTooltipText={`${translate(LabelKeys.CorrectOrganisationNumberFormats)}`}
                      tooltipDialogIsOpen={orgNrTooltipDialogIsOpen}
                      onSetTooltipDialogIsOpen={(value) => setOrgNrTooltipDialogIsOpen(value)}
                    />
                  </div>
                </div>
                <Grid item xs={11} sm={11} md={11} lg={11} xl={11}>
                  <CSTextField
                    id="organisationNumber"
                    required
                    maxLength={11}
                    type="text"
                    value={props.organisationNumber}
                    placeholder={`${translate(LabelKeys.OrganisationNumberPlaceholder)}`}
                    onBlur={() => handleOrganisationNumberOnBlur()}
                    onChange={(e) => handleFieldOnChange(StateNames.stepperFourState, e.target.value, FieldNames.organisationNumber)}
                    error={props.organisationNumberHasError}
                    helperText={translate(LabelKeys.InvalidOrganisationNumber)}
                  />
                </Grid>
                <Grid item xs={11} sm={11} md={11} lg={11} xl={11} className={classes.textfield}>
                  <CSTextField
                    id="companyName"
                    required
                    label={translate(LabelKeys.CompanyName)}
                    value={props.companyName}
                    placeholder={`${translate(LabelKeys.CompanyNamePlaceholder)}`}
                    type="text"
                    onChange={(e) => handleFieldOnChange(StateNames.stepperFourState, e.target.value, FieldNames.companyName)}
                    error={props.companyNameHasError}
                    helperText={translate(LabelKeys.InvalidCompanyName)}
                  />
                </Grid>
                <React.Fragment>
                  <Grid item xs={11} sm={11} md={11} lg={11} xl={11}>
                    <div style={{ display: "flex", marginTop: 30 }}>
                      <div>
                        <Typography
                          align="left"
                          variant="body2"
                          sx={{ paddingTop: { xs: "5px", sm: "5px", md: "5px", lg: "3px", xl: "3px" } }}
                          style={{ fontWeight: "bold" }}
                        >
                          {`${translate(LabelKeys.Email)}*`}
                        </Typography>
                      </div>
                      <div style={{ padding: 0, margin: 0, bottom: 0, paddingLeft: 14 }}>
                        <CSInfo
                          hoverTooltopText={`${translate(LabelKeys.CorrectEmailFormat)}`}
                          popupTooltipText={`${translate(LabelKeys.CorrectEmailFormat)}`}
                          tooltipDialogIsOpen={companyEmailTooltipDialogIsOpen}
                          onSetTooltipDialogIsOpen={(value) => setCompanyEmailTooltipDialogIsOpen(value)}
                        />
                      </div>
                    </div>
                    <CSTextField
                      id="senderEmail"
                      required
                      type="email"
                      value={props.senderEmail}
                      placeholder={`${translate(LabelKeys.Email)}`}
                      onChange={(e) => handleFieldOnChange(StateNames.stepperFourState, e.target.value, FieldNames.senderEmail)}
                      error={props.senderEmailHasError}
                      helperText={translate(LabelKeys.InvalidEmailFormat)}
                    />
                  </Grid>
                  {isResellerConnected && (
                    <Grid item xs={11} sm={11} md={11} lg={11} xl={11} style={{ paddingTop: 30 }}>
                      <CSTextField
                        id="contactResellerName"
                        type="text"
                        label={translate(LabelKeys.Reseller)}
                        disabled={Cookies.get(CookieConstants.GBVReseller)?.length > 0}
                        value={props.contactResellerName}
                        placeholder={`${translate(LabelKeys.ContactResellerNamePlaceholder)}`}
                        onChange={(e) => handleFieldOnChange(StateNames.stepperFourState, e.target.value, FieldNames.contactResellerName)}
                        error={props.contactResellerNameHasError}
                        helperText={translate(LabelKeys.FieldCantBeEmpty)}
                      />
                    </Grid>
                  )}
                </React.Fragment>
              </Grid>
            </Grid>
          </Grid>
        )}
        <Grid container item xs={11} sm={11} md={11} lg={11} xl={11}>
          <Grid id="title" item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Typography variant="h3" style={{ textAlign: "left", paddingTop: 50 }}>
              {translate(LabelKeys.Client)}
            </Typography>
          </Grid>
          <div style={{ display: "flex", paddingTop: 30 }}>
            <div>
              <Typography
                align="left"
                variant="body2"
                sx={{ paddingTop: { xs: "5px", sm: "5px", md: "5px", lg: "3px", xl: "3px" } }}
                style={{ fontWeight: "bold" }}
              >
                {`${translate(LabelKeys.Email)}*`}
              </Typography>
            </div>
            <div style={{ padding: 0, margin: 0, bottom: 0, paddingLeft: 14 }}>
              <CSInfo
                hoverTooltopText={`${translate(LabelKeys.CorrectEmailFormat)}`}
                popupTooltipText={`${translate(LabelKeys.CorrectEmailFormat)}`}
                tooltipDialogIsOpen={emailTooltipDialogIsOpen}
                onSetTooltipDialogIsOpen={(value) => setEmailTooltipDialogIsOpen(value)}
              />
            </div>
          </div>
          <Grid item xs={11} sm={11} md={11} lg={11} xl={11}>
            <CSTextField
              id="contactEmail"
              required
              type="email"
              value={props.contactEmail}
              placeholder={`${translate(LabelKeys.YourEmail)}`}
              onChange={(e) => handleFieldOnChange(StateNames.stepperFourState, e.target.value, FieldNames.contactEmail)}
              error={props.contactEmailHasError}
              helperText={translate(LabelKeys.InvalidEmailFormat)}
            />
          </Grid>
          {props.systemPart == SystemPart.Company && !isAdmin && (
            <div style={{ display: "flex", paddingTop: 30 }}>
              <div>
                <Typography
                  align="left"
                  variant="body2"
                  sx={{ paddingTop: { xs: "5px", sm: "5px", md: "5px", lg: "3px", xl: "3px" } }}
                  style={{ fontWeight: "bold" }}
                >
                  {`${translate(LabelKeys.CompanyContactPhoneNumber)}*`}
                </Typography>
              </div>
              <div style={{ padding: 0, margin: 0, bottom: 0, paddingLeft: 14 }}>
                <CSInfo
                  hoverTooltopText={`${translate(LabelKeys.CorrectPhoneNumberFormats)}`}
                  popupTooltipText={`${translate(LabelKeys.CorrectPhoneNumberFormats)}`}
                  tooltipDialogIsOpen={phoneNumberTooltipDialogIsOpen}
                  onSetTooltipDialogIsOpen={(value) => setPhoneNumberTooltipDialogIsOpen(value)}
                />
              </div>
            </div>
          )}
          {props.systemPart == SystemPart.Company && !isAdmin && (
            <Grid item xs={11} sm={11} md={11} lg={11} xl={11}>
              <CSTextField
                id="companyContactPhoneNumber"
                required
                type="text"
                value={props.companyContactPhoneNumber}
                placeholder={`${translate(LabelKeys.CompanyContactPhoneNumberPlaceholder)}`}
                onChange={(e: any) => {
                  let value = e.target.value;
                  value = value.replaceAll(" ", "");
                  handleFieldOnChange(StateNames.stepperFourState, value, FieldNames.companyContactPhoneNumber);
                }}
                error={props.companyContactPhoneNumberHasError}
                helperText={translate(LabelKeys.InvalidCompanyContactPhoneNumber)}
              />
            </Grid>
          )}
          {(props.systemPart == SystemPart.Company || isAdmin) && (
            <Grid item xs={11} sm={11} md={11} lg={11} xl={11}>
              <div style={{ display: "flex", paddingTop: 30 }}>
                <div>
                  <Typography
                    align="left"
                    variant="body2"
                    sx={{ paddingTop: { xs: "5px", sm: "5px", md: "5px", lg: "3px", xl: "3px" } }}
                    style={{ fontWeight: "bold" }}
                  >
                    {`${translate(LabelKeys.Marking)}`}
                  </Typography>
                </div>
                <div style={{ padding: 0, margin: 0, bottom: 0, paddingLeft: 14 }}>
                  <CSInfo
                    hoverTooltopText={`${translate(LabelKeys.MarkingInformation)}`}
                    popupTooltipText={`${translate(LabelKeys.MarkingInformation)}`}
                    tooltipDialogIsOpen={markingTooltipDialogIsOpen}
                    onSetTooltipDialogIsOpen={(value) => setMarkingTooltipDialogIsOpen(value)}
                  />
                </div>
              </div>
              <CSTextField
                id="marking"
                value={props.marking}
                placeholder={`${translate(LabelKeys.MarkingPlaceholder)}`}
                type="text"
                onChange={(e) => handleFieldOnChange(StateNames.stepperFourState, e.target.value, FieldNames.marking)}
                error={props.markingHasError}
                helperText={translate(LabelKeys.InvalidMarking)}
              />
            </Grid>
          )}
        </Grid>
      </Grid>
      {!isMobile && (
        <Grid id="rightContent" container item xs={6} sm={6} md={6} lg={6} xl={6} justifyContent="right">
          <img
            style={{ objectFit: "cover", maxHeight: 600, maxWidth: "100%" }}
            src={GetLitiumImageUrl(ImageKeys.DeliveryPageDesktop, `${QueryParamKeys.MaxWidth}600`)}
          />
        </Grid>
      )}
    </Grid>
  );
}
