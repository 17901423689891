import React, { useEffect, useState } from "react";
import { Grid, Typography, Button, Dialog, DialogContent, DialogContentText, DialogActions, useMediaQuery, Box, Tab } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import CSFileUploader from "../../../components/FileUploader/CSFileUploader";
import { FileType, IUploadedFile } from "../../../components/FileUploader/types";
import ProcessCircle from "../../../components/ProcessCircle/ProcessCircle";
import SnackbarActions from "../../../components/Snackbar/actions";
import { ResellerActions } from "../../../Modules/Reseller/actions";
import {
  IUpdateResellerLogoRequest,
  IUpdateResellerHomePageImageRequest,
  IDeleteResellerLogoRequest,
  IGetResellerHomePageImageRequest,
  IGetResellerLogoRequest,
  IGetResellerHomePageTextsRequest,
  IUpdateResellerHomePageTextsRequest,
} from "../../../Modules/Reseller/types";
import { RootState } from "../../../store";
import { LabelKeys } from "../../../Utils/enums/labelKeys";
import { LocalStorageKeys } from "../../../Utils/history/history";
import { FieldNames, GetCurrentLanguageAsText, UserSession } from "../../../Utils/types";
import { ResellerAdminStyles } from "../styles";
import { useTranslation } from "react-i18next";
import { theme } from "../../../appTheme";
import CSTextField from "../../../components/Input/Textfield/CSTextField";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import ResellerAdminMenu from "../ResellerAdminMenu";
import { ResellerAdminSubview } from "../types";
import ResellerSidebar from "./ResellerSidebar";
import { IsResellerAuth } from "../../../Utils/authentication";
import { useNavigate } from "react-router";
import { MaxFileSize } from "../../../Utils/enums/enums";

interface ResellerBrandingState {
  homePageHeaderText: string;
  homePageBodyText: string;
}

export default function ResellerBranding() {
  const { t: translate } = useTranslation();
  const classes = ResellerAdminStyles();
  const dispatch = useDispatch();
  const isMobile = useMediaQuery(theme.breakpoints.only("xs"));
  const isSm = useMediaQuery(theme.breakpoints.only("sm"));
  const [confirmDeleteResellerLogoDialogOpen, setConfirmDeleteResellerLogoDialogOpen] = useState(false);
  const [confirmDeleteResellerHomePageImageDialogOpen, setConfirmDeleteResellerHomePageImageDialogOpen] = useState(false);
  const homePageHeaderTextMaxLength = 50;
  const homePageBodyTextMaxLength = 200;
  const [value, setValue] = React.useState("1");
  const navigate = useNavigate();

  const reducerState = useSelector(
    (s: RootState) => {
      return {
        reseller: s.reseller,
        authentication: s.authentication,
      };
    },
    (prev, next) =>
      prev.reseller.isLoading === next.reseller.isLoading &&
      prev.reseller.logotypeAsBase64 === next.reseller.logotypeAsBase64 &&
      prev.reseller.logotypeFileType === next.reseller.logotypeFileType &&
      prev.reseller.homePageImageUrl === next.reseller.homePageImageUrl &&
      prev.reseller.homePageImageFileType === next.reseller.homePageImageFileType &&
      prev.reseller.homePageHeaderText === next.reseller.homePageHeaderText &&
      prev.reseller.homePageBodyText === next.reseller.homePageBodyText
  );

  const [resellerBrandingState, setResellerBrandingState] = useState({
    homePageHeaderText: reducerState.reseller.homePageHeaderText?.length > 0 ? reducerState.reseller.homePageHeaderText : "",
    homePageBodyText: reducerState.reseller.homePageBodyText?.length > 0 ? reducerState.reseller.homePageBodyText : "",
  } as ResellerBrandingState);

  useEffect(() => {
    dispatch(ResellerActions.GetResellerLogoRequest({} as IGetResellerLogoRequest));
    dispatch(ResellerActions.GetResellerHomePageImageRequest({} as IGetResellerHomePageImageRequest));
    dispatch(
      ResellerActions.GetResellerHomePageTextsRequest({
        resellerId: reducerState.reseller.adminResellerContactInfo?.resellerId,
      } as IGetResellerHomePageTextsRequest)
    );
  }, []);

  useEffect(() => {}, [reducerState.reseller.logotypeAsBase64, reducerState.reseller.homePageImageUrl]);

  useEffect(() => {
    if (!IsResellerAuth()) {
      navigate(`/${GetCurrentLanguageAsText()}/${translate(LabelKeys.Reseller)}/${translate(LabelKeys.SignInUrl)}`);
    }
  }, [reducerState.authentication.hasBeenSignedOut]);

  useEffect(() => {
    setResellerBrandingState({
      homePageBodyText: reducerState.reseller.homePageBodyText,
      homePageHeaderText: reducerState.reseller.homePageHeaderText,
    });
  }, [reducerState.reseller.homePageBodyText, reducerState.reseller.homePageHeaderText]);

  function handleFieldOnChange(fieldName: FieldNames, value: string | FileType) {
    setResellerBrandingState((state) => ({
      ...state,
      [fieldName]: value,
    }));
  }

  function handleOnFileUpload(e: IUploadedFile | React.ChangeEvent<HTMLInputElement>) {
    const file = e as IUploadedFile;
    if (file.base64?.length > 0 && file?.type !== FileType.unknown) {
      dispatch(
        ResellerActions.UpdateResellerLogoRequest({
          file: file.base64,
          fileType: file.type,
        } as IUpdateResellerLogoRequest)
      );
    }
  }

  function handleOnHomePageFileUpload(e: IUploadedFile | React.ChangeEvent<HTMLInputElement>) {
    const file = e as IUploadedFile;
    if (file.base64?.length > 0 && file?.type !== FileType.unknown) {
      dispatch(
        ResellerActions.UpdateResellerHomePageImageRequest({
          file: file.base64,
          fileType: file.type,
        } as IUpdateResellerHomePageImageRequest)
      );
    }
  }

  function handleSaveHomePageTexts(): void {
    dispatch(
      ResellerActions.UpdateResellerHomePageTextsRequest({
        homePageHeaderText: resellerBrandingState.homePageHeaderText,
        homePageBodyText: resellerBrandingState.homePageBodyText,
        resellerId: reducerState.reseller.adminResellerContactInfo?.resellerId,
      } as IUpdateResellerHomePageTextsRequest)
    );
  }

  function handleDeleteResellerLogotype(): void {
    setConfirmDeleteResellerLogoDialogOpen(false);
    const session: UserSession = JSON.parse(localStorage.getItem(LocalStorageKeys.userSession)) as UserSession;
    if (session?.resellerContactId > 0) {
      if (reducerState.reseller.logotypeAsBase64?.length > 0) {
        dispatch(ResellerActions.DeleteResellerLogoRequest({} as IDeleteResellerLogoRequest));
      }
    } else {
      dispatch(SnackbarActions.ShowError(translate(LabelKeys.CouldNotGetResellerCode)));
    }
  }

  function handleDeleteResellerHomePageImage(): void {
    setConfirmDeleteResellerHomePageImageDialogOpen(false);
    const resellerId = reducerState.reseller.adminResellerContactInfo?.resellerId;
    if (resellerId != undefined && resellerId > 0) {
      if (reducerState.reseller.homePageImageUrl?.length > 0) {
        dispatch(
          ResellerActions.DeleteResellerHomePageImageRequest({ resellerId: reducerState.reseller.adminResellerContactInfo.resellerId })
        );
      }
    } else {
      dispatch(SnackbarActions.ShowError(translate(LabelKeys.CouldNotGetResellerId)));
    }
  }

  const handleChange = (newValue: string) => {
    setValue(newValue);
  };

  return (
    <div style={{ display: "flex" }}>
      {!isMobile && (
        <Grid item xs={2} sm={1} md={1} lg={1} xl={1}>
          <ResellerSidebar activeSubview={ResellerAdminSubview.Branding} />
        </Grid>
      )}
      <Grid container item xs={12} sm={12} md={12} lg={12} xl={12} justifyContent={"center"}>
        {reducerState.reseller?.isLoading && <ProcessCircle />}
        {isMobile && (
          <Grid item xs={11}>
            <ResellerAdminMenu />
          </Grid>
        )}
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className={classes.subviewContent}>
          <React.Fragment>
            <TabContext value={value}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <TabList
                  orientation={isMobile ? "vertical" : "horizontal"}
                  textColor="secondary"
                  indicatorColor="secondary"
                  onChange={(_, value) => handleChange(value)}
                >
                  <Tab label={translate(LabelKeys.HomepageImage)} value="1" />
                  <Tab label={translate(LabelKeys.Logo)} value="2" />
                  <Tab label={translate(LabelKeys.Texts)} value="3" />
                </TabList>
              </Box>
              <Grid
                id="resellerAdminBrandingContainer"
                container
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                justifyContent={isMobile ? "center" : "flex-start"}
                sx={{ marginTop: { xs: 0, sm: 0, md: 0, lg: 0, xl: 0 }, height: "auto" }}
              >
                {reducerState.reseller?.isLoading && <ProcessCircle />}
                <TabPanel value="1">
                  <Grid
                    id="homePageImageContainer"
                    container
                    item
                    xs={12}
                    sm={12}
                    md={8}
                    lg={4}
                    xl={4}
                    justifyContent="flex-start"
                    className={classes.homePageImageContainer}
                  >
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <Typography variant="h1" style={{ textAlign: "left", height: 30 }}>
                        {translate(LabelKeys.HomepageImage)}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ textAlign: "left", marginTop: 20, height: 120 }}>
                      <Typography variant="body1" style={{ float: "left" }}>
                        {translate(LabelKeys.ResellerHomePageImageDescription)}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ paddingBottom: 30 }}>
                      {reducerState.reseller.homePageImageUrl?.length > 0 ? (
                        <img
                          //height={isMobile ? "150" : isSm ? "200" : "280"}
                          width="100%"
                          style={{ float: "left", objectFit: "contain" }}
                          src={reducerState.reseller.homePageImageUrl}
                        />
                      ) : (
                        <Typography align={isMobile ? "center" : "left"} style={{ fontWeight: "bold" }}>
                          {translate(LabelKeys.NoUploadedHomePageImage)}
                        </Typography>
                      )}
                    </Grid>
                    <Grid container item xs={12} sm={12} md={12} lg={12} xl={12} className={classes.allowedFileFormatInfo}>
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Typography
                          align={isMobile ? "center" : "left"}
                          style={{
                            whiteSpace: "pre-line",
                            fontSize: "0.85rem",
                          }}
                        >
                          {translate(LabelKeys.AllowedFileFormatResellerHomePageImage)}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Typography
                          align={isMobile ? "center" : "left"}
                          style={{
                            whiteSpace: "pre-line",
                            fontSize: "0.85rem",
                          }}
                        >
                          {translate(LabelKeys.AllowedFileTypesResellerHomePageImage)}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Typography
                          align={isMobile ? "center" : "left"}
                          style={{
                            whiteSpace: "pre-line",
                            fontSize: "0.85rem",
                          }}
                        >
                          {translate(LabelKeys.AllowedFileMaxSizeResellerHomePageImage)}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid container item xs={12} sm={12} md={12} lg={12} xl={12} style={{ marginTop: 20 }}>
                      <Grid item xs={12} sm={6} md={6} lg={6} xl={6} style={{ paddingRight: isMobile ? 0 : 3 }}>
                        <CSFileUploader
                          key={"resellerHomePageImageUploader"}
                          classNameRoot={classes.fileUploader}
                          onChange={(e) => handleOnHomePageFileUpload(e)}
                          isCSVFile={false}
                          btnHeight={56}
                          btnWidth={"100%"}
                          isImageUploaded={true}
                          btnTitle={translate(LabelKeys.UploadPreviewImageBtn)}
                          fileTypes=".jpeg,.jpg,.png"
                          maxFileSizeInBytes={MaxFileSize.FiveMB}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        md={6}
                        lg={6}
                        xl={6}
                        style={{ paddingLeft: isMobile ? 0 : 3, paddingTop: isMobile ? 20 : 0 }}
                      >
                        <Button
                          id="deleteHomePageImage"
                          style={{
                            height: 56,
                            width: "100%",
                            cursor: "pointer",
                            color: "#fff",
                            display: "flex",
                            borderRadius: 0,
                            backgroundColor: "#000",
                          }}
                          onClick={() => setConfirmDeleteResellerHomePageImageDialogOpen(true)}
                        >
                          <div style={{ margin: "auto", textTransform: "none" }}>{`${translate(LabelKeys.Delete)}`}</div>
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </TabPanel>
                <TabPanel value="2">
                  <Grid id="logoContainer" container item xs={12} sm={12} md={8} lg={4} xl={4} className={classes.logoContainer}>
                    <Grid id="logoTitle" item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <Typography variant="h2" style={{ textAlign: "left" }}>
                        {translate(LabelKeys.Logo)}
                      </Typography>
                    </Grid>
                    <Grid
                      id="resellerLogoDescription"
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      xl={12}
                      style={{ textAlign: "left", marginTop: 20, height: "auto" }}
                    >
                      <Typography variant="body1" style={{ float: "left" }}>
                        {translate(LabelKeys.ResellerLogoDescription)}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ paddingTop: 30, paddingBottom: 30 }}>
                      {reducerState.reseller.logotypeAsBase64?.length > 0 ? (
                        <img height="100" style={{ float: "left" }} src={reducerState.reseller.logotypeAsBase64} />
                      ) : (
                        <Typography align={isMobile ? "center" : "left"} style={{ fontWeight: "bold" }}>
                          {translate(LabelKeys.NoUploadedLogo)}
                        </Typography>
                      )}
                    </Grid>
                    <Grid container item xs={12} sm={12} md={12} lg={12} xl={12} className={classes.allowedFileFormatInfo}>
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Typography
                          align={isMobile ? "center" : "left"}
                          style={{
                            whiteSpace: "pre-line",
                            fontSize: "0.85rem",
                          }}
                        >
                          {translate(LabelKeys.AllowedFileFormatResellerLogo)}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Typography
                          align={isMobile ? "center" : "left"}
                          style={{
                            whiteSpace: "pre-line",
                            fontSize: "0.85rem",
                          }}
                        >
                          {translate(LabelKeys.AllowedFileTypesResellerLogo)}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Typography
                          align={isMobile ? "center" : "left"}
                          style={{
                            whiteSpace: "pre-line",
                            fontSize: "0.85rem",
                          }}
                        >
                          {translate(LabelKeys.AllowedFileMaxSizeResellerLogo)}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid container item xs={12} sm={12} md={12} lg={12} xl={12} style={{ marginTop: 20 }}>
                      <Grid item xs={12} sm={6} md={6} lg={6} xl={6} style={{ paddingRight: isMobile ? 0 : 3 }}>
                        <CSFileUploader
                          classNameRoot={classes.fileUploader}
                          key={"resellerLogoUploader"}
                          onChange={(e) => handleOnFileUpload(e)}
                          isCSVFile={false}
                          btnHeight={56}
                          btnWidth={"100%"}
                          isImageUploaded={true}
                          btnTitle={translate(LabelKeys.UploadPreviewImageBtn)}
                          fileTypes=".jpeg,.jpg,.png"
                          maxFileSizeInBytes={MaxFileSize.OneMB}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        md={6}
                        lg={6}
                        xl={6}
                        style={{ paddingLeft: isMobile ? 0 : 3, paddingTop: isMobile ? 20 : 0 }}
                      >
                        <Button
                          id="deleteLogotype"
                          style={{
                            height: 56,
                            width: "100%",
                            cursor: "pointer",
                            color: "#fff",
                            display: "flex",
                            borderRadius: 0,
                            backgroundColor: "#000",
                          }}
                          onClick={() => setConfirmDeleteResellerLogoDialogOpen(true)}
                        >
                          <div style={{ margin: "auto", textTransform: "none" }}>{`${translate(LabelKeys.Delete)}`}</div>
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </TabPanel>
              </Grid>
              <TabPanel value="3">
                <Grid container item xs={12} sm={12} md={12} lg={6} xl={6} style={{ marginTop: 30 }}>
                  <Grid item xs={12} sm={12} md={6} lg={6} xl={6} style={{ paddingRight: isMobile || isSm ? 0 : 20 }}>
                    <Typography variant="h3" align="left">
                      {translate(LabelKeys.HomePageHeaderText)}
                    </Typography>
                    <CSTextField
                      id="homePageHeaderText"
                      type="text"
                      multiline
                      rows={10}
                      maxLength={homePageHeaderTextMaxLength}
                      value={resellerBrandingState.homePageHeaderText ?? ""}
                      onChange={(e: any) => handleFieldOnChange(FieldNames.homePageHeaderText, e.target.value)}
                    />
                    <Typography variant="body2" align="right">
                      {`${resellerBrandingState.homePageHeaderText?.length ?? 0}/${homePageHeaderTextMaxLength}`}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6} xl={6} style={{ paddingLeft: isMobile || isSm ? 0 : 20 }}>
                    <Typography variant="h3" align="left">
                      {translate(LabelKeys.HomePageBodyText)}
                    </Typography>
                    <CSTextField
                      id="homePageBodyText"
                      type="text"
                      multiline
                      rows={10}
                      maxLength={homePageBodyTextMaxLength}
                      value={resellerBrandingState.homePageBodyText ?? ""}
                      onChange={(e: any) => handleFieldOnChange(FieldNames.homePageBodyText, e.target.value)}
                    />
                    <Typography variant="body2" align="right">
                      {`${resellerBrandingState.homePageBodyText?.length ?? 0}/${homePageBodyTextMaxLength}`}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} md={3} lg={3} xl={3} style={{ marginTop: 20 }}>
                    <Button
                      id="saveHomePageTextsBtn"
                      style={{
                        height: 46,
                        width: "100%",
                        cursor: "pointer",
                        color: "#fff",
                        borderRadius: 0,
                        backgroundColor: "#000",
                      }}
                      onClick={() => handleSaveHomePageTexts()}
                    >
                      <div style={{ margin: "auto", textTransform: "none" }}>{`${translate(LabelKeys.SaveTexts)}`}</div>
                    </Button>
                  </Grid>
                </Grid>
              </TabPanel>
              <Dialog
                id="resellerLogoDialog"
                open={confirmDeleteResellerLogoDialogOpen}
                disableEnforceFocus
                PaperProps={{ style: { borderRadius: 0 } }}
              >
                <DialogContent style={{ borderRadius: 0 }}>
                  <DialogContentText component={"div"}>
                    <Typography variant="h2" align="left">
                      {translate(LabelKeys.Logo)}
                    </Typography>
                  </DialogContentText>
                </DialogContent>
                <Typography variant="body1" style={{ padding: 24 }}>
                  {translate(LabelKeys.ConfirmDeleteResellerLogotype)}
                </Typography>
                <DialogActions>
                  <Button
                    onClick={() => handleDeleteResellerLogotype()}
                    style={{
                      height: 56,
                      width: isMobile ? "100%" : "80%",
                      cursor: "pointer",
                      color: "#fff",
                      display: "flex",
                      marginTop: 20,
                      borderRadius: 0,
                      backgroundColor: "#000",
                      textTransform: "none",
                    }}
                  >
                    {translate(LabelKeys.Yes)}
                  </Button>
                  <Button
                    onClick={() => setConfirmDeleteResellerLogoDialogOpen(false)}
                    autoFocus
                    style={{
                      height: 56,
                      width: isMobile ? "100%" : "80%",
                      cursor: "pointer",
                      color: "#fff",
                      display: "flex",
                      marginTop: 20,
                      borderRadius: 0,
                      backgroundColor: "#000",
                      textTransform: "none",
                    }}
                  >
                    {translate(LabelKeys.Cancel)}
                  </Button>
                </DialogActions>
              </Dialog>
              <Dialog
                id="ResellerHomePageImageDialog"
                open={confirmDeleteResellerHomePageImageDialogOpen}
                disableEnforceFocus
                PaperProps={{ style: { borderRadius: 0 } }}
              >
                <DialogContent style={{ borderRadius: 0 }}>
                  <DialogContentText component={"div"}>
                    <Typography variant="h2" align="left">
                      {translate(LabelKeys.ResellerHomePageImage)}
                    </Typography>
                  </DialogContentText>
                </DialogContent>
                <Typography variant="body1" style={{ padding: 24 }}>
                  {translate(LabelKeys.ConfirmDeleteResellerHomePageImage)}
                </Typography>
                <DialogActions>
                  <Button
                    onClick={() => handleDeleteResellerHomePageImage()}
                    style={{
                      height: 56,
                      width: isMobile ? "100%" : "80%",
                      cursor: "pointer",
                      color: "#fff",
                      display: "flex",
                      textTransform: "none",
                      marginTop: 20,
                      borderRadius: 0,
                      backgroundColor: "#000",
                    }}
                  >
                    {translate(LabelKeys.Yes)}
                  </Button>
                  <Button
                    onClick={() => setConfirmDeleteResellerHomePageImageDialogOpen(false)}
                    autoFocus
                    style={{
                      height: 56,
                      width: isMobile ? "100%" : "80%",
                      cursor: "pointer",
                      color: "#fff",
                      display: "flex",
                      textTransform: "none",
                      marginTop: 20,
                      borderRadius: 0,
                      backgroundColor: "#000",
                    }}
                  >
                    {translate(LabelKeys.Cancel)}
                  </Button>
                </DialogActions>
              </Dialog>
            </TabContext>
          </React.Fragment>
        </Grid>
      </Grid>
    </div>
  );
}
