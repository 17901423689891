import React from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import clsx from "clsx";
import { AboutSummaryStyles } from "./styles";
import { LabelKeys } from "../../Utils/enums/labelKeys";
import { useTranslation } from "react-i18next";

interface IAboutSummaryProps {
  id: string;
  className?: string;
  title: LabelKeys;
  bodyText: LabelKeys;
}

function AboutSummary(props: IAboutSummaryProps) {
  const classes = AboutSummaryStyles();
  const { t: translate } = useTranslation();

  return (
    <Grid container className={clsx(props.className, classes.root)} justifyContent="center">
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className={classes.titleText}>
        <Typography style={{ fontSize: 42, fontFamily: "Butler", fontWeight: "bold" }}>{translate(props.title)}</Typography>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className={classes.bodyText}>
        <span>
          <Typography variant="body1" style={{ whiteSpace: "pre-line" }}>
            {translate(props.bodyText)}
          </Typography>
        </span>
      </Grid>
    </Grid>
  );
}

export default AboutSummary;
