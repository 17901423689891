import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { IAdminState, AdminSubview } from "./types";
import { LabelKeys } from "../../Utils/enums/labelKeys";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store";
import { IsAuth, SignOut } from "../../Utils/authentication";
import Cookies from "js-cookie";
import { CookieConstants } from "../../Utils/Labels/types";
import jwt_decode from "jwt-decode";
import { AuthenticationActions } from "../../Modules/Authentication/actions";
import { ISetSignedInUserEmailRequest } from "../../Modules/Authentication/types";
import { GetCurrentLanguageAsText } from "../../Utils/types";

function Admin() {
  const dispatch = useDispatch();
  const { t: translate } = useTranslation();
  const navigate = useNavigate();
  const [adminState, setAdminState] = useState({
    subview: AdminSubview.Order,
    isExpanded: false,
  } as IAdminState);

  const reducerState = useSelector(
    (s: RootState) => {
      return {
        authentication: s.authentication,
      };
    },
    (prev, next) =>
      prev.authentication.hasBeenSignedOut === next.authentication.hasBeenSignedOut &&
      prev.authentication.isLoading === next.authentication.isLoading
  );

  useEffect(() => {
    const jwt = Cookies.get(CookieConstants.GBVJwt);
    if (jwt == undefined) return;

    const decodedJwt = jwt_decode(jwt) as any;
    if (decodedJwt == undefined) return;
    if (decodedJwt?.Email?.length > 0) {
      dispatch(AuthenticationActions.SetSignedInUserEmail({ email: decodedJwt?.Email } as ISetSignedInUserEmailRequest));
    } else {
      return SignOut();
    }
    navigate(`/${GetCurrentLanguageAsText()}/${translate(LabelKeys.AdminUrl)}/${translate(LabelKeys.OrderSubviewUrl)}`);
  }, []);

  useEffect(() => {
    if (!IsAuth()) {
      navigate(`/${GetCurrentLanguageAsText()}/${translate(LabelKeys.SignInUrl)}`);
    }
  }, [reducerState.authentication.hasBeenSignedOut]);

  return <div></div>;
}

export default Admin;
