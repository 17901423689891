import { HeadCell } from "../../../../components/Table/types";
import { LabelKeys } from "../../../../Utils/enums/labelKeys";

export const externalSystemHeaders: HeadCell[] = [
  {
    key: "id",
    labelKey: LabelKeys.Id,
    type: "number",
    width: "5%",
    numeric: true,
  },
  {
    key: "name",
    labelKey: LabelKeys.Name,
    type: "text",
    width: "20%",
    numeric: false,
  },
  {
    key: "token",
    labelKey: LabelKeys.Token,
    type: "text",
    width: "35%",
    numeric: false,
  },
  {
    key: "isActive",
    labelKey: LabelKeys.IsActive,
    type: "boolean",
    width: "20%",
    numeric: false,
  },
  {
    key: "createdDate",
    labelKey: LabelKeys.CreatedDate,
    type: "date",
    width: "20%",
    numeric: false,
  },
];

export interface IExternalSystem {
  id: number;
  name: string;
  token: string;
  isActive: boolean;
  createdDate: Date;
}
