import React, { useEffect } from "react";
import { Grid } from "@mui/material";
import { PDFPreviewStyles } from "./styles";
import { LabelKeys } from "../../Utils/enums/labelKeys";
import { useTranslation } from "react-i18next";
import { ProductCategory } from "../../Modules/Products/types";
import clsx from "clsx";
import { GetLitiumImageUrl } from "../../Utils/extensions";
import { ImageKeys } from "../../Utils/enums/imageKeys";
import { QueryParamKeys } from "../../Utils/enums/queryParams";

export interface IMailPDFPreviewProps {
  bodyText: string;
  imageUrl?: string;
  className?: string;
  productCategory: ProductCategory;
}

export default function MailPDFPreview(props: IMailPDFPreviewProps) {
  const classes = PDFPreviewStyles();
  useEffect(() => {}, [props?.bodyText]);
  const { t: translate } = useTranslation();
  const generateHTMLTemplate = () => {
    return {
      __html: props.bodyText,
    };
  };

  return (
    <Grid
      id="mailPDFPreview"
      container
      item
      className={clsx(classes.previewContainer, props.className)}
      xs={12}
      sm={12}
      md={12}
      lg={12}
      xl={12}
      style={{ width: 595, zoom: 0.85 }}
    >
      <Grid id="mailPDFBodyImage" item xs={12} sm={12} md={12} lg={12} xl={12}>
        <img
          className={classes.mailPDFBodyImage}
          width={"100%"}
          src={props.imageUrl && props.imageUrl.includes("base64") ? props.imageUrl : `${props.imageUrl + QueryParamKeys.MaxWidth + 750}`}
        />
      </Grid>
      <Grid
        id="mailPDFUpperBodyTextContainer"
        item
        xs={12}
        sm={12}
        md={12}
        lg={12}
        xl={12}
        className={classes.mailPDFUpperBodyTextContainer}
      >
        <div id="mailPDFBodyText" className={classes.mailPDFUpperBodyText} dangerouslySetInnerHTML={generateHTMLTemplate()} />
      </Grid>
      <Grid
        id="mailPDFLowerBodyTextContainer"
        item
        xs={12}
        sm={12}
        md={12}
        lg={12}
        xl={12}
        className={classes.mailPDFLowerBodyTextContainer}
      >
        <div id="mailPDFBodyText" className={classes.mailPDFLowerBodyText}>
          {props.productCategory === ProductCategory.GiftCardVH
            ? translate(LabelKeys.VhPdfFooterText)
            : translate(LabelKeys.GmgPdfFooterText)}
        </div>
      </Grid>
      <Grid id="mailPDFCode" item xs={12} sm={12} md={12} lg={12} xl={12}>
        <div id="mailPDFGiftCardCodeContainer" className={classes.mailPDFGiftCardCodeContainer}>
          <div className={classes.mailPDFGiftCardCodeInner} style={{ display: "flex" }}>
            <div style={{ paddingLeft: 15 }}>{`${translate(LabelKeys.GiftCardCode)}: xxxxx-xxxxx-xxxxx`}</div>
            <div style={{ paddingLeft: 15 }}>{`${translate(LabelKeys.ValidThrough)}: xxxx-xx-xx`}</div>
          </div>
        </div>
      </Grid>
      <Grid id="mailPDFQRCodeContainer" item xs={12} sm={12} md={12} lg={12} xl={12} className={classes.mailPDFQRCodeContainer}>
        <img
          width={"50%"}
          src={
            props.productCategory === ProductCategory.GiftCardVH
              ? GetLitiumImageUrl(ImageKeys.PdfVhInfo, "")
              : GetLitiumImageUrl(ImageKeys.PdfGmgInfo, "")
          }
        />
      </Grid>
      <Grid id="link" item xs={12} sm={12} md={12} lg={12} xl={12} className={classes.link}>
        <a style={{ textDecoration: "none", color: "#000" }} target="_blank" href="https://vingahome.com/los-in" rel="noreferrer">
          {"VINGAHOME.COM"}
        </a>
      </Grid>
    </Grid>
  );
}
