import makeStyles from "@mui/styles/makeStyles";
import { theme } from "../../appTheme";

//Used in each headpage; private, company and reseller for the summary
export const AboutSummaryStyles = makeStyles(() => ({
  root: { minHeight: 200, display: "flex", alignContent: "flex-start" },
  titleText: { paddingTop: 30 },
  bodyText: { paddingTop: 20 },
  readMoreBtnContainer: {
    paddingTop: 20,
    paddingBottom: 20,
    display: "flex",
    alignContent: "center",
  },
}));

//Used in "How it works" sections
export const HIWInformationStyles = makeStyles(() => ({
  root: {},
  container: {},
  leftSideContent: {
    minHeight: 350,
    [theme.breakpoints.only("xs")]: {
      minHeight: 200,
    },
    [theme.breakpoints.only("sm")]: {
      minHeight: 80,
    },
  },
  rightSideContent: {
    minHeight: 350,
    [theme.breakpoints.only("xs")]: {
      minHeight: 120,
    },
    [theme.breakpoints.only("sm")]: {
      minHeight: 80,
    },
  },
}));

export const InformationStyles = makeStyles(() => ({
  root: {},
  container: {},
  leftSideContent: {
    minHeight: 350,
    [theme.breakpoints.only("xs")]: {
      minHeight: 200,
    },
    [theme.breakpoints.only("sm")]: {
      minHeight: 80,
    },
  },
  rightSideContent: {
    minHeight: 350,
    [theme.breakpoints.only("xs")]: {
      minHeight: 120,
    },
    [theme.breakpoints.only("sm")]: {
      minHeight: 80,
    },
  },
}));
