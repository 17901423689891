import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid/Grid";
import CSTextField from "../../components/Input/Textfield/CSTextField";
import { LabelKeys } from "../../Utils/enums/labelKeys";
import { useTranslation } from "react-i18next";
import { ResellerSignInStyles } from "./styles";
import { AuthenticationActions } from "../../Modules/Authentication/actions";
import { ISignInResellerRequest } from "../../Modules/Authentication/types";
import SnackbarActions from "../../components/Snackbar/actions";
import { ValidateEmail, ValidateExactStringLength } from "../../Utils/validations";
import { FieldNames, GetCurrentLanguageAsText } from "../../Utils/types";
import ProcessCircle from "../../components/ProcessCircle/ProcessCircle";
import { RootState } from "../../store";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Button, Typography } from "@mui/material";
import { IsResellerAuth } from "../../Utils/authentication";
import { ResellerActions } from "../../Modules/Reseller/actions";
import { IValidateResellerEmailRequest } from "../../Modules/Reseller/types";

interface IResellerSigninState {
  contactEmail: string;
  validationCode: string;
}

interface IResellerSigninErrorState {
  contactEmail: boolean;
  validationCode: boolean;
}

export default function ResellerSignIn() {
  const { t: translate } = useTranslation();
  const classes = ResellerSignInStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [resellerSignInState, setResellerSingInState] = useState({
    contactEmail: "",
    validationCode: "",
  } as IResellerSigninState);

  const [resellerSignInErrorState, setResellerSignInErrorState] = useState({
    contactEmail: false,
    validationCode: false,
  } as IResellerSigninErrorState);

  const reducerState = useSelector(
    (s: RootState) => {
      return {
        authentication: s.authentication,
      };
    },
    (prev, next) =>
      prev.authentication.isLoading === next.authentication.isLoading &&
      prev.authentication.isResellerSignedIn === next.authentication.isResellerSignedIn
  );

  useEffect(() => {
    if (IsResellerAuth()) {
      navigate(`/${GetCurrentLanguageAsText()}/${translate(LabelKeys.ResellerUrl)}/${translate(LabelKeys.AdminUrl)}`);
    }
  }, []);

  useEffect(() => {
    if (reducerState.authentication.isResellerSignedIn === true) {
      navigate(`/${GetCurrentLanguageAsText()}/${translate(LabelKeys.ResellerUrl)}/${translate(LabelKeys.AdminUrl)}`);
    }
  }, [reducerState.authentication.isResellerSignedIn]);

  function handleFieldOnChange(fieldName: FieldNames, value: string) {
    setResellerSingInState((state) => ({
      ...state,
      [fieldName]: value,
    }));
  }

  function handleSetErrorState(fieldName: FieldNames, value: boolean) {
    setResellerSignInErrorState((state) => ({
      ...state,
      [fieldName]: value,
    }));
  }

  function onSignInClicked(): void {
    if (!ValidateAllFields()) {
      dispatch(SnackbarActions.ShowError(translate(LabelKeys.CheckMandatoryField)));
      return;
    }
    dispatch(
      AuthenticationActions.SignInResellerRequest({
        email: resellerSignInState.contactEmail,
        validationCode: resellerSignInState.validationCode,
      } as ISignInResellerRequest)
    );
  }

  function ValidateAllFields(): boolean {
    let isValid: boolean = true;
    if (!ValidateEmail(resellerSignInState.contactEmail)) {
      isValid = false;
      handleSetErrorState(FieldNames.contactEmail, true);
    } else {
      handleSetErrorState(FieldNames.contactEmail, false);
    }

    if (!ValidateExactStringLength(resellerSignInState.validationCode, 20)) {
      isValid = false;
      handleSetErrorState(FieldNames.validationCode, true);
    } else {
      handleSetErrorState(FieldNames.validationCode, false);
    }

    return isValid;
  }

  //Used to get validation code
  function handleValidateResellerEmail() {
    if (ValidateEmail(resellerSignInState.contactEmail)) {
      dispatch(
        ResellerActions.ValidateResellerEmailRequest({
          email: resellerSignInState.contactEmail,
        } as IValidateResellerEmailRequest)
      );
      handleSetErrorState(FieldNames.contactEmail, false);
    } else {
      dispatch(SnackbarActions.ShowError(translate(LabelKeys.InvalidEmailFormat)));
      handleSetErrorState(FieldNames.contactEmail, true);
    }
  }

  return (
    <Grid container justifyContent="center" style={{ margin: "auto" }}>
      {reducerState.authentication?.isLoading && <ProcessCircle />}
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className={classes.titleContainer}>
        <Typography variant="h1" className={classes.title}>
          {translate(LabelKeys.Welcome)}
        </Typography>
      </Grid>
      <Grid container item xs={12} sm={12} md={5} lg={3} xl={3} justifyContent="center" style={{ margin: "auto" }}>
        <Grid id="contactEmail" item xs={10} sm={10} md={10} lg={12} xl={12} className={classes.contactEmail}>
          <CSTextField
            id="contactEmail"
            placeholder={translate(LabelKeys.ContactEmail)}
            type="email"
            onChange={(e) => {
              handleFieldOnChange(FieldNames.contactEmail, e.target.value);
            }}
            error={resellerSignInErrorState.contactEmail}
            helperText={translate(LabelKeys.InvalidEmailFormat)}
          />
        </Grid>
        <Grid id="validationCode" item xs={10} sm={10} md={10} lg={12} xl={12} className={classes.validationCode}>
          <CSTextField
            placeholder={translate(LabelKeys.ValidationCode)}
            type="text"
            onChange={(e) => {
              handleFieldOnChange(FieldNames.validationCode, e.target.value);
            }}
            error={resellerSignInErrorState.validationCode}
            helperText={translate(LabelKeys.InvalidValidationCode)}
          />
        </Grid>
        <Grid item xs={10} sm={10} md={10} lg={12} xl={12}>
          <Typography variant="body2">
            <a
              style={{
                float: "left",
                cursor: "pointer",
                paddingTop: 10,
              }}
              className={classes.resendValidationCode}
              id="validateResellerEmail"
              onClick={() => handleValidateResellerEmail()}
            >
              {translate(LabelKeys.ResendValidationCode)}
            </a>
          </Typography>
        </Grid>
        <Grid id="submitResellerSignin" item xs={10} sm={10} md={10} lg={12} xl={12} className={classes.submitResellerSignin}>
          <Button
            id="submitResellerSignin"
            style={{
              height: 60,
              width: "100%",
              cursor: "pointer",
              color: "#fff",
              display: "flex",
              marginTop: 20,
              borderRadius: 0,
              backgroundColor: "#000",
            }}
            onClick={() => onSignInClicked()}
          >
            <div style={{ margin: "auto", textTransform: "none" }}>{`${translate(LabelKeys.SignIn)}`}</div>
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
}
