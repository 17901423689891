import React from "react";
import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import { LabelKeys } from "../../../Utils/enums/labelKeys";
import { FieldNames, StateNames } from "../../../Utils/types";
import CSTextField from "../../Input/Textfield/CSTextField";
import { DeliveryStyles } from "../styles";
import { Country, SystemPart } from "../../../Utils/enums/enums";
import CSDropDown from "../../DropDown/CSDropDown";
import { IFilterOption } from "../../DropDown/types";

interface ISendByPhysicalCardProps {
  systemPart: SystemPart;
  amountOfMailings: number;
  addressLine?: string;
  postalCode?: string;
  city?: string;
  name?: string;
  country?: number;
  deliveryCustomerContactName?: string;
  amountOfMailingsHasError: boolean;
  nameHasError: boolean;
  addressLineHasError: boolean;
  postalCodeHasError: boolean;
  cityHasError: boolean;
  countryHasError: boolean;
  onStateChanged: (
    stateName: StateNames.stepperFiveState,
    value: string | string[] | Date | number | boolean,
    fieldName: FieldNames
  ) => void;
}

export default function SendByPhysicalCard(props: ISendByPhysicalCardProps) {
  const { t: translate } = useTranslation();
  const classes = DeliveryStyles();

  const countryOptions: IFilterOption[] = [{ id: 0, name: translate(LabelKeys.Sweden), value: Country.SE.toString() }];

  function GetCountryValue() {
    return countryOptions?.find((x) => Number(x.value) === props.country)?.name;
  }

  return (
    <Grid id="sendByPhysicalCardComponent" container item xs={12} sm={11} md={11} lg={11} xl={11}>
      <Grid id="amountOfMailingsContainer" item xs={12} sm={12} md={12} lg={12} xl={12}>
        <CSTextField
          id="amountOfMailings"
          type="text"
          required
          label={`${translate(LabelKeys.AmountOfCards)}`}
          placeholder={`${translate(LabelKeys.AmountOfMailings)}`}
          value={props.amountOfMailings ?? ""}
          onChange={(e: any) => {
            let value = e.target.value;
            value = value.replaceAll(" ", "");
            if (isNaN(value)) {
              props.onStateChanged(StateNames.stepperFiveState, props.amountOfMailings, FieldNames.amountOfMailings);
              return;
            }
            if (value <= 0 || value?.length <= 0 || value === undefined) {
              props.onStateChanged(StateNames.stepperFiveState, "", FieldNames.amountOfMailings);
              return;
            } else {
              props.onStateChanged(StateNames.stepperFiveState, value, FieldNames.amountOfMailings);
              return;
            }
          }}
          error={props.amountOfMailingsHasError}
          helperText={translate(LabelKeys.InvalidAmountOfMailings)}
        />
      </Grid>
      <Grid container item xs={12} sm={12} md={12} lg={12} xl={12} style={{ paddingTop: 30 }}>
        <Grid id="addressContainer" container item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Grid id="addressLineContainer" item xs={12} sm={12} md={12} lg={12} xl={12} className={classes.textfield}>
            <CSTextField
              id="name"
              type="text"
              className={classes.textfield}
              label={translate(LabelKeys.Name)}
              required
              value={props.name}
              placeholder={`${translate(LabelKeys.Name)}`}
              onChange={(e) => props.onStateChanged(StateNames.stepperFiveState, e.target.value, FieldNames.name)}
              error={props.nameHasError}
              helperText={translate(LabelKeys.InvalidName)}
            />
          </Grid>
          <Grid id="addressLineContainer" item xs={12} sm={12} md={12} lg={12} xl={12} className={classes.textfield}>
            <CSTextField
              id="addressLine"
              type="text"
              className={classes.textfield}
              label={translate(LabelKeys.AddressLine)}
              required
              value={props.addressLine}
              placeholder={`${translate(LabelKeys.AddressLine)}`}
              onChange={(e) => props.onStateChanged(StateNames.stepperFiveState, e.target.value, FieldNames.addressLine)}
              error={props.addressLineHasError}
              helperText={translate(LabelKeys.InvalidAddressLine)}
            />
          </Grid>
          <Grid id="postalCodeContainer" item xs={12} sm={12} md={12} lg={12} xl={12} className={classes.textfield}>
            <CSTextField
              id="postalCode"
              type="text"
              maxLength={5}
              label={translate(LabelKeys.PostalCode)}
              required
              value={props.postalCode}
              placeholder={`${translate(LabelKeys.PostalCode)}`}
              onChange={(e) => {
                let value = e.target.value;
                props.onStateChanged(StateNames.stepperFiveState, value, FieldNames.postalCode);
              }}
              error={props.postalCodeHasError}
              helperText={translate(LabelKeys.InvalidPostalCode)}
            />
          </Grid>
          <Grid id="cityContainer" item xs={12} sm={12} md={12} lg={12} xl={12} className={classes.textfield}>
            <CSTextField
              id="city"
              type="text"
              label={translate(LabelKeys.City)}
              required
              className={classes.textfield}
              value={props.city}
              placeholder={`${translate(LabelKeys.City)}`}
              onChange={(e) => props.onStateChanged(StateNames.stepperFiveState, e.target.value, FieldNames.city)}
              error={props.cityHasError}
              helperText={translate(LabelKeys.InvalidCity)}
            />
          </Grid>
          <Grid id="countryContainer" item xs={12} sm={12} md={12} lg={12} xl={12} className={classes.textfield}>
            <CSDropDown
              id="country"
              filterOptions={countryOptions}
              label={translate(LabelKeys.Country)}
              required
              value={GetCountryValue()}
              placeholder={`${translate(LabelKeys.Country)}`}
              onStateChanged={(e: IFilterOption) => props.onStateChanged(StateNames.stepperFiveState, Number(e.value), FieldNames.country)}
            />
          </Grid>
          <Grid id="deliveryCustomerContactNameContainer" item xs={12} sm={12} md={12} lg={12} xl={12} className={classes.textfield}>
            <CSTextField
              id="deliveryCustomerContactName"
              type="text"
              label={translate(LabelKeys.DeliveryCustomerContactName)}
              className={classes.textfield}
              value={props.deliveryCustomerContactName}
              maxLength={20}
              placeholder={`${translate(LabelKeys.DeliveryCustomerContactName)}`}
              onChange={(e) => props.onStateChanged(StateNames.stepperFiveState, e.target.value, FieldNames.deliveryCustomerContactName)}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
