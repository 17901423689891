export enum AuthenticationActionTypes {
  //Email verification
  VALIDATE_SIGN_IN_EMAIL_REQUEST = "VALIDATE_SIGN_IN_EMAIL_REQUEST",
  VALIDATE_SIGN_IN_EMAIL_SUCCESS = "VALIDATE_SIGN_IN_EMAIL_SUCCESS",
  VALIDATE_SIGN_IN_EMAIL_ERROR = "VALIDATE_SIGN_IN_EMAIL_ERROR",

  //Get external systems
  GET_EXTERNAL_SYSTEMS_REQUEST = "GET_EXTERNAL_SYSTEMS_REQUEST",
  GET_EXTERNAL_SYSTEMS_SUCCESS = "GET_EXTERNAL_SYSTEMS_SUCCESS",
  GET_EXTERNAL_SYSTEMS_ERROR = "GET_EXTERNAL_SYSTEMS_ERROR",

  //Get external system details
  GET_EXTERNAL_SYSTEM_DETAILS_REQUEST = "GET_EXTERNAL_SYSTEM_DETAILS_REQUEST",
  GET_EXTERNAL_SYSTEM_DETAILS_SUCCESS = "GET_EXTERNAL_SYSTEM_DETAILS_SUCCESS",
  GET_EXTERNAL_SYSTEM_DETAILS_ERROR = "GET_EXTERNAL_SYSTEM_DETAILS_ERROR",

  //Get external systems
  ADD_EXTERNAL_SYSTEM_REQUEST = "ADD_EXTERNAL_SYSTEM_REQUEST",
  ADD_EXTERNAL_SYSTEM_SUCCESS = "ADD_EXTERNAL_SYSTEM_SUCCESS",
  ADD_EXTERNAL_SYSTEM_ERROR = "ADD_EXTERNAL_SYSTEM_ERROR",

  //Get external systems
  UPDATE_EXTERNAL_SYSTEM_REQUEST = "UPDATE_EXTERNAL_SYSTEM_REQUEST",
  UPDATE_EXTERNAL_SYSTEM_SUCCESS = "UPDATE_EXTERNAL_SYSTEM_SUCCESS",
  UPDATE_EXTERNAL_SYSTEM_ERROR = "UPDATE_EXTERNAL_SYSTEM_ERROR",

  //Validation code
  SIGN_IN_REQUEST = "SIGN_IN_REQUEST",
  SIGN_IN_SUCCESS = "SIGN_IN_SUCCESS",
  SIGN_IN_ERROR = "SIGN_IN_ERROR",

  //Sign out
  SIGN_OUT_REQUEST = "SIGN_OUT_REQUEST",
  SIGN_OUT_SUCCESS = "SIGN_OUT_SUCCESS",
  SIGN_OUT_ERROR = "SIGN_OUT_ERROR",

  //Sign out reseller
  SIGN_OUT_RESELLER_REQUEST = "SIGN_OUT_RESELLER_REQUEST",

  //Sign in reseller
  SIGN_IN_RESELLER_REQUEST = "SIGN_IN_RESELLER_REQUEST",
  SIGN_IN_RESELLER_SUCCESS = "SIGN_IN_RESELLER_SUCCESS",
  SIGN_IN_RESELLER_ERROR = "SIGN_IN_RESELLER_ERROR",

  // Set system part dialog open
  SET_IS_SYSTEMPART_DIALOG_OPEN_REQUEST = "SET_IS_SYSTEMPART_DIALOG_OPEN_REQUEST",

  // Set signed in user email
  SET_SIGNED_IN_USER_EMAIL_REQUEST = "SET_SIGNED_IN_USER_EMAIL_REQUEST",

  //Reset state
  RESET_REDUCER = "RESET_REDUCER",
}
