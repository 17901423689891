import { ActionTypes } from './types';
import { Reducer } from 'redux';
import { AlertColor } from '@mui/material';

interface ISnackBarState {
  isOpen: boolean;
  message: string;
  type: ActionTypes;
  severity: AlertColor;
}

const initState: ISnackBarState = {
  isOpen: false,
  message: '',
  type: undefined,
  severity: undefined,
};

const SnackbarReducer: Reducer<ISnackBarState> = (
  state = initState,
  action
) => {
  switch (action.type) {
    case ActionTypes.SHOW_SNACKBAR:
      return {
        ...state,
        isOpen: true,
        message: action.message,
        severity: action.severity,
      };
    case ActionTypes.HIDE_SNACKBAR:
      return {
        ...state,
        isOpen: false,
      };
    default:
      return state;
  }
};

export default SnackbarReducer;
