import React from "react";
import Button, { ButtonProps } from "@mui/material/Button/Button";
import { GetButtonStyling } from "../../Utils/extensions";

export interface ICSButton extends ButtonProps {
  id: string;
  label: string;
  height: number;
  width?: number | "auto";
  fullWidth?: boolean;
}

export default function CSButton(props: ICSButton) {
  return (
    <Button
      id={props.id}
      style={GetButtonStyling(props.height, props.width)}
      {...props}
      size="small"
      type="submit"
      variant="contained"
      fullWidth={props.fullWidth}
    >
      {props.label}
    </Button>
  );
}
