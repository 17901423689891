import { ICountryOption } from "./../../components/DropDown/types";
import { IImageOption, IProduct, IProductCategory } from "./../../Modules/Products/types";
import { SystemPart, PaymentMethod } from "./../../Utils/enums/enums";
import { IDeliveryState, SendBy, WhenToSend } from "../../components/Delivery/types";
import { IMessageState } from "../../components/Message/types";
import { IFilterOption } from "../../components/DropDown/types";
import { IUploadedFile } from "../../components/FileUploader/types";
import { IErrorStep } from "../../components/Stepper/CSStepper";
import { ICustomProduct } from "../../components/Stepper/types";

export interface IPrivateState {
  messageState: IMessageState;
  deliveryState: IDeliveryState;
  termsOfUseState: ITermsOfUseState;
}

export interface IStepperState {
  activeStep: number;
  readyToPay: boolean;
  errorSections: IErrorStep[];
}

export interface IStepperOneState {
  selectedProductCategory: IProductCategory;
  adminSelectedResellerCountryOption: ICountryOption;
}

export interface IStepperTwoState {
  selectedProduct: IProduct;
}

export interface IStepperThreeState {
  emailSubject?: string;
  smsMessage: string;
  emailMessage: string;
  emailPDFMessage: string;
  messageSignature: string;
  customMessage: boolean;
  acceptedPDFPreview: boolean;
  customWebPage: boolean;
  selectedImage?: IImageOption;
  uploadedImage?: IUploadedFile;
  openPDF?: boolean;
  sendBy: SendBy;
  selectedMessageTemplate?: IFilterOption;
  messageTemplates?: IFilterOption[];
  physicalCardSelectedCoverImage?: IImageOption;
  physicalCardSelectedUploadedCoverImage?: IUploadedFile;
  physicalCardSelectedUploadedLogotype?: IUploadedFile;
  physicalCardHeading?: string;
  physicalCardCustomMessage?: string;
  isPhysicalCardEditorPopupOpen?: boolean;
}

export interface IStepperFourState {
  companyName: string;
  companyContactPhoneNumber: string;
  organisationNumber: string;
  contactResellerName: string;
  contactEmail: string;
  senderEmail: string;
  isAutomaticlySet: boolean;
  marking: string;
}

export interface IStepperFiveState {
  orderDate: Date;
  includeFreight: boolean;
  whenToSend: WhenToSend;
  receiverEmails: string;
  receiverPhoneNumbers: string;
  hasMultipleEntries?: boolean;
  amountOfMailings: number;
  amountOfReceivers: string;
  sendToSameReceiver: boolean;
  name?: string;
  addressLine?: string;
  city?: string;
  postalCode?: string;
  country?: number;
  deliveryCustomerContactName?: string;
}

export interface IStepperSixState {
  paymentMethod: PaymentMethod;
  acceptedTermsOfUse: boolean;
  acceptedCustomWebPage: boolean;
  customWebPageDialogOpen: boolean;
}

export interface IEnvironmentState {
  systemPart: SystemPart;
}

export interface IOrderState {
  environmentState: IEnvironmentState;
  stepperState: IStepperState;
  stepperOneState: IStepperOneState;
  stepperTwoState: IStepperTwoState;
  stepperThreeState: IStepperThreeState;
  stepperFourState: IStepperFourState;
  stepperFiveState: IStepperFiveState;
  stepperSixState: IStepperSixState;
}

export interface ITermsOfUseState {
  senderEmail: string;
  acceptedPDFPreview: boolean;
  acceptedTermsOfUse: boolean;
}

export interface IOrderErrorState {
  selectedProductCategory: boolean;
  selectedProduct: boolean;
  senderEmail: boolean;
  emailSubject: boolean;
  smsMessage: boolean;
  emailMessage: boolean;
  customMessage: boolean;
  receiverPhoneNumbers: boolean;
  receiverEmails: boolean;
  receiverEmailsPDF: boolean;
  receiverEmailsPDFSendToSameReceiver: boolean;
  orderDate: boolean;
  sendBy: boolean;
  companyName: boolean;
  companyContactPhoneNumber: boolean;
  physicalCardSelectedCoverImage: boolean;
  organisationNumber: boolean;
  amountOfMailings: boolean;
  addressLine: boolean;
  postalCode: boolean;
  city: boolean;
  country: boolean;
  name: boolean;
  marking: boolean;
  acceptedTermsOfUse: boolean;
  acceptedPDFPreview: boolean;
  contactEmail: boolean;
  contactReseller: boolean;
  stepOne: boolean;
  stepTwo: boolean;
  stepThree: boolean;
  stepFour: boolean;
  stepFive: boolean;
  stepSix: boolean;
  errorSections: IErrorStep[];
}

export interface IReceiverEmail {
  firstName: string;
  email: string;
}

export interface IReceiverPhoneNumber {
  firstName: string;
  phoneNumber: string;
}

export function MapProductToCustomProduct(product: IProduct): ICustomProduct {
  let customProduct: ICustomProduct = {} as ICustomProduct;

  customProduct.id = product.id;
  customProduct.category = product.category;
  customProduct.currency = product.currency;
  customProduct.description = product.description;
  customProduct.imageUrl = product.imageUrl;
  customProduct.isMostPopular = product.isMostPopular;
  customProduct.name = product.name;
  customProduct.priceExcludingVat = product.priceExcludingVat;
  customProduct.priceIncludingVat = product.priceIncludingVat;

  return customProduct;
}

export function GetOrderErrorStateInit() {
  return {
    selectedProductCategory: false,
    selectedProduct: false,
    senderEmail: false,
    emailSubject: false,
    smsMessage: false,
    emailMessage: false,
    customMessage: false,
    receiverPhoneNumbers: false,
    receiverEmails: false,
    receiverEmailsPDF: false,
    receiverEmailsPDFSendToSameReceiver: false,
    orderDate: false,
    sendBy: false,
    companyName: false,
    companyContactPhoneNumber: false,
    organisationNumber: false,
    amountOfMailings: false,
    addressLine: false,
    postalCode: false,
    city: false,
    country: false,
    name: false,
    marking: false,
    acceptedTermsOfUse: false,
    acceptedPDFPreview: false,
    physicalCardSelectedCoverImage: false,
    contactReseller: false,
    contactEmail: false,
    stepOne: false,
    stepTwo: false,
    stepThree: false,
    stepFour: false,
    stepFive: false,
    stepSix: false,
    errorSections: [],
  } as IOrderErrorState;
}

export enum OrderType {
  Unknown = 0,
  Private = 1,
  Company = 2,
  ResellerAdmin = 3,
  UserAdmin = 4,
}
