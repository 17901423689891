import React, { useEffect, useState } from "react";
import { Grid, Typography } from "@mui/material";
import { FAQList } from "./data";
import { useTranslation } from "react-i18next";
import { LabelKeys } from "../../Utils/enums/labelKeys";
import Linkify from "linkify-react";
import { CheckSystemPartCookie } from "../../Utils/authentication";

function FAQ() {
  const { t: translate } = useTranslation();
  const [faqList, setFaqList] = useState(FAQList);
  const options = {
    target: "_blank",
  };
  useEffect(() => {
    CheckSystemPartCookie();
    window.scrollTo(0, 0);
  }, []);

  function GetFormattedText(title: string, body: string): React.ReactNode {
    return (
      <div
        style={{
          textAlign: "left",
          paddingTop: 30,
          paddingRight: 10,
          whiteSpace: "pre-line",
        }}
      >
        <Linkify options={options}>
          <Typography style={{ fontWeight: 800 }}>{title}</Typography>
        </Linkify>
        <Linkify options={options}>
          <Typography style={{ paddingTop: 5 }}>{body}</Typography>
        </Linkify>
      </div>
    );
  }

  return (
    <Grid container justifyContent="center">
      <Grid item xs={12} sm={9} md={9} lg={9} xl={9} style={{ paddingTop: 30 }}>
        <Typography id="faqTitle" variant="h1" style={{ textAlign: "center" }}>
          {translate(LabelKeys.FAQPageTitle)}
        </Typography>
      </Grid>
      <Grid
        container
        item
        xs={10}
        sm={9}
        md={9}
        lg={9}
        xl={9}
        justifyContent="center"
        sx={{ paddingTop: { xs: 0, sm: 1, md: 1, lg: 1, xl: 1 } }}
      >
        {faqList.map((item) => (
          <Grid container item xs={12} sm={6} md={6} lg={6} xl={6} key={item.key}>
            {GetFormattedText(item.title, item.body)}
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
}

export default FAQ;
