import * as React from "react";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { ICountryOption } from "./types";
import { CSDropDownStyles } from "./styles";
import clsx from "clsx";
import { Typography } from "@mui/material";
import Flag from "react-world-flags";
import { GetLanguageAsText } from "../../Utils/types";
import { useState } from "react";

interface ICSDropDownProps {
  id?: string;
  options: ICountryOption[];
  className?: string;
  label?: string;
  value: ICountryOption;
  // defaultValue?: string;
  classNameRoot?: string;
  disabled?: boolean;
  classNameInner?: string;
  required?: boolean;
  onStateChanged: (value: ICountryOption) => void;
}

export default function CSDropDown(props: ICSDropDownProps) {
  const classes = CSDropDownStyles();
  // (props.options?.find((x) => x.name?.toLowerCase() == props.value?.toLowerCase()) as ICountryOption) ?? ({} as ICountryOption)
  return (
    <div className={props.classNameRoot}>
      <Typography variant="body2" style={{ textAlign: "left", paddingBottom: 14, fontWeight: "bold" }}>
        {props.label ? (props.required ? `${props.label}*` : `${props.label}`) : ""}
      </Typography>
      <FormControl
        id={props.id}
        disabled={props.disabled}
        className={clsx(classes.root, props.classNameInner, props.className)}
        sx={{ width: "100%" }}
      >
        <Select
          style={{ borderRadius: 0, backgroundColor: "#fff", textAlign: "left", height: 46 }}
          value={props.value?.value ?? ""}
          MenuProps={{
            sx: { height: { xs: 250, sm: 200, md: 300, lg: 300, xl: 500 } },
          }}
        >
          {props.options?.map((opt) => (
            <MenuItem key={opt.value} value={opt?.value} onClick={() => props.onStateChanged(opt)}>
              <div style={{ display: "flex" }}>
                <div>
                  <Flag code={GetLanguageAsText(opt?.value)} height={20} style={{ marginRight: 10, marginTop: 5 }} />
                </div>
                <div style={{ marginTop: 3 }}>{opt?.name}</div>
              </div>
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}
