import { ActionTypes, Snackbar_Success, Hide_Snackbar, Snackbar_Danger as Snackbar_Error, Snackbar_Warning, Snackbar_Info } from "./types";

const SnackbarActions = {
  ShowSuccess: (message: string): Snackbar_Success => ({
    type: ActionTypes.SHOW_SNACKBAR,
    message,
    severity: "success",
  }),
  ShowInfo: (message: string): Snackbar_Info => ({
    type: ActionTypes.SHOW_SNACKBAR,
    message,
    severity: "info",
  }),
  ShowWarning: (message: string): Snackbar_Warning => ({
    type: ActionTypes.SHOW_SNACKBAR,
    message,
    severity: "warning",
  }),
  ShowError: (message: string): Snackbar_Error => ({
    type: ActionTypes.SHOW_SNACKBAR,
    message,
    severity: "error",
  }),
  Hide: (): Hide_Snackbar => ({
    type: ActionTypes.HIDE_SNACKBAR,
  }),
};

export default SnackbarActions;
