import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import OrderDetails from "../OrderDetails";

interface IOrderDetailsPopupProps {
  onGoBackToOrderPageClicked?: (orderDetailsOpen: boolean) => void;
}

export default function OrderDetailsPopup(props: IOrderDetailsPopupProps) {
  return (
    <Dialog
      id={"1"}
      open={true}
      PaperProps={{ style: { borderRadius: 0, maxWidth: "100vw" } }}
      onKeyDown={(e) => {
        if (e.key === "Escape") {
          props.onGoBackToOrderPageClicked(false);
        }
      }}
    >
      <DialogContent style={{ padding: 0 }}>
        <OrderDetails onGoBackToOrderPageClicked={(x) => props.onGoBackToOrderPageClicked(x)} />
      </DialogContent>
    </Dialog>
  );
}
