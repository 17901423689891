import { Reducer } from 'redux';
import { CompanyActionTypes } from './actionTypes';
import { IGetCompanyDetailsResponse } from './types';

interface ICompanyInitState {
  companyDetails: IGetCompanyDetailsResponse;
}

const companyInitState: ICompanyInitState = {
  companyDetails: {} as IGetCompanyDetailsResponse,
};

const CompanyReducer: Reducer<ICompanyInitState> = (
  state = companyInitState,
  action
) => {
  switch (action.type) {
    case CompanyActionTypes.GET_COMPANY_DETAILS_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case CompanyActionTypes.GET_COMPANY_DETAILS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        companyDetails: action.response,
      };
    case CompanyActionTypes.GET_COMPANY_DETAILS_ERROR:
      return {
        ...state,
        isLoading: false,
      };
    case CompanyActionTypes.RESET_REDUCER:
      return (state = companyInitState);
    default:
      return state;
  }
};

export default CompanyReducer;
