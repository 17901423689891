import { Grid, Typography, useMediaQuery } from "@mui/material";
import React from "react";
import { theme } from "../../appTheme";
import { QueryParamKeys } from "../../Utils/enums/queryParams";
import { GetLitiumImageUrl } from "../../Utils/extensions";
import { GetSampleAssortmentArray } from "../Stepper/extensions";
import { ExampleAssortmentStyles } from "./styles";

interface IExampleAssortmentProps {
  id: string;
  productName: string;
}

const ExampleAssortment = (props: IExampleAssortmentProps): any => {
  const classes = ExampleAssortmentStyles();
  const isMobile = useMediaQuery(theme.breakpoints.only("xs"));
  const isSm = useMediaQuery(theme.breakpoints.only("sm"));
  const isMd = useMediaQuery(theme.breakpoints.only("md"));
  const isLg = useMediaQuery(theme.breakpoints.only("lg"));

  function GetSampleAssortment(): React.ReactNode {
    let samples = GetSampleAssortmentArray(props.productName);

    if (samples?.length > 0) {
      return samples.map((x, index) => (
        <Grid key={index} container item xs={12} sm={3} md={3} lg={3} xl={3}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <img
              style={{
                backgroundRepeat: "no-repeat",
                objectFit: "cover",
                height: GetSampleAssortmentImageHeight(),
                width: isMobile ? 280 : "100%",
                // cursor: "pointer",
              }}
              src={GetLitiumImageUrl(x.imageUrl, `${QueryParamKeys.MaxWidth}350`)}
              //onClick={() => window.open(x.redirectUrl, "_blank")}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Typography
              variant="body1"
              align="left"
              style={{ paddingTop: 10, cursor: "pointer" }}
              //onClick={() => window.open(x.redirectUrl, "_blank")}
            >
              {x.title}
            </Typography>
          </Grid>
        </Grid>
      ));
    } else {
      return null;
    }
  }

  function GetSampleAssortmentImageHeight(): number {
    if (isMobile) {
      return 280;
    } else if (isSm) {
      return 220;
    } else if (isMd) {
      return 220;
    } else if (isLg) {
      return 275;
    } else {
      return 350;
    }
  }

  return (
    <Grid
      container
      item
      xs={12}
      sm={12}
      md={12}
      lg={12}
      xl={12}
      spacing={1}
      style={{
        paddingTop: 40,
        display: "flex",
        flexWrap: isMobile ? "nowrap" : "inherit",
        overflowX: isMobile ? "scroll" : "inherit",
      }}
      className={classes.exampleAssortmentContainer}
    >
      {GetSampleAssortment()}
    </Grid>
  );
};

export default ExampleAssortment;
