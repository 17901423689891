import { ProductActionTypes } from "./actionTypes";
import { Reducer } from "redux";
import { IImageOption, IProduct, ITag, IMessageTemplate, IVoucher, IProductCategory, IPhysicalCardImage } from "./types";
import { Currency } from "../../Utils/enums/enums";

interface IProductInitState {
  isLoading: boolean;
  products: IProduct[];
  selectedProduct: IProduct;
  previewImageOptions: IImageOption[];
  previewPDFFile: Blob;
  messageTemplates: IMessageTemplate[];
  voucher: IVoucher;
  productCategories: IProductCategory[];
  selectedProductCategory: IProductCategory;
  physicalCardCoverImages: IPhysicalCardImage[];
  physicalCardWebsiteCoverImages: IPhysicalCardImage[];
  physicalCardMiddlePageImage: IPhysicalCardImage;
  physicalCardBackImage: IPhysicalCardImage;
  downloadedDocumentation: Blob;
}

const productInitState: IProductInitState = {
  isLoading: false,
  products: [] as IProduct[],
  selectedProduct: {} as IProduct,
  previewImageOptions: [] as IImageOption[],
  previewPDFFile: new Blob(),
  messageTemplates: [] as IMessageTemplate[],
  voucher: {} as IVoucher,
  productCategories: [] as IProductCategory[],
  selectedProductCategory: {} as IProductCategory,
  physicalCardCoverImages: [] as IPhysicalCardImage[],
  physicalCardWebsiteCoverImages: [] as IPhysicalCardImage[],
  physicalCardMiddlePageImage: {} as IPhysicalCardImage,
  physicalCardBackImage: {} as IPhysicalCardImage,
  downloadedDocumentation: new Blob(),
};

const ProductReducer: Reducer<IProductInitState> = (state = productInitState, action) => {
  switch (action.type) {
    //GET PRODUCT BY ID
    case ProductActionTypes.GET_PRODUCT_BY_ID_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case ProductActionTypes.GET_PRODUCT_BY_ID_SUCCESS:
      return {
        ...state,
        isLoading: false,
        selectedProduct: action.response,
      };
    case ProductActionTypes.GET_PRODUCT_BY_ID_ERROR:
      return {
        ...state,
        isLoading: false,
      };

    //GET PRODUCTS
    case ProductActionTypes.GET_PRODUCTS_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case ProductActionTypes.GET_PRODUCTS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        products: action.response.products,
      };
    case ProductActionTypes.GET_PRODUCTS_ERROR:
      return {
        ...state,
        isLoading: false,
      };
    //GET PREVIEW PDF
    case ProductActionTypes.GET_PREVIEW_PDF_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case ProductActionTypes.GET_PREVIEW_PDF_SUCCESS:
      return {
        ...state,
        isLoading: false,
        previewPDFFile: action?.response,
      };
    case ProductActionTypes.GET_PREVIEW_PDF_ERROR:
      return {
        ...state,
        isLoading: false,
      };
    //CREATE MESSAGE TEMPLATES
    case ProductActionTypes.CREATE_MESSAGE_TEMPLATES_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case ProductActionTypes.CREATE_MESSAGE_TEMPLATES_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case ProductActionTypes.CREATE_MESSAGE_TEMPLATES_ERROR:
      return {
        ...state,
        isLoading: false,
      };
    //GET MESSAGE TEMPLATES
    case ProductActionTypes.GET_MESSAGE_TEMPLATES_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case ProductActionTypes.GET_MESSAGE_TEMPLATES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        messageTemplates: action.response.messageTemplates,
      };
    case ProductActionTypes.GET_MESSAGE_TEMPLATES_ERROR:
      return {
        ...state,
        isLoading: false,
      };
    //UPDATE MESSAGE TEMPLATES
    case ProductActionTypes.UPDATE_MESSAGE_TEMPLATES_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case ProductActionTypes.UPDATE_MESSAGE_TEMPLATES_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case ProductActionTypes.UPDATE_MESSAGE_TEMPLATES_ERROR:
      return {
        ...state,
        isLoading: false,
      };
    //DELETE MESSAGE TEMPLATES
    case ProductActionTypes.DELETE_MESSAGE_TEMPLATES_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case ProductActionTypes.DELETE_MESSAGE_TEMPLATES_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case ProductActionTypes.DELETE_MESSAGE_TEMPLATES_ERROR:
      return {
        ...state,
        isLoading: false,
      };
    //GET VOUCHER
    case ProductActionTypes.GET_VOUCHER_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case ProductActionTypes.GET_VOUCHER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        voucher: action.response,
      };
    case ProductActionTypes.GET_VOUCHER_ERROR:
      return {
        ...state,
        isLoading: false,
      };
    //GET PRODUCT CATEGORIES
    case ProductActionTypes.GET_PRODUCT_CATEGORIES_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case ProductActionTypes.GET_PRODUCT_CATEGORIES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        productCategories: action.response.productCategories,
      };
    case ProductActionTypes.GET_PRODUCT_CATEGORIES_ERROR:
      return {
        ...state,
        isLoading: false,
      };
    case ProductActionTypes.GET_PHYSICAL_CARD_COVER_IMAGES_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case ProductActionTypes.GET_PHYSICAL_CARD_COVER_IMAGES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        physicalCardCoverImages: action.response.images,
      };
    case ProductActionTypes.GET_PHYSICAL_CARD_COVER_IMAGES_ERROR:
      return {
        ...state,
        isLoading: false,
      };
    case ProductActionTypes.GET_PHYSICAL_CARD_BACK_IMAGES_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case ProductActionTypes.GET_PHYSICAL_CARD_BACK_IMAGES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        physicalCardBackImage: action.response,
      };
    case ProductActionTypes.GET_PHYSICAL_CARD_BACK_IMAGES_ERROR:
      return {
        ...state,
        isLoading: false,
      };
    case ProductActionTypes.GET_PHYSICAL_CARD_MIDDLE_PAGE_IMAGES_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case ProductActionTypes.GET_PHYSICAL_CARD_MIDDLE_PAGE_IMAGES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        physicalCardMiddlePageImage: action.response,
      };
    case ProductActionTypes.GET_PHYSICAL_CARD_MIDDLE_PAGE_IMAGES_ERROR:
      return {
        ...state,
        isLoading: false,
      };
    case ProductActionTypes.GET_PHYSICAL_CARD_WEBSITE_COVER_IMAGES_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case ProductActionTypes.GET_PHYSICAL_CARD_WEBSITE_COVER_IMAGES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        physicalCardWebsiteCoverImages: action.response.images,
      };
    case ProductActionTypes.GET_PHYSICAL_CARD_WEBSITE_COVER_IMAGES_ERROR:
      return {
        ...state,
        isLoading: false,
      };
    case ProductActionTypes.SET_SELECTED_PRODUCT:
      return {
        ...state,
        selectedProduct: action.request.product,
      };

    case ProductActionTypes.SET_SELECTED_PRODUCT_CATEGORY:
      return {
        ...state,
        selectedProductCategory: action.request.productCategory,
      };
    case ProductActionTypes.RESET_REDUCER:
      return (state = productInitState);
    case ProductActionTypes.GET_IMAGE_SELECTOR_IMAGES_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case ProductActionTypes.GET_IMAGE_SELECTOR_IMAGES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        previewImageOptions: action.response.images,
      };
    case ProductActionTypes.GET_IMAGE_SELECTOR_IMAGES_ERROR:
      return {
        ...state,
        isLoading: false,
      };
    case ProductActionTypes.DOWNLOAD_DOCUMENTATION_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case ProductActionTypes.DOWNLOAD_DOCUMENTATION_SUCCESS:
      return {
        ...state,
        isLoading: false,
        downloadedDocumentation: action.response,
      };
    case ProductActionTypes.DOWNLOAD_DOCUMENTATION_ERROR:
      return {
        ...state,
        isLoading: false,
      };
    default:
      return state;
  }
};

export default ProductReducer;
