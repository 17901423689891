import React, { useEffect, useState } from "react";
import { Typography, Grid, useMediaQuery, Button } from "@mui/material/";
import { LabelKeys } from "../../Utils/enums/labelKeys";
import { MessageStyles } from "./styles";
import { SendBy, WhenToSend } from "../Delivery/types";
import { useTranslation } from "react-i18next";
import { IMessageState } from "./types";
import CSTextField from "../Input/Textfield/CSTextField";
import CSCheckbox from "../Input/Forms/CSCheckbox";
import { Currency, SystemPart } from "../../Utils/enums/enums";
import { RootState } from "../../store";
import { useDispatch, useSelector } from "react-redux";
import { FieldNames, StateNames } from "../../Utils/types";
import CSImageSelector from "../ImageSelector/CSImageSelector";
import { ProductActions } from "../../Modules/Products/actions";
import { IImageOption, IPhysicalCardImage, IProduct, ProductCategory } from "../../Modules/Products/types";
import { IFilterOption } from "../DropDown/types";
import { IUploadedFile } from "../FileUploader/types";
import { theme } from "../../appTheme";
import MessageTags from "./MessageTags";
import CSDropDown from "../DropDown/CSDropDown";
import CSInfo from "../Helper/CSInfo";
import { OrderActions } from "../../Modules/Orders/actions";
import MessagePreviewWrapper from "./MessagePreviewWrapper";
import MessageTextEditorWrapper from "./MessageTextEditorWrapper";
import MessageSendByWrapper from "./MessageSendByWrapper";
import { IsResellerAuth, IsAuth } from "../../Utils/authentication";
import PhysicalCardEditorPopup from "./Popup/PhysicalCardEditorPopup";

interface IMessageProps {
  id: string;
  selectedProduct: IProduct;
  systemPart: SystemPart;
  sendBy: SendBy;
  smsMessage: string;
  emailMessage: string;
  emailPDFMessage: string;
  selectedMessageTemplate: IFilterOption;
  messageTemplates: IFilterOption[];
  emailSubject: string;
  messageSignature: string;
  customMessage: boolean;
  orderDate: Date;
  customWebPage: boolean;
  acceptedPDFPreview: boolean;
  selectedImage: IImageOption;
  uploadedImage: IUploadedFile;
  physicalCardSelectedCoverImage: IImageOption;
  physicalCardMiddlePageImage: string;
  physicalCardSelectedUploadedCoverImage: IUploadedFile;
  physicalCardSelectedUploadedLogotype: IUploadedFile;
  physicalCardHeading: string;
  physicalCardCustomMessage: string;
  onStateChanged: (
    stateName: StateNames.stepperThreeState | StateNames.stepperFourState | StateNames.stepperFiveState,
    value: string | Date | boolean | number | IImageOption | IUploadedFile | SendBy | IMessageState | IFilterOption | WhenToSend,
    fieldName: FieldNames
  ) => void;
  acceptedPDFPreviewHasError: boolean;
  physicalCardCoverImageHasError: boolean;
}

function Message(props: IMessageProps) {
  const { t: translate } = useTranslation();
  const classes = MessageStyles();
  const dispatch = useDispatch();
  const [tooltipDialogIsOpen, setTooltipDialogIsOpen] = useState(false);
  const [previewImageTags, setPreviewImageTags] = useState([] as IFilterOption[]);
  const [customMessageTooltipDialogIsOpen, setCustomMessageTooltipDialogIsOpen] = useState(false);
  const [customWebPageTooltipDialogIsOpen, setCustomWebPageTooltipDialogIsOpen] = useState(false);
  const [openPDF, setOpenPDF] = useState(false);
  const [isPhysicalCardEditorPopupOpen, setIsPhysicalCardEditorPopupOpen] = useState(false);
  const [resetImageSelector, setResetImageSelector] = useState("");
  const maxLengthSMS = 35;
  const isAdmin =
    (IsResellerAuth() &&
      location.pathname.includes(
        `/${translate(LabelKeys.ResellerUrl)}/${translate(LabelKeys.AdminUrl)}/${translate(LabelKeys.OrderUrl)}`
      )) ||
    (IsAuth() && location.pathname.includes(`/${translate(LabelKeys.AdminUrl)}/${translate(LabelKeys.OrderUrl)}`));

  const reducerState = useSelector(
    (s: RootState) => {
      return {
        product: s.product,
        order: s.order,
      };
    },
    (prev, next) =>
      prev.product.isLoading === next.product.isLoading &&
      prev.product.messageTemplates === next.product.messageTemplates &&
      prev.product.previewImageOptions === next.product.previewImageOptions &&
      prev.product.previewPDFFile === next.product.previewPDFFile &&
      prev.product.products === next.product.products &&
      prev.order.stepperThreeState.messageTemplates === next.order.stepperThreeState.messageTemplates &&
      prev.order.stepperThreeState.selectedImage === next.order.stepperThreeState.selectedImage &&
      prev.order.stepperThreeState.uploadedImage === next.order.stepperThreeState.uploadedImage &&
      prev.order.stepperThreeState.smsMessage === next.order.stepperThreeState.smsMessage &&
      prev.order.stepperThreeState.emailMessage === next.order.stepperThreeState.emailMessage &&
      prev.order.stepperThreeState.emailPDFMessage === next.order.stepperThreeState.emailPDFMessage &&
      prev.order.stepperThreeState.selectedMessageTemplate === next.order.stepperThreeState.selectedMessageTemplate &&
      prev.order.stepperThreeState.physicalCardSelectedCoverImage === next.order.stepperThreeState.physicalCardSelectedCoverImage &&
      prev.order.stepperThreeState.physicalCardSelectedUploadedCoverImage ===
        next.order.stepperThreeState.physicalCardSelectedUploadedCoverImage
  );

  function handleFieldOnChange(
    stateName: StateNames.stepperThreeState | StateNames.stepperFourState,
    value: string | boolean | number | IImageOption | IUploadedFile | IFilterOption,
    fieldName: FieldNames
  ) {
    props.onStateChanged(stateName, value, fieldName);
  }

  useEffect(() => {
    if (
      !reducerState.order.stepperThreeState.customMessage ||
      (reducerState.order.stepperThreeState.selectedImage?.url == undefined &&
        reducerState.order.stepperThreeState.uploadedImage?.base64 == undefined)
    ) {
      setStandardImage();
    }

    if (reducerState.product.previewImageOptions?.length > 0 || reducerState.product.previewImageOptions?.length <= 0) {
      setPreviewImageTags(GetAllPreviewImageTags(reducerState.product.previewImageOptions));
    }
  }, []);

  useEffect(() => {
    if (
      (props.smsMessage?.length <= 0 || props.smsMessage == undefined) &&
      (props.emailMessage?.length <= 0 || props.emailMessage == undefined) &&
      (props.emailPDFMessage?.length <= 0 || props.emailPDFMessage == undefined)
    ) {
      setStandardTemplate();
    }
  }, [reducerState.order.stepperThreeState.messageTemplates]);

  useEffect(() => {
    if (
      reducerState.order.stepperThreeState.uploadedImage?.base64?.length <= 0 &&
      reducerState.order.stepperThreeState.selectedImage?.imageId?.length <= 0
    )
      dispatch(
        OrderActions.SetStepThreeStateRequest({
          fieldName: FieldNames.selectedImage,
          value: reducerState.product.previewImageOptions?.find((x) => x.isStandard === true),
        })
      );
    dispatch(
      OrderActions.SetStepThreeStateRequest({
        fieldName: FieldNames.previewImageOptions,
        value: reducerState.product.previewImageOptions,
      })
    );
  }, [reducerState.product.previewImageOptions]);

  useEffect(() => {
    if (props.sendBy == SendBy.SMS) {
      props.onStateChanged(StateNames.stepperThreeState, "", FieldNames.emailMessage);
      props.onStateChanged(StateNames.stepperThreeState, "", FieldNames.emailPDFMessage);
      props.onStateChanged(StateNames.stepperThreeState, "", FieldNames.emailSubject);
      if (reducerState.order.stepperThreeState.smsMessage?.length <= 0) {
        setStandardTemplate();
      }
      if (
        !reducerState.order.stepperThreeState.customMessage ||
        (reducerState.order.stepperThreeState.selectedImage?.url == undefined &&
          reducerState.order.stepperThreeState.uploadedImage?.base64 == undefined)
      ) {
        setStandardImage();
      }
    } else if (props.sendBy == SendBy.Email) {
      props.onStateChanged(StateNames.stepperThreeState, "", FieldNames.smsMessage);
      props.onStateChanged(StateNames.stepperThreeState, "", FieldNames.emailPDFMessage);
      if (reducerState.order.stepperThreeState.emailMessage?.length <= 0) {
        setStandardTemplate();
      }
      if (
        !reducerState.order.stepperThreeState.customMessage ||
        (reducerState.order.stepperThreeState.selectedImage?.url == undefined &&
          reducerState.order.stepperThreeState.uploadedImage?.base64 == undefined)
      ) {
        setStandardImage();
      }
    } else if (props.sendBy == SendBy.EmailPDF) {
      props.onStateChanged(StateNames.stepperThreeState, "", FieldNames.smsMessage);
      props.onStateChanged(StateNames.stepperThreeState, "", FieldNames.emailMessage);
      if (reducerState.order.stepperThreeState.emailPDFMessage?.length <= 0) {
        setStandardTemplate();
      }
      if (
        !reducerState.order.stepperThreeState.customMessage ||
        (reducerState.order.stepperThreeState.selectedImage?.url == undefined &&
          reducerState.order.stepperThreeState.uploadedImage?.base64 == undefined)
      ) {
        setStandardImage();
      }
    } else if (props.sendBy == SendBy.PhysicalCard) {
      props.onStateChanged(StateNames.stepperThreeState, "", FieldNames.smsMessage);
      props.onStateChanged(StateNames.stepperThreeState, "", FieldNames.emailMessage);
      props.onStateChanged(StateNames.stepperThreeState, "", FieldNames.emailPDFMessage);
      props.onStateChanged(StateNames.stepperThreeState, "", FieldNames.emailSubject);
    }

    if (
      props.smsMessage?.length <= 0 &&
      props.emailMessage?.length <= 0 &&
      props.emailPDFMessage?.length <= 0 &&
      props.sendBy != SendBy.PhysicalCard
    ) {
      setStandardImage();
      setStandardTemplate();
      ResetPhysicalCardValues();
    }

    if (
      props.sendBy == SendBy.PhysicalCard &&
      reducerState.order.stepperThreeState.physicalCardSelectedCoverImage?.imageId == undefined &&
      reducerState.order.stepperThreeState.physicalCardSelectedUploadedCoverImage?.base64 == undefined
    ) {
      setIsPhysicalCardEditorPopupOpen(true);
      props.onStateChanged(StateNames.stepperThreeState, true, FieldNames.isPhysicalCardEditorPopupOpen);
    }
  }, [props.sendBy]);

  useEffect(() => {
    if (openPDF) {
      let url = URL.createObjectURL(reducerState.product.previewPDFFile);
      window.open(url);
      setOpenPDF(false);
    }
  }, [reducerState.product.previewPDFFile]);

  function GetAllPreviewImageTags(previewImages: IImageOption[]) {
    let tags: IFilterOption[] = [];
    previewImages?.forEach((x) => {
      x.gbvImageTags?.forEach((z, index) => {
        if (tags?.some((g) => g.value == z)) {
          return;
        } else {
          tags?.push({ id: index + 1, name: translate(z), value: z } as IFilterOption);
        }
      });
    });
    return tags;
  }

  function ResetPhysicalCardValues() {
    props.onStateChanged(StateNames.stepperThreeState, {} as IPhysicalCardImage, FieldNames.physicalCardSelectedCoverImage);
    props.onStateChanged(StateNames.stepperThreeState, {} as IUploadedFile, FieldNames.physicalCardSelectedUploadedCoverImage);
    props.onStateChanged(StateNames.stepperThreeState, {} as IUploadedFile, FieldNames.physicalCardSelectedUploadedLogotype);
    props.onStateChanged(StateNames.stepperThreeState, "", FieldNames.physicalCardHeading);
    props.onStateChanged(StateNames.stepperThreeState, "", FieldNames.physicalCardCustomMessage);
    props.onStateChanged(StateNames.stepperFiveState, "", FieldNames.amountOfMailings);
    props.onStateChanged(StateNames.stepperFiveState, "", FieldNames.addressLine);
    props.onStateChanged(StateNames.stepperFiveState, "", FieldNames.postalCode);
    props.onStateChanged(StateNames.stepperFiveState, "", FieldNames.city);
  }

  function setStandardImage() {
    let standardImage = {} as IImageOption;
    standardImage = reducerState.product.previewImageOptions?.find((x) => x.isStandard === true);

    if (standardImage === undefined) {
      standardImage === reducerState.product.previewImageOptions[0];
    }

    //Still empty object? No value at all
    if (standardImage === undefined) {
      return;
    }

    handleFieldOnChange(StateNames.stepperThreeState, standardImage, FieldNames.selectedImage);
  }

  function onCustomMessageCheckboxChanged(e: React.ChangeEvent<HTMLInputElement>): void {
    handleFieldOnChange(StateNames.stepperThreeState, e.target.checked, FieldNames.customMessage);

    if (!e.target.checked) {
      setStandardImage();
    }

    handleFieldOnChange(StateNames.stepperThreeState, "", FieldNames.messageSignature);
  }

  function getMessageSignaturePlaceHolder(): string {
    const label = `${translate(LabelKeys.SignatureInMessage)} (${translate(LabelKeys.Max)} ${maxLengthSMS} ${translate(
      LabelKeys.Characters
    )})`;

    return label;
  }

  function setStandardTemplate() {
    let template = reducerState.order.stepperThreeState.messageTemplates?.find((x: IFilterOption) => x.checked);
    handleFieldOnChange(StateNames.stepperThreeState, template, FieldNames.selectedMessageTemplate);

    let fieldName =
      props.sendBy == SendBy.SMS
        ? FieldNames.smsMessage
        : props.sendBy == SendBy.Email
        ? FieldNames.emailMessage
        : FieldNames.emailPDFMessage;
    handleFieldOnChange(StateNames.stepperThreeState, template?.value, fieldName);
  }

  function onMessageSignatureChanged(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void {
    handleFieldOnChange(StateNames.stepperThreeState, e.target.value, FieldNames.messageSignature);

    let fieldName = props.sendBy == SendBy.SMS ? FieldNames.smsMessage : FieldNames.emailMessage;
    handleFieldOnChange(StateNames.stepperThreeState, props.selectedMessageTemplate?.value + e.target.value, fieldName);
  }

  function handleTemplateChanged(e: IFilterOption): void {
    let message = e?.value;
    let fieldName = getSendByFieldName();
    handleFieldOnChange(StateNames.stepperThreeState, e, FieldNames.selectedMessageTemplate);
    handleFieldOnChange(StateNames.stepperThreeState, message, fieldName);
    handleFieldOnChange(StateNames.stepperThreeState, "", FieldNames.messageSignature);
  }

  function getSendByFieldName(): FieldNames {
    return props.sendBy == SendBy.SMS
      ? FieldNames.smsMessage
      : props.sendBy == SendBy.Email
      ? FieldNames.emailMessage
      : FieldNames.emailPDFMessage;
  }

  function GetCustomeWebPageLabel(): string {
    const customWebPageProduct = reducerState.product.products.find((x) => x.category == ProductCategory.CustomWebPage);

    if (customWebPageProduct?.id !== undefined) {
      return (
        translate(LabelKeys.CustomWebPage) +
        " (" +
        `${customWebPageProduct.priceExcludingVat} ${Currency[customWebPageProduct?.currency]} ${translate(
          LabelKeys.ExcludingVat
        )?.toLowerCase()}` +
        ")"
      );
    } else {
      return translate(LabelKeys.CustomWebPage);
    }
  }

  return (
    <Grid id={props.id} container item justifyContent="flex-start" xs={12} sm={12} md={12} lg={12} xl={12}>
      <Grid
        container
        item
        xs={12}
        sm={12}
        md={props.sendBy == SendBy.EmailPDF ? 12 : 6}
        lg={6}
        xl={6}
        justifyContent="center"
        className={classes.messageFieldsContainer}
        alignContent="flex-start"
      >
        <Grid item xs={12} sm={12} md={12}>
          <Typography align="left" variant="h1">
            {translate(LabelKeys.StepThreeTitle)}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={12} style={{ paddingTop: 10 }}>
          <Typography align="left" variant="body1">
            {props.systemPart == SystemPart.Private && !isAdmin
              ? translate(LabelKeys.StepThreeDescriptionPrivate)
              : translate(LabelKeys.StepThreeDescriptionCompany)}
          </Typography>
        </Grid>
        <MessageSendByWrapper
          id="messageSendByWrapperId"
          sendBy={props.sendBy}
          systemPart={props.systemPart}
          onSetResetImageSelector={(value) => setResetImageSelector(value)}
          onStateChanged={(stateName, value, fieldName) => props.onStateChanged(stateName, value, fieldName)}
        />
        {props.sendBy != SendBy.EmailPDF && props.sendBy != SendBy.PhysicalCard && (
          <Grid item xs={12} sm={12} md={12} className={classes.checkbox}>
            <div style={{ display: "flex" }}>
              <div>
                <CSCheckbox
                  id="customMessageCheckbox"
                  className={classes.customMessage}
                  label={`${translate(LabelKeys.UseCustomMessage)}`}
                  checked={props.customMessage}
                  onChange={(e) => onCustomMessageCheckboxChanged(e)}
                />
              </div>
              <div style={{ padding: 0, margin: 0, bottom: 0, paddingLeft: 14 }}>
                <CSInfo
                  className={classes.csInfoCustomMessage}
                  placement={"right"}
                  hoverTooltopText={translate(LabelKeys.CustomMessageTooltipInfo)}
                  popupTooltipText={translate(LabelKeys.CustomMessageTooltipInfo)}
                  tooltipDialogIsOpen={customMessageTooltipDialogIsOpen}
                  onSetTooltipDialogIsOpen={(value) => setCustomMessageTooltipDialogIsOpen(value)}
                />
              </div>
            </div>
          </Grid>
        )}
        {(props.sendBy == SendBy.Email || props.sendBy == SendBy.EmailPDF) && (
          <Grid item xs={12} sm={12} md={12} className={classes.textfield}>
            <CSTextField
              id={FieldNames.emailSubject}
              type="text"
              className={classes.textFieldBackground}
              maxLength={50}
              value={props.emailSubject}
              placeholder={translate(LabelKeys.Subject)}
              onChange={(e) => handleFieldOnChange(StateNames.stepperThreeState, e.target.value, FieldNames.emailSubject)}
            />
          </Grid>
        )}
        {props.customMessage && props.sendBy != SendBy.PhysicalCard && (
          <Grid id="imageSelectorContainer" item container xs={12} sm={12} md={12} lg={12} xl={12} justifyContent={"center"}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <CSImageSelector
                key={"imageSelector"}
                className={classes.textFieldBackground}
                selectedImage={reducerState.order.stepperThreeState.selectedImage}
                resetComponent={resetImageSelector}
                hasUploadedImage={reducerState.order.stepperThreeState.uploadedImage?.base64?.length > 0}
                filterOptions={previewImageTags}
                imageOptions={reducerState.product.previewImageOptions}
                onStateChanged={(stateName, value, fieldName) => handleFieldOnChange(stateName, value, fieldName)}
                sendBy={props.sendBy}
                showTooltip
              />
            </Grid>
          </Grid>
        )}
        {props.sendBy != SendBy.PhysicalCard && (
          <Grid item xs={12} sm={4} md={6} lg={4} xl={4} style={{ paddingTop: 20 }}>
            <div style={{ display: "flex" }}>
              <Typography id="tagText" variant="body1" style={{ textAlign: "left", fontWeight: "bold" }}>{`${translate(
                LabelKeys.Templates
              )}:`}</Typography>
              <div style={{ padding: 0, margin: 0, bottom: 0, paddingLeft: 14 }}>
                <CSInfo
                  placement={"right"}
                  className={classes.csInfoTempaltes}
                  hoverTooltopText={translate(LabelKeys.TemplateTooltipInfo)}
                  popupTooltipText={translate(LabelKeys.TemplateTooltipInfo)}
                  tooltipDialogIsOpen={tooltipDialogIsOpen}
                  onSetTooltipDialogIsOpen={(value) => setTooltipDialogIsOpen(value)}
                />
              </div>
            </div>
            <div style={{ paddingTop: 10 }}>
              <CSDropDown
                id="templateDropDown"
                value={props?.selectedMessageTemplate?.name}
                filterOptions={props.messageTemplates}
                onStateChanged={(e) => handleTemplateChanged(e)}
              />
            </div>
          </Grid>
        )}
        <Grid item xs={12} sm={8} md={6} lg={8} xl={8} />
        {!props.customMessage && props.sendBy != SendBy.PhysicalCard && (
          <Grid item xs={12} sm={12} md={12} className={classes.textfield}>
            <CSTextField
              id={FieldNames.messageSignature}
              type="text"
              value={props.messageSignature}
              className={classes.textFieldBackground}
              maxLength={maxLengthSMS}
              placeholder={getMessageSignaturePlaceHolder()}
              onChange={(e) => onMessageSignatureChanged(e)}
            />
          </Grid>
        )}
        {props.customMessage && (props.systemPart != SystemPart.Private || isAdmin) && props.sendBy != SendBy.PhysicalCard && (
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className={classes.messageTags}>
            <MessageTags />
          </Grid>
        )}
        {props.customMessage && props.sendBy != SendBy.PhysicalCard && (
          <MessageTextEditorWrapper
            id="messageTextEditorWrapperId"
            emailMessage={props.emailMessage}
            emailPDFMessage={props.emailPDFMessage}
            smsMessage={props.smsMessage}
            sendBy={props.sendBy}
            onStateChanged={(stateName, value, fieldName) => props.onStateChanged(stateName, value, fieldName)}
          />
        )}
        {props.sendBy != SendBy.PhysicalCard && (
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className={classes.previewVariationInformation}>
            <Typography variant="body2">{translate(LabelKeys.PreviewVariationInformation)}</Typography>
          </Grid>
        )}
        {props.sendBy === SendBy.EmailPDF && (
          <Grid container item xs={12} sm={12} md={12} lg={12} xl={12} style={{ marginTop: 50 }}>
            <CSCheckbox
              id="acceptedPDFPreview"
              checked={props.acceptedPDFPreview}
              onChange={(e) => props.onStateChanged(StateNames.stepperThreeState, e.target.checked, FieldNames.acceptedPDFPreview)}
              error={props.acceptedPDFPreviewHasError}
            />
            <Typography variant="body1">{translate(LabelKeys.AcceptedPDFPreview)}</Typography>
          </Grid>
        )}
        {props.systemPart == SystemPart.Company && props.sendBy == SendBy.PhysicalCard && (
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ marginTop: 50 }}>
            <Button
              style={{
                float: "left",
                border: props.physicalCardCoverImageHasError ? "1px solid red" : "1px solid #000",
                color: "#000",
                textTransform: "unset",
                fontWeight: 600,
                height: 50,
                width: 150,
              }}
              fullWidth
              onClick={() => {
                setIsPhysicalCardEditorPopupOpen(true);
                props.onStateChanged(StateNames.stepperThreeState, true, FieldNames.isPhysicalCardEditorPopupOpen);
              }}
            >
              {translate(LabelKeys.Edit)}
            </Button>
          </Grid>
        )}
        {/* {props.systemPart == SystemPart.Company && !isAdmin && (
          <Grid container item xs={12} sm={12} md={12} lg={12} xl={12} style={{ marginTop: 50 }}>
            <div style={{ display: "flex" }}>
              <CSCheckbox
                id="customWebPage"
                checked={props.customWebPage}
                onChange={(e) => {
                  props.onStateChanged(StateNames.stepperThreeState, e.target.checked, FieldNames.customWebPage);
                }}
              />
              <Typography variant="body1">{GetCustomeWebPageLabel()}</Typography>
              <div style={{ paddingLeft: 10, paddingTop: 3 }}>
                <CSInfo
                  placement={"right"}
                  hoverTooltopText={translate(LabelKeys.CustomWebPageTooltipInfo)}
                  popupTooltipText={translate(LabelKeys.CustomWebPageTooltipInfo)}
                  tooltipDialogIsOpen={customWebPageTooltipDialogIsOpen}
                  onSetTooltipDialogIsOpen={(value) => setCustomWebPageTooltipDialogIsOpen(value)}
                />
              </div>
            </div>
          </Grid>
        )} */}
      </Grid>
      <MessagePreviewWrapper
        id="messadePreviewWrapper"
        customMessage={props.customMessage}
        emailMessage={props.emailMessage}
        emailPDFMessage={props.emailPDFMessage}
        smsMessage={props.smsMessage}
        selectedImage={props.selectedImage}
        selectedProduct={props.selectedProduct}
        sendBy={props.sendBy}
        uploadedImage={props.uploadedImage}
        onOpenPdfChanged={(value) => setOpenPDF(value)}
        physicalCardSelectedCoverImage={props.physicalCardSelectedCoverImage?.url}
        physicalCardUploadedCoverImage={props.physicalCardSelectedUploadedCoverImage?.base64}
        physicalCardMiddlePageImage={props.physicalCardMiddlePageImage}
      />
      {props.sendBy == SendBy.PhysicalCard && isPhysicalCardEditorPopupOpen && (
        <PhysicalCardEditorPopup
          id="physicalCardEditor"
          selectedProduct={reducerState.order.stepperTwoState.selectedProduct}
          physicalCardSelectedCoverImage={props.physicalCardSelectedCoverImage}
          physicalCardSelectedUploadedCoverImage={props.physicalCardSelectedUploadedCoverImage}
          physicalCardSelectedUploadedLogotype={props.physicalCardSelectedUploadedLogotype}
          physicalCardHeading={props.physicalCardHeading}
          physicalCardCustomMessage={props.physicalCardCustomMessage}
          onStateChanged={(stateName, value, fieldName) => handleFieldOnChange(stateName, value, fieldName)}
          onDoneClicked={() => {
            setIsPhysicalCardEditorPopupOpen(false);
            props.onStateChanged(StateNames.stepperThreeState, false, FieldNames.isPhysicalCardEditorPopupOpen);
          }}
          onExitClicked={() => {
            setIsPhysicalCardEditorPopupOpen(false);
            props.onStateChanged(StateNames.stepperThreeState, false, FieldNames.isPhysicalCardEditorPopupOpen);
          }}
          physicalCardCoverImageHasError={props.physicalCardCoverImageHasError}
        />
      )}
    </Grid>
  );
}
export default React.memo(Message);
