import React, { useEffect, useState } from "react";
import { Grid, useMediaQuery } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { SendBy, WhenToSend, IDeliveryState } from "../../components/Delivery/types";
import { IFilterOption } from "../../components/DropDown/types";
import { FileType, IUploadedFile } from "../../components/FileUploader/types";
import { IMessageState, TemplateType } from "../../components/Message/types";
import CSStepper, { IErrorStep } from "../../components/Stepper/CSStepper";
import {
  IGetMessageTemplatesRequest,
  IGetProductsRequest,
  IImageOption,
  IProduct,
  IProductCategory,
  ProductCategory,
} from "../../Modules/Products/types";
import { RootState } from "../../store";
import {
  GetValuesAsArray,
  ReceiverDataObjects,
  ValidateAndGetReceiverDataObjects,
  ValidateDeliveryPhoneNumbers,
  ValidateOrganisationNumber,
} from "../../Utils/extensions";
import { StateNames, FieldNames, GetCurrentLanguageAsCodeFromLocalStorage } from "../../Utils/types";
import { GetOrderErrorStateInit, IEnvironmentState, MapProductToCustomProduct } from "../Order/types";
import { AmountOfMailingsLimit, LanguageCode, SystemPart, ImageSystemPart, PaymentMethod } from "../../Utils/enums/enums";
import CSStepperStepOne from "../../components/Stepper/CSStepperStepOne";
import CSStepperStepFive from "../../components/Stepper/CSStepperStepFive";
import CSStepperStepFour from "../../components/Stepper/CSStepperStepFour";
import CSStepperStepSix from "../../components/Stepper/CSStepperStepSix";
import CSStepperStepThree from "../../components/Stepper/CSStepperStepThree";
import CSStepperStepTwo from "../../components/Stepper/CSStepperStepTwo";
import { ProductActions } from "../../Modules/Products/actions";
import { CookieConstants } from "../../Utils/Labels/types";
import Cookies from "js-cookie";
import SnackbarActions from "../../components/Snackbar/actions";
import { OrderActions } from "../../Modules/Orders/actions";
import { LabelKeys } from "../../Utils/enums/labelKeys";
import { ValidateEmail, IsLongerThanOrEqualToLength } from "../../Utils/validations";
import {
  ICreateAdminOrderRequest,
  ICreateCompanyOrderRequest,
  ICreatePrivateOrderRequest,
  IPhysicalCard,
  ISetStepState,
} from "../../Modules/Orders/types";
import ProcessCircle from "../../components/ProcessCircle/ProcessCircle";
import { OrderStyles } from "./styles";
import { theme } from "../../appTheme";
import CSNextPrevStep from "../../components/Stepper/CSNextPrevStep";
import { ICustomProduct } from "../../components/Stepper/types";
import { CheckSystemPartCookie, IsAuth, IsResellerAuth } from "../../Utils/authentication";
import { useLocation } from "react-router-dom";
import { LitiumFolderKeys } from "../../Utils/enums/imageKeys";
import { useTranslation } from "react-i18next";

export default function Order() {
  const dispatch = useDispatch();
  const classes = OrderStyles();
  const { t: translate } = useTranslation();
  const location = useLocation();
  const isLg = useMediaQuery(theme.breakpoints.only("lg"));
  const isXl = useMediaQuery(theme.breakpoints.only("xl"));
  const systemPart: SystemPart = Number(Cookies.get(CookieConstants.SystemPart));

  const isSystemAdmin = IsAuth() && location.pathname.includes(`/${translate(LabelKeys.AdminUrl)}/${translate(LabelKeys.OrderUrl)}`);
  const isResellerAdmin =
    IsResellerAuth() &&
    location.pathname.includes(`/${translate(LabelKeys.ResellerUrl)}/${translate(LabelKeys.AdminUrl)}/${translate(LabelKeys.OrderUrl)}`);

  const [errorState, setErrorState] = useState(GetOrderErrorStateInit());

  const reducerState = useSelector(
    (s: RootState) => {
      return {
        product: s.product,
        order: s.order,
        reseller: s.reseller,
      };
    },
    (prev, next) =>
      prev.order.isLoading === next.order.isLoading &&
      prev.product.isLoading === next.product.isLoading &&
      prev.product.products === next.product.products &&
      prev.product.selectedProductCategory === next.product.selectedProductCategory &&
      prev.product.selectedProduct === next.product.selectedProduct &&
      prev.order.redirectUrl === next.order.redirectUrl &&
      prev.order.environmentState === next.order.environmentState &&
      prev.order.stepperState === next.order.stepperState &&
      prev.order.stepperOneState === next.order.stepperOneState &&
      prev.order.stepperTwoState === next.order.stepperTwoState &&
      prev.order.stepperThreeState === next.order.stepperThreeState &&
      prev.order.stepperThreeState.messageTemplates === next.order.stepperThreeState.messageTemplates &&
      prev.order.stepperFourState === next.order.stepperFourState &&
      prev.order.stepperFiveState === next.order.stepperFiveState &&
      prev.order.stepperSixState === next.order.stepperSixState &&
      prev.order.stepperState.errorSections === next.order.stepperState.errorSections &&
      prev.reseller.activeReseller === next.reseller.activeReseller
  );

  useEffect(() => {
    window.scroll(0, 0);
    CheckSystemPartCookie();

    //Get message templates
    dispatch(
      ProductActions.GetMessageTemplatesRequest({
        templateTypes: [TemplateType.General, TemplateType.Unknown],
      } as IGetMessageTemplatesRequest)
    );
    //Sets systemPart
    setSystemPart();

    if (reducerState.product.previewImageOptions === undefined || reducerState.product.previewImageOptions?.length === 0) {
      dispatch(
        ProductActions.GetImageSelectorImagesRequest({
          folderId: LitiumFolderKeys.ImageSelectorImages,
        })
      );
    }

    // Company should always have includeFreight as true
    if ((systemPart == SystemPart.Company || isSystemAdmin || isResellerAdmin) && !reducerState.order.stepperFiveState?.includeFreight) {
      handleFieldOnChange(StateNames.stepperFiveState, true, FieldNames.includeFreight);
    }

    if (reducerState.product.productCategories?.length <= 0) {
      dispatch(ProductActions.GetProductCategoriesRequest());
    }
  }, []);

  // Redirect when customer wants to pay with a selected payment method
  useEffect(() => {
    if (reducerState.order.redirectUrl && reducerState.order.internalOrderId) window.location.href = reducerState.order.redirectUrl;
  }, [reducerState.order.redirectUrl]);

  useEffect(() => {
    CheckSystemPartCookie();

    if (reducerState.order.stepperState.activeStep === 1) {
      if (
        (!isSystemAdmin && reducerState.order.stepperOneState.selectedProductCategory?.id !== undefined) ||
        (isSystemAdmin &&
          reducerState.product.products?.length <= 0 &&
          reducerState.order.stepperOneState.selectedProductCategory?.id !== undefined) ||
        (isSystemAdmin &&
          reducerState.product.products?.length > 0 &&
          reducerState.order.stepperOneState.selectedProductCategory?.id !== undefined &&
          reducerState.product.products[0]?.language != reducerState.reseller.adminSelectedResellerCountryOption?.value)
      ) {
        dispatch(
          ProductActions.GetProductsRequest({
            language: GetLanguage(),
            systemPart: systemPart,
            productCategoryId: reducerState.order.stepperOneState.selectedProductCategory?.category,
          } as IGetProductsRequest)
        );
        dispatch(
          OrderActions.SetStepTwoStateRequest({
            fieldName: FieldNames.selectedProduct,
            value: {} as IProduct,
          })
        );
      }
    }
  }, [reducerState.order.stepperState.activeStep]);

  useEffect(() => {
    if (reducerState.order.stepperState.readyToPay) {
      if (
        reducerState.order.stepperThreeState.customWebPage &&
        !reducerState.order.stepperSixState.acceptedCustomWebPage &&
        reducerState.order.stepperThreeState.sendBy != SendBy.PhysicalCard
      ) {
        dispatch(OrderActions.SetStepSixStateRequest({ fieldName: FieldNames.customWebPageDialogOpen, value: true }));
      } else {
        handlePayOrderClicked();
        dispatch(
          OrderActions.SetStepperStateRequest({
            fieldName: FieldNames.readyToPay,
            value: false,
          })
        );
      }
    }
  }, [reducerState.order.stepperState.readyToPay]);

  useEffect(() => {
    if (
      systemPart == SystemPart.Private &&
      !isSystemAdmin &&
      !isResellerAdmin &&
      reducerState.order.stepperOneState.selectedProductCategory.category == ProductCategory.GiftCardGMG
    ) {
      handleFieldOnChange(StateNames.stepperFiveState, true, FieldNames.includeFreight);
    }

    if (
      systemPart == SystemPart.Private &&
      !isSystemAdmin &&
      !isResellerAdmin &&
      reducerState.order.stepperOneState.selectedProductCategory.category != ProductCategory.GiftCardGMG
    ) {
      handleFieldOnChange(StateNames.stepperFiveState, false, FieldNames.includeFreight);
    }
  }, [reducerState.order.stepperOneState.selectedProductCategory]);

  function GetLanguage() {
    if (isSystemAdmin) {
      return reducerState.reseller.adminSelectedResellerCountryOption?.value != LanguageCode.Unknown
        ? reducerState.reseller.adminSelectedResellerCountryOption?.value
        : LanguageCode.SE;
    } else return GetCurrentLanguageAsCodeFromLocalStorage();
  }

  function handleFieldOnChange(
    stateName:
      | StateNames.environmentState
      | StateNames.stepperState
      | StateNames.stepperOneState
      | StateNames.stepperTwoState
      | StateNames.stepperThreeState
      | StateNames.stepperFourState
      | StateNames.stepperFiveState
      | StateNames.stepperSixState,
    value:
      | string[]
      | Date
      | number
      | SendBy
      | string
      | boolean
      | WhenToSend
      | IImageOption
      | IUploadedFile
      | IMessageState
      | IDeliveryState
      | IEnvironmentState
      | IFilterOption
      | IFilterOption[]
      | IProductCategory,
    fieldName: FieldNames
  ) {
    switch (stateName) {
      case StateNames.environmentState: {
        dispatch(
          OrderActions.SetEnvironmentStateRequest({
            fieldName: fieldName,
            value: value,
          } as ISetStepState)
        );
        break;
      }
      case StateNames.stepperState: {
        dispatch(
          OrderActions.SetStepperStateRequest({
            fieldName: fieldName,
            value: value,
          } as ISetStepState)
        );
        break;
      }
      case StateNames.stepperOneState: {
        dispatch(
          OrderActions.SetStepOneStateRequest({
            fieldName: fieldName,
            value: value,
          } as ISetStepState)
        );
        break;
      }
      case StateNames.stepperTwoState: {
        dispatch(
          OrderActions.SetStepTwoStateRequest({
            fieldName: fieldName,
            value: value,
          } as ISetStepState)
        );
        break;
      }
      case StateNames.stepperThreeState: {
        dispatch(
          OrderActions.SetStepThreeStateRequest({
            fieldName: fieldName,
            value: value,
          } as ISetStepState)
        );
        break;
      }
      case StateNames.stepperFourState: {
        dispatch(
          OrderActions.SetStepFourStateRequest({
            fieldName: fieldName,
            value: value,
          } as ISetStepState)
        );
        break;
      }
      case StateNames.stepperFiveState: {
        dispatch(
          OrderActions.SetStepFiveStateRequest({
            fieldName: fieldName,
            value: value,
          } as ISetStepState)
        );
        break;
      }
      case StateNames.stepperSixState: {
        dispatch(
          OrderActions.SetStepSixStateRequest({
            fieldName: fieldName,
            value: value,
          } as ISetStepState)
        );
        break;
      }
      default:
        break;
    }
  }

  function handleSetErrorState(fieldName: FieldNames, value: boolean | IErrorStep[]) {
    setErrorState((state: any) => ({
      ...state,
      [fieldName]: value,
    }));
  }

  useEffect(() => {
    setSystemPart();
  }, [Cookies.get(CookieConstants.SystemPart)]);

  useEffect(() => {
    if (reducerState.product.messageTemplates?.length >= 0) {
      const template = reducerState.product.messageTemplates?.find((x: any) => x.isStandard === true);

      if (template !== undefined) {
        const filterOption: IFilterOption = {
          id: template.id,
          name: template.name,
          value: template.message,
          checked: template.isStandard,
        } as IFilterOption;

        handleFieldOnChange(StateNames.stepperThreeState, filterOption, FieldNames.selectedMessageTemplate);

        let options = reducerState.product.messageTemplates?.map((x: any) => {
          return {
            id: x.id,
            name: x.name,
            value: x.message,
            checked: x.isStandard,
          } as IFilterOption;
        });

        handleFieldOnChange(StateNames.stepperThreeState, options, FieldNames.messageTemplates);
      }
    }
  }, [reducerState.product.messageTemplates]);

  function setSystemPart() {
    const systemPartCookie = Cookies.get(CookieConstants.SystemPart);
    handleFieldOnChange(StateNames.environmentState, systemPartCookie, FieldNames.systemPart);
  }

  function GetFreightProduct(): IProduct {
    return reducerState.product?.products?.find((x: any) => x.category === ProductCategory.Freight);
  }

  function GetReceiversAmount() {
    if (
      reducerState.order.stepperFiveState.receiverPhoneNumbers !== undefined &&
      reducerState.order.stepperFiveState.receiverPhoneNumbers?.length > 0 &&
      reducerState.order.stepperThreeState.sendBy == SendBy.SMS
    ) {
      return GetValuesAsArray(reducerState.order.stepperFiveState.receiverPhoneNumbers).length;
    } else if (
      reducerState.order.stepperFiveState.receiverEmails !== undefined &&
      reducerState.order.stepperFiveState.receiverEmails?.length > 0 &&
      reducerState.order.stepperThreeState.sendBy == SendBy.Email
    ) {
      return GetValuesAsArray(reducerState.order.stepperFiveState.receiverEmails).length;
    } else if (
      reducerState.order.stepperFiveState.receiverEmails !== undefined &&
      reducerState.order.stepperFiveState.receiverEmails?.length > 0 &&
      reducerState.order.stepperThreeState.sendBy == SendBy.EmailPDF
    ) {
      if (reducerState.order.stepperFiveState.sendToSameReceiver) {
        return reducerState.order.stepperFiveState.amountOfMailings;
      } else {
        return GetValuesAsArray(reducerState.order.stepperFiveState.receiverEmails).length;
      }
    } else if (reducerState.order.stepperThreeState.sendBy == SendBy.PhysicalCard) {
      return reducerState.order.stepperFiveState.amountOfMailings;
    } else {
      return 0;
    }
  }

  function GetOrderSummaryRows(): ICustomProduct[] {
    let rows: ICustomProduct[] = [];

    if (reducerState.order.stepperTwoState?.selectedProduct?.id > 0) {
      rows.push(MapProductToCustomProduct(reducerState.order.stepperTwoState.selectedProduct));
    }

    if (reducerState.order.stepperFiveState.includeFreight === true) {
      rows.push(MapProductToCustomProduct(GetFreightProduct()));
    }

    if (reducerState.order.stepperThreeState.customWebPage) {
      let row = MapProductToCustomProduct(reducerState.product?.products?.find((x: any) => x.category == ProductCategory.CustomWebPage));
      row.amount = 1;
      rows.push(row);
    }

    return rows;
  }

  function ValidateAllFieldsCompany(dataObjects: ReceiverDataObjects): boolean {
    let isValid: boolean = true;
    let errorSections: IErrorStep[] = [];

    if (reducerState.order.stepperOneState.selectedProductCategory?.id === undefined) {
      handleSetErrorState(FieldNames.selectedProductCategory, true);
      isValid = false;
      errorSections.push({ name: FieldNames.stepOne });
    } else {
      handleSetErrorState(FieldNames.selectedProductCategory, false);
    }

    if (reducerState.order.stepperTwoState.selectedProduct?.id === undefined) {
      handleSetErrorState(FieldNames.selectedProduct, true);
      isValid = false;
      errorSections.push({ name: FieldNames.stepTwo });
    } else {
      handleSetErrorState(FieldNames.selectedProduct, false);
    }

    if (
      reducerState.order.stepperThreeState.sendBy == SendBy.SMS &&
      (dataObjects.receiverPhoneNumbers?.length <= 0 || !dataObjects.valid)
    ) {
      handleSetErrorState(FieldNames.receiverPhoneNumbers, true);
      isValid = false;
      errorSections.push({ name: FieldNames.stepFive });
    } else {
      handleSetErrorState(FieldNames.receiverPhoneNumbers, false);
    }

    if (
      (reducerState.order.stepperThreeState.sendBy == SendBy.Email || reducerState.order.stepperThreeState.sendBy == SendBy.EmailPDF) &&
      (dataObjects.receiverEmails?.length <= 0 || !dataObjects.valid) &&
      !reducerState.order.stepperFiveState.sendToSameReceiver
    ) {
      handleSetErrorState(
        reducerState.order.stepperThreeState.sendBy == SendBy.Email ? FieldNames.receiverEmails : FieldNames.receiverEmailsPDF,
        true
      );
      isValid = false;
      errorSections.push({ name: FieldNames.stepFive });
    } else {
      handleSetErrorState(
        reducerState.order.stepperThreeState.sendBy == SendBy.Email ? FieldNames.receiverEmails : FieldNames.receiverEmailsPDF,
        false
      );
    }

    if (
      reducerState.order.stepperThreeState.sendBy == SendBy.EmailPDF &&
      reducerState.order.stepperFiveState.sendToSameReceiver &&
      (dataObjects.receiverEmails?.length > 1 || !dataObjects.valid)
    ) {
      handleSetErrorState(FieldNames.receiverEmailsPDFSendToSameReceiver, true);
      isValid = false;
      errorSections.push({ name: FieldNames.stepFive });
    } else {
      handleSetErrorState(FieldNames.receiverEmailsPDFSendToSameReceiver, false);
    }

    if (
      reducerState.order.stepperThreeState.sendBy == SendBy.EmailPDF &&
      reducerState.order.stepperFiveState.sendToSameReceiver &&
      (!reducerState.order.stepperFiveState.amountOfMailings ||
        reducerState.order.stepperFiveState.amountOfMailings <= 0 ||
        reducerState.order.stepperFiveState.amountOfMailings > AmountOfMailingsLimit)
    ) {
      handleSetErrorState(FieldNames.amountOfMailings, true);
      isValid = false;
      errorSections.push({ name: FieldNames.stepFive });
    } else {
      handleSetErrorState(FieldNames.amountOfMailings, false);
    }
    if (!ValidateEmail(reducerState.order.stepperFourState.senderEmail)) {
      handleSetErrorState(FieldNames.senderEmail, true);
      isValid = false;
      errorSections.push({ name: FieldNames.stepFour });
    } else {
      handleSetErrorState(FieldNames.senderEmail, false);
    }
    if (!ValidateOrganisationNumber(reducerState.order.stepperFourState.organisationNumber)) {
      handleSetErrorState(FieldNames.organisationNumber, true);
      isValid = false;
      errorSections.push({ name: FieldNames.stepFour });
    } else {
      handleSetErrorState(FieldNames.organisationNumber, false);
    }
    if (!IsLongerThanOrEqualToLength(reducerState.order.stepperFourState.companyName, 1)) {
      handleSetErrorState(FieldNames.companyName, true);
      isValid = false;
      errorSections.push({ name: FieldNames.stepFour });
    } else {
      handleSetErrorState(FieldNames.companyName, false);
    }
    if (!ValidateDeliveryPhoneNumbers(reducerState.order.stepperFourState.companyContactPhoneNumber)) {
      handleSetErrorState(FieldNames.companyContactPhoneNumber, true);
      isValid = false;
      errorSections.push({ name: FieldNames.stepFour });
    } else {
      handleSetErrorState(FieldNames.companyContactPhoneNumber, false);
    }
    if (!reducerState.order.stepperSixState.acceptedTermsOfUse) {
      handleSetErrorState(FieldNames.acceptedTermsOfUse, true);
      isValid = false;
      errorSections.push({ name: FieldNames.stepSix });
    } else {
      handleSetErrorState(FieldNames.acceptedTermsOfUse, false);
    }
    if (!ValidateEmail(reducerState.order.stepperFourState.contactEmail)) {
      handleSetErrorState(FieldNames.contactEmail, true);
      isValid = false;
      errorSections.push({ name: FieldNames.stepFour });
    } else {
      handleSetErrorState(FieldNames.contactEmail, false);
    }

    if (!reducerState.order.stepperThreeState.acceptedPDFPreview && reducerState.order.stepperThreeState.sendBy == SendBy.EmailPDF) {
      handleSetErrorState(FieldNames.acceptedPDFPreview, true);
      isValid = false;
      errorSections.push({ name: FieldNames.stepThree });
    } else {
      handleSetErrorState(FieldNames.acceptedPDFPreview, false);
    }

    if (
      (reducerState.order.stepperThreeState.physicalCardSelectedCoverImage?.imageId == undefined ||
        reducerState.order.stepperThreeState.physicalCardSelectedCoverImage?.imageId == "") &&
      reducerState.order.stepperThreeState.physicalCardSelectedUploadedCoverImage?.base64?.length <= 0 &&
      reducerState.order.stepperThreeState.sendBy == SendBy.PhysicalCard
    ) {
      handleSetErrorState(FieldNames.physicalCardSelectedCoverImage, true);
      isValid = false;
      errorSections.push({ name: FieldNames.stepThree });
    } else {
      handleSetErrorState(FieldNames.physicalCardSelectedCoverImage, false);
    }

    if (
      reducerState.order.stepperThreeState.sendBy == SendBy.PhysicalCard &&
      (reducerState.order.stepperFiveState.amountOfMailings <= 0 ||
        reducerState.order.stepperFiveState.amountOfMailings == undefined ||
        reducerState.order.stepperFiveState.amountOfMailings > AmountOfMailingsLimit)
    ) {
      handleSetErrorState(FieldNames.amountOfMailings, true);
      isValid = false;
      errorSections.push({ name: FieldNames.stepFive });
    } else {
      handleSetErrorState(FieldNames.amountOfMailings, false);
    }

    if (reducerState.order.stepperFourState.marking?.length > 30) {
      handleSetErrorState(FieldNames.marking, true);
      isValid = false;
      errorSections.push({ name: FieldNames.stepFour });
    } else {
      handleSetErrorState(FieldNames.marking, false);
    }

    if (
      reducerState.order.stepperThreeState.sendBy == SendBy.PhysicalCard &&
      (reducerState.order.stepperFiveState.name == undefined ||
        reducerState.order.stepperFiveState.name?.length <= 0 ||
        reducerState.order.stepperFiveState.name?.length > 30)
    ) {
      handleSetErrorState(FieldNames.name, true);
      isValid = false;
      errorSections.push({ name: FieldNames.stepFive });
    } else {
      handleSetErrorState(FieldNames.name, false);
    }

    if (
      reducerState.order.stepperThreeState.sendBy == SendBy.PhysicalCard &&
      (reducerState.order.stepperFiveState.addressLine == undefined ||
        reducerState.order.stepperFiveState.addressLine?.length <= 0 ||
        reducerState.order.stepperFiveState.addressLine?.length > 30)
    ) {
      handleSetErrorState(FieldNames.addressLine, true);
      isValid = false;
      errorSections.push({ name: FieldNames.stepFive });
    } else {
      handleSetErrorState(FieldNames.addressLine, false);
    }

    if (
      reducerState.order.stepperThreeState.sendBy == SendBy.PhysicalCard &&
      (reducerState.order.stepperFiveState.postalCode == undefined ||
        reducerState.order.stepperFiveState.postalCode?.length <= 0 ||
        reducerState.order.stepperFiveState.postalCode?.length > 30)
    ) {
      handleSetErrorState(FieldNames.postalCode, true);
      isValid = false;
      errorSections.push({ name: FieldNames.stepFive });
    } else {
      handleSetErrorState(FieldNames.postalCode, false);
    }

    if (
      reducerState.order.stepperThreeState.sendBy == SendBy.PhysicalCard &&
      (reducerState.order.stepperFiveState.city == undefined ||
        reducerState.order.stepperFiveState.city?.length <= 0 ||
        reducerState.order.stepperFiveState.city?.length > 30)
    ) {
      handleSetErrorState(FieldNames.city, true);
      isValid = false;
      errorSections.push({ name: FieldNames.stepFive });
    } else {
      handleSetErrorState(FieldNames.city, false);
    }

    if (
      reducerState.order.stepperThreeState.sendBy == SendBy.PhysicalCard &&
      (reducerState.order.stepperFiveState.country == undefined || reducerState.order.stepperFiveState.country <= 0)
    ) {
      handleSetErrorState(FieldNames.country, true);
      isValid = false;
      errorSections.push({ name: FieldNames.stepFive });
    } else {
      handleSetErrorState(FieldNames.country, false);
    }

    dispatch(
      OrderActions.SetStepperStateRequest({
        fieldName: FieldNames.errorSections,
        value: errorSections,
      })
    );

    dispatch(OrderActions.SetStepSixStateRequest({ fieldName: FieldNames.acceptedCustomWebPage, value: false }));

    if (!isValid) {
      dispatch(SnackbarActions.ShowError(translate(LabelKeys.ValidationError)));
    }

    return isValid;
  }

  function ValidateAllFieldsAdmin(dataObjects: ReceiverDataObjects): boolean {
    let isValid: boolean = true;
    let errorSections: IErrorStep[] = [];

    if (reducerState.order.stepperOneState.selectedProductCategory?.id === undefined) {
      handleSetErrorState(FieldNames.selectedProductCategory, true);
      isValid = false;
      errorSections.push({ name: FieldNames.stepOne });
    } else {
      handleSetErrorState(FieldNames.selectedProductCategory, false);
    }

    if (reducerState.order.stepperTwoState.selectedProduct?.id === undefined) {
      handleSetErrorState(FieldNames.selectedProduct, true);
      isValid = false;
      errorSections.push({ name: FieldNames.stepTwo });
    } else {
      handleSetErrorState(FieldNames.selectedProduct, false);
    }

    if (
      reducerState.order.stepperThreeState.sendBy == SendBy.SMS &&
      (dataObjects.receiverPhoneNumbers?.length <= 0 || !dataObjects.valid)
    ) {
      handleSetErrorState(FieldNames.receiverPhoneNumbers, true);
      isValid = false;
      errorSections.push({ name: FieldNames.stepFive });
    } else {
      handleSetErrorState(FieldNames.receiverPhoneNumbers, false);
    }

    if (
      (reducerState.order.stepperThreeState.sendBy == SendBy.Email || reducerState.order.stepperThreeState.sendBy == SendBy.EmailPDF) &&
      (dataObjects.receiverEmails?.length <= 0 || !dataObjects.valid) &&
      !reducerState.order.stepperFiveState.sendToSameReceiver
    ) {
      handleSetErrorState(
        reducerState.order.stepperThreeState.sendBy == SendBy.Email ? FieldNames.receiverEmails : FieldNames.receiverEmailsPDF,
        true
      );
      isValid = false;
      errorSections.push({ name: FieldNames.stepFive });
    } else {
      handleSetErrorState(
        reducerState.order.stepperThreeState.sendBy == SendBy.Email ? FieldNames.receiverEmails : FieldNames.receiverEmailsPDF,
        false
      );
    }

    if (
      reducerState.order.stepperThreeState.sendBy == SendBy.EmailPDF &&
      reducerState.order.stepperFiveState.sendToSameReceiver &&
      (dataObjects.receiverEmails?.length > 1 || !dataObjects.valid)
    ) {
      handleSetErrorState(FieldNames.receiverEmailsPDF, true);
      isValid = false;
      errorSections.push({ name: FieldNames.stepFive });
    } else {
      handleSetErrorState(FieldNames.receiverEmailsPDF, false);
    }

    if (
      reducerState.order.stepperThreeState.sendBy == SendBy.EmailPDF &&
      reducerState.order.stepperFiveState.sendToSameReceiver &&
      (!reducerState.order.stepperFiveState.amountOfMailings ||
        reducerState.order.stepperFiveState.amountOfMailings <= 0 ||
        reducerState.order.stepperFiveState.amountOfMailings > AmountOfMailingsLimit)
    ) {
      handleSetErrorState(FieldNames.amountOfMailings, true);
      isValid = false;
      errorSections.push({ name: FieldNames.stepFive });
    } else {
      handleSetErrorState(FieldNames.amountOfMailings, false);
    }
    if (!reducerState.order.stepperSixState.acceptedTermsOfUse) {
      handleSetErrorState(FieldNames.acceptedTermsOfUse, true);
      isValid = false;
      errorSections.push({ name: FieldNames.stepSix });
    } else {
      handleSetErrorState(FieldNames.acceptedTermsOfUse, false);
    }
    if (!ValidateEmail(reducerState.order.stepperFourState.contactEmail)) {
      handleSetErrorState(FieldNames.contactEmail, true);
      isValid = false;
      errorSections.push({ name: FieldNames.stepFour });
    } else {
      handleSetErrorState(FieldNames.contactEmail, false);
    }
    if (!reducerState.order.stepperThreeState.acceptedPDFPreview && reducerState.order.stepperThreeState.sendBy == SendBy.EmailPDF) {
      handleSetErrorState(FieldNames.acceptedPDFPreview, true);
      isValid = false;
      errorSections.push({ name: FieldNames.stepThree });
    } else {
      handleSetErrorState(FieldNames.acceptedPDFPreview, false);
    }
    if (
      (reducerState.order.stepperThreeState.physicalCardSelectedCoverImage?.imageId == undefined ||
        reducerState.order.stepperThreeState.physicalCardSelectedCoverImage?.imageId == "") &&
      reducerState.order.stepperThreeState.sendBy == SendBy.PhysicalCard
    ) {
      handleSetErrorState(FieldNames.physicalCardSelectedCoverImage, true);
      isValid = false;
      errorSections.push({ name: FieldNames.stepThree });
    } else {
      handleSetErrorState(FieldNames.physicalCardSelectedCoverImage, false);
    }

    if (
      reducerState.order.stepperThreeState.sendBy == SendBy.PhysicalCard &&
      (reducerState.order.stepperFiveState.amountOfMailings <= 0 ||
        reducerState.order.stepperFiveState.amountOfMailings == undefined ||
        reducerState.order.stepperFiveState.amountOfMailings > AmountOfMailingsLimit)
    ) {
      handleSetErrorState(FieldNames.amountOfMailings, true);
      isValid = false;
      errorSections.push({ name: FieldNames.stepFive });
    } else {
      handleSetErrorState(FieldNames.amountOfMailings, false);
    }

    if (reducerState.order.stepperFourState.marking?.length > 30) {
      handleSetErrorState(FieldNames.marking, true);
      isValid = false;
      errorSections.push({ name: FieldNames.stepFour });
    } else {
      handleSetErrorState(FieldNames.marking, false);
    }

    if (
      reducerState.order.stepperThreeState.sendBy == SendBy.PhysicalCard &&
      (reducerState.order.stepperFiveState.name == undefined ||
        reducerState.order.stepperFiveState.name?.length <= 0 ||
        reducerState.order.stepperFiveState.name?.length > 30)
    ) {
      handleSetErrorState(FieldNames.name, true);
      isValid = false;
      errorSections.push({ name: FieldNames.stepFive });
    } else {
      handleSetErrorState(FieldNames.name, false);
    }

    if (
      reducerState.order.stepperThreeState.sendBy == SendBy.PhysicalCard &&
      (reducerState.order.stepperFiveState.addressLine == undefined ||
        reducerState.order.stepperFiveState.addressLine?.length <= 0 ||
        reducerState.order.stepperFiveState.addressLine?.length > 30)
    ) {
      handleSetErrorState(FieldNames.addressLine, true);
      isValid = false;
      errorSections.push({ name: FieldNames.stepFive });
    } else {
      handleSetErrorState(FieldNames.addressLine, false);
    }

    if (
      reducerState.order.stepperThreeState.sendBy == SendBy.PhysicalCard &&
      (reducerState.order.stepperFiveState.postalCode == undefined ||
        reducerState.order.stepperFiveState.postalCode?.length <= 0 ||
        reducerState.order.stepperFiveState.postalCode?.length > 30)
    ) {
      handleSetErrorState(FieldNames.postalCode, true);
      isValid = false;
      errorSections.push({ name: FieldNames.stepFive });
    } else {
      handleSetErrorState(FieldNames.postalCode, false);
    }

    if (
      reducerState.order.stepperThreeState.sendBy == SendBy.PhysicalCard &&
      (reducerState.order.stepperFiveState.city == undefined ||
        reducerState.order.stepperFiveState.city?.length <= 0 ||
        reducerState.order.stepperFiveState.city?.length > 30)
    ) {
      handleSetErrorState(FieldNames.city, true);
      isValid = false;
      errorSections.push({ name: FieldNames.stepFive });
    } else {
      handleSetErrorState(FieldNames.city, false);
    }

    if (
      reducerState.order.stepperThreeState.sendBy == SendBy.PhysicalCard &&
      (reducerState.order.stepperFiveState.country == undefined || reducerState.order.stepperFiveState.country <= 0)
    ) {
      handleSetErrorState(FieldNames.country, true);
      isValid = false;
      errorSections.push({ name: FieldNames.stepFive });
    } else {
      handleSetErrorState(FieldNames.country, false);
    }

    dispatch(
      OrderActions.SetStepperStateRequest({
        fieldName: FieldNames.errorSections,
        value: errorSections,
      })
    );

    if (!isValid) {
      dispatch(SnackbarActions.ShowError(translate(LabelKeys.ValidationError)));
    }

    return isValid;
  }

  function ValidateAllFieldsPrivate(dataObjects: ReceiverDataObjects): boolean {
    let isValid: boolean = true;
    let errorSections: IErrorStep[] = [];
    if (reducerState.order.stepperOneState.selectedProductCategory?.id === undefined) {
      handleSetErrorState(FieldNames.selectedProductCategory, true);
      isValid = false;
      errorSections.push({ name: FieldNames.stepOne });
    } else {
      handleSetErrorState(FieldNames.selectedProductCategory, false);
    }

    if (reducerState.order.stepperTwoState.selectedProduct?.id === undefined) {
      handleSetErrorState(FieldNames.selectedProduct, true);
      isValid = false;
      errorSections.push({ name: FieldNames.stepTwo });
    } else {
      handleSetErrorState(FieldNames.selectedProduct, false);
    }

    if (
      reducerState.order.stepperOneState.selectedProductCategory?.id === undefined ||
      reducerState.order.stepperOneState.selectedProductCategory?.id === 0
    ) {
      handleSetErrorState(FieldNames.selectedProductCategory, true);
      isValid = false;
      errorSections.push({ name: FieldNames.stepOne });
    } else {
      handleSetErrorState(FieldNames.selectedProductCategory, false);
    }

    if (
      reducerState.order.stepperTwoState.selectedProduct?.id === undefined ||
      reducerState.order.stepperTwoState.selectedProduct?.id === 0
    ) {
      handleSetErrorState(FieldNames.selectedProduct, true);
      isValid = false;
      errorSections.push({ name: FieldNames.stepTwo });
    } else {
      handleSetErrorState(FieldNames.selectedProduct, false);
    }

    if (
      reducerState.order.stepperThreeState.sendBy == SendBy.SMS &&
      (dataObjects.receiverPhoneNumbers?.length <= 0 || !dataObjects.valid)
    ) {
      handleSetErrorState(FieldNames.receiverPhoneNumbers, true);
      isValid = false;
      errorSections.push({ name: FieldNames.stepFive });
    } else {
      handleSetErrorState(FieldNames.receiverPhoneNumbers, false);
    }

    if (reducerState.order.stepperThreeState.sendBy == SendBy.Email && (dataObjects.receiverEmails?.length <= 0 || !dataObjects.valid)) {
      handleSetErrorState(
        reducerState.order.stepperThreeState.sendBy == SendBy.Email ? FieldNames.receiverEmails : FieldNames.receiverEmailsPDF,
        true
      );
      isValid = false;
      errorSections.push({ name: FieldNames.stepFive });
    } else {
      handleSetErrorState(
        reducerState.order.stepperThreeState.sendBy == SendBy.Email ? FieldNames.receiverEmails : FieldNames.receiverEmailsPDF,
        false
      );
    }

    if (
      reducerState.order.stepperThreeState.sendBy == SendBy.EmailPDF &&
      reducerState.order.stepperFiveState.sendToSameReceiver &&
      (!reducerState.order.stepperFiveState.amountOfMailings ||
        reducerState.order.stepperFiveState.amountOfMailings <= 0 ||
        reducerState.order.stepperFiveState.amountOfMailings > AmountOfMailingsLimit)
    ) {
      handleSetErrorState(FieldNames.amountOfMailings, true);
      isValid = false;
      errorSections.push({ name: FieldNames.stepFive });
    } else {
      handleSetErrorState(FieldNames.amountOfMailings, false);
    }
    if (!ValidateEmail(reducerState.order.stepperFourState.contactEmail)) {
      handleSetErrorState(FieldNames.contactEmail, true);
      isValid = false;
      errorSections.push({ name: FieldNames.stepFour });
    } else {
      handleSetErrorState(FieldNames.contactEmail, false);
    }
    if (!reducerState.order.stepperSixState.acceptedTermsOfUse) {
      handleSetErrorState(FieldNames.acceptedTermsOfUse, true);
      isValid = false;
      errorSections.push({ name: FieldNames.stepSix });
    } else {
      handleSetErrorState(FieldNames.acceptedTermsOfUse, false);
    }
    if (!reducerState.order.stepperThreeState.acceptedPDFPreview && reducerState.order.stepperThreeState.sendBy == SendBy.EmailPDF) {
      handleSetErrorState(FieldNames.acceptedPDFPreview, true);
      isValid = false;
      errorSections.push({ name: FieldNames.stepThree });
    } else {
      handleSetErrorState(FieldNames.acceptedPDFPreview, false);
    }

    dispatch(
      OrderActions.SetStepperStateRequest({
        fieldName: FieldNames.errorSections,
        value: errorSections,
      })
    );

    if (!isValid) {
      dispatch(SnackbarActions.ShowError(translate(LabelKeys.ValidationError)));
    }

    return isValid;
  }

  function handlePayOrderClicked() {
    const dataObjects = ValidateAndGetReceiverDataObjects(
      reducerState.order.stepperThreeState.sendBy,
      reducerState.order.stepperThreeState.sendBy == SendBy.SMS
        ? reducerState.order.stepperFiveState.receiverPhoneNumbers
        : reducerState.order.stepperFiveState.receiverEmails
    );

    let isFieldsValid: boolean = false;

    if (reducerState.order.environmentState.systemPart == SystemPart.Private && !isSystemAdmin && !isResellerAdmin) {
      isFieldsValid = ValidateAllFieldsPrivate(dataObjects);
      if (!isFieldsValid) return;

      dispatch(
        OrderActions.CreatePrivateOrderRequest({
          senderEmail: reducerState.order.stepperFourState.contactEmail,
          receiverPhoneNumbers: reducerState.order.stepperThreeState.sendBy == SendBy.SMS ? dataObjects.receiverPhoneNumbers : undefined,
          receiverEmails: reducerState.order.stepperThreeState.sendBy == SendBy.Email ? dataObjects.receiverEmails : undefined,
          productId: reducerState.order.stepperTwoState.selectedProduct.id,
          orderDate:
            reducerState.order.stepperFiveState.whenToSend === WhenToSend.Now ? undefined : reducerState.order.stepperFiveState.orderDate,
          message:
            reducerState.order.stepperThreeState.sendBy == SendBy.SMS
              ? reducerState.order.stepperThreeState.smsMessage
              : reducerState.order.stepperThreeState.emailMessage,
          emailSubject: reducerState.order.stepperThreeState.emailSubject,
          customMessage: reducerState.order.stepperThreeState.customMessage,
          paymentMethod: reducerState.order.stepperSixState.paymentMethod,
          includeFreight: reducerState.order.stepperOneState.selectedProductCategory.category == ProductCategory.GiftCardGMG ? true : false,
          freightProductId: reducerState.order.stepperFiveState.includeFreight === true ? GetFreightProduct()?.id : undefined,
          language: LanguageCode.SE,
          sendNow: reducerState.order.stepperFiveState.whenToSend === WhenToSend.Now,
          selectedImageId: reducerState.order.stepperThreeState.selectedImage.imageId,
          customImageFile: reducerState.order.stepperThreeState.uploadedImage?.base64,
          customImageFileType: reducerState.order.stepperThreeState.uploadedImage.type,
          sendBy: reducerState.order.stepperThreeState.sendBy,
        } as ICreatePrivateOrderRequest)
      );
    } else if (reducerState.order.environmentState.systemPart == SystemPart.Company && !isSystemAdmin && !isResellerAdmin) {
      isFieldsValid = ValidateAllFieldsCompany(dataObjects);
      if (!isFieldsValid) return;

      dispatch(
        OrderActions.CreateCompanyOrderRequest({
          organisationNumber: reducerState.order.stepperFourState.organisationNumber,
          companyName: reducerState.order.stepperFourState.companyName,
          companyResellerId: reducerState.reseller.activeReseller?.id,
          contactResellerName: reducerState.order.stepperFourState.contactResellerName,
          contactEmail: reducerState.order.stepperFourState.contactEmail,
          customWebPage: false, //reducerState.order.stepperThreeState.customWebPage,
          marking: reducerState.order.stepperFourState.marking,
          customWebPageProductId: undefined,
          // reducerState.order.stepperThreeState.customWebPage === true && systemPart == SystemPart.Company
          //   ? reducerState.product.products?.find((x) => x.category === ProductCategory.CustomWebPage)?.id
          //   : undefined,
          companyContactPhoneNumber: reducerState.order.stepperFourState.companyContactPhoneNumber,
          receiverPhoneNumbers: reducerState.order.stepperThreeState.sendBy == SendBy.SMS ? dataObjects.receiverPhoneNumbers : undefined,
          receiverEmails:
            reducerState.order.stepperThreeState.sendBy == SendBy.Email || reducerState.order.stepperThreeState.sendBy == SendBy.EmailPDF
              ? dataObjects.receiverEmails
              : undefined,
          productId: reducerState.order.stepperTwoState.selectedProduct.id,
          message:
            reducerState.order.stepperThreeState.sendBy == SendBy.SMS
              ? reducerState.order.stepperThreeState.smsMessage
              : reducerState.order.stepperThreeState.sendBy == SendBy.Email
              ? reducerState.order.stepperThreeState.emailMessage
              : reducerState.order.stepperThreeState.emailPDFMessage,
          orderDate:
            reducerState.order.stepperFiveState.whenToSend === WhenToSend.Now ? undefined : reducerState.order.stepperFiveState.orderDate,
          emailSubject: reducerState.order.stepperThreeState.emailSubject,
          customMessage: reducerState.order.stepperThreeState.customMessage,
          paymentMethod: reducerState.order.stepperSixState.paymentMethod,
          includeFreight: true,
          freightProductId: reducerState.product.products.find((x: any) => x.category === ProductCategory.Freight).id,
          language: LanguageCode.SE,
          sendNow: reducerState.order.stepperFiveState.whenToSend === WhenToSend.Now,
          selectedImageId: reducerState.order.stepperThreeState.selectedImage.imageId,
          customImageFile: reducerState.order.stepperThreeState.uploadedImage.base64,
          customImageFileType: reducerState.order.stepperThreeState.uploadedImage.type,
          sendBy: reducerState.order.stepperThreeState.sendBy,
          amountOfMailings:
            reducerState.order.stepperFiveState.sendToSameReceiver || reducerState.order.stepperThreeState.sendBy == SendBy.PhysicalCard
              ? reducerState.order.stepperFiveState.amountOfMailings
              : undefined,
          sendToSameReceiver: reducerState.order.stepperFiveState.sendToSameReceiver,
          senderEmail: reducerState.order.stepperFourState.senderEmail,
          physicalCard: {
            coverImageFile:
              reducerState.order.stepperThreeState.physicalCardSelectedCoverImage?.imageId != undefined
                ? reducerState.order.stepperThreeState.physicalCardSelectedCoverImage?.imageId
                : reducerState.order.stepperThreeState.physicalCardSelectedUploadedCoverImage?.base64,
            coverImageFileType:
              reducerState.order.stepperThreeState.physicalCardSelectedCoverImage?.imageId != undefined
                ? FileType.unknown
                : reducerState.order.stepperThreeState.physicalCardSelectedUploadedCoverImage?.type,
            logotypeFile:
              reducerState.order.stepperThreeState.physicalCardSelectedUploadedLogotype?.base64 ??
              reducerState.order.stepperThreeState.physicalCardSelectedUploadedLogotype?.base64,
            logotypeFileType:
              reducerState.order.stepperThreeState.physicalCardSelectedUploadedLogotype?.base64?.length <= 0
                ? FileType.unknown
                : reducerState.order.stepperThreeState.physicalCardSelectedUploadedLogotype?.type,
            header: reducerState.order.stepperThreeState.physicalCardHeading,
            body: reducerState.order.stepperThreeState.physicalCardCustomMessage,
            name: reducerState.order.stepperFiveState.name,
            addressLine1: reducerState.order.stepperFiveState.addressLine,
            postalCode: reducerState.order.stepperFiveState.postalCode,
            city: reducerState.order.stepperFiveState.city,
            country: reducerState.order.stepperFiveState.country,
            deliveryCustomerContactName: reducerState.order.stepperFiveState.deliveryCustomerContactName,
          } as IPhysicalCard,
        })
      );
    } else if (isSystemAdmin || isResellerAdmin) {
      isFieldsValid = ValidateAllFieldsAdmin(dataObjects);
      if (!isFieldsValid) return;

      dispatch(
        OrderActions.CreateAdminOrderRequest({
          contactEmail: reducerState.order.stepperFourState.contactEmail,
          marking: reducerState.order.stepperFourState.marking,
          senderResellerId: isSystemAdmin ? reducerState.reseller.adminSelectedResellerOption.id : undefined,
          receiverPhoneNumbers: reducerState.order.stepperThreeState.sendBy == SendBy.SMS ? dataObjects.receiverPhoneNumbers : undefined,
          receiverEmails:
            reducerState.order.stepperThreeState.sendBy == SendBy.Email || reducerState.order.stepperThreeState.sendBy == SendBy.EmailPDF
              ? dataObjects.receiverEmails
              : undefined,
          productId: reducerState.order.stepperTwoState.selectedProduct.id,
          message:
            reducerState.order.stepperThreeState.sendBy == SendBy.SMS
              ? reducerState.order.stepperThreeState.smsMessage
              : reducerState.order.stepperThreeState.sendBy == SendBy.Email
              ? reducerState.order.stepperThreeState.emailMessage
              : reducerState.order.stepperThreeState.emailPDFMessage,
          orderDate:
            reducerState.order.stepperFiveState.whenToSend === WhenToSend.Now ? undefined : reducerState.order.stepperFiveState.orderDate,
          emailSubject: reducerState.order.stepperThreeState.emailSubject,
          customMessage: reducerState.order.stepperThreeState.customMessage,
          paymentMethod: PaymentMethod.Invoice,
          includeFreight: true,
          freightProductId: reducerState.product.products.find((x: any) => x.category === ProductCategory.Freight).id,
          language: GetLanguage(),
          sendNow: reducerState.order.stepperFiveState.whenToSend === WhenToSend.Now,
          selectedImageId: reducerState.order.stepperThreeState.selectedImage.imageId,
          customImageFile: reducerState.order.stepperThreeState.uploadedImage.base64,
          customImageFileType: reducerState.order.stepperThreeState.uploadedImage.type,
          sendBy: reducerState.order.stepperThreeState.sendBy,
          amountOfMailings:
            reducerState.order.stepperFiveState.sendToSameReceiver || reducerState.order.stepperThreeState.sendBy == SendBy.PhysicalCard
              ? reducerState.order.stepperFiveState.amountOfMailings
              : undefined,
          sendToSameReceiver: reducerState.order.stepperFiveState.sendToSameReceiver,
          physicalCard: {
            coverImageFile:
              reducerState.order.stepperThreeState.physicalCardSelectedCoverImage?.imageId != undefined
                ? reducerState.order.stepperThreeState.physicalCardSelectedCoverImage?.imageId
                : reducerState.order.stepperThreeState.physicalCardSelectedUploadedCoverImage?.base64,
            coverImageFileType:
              reducerState.order.stepperThreeState.physicalCardSelectedCoverImage?.imageId != undefined
                ? FileType.unknown
                : reducerState.order.stepperThreeState.physicalCardSelectedUploadedCoverImage.type,
            logotypeFile:
              reducerState.order.stepperThreeState.physicalCardSelectedUploadedLogotype?.base64 ??
              reducerState.order.stepperThreeState.physicalCardSelectedUploadedLogotype?.base64,
            logotypeFileType:
              reducerState.order.stepperThreeState.physicalCardSelectedUploadedLogotype?.base64?.length <= 0
                ? FileType.unknown
                : reducerState.order.stepperThreeState.physicalCardSelectedUploadedLogotype?.type,
            header: reducerState.order.stepperThreeState.physicalCardHeading,
            body: reducerState.order.stepperThreeState.physicalCardCustomMessage,
            name: reducerState.order.stepperFiveState.name,
            addressLine1: reducerState.order.stepperFiveState.addressLine,
            postalCode: reducerState.order.stepperFiveState.postalCode,
            city: reducerState.order.stepperFiveState.city,
            country: reducerState.order.stepperFiveState.country,
            deliveryCustomerContactName: reducerState.order.stepperFiveState.deliveryCustomerContactName,
          } as IPhysicalCard,
        })
      );
    }
  }

  return (
    <Grid container item justifyContent="center" xs={12} sm={12} md={12} lg={9} xl={9} style={{ margin: "auto" }} className={classes.root}>
      {(reducerState.order?.isLoading || reducerState.product?.isLoading) && <ProcessCircle />}
      {(isLg || isXl) && (
        <Grid item xs={10} sm={2} md={2} lg={2} xl={2} justifyContent="center">
          <CSStepper
            errorSections={reducerState.order.stepperState.errorSections}
            activeStep={reducerState.order.stepperState.activeStep}
            onStateChanged={(stateName, value, fieldName) => handleFieldOnChange(stateName, value, fieldName)}
          />
        </Grid>
      )}
      <Grid item container xs={12} sm={10} md={10} lg={10} xl={10} justifyContent="center">
        {reducerState.order.stepperState.activeStep === 0 && (
          <Grid item xs={11} sm={12} md={12} lg={12} xl={12}>
            {/* Korttyp, kategori */}
            <CSStepperStepOne
              id="#category"
              selectedProductCategoryHasError={errorState.selectedProductCategory}
              onStateChanged={(stateName, value, fieldName) => {
                handleFieldOnChange(stateName, value, fieldName);
                handleFieldOnChange(StateNames.stepperState, 1, FieldNames.activeStep);
              }}
            />
          </Grid>
        )}
        {reducerState.order.stepperState.activeStep === 1 && (
          <Grid id="#price" item xs={11} sm={12} md={12} lg={12} xl={12} justifyContent="center">
            {/* Belopp */}
            <CSStepperStepTwo
              id="productPriceComponent"
              options={reducerState.product.products}
              selectedProductHasError={errorState.selectedProduct}
              systemPart={reducerState.order.environmentState.systemPart}
              onStateChanged={(stateName, value, fieldName) => {
                handleFieldOnChange(stateName, value, fieldName);
              }}
            />
          </Grid>
        )}
        {reducerState.order.stepperState.activeStep === 2 && (
          <Grid id="#design" item xs={11} sm={12} md={12} lg={12} xl={12}>
            {/* Design */}
            <CSStepperStepThree
              id="designComponent"
              sendBy={reducerState.order.stepperThreeState.sendBy}
              smsMessage={reducerState.order.stepperThreeState.smsMessage}
              emailMessage={reducerState.order.stepperThreeState.emailMessage}
              orderDate={reducerState.order.stepperFiveState.orderDate}
              emailPDFMessage={reducerState.order.stepperThreeState.emailPDFMessage}
              selectedMessageTemplate={reducerState.order.stepperThreeState.selectedMessageTemplate}
              messageTemplates={reducerState.order.stepperThreeState.messageTemplates}
              selectedImage={reducerState.order.stepperThreeState.selectedImage}
              uploadedImage={reducerState.order.stepperThreeState.uploadedImage}
              customMessage={reducerState.order.stepperThreeState.customMessage}
              customWebPage={reducerState.order.stepperThreeState.customWebPage}
              acceptedPDFPreview={reducerState.order.stepperThreeState.acceptedPDFPreview}
              messageSignature={reducerState.order.stepperThreeState.messageSignature}
              emailSubject={reducerState.order.stepperThreeState.emailSubject}
              systemPart={reducerState.order.environmentState.systemPart}
              selectedProduct={reducerState.order.stepperTwoState.selectedProduct}
              physicalCardSelectedCoverImage={reducerState.order.stepperThreeState.physicalCardSelectedCoverImage}
              physicalCardMiddlePageImage={reducerState.order.stepperTwoState.selectedProduct.physicalCardInsideImageId}
              physicalCardSelectedUploadedCoverImage={reducerState.order.stepperThreeState.physicalCardSelectedUploadedCoverImage}
              physicalCardSelectedUploadedLogotype={reducerState.order.stepperThreeState.physicalCardSelectedUploadedLogotype}
              physicalCardHeading={reducerState.order.stepperThreeState.physicalCardHeading}
              physicalCardCustomMessage={reducerState.order.stepperThreeState.physicalCardCustomMessage}
              onStateChanged={(stateName, value, fieldName) => handleFieldOnChange(stateName, value, fieldName)}
              acceptedPDFPreviewHasError={errorState.acceptedPDFPreview}
              physicalCardCoverImageHasError={errorState.physicalCardSelectedCoverImage}
            />
          </Grid>
        )}
        {reducerState.order.stepperState.activeStep === 3 && (
          <Grid id="#sender" item xs={11} sm={12} md={12} lg={12} xl={12} justifyContent="center">
            {/* Avsändare */}
            <CSStepperStepFour
              id="companySenderInformationComponent"
              companyName={reducerState.order.stepperFourState.companyName}
              companyContactPhoneNumber={reducerState.order.stepperFourState.companyContactPhoneNumber}
              organisationNumber={reducerState.order.stepperFourState.organisationNumber}
              systemPart={reducerState.order.environmentState.systemPart}
              marking={reducerState.order.stepperFourState.marking}
              contactEmail={reducerState.order.stepperFourState.contactEmail}
              senderEmail={reducerState.order.stepperFourState.senderEmail}
              isAutomaticlySet={reducerState.order.stepperFourState.isAutomaticlySet}
              contactResellerName={reducerState.order.stepperFourState.contactResellerName}
              onStateChanged={(stateName, value, fieldName) => handleFieldOnChange(stateName, value, fieldName)}
              senderEmailHasError={errorState.senderEmail}
              companyNameHasError={errorState.companyName}
              organisationNumberHasError={errorState.organisationNumber}
              contactEmailHasError={errorState.contactEmail}
              contactResellerNameHasError={errorState.contactReseller}
              companyContactPhoneNumberHasError={errorState.companyContactPhoneNumber}
              markingHasError={errorState.marking}
            />
          </Grid>
        )}
        {reducerState.order.stepperState.activeStep === 4 && (
          <Grid id="#receiver" item xs={11} sm={12} md={12} lg={12} xl={12} justifyContent="center">
            {/* Mottagare */}
            <CSStepperStepFive
              id="receiverComponent"
              receiverEmails={reducerState.order.stepperFiveState.receiverEmails}
              receiverPhoneNumbers={reducerState.order.stepperFiveState.receiverPhoneNumbers}
              includeFreight={reducerState.order.stepperFiveState.includeFreight}
              sendToSameReceiver={reducerState.order.stepperFiveState.sendToSameReceiver}
              whenToSend={reducerState.order.stepperFiveState.whenToSend}
              amountOfMailings={reducerState.order.stepperFiveState.amountOfMailings}
              sendBy={reducerState.order.stepperThreeState.sendBy}
              systemPart={reducerState.order.environmentState.systemPart}
              orderDate={reducerState.order.stepperFiveState.orderDate}
              customMessage={reducerState.order.stepperThreeState.customMessage}
              frieghtProduct={reducerState.product.products.find((x: any) => x.category === ProductCategory.Freight)}
              onStateChanged={(stateName, value, fieldName) => handleFieldOnChange(stateName, value, fieldName)}
              receiverPhoneNumberHasError={errorState.receiverPhoneNumbers}
              receiverEmailsHasError={errorState.receiverEmails}
              receiverEmailsPDFHasError={errorState.receiverEmailsPDF}
              receiverEmailsPDFSendToSameReceiverHasError={errorState.receiverEmailsPDFSendToSameReceiver}
              amountOfMailingsHasError={errorState.amountOfMailings}
              name={reducerState.order.stepperFiveState.name}
              addressLine={reducerState.order.stepperFiveState.addressLine}
              postalCode={reducerState.order.stepperFiveState.postalCode}
              city={reducerState.order.stepperFiveState.city}
              country={reducerState.order.stepperFiveState.country}
              deliveryCustomerContactName={reducerState.order.stepperFiveState.deliveryCustomerContactName}
              addressLineHasError={errorState.addressLine}
              postalCodeHasError={errorState.postalCode}
              cityHasError={errorState.city}
              countryHasError={errorState.country}
              nameHasError={errorState.name}
            />
          </Grid>
        )}
        {reducerState.order.stepperState.activeStep === 5 && (
          <Grid id="#payment" item xs={11} sm={12} md={12} lg={12} xl={12} justifyContent="center">
            {/* Betalning */}
            <CSStepperStepSix
              paymentMethod={reducerState.order.stepperSixState.paymentMethod}
              systemPart={reducerState.order.environmentState.systemPart}
              rows={GetOrderSummaryRows()}
              orderDate={reducerState.order.stepperFiveState.orderDate}
              freightPrice={GetFreightProduct()?.priceIncludingVat}
              receiversAmount={GetReceiversAmount()}
              customMessage={reducerState.order.stepperThreeState.customMessage}
              acceptedTermsOfUse={reducerState.order.stepperSixState.acceptedTermsOfUse}
              includeFreight={reducerState.order.stepperFiveState.includeFreight}
              currency={reducerState.order.stepperTwoState.selectedProduct?.currency}
              selectedProduct={reducerState.order.stepperTwoState.selectedProduct}
              onStateChanged={(stateName, value, fieldName) => handleFieldOnChange(stateName, value, fieldName)}
              acceptedTermsOfUseHasError={errorState.acceptedTermsOfUse}
            />
          </Grid>
        )}
        {(isLg || isXl) && (
          <div style={{ width: "100%", marginTop: 30 }}>
            <CSNextPrevStep hideStepper={false} />
          </div>
        )}
      </Grid>
    </Grid>
  );
}
