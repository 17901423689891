import { makeStyles } from "@mui/styles";
import { theme } from "../../appTheme";

export const MailPreviewStyles = makeStyles({
  previewContainer: {
    border: "1px solid rgba(0, 0, 0, 0.25)",
    margin: "auto",
    justifyContent: "center",
    [theme.breakpoints.up("md")]: {
      boxShadow: "1000px 5px 100px rgba(0,0,0,0.2), 0 5px 50px rgba(0,0,0,0.3)",
    },
  },
  previewHeaderImg: {
    maxHeight: 400,
    backgroundPosition: "center center",
    backgroundRepeat: "no-repeat",
    objectFit: "contain",
  },
  previewMessage: {
    minHeight: 100,
    paddingBottom: 20,
    paddingLeft: 10,
    paddingRight: 10,
    [theme.breakpoints.only("xs")]: { fontSize: "0.8rem" },
    [theme.breakpoints.only("sm")]: { fontSize: "0.9rem" },
    [theme.breakpoints.only("md")]: { fontSize: "0.7rem" },
    [theme.breakpoints.only("lg")]: { fontSize: "0.8rem" },
    [theme.breakpoints.only("xl")]: { fontSize: "0.8rem" },
  },
});

export const SMSPreviewStyles = makeStyles({
  container: {
    zIndex: 1,
    [theme.breakpoints.down("sm")]: {
      height: 600,
    },
  },
  previewSMSText: {
    width: 155,
    marginRight: 43,
    zIndex: 3,
    height: 315,
    marginTop: 135,
    whiteSpace: "pre-wrap",
    wordBreak: "break-word",
    overflowY: "auto",
    position: "absolute",
    textAlign: "left",
    fontSize: "0.8rem",
    [theme.breakpoints.only("xs")]: {
      width: 150,
      marginTop: 130,
    },
    [theme.breakpoints.only("sm")]: {
      width: 150,
    },
  },
  previewBase: {
    zIndex: 2,
    margin: "auto",
    height: 550,
    [theme.breakpoints.up("sm")]: {
      backgroundSize: "contain",
      backgroundRepeat: "no-repeat",
    },
    [theme.breakpoints.down("sm")]: {
      height: 550,
      backgroundSize: "contain",
      backgroundRepeat: "no-repeat",
    },
  },
});

//CUSTOM
export const SMSCustomPreviewStyles = makeStyles({
  container: {
    zIndex: 1,
    [theme.breakpoints.down("sm")]: {
      height: 600,
    },
  },
  previewSMSText: {
    width: 155,
    marginRight: -2,
    zIndex: 3,
    height: 315,
    marginTop: 77,
    whiteSpace: "pre-wrap",
    wordBreak: "break-word",
    overflowY: "auto",
    position: "absolute",
    textAlign: "left",
    fontSize: "0.8rem",
    [theme.breakpoints.only("xs")]: {
      width: 234,
      marginTop: 78,
      height: 430,
    },
    [theme.breakpoints.only("sm")]: {
      marginTop: 99,
      marginRight: 0,
      height: 410,
      width: 233,
    },
    [theme.breakpoints.only("md")]: {
      height: 430,
      width: 235,
    },
    [theme.breakpoints.only("lg")]: { height: 430, width: 233 },
    [theme.breakpoints.only("xl")]: { height: 430, width: 233 },
  },
  previewBase: {
    margin: "auto",
    height: 550,
    [theme.breakpoints.up("sm")]: {
      backgroundSize: "contain",
      backgroundRepeat: "no-repeat",
    },
    [theme.breakpoints.down("sm")]: {
      height: 550,
      backgroundSize: "contain",
      backgroundRepeat: "no-repeat",
    },
  },
  contentBase: {
    zIndex: 2,
    display: "flex",
    width: "100%",
    marginTop: 79,
    margin: "auto",
    overflowY: "auto",
    [theme.breakpoints.only("xs")]: {
      fontSize: "0.8rem",
      width: "74.4%",
      height: 560,
      top: 1,
      left: 41.2,
    },
    [theme.breakpoints.only("sm")]: {
      fontSize: "0.9rem",
      width: "46.5%",
      height: 500,
      top: 20,
      left: 172,
    },
    [theme.breakpoints.only("md")]: {
      fontSize: "0.8rem",
      width: "70%",
      height: 550,
      top: 20,
      left: 65,
    },
    [theme.breakpoints.only("lg")]: {
      fontSize: "0.75rem",
      height: 550,
      top: 20,
    },
    [theme.breakpoints.only("xl")]: {
      fontSize: "0.8rem",
      width: "32.2%",
      height: 550,
      top: 20,
      left: 315,
    },
  },
  image: {
    [theme.breakpoints.only("xs")]: { width: "100%" },
    [theme.breakpoints.only("sm")]: { width: "100%" },
    [theme.breakpoints.only("md")]: { width: "100%" },
    [theme.breakpoints.only("lg")]: { width: "100%" },
    [theme.breakpoints.only("xl")]: { width: "100%" },
  },
  codeContainer: {
    zIndex: 2,
    height: 36,
    top: 5,
    textAlign: "center",
    margin: "auto",
    backgroundColor: "rgba(255, 255, 255, 0.5)",
    border: "2px solid transparent",
    "&:hover": {
      border: "2px solid orange",
    },
  },
  code: { lineHeight: 0.5, width: "100%", fontSize: "1rem" },
  codeClickToCopy: {
    width: "100%",
    fontSize: "0.65rem",
  },
  expireDate: {
    textAlign: "center",
    width: "100%",
    //fontWeight: 600,
    fontSize: "0.8rem",
    paddingBottom: 10,
  },
  message: {
    width: "95%",
    margin: "auto",
    whiteSpace: "pre-wrap",
    wordBreak: "break-word",
    fontSize: "0.8rem",
    textAlign: "center",
    paddingTop: 10,
  },
});

export const PDFPreviewStyles = makeStyles({
  previewContainer: {
    border: "1px solid rgba(0, 0, 0, 0.25)",

    minheight: 842,
    width: 595,
    [theme.breakpoints.up("lg")]: {
      boxShadow: "50px 10px 150px rgba(0,0,0,0.2), 0 5px 50px rgba(0,0,0,0.3)",
    },
    [theme.breakpoints.only("md")]: {
      float: "left",
    },
  },
  mailPDFHeaderImage: {
    objectFit: "cover",
    height: 240,
  },
  mailPDFBodyImage: {
    objectFit: "fill",
    height: 350,
  },
  mailPDFUpperBodyTextContainer: {
    paddingTop: 5,
    minHeight: 150,
    maxHeight: 220,
    lineHeight: 1.5,
    overflow: "hidden",
    textAlign: "justify",
  },
  mailPDFLowerBodyTextContainer: {
    paddingTop: 5,
    minHeight: 80,
    lineHeight: "1.6",
    overflow: "hidden",
    textAlign: "justify",
  },
  mailPDFUpperBodyText: {
    width: "50%",
    paddingTop: 10,
    margin: "auto",
    whiteSpace: "pre-wrap",
    wordBreak: "break-word",
    textAlign: "justify",
    fontSize: "0.5rem",
  },
  mailPDFLowerBodyText: {
    width: "50%",
    paddingTop: 10,
    margin: "auto",
    whiteSpace: "pre-wrap",
    wordBreak: "break-word",
    textAlign: "justify",
    fontSize: "0.5rem",
    borderTop: "1px solid black",
  },
  mailPDFGiftCardCodeContainer: {
    margin: "auto",
    width: "50%",
    textAlign: "center",
    height: 28,
  },
  mailPDFGiftCardCodeInner: {
    borderTop: "1px solid black",
    borderBottom: "1px solid black",
    fontSize: "0.6rem",
    paddingTop: 5,
    paddingBottom: 5,
  },
  mailPDFQRCodeContainer: {
    width: "50%",
    margin: "0px auto",
    right: "50%",
    left: "50%",
    height: 70,
    paddingTop: 5,
  },
  qrCodeText: {
    width: 300,
    wordWrap: "break-word",
    fontSize: "0.5rem",
    paddingLeft: 5,
    float: "left",
    textAlign: "left",
  },
  qrCodeImageContainer: { width: 70, height: 70, float: "right" },
  qrCodeImage: {
    padding: 3,
    width: 70,
    height: 70,
  },
  link: {
    height: 20,
    textAlign: "center",
    paddingTop: 15,
    width: "50%",
    paddingBottom: 30,
    fontSize: "0.5rem",
  },
});

export const PhysicalCardPreviewStyles = makeStyles({
  previewContainer: {
    minHeight: 580,
    width: 595,
    backgroundColor: "#e3e1de",
  },
  root: {
    position: "relative",
    left: 200,
    transformStyle: "preserve-3d",
    transform: "rotateX(-10deg)",
    boxShadow: "inset 100px 20px 100px rgba(0,0,0,0.2), 0 10px 100px rgba(0,0,0,0.4)",
  },
  cardFrontContainer: {
    position: "absolute",
    backgroundColor: "#fff",
    width: 350,
    height: 494,
    overflow: "hidden",
    transformOrigin: "left",
    transform: "perspective(2500px) rotateY(-45deg) translateZ(3px)",
    transformStyle: "preserve-3d",
    zIndex: 1,
  },
  cardInsideContainer: {
    position: "absolute",
    backgroundColor: "#ccc",
    transformStyle: "preserve-3d",
    left: 11,
    width: 350,
    height: 494,
    transform: "perspective(5000px) rotateY(20deg) translateZ(-65px)",
    boxShadow: "inset 0px 0px 100px rgba(0,0,0,0.2), 10px 0px 20px rgba(0,0,0,0.3)",
    zIndex: -1,
  },
  coverImage: {
    objectFit: "cover",
    objectPosition: "center",
    width: "100%",
    height: "100%",
  },
  insideFrontPageImage: { objectFit: "cover", objectPosition: "center", width: "100%", height: "100%", filter: "brightness(80%)" },
});
