import React from "react";
import { Typography, Grid, useMediaQuery } from "@mui/material/";
import { FooterStyles } from "./styles";
import { useTranslation } from "react-i18next";
import { LabelKeys } from "../../Utils/enums/labelKeys";
import { Link, useNavigate } from "react-router-dom";
import mastercard_logo from "../../Utils/Images/PaymentLogos/mastercard_logo.svg";
import swish_logo from "../../Utils/Images/PaymentLogos/swish_logo.svg";
import visa_logo from "../../Utils/Images/PaymentLogos/visa_logo.png";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Cookies from "js-cookie";
import { CookieConstants } from "../../Utils/Labels/types";
import { theme } from "../../appTheme";
import { GetLitiumImageUrl } from "../../Utils/extensions";
import { ImageKeys } from "../../Utils/enums/imageKeys";
import { AuthenticationActions } from "../../Modules/Authentication/actions";
import { useDispatch, useSelector } from "react-redux";
import { SystemPart } from "../../Utils/enums/enums";
import { IsResellerAuth, IsAuth } from "../../Utils/authentication";
import { RootState } from "../../store";
import { GetCurrentLanguageAsText } from "../../Utils/types";

function CSFooter() {
  const classes = FooterStyles();
  const { t: translate } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isMobile = useMediaQuery(theme.breakpoints.only("xs"));
  const systemPart: SystemPart = Number(Cookies.get(CookieConstants.SystemPart));
  const isResellerConnected = Cookies.get(CookieConstants.GBVReseller)?.length > 0 && systemPart == SystemPart.Company;

  const reducerState = useSelector(
    (s: RootState) => {
      return {
        reseller: s.reseller,
      };
    },
    (prev, next) => prev.reseller.coupledResellerLogotype === next.reseller.coupledResellerLogotype
  );

  const isAdmin =
    (IsResellerAuth() && location.pathname == `/${translate(LabelKeys.ResellerUrl)}/${translate(LabelKeys.AdminUrl)}`) ||
    (IsAuth() && location.pathname == `/${translate(LabelKeys.AdminUrl)}`);

  function GetSystemPartLabel(): React.ReactNode {
    var type = Number(Cookies.get(CookieConstants.SystemPart));
    if (type === SystemPart.Private) return translate(LabelKeys.Private);
    else {
      return translate(LabelKeys.Company);
    }
  }

  return (
    <Grid container justifyContent="center" className={classes.footer} style={{ marginTop: isAdmin ? 0 : 30 }}>
      <Grid
        container
        item
        xs={10}
        sm={9}
        md={9}
        lg={9}
        xl={9}
        style={{
          paddingTop: 20,
          textAlign: "left",
        }}
      >
        <Grid container item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Grid item xs={6} sm={3} md={3} lg={3} xl={3}>
            <img
              onClick={() => navigate(`/${GetCurrentLanguageAsText()}/`)}
              height={isMobile ? 75 : 90}
              width={isMobile ? 150 : 180}
              style={{ cursor: "pointer", objectFit: "contain", objectPosition: "center" }}
              src={
                reducerState.reseller.coupledResellerLogotype?.length > 0 &&
                Cookies.get(CookieConstants.GBVReseller) != undefined &&
                (systemPart == SystemPart.Company || isAdmin)
                  ? reducerState.reseller.coupledResellerLogotype
                  : GetLitiumImageUrl(ImageKeys.WhiteLogo, "")
              }
            />
          </Grid>
          <Grid
            container
            item
            xs={6}
            sm={6}
            md={6}
            lg={6}
            xl={6}
            style={{ paddingTop: 10 }}
            justifyContent={isMobile ? "flex-start" : "center"}
            order={isMobile ? 1 : ""}
          >
            <Link
              className={classes.links}
              to={`/${GetCurrentLanguageAsText()}/${translate(LabelKeys.HowItWorksUrl)}`}
              style={{ paddingRight: isMobile ? 50 : "" }}
            >
              {translate(LabelKeys.HowItWorks)}
            </Link>
            <a
              className={classes.links}
              style={{
                color: "#000",
                textDecoration: "none",
                fontSize: "1rem",
                fontFamily: "mulish",
                cursor: "pointer",
              }}
              onClick={() =>
                Cookies.get(CookieConstants.GBVReseller)?.length > 0 && systemPart == SystemPart.Company
                  ? navigate(`/${GetCurrentLanguageAsText()}/${translate(LabelKeys.FAQUrl)}`)
                  : window.open("https://support.giftsbyvinga.com/hc/sv", "_blank")
              }
              target="_blank"
              rel="noopener noreferrer"
            >
              {translate(LabelKeys.FAQ)}
            </a>
            <Link
              className={classes.links}
              to={`/${GetCurrentLanguageAsText()}/${translate(LabelKeys.TermsOfUseUrl)}`}
              style={{ paddingRight: isMobile ? 30 : "" }}
            >
              {translate(LabelKeys.TermsOfUseFull)}
            </Link>
            {isResellerConnected && (
              <Link className={classes.links} to={`/${GetCurrentLanguageAsText()}/${translate(LabelKeys.ContactUrl)}`}>
                {translate(LabelKeys.Contact)}
              </Link>
            )}
            {!isResellerConnected && (
              <a
                className={classes.links}
                style={{
                  color: "#000",
                  textDecoration: "none",
                  fontSize: "1rem",
                  fontFamily: "mulish",
                }}
                href="https://support.giftsbyvinga.com/hc/sv/requests/new"
                target="_blank"
                rel="noopener noreferrer"
              >
                {translate(LabelKeys.Contact)}
              </a>
            )}
          </Grid>
          <Grid item xs={6} sm={3} md={3} lg={3} xl={3} style={{ display: "flex" }} justifyContent={"flex-end"}>
            {(Cookies.get(CookieConstants.GBVReseller) == undefined || Cookies.get(CookieConstants.GBVReseller)?.length == 0) && (
              <div
                style={{
                  display: "flex",
                  border: "1px solid #000",
                  height: 48,
                  width: 125,
                  marginTop: 10,
                  right: 57,
                  cursor: "pointer",
                }}
                onClick={() => dispatch(AuthenticationActions.SetIsSystemPartDialogOpenRequest(true))}
              >
                <div style={{ margin: "auto", display: "flex" }}>
                  <div>
                    <Typography color="#000" style={{ fontFamily: "Mulish" }}>
                      {GetSystemPartLabel()}
                    </Typography>
                  </div>
                  <div style={{ paddingTop: 4, paddingLeft: 3 }}>
                    <KeyboardArrowDownIcon style={{ color: "#000" }} fontSize="small" />
                  </div>
                </div>
              </div>
            )}
          </Grid>
        </Grid>
        {!isAdmin && (Cookies.get(CookieConstants.GBVReseller) == undefined || Cookies.get(CookieConstants.GBVReseller)?.length == 0) && (
          <Grid container item xs={12} sm={12} md={12} lg={12} xl={12} alignItems="center" justifyContent="center">
            <Grid item xs={6} sm={3} md={2} lg={1} xl={1} className={classes.paymentMethods}>
              <img src={swish_logo} height={"30"} style={{ display: "block", margin: "auto" }} />
            </Grid>
            <Grid item xs={6} sm={2} md={2} lg={1} xl={1} className={classes.paymentMethods}>
              <img src={mastercard_logo} height={"50"} style={{ display: "block", margin: "auto" }} />
            </Grid>
            <Grid item xs={6} sm={2} md={2} lg={1} xl={1} className={classes.paymentMethods}>
              <img src={visa_logo} height={"30"} style={{ display: "block", margin: "auto" }} />
            </Grid>
          </Grid>
        )}
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} justifyContent="center" className={classes.copyrightText}>
          <Typography variant="body1">Copyright © {new Date().getFullYear()} - Vinga of Sweden AB</Typography>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default CSFooter;
