import * as React from "react";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import { IFilterOption as IFilterOption } from "./types";
import { useEffect, useState } from "react";
import clsx from "clsx";
import { CSMSDropDownStyles } from "./styles";
import CSCheckbox from "../Input/Forms/CSCheckbox";
import { useTranslation } from "react-i18next";

interface ICSMSDropDown {
  filterOptions: IFilterOption[];
  className?: string;
  classNameRoot?: string;
  classNameInner?: string;
  size: "small" | "medium";
  placeholder?: string;
  onFilterOptionClicked: (selectedOptions: IFilterOption[]) => void;
}

export default function CSMSDropDown(props: ICSMSDropDown) {
  const [selectedItems, setSelectedItems] = useState([] as IFilterOption[]);
  const classes = CSMSDropDownStyles();
  const { t: translate } = useTranslation();

  useEffect(() => {
    if (selectedItems) props.onFilterOptionClicked(selectedItems);
  }, [selectedItems]);

  return (
    <div className={props.classNameRoot}>
      <FormControl sx={{ width: "100%" }} size={props.size} className={clsx(classes.root, props.classNameInner, props.className)}>
        <Select
          multiple
          value={selectedItems}
          displayEmpty
          MenuProps={{
            sx: { height: { xs: 250, sm: 200, md: 300, lg: 300, xl: 500 } },
          }}
          renderValue={() => {
            if (selectedItems?.length === 0) {
              return <em>{props.placeholder}</em>;
            }
            return selectedItems?.map((sel: IFilterOption) => sel.name).join(", ");
          }}
        >
          {props.filterOptions?.map((option) => (
            <MenuItem
              key={option.name}
              value={option.name}
              onClick={() => {
                if (selectedItems?.find((selectedItem) => selectedItem.value == option.value)) {
                  let newList = selectedItems?.filter((i) => i.value != option.value);
                  setSelectedItems(newList);
                } else {
                  let newObject = option;
                  newObject.checked = true;
                  let newList = selectedItems?.concat(newObject);
                  setSelectedItems(newList);
                }
              }}
            >
              <CSCheckbox
                id={`cb${option.name}`}
                className={classes.checkbox}
                checked={selectedItems.some((o) => o.name === option.name)}
              />
              <ListItemText primary={translate(option.name)} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}
