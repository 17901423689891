export interface IUploadedFile {
  name: string;
  type: FileType;
  size?: string;
  base64: string;
}

export enum FileType {
  unknown = 0,
  pdf = 1,
  excel = 2,
  jpeg = 3,
  jpg = 4,
  png = 5,
  xlsx = 6,
  webp = 7,
}
