import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import ResellerOrderDetails from "../Subviews/ResellerOrderDetails";

interface IResellerOrderDetailsPopupProps {
  onGoBackToOrderPageClicked?: (orderDetailsOpen: boolean) => void;
}

export default function ResellerOrderDetailsPopup(props: IResellerOrderDetailsPopupProps) {
  return (
    <Dialog
      id={"1"}
      open={true}
      PaperProps={{ style: { borderRadius: 0, maxWidth: "100vw" } }}
      onKeyDown={(e) => {
        if (e.key === "Escape") {
          props.onGoBackToOrderPageClicked(false);
        }
      }}
    >
      <DialogContent style={{ padding: 0 }}>
        <ResellerOrderDetails onGoBackToOrderPageClicked={(x) => props.onGoBackToOrderPageClicked(x)} />
      </DialogContent>
    </Dialog>
  );
}
