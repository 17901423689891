import makeStyles from "@mui/styles/makeStyles";
import { theme } from "../../appTheme";

export const HowItWorksStyles = makeStyles({
  "#section1": {
    height: 150,
    paddingTop: 30,
    whiteSpace: "pre-line",
    [theme.breakpoints.only("xs")]: {
      height: 80,
    },
  },
  "#section2": {
    paddingTop: 30,
    whiteSpace: "pre-line",
  },
  "#section3": {
    paddingTop: 30,
    whiteSpace: "pre-line",
  },
  "#section4": {
    paddingTop: 30,
    whiteSpace: "pre-line",
  },
  "#section5": {
    paddingTop: 30,
    whiteSpace: "pre-line",
  },
  "#section6": {
    paddingTop: 30,
    whiteSpace: "pre-line",
  },
  "#section7": {
    paddingTop: 30,
    paddingBottom: 30,
    whiteSpace: "pre-line",
  },
  howItWorksPageImage: {
    objectFit: "cover",
    height: "70vh",
    width: "100%",
  },
  imageTitleText: {
    zIndex: 2,
    position: "absolute",
    top: "70%",
    left: "50%",
    maxWidth: "80%",
    transform: "translate(-50%, -25%)",
    [theme.breakpoints.only("xs")]: {
      width: "90%",
    },
    [theme.breakpoints.only("sm")]: {
      width: "90%",
    },
    [theme.breakpoints.only("md")]: {
      width: "90%",
    },
  },
  imageBodyText: {
    textAlign: "left",
  },
});
