import { useMediaQuery, Tooltip } from "@mui/material";
import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { theme } from "../../appTheme";
import { RootState } from "../../store";
import { SignOut } from "../../Utils/authentication";
import { Environment } from "../../Utils/enums/enums";
import { LabelKeys } from "../../Utils/enums/labelKeys";
import { CookieConstants } from "../../Utils/Labels/types";
import { DEVELOPMENT_BACKEND_URL, TEST_BACKEND_URL, PRODUCTION_BACKEND_URL } from "../../Utils/network/axios/urls";
import { SidebarStyles } from "./styles";
import { AdminSubview, IAdminState } from "./types";
import LocalFireDepartmentOutlinedIcon from "@mui/icons-material/LocalFireDepartmentOutlined";
import { GetCurrentLanguageAsText } from "../../Utils/types";

interface IAdminSidebar {
  activeSubview: AdminSubview;
}

export default function AdminSidebar(props: IAdminSidebar) {
  const sideClasses = SidebarStyles();
  const { t: translate } = useTranslation();
  const isMobile = useMediaQuery(theme.breakpoints.only("xs"));
  const isSm = useMediaQuery(theme.breakpoints.only("sm"));
  const navigate = useNavigate();
  const [isActive, setIsActive] = useState(false);
  const [adminState, setAdminState] = useState({
    subview: AdminSubview.Order,
    isExpanded: false,
  } as IAdminState);

  const reducerState = useSelector(
    (s: RootState) => {
      return {
        authentication: s.authentication,
      };
    },
    (prev, next) =>
      prev.authentication.hasBeenSignedOut === next.authentication.hasBeenSignedOut &&
      prev.authentication.signedInUserEmail === next.authentication.signedInUserEmail &&
      prev.authentication.isLoading === next.authentication.isLoading
  );

  useEffect(() => {}, [props.activeSubview]);
  useEffect(() => {}, [reducerState.authentication.signedInUserEmail]);

  function GetExpandIcon() {
    return (
      <svg width="25" height="25" fill="currentColor" className="bi bi-list" viewBox="0 0 16 16" style={{ marginTop: -1 }}>
        <path
          fillRule="evenodd"
          d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"
        />
      </svg>
    );
  }

  function GetCollapseIcon() {
    return (
      <svg width="25" height="25" fill="currentColor" className="bi bi-arrow-bar-left" viewBox="0 0 16 16" style={{ marginTop: -1 }}>
        <path
          fillRule="evenodd"
          d="M12.5 15a.5.5 0 0 1-.5-.5v-13a.5.5 0 0 1 1 0v13a.5.5 0 0 1-.5.5zM10 8a.5.5 0 0 1-.5.5H3.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L3.707 7.5H9.5a.5.5 0 0 1 .5.5z"
        />
      </svg>
    );
  }

  function GetOrderIcon(): React.ReactNode {
    return (
      <svg width="20" height="20" fill="currentColor" className="bi bi-table" viewBox="0 0 16 16" style={{ paddingRight: 5 }}>
        <path d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2zm15 2h-4v3h4V4zm0 4h-4v3h4V8zm0 4h-4v3h3a1 1 0 0 0 1-1v-2zm-5 3v-3H6v3h4zm-5 0v-3H1v2a1 1 0 0 0 1 1h3zm-4-4h4V8H1v3zm0-4h4V4H1v3zm5-3v3h4V4H6zm4 4H6v3h4V8z" />
      </svg>
    );
  }
  function GetInvoiceIcon(): React.ReactNode {
    return (
      <svg width="20" height="20" fill="currentColor" className="bi bi-receipt-cutoff" viewBox="0 0 16 16" style={{ paddingRight: 5 }}>
        <path d="M3 4.5a.5.5 0 0 1 .5-.5h6a.5.5 0 1 1 0 1h-6a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h6a.5.5 0 1 1 0 1h-6a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h6a.5.5 0 1 1 0 1h-6a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 0 1h-6a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 0 1h-6a.5.5 0 0 1-.5-.5zM11.5 4a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1h-1zm0 2a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1h-1zm0 2a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1h-1zm0 2a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1h-1zm0 2a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1h-1z" />
        <path d="M2.354.646a.5.5 0 0 0-.801.13l-.5 1A.5.5 0 0 0 1 2v13H.5a.5.5 0 0 0 0 1h15a.5.5 0 0 0 0-1H15V2a.5.5 0 0 0-.053-.224l-.5-1a.5.5 0 0 0-.8-.13L13 1.293l-.646-.647a.5.5 0 0 0-.708 0L11 1.293l-.646-.647a.5.5 0 0 0-.708 0L9 1.293 8.354.646a.5.5 0 0 0-.708 0L7 1.293 6.354.646a.5.5 0 0 0-.708 0L5 1.293 4.354.646a.5.5 0 0 0-.708 0L3 1.293 2.354.646zm-.217 1.198.51.51a.5.5 0 0 0 .707 0L4 1.707l.646.647a.5.5 0 0 0 .708 0L6 1.707l.646.647a.5.5 0 0 0 .708 0L8 1.707l.646.647a.5.5 0 0 0 .708 0L10 1.707l.646.647a.5.5 0 0 0 .708 0L12 1.707l.646.647a.5.5 0 0 0 .708 0l.509-.51.137.274V15H2V2.118l.137-.274z" />
      </svg>
    );
  }
  function GetResellerIcon(): React.ReactNode {
    return (
      <svg width="20" height="20" fill="currentColor" className="bi bi-shop" viewBox="0 0 16 16" style={{ paddingRight: 5 }}>
        <path d="M2.97 1.35A1 1 0 0 1 3.73 1h8.54a1 1 0 0 1 .76.35l2.609 3.044A1.5 1.5 0 0 1 16 5.37v.255a2.375 2.375 0 0 1-4.25 1.458A2.371 2.371 0 0 1 9.875 8 2.37 2.37 0 0 1 8 7.083 2.37 2.37 0 0 1 6.125 8a2.37 2.37 0 0 1-1.875-.917A2.375 2.375 0 0 1 0 5.625V5.37a1.5 1.5 0 0 1 .361-.976l2.61-3.045zm1.78 4.275a1.375 1.375 0 0 0 2.75 0 .5.5 0 0 1 1 0 1.375 1.375 0 0 0 2.75 0 .5.5 0 0 1 1 0 1.375 1.375 0 1 0 2.75 0V5.37a.5.5 0 0 0-.12-.325L12.27 2H3.73L1.12 5.045A.5.5 0 0 0 1 5.37v.255a1.375 1.375 0 0 0 2.75 0 .5.5 0 0 1 1 0zM1.5 8.5A.5.5 0 0 1 2 9v6h1v-5a1 1 0 0 1 1-1h3a1 1 0 0 1 1 1v5h6V9a.5.5 0 0 1 1 0v6h.5a.5.5 0 0 1 0 1H.5a.5.5 0 0 1 0-1H1V9a.5.5 0 0 1 .5-.5zM4 15h3v-5H4v5zm5-5a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1h-2a1 1 0 0 1-1-1v-3zm3 0h-2v3h2v-3z" />
      </svg>
    );
  }
  function GetTemplatesIcon(): React.ReactNode {
    return (
      <svg width="20" height="20" fill="currentColor" className="bi bi-file-earmark-font" viewBox="0 0 16 16" style={{ paddingRight: 5 }}>
        <path d="M10.943 6H5.057L5 8h.5c.18-1.096.356-1.192 1.694-1.235l.293-.01v5.09c0 .47-.1.582-.898.655v.5H9.41v-.5c-.803-.073-.903-.184-.903-.654V6.755l.298.01c1.338.043 1.514.14 1.694 1.235h.5l-.057-2z" />
        <path d="M14 4.5V14a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h5.5L14 4.5zm-3 0A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V4.5h-2z" />
      </svg>
    );
  }
  function GetExternalSystemIcon(): React.ReactNode {
    return (
      <svg width="20" height="20" fill="currentColor" className="bi bi-laptop" viewBox="0 0 16 16">
        <path d="M13.5 3a.5.5 0 0 1 .5.5V11H2V3.5a.5.5 0 0 1 .5-.5h11zm-11-1A1.5 1.5 0 0 0 1 3.5V12h14V3.5A1.5 1.5 0 0 0 13.5 2h-11zM0 12.5h16a1.5 1.5 0 0 1-1.5 1.5h-13A1.5 1.5 0 0 1 0 12.5z" />
      </svg>
    );
  }
  function GetSignOutIcon(): React.ReactNode {
    return (
      <svg width="20" height="20" fill="currentColor" className="bi bi-box-arrow-right" viewBox="0 0 16 16">
        <path
          fillRule="evenodd"
          d="M10 12.5a.5.5 0 0 1-.5.5h-8a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 .5.5v2a.5.5 0 0 0 1 0v-2A1.5 1.5 0 0 0 9.5 2h-8A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h8a1.5 1.5 0 0 0 1.5-1.5v-2a.5.5 0 0 0-1 0v2z"
        />
        <path
          fillRule="evenodd"
          d="M15.854 8.354a.5.5 0 0 0 0-.708l-3-3a.5.5 0 0 0-.708.708L14.293 7.5H5.5a.5.5 0 0 0 0 1h8.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3z"
        />
      </svg>
    );
  }

  function GetHangfireUrl(): string {
    return process.env.NODE_ENV === Environment.Development
      ? DEVELOPMENT_BACKEND_URL + "hangfire?jwt=" + Cookies.get(CookieConstants.GBVJwt)
      : process.env.REACT_APP_ENVIRONMENT === Environment.Test
      ? TEST_BACKEND_URL + "hangfire?jwt=" + Cookies.get(CookieConstants.GBVJwt)
      : PRODUCTION_BACKEND_URL + "hangfire?jwt=" + Cookies.get(CookieConstants.GBVJwt);
  }

  return (
    <div
      id="sidebar"
      style={{
        width: isActive ? 175 : 50,
        height: "100vh",
        backgroundColor: "#e3e1de",
        borderRight: "1px solid rgba(45, 45, 45, 0.2)",
      }}
    >
      <div
        id="sidebarExpander"
        style={{ width: isActive ? 175 : 50, backgroundColor: "#000", color: "#fff", height: 50, cursor: "pointer" }}
        onClick={() => {
          if ((!isMobile && !isSm) || isActive) {
            setIsActive(!isActive);
          }
        }}
      >
        <div
          key={"expandSidebar"}
          onClick={() => {
            if ((!isMobile && !isSm) || isActive) {
              setIsActive(!isActive);
            }
          }}
          style={{
            width: 50,
            transform: "translateY(50%)",
            cursor: "pointer",
            color: "#e3e1de",
            float: isActive ? "right" : "inherit",
          }}
        >
          {isActive ? GetCollapseIcon() : GetExpandIcon()}
        </div>
      </div>
      <div id="content" style={{ height: "100%", width: isActive ? 175 : 50 }}>
        <div id="body">
          <div
            id="order"
            className={sideClasses.childElement}
            style={{ backgroundColor: props.activeSubview == AdminSubview.Order ? "#cccccc" : "" }}
            onClick={() => {
              navigate(`/${GetCurrentLanguageAsText()}/${translate(LabelKeys.AdminUrl)}/${translate(LabelKeys.OrderSubviewUrl)}`);
            }}
          >
            <Tooltip arrow title={translate(LabelKeys.Order)} placement={"right"}>
              <div style={{ transform: "translateY(50%)", paddingTop: 2 }}>
                {isActive ? (
                  <div style={{ paddingTop: 2, fontFamily: "Mulish", textAlign: "left", marginLeft: 20, color: "#000" }}>
                    {translate(LabelKeys.Order)}
                  </div>
                ) : (
                  GetOrderIcon()
                )}
              </div>
            </Tooltip>
          </div>
          <div
            id="invoice"
            className={sideClasses.childElement}
            style={{ backgroundColor: props.activeSubview == AdminSubview.Invoice ? "#cccccc" : "" }}
            onClick={() => {
              navigate(`/${GetCurrentLanguageAsText()}/${translate(LabelKeys.AdminUrl)}/${translate(LabelKeys.InvoiceUrl)}`);
            }}
          >
            <Tooltip arrow title={translate(LabelKeys.Invoice)} placement={"right"}>
              <div style={{ transform: "translateY(50%)", paddingTop: 2 }}>
                {isActive ? (
                  <div style={{ paddingTop: 2, fontFamily: "Mulish", textAlign: "left", marginLeft: 20, color: "#000" }}>
                    {translate(LabelKeys.Invoice)}
                  </div>
                ) : (
                  GetInvoiceIcon()
                )}
              </div>
            </Tooltip>
          </div>
          <div
            id="reseller"
            className={sideClasses.childElement}
            style={{ backgroundColor: props.activeSubview == AdminSubview.Reseller ? "#cccccc" : "" }}
            onClick={() => {
              navigate(`/${GetCurrentLanguageAsText()}/${translate(LabelKeys.AdminUrl)}/${translate(LabelKeys.ResellerUrl)}`);
            }}
          >
            <Tooltip arrow title={translate(LabelKeys.Reseller)} placement={"right"}>
              <div style={{ transform: "translateY(50%)", paddingTop: 2 }}>
                {isActive ? (
                  <div style={{ paddingTop: 2, fontFamily: "Mulish", textAlign: "left", marginLeft: 20, color: "#000" }}>
                    {translate(LabelKeys.Reseller)}
                  </div>
                ) : (
                  GetResellerIcon()
                )}
              </div>
            </Tooltip>
          </div>
          <div
            id="templates"
            className={sideClasses.childElement}
            style={{ backgroundColor: props.activeSubview == AdminSubview.Template ? "#cccccc" : "" }}
            onClick={() => {
              navigate(`/${GetCurrentLanguageAsText()}/${translate(LabelKeys.AdminUrl)}/${translate(LabelKeys.TemplateUrl)}`);
            }}
          >
            <Tooltip arrow title={translate(LabelKeys.Templates)} placement={"right"}>
              <div style={{ transform: "translateY(50%)", paddingTop: 2 }}>
                {isActive ? (
                  <div style={{ paddingTop: 2, fontFamily: "Mulish", textAlign: "left", marginLeft: 20, color: "#000" }}>
                    {translate(LabelKeys.Templates)}
                  </div>
                ) : (
                  GetTemplatesIcon()
                )}
              </div>
            </Tooltip>
          </div>
          {(reducerState.authentication.signedInUserEmail == "andreas.carlsson@vinga.com" ||
            reducerState.authentication.signedInUserEmail == "alexander.carlsson@vinga.com") && (
            <div
              id="externalSystems"
              className={sideClasses.childElement}
              style={{ backgroundColor: props.activeSubview == AdminSubview.ExternalSystem ? "#cccccc" : "" }}
              onClick={() => {
                navigate(`/${GetCurrentLanguageAsText()}/${translate(LabelKeys.AdminUrl)}/${translate(LabelKeys.ExternalSystemsUrl)}`);
              }}
            >
              <Tooltip arrow title={translate(LabelKeys.ExternalSystemTitle)} placement={"right"}>
                <div style={{ transform: "translateY(50%)", paddingTop: 2 }}>
                  {isActive ? (
                    <div style={{ paddingTop: 2, fontFamily: "Mulish", textAlign: "left", marginLeft: 20, color: "#000" }}>
                      {translate(LabelKeys.ExternalSystems)}
                    </div>
                  ) : (
                    GetExternalSystemIcon()
                  )}
                </div>
              </Tooltip>
            </div>
          )}
          <div id="hangfire" className={sideClasses.childElement}>
            <Tooltip arrow title={"Hangfire"} placement={"right"}>
              <div style={{ transform: "translateY(50%)", paddingTop: 2 }}>
                {isActive ? (
                  <div style={{ paddingTop: 2, fontFamily: "Mulish", textAlign: "left", marginLeft: 20, color: "#000" }}>
                    <a
                      style={{
                        textDecoration: "none",
                        color: "#000",
                        fontFamily: "Mulish",
                      }}
                      href={GetHangfireUrl()}
                      rel="noreferrer"
                      target={"_blank"}
                    >
                      Hangfire
                    </a>
                  </div>
                ) : (
                  <a
                    style={{
                      textDecoration: "none",
                      color: "#000",
                      fontFamily: "Mulish",
                    }}
                    href={GetHangfireUrl()}
                    rel="noreferrer"
                    target={"_blank"}
                  >
                    <LocalFireDepartmentOutlinedIcon style={{ fontSize: 20 }} />
                  </a>
                )}
              </div>
            </Tooltip>
          </div>
          <div
            id="signOut"
            className={sideClasses.childElement}
            onClick={() => {
              SignOut();
              navigate(`/${GetCurrentLanguageAsText()}/`);
            }}
          >
            <Tooltip arrow title={translate(LabelKeys.SignOut)} placement={"right"}>
              <div style={{ transform: "translateY(50%)", paddingTop: 2 }}>
                {isActive ? (
                  <div style={{ paddingTop: 2, fontFamily: "Mulish", textAlign: "left", marginLeft: 20, color: "#000" }}>
                    {translate(LabelKeys.SignOut)}
                  </div>
                ) : (
                  GetSignOutIcon()
                )}
              </div>
            </Tooltip>
          </div>
        </div>
      </div>
    </div>
  );
}
