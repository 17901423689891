export enum OrderActionTypes {
  //Create order (Privte)
  CREATE_PRIVATE_ORDER_REQUEST = "CREATE_PRIVATE_ORDER_REQUEST",
  CREATE_PRIVATE_ORDER_SUCCESS = "CREATE_PRIVATE_ORDER_SUCCESS",
  CREATE_PRIVATE_ORDER_ERROR = "CREATE_PRIVATE_ORDER_ERROR",

  //Create order (Company)
  CREATE_COMPANY_ORDER_REQUEST = "CREATE_COMPANY_ORDER_REQUEST",
  CREATE_COMPANY_ORDER_SUCCESS = "CREATE_COMPANY_ORDER_SUCCESS",
  CREATE_COMPANY_ORDER_ERROR = "CREATE_COMPANY_ORDER_ERROR",

  //Create order (Admin)
  CREATE_ADMIN_ORDER_REQUEST = "CREATE_ADMIN_ORDER_REQUEST",
  CREATE_ADMIN_ORDER_SUCCESS = "CREATE_ADMIN_ORDER_SUCCESS",
  CREATE_ADMIN_ORDER_ERROR = "CREATE_ADMIN_ORDER_ERROR",

  //Resend orders
  RESEND_ORDERS_REQUEST = "RESEND_ORDERS_REQUEST",
  RESEND_ORDERS_SUCCESS = "RESEND_ORDERS_SUCCESS",
  RESEND_ORDERS_ERROR = "RESEND_ORDERS_ERROR",

  //Refund orders
  REFUND_ORDERS_REQUEST = "REFUND_ORDERS_REQUEST",
  REFUND_ORDERS_SUCCESS = "REFUND_ORDERS_SUCCESS",
  REFUND_ORDERS_ERROR = "REFUND_ORDERS_ERROR",

  //Resend receipts
  RESEND_RECEIPTS_REQUEST = "RESEND_RECEIPTS_REQUEST",
  RESEND_RECEIPTS_SUCCESS = "RESEND_RECEIPTS_SUCCESS",
  RESEND_RECEIPTS_ERROR = "RESEND_RECEIPTS_ERROR",

  //Search single order by Id
  SEARCH_SINGLE_ORDER_REQUEST = "SEARCH_SINGLE_ORDER_REQUEST",
  SEARCH_SINGLE_ORDER_SUCCESS = "SEARCH_SINGLE_ORDER_SUCCESS",
  SEARCH_SINGLE_ORDER_ERROR = "SEARCH_SINGLE_ORDER_ERROR",

  //Search orders
  SEARCH_ORDERS_REQUEST = "SEARCH_ORDERS_REQUEST",
  SEARCH_ORDERS_SUCCESS = "SEARCH_ORDERS_SUCCESS",
  SEARCH_ORDERS_ERROR = "SEARCH_ORDERS_ERROR",

  //Update orderline
  UPDATE_ORDERLINE_REQUEST = "UPDATE_ORDERLINE_REQUEST",
  UPDATE_ORDERLINE_SUCCESS = "UPDATE_ORDERLINE_SUCCESS",
  UPDATE_ORDERLINE_ERROR = "UPDATE_ORDERLINE_ERROR",

  //Update orderline
  RESEND_ORDERLINE_REQUEST = "RESEND_ORDERLINE_REQUEST",
  RESEND_ORDERLINE_SUCCESS = "RESEND_ORDERLINE_SUCCESS",
  RESEND_ORDERLINE_ERROR = "RESEND_ORDERLINE_ERROR",

  //Get order confirmation details
  GET_ORDER_CONFIRMATION_DETAILS_REQUEST = "GET_ORDER_CONFIRMATION_DETAILS_REQUEST",
  GET_ORDER_CONFIRMATION_DETAILS_SUCCESS = "GET_ORDER_CONFIRMATION_DETAILS_SUCCESS",
  GET_ORDER_CONFIRMATION_DETAILS_ERROR = "GET_ORDER_CONFIRMATION_DETAILS_ERROR",

  //SET STEP STATE
  SET_ENVIRONMENT_STATE_REQUEST = "SET_ENVIRONMENT_STATE_REQUEST",
  SET_STEPPER_STATE_REQUEST = "SET_STEPPER_STATE_REQUEST",
  SET_STEP_ONE_STATE_REQUEST = "SET_STEP_ONE_STATE_REQUEST",
  SET_STEP_TWO_STATE_REQUEST = "SET_STEP_TWO_STATE_REQUEST",
  SET_STEP_THREE_STATE_REQUEST = "SET_STEP_THREE_STATE_REQUEST",
  SET_STEP_FOUR_STATE_REQUEST = "SET_STEP_FOUR_STATE_REQUEST",
  SET_STEP_FIVE_STATE_REQUEST = "SET_STEP_FIVE_STATE_REQUEST",
  SET_STEP_SIX_STATE_REQUEST = "SET_STEP_SIX_STATE_REQUEST",

  RESET_REDUCER = "RESET_REDUCER",
  RESET_STEP_ONE_STATE = "RESET_STEP_ONE_STATE",
  RESET_STEP_TWO_STATE = "RESET_STEP_TWO_STATE",
  RESET_STEP_THREE_STATE = "RESET_STEP_THREE_STATE",
  RESET_STEP_FOUR_STATE = "RESET_STEP_FOUR_STATE",
  RESET_STEP_FIVE_STATE = "RESET_STEP_FIVE_STATE",
  RESET_STEP_SIX_STATE = "RESET_STEP_SIX_STATE",
  RESET_STEPPER_STATE = "RESET_STEPPER_STATE",

  MARK_ORDER_AS_DONE_REQUEST = "MARK_ORDER_AS_DONE_REQUEST",
  MARK_ORDER_AS_DONE_SUCCESS = "MARK_ORDER_AS_DONE_SUCCESS",
  MARK_ORDER_AS_DONE_ERROR = "MARK_ORDER_AS_DONE_ERROR",

  RESEND_ORDER_TO_NAV_REQUEST = "RESEND_ORDER_TO_NAV_REQUEST",
  RESEND_ORDER_TO_NAV_SUCCESS = "RESEND_ORDER_TO_NAV_SUCCESS",
  RESEND_ORDER_TO_NAV_ERROR = "RESEND_ORDER_TO_NAV_ERROR",

  RESEND_GIFT_CARDS_TO_PRINTER_REQUEST = "RESEND_GIFT_CARDS_TO_PRINTER_REQUEST",
  RESEND_GIFT_CARDS_TO_PRINTER_SUCCESS = "RESEND_GIFT_CARDS_TO_PRINTER_SUCCESS",
  RESEND_GIFT_CARDS_TO_PRINTER_ERROR = "RESEND_GIFT_CARDS_TO_PRINTER_ERROR",
}
