import makeStyles from "@mui/styles/makeStyles";
import { theme } from "../../appTheme";

export const SidebarStyles = makeStyles({
  root: {}, //transition: "all 0.4s"
  content: {},
  header: {},
  body: {},
  childElement: {
    width: "100%",
    backgroundColor: "#e3e1de",
    color: "#000",
    height: 50,
    cursor: "pointer",
    "&:hover": { backgroundColor: "#979797" }, //#2eac77
    borderBottom: "1px solid rgba(45, 45, 45, 0.2)",
  },
});

export const OrderDetailStyles = makeStyles({
  root: {
    [theme.breakpoints.only("xs")]: { paddingTop: 10 },
    [theme.breakpoints.only("sm")]: { paddingTop: 40 },
  },
  orderDetailOverviewContainer: {
    [theme.breakpoints.only("xs")]: { paddingTop: 20 },
  },
  orderDetailOverviewRow: {
    paddingTop: 7,
    display: "block",
    [theme.breakpoints.only("xs")]: { paddingTop: 5, paddingLeft: 10 },
    [theme.breakpoints.only("sm")]: { paddingTop: 5 },
  },
  orderDetailContainer: { zoom: 0.85 },
  resendBtnContainer: {
    [theme.breakpoints.only("xs")]: { paddingTop: 10, float: "left" },
    [theme.breakpoints.only("sm")]: {
      paddingTop: 5,
      float: "left",
      paddingLeft: 10,
    },
    [theme.breakpoints.only("md")]: { float: "right", paddingLeft: 10 },
    [theme.breakpoints.only("lg")]: { float: "right", paddingLeft: 10 },
    [theme.breakpoints.only("xl")]: { float: "right", paddingLeft: 10 },
  },
  markOrderAsDoneContainer: {
    [theme.breakpoints.only("xs")]: { paddingTop: 10 },
    [theme.breakpoints.only("sm")]: { paddingLeft: 10 },
    [theme.breakpoints.only("md")]: { paddingLeft: 10 },
    [theme.breakpoints.only("lg")]: { paddingLeft: 10 },
    [theme.breakpoints.only("xl")]: { paddingLeft: 10 },
  },
});

export const AddResellerStyles = makeStyles({
  toolbar: {
    paddingTop: 20,
    float: "right",
  },
  toolbar2: {
    float: "right",
  },
});
export const ResellerStyles = makeStyles({
  textfield: { backgroundColor: "#fff" },
  toolbar: {
    paddingTop: 20,
    float: "right",
  },
  toolbar2: {
    float: "right",
  },
  leftContent: {
    [theme.breakpoints.only("xs")]: { paddingRight: 0 },
    [theme.breakpoints.only("sm")]: { paddingRight: 0 },
    [theme.breakpoints.only("md")]: { paddingRight: 5 },
    [theme.breakpoints.only("lg")]: { paddingRight: 5 },
    [theme.breakpoints.only("xl")]: { paddingRight: 5 },
  },
  searchResellerBtn: {
    paddingLeft: 20,
    [theme.breakpoints.only("xs")]: {
      paddingLeft: 0,
    },
  },
  allowedFileFormatInfo: {
    paddingTop: 10,
  },
  fileUploader: {
    float: "left",
  },
});

export const ResellerDetailStyles = makeStyles({
  textfield: { backgroundColor: "#fff" },
  root: {
    [theme.breakpoints.only("xs")]: { paddingTop: 10 },
    [theme.breakpoints.only("sm")]: { paddingTop: 40 },
  },
  resellerDetailOverviewContainer: {
    [theme.breakpoints.only("xs")]: { paddingTop: 20 },
  },
  titleText: {
    [theme.breakpoints.only("xs")]: { paddingLeft: 10 },
  },
  resellerDetailOverviewRow: {
    paddingTop: 7,
    display: "block",
    [theme.breakpoints.only("xs")]: { paddingTop: 5 },
    [theme.breakpoints.only("sm")]: { paddingTop: 5 },
  },
  allowedFileFormatInfo: {
    paddingTop: 10,
  },
  fileUploader: {
    float: "left",
  },
});

export const AdminStyles = makeStyles({
  toolbar: {
    paddingTop: 20,
    float: "left",
  },
  toolbar2: {
    float: "left",
  },
  newOrderToolbar: { float: "right" },
  searchResellerBtn: {
    paddingLeft: 20,
    [theme.breakpoints.only("xs")]: {
      paddingLeft: 0,
    },
  },
  fromDateCreateInvoice: {
    [theme.breakpoints.only("xs")]: {
      paddingTop: 10,
      paddingRight: 0,
    },
    [theme.breakpoints.only("sm")]: {
      paddingTop: 10,
      paddingRight: 5,
    },
    [theme.breakpoints.only("md")]: {
      paddingTop: 10,
      paddingRight: 5,
    },
    [theme.breakpoints.only("lg")]: {
      paddingTop: 10,
      paddingRight: 5,
    },
    [theme.breakpoints.only("xl")]: {
      paddingTop: 10,
      paddingRight: 5,
    },
  },
  toDateCreateInvoice: {
    [theme.breakpoints.only("xs")]: {
      paddingTop: 10,
      paddingLeft: 0,
    },
    [theme.breakpoints.only("sm")]: {
      paddingTop: 10,
      paddingLeft: 5,
    },
    [theme.breakpoints.only("md")]: {
      paddingTop: 10,
      paddingLeft: 5,
    },
    [theme.breakpoints.only("lg")]: {
      paddingTop: 10,
      paddingLeft: 5,
    },
    [theme.breakpoints.only("xl")]: {
      paddingTop: 10,
      paddingLeft: 5,
    },
  },
  searchedCustomerNumber: {
    [theme.breakpoints.only("xs")]: { paddingTop: 20 },
    [theme.breakpoints.only("sm")]: { paddingTop: 20 },
    [theme.breakpoints.only("md")]: { paddingTop: 0 },
    [theme.breakpoints.only("lg")]: { paddingTop: 0 },
    [theme.breakpoints.only("xl")]: { paddingTop: 0 },
  },
  leftContent: {
    [theme.breakpoints.only("xs")]: { paddingRight: 0 },
    [theme.breakpoints.only("sm")]: { paddingRight: 0 },
    [theme.breakpoints.only("md")]: { paddingRight: 5 },
    [theme.breakpoints.only("lg")]: { paddingRight: 5 },
    [theme.breakpoints.only("xl")]: { paddingRight: 5 },
  },
  rightContent: {
    [theme.breakpoints.only("xs")]: { paddingLeft: 0 },
    [theme.breakpoints.only("sm")]: { paddingLeft: 0 },
    [theme.breakpoints.only("md")]: { paddingLeft: 5 },
    [theme.breakpoints.only("lg")]: { paddingLeft: 5 },
    [theme.breakpoints.only("xl")]: { paddingLeft: 5 },
  },
  customerNumberTooltip: {
    margin: "auto",
    fontSize: "1.8rem",
    [theme.breakpoints.down("lg")]: {
      visibility: "hidden",
      height: 0,
    },
    [theme.breakpoints.up("lg")]: {
      visibility: "visible",
    },
  },
  customerNumberIconBtn: {
    margin: "auto",
    [theme.breakpoints.down("lg")]: {
      visibility: "visible",
    },
    [theme.breakpoints.up("lg")]: {
      visibility: "hidden",
      height: 0,
    },
  },
  isActive: {
    float: "left",
    "&.Mui-checked": {
      color: "#354451",
    },
  },
  adminSidebar: {
    marginBottom: 30,
    [theme.breakpoints.only("xs")]: {
      visibility: "hidden",
      height: 0,
      padding: 0,
      width: 0,
    },
    [theme.breakpoints.only("sm")]: {
      visibility: "hidden",
      height: 0,
      padding: 0,
      width: 0,
    },
    [theme.breakpoints.only("md")]: {
      visibility: "visible",
    },
    [theme.breakpoints.only("lg")]: {
      visibility: "visible",
    },
    [theme.breakpoints.only("xl")]: {
      visibility: "visible",
    },
  },
  adminMenubar: {
    paddingRight: 30,
    [theme.breakpoints.only("xs")]: {
      visibility: "hidden",
      height: 0,
      padding: 0,
      width: 0,
    },
    [theme.breakpoints.only("sm")]: {
      visibility: "hidden",
    },
    [theme.breakpoints.only("md")]: {
      visibility: "hidden",
    },
    [theme.breakpoints.only("lg")]: {
      visibility: "hidden",
      height: 0,
      padding: 0,
      width: 0,
    },
    [theme.breakpoints.only("xl")]: {
      visibility: "hidden",
      height: 0,
      padding: 0,
      width: 0,
    },
  },
  expandIconBtn: {
    transform: "rotate(90deg)",
  },
  subviewContent: {
    paddingTop: 20,
    [theme.breakpoints.only("xs")]: { marginTop: 30 },
    [theme.breakpoints.only("sm")]: { marginTop: 30 },
    [theme.breakpoints.only("md")]: { marginTop: 0 },
    [theme.breakpoints.only("lg")]: { marginTop: 0 },
    [theme.breakpoints.only("xl")]: { marginTop: 0 },
  },
  dropDownMenuRoot: {
    float: "left",
    width: "100%",
  },
  dropDownInner: {
    width: 165,
  },
  fromDateOrderSearch: {
    [theme.breakpoints.only("xs")]: { paddingTop: 20 },
    [theme.breakpoints.only("sm")]: { paddingLeft: 20 },
    [theme.breakpoints.only("md")]: { paddingLeft: 20 },
    [theme.breakpoints.only("lg")]: { paddingLeft: 20 },
    [theme.breakpoints.only("xl")]: { paddingLeft: 20 },
  },
  toDateOrderSearch: {
    [theme.breakpoints.only("xs")]: { paddingTop: 20 },
    [theme.breakpoints.only("sm")]: { paddingLeft: 20 },
    [theme.breakpoints.only("md")]: { paddingLeft: 20 },
    [theme.breakpoints.only("lg")]: { paddingLeft: 20 },
    [theme.breakpoints.only("xl")]: { paddingLeft: 20 },
  },
});

export const SignInStyles = makeStyles({
  root: {
    margin: "auto",
  },
  resendValidationCode: {
    "&:hover": {
      textDecoration: "underline",
    },
  },
  titleContainer: { paddingTop: 50 },
  title: { fontSize: "1rem" },
  emailContainer: {
    paddingTop: 20,
  },
  email: { paddingLeft: 30 },
  validationCodeContainer: {
    paddingTop: 20,
  },
  validationCode: {},
  validateEmailBtn: { paddingTop: 30 },
  submitBtn: { paddingTop: 30 },
});
