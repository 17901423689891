import React, { useEffect } from "react";
import { Button, Grid, Typography, useMediaQuery } from "@mui/material";
import { FieldNames, StateNames } from "../../../Utils/types";
import { IProduct, ProductCategory } from "../../../Modules/Products/types";
import { Currency, SystemPart } from "../../../Utils/enums/enums";
import { StepperTwoStyles } from "../styles";
import { LabelKeys } from "../../../Utils/enums/labelKeys";
import { useTranslation } from "react-i18next";
import { theme } from "../../../appTheme";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store";
import { OrderActions } from "../../../Modules/Orders/actions";
import { IsResellerAuth, IsAuth } from "../../../Utils/authentication";
import ExampleAssortment from "../../ExampleAssortment/ExampleAssortment";

interface IStepperStepTwoVHProps {
  id: string;
  systemPart: SystemPart;
  options: IProduct[];
  selectedProductHasError: boolean;
  onStateChanged: (stateName: StateNames.stepperTwoState, value: IProduct, fieldName: FieldNames) => void;
}

export default function StepperStepTwoVH(props: IStepperStepTwoVHProps) {
  const classes = StepperTwoStyles();
  const { t: translate } = useTranslation();
  const isMobile = useMediaQuery(theme.breakpoints.only("xs"));
  const dispatch = useDispatch();
  const isSystemAdmin = IsAuth() && location.pathname.includes(`/${translate(LabelKeys.AdminUrl)}/${translate(LabelKeys.OrderUrl)}`);
  const isResellerAdmin =
    IsResellerAuth() &&
    location.pathname.includes(`/${translate(LabelKeys.ResellerUrl)}/${translate(LabelKeys.AdminUrl)}/${translate(LabelKeys.OrderUrl)}`);

  const reducerState = useSelector(
    (s: RootState) => {
      return {
        product: s.product,
        order: s.order,
        reseller: s.reseller,
      };
    },
    (prev, next) =>
      prev.product.products === next.product.products &&
      prev.product.isLoading === next.product.isLoading &&
      prev.order.isLoading === next.order.isLoading &&
      prev.order.stepperOneState.selectedProductCategory === next.order.stepperOneState.selectedProductCategory &&
      prev.order.stepperTwoState.selectedProduct === next.order.stepperTwoState.selectedProduct &&
      prev.order.stepperState.errorSections === next.order.stepperState.errorSections &&
      prev.reseller.adminSelectedResellerCountryOption === next.reseller.adminSelectedResellerCountryOption
  );

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  useEffect(() => {
    if (reducerState.order.stepperTwoState.selectedProduct?.id === undefined && props.options?.length > 0) {
      const product = props.options?.find((x) => x.category == ProductCategory.GiftCardVH && x?.name.includes("Regal"));

      if (product?.id > 0) {
        dispatch(
          OrderActions.SetStepTwoStateRequest({
            fieldName: FieldNames.selectedProduct,
            value: product,
          })
        );
      }
    }
  }, [props.options]);

  function GetTextToReplace(): string {
    const product: IProduct = props.options?.find((x) => x.category == ProductCategory.Freight);
    if (product?.id != undefined) {
      return `${
        props.systemPart == SystemPart.Private && !isSystemAdmin && !isResellerAdmin ? product.priceIncludingVat : product.priceExcludingVat
      } ${Currency[product.currency]}`;
    } else {
      return "x";
    }
  }

  return (
    <Grid
      container
      item
      xs={12}
      sm={12}
      md={12}
      lg={12}
      xl={12}
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start",
        alignItems: "flex-start",
      }}
    >
      <Grid id="leftContent" container item xs={12} sm={12} md={6} lg={6} xl={6}>
        <Grid id="title" item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Typography variant="h1" align="left">
            {translate(LabelKeys.ChooseProductPriceVH)}
          </Typography>
        </Grid>
        <Grid id="description" item xs={12} sm={12} md={12} lg={12} xl={12} style={{ paddingTop: 20 }}>
          <Typography variant="body1" align="left">
            {translate(LabelKeys.GiftcardVHDescription)}
          </Typography>
        </Grid>
        {reducerState.order.stepperOneState?.selectedProductCategory?.id === undefined && (
          <Grid id="noSelectedCategory" item xs={12} sm={12} md={12} lg={12} xl={12} justifyContent="center" style={{ paddingTop: 40 }}>
            <Typography variant="body1" align="left" style={{ fontWeight: "bold" }}>
              {translate(LabelKeys.NoSelectedCategory)}
            </Typography>
          </Grid>
        )}
        <Grid id="giftcardsVHContainer" container item xs={12} sm={12} md={12} lg={12} xl={12} style={{ paddingTop: 40 }}>
          {props?.options
            .filter((x) => x.category == ProductCategory.GiftCardVH)
            ?.map((x) => {
              return (
                <div
                  key={x?.id}
                  style={{
                    borderColor: props.selectedProductHasError ? "red" : "",
                  }}
                  className={
                    x?.id == reducerState.order.stepperTwoState.selectedProduct?.id ? classes.selectedCardVH : classes.notSelectedCardVH
                  }
                  onClick={() => {
                    props.onStateChanged(StateNames.stepperTwoState, x, FieldNames.selectedProduct);
                  }}
                >
                  <div
                    style={{
                      paddingTop: isMobile ? 19 : 8,
                    }}
                  >
                    <Typography
                      variant="body1"
                      style={{
                        color: x?.id === reducerState.order.stepperTwoState.selectedProduct?.id ? "#fff" : "#000",
                      }}
                    >{`${
                      props.systemPart == SystemPart.Company || isSystemAdmin || isResellerAdmin
                        ? x?.priceExcludingVat
                        : x?.priceIncludingVat
                    } ${Currency[x?.currency]}`}</Typography>
                  </div>
                </div>
              );
            })}
        </Grid>
        {(props.systemPart == SystemPart.Company || isSystemAdmin || isResellerAdmin) &&
          reducerState.order.stepperOneState?.selectedProductCategory?.id > 0 && (
            <Grid
              id="freightCostWillBeAdded"
              container
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              style={{ paddingTop: 20, display: "flex" }}
              justifyContent="flex-start"
            >
              <Typography variant="body2">
                {translate(
                  props.systemPart == SystemPart.Private ? LabelKeys.PriceWillAddVatPrivate : LabelKeys.PriceWillAddVat
                )?.replaceAll("{amount} {currency}", GetTextToReplace())}
              </Typography>
            </Grid>
          )}
        {(props.systemPart == SystemPart.Company || isSystemAdmin || isResellerAdmin) &&
          reducerState.order.stepperOneState?.selectedProductCategory?.id > 0 && (
            <Grid
              id="allPricesExcludesVatText"
              container
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              style={{ paddingTop: 5, display: "flex" }}
              justifyContent="flex-start"
            >
              <Typography variant="body2">{"** " + translate(LabelKeys.NoteAllExclVatPurchase)}</Typography>
            </Grid>
          )}
      </Grid>
      {reducerState.order.stepperTwoState.selectedProduct?.id !== undefined && (
        <Grid id="exampleAssortment" container item xs={12} sm={12} md={12} lg={12} xl={12} style={{ paddingTop: 15 }}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ display: "flex" }}>
            <Typography
              variant="h2"
              style={{
                textAlign: "left",
                fontWeight: 650,
                width: "100%",
                margin: "auto",
              }}
            >
              {translate(LabelKeys.ExampleAssortment)}
            </Typography>
            {!isMobile && (
              <Button
                id="seeExampleAssortmentBtn"
                style={{
                  height: 52,
                  width: 300,
                  float: "right",
                  border: "1px solid #000",
                  cursor: "pointer",
                  color: "#000",
                  display: "flex",
                  marginTop: 20,
                  borderRadius: 0,
                  margin: "auto",
                }}
                onClick={() => window.open("https://vingahome.com/se/testinloggning", "_blank")}
              >
                <Typography
                  variant="body1"
                  style={{
                    paddingLeft: 10,
                    textTransform: "none",
                  }}
                >
                  {translate(LabelKeys.SeeWholeAssortmentVH)}
                </Typography>
                <div
                  style={{
                    float: "right",
                    paddingRight: 18,
                    paddingTop: 3,
                    margin: "auto",
                  }}
                >
                  <svg width="35" height="35" fill="currentColor" className="bi bi-arrow-right-short" viewBox="0 0 16 16">
                    <path
                      fillRule="evenodd"
                      d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z"
                    />
                  </svg>
                </div>
              </Button>
            )}
          </Grid>
          <ExampleAssortment id="exampleAssortmentId" productName={reducerState.order.stepperTwoState.selectedProduct?.name} />
          {isMobile && (
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ paddingTop: 40 }}>
              <Button
                id="seeExampleAssortmentBtn"
                style={{
                  height: 52,
                  width: 250,
                  border: "1px solid #000",
                  cursor: "pointer",
                  color: "#000",
                  display: "flex",
                  marginTop: 20,
                  borderRadius: 0,
                  margin: "auto",
                }}
                onClick={() => window.open("https://vingahome.com/se/testinloggning", "_blank")}
              >
                <Typography
                  variant="body1"
                  style={{
                    paddingLeft: 10,
                    textTransform: "none",
                  }}
                >
                  {translate(LabelKeys.SeeWholeAssortmentVH)}
                </Typography>
                <div
                  style={{
                    float: "right",
                    paddingRight: 18,
                    paddingTop: 3,
                    margin: "auto",
                  }}
                >
                  <svg width="35" height="35" fill="currentColor" className="bi bi-arrow-right-short" viewBox="0 0 16 16">
                    <path
                      fillRule="evenodd"
                      d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z"
                    />
                  </svg>
                </div>
              </Button>
            </Grid>
          )}
        </Grid>
      )}
    </Grid>
  );
}
