import React from "react";
import Grid from "@mui/material/Grid";
import { SMSPreviewStyles } from "./styles";
import SMSTemplate from "../../Utils/Images/SMSPreview.png";
import { Typography } from "@mui/material";

export interface ISMSPreviewProps {
  smsMessage: string;
}

export default function SMSPreview(props: ISMSPreviewProps) {
  const classes = SMSPreviewStyles();
  return (
    <Grid id="smsPreview" container item xs={12} sm={12} md={12} lg={12} xl={12} justifyContent="center" className={classes.container}>
      <div>
        <img src={SMSTemplate} className={classes.previewBase} />
      </div>
      <div className={classes.previewSMSText}>
        <Typography variant="body2">{props.smsMessage}</Typography>
      </div>
    </Grid>
  );
}
