import React from "react";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { useTranslation } from "react-i18next";
import { LabelKeys } from "../../Utils/enums/labelKeys";
import CSTextField from "../Input/Textfield/CSTextField";

interface ISMSTextEditorProps {
  id: string;
  className?: string;
  maxLengthSMS?: number;
  value?: string;
  onChange: (value: string) => void;
}

function SMSTextEditor(props: ISMSTextEditorProps) {
  const { t: translate } = useTranslation();

  return (
    <React.Fragment>
      <CSTextField
        id={props.id}
        className={props.className}
        placeholder={translate(LabelKeys.WriteMessageHere)}
        multiline
        rows={10}
        value={props.value}
        onChange={(e) => props.onChange(e.target.value)}
      />
      {/* {props.maxLengthSMS > 0 && (
        <div style={{ textAlign: 'left', backgroundColor: '#ebebeb' }}>
          <p style={{ backgroundColor: '#ebebeb' }}>{`${translate(
            LabelKeys.SmsCharactersLeft
          )}: ${props.maxLengthSMS - props.value?.length}`}</p>
        </div>
      )} */}
    </React.Fragment>
  );
}
export default SMSTextEditor;
