import makeStyles from "@mui/styles/makeStyles";
import { theme } from "../../appTheme";

export const MenuBarStyles = makeStyles({
  menubarContainer: {
    float: "left",
  },
});
export const GiftCardMenuStyles = makeStyles({
  menubarContainer: {
    [theme.breakpoints.only("xs")]: {},
    [theme.breakpoints.only("sm")]: { left: "80%", marginTop: 10 },
    [theme.breakpoints.only("md")]: { left: "100%", marginTop: 10 },
    [theme.breakpoints.only("lg")]: { left: "100%", marginTop: 10 },
    [theme.breakpoints.only("xl")]: { left: "100%", marginTop: 10 },
  },
  img: {
    width: 210,
    [theme.breakpoints.only("xs")]: { width: 150 },
    [theme.breakpoints.only("sm")]: { width: 120 },
    [theme.breakpoints.only("md")]: { width: 180 },
  },
});
