import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import { useTranslation } from "react-i18next";
import { IconButton, Paper, Tooltip, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { OrderDetailStyles } from "../../Admin/styles";
import { SendBy } from "../../../components/Delivery/types";
import ProcessCircle from "../../../components/ProcessCircle/ProcessCircle";
import EditableTable from "../../../components/Table/EditableTable";
import { OrderActions } from "../../../Modules/Orders/actions";
import { ISearchSingleOrderRequest, IResendOrderLineRequest } from "../../../Modules/Orders/types";
import { RootState } from "../../../store";
import { Currency, PaymentMethod } from "../../../Utils/enums/enums";
import { LabelKeys } from "../../../Utils/enums/labelKeys";
import { OrderLineStatusConverter, OrderStatusConverter, GetDateWithTime } from "../../../Utils/extensions";
import { ICustomOrderLine, IOrderLine, searchedOrderOrderLinesHeadCells } from "../../Admin/types";
import { OrderType } from "../../Order/types";
import { FieldNames, GetCurrentLanguageAsCodeFromLocalStorage } from "../../../Utils/types";

interface IOrderDetailsProps {
  onGoBackToOrderPageClicked?: (orderDetailsOpen: boolean) => void;
}

function OrderDetails(props: IOrderDetailsProps) {
  const dispatch = useDispatch();
  const classes = OrderDetailStyles();
  const { t: translate } = useTranslation();
  const reducerState = useSelector(
    (s: RootState) => {
      return {
        reseller: s.reseller,
      };
    },
    (prev, next) =>
      prev.reseller.isLoading === next.reseller.isLoading &&
      prev.reseller.selectedResellerCoupledOrder === next.reseller.selectedResellerCoupledOrder
  );
  const [adminOrderDetailState, setAdminOrderDetailState] = useState({
    customOrderLines: [] as IOrderLine[],
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    let customOrderLines: ICustomOrderLine[] = [];

    if (reducerState.reseller?.selectedResellerCoupledOrder?.orderLines?.length > 0) {
      reducerState.reseller?.selectedResellerCoupledOrder?.orderLines?.map((item) => {
        const orderLine = {
          id: item.id,
          sentDate: item.sentDate,
          status: OrderLineStatusConverter(item.status, GetCurrentLanguageAsCodeFromLocalStorage()),
          receiver: item.receiver,
          claimCode: item.claimCode,
        };
        customOrderLines.push(orderLine);
      });

      handleFieldOnChange(FieldNames.customOrderLines, customOrderLines);
    }
  }, [reducerState.reseller?.selectedResellerCoupledOrder]);

  function handleFieldOnChange(fieldName: FieldNames, value: ICustomOrderLine[]) {
    setAdminOrderDetailState((state) => ({
      ...state,
      [fieldName]: value,
    }));
  }

  const handleRefreshMenuClick = () => {
    dispatch(
      OrderActions.SearchSingleOrderRequest({
        orderId: reducerState.reseller?.selectedResellerCoupledOrder?.id,
      } as ISearchSingleOrderRequest)
    );
  };

  function handleResendRowClicked(row: IOrderLine): void {
    dispatch(
      OrderActions.ResendOrderLineRequest({
        orderLineId: row.id,
        orderId: reducerState.reseller.selectedResellerCoupledOrder?.id,
      } as IResendOrderLineRequest)
    );
  }

  return (
    <Grid
      container
      item
      xs={12}
      sm={12}
      md={12}
      lg={12}
      xl={12}
      className={classes.root}
      style={{ backgroundColor: "#e3e1de", paddingTop: "25px", width: "95vw" }}
      justifyContent="center"
    >
      {reducerState.reseller?.isLoading && <ProcessCircle />}
      <Grid container item xs={11} sm={11} md={11} lg={11} xl={11}>
        <Grid container item xs={12} sm={12} md={12} lg={12} xl={12} style={{ textAlign: "left", paddingBottom: 20 }}>
          <Grid item xs={2} sm={1} md={1} lg={1} xl={1} style={{ paddingTop: 10 }}>
            <Tooltip
              arrow
              leaveDelay={100}
              title={
                <span
                  style={{
                    whiteSpace: "pre-line",
                    fontSize: "1rem",
                    fontFamily: "Mulish",
                  }}
                >
                  {translate(LabelKeys.GoBack)}
                </span>
              }
            >
              <IconButton id="goBackToAdminOrderPage" onClick={() => props.onGoBackToOrderPageClicked(false)}>
                <svg width="20" height="20" fill="currentColor" className="bi bi-arrow-left" viewBox="0 0 16 16">
                  <path
                    fillRule="evenodd"
                    d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"
                  />
                </svg>
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid item xs={9} sm={10} md={10} lg={10} xl={10}>
            <Typography variant="h2" style={{ paddingTop: 13 }}>
              {translate(LabelKeys.OrderDetails)}
            </Typography>
          </Grid>
          <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
            <IconButton onClick={() => handleRefreshMenuClick()} style={{ float: "right" }}>
              <svg width="25" height="25" fill="currentColor" className="bi bi-arrow-counterclockwise" viewBox="0 0 16 16">
                <path d="M8 3a5 5 0 1 1-4.546 2.914.5.5 0 0 0-.908-.417A6 6 0 1 0 8 2v1z" />
                <path d="M8 4.466V.534a.25.25 0 0 0-.41-.192L5.23 2.308a.25.25 0 0 0 0 .384l2.36 1.966A.25.25 0 0 0 8 4.466z" />
              </svg>
            </IconButton>
          </Grid>
        </Grid>
        <Paper style={{ width: "100%", borderRadius: 0, marginTop: 20, marginBottom: 30 }}>
          <Grid
            id="orderDetailsOverView"
            container
            item
            justifyContent="center"
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            style={{
              paddingTop: 10,
              paddingBottom: 30,
              paddingLeft: 20,
              textAlign: "left",
              fontFamily: "Mulish",
            }}
          >
            <Grid item xs={12} sm={3} md={3} lg={3} xl={3} className={classes.orderDetailContainer}>
              <div className={classes.orderDetailOverviewRow}>
                <b>{translate(LabelKeys.OrderId)}</b>: {reducerState.reseller.selectedResellerCoupledOrder?.id}
              </div>
              <div className={classes.orderDetailOverviewRow}>
                <b>{translate(LabelKeys.OrderStatus)}</b>:{" "}
                {OrderStatusConverter(
                  reducerState.reseller.selectedResellerCoupledOrder?.status,
                  GetCurrentLanguageAsCodeFromLocalStorage()
                )}
              </div>
              <div className={classes.orderDetailOverviewRow}>
                <b>{translate(LabelKeys.CreatedDate)}</b>:{" "}
                {GetDateWithTime(reducerState.reseller.selectedResellerCoupledOrder?.createdDate)}
              </div>
              <div className={classes.orderDetailOverviewRow}>
                <b>{translate(LabelKeys.DeliveryDate)}</b>: {GetDateWithTime(reducerState.reseller.selectedResellerCoupledOrder?.orderDate)}
              </div>
              <div className={classes.orderDetailOverviewRow}>
                <b>{translate(LabelKeys.SendBy)}</b>:{" "}
                {translate(SendBy[reducerState.reseller.selectedResellerCoupledOrder?.sendBy]?.toLowerCase())}
              </div>
              <div className={classes.orderDetailOverviewRow}>
                <b>{translate(LabelKeys.OrderType)}</b>:{" "}
                {translate(OrderType[reducerState.reseller.selectedResellerCoupledOrder?.orderType]?.toLowerCase())}
              </div>
            </Grid>
            <Grid item xs={12} sm={5} md={5} lg={5} xl={5} className={classes.orderDetailContainer}>
              <div className={classes.orderDetailOverviewRow}>
                <b>{translate(LabelKeys.CompanyName)}</b>: {reducerState.reseller.selectedResellerCoupledOrder?.companyName}
              </div>
              <div className={classes.orderDetailOverviewRow}>
                <b>{translate(LabelKeys.CompanyContactPhoneNumber)}</b>:{" "}
                {reducerState.reseller.selectedResellerCoupledOrder?.companyContactPhoneNumber}
              </div>
              <div className={classes.orderDetailOverviewRow}>
                <b>{translate(LabelKeys.SenderEmail)}</b>: {reducerState.reseller.selectedResellerCoupledOrder?.contactEmail}
              </div>
              <div className={classes.orderDetailOverviewRow}>
                <b>{translate(LabelKeys.OrganisationNumber)}</b>: {reducerState.reseller.selectedResellerCoupledOrder?.organisationNumber}
              </div>
              <div className={classes.orderDetailOverviewRow}>
                <b>{translate(LabelKeys.Name)}</b>: {reducerState.reseller.selectedResellerCoupledOrder?.name}
              </div>
              <div className={classes.orderDetailOverviewRow}>
                <b>{translate(LabelKeys.AddressLine)}</b>: {reducerState.reseller.selectedResellerCoupledOrder?.addressLine}
              </div>
              <div className={classes.orderDetailOverviewRow}>
                <b>{translate(LabelKeys.PostalCode)}</b>: {reducerState.reseller.selectedResellerCoupledOrder?.postalCode}
              </div>
              <div className={classes.orderDetailOverviewRow}>
                <b>{translate(LabelKeys.City)}</b>: {reducerState.reseller.selectedResellerCoupledOrder?.city}
              </div>
              <div className={classes.orderDetailOverviewRow}>
                <b>{translate(LabelKeys.Country)}</b>:{" "}
                {reducerState.reseller.selectedResellerCoupledOrder?.country == "Unknown"
                  ? ""
                  : reducerState.reseller.selectedResellerCoupledOrder?.country}
              </div>
            </Grid>
            <Grid item xs={12} sm={4} md={4} lg={4} xl={4} className={classes.orderDetailContainer}>
              <div className={classes.orderDetailOverviewRow}>
                <b>{translate(LabelKeys.OrderValue)}</b>: {reducerState.reseller.selectedResellerCoupledOrder?.totalOrderValue}{" "}
                {Currency[reducerState.reseller.selectedResellerCoupledOrder?.currency]}
              </div>
              <div className={classes.orderDetailOverviewRow} style={{ textTransform: "capitalize" }}>
                <b>{translate(LabelKeys.PaymentMethod)}</b>:{" "}
                {translate(PaymentMethod[reducerState.reseller.selectedResellerCoupledOrder?.paymentMethod]?.toLowerCase())}
              </div>
            </Grid>
          </Grid>
        </Paper>
        {reducerState.reseller.selectedResellerCoupledOrder.orderType == OrderType.ResellerAdmin && (
          <React.Fragment>
            <Grid container item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Grid item xs={12} sm={10} md={10} lg={10} xl={10}>
                <Typography align="left" variant="h3">
                  {translate(LabelKeys.OrderRows)}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              id="orderLines"
              container
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              style={{ paddingTop: 10, paddingBottom: 30, textAlign: "left" }}
            >
              <EditableTable
                columns={searchedOrderOrderLinesHeadCells}
                rows={adminOrderDetailState.customOrderLines}
                enableToolbar={true}
                enableInlineResendMode
                onInlineResendRowClicked={(row: IOrderLine) => handleResendRowClicked(row)}
                totalItems={reducerState.reseller.selectedResellerCoupledOrder?.orderLines?.length}
              />
            </Grid>
          </React.Fragment>
        )}
      </Grid>
    </Grid>
  );
}

export default OrderDetails;
