import React, { useState } from "react";
import {
  TableRow,
  TableCell,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TablePagination,
  IconButton,
  Tooltip,
} from "@mui/material";
import { shallowEqual } from "react-redux";
import { WithoutTime } from "../../Utils/extensions";
import CSDialog from "../Dialog/CSDialog";
import { TableStyles } from "./styles";
import { HeadCell } from "./types";
import CSToolbar from "../Toolbar/CSToolbar";
import { useTranslation } from "react-i18next";
import CSTextField from "../Input/Textfield/CSTextField";
import CSDatePicker from "../Input/DatePicker/CSDatePicker";
import CSCheckbox from "../Input/Forms/CSCheckbox";
import { LabelKeys } from "../../Utils/enums/labelKeys";

interface IReadOnlyTableProps<T> {
  columns: HeadCell[];
  rows: T[];
  className?: string;
  enableToolbar: boolean;
  totalItems: number;
  enablePagination?: boolean;
  currentPage?: number;
  rowsPerPage?: number;
  rowsPerPageOptions?: number[];
  rowsPerPageLabel?: string;
  deleteDialogContent?: React.ReactNode;
  highlightOnRowSelection?: boolean;
  editableColumns?: string[];
  maxHeight?: number;
  tableTitle?: string;
  selectedRow?: T;
  onCurrentPageChanged?(e: React.MouseEvent<HTMLButtonElement> | null, newPage: number): void;
  onRowsPerPageChanged?(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void;
  onRowClick?(selectedRow: T, selectedRows: T[], e?: React.MouseEvent<HTMLTableRowElement, MouseEvent>): void;
  onToolbarAddClicked?: () => void;
  onToolbarEditClicked?: () => void;
  onToolbarRefreshClicked?: () => void;
  onToolbarDeleteClicked?: (selectedRows: T[]) => void;
  onInlineResendRowClicked?: (rowToResend: T) => void;
  onInlineRowDeleteClicked?: (rowToDelete: T) => void;
  onInlineConfirmRowChangesClicked?: (editableRow: T) => void;
  enableAddMode?: boolean;
  enableEditMode?: boolean;
  enableRefreshMode?: boolean;
  enableDeleteMode?: boolean;
  enableCheckboxes?: boolean;
  enableResendMode?: boolean;
  enableInlineEditMode?: boolean;
  enableInlineResendMode?: boolean;
  enableInlineDeleteMode?: boolean;
  disableAddBtn?: boolean;
  disableEditBtn?: boolean;
  disableRefreshBtn?: boolean;
  disableDeleteBtn?: boolean;
  disableResendBtn?: boolean;
  disableInlineEditBtn?: boolean;
  disableInlineResendBtn?: boolean;
  disableInlineDeleteBtn?: boolean;
  toolbarAddTooltipText?: string;
  toolbarEditTooltipText?: string;
  toolbarDeleteTooltipText?: string;
  toolbarRefreshTooltipText?: string;
}

interface IReadOnlyTableCell {
  key: string;
  name: string;
  value: string | number | Date | boolean;
  hide?: boolean;
}

function EditableTable<T>(props: IReadOnlyTableProps<T>) {
  const classes = TableStyles();
  const [selectedRows, setSelectedRows] = useState<typeof props.rows>([]);
  const [editableRow, setEditableRow] = useState({} as T);
  const [confirmDiaglogOpen, setConfirmDialogOpen] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const { t: translate } = useTranslation();

  //UseEffects and direct data lookup methods
  function GetDialogChildren() {
    return <div>{props.deleteDialogContent}</div>;
  }
  const isSelected = (row: T) => {
    return selectedRows?.some((item) => shallowEqual(item, row));
  };

  //Handle X methods
  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      setSelectedRows(props.rows);
      return;
    }
    setSelectedRows([]);
  };

  function onInlineEditModeClicked(row: T) {
    // let newRows: typeof props.rows = [] as typeof props.rows;
    // let oldRow: typeof props.rows = editableRow.map((x) => x);

    // if (oldRows.some((x) => (x as any).id === (row as any).id)) {
    //   newRows = oldRows.filter((x) => (x as any).id !== (row as any).id);
    // } else {
    //   newRows = oldRows;
    //   newRows.push(row);
    // }
    setEditableRow({} as T); //Clean it first
    setEditableRow(row);
  }

  function handleOnAddClicked(): void {
    if (!props.enableAddMode) return;
    if (props.onToolbarAddClicked) props.onToolbarAddClicked();
  }

  function handleOnEditClicked(): void {
    if (!props.enableEditMode) return;
    if (props.onToolbarEditClicked) props.onToolbarEditClicked();
  }

  function handleOnDeleteClicked(): void {
    setConfirmDialogOpen(true);
  }

  function handleDeleteBtnDisabled() {
    return props.disableDeleteBtn || selectedRows?.length > 0;
  }

  const handleCheckboxClick = (row: T) => {
    const rowToSelect = props.rows && props.rows.find((rowInList) => rowInList === row);
    let newSelection: typeof props.rows = [];

    if (isSelected(row)) {
      const newValue = selectedRows?.filter((item) => item !== row);
      setSelectedRows(newValue);
      return;
    } else {
      setSelectedRows(newSelection.concat(selectedRows, rowToSelect));
      return;
    }
  };

  function handleRowClick(row: T) {
    const selectedRow = props.rows?.find((rowInList) => rowInList === row);

    if (selectedRow) props.selectedRow;
  }

  function handleOnRefreshClicked(): void {
    if (!props.enableRefreshMode) return;
    setSelectedRows([]);
    props.onToolbarRefreshClicked();
  }

  function handleTrue(): boolean {
    return props.currentPage === 0;
  }

  function handleFieldOnChange(fieldName: string, value: string | number | Date | boolean): void {
    setEditableRow((state) => ({ ...state, [fieldName]: value }));
    // let rowToUpdate = editableRow.find(
    //   (x) => (x as any)?.id === (row as any)?.id
    // );

    // if (!rowToUpdate) return;
    // (rowToUpdate as any)[fieldName] = value;

    // let rowsToSet = editableRow?.filter(
    //   (x) => (x as any)?.id !== (rowToUpdate as any)?.Id
    // );

    // rowsToSet.push(rowToUpdate);
    // setEditableRow(rowsToSet);
  }

  return (
    <Paper style={{ width: "100%" }}>
      {props.enableToolbar && (
        <div>
          <div style={{ float: "left", marginLeft: 15 }}>
            <h2>{props.tableTitle}</h2>
          </div>
          <div style={{ float: "right", paddingRight: 10, marginTop: 8 }}>
            <CSToolbar
              enableAdd={props.enableAddMode}
              enableEdit={props.enableEditMode}
              enableRefresh={props.enableRefreshMode}
              enableDelete={props.enableDeleteMode}
              disableDelete={!handleDeleteBtnDisabled()}
              onAddClicked={() => handleOnAddClicked()}
              onEditClicked={() => handleOnEditClicked()}
              onRefreshClicked={() => handleOnRefreshClicked()}
              onDeleteClicked={() => handleOnDeleteClicked()}
              addTooltipText={props.toolbarAddTooltipText}
              editTooltipText={props.toolbarEditTooltipText}
              deleteTooltipText={props.toolbarDeleteTooltipText}
              refreshTooltipText={props.toolbarRefreshTooltipText}
            />
          </div>
        </div>
      )}
      <TableContainer
        style={{
          width: "100%",
          maxHeight: props.maxHeight ?? 400,
          display: "block",
        }}
      >
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              {props.enableCheckboxes && (
                <TableCell key="editableTableCheckBox" width="20" align="left">
                  <CSCheckbox
                    indeterminate={selectedRows && props.rows && selectedRows.length > 0 && selectedRows.length < props.rows.length}
                    checked={props.rows && selectedRows && props.rows.length > 0 && selectedRows.length === props.rows.length}
                    onChange={handleSelectAllClick}
                  />
                </TableCell>
              )}
              {props.columns &&
                props.columns?.map((column) => (
                  <TableCell key={column.key} width={column.width} align="left">
                    {translate(column.labelKey)}
                  </TableCell>
                ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {props.rows &&
              props.rows?.map((row, index) => {
                let convertedCells: IReadOnlyTableCell[] = [];
                if (row) {
                  const keyValues = Object.entries(row);
                  convertedCells = keyValues?.map((keyValue) => {
                    return {
                      key: keyValue[0],
                      name: keyValue[0],
                      value: keyValue[1],
                      hide: false,
                    } as IReadOnlyTableCell;
                  });
                }

                const isItemSelected = isSelected(row);

                return (
                  <TableRow key={index} hover className={index % 2 ? classes.evenClassName : classes.oddClassName}>
                    {props.enableCheckboxes && (
                      <TableCell key={index} onClick={() => handleCheckboxClick(row)} suppressContentEditableWarning={true} align="left">
                        <CSCheckbox checked={isItemSelected} />
                      </TableCell>
                    )}
                    {props.columns &&
                      props.columns?.map((column, index) => {
                        if (column) {
                          const cell = convertedCells.find((x) => x.key === column.key);
                          if (cell) {
                            return (
                              <React.Fragment key={index}>
                                {(editableRow as any)?.id !== (row as any).id ? (
                                  <TableCell
                                    key={cell.key}
                                    onClick={() => {
                                      handleRowClick(row);
                                      if (props.onRowClick) props.onRowClick(row, selectedRows);
                                    }}
                                    suppressContentEditableWarning={true}
                                    align="left"
                                  >
                                    {column.type === "date"
                                      ? WithoutTime(cell.value as Date)
                                      : column.type === "boolean"
                                      ? cell.value === true
                                        ? translate(LabelKeys.Yes)
                                        : translate(LabelKeys.No)
                                      : cell.value}
                                  </TableCell>
                                ) : props.editableColumns?.includes(column.key) ? (
                                  <TableCell
                                    key={cell.key}
                                    onClick={() => {
                                      handleRowClick(row);
                                      if (props.onRowClick) props.onRowClick(row, selectedRows);
                                    }}
                                    suppressContentEditableWarning={true}
                                    align="left"
                                  >
                                    {column.type == "date" ? (
                                      <CSDatePicker
                                        key={cell.key + "_" + cell.name}
                                        okLabel="Ok"
                                        cancelLabel="Cancel"
                                        value={(editableRow as any)?.id === (row as any).id ? (editableRow as any)[cell.key] : cell.key}
                                        onChange={(e) => handleFieldOnChange(cell.key, e)}
                                      />
                                    ) : column.type == "boolean" ? (
                                      <CSCheckbox
                                        key={cell.key + "_" + cell.name}
                                        checked={(editableRow as any)?.id === (row as any).id ? (editableRow as any)[cell.key] : cell.key}
                                        onChange={(e) => handleFieldOnChange(cell.key, e.target.checked)}
                                      />
                                    ) : (
                                      <CSTextField
                                        key={cell.key + "_" + cell.name}
                                        value={(editableRow as any)?.id === (row as any).id ? (editableRow as any)[cell.key] : cell.key}
                                        onChange={(e) => handleFieldOnChange(cell.key, e.target.value)}
                                      />
                                    )}
                                  </TableCell>
                                ) : (
                                  <TableCell
                                    key={cell.key}
                                    onClick={() => {
                                      handleRowClick(row);
                                      if (props.onRowClick) props.onRowClick(row, selectedRows);
                                    }}
                                    suppressContentEditableWarning={true}
                                    align="left"
                                  >
                                    {column.type === "date"
                                      ? WithoutTime(cell.value as Date)
                                      : column.type === "boolean"
                                      ? cell.value === true
                                        ? translate(LabelKeys.Yes)
                                        : translate(LabelKeys.No)
                                      : cell.value}
                                  </TableCell>
                                )}
                              </React.Fragment>
                            );
                          }
                        }
                      })}
                    <TableCell suppressContentEditableWarning={true} align="left">
                      {props.enableInlineEditMode && (editableRow as any)?.id === (row as any).id ? (
                        <React.Fragment>
                          <Tooltip arrow title={translate(LabelKeys.Confirm)}>
                            <IconButton
                              onClick={() => {
                                if (props.onInlineConfirmRowChangesClicked) props.onInlineConfirmRowChangesClicked(editableRow);
                                setEditableRow({} as T);
                              }}
                            >
                              <svg width="20" height="20" fill="currentColor" className="bi bi-check-circle" viewBox="0 0 16 16">
                                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                <path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z" />
                              </svg>
                            </IconButton>
                          </Tooltip>
                          <Tooltip arrow title={translate(LabelKeys.Cancel)}>
                            <IconButton
                              onClick={() => {
                                setEditableRow({} as T);
                              }}
                            >
                              <svg width="20" height="20" fill="currentColor" className="bi bi-x-circle" viewBox="0 0 16 16">
                                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                              </svg>
                            </IconButton>
                          </Tooltip>
                        </React.Fragment>
                      ) : (
                        <React.Fragment>
                          {props.enableInlineEditMode && (
                            <Tooltip arrow title={translate(LabelKeys.Edit)}>
                              <IconButton
                                disabled={props.disableInlineEditBtn}
                                onClick={() => {
                                  onInlineEditModeClicked(row);
                                }}
                              >
                                <svg width="20" height="20" fill="currentColor" className="bi bi-pencil" viewBox="0 0 16 16">
                                  <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z" />
                                </svg>
                              </IconButton>
                            </Tooltip>
                          )}
                          {props.enableInlineResendMode && (
                            <Tooltip arrow title={translate(LabelKeys.Resend)}>
                              <IconButton
                                disabled={props.disableInlineResendBtn}
                                onClick={() => {
                                  if (props.onInlineResendRowClicked) props.onInlineResendRowClicked(row);
                                }}
                              >
                                <svg
                                  width="20"
                                  height="20"
                                  fill="currentColor"
                                  className="bi bi-send"
                                  viewBox="0 0 16 16"
                                  style={{ paddingTop: 2, paddingRight: 2 }}
                                >
                                  <path d="M15.854.146a.5.5 0 0 1 .11.54l-5.819 14.547a.75.75 0 0 1-1.329.124l-3.178-4.995L.643 7.184a.75.75 0 0 1 .124-1.33L15.314.037a.5.5 0 0 1 .54.11ZM6.636 10.07l2.761 4.338L14.13 2.576 6.636 10.07Zm6.787-8.201L1.591 6.602l4.339 2.76 7.494-7.493Z" />
                                </svg>
                              </IconButton>
                            </Tooltip>
                          )}
                          {props.enableInlineDeleteMode && (
                            <Tooltip arrow title={translate(LabelKeys.Delete)}>
                              <IconButton
                                disabled={props.disableInlineDeleteBtn}
                                onClick={() => {
                                  if (props.onInlineRowDeleteClicked) props.onInlineRowDeleteClicked(row);
                                }}
                              >
                                <svg width="20" height="20" fill="currentColor" className="bi bi-trash" viewBox="0 0 16 16">
                                  <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                                  <path
                                    fillRule="evenodd"
                                    d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"
                                  />
                                </svg>
                              </IconButton>
                            </Tooltip>
                          )}
                        </React.Fragment>
                      )}
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      {props.enablePagination && (
        <TablePagination
          count={props.totalItems}
          onPageChange={() => props.onCurrentPageChanged}
          page={props.currentPage}
          rowsPerPageOptions={props.rowsPerPageOptions ? props.rowsPerPageOptions : [10, 20, 30]}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={(e) => {
            setRowsPerPage(Number(e.target.value));
            props.onRowsPerPageChanged;
          }}
          backIconButtonProps={{
            disabled: handleTrue(),
          }}
          labelRowsPerPage={props.rowsPerPageLabel ? props.rowsPerPageLabel : "Rows per page"}
        />
      )}
      {confirmDiaglogOpen && props.enableDeleteMode && (
        <CSDialog
          id="editableTableConfirmDialog"
          title={"Warning"}
          open={confirmDiaglogOpen}
          enableSubmitButton={true}
          enableCloseButton={true}
          onCloseClicked={() => setConfirmDialogOpen(false)}
          onSubmitClicked={() => {
            setSelectedRows([]);
            setConfirmDialogOpen(false);
            if (props.enableDeleteMode) props.onToolbarDeleteClicked(selectedRows);
          }}
          SubmitBtnText={"Yes"}
          CloseBtnText={"No"}
        >
          {GetDialogChildren()}
        </CSDialog>
      )}
    </Paper>
  );
}

export default EditableTable;
