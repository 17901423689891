import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import { useTranslation } from "react-i18next";
import { Button, Typography, useMediaQuery } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import CSTextField from "../../../../components/Input/Textfield/CSTextField";
import ProcessCircle from "../../../../components/ProcessCircle/ProcessCircle";
import { OrderActions } from "../../../../Modules/Orders/actions";
import { RootState } from "../../../../store";
import { LabelKeys } from "../../../../Utils/enums/labelKeys";
import { FieldNames, GetCurrentLanguageAsCodeFromLocalStorage, GetCurrentLanguageAsText } from "../../../../Utils/types";
import { AdminStyles } from "../../styles";
import { IAdminOrderSearchState, searchOrderHeaders, ICustomOrder, AdminSubview } from "../../types";
import { IOrderRow } from "../../types";
import CSReadOnlyTable from "../../../../components/Table/CSReadOnlyTable";
import { OrderStatusConverter } from "../../../../Utils/extensions";
import { PaymentMethod } from "../../../../Utils/enums/enums";
import CSDatePicker from "../../../../components/Input/DatePicker/CSDatePicker";
import CSToolbar from "../../../../components/Toolbar/CSToolbar";
import { useNavigate } from "react-router";
import { TransactionActions } from "../../../../Modules/Transaction/actions";
import { IGetTransactionsRequest } from "../../../../Modules/Transaction/types";
import OrderDetailsPopup from "./Popups/OrderDetalisPopup";
import { SendBy } from "../../../../components/Delivery/types";
import { OrderType } from "../../../Order/types";
import AdminMenu from "../../../../components/Menu/AdminMenu";
import AdminSidebar from "../../AdminSidebar";
import { theme } from "../../../../appTheme";
import { IsAuth } from "../../../../Utils/authentication";
import Cookies from "js-cookie";
import { AuthenticationActions } from "../../../../Modules/Authentication/actions";
import { ISetSignedInUserEmailRequest } from "../../../../Modules/Authentication/types";
import { CookieConstants } from "../../../../Utils/Labels/types";
import jwt_decode from "jwt-decode";

export function AdminOrder() {
  const classes = AdminStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [currentTablePage, setCurrentTablePage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const { t: translate } = useTranslation();
  const currentLanguage = GetCurrentLanguageAsCodeFromLocalStorage();
  const isMobile = useMediaQuery(theme.breakpoints.only("xs"));
  const [adminOrderSearchState, setAdminOrderSearchState] = useState({
    searchParameter: "",
    searchOrderFromDate: GetDatePickerFromDate(),
    searchOrderToDate: new Date(),
    orderRows: {} as IOrderRow[],
    orderDetailsPopupOpen: false,
  } as IAdminOrderSearchState);

  const reducerState = useSelector(
    (s: RootState) => {
      return {
        order: s.order,
        authentication: s.authentication,
      };
    },
    (prev, next) => prev.order.isLoading === next.order.isLoading && prev.order.adminOrderRows === next.order.adminOrderRows
  );

  useEffect(() => {
    if (!IsAuth()) {
      navigate(`/se/${translate(LabelKeys.SignInUrl)}`);
    }
  }, [reducerState.authentication.hasBeenSignedOut]);

  useEffect(() => {
    const fromDate = new Date();
    fromDate.setDate(new Date().getDate() - 60);
    dispatch(
      OrderActions.SearchOrdersRequest({
        fromDate: fromDate,
        toDate: new Date(),
        searchParameter: adminOrderSearchState.searchParameter,
        skip: currentTablePage == 0 ? 0 : currentTablePage * rowsPerPage,
        take: rowsPerPage,
      })
    );

    if (reducerState.authentication.signedInUserEmail?.length <= 0) {
      const jwt = Cookies.get(CookieConstants.GBVJwt);
      if (jwt == undefined) return;

      const decodedJwt = jwt_decode(jwt) as any;
      if (decodedJwt == undefined) return;
      if (decodedJwt?.Email?.length > 0) {
        dispatch(AuthenticationActions.SetSignedInUserEmail({ email: decodedJwt?.Email } as ISetSignedInUserEmailRequest));
      }
    }
  }, []);

  useEffect(() => {
    const fromDate = new Date();
    fromDate.setDate(new Date().getDate() - 60);
    dispatch(
      OrderActions.SearchOrdersRequest({
        fromDate: fromDate,
        toDate: new Date(),
        searchParameter: adminOrderSearchState.searchParameter,
        skip: currentTablePage == 0 ? 0 : currentTablePage * rowsPerPage,
        take: rowsPerPage,
      })
    );
  }, [rowsPerPage, currentTablePage]);

  useEffect(() => {
    if (reducerState.order?.adminOrderRows?.length > 0) {
      handleFieldOnChange(FieldNames.orderRows, reducerState.order.adminOrderRows);
    }
  }, [reducerState.order?.adminOrderRows]);

  function GetDatePickerFromDate(): Date {
    const fromDate = new Date();
    fromDate.setDate(new Date().getDate() - 60);
    return fromDate;
  }

  function handleSearchedOrdersClicked(selectedRow: ICustomOrder): void {
    if (selectedRow) {
      dispatch(OrderActions.SearchSingleOrderRequest({ orderId: selectedRow.id }));
      dispatch(TransactionActions.GetTransactionsRequest({ orderId: selectedRow.id } as IGetTransactionsRequest));
    }
    //props.onChangeSubviewTriggered(AdminSubview.OrderDetails);
    setAdminOrderSearchState((state) => ({
      ...state,
      ["orderDetailsPopupOpen"]: true,
    }));
  }

  function handleFieldOnChange(fieldName: FieldNames, value: string | Date | boolean | number | undefined | IOrderRow | IOrderRow[]) {
    setAdminOrderSearchState((state) => ({
      ...state,
      [fieldName]: value,
    }));
  }

  function handleSearchOrders() {
    dispatch(
      OrderActions.SearchOrdersRequest({
        fromDate: adminOrderSearchState.searchOrderFromDate,
        toDate: adminOrderSearchState.searchOrderToDate,
        searchParameter: adminOrderSearchState.searchParameter,
        skip: currentTablePage == 0 ? 0 : currentTablePage * rowsPerPage,
        take: rowsPerPage,
      })
    );
  }

  function ResetAllOrderValues() {
    dispatch(OrderActions.ResetStepperState());
    dispatch(OrderActions.ResetStepOneState());
    dispatch(OrderActions.ResetStepTwoState());
    dispatch(OrderActions.ResetStepThreeState());
    dispatch(OrderActions.ResetStepFourState());
    dispatch(OrderActions.ResetStepFiveState());
    dispatch(OrderActions.ResetStepSixState());
  }

  function GetTableBodyData(searchedOrders: IOrderRow[]): ICustomOrder[] {
    let customOrders: ICustomOrder[] = [];

    if (searchedOrders.length > 0) {
      searchedOrders.map((item) => {
        const order = {
          id: item.id,
          createdDate: item.createdDate,
          orderDate: item.orderDate,
          name: item.name,
          status: OrderStatusConverter(item.status, currentLanguage),
          customWebPage: item.customWebPage == true ? translate(LabelKeys.Yes) : translate(LabelKeys.No),
          paymentMethod: translate(PaymentMethod[item.paymentMethod]?.toLowerCase()),
          sendBy: translate(SendBy[item.sendBy]?.toLowerCase()),
          orderType: translate(OrderType[item.orderType]?.toLowerCase()),
        };
        customOrders.push(order);
      });
    }
    return customOrders;
  }

  return (
    <div style={{ display: "flex" }}>
      {!isMobile && (
        <Grid item xs={2} sm={1} md={1} lg={1} xl={1}>
          <AdminSidebar activeSubview={AdminSubview.Order} />
        </Grid>
      )}
      {reducerState.authentication?.isLoading && <ProcessCircle />}
      <Grid container item xs={12} sm={12} md={12} lg={12} xl={12} justifyContent={"center"}>
        {isMobile && (
          <Grid item xs={11}>
            <AdminMenu />
          </Grid>
        )}
        <Grid item xs={11} sm={11} md={11} lg={11} xl={11} className={classes.subviewContent} style={{ paddingTop: isMobile ? 0 : 50 }}>
          <Grid container item xs={12} sm={12} md={12} lg={12} xl={12}>
            {reducerState.order?.isLoading && <ProcessCircle />}
            <Grid item xs={10} sm={6} md={6} lg={6} xl={6} style={{ paddingBottom: 10, float: "left" }}>
              <Typography variant="h2" style={{ paddingBottom: 10, float: "left" }}>
                {translate(LabelKeys.SearchOrderTitle)}
              </Typography>
            </Grid>
            <Grid item xs={2} sm={6} md={6} lg={6} xl={6} style={{ paddingBottom: 10 }}>
              <CSToolbar
                className={classes.newOrderToolbar}
                enableAdd
                disableAdd={false}
                iconSize={30}
                addTooltipText={translate(LabelKeys.CreateNewOrder)}
                onAddClicked={() => {
                  ResetAllOrderValues();
                  navigate(`/${GetCurrentLanguageAsText()}/${translate(LabelKeys.AdminUrl)}/${translate(LabelKeys.OrderUrl)}`);
                }}
              />
            </Grid>
            <Grid container item xs={12} sm={12} md={12} lg={12} xl={12} className={classes.leftContent}>
              <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                <CSTextField
                  id="searchOrdersString"
                  label={translate(LabelKeys.SearchString)}
                  type="text"
                  placeholder={`${translate(LabelKeys.SearchOrder)}`}
                  onChange={(e) => handleFieldOnChange(FieldNames.searchParameter, e.target.value)}
                  onEnterKeyDown={() => handleSearchOrders()}
                />
              </Grid>
              <Grid item xs={12} sm={2} md={2} lg={2} xl={2} className={classes.fromDateOrderSearch}>
                <CSDatePicker
                  id={"fromDate"}
                  label={translate(LabelKeys.FromDate)}
                  todayLabel={translate(LabelKeys.Today)}
                  fullWidth
                  showTodayBtn={true}
                  showToolbar={false}
                  value={adminOrderSearchState.searchOrderFromDate}
                  okLabel={translate(LabelKeys.Ok)}
                  cancelLabel={translate(LabelKeys.Cancel)}
                  onChange={(e) => handleFieldOnChange(FieldNames.searchOrderFromDate, e)}
                />
              </Grid>
              <Grid item xs={12} sm={2} md={2} lg={2} xl={2} className={classes.toDateOrderSearch}>
                <CSDatePicker
                  id={"toDate"}
                  label={translate(LabelKeys.ToDate)}
                  todayLabel={translate(LabelKeys.Today)}
                  fullWidth
                  showTodayBtn={true}
                  showToolbar={false}
                  minDate={adminOrderSearchState.searchOrderFromDate}
                  value={adminOrderSearchState.searchOrderToDate}
                  okLabel={translate(LabelKeys.Ok)}
                  cancelLabel={translate(LabelKeys.Cancel)}
                  onChange={(e) => handleFieldOnChange(FieldNames.searchOrderToDate, e)}
                />
              </Grid>
            </Grid>
            <Grid
              container
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              style={{ paddingTop: 10, display: "flex" }}
              alignItems="center"
              spacing={1}
            >
              <Grid item xs={12} sm={4} md={2} lg={2} xl={2} style={{ paddingTop: 20 }}>
                <Button
                  id="searchOrderBtn"
                  style={{
                    height: 56,
                    width: "100%",
                    cursor: "pointer",
                    color: "#fff",
                    borderRadius: 0,
                    backgroundColor: "#000",
                  }}
                  onClick={() => handleSearchOrders()}
                >
                  <div style={{ margin: "auto", textTransform: "none" }}>{`${translate(LabelKeys.SearchOrderBtn)}`}</div>
                </Button>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ paddingTop: 20 }}>
              <CSReadOnlyTable
                columns={searchOrderHeaders}
                showTimeWithDate={true}
                rows={GetTableBodyData(reducerState.order.adminOrderRows)}
                onRowClick={(e, selectedRow, selectedRows) => handleSearchedOrdersClicked(selectedRow)}
                enableRowClick={true}
                maxHeight={600}
                totalItems={reducerState.order.adminOrderRowsTotalItems}
                enablePagination
                rowsPerPage={rowsPerPage}
                currentPage={currentTablePage}
                onCurrentPageChanged={(e, page) => setCurrentTablePage(page)}
                onRowsPerPageChanged={(e) => {
                  setRowsPerPage(Number(e.target.value));
                  setCurrentTablePage(0);
                }}
              />
            </Grid>
            {adminOrderSearchState.orderDetailsPopupOpen && (
              <OrderDetailsPopup
                onGoBackToOrderPageClicked={(x) =>
                  setAdminOrderSearchState((state) => ({
                    ...state,
                    ["orderDetailsPopupOpen"]: x,
                  }))
                }
              />
            )}
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}

export default AdminOrder;
