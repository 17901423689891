import { makeStyles } from "@mui/styles";
import { theme } from "../../appTheme";

export const TableStyles = makeStyles({
  editableCheckbox: { marginTop: 15 },
  evenClassName: {
    backgroundColor: theme.palette.grey[200],
    "&:hover": {
      backgroundColor: "#979797 !important",
    },
  },
  oddClassName: {
    backgroundColor: theme.palette.grey[300],
    "&:hover": {
      backgroundColor: "#979797 !important",
    },
  },
  confirmDialogWarning: {
    margin: "auto",
    paddingRight: 10,
    paddingTop: 2,
  },
  tableRow: {
    cursor: "pointer",
  },
});

export const DataSheetGridStyles = makeStyles({
  root: {
    minHeight: 500,
  },
});
