import React, { useEffect, useState } from "react";
import { Grid, Button, useMediaQuery, Typography } from "@mui/material";
import { TemplateStyles } from "./styles";
import CSTextField from "../../../../components/Input/Textfield/CSTextField";
import { useTranslation } from "react-i18next";
import { LabelKeys } from "../../../../Utils/enums/labelKeys";
import {
  ICreateMessageTemplateRequest,
  IDeleteMessageTemplateRequest,
  IGetMessageTemplatesRequest,
  IUpdateMessageTemplateRequest,
} from "../../../../Modules/Products/types";
import { theme } from "../../../../appTheme";
import CSDropDown from "../../../../components/DropDown/CSDropDown";
import { useDispatch, useSelector } from "react-redux";
import { DropDownStandardTemplateId, IFilterOption } from "../../../../components/DropDown/types";
import { ProductActions } from "../../../../Modules/Products/actions";
import { RootState } from "../../../../store";
import SnackbarActions from "../../../../components/Snackbar/actions";
import ProcessCircle from "../../../../components/ProcessCircle/ProcessCircle";
import CSToolbar from "../../../../components/Toolbar/CSToolbar";
import { FieldNames } from "../../../../Utils/types";
import CSCheckbox from "../../../../components/Input/Forms/CSCheckbox";
import AdminMenu from "../../../../components/Menu/AdminMenu";
import AdminSidebar from "../../AdminSidebar";
import { AdminSubview } from "../../types";
import { AdminStyles } from "../../styles";
import jwt_decode from "jwt-decode";
import Cookies from "js-cookie";
import { AuthenticationActions } from "../../../../Modules/Authentication/actions";
import { ISetSignedInUserEmailRequest } from "../../../../Modules/Authentication/types";
import { CookieConstants } from "../../../../Utils/Labels/types";

interface ITemplateState {
  id: number;
  name: string;
  message: string;
  isStandard: boolean;
  selectedMessageTemplate: IFilterOption;
  selectedTemplateType: IFilterOption;
  isAddMode: boolean;
  openPDF: boolean;
  templateOptions: IFilterOption[];
  templateTypeOptions: IFilterOption[];
}

interface ITemplateErrorState {
  templateError: boolean;
  templateTitleError: boolean;
}

const initState: ITemplateState = {
  id: 0,
  name: "",
  message: "",
  isStandard: false,
  selectedMessageTemplate: {
    id: DropDownStandardTemplateId,
    name: "None",
    value: "None",
    checked: false,
  } as IFilterOption,
  selectedTemplateType: { id: 0, name: "Rubrik", value: "heading" } as IFilterOption,
  openPDF: false,
  templateOptions: [] as IFilterOption[],
  templateTypeOptions: [
    { id: 1, name: "Rubrik", value: "heading" },
    { id: 2, name: "Generell", value: "general" },
    { id: 3, name: "Rubrik fysiska kort", value: "physicalCardHeading" },
    { id: 4, name: "Personligt meddelande fysiska kort", value: "physicalCardCustomMessage" },
  ] as IFilterOption[],
} as ITemplateState;

export default function AdminTemplate() {
  const classes = TemplateStyles();
  const classes2 = AdminStyles();
  const { t: translate } = useTranslation();
  const dispatch = useDispatch();
  const [templateState, setTemplateState] = useState(initState);
  const [mode, setMode] = useState({
    isAddMode: false,
    isEditMode: false,
  });

  const [templateErrorState, setTemplateErrorState] = useState({
    templateError: false,
    templateTitleError: false,
  } as ITemplateErrorState);
  const isMobile = useMediaQuery(theme.breakpoints.only("xs"));

  function handleFieldOnChange(fieldName: FieldNames, value: number | string | boolean | IFilterOption | IFilterOption[]) {
    setTemplateState((state) => ({
      ...state,
      [fieldName]: value,
    }));
  }

  function handleModeOnChange(fieldName: FieldNames, value: boolean) {
    setMode((state) => ({
      ...state,
      [fieldName]: value,
    }));
  }

  function handleErrorStateChange(fieldName: FieldNames, value: boolean) {
    setTemplateErrorState((state) => ({
      ...state,
      [fieldName]: value,
    }));
  }

  const reducerState = useSelector(
    (s: RootState) => {
      return {
        product: s.product,
        authentication: s.authentication,
      };
    },
    (prev, next) => prev.product.isLoading === next.product.isLoading && prev.product.messageTemplates === next.product.messageTemplates
  );

  useEffect(() => {
    if (reducerState.product?.messageTemplates?.length <= 0)
      dispatch(ProductActions.GetMessageTemplatesRequest({ templateTypes: undefined } as IGetMessageTemplatesRequest));

    if (reducerState.authentication.signedInUserEmail?.length <= 0) {
      const jwt = Cookies.get(CookieConstants.GBVJwt);
      if (jwt == undefined) return;

      const decodedJwt = jwt_decode(jwt) as any;
      if (decodedJwt == undefined) return;
      if (decodedJwt?.Email?.length > 0) {
        dispatch(AuthenticationActions.SetSignedInUserEmail({ email: decodedJwt?.Email } as ISetSignedInUserEmailRequest));
      }
    }
  }, []);

  useEffect(() => {
    handleFieldOnChange(FieldNames.templateOptions, getMessageTemplates());
    handleFieldOnChange(FieldNames.selectedMessageTemplate, initState.selectedMessageTemplate);
  }, [reducerState.product?.messageTemplates]);

  // useEffect(() => {}, [templateState.selectedMessageTemplate?.name]);

  useEffect(() => {
    if (templateState.openPDF) {
      let url = URL.createObjectURL(reducerState.product.previewPDFFile);
      window.open(url);
      handleFieldOnChange(FieldNames.openPDF, false);
    }
  }, [reducerState.product.previewPDFFile]);

  function getMessageTemplates(): IFilterOption[] {
    if (reducerState.product.messageTemplates?.length > 0) {
      let options: IFilterOption[] = [];
      //Add all options from DB
      reducerState.product.messageTemplates?.map((template) => {
        options?.push({
          id: template.id,
          name: template.name,
          value: template.message,
          checked: template.isStandard,
          templateType: template.type,
        });
      });
      return options;
    }
  }

  function handleTemplateChanged(e: IFilterOption): void {
    handleFieldOnChange(FieldNames.id, e.id);
    handleFieldOnChange(FieldNames.name, e.name);
    handleFieldOnChange(FieldNames.message, e.value);
    handleFieldOnChange(FieldNames.isStandard, e.checked);
    handleFieldOnChange(FieldNames.selectedMessageTemplate, e);

    const type = templateState.templateTypeOptions.find((x) => x.id == e.templateType);

    if (type != undefined) {
      handleFieldOnChange(FieldNames.selectedTemplateType, type);
    }
  }

  function handleTemplateTypeChanged(e: IFilterOption): void {
    handleFieldOnChange(FieldNames.selectedTemplateType, e);
  }

  function onAddTemplateClicked(): void {
    handleModeOnChange(FieldNames.isAddMode, true);
    handleModeOnChange(FieldNames.isEditMode, false);
    setTemplateState(initState);
    handleFieldOnChange(FieldNames.templateOptions, getMessageTemplates());
    handleFieldOnChange(FieldNames.selectedMessageTemplate, initState.selectedMessageTemplate);
    handleFieldOnChange(FieldNames.selectedTemplateType, initState.selectedTemplateType);
  }

  function onEditTemplateClicked(): void {
    handleModeOnChange(FieldNames.isAddMode, false);
    handleModeOnChange(FieldNames.isEditMode, true);
    handleFieldOnChange(FieldNames.id, templateState.selectedMessageTemplate.id);
    handleFieldOnChange(FieldNames.name, templateState.selectedMessageTemplate.name);
    handleFieldOnChange(FieldNames.message, templateState.selectedMessageTemplate.value);
    handleFieldOnChange(FieldNames.isStandard, templateState.selectedMessageTemplate.checked);
    handleFieldOnChange(FieldNames.selectedTemplateType, templateState.selectedTemplateType);
  }

  function onDeleteTemplateClicked(): void {
    {
      dispatch(
        ProductActions.DeleteMessageTemplateRequest({
          id: templateState.selectedMessageTemplate.id,
        } as IDeleteMessageTemplateRequest)
      );
    }
    setTemplateState(initState);
  }

  function onSaveTemplateClicked(): void {
    let valid: boolean = true;
    if (templateState.message?.length <= 0) {
      valid = false;
      handleErrorStateChange(FieldNames.templateError, true);
    } else {
      handleErrorStateChange(FieldNames.templateError, false);
    }
    if (templateState.name?.length <= 0) {
      valid = false;
      handleErrorStateChange(FieldNames.templateTitleError, true);
    } else {
      handleErrorStateChange(FieldNames.templateTitleError, false);
    }

    if (!valid) {
      dispatch(SnackbarActions.ShowError(translate(LabelKeys.CheckMandatoryField)));
      return;
    }

    if (mode.isAddMode) {
      dispatch(
        ProductActions.CreateMessageTemplateRequest({
          message: templateState.message,
          name: templateState.name,
          isStandard: templateState.isStandard,
          type: templateState.selectedTemplateType.id,
        } as ICreateMessageTemplateRequest)
      );
    } else if (mode.isEditMode) {
      dispatch(
        ProductActions.UpdateMessageTemplatesRequest({
          id: templateState.selectedMessageTemplate?.id,
          title: templateState?.name,
          message: templateState?.message,
          isStandard: templateState.isStandard,
          type: templateState.selectedTemplateType.id,
        } as IUpdateMessageTemplateRequest)
      );
    }

    handleModeOnChange(FieldNames.isAddMode, false);
    handleModeOnChange(FieldNames.isEditMode, false);
    setTemplateState(initState);
  }

  function onCancelTemplateClicked(): void {
    if (mode.isAddMode) {
      handleFieldOnChange(FieldNames.selectedMessageTemplate, initState.selectedMessageTemplate);
    } else if (mode.isEditMode) {
      handleFieldOnChange(FieldNames.id, templateState.selectedMessageTemplate?.id);
      handleFieldOnChange(FieldNames.name, templateState.selectedMessageTemplate?.name);
      handleFieldOnChange(FieldNames.message, templateState.selectedMessageTemplate?.value);
      handleFieldOnChange(FieldNames.isStandard, templateState.selectedMessageTemplate?.checked);
    }

    handleModeOnChange(FieldNames.isEditMode, false);
    handleModeOnChange(FieldNames.isAddMode, false);
  }

  return (
    <div style={{ display: "flex" }}>
      {!isMobile && (
        <Grid item xs={2} sm={1} md={1} lg={1} xl={1}>
          <AdminSidebar activeSubview={AdminSubview.Template} />
        </Grid>
      )}
      {reducerState.authentication?.isLoading && <ProcessCircle />}
      <Grid container item xs={12} sm={12} md={12} lg={12} xl={12} justifyContent={"center"}>
        {isMobile && (
          <Grid item xs={11}>
            <AdminMenu />
          </Grid>
        )}
        <Grid item xs={11} sm={11} md={11} lg={11} xl={11} className={classes2.subviewContent} style={{ paddingTop: isMobile ? 0 : 50 }}>
          <Grid container item justifyContent="center" xs={12} sm={12} md={12} lg={12} xl={12}>
            {reducerState.product.isLoading && <ProcessCircle />}
            <Grid container item justifyContent="flex-start" xs={12} sm={12} md={12} lg={12} xl={12}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ paddingBottom: 10, float: "left" }}>
                <Typography variant="h2" style={{ paddingBottom: 10, float: "left" }}>
                  {translate(LabelKeys.Templates)}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={8} lg={7} xl={7} style={{ paddingTop: 20 }}>
                <CSToolbar
                  className={classes.toolbar2}
                  enableAdd
                  enableEdit
                  enableDelete
                  disableAdd={mode.isEditMode}
                  disableEdit={mode.isAddMode || templateState.selectedMessageTemplate?.id === DropDownStandardTemplateId}
                  disableDelete={
                    templateState.selectedMessageTemplate?.checked === true ||
                    mode.isAddMode ||
                    mode.isEditMode ||
                    templateState.selectedMessageTemplate?.id === DropDownStandardTemplateId
                  }
                  addTooltipText={translate(LabelKeys.Add)}
                  editTooltipText={translate(LabelKeys.Edit)}
                  deleteTooltipText={translate(LabelKeys.Delete)}
                  onAddClicked={() => onAddTemplateClicked()}
                  onEditClicked={() => onEditTemplateClicked()}
                  onDeleteClicked={() => onDeleteTemplateClicked()}
                />
                <CSDropDown
                  disabled={mode.isAddMode || mode.isEditMode}
                  value={templateState.selectedMessageTemplate?.name}
                  filterOptions={templateState.templateOptions}
                  onStateChanged={(e) => handleTemplateChanged(e)}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                style={{
                  paddingTop: 20,
                  opacity: !mode.isAddMode && !mode.isEditMode ? 0.5 : 1,
                }}
              >
                <CSCheckbox
                  id="isStandardTemplate"
                  disabled={!mode.isAddMode && !mode.isEditMode}
                  label={"Standard"}
                  checked={templateState.isStandard}
                  onChange={(e) => handleFieldOnChange(FieldNames.isStandard, e.target.checked)}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={8} lg={7} xl={7} style={{ paddingTop: 30 }}>
                <CSDropDown
                  disabled={!mode.isAddMode && !mode.isEditMode}
                  value={templateState.selectedTemplateType?.name}
                  label={translate(LabelKeys.TemplateType)}
                  filterOptions={templateState.templateTypeOptions}
                  onStateChanged={(e) => handleTemplateTypeChanged(e)}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={8} lg={7} xl={7} style={{ paddingTop: 30 }}>
                <CSTextField
                  id="templateName"
                  disabled={!mode.isAddMode && !mode.isEditMode}
                  placeholder={translate(LabelKeys.Name)}
                  value={templateState?.name}
                  onChange={(e) => handleFieldOnChange(FieldNames.name, e.target.value)}
                  error={templateErrorState.templateTitleError}
                  errorMessage={"Värdet får inte vara tomt"}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={8} lg={7} xl={7} style={{ paddingTop: 30 }}>
                <CSTextField
                  id="messageEditor"
                  disabled={!mode.isAddMode && !mode.isEditMode}
                  placeholder={translate(LabelKeys.WriteMessageHere)}
                  multiline
                  rows={15}
                  value={templateState?.message}
                  onChange={(e) => handleFieldOnChange(FieldNames.message, e.target.value)}
                  error={templateErrorState.templateError}
                  errorMessage={"Värdet får inte vara tomt"}
                />
              </Grid>
              <Grid container className={classes.buttonContainer} xs={12} sm={12} md={8} lg={7} xl={7}>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={6}
                  lg={6}
                  xl={6}
                  style={{ opacity: !mode.isAddMode && !mode.isEditMode ? 0.5 : 1, paddingRight: isMobile ? 0 : 8 }}
                >
                  <Button
                    id="saveTemplateBtn"
                    disabled={!mode.isAddMode && !mode.isEditMode}
                    style={{
                      height: 50,
                      width: "100%",
                      cursor: "pointer",
                      color: "#fff",
                      display: "flex",
                      borderRadius: 0,
                      backgroundColor: "#000",
                    }}
                    onClick={() => onSaveTemplateClicked()}
                  >
                    <div style={{ margin: "auto", textTransform: "none" }}>{`${translate(LabelKeys.Save)}`}</div>
                  </Button>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={6}
                  lg={6}
                  xl={6}
                  className={classes.cancelBtn}
                  style={{ opacity: !mode.isAddMode && !mode.isEditMode ? 0.5 : 1, paddingLeft: isMobile ? 0 : 8 }}
                >
                  <Button
                    id="updateReseller"
                    disabled={!mode.isAddMode && !mode.isEditMode}
                    style={{
                      height: 50,
                      width: "100%",
                      cursor: "pointer",
                      color: "#fff",
                      display: "flex",
                      borderRadius: 0,
                      backgroundColor: "#000",
                    }}
                    onClick={() => onCancelTemplateClicked()}
                  >
                    <div style={{ margin: "auto", textTransform: "none" }}>{`${translate(LabelKeys.Cancel)}`}</div>
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}
