import { IStandardResponse } from "./../Orders/types";
import { ResellerActionTypes } from "./actionTypes";
import {
  ICreateResellerContactRequest,
  ICreateResellerContactResponse,
  ICreateResellerRequest,
  ICreateResellerResponse,
  IDeleteResellerContactRequest,
  IDeleteResellerLogoRequest,
  IDeleteResellerHomePageImageRequest,
  IGetResellerAdminContactInfoRequest,
  IGetResellerAdminContactInfoResponse,
  IGetResellerByCodeRequest,
  IGetResellerByCodeResponse,
  IGetResellerContactRequest,
  IGetResellerContactResponse,
  IGetResellerIncomingOrderEmailsRequest,
  IGetResellerIncomingOrderEmailsResponse,
  IGetResellerLogoByCodeRequest,
  IGetResellerLogoByCodeResponse,
  IGetResellerLogoResponse,
  IGetResellerRequest,
  IGetResellerResponse,
  IGetResellerHomePageImageRequest,
  IGetResellerHomePageImageResponse,
  ISearchResellersRequest,
  ISearchResellersResponse,
  ISearchSingleResellerRequest,
  ISearchSingleResellerResponse,
  IUpdateResellerContactRequest,
  IUpdateResellerContactResponse,
  IUpdateResellerIncomingOrderEmailsRequest,
  IUpdateResellerLogoRequest,
  IUpdateResellerRequest,
  IUpdateResellerResponse,
  IUpdateResellerHomePageImageRequest,
  IGetResellerHomePageImageByCodeRequest,
  IGetResellerHomePageImageByCodeResponse,
  IGetResellerLogoRequest,
  ISearchResellerOrdersRequest as ISearchResellerCoupledOrdersRequest,
  ISearchResellerOrdersResponse as ISearchResellerCoupledOrdersResponse,
  ISearchSingleOrderRequest,
  IGetResellersResponse,
  IGetResellersRequest,
  IGetResellerContactPageInformtaionRequest,
  IGetResellerContactPageInformtaionResponse,
  IUpdateResellerContactPageInformtaionRequest,
  IGetResellerHomePageTextsByCodeRequest,
  IGetResellerHomePageTextsRequest,
  IUpdateResellerHomePageTextsRequest,
  IGetResellerHomePageTextsByCodeResponse,
  IGetResellerHomePageTextsResponse,
  IGetResellerContactsRequest,
  IGetResellerContactsResponse,
  RequestFrom,
  IValidateResellerEmailRequest,
  IValidateResellerEmailResponse,
  IGetAllResellersResponse,
} from "./types";
import { axiosInstance, GetBaseURL } from "./../../Utils/network/axios/index";
import { ORDER_API, RESELLER_API } from "../../Utils/network/axios/urls";
import { all, call, put, SagaReturnType, takeLatest } from "redux-saga/effects";
import SnackbarActions from "../../components/Snackbar/actions";
import { ErrorReason } from "../../Utils/enums/enums";
import { ResellerActions } from "./actions";
import { IOrderDetailsRow, IResellerDetailsRow, IResellerRow } from "../../views/Admin/types";
import { OrderActions } from "../Orders/actions";

export default function* ResellerWatcher() {
  yield all([
    takeLatest(ResellerActionTypes.CREATE_RESELLER_REQUEST, CreateResellerWorker),
    takeLatest(ResellerActionTypes.GET_RESELLER_REQUEST, GetResellerWorker),
    takeLatest(ResellerActionTypes.UPDATE_RESELLER_REQUEST, UpdateResellerWorker),
    takeLatest(ResellerActionTypes.CREATE_RESELLER_CONTACT_REQUEST, CreateResellerContactWorker),
    takeLatest(ResellerActionTypes.GET_RESELLER_CONTACT_REQUEST, GetResellerContactWorker),
    takeLatest(ResellerActionTypes.UPDATE_RESELLER_CONTACT_REQUEST, UpdateResellerContactWorker),
    takeLatest(ResellerActionTypes.GET_RESELLER_ADMIN_CONTACT_INFO_REQUEST, GetResellerAdminContactInfoWorker),
    takeLatest(ResellerActionTypes.GET_RESELLER_LOGO_REQUEST, GetResellerLogoWorker),
    takeLatest(ResellerActionTypes.GET_RESELLER_LOGO_BY_CODE_REQUEST, GetResellerLogoByCodeWorker),
    takeLatest(ResellerActionTypes.UPDATE_RESELLER_LOGO_REQUEST, UpdateResellerLogoWorker),
    takeLatest(ResellerActionTypes.GET_RESELLER_BY_CODE_REQUEST, GetResellerByCodeWorker),
    takeLatest(ResellerActionTypes.DELETE_RESELLER_LOGO_REQUEST, DeleteResellerLogoWorker),
    takeLatest(ResellerActionTypes.GET_RESELLERS_REQUEST, GetResellersWorker),
    takeLatest(ResellerActionTypes.GET_ALL_RESELLERS_REQUEST, GetAllResellersWorker),
    takeLatest(ResellerActionTypes.SEARCH_RESELLERS_REQUEST, SearchResellersWorker),
    takeLatest(ResellerActionTypes.SEARCH_SINGLE_RESELLER_REQUEST, SearchSingleResellerWorker),
    takeLatest(ResellerActionTypes.DELETE_RESELLER_CONTACT_REQUEST, DeleteResellerContactWorker),
    takeLatest(ResellerActionTypes.GET_INCOMING_RESELLER_ORDER_EMAILS_REQUEST, GetResellerIncomingOrderEmailsWorker),
    takeLatest(ResellerActionTypes.UPDATE_INCOMING_RESELLER_ORDER_EMAILS_REQUEST, UpdateResellerIncomingOrderEmailsWorker),
    takeLatest(ResellerActionTypes.GET_RESELLER_HOME_PAGE_IMAGE_REQUEST, GetResellerHomePageImageWorker),
    takeLatest(ResellerActionTypes.GET_RESELLER_HOME_PAGE_IMAGE_BY_CODE_REQUEST, GetResellerHomePageImageByCodeWorker),
    takeLatest(ResellerActionTypes.UPDATE_RESELLER_HOME_PAGE_IMAGE_REQUEST, UpdateResellerHomePageImageWorker),
    takeLatest(ResellerActionTypes.DELETE_RESELLER_HOME_PAGE_IMAGE_REQUEST, DeleteResellerHomePageImageWorker),
    takeLatest(ResellerActionTypes.SEARCH_RESELLER_COUPLED_ORDERS_REQUEST, SearchResellerCoupledOrdersWorker),
    takeLatest(ResellerActionTypes.SEARCH_SINGLE_ORDER_REQUEST, SearchSingleOrderWorker),
    takeLatest(ResellerActionTypes.GET_RESELLER_CONTACT_PAGE_INFORMATION_AUTH_REQUEST, GetResellerContactPageInformationAuthWorker),
    takeLatest(ResellerActionTypes.UPDATE_RESELLER_CONTACT_PAGE_INFORMATION_REQUEST, UpdateResellerContactPageInformationWorker),
    takeLatest(ResellerActionTypes.GET_RESELLER_CONTACT_PAGE_INFORMATION_REQUEST, GetResellerContactPageInformationWorker),
    takeLatest(ResellerActionTypes.GET_RESELLER_HOME_PAGE_TEXTS_REQUEST, GetResellerHomePageTextsWorker),
    takeLatest(ResellerActionTypes.GET_RESELLER_HOME_PAGE_TEXTS_BY_CODE_REQUEST, GetResellerHomePageTextsByCodeWorker),
    takeLatest(ResellerActionTypes.UPDATE_RESELLER_HOME_PAGE_TEXTS_REQUEST, UpdateResellerHomePageTextsWorker),
    takeLatest(ResellerActionTypes.GET_RESELLER_CONTACTS_REQUEST, GetResellerContactsWorker),
    takeLatest(ResellerActionTypes.VALIDATE_RESELLER_EMAIL_REQUEST, ValidateResellerEmailWorker),
  ]);
}

//WORKERS
//Create reseller
function* CreateResellerWorker({ request }: ReturnType<typeof ResellerActions.CreateResellerRequest>) {
  try {
    const response: CreateResellerServiceResponse = yield call(createReseller, request);
    if (response && response.error === ErrorReason.Unknown) {
      yield put(ResellerActions.CreateResellerSuccess(response as ICreateResellerResponse));
      yield put(SnackbarActions.ShowSuccess("Återförsäljare skapad"));
      yield put(ResellerActions.SearchResellersRequest({ searchParameter: "", isActive: false } as ISearchResellersRequest));
    } else {
      if (response.error === ErrorReason.InvalidEmail) {
        yield put(ResellerActions.CreateResellerError());
        yield put(SnackbarActions.ShowError("En eller flera emails har fel format"));
      } else if (response.error === ErrorReason.ResellerAlreadyExists) {
        yield put(ResellerActions.CreateResellerError());
        yield put(SnackbarActions.ShowError("En återförsäljare med angiven email finns redan"));
      } else {
        yield put(ResellerActions.CreateResellerError());
        yield put(SnackbarActions.ShowError("Något gick fel när återförsäljaren skulle skapas, vänligen försök igen"));
      }
    }
  } catch (error) {
    yield put(ResellerActions.CreateResellerError());
    yield put(SnackbarActions.ShowError("Något gick fel när återförsäljaren skulle skapas, vänligen försök igen"));
  }
}

//Get reseller
function* GetResellerWorker({ request }: ReturnType<typeof ResellerActions.GetResellerRequest>) {
  try {
    const response: GetResellerServiceResponse = yield call(getReseller, request);
    if (response) {
      yield put(ResellerActions.GetResellerSuccess(response as IGetResellerResponse));
    } else {
      yield put(ResellerActions.GetResellerError());
      yield put(SnackbarActions.ShowError("Något gick fel när återförsäljaren hämtades, vänligen försök igen"));
    }
  } catch (error) {
    yield put(ResellerActions.GetResellerError());
    yield put(SnackbarActions.ShowError("Något gick fel när återförsäljaren hämtades, vänligen försök igen"));
  }
}

//Get reseller by code
function* GetResellerByCodeWorker({ request }: ReturnType<typeof ResellerActions.GetResellerByCodeRequest>) {
  try {
    const response: GetResellerByCodeServiceResponse = yield call(getResellerByCode, request);
    if (response) {
      yield put(ResellerActions.GetResellerByCodeSuccess(response as IGetResellerByCodeResponse));
    } else {
      yield put(ResellerActions.GetResellerByCodeError());
      yield put(SnackbarActions.ShowError("Kunde inte hämta information om återförsäljaren, vänligen försök igen"));
    }
  } catch (error) {
    yield put(ResellerActions.GetResellerByCodeError());
    yield put(SnackbarActions.ShowError("Kunde inte hämta information om återförsäljaren, vänligen försök igen"));
  }
}

//Get reseller admin contact info
function* GetResellerAdminContactInfoWorker({ request }: ReturnType<typeof ResellerActions.GetResellerAdminContactInfoRequest>) {
  try {
    const response: GetResellerAdminContactInfoServiceResponse = yield call(getResellerAdminContactInfo, request);
    if (response) {
      yield put(ResellerActions.GetResellerAdminContactInfoSuccess(response as IGetResellerAdminContactInfoResponse));
    } else {
      yield put(ResellerActions.GetResellerAdminContactInfoError());
      yield put(SnackbarActions.ShowError("Kunde inte hämta kontaktinformation, vänligen försök igen."));
    }
  } catch (error) {
    yield put(ResellerActions.GetResellerAdminContactInfoError());
    yield put(SnackbarActions.ShowError("Kunde inte hämta kontaktinformation, vänligen försök igen."));
  }
}

//Get reseller admin contact info
function* GetResellerIncomingOrderEmailsWorker({ request }: ReturnType<typeof ResellerActions.GetResellerIncomingOrderEmailsRequest>) {
  try {
    const response: GetResellerIncomingOrderEmailsServiceResponse = yield call(getResellerIncomingOrderEmails, request);
    if (response) {
      yield put(ResellerActions.GetResellerIncomingOrderEmailsSuccess(response as IGetResellerIncomingOrderEmailsResponse));
    } else {
      yield put(ResellerActions.GetResellerIncomingOrderEmailsError());
      yield put(SnackbarActions.ShowError("Kunde inte hämta emails för inkomna orders, vänligen försök igen."));
    }
  } catch (error) {
    yield put(ResellerActions.GetResellerIncomingOrderEmailsError());
    yield put(SnackbarActions.ShowError("Kunde inte hämta emails för inkomna orders, vänligen försök igen."));
  }
}

//Get reseller admin contact info
function* UpdateResellerIncomingOrderEmailsWorker({
  request,
}: ReturnType<typeof ResellerActions.UpdateResellerIncomingOrderEmailsRequest>) {
  try {
    yield call(updateResellerIncomingOrderEmails, request);
    yield put(ResellerActions.UpdateResellerIncomingOrderEmailsSuccess());
    yield put(SnackbarActions.ShowSuccess("E-mails uppdaterade"));
  } catch (error) {
    yield put(ResellerActions.UpdateResellerIncomingOrderEmailsError());
    yield put(SnackbarActions.ShowError("Kunde inte uppdatera emails för inkomna orders, vänligen försök igen."));
  }
}

//Get reseller logo
function* GetResellerLogoWorker({ request }: ReturnType<typeof ResellerActions.GetResellerLogoRequest>) {
  try {
    const response: GetResellerLogoServiceResponse = yield call(getResellerLogo, request);
    if (response) {
      yield put(ResellerActions.GetResellerLogoSuccess(response as IGetResellerLogoResponse));
    } else {
      yield put(ResellerActions.GetResellerLogoError());
      yield put(SnackbarActions.ShowError("Kunde inte hämta logotyp, vänligen försök igen."));
    }
  } catch (error) {
    yield put(ResellerActions.GetResellerLogoError());
    yield put(SnackbarActions.ShowError("Kunde inte hämta logotyp, vänligen försök igen."));
  }
}

//Delete reseller logo
function* DeleteResellerLogoWorker({ request }: ReturnType<typeof ResellerActions.DeleteResellerLogoRequest>) {
  try {
    const response: DeleteResellerLogoServiceResponse = yield call(deleteResellerLogotype, request);
    if (response && response.error == ErrorReason.Unknown) {
      yield put(ResellerActions.DeleteResellerLogoSuccess());
      yield put(SnackbarActions.ShowSuccess("Logotyp borttagen."));
    } else {
      yield put(ResellerActions.DeleteResellerLogoError());
      yield put(SnackbarActions.ShowError("Kunde inte ta bort logotyp, vänligen försök igen."));
    }
  } catch (error) {
    yield put(ResellerActions.DeleteResellerLogoError());
    yield put(SnackbarActions.ShowError("Kunde inte ta bort logotyp, vänligen försök igen."));
  }
}

//Get reseller logo by code
function* GetResellerLogoByCodeWorker({ request }: ReturnType<typeof ResellerActions.GetResellerLogoByCodeRequest>) {
  try {
    const response: GetResellerLogoByCodeServiceResponse = yield call(getResellerLogoByCode, request);
    if (response) {
      yield put(ResellerActions.GetResellerLogoByCodeSuccess(response as IGetResellerLogoByCodeResponse));
    } else {
      yield put(ResellerActions.GetResellerLogoByCodeError());
    }
  } catch (error) {
    yield put(ResellerActions.GetResellerLogoByCodeError());
    yield put(SnackbarActions.ShowError("Kunde inte hämta logotyp, vänligen försök igen."));
  }
}

//Update reseller logo
function* UpdateResellerLogoWorker({ request }: ReturnType<typeof ResellerActions.UpdateResellerLogoRequest>) {
  try {
    yield call(updateResellerLogo, request);
    yield put(ResellerActions.UpdateResellerLogoSuccess());
    yield put(ResellerActions.GetResellerLogoRequest({ resellerId: request.resellerId } as IGetResellerLogoRequest));
    yield put(SnackbarActions.ShowSuccess("Uppdatering klar."));
  } catch (error) {
    yield put(ResellerActions.UpdateResellerLogoError());
    yield put(SnackbarActions.ShowError("Kunde inte uppdatera logotyp, vänligen försök igen."));
  }
}

//Get reseller home page image
function* GetResellerHomePageImageWorker({ request }: ReturnType<typeof ResellerActions.GetResellerHomePageImageRequest>) {
  try {
    const response: GetResellerHomePageImageServiceResponse = yield call(getResellerHomePageImage, request);
    if (response) {
      yield put(ResellerActions.GetResellerHomePageImageSuccess(response as IGetResellerHomePageImageResponse));
    } else {
      yield put(ResellerActions.GetResellerHomePageImageError());
      yield put(SnackbarActions.ShowError("Kunde inte hämta bild för startsida, vänligen ladda om sidan och försök igen."));
    }
  } catch (error) {
    yield put(ResellerActions.GetResellerHomePageImageError());
    yield put(SnackbarActions.ShowError("Kunde inte hämta bild för startsida, vänligen ladda om sidan och försök igen."));
  }
}

//Get reseller home page image by code
function* GetResellerHomePageImageByCodeWorker({ request }: ReturnType<typeof ResellerActions.GetResellerHomePageImageByCodeRequest>) {
  try {
    const response: GetResellerHomePageImageByCodeServiceResponse = yield call(getResellerHomePageImageByCode, request);
    if (response) {
      yield put(ResellerActions.GetResellerHomePageImageByCodeSuccess(response as IGetResellerHomePageImageByCodeResponse));
    } else {
      yield put(ResellerActions.GetResellerHomePageImageByCodeError());
    }
  } catch (error) {
    yield put(ResellerActions.GetResellerHomePageImageByCodeError());
    yield put(SnackbarActions.ShowError("Kunde inte hämta bild för startsida, vänligen ladda om sidan och försök igen."));
  }
}

//Update reseller home page image
function* UpdateResellerHomePageImageWorker({ request }: ReturnType<typeof ResellerActions.UpdateResellerHomePageImageRequest>) {
  try {
    yield call(updateResellerHomePageImage, request);
    yield put(ResellerActions.UpdateResellerHomePageImageSuccess());
    yield put(SnackbarActions.ShowSuccess("Uppladdning klar."));
    yield put(ResellerActions.GetResellerHomePageImageRequest({ resellerId: request.resellerId } as IGetResellerHomePageImageRequest));
  } catch (error) {
    yield put(ResellerActions.UpdateResellerHomePageImageError());
    yield put(SnackbarActions.ShowError("Bild för startsida kunde inte uppdateras, vänligen försök igen."));
  }
}

//Delete reseller home page image
function* DeleteResellerHomePageImageWorker({ request }: ReturnType<typeof ResellerActions.DeleteResellerHomePageImageRequest>) {
  try {
    const response: DeleteResellerHomePageImageServiceResponse = yield call(deleteResellerHomePageImage, request);
    if (response && response.error == ErrorReason.Unknown) {
      yield put(ResellerActions.DeleteResellerHomePageImageSuccess());
      yield put(SnackbarActions.ShowSuccess("Bild för startsidan är nu borttagen."));
      yield put(ResellerActions.GetResellerHomePageImageRequest({ resellerId: request.resellerId } as IGetResellerHomePageImageRequest));
    } else {
      yield put(ResellerActions.DeleteResellerHomePageImageError());
      yield put(SnackbarActions.ShowError("Kunde inte uppdatera bild för startsida, vänligen försök igen."));
    }
  } catch (error) {
    yield put(ResellerActions.DeleteResellerHomePageImageError());
    yield put(SnackbarActions.ShowError("Kunde inte uppdatera bild för startsida, vänligen försök igen."));
  }
}

//Update reseller
function* UpdateResellerWorker({ request }: ReturnType<typeof ResellerActions.UpdateResellerRequest>) {
  try {
    const response: UpdateResellerServiceResponse = yield call(updateReseller, request);
    if (response && response.error === ErrorReason.Unknown) {
      yield put(ResellerActions.UpdateResellerSuccess(response as IUpdateResellerResponse));
      yield put(ResellerActions.SearchSingleResellerRequest({ resellerId: request.id } as ISearchSingleResellerRequest));
    } else {
      if (response.error === ErrorReason.InvalidEmail) {
        yield put(ResellerActions.UpdateResellerError());
        yield put(SnackbarActions.ShowError("En eller flera emails har fel format"));
      } else if (response.error === ErrorReason.ResellerNull) {
        yield put(ResellerActions.UpdateResellerError());
        yield put(SnackbarActions.ShowError("Återförsäljaren som skulle uppdateras kunde inte hittas"));
      } else {
        yield put(ResellerActions.UpdateResellerError());
        yield put(SnackbarActions.ShowError("Något gick fel när återförsäljaren skulle uppdateras, vänligen försök igen"));
      }
    }
  } catch (error) {
    yield put(ResellerActions.UpdateResellerError());
    yield put(SnackbarActions.ShowError("Något gick fel när återförsäljaren skulle uppdateras, vänligen försök igen"));
  }
}

//Create reseller contact
function* CreateResellerContactWorker({ request }: ReturnType<typeof ResellerActions.CreateResellerContactRequest>) {
  try {
    const response: CreateResellerContactServiceResponse = yield call(createResellerContact, request);
    if (response && response.error === ErrorReason.Unknown) {
      yield put(ResellerActions.CreateResellerContactSuccess(response as ICreateResellerContactResponse));
      yield put(SnackbarActions.ShowSuccess("Kontakt för återförsäljare skapad"));
      if (request.requestFrom == RequestFrom.UserAdmin) {
        yield put(ResellerActions.SearchSingleResellerRequest({ resellerId: request.resellerId } as ISearchSingleResellerRequest));
      } else if (request.requestFrom == RequestFrom.ResellerAdmin) {
        yield put(ResellerActions.GetResellerContactsRequest({ resellerId: request.resellerId } as IGetResellerContactsRequest));
      } else {
      }
    } else {
      yield put(ResellerActions.CreateResellerContactError(response.error));
    }
  } catch (error) {
    yield put(ResellerActions.CreateResellerContactError(ErrorReason.Unknown));
  }
}

//Get reseller contact
function* GetResellerContactWorker({ request }: ReturnType<typeof ResellerActions.GetResellerContactRequest>) {
  try {
    const response: GetResellerContactServiceResponse = yield call(getResellerContact, request);
    if (response) {
      yield put(ResellerActions.GetResellerContactSuccess(response as IGetResellerContactResponse));
    } else {
      yield put(ResellerActions.GetResellerContactError());
      yield put(SnackbarActions.ShowError("Något gick fel när kontakten skulle hämtas, vänligen försök igen"));
    }
  } catch (error) {
    yield put(ResellerActions.GetResellerContactError());
    yield put(SnackbarActions.ShowError("Något gick fel när kontakten skulle hämtas, vänligen försök igen"));
  }
}

//Update reseller contact
function* UpdateResellerContactWorker({ request }: ReturnType<typeof ResellerActions.UpdateResellerContactRequest>) {
  try {
    const response: UpdateResellerContactServiceResponse = yield call(updateResellerContact, request);
    if (response && response.error === ErrorReason.Unknown) {
      yield put(ResellerActions.UpdateResellerContactSuccess(response as IUpdateResellerContactResponse));

      //Update table after update
      yield put(ResellerActions.SearchSingleResellerSuccess({} as ISearchSingleResellerRequest));
      if (request.requestFrom == RequestFrom.UserAdmin) {
        yield put(ResellerActions.SearchSingleResellerRequest({ resellerId: request.resellerId } as ISearchSingleResellerRequest));
      } else if (request.requestFrom == RequestFrom.ResellerAdmin) {
        yield put(ResellerActions.GetResellerContactsRequest({ resellerId: request.resellerId } as IGetResellerContactsRequest));
      } else {
      }
    } else {
      yield put(ResellerActions.UpdateResellerContactError(response.error));
    }
  } catch (error) {
    yield put(ResellerActions.CreateResellerContactError(ErrorReason.Unknown));
  }
}

//Delete reseller contact
function* DeleteResellerContactWorker({ request }: ReturnType<typeof ResellerActions.DeleteResellerContactRequest>) {
  try {
    yield call(deleteResellerContact, request);
    yield put(ResellerActions.DeleteResellerContactSuccess());

    //Update table after delete
    if (request.requestFrom == RequestFrom.UserAdmin) {
      yield put(ResellerActions.SearchSingleResellerSuccess({} as ISearchSingleResellerRequest));
      yield put(ResellerActions.SearchSingleResellerRequest({ resellerId: request.resellerId } as ISearchSingleResellerRequest));
    } else if (request.requestFrom == RequestFrom.ResellerAdmin) {
      yield put(ResellerActions.GetResellerContactsRequest({ resellerId: request.resellerId } as IGetResellerContactsRequest));
    } else {
    }
  } catch (error) {
    yield put(ResellerActions.DeleteResellerContactError());
  }
}

//Get resellers
function* GetResellersWorker({ request }: ReturnType<typeof ResellerActions.GetResellersRequest>) {
  try {
    const response: GetResellersServiceResponse = yield call(getResellers, request);
    if (response) {
      yield put(ResellerActions.GetResellersSuccess(response as IGetResellersResponse));
    } else {
      yield put(ResellerActions.GetResellersError());
      yield put(SnackbarActions.ShowError("Could not retrieve resellers, please try again later."));
    }
  } catch (error) {
    yield put(ResellerActions.GetResellersError());
    yield put(SnackbarActions.ShowError("Could not retrieve resellers, please try again later."));
  }
}

//Get all resellers
function* GetAllResellersWorker({}: ReturnType<typeof ResellerActions.GetAllResellersRequest>) {
  try {
    const response: GetAllResellersServiceResponse = yield call(getAllResellers);
    if (response) {
      yield put(ResellerActions.GetAllResellersSuccess(response));
    } else {
      yield put(ResellerActions.GetAllResellersError());
      yield put(SnackbarActions.ShowError("Could not retrieve resellers, please try again later."));
    }
  } catch (error) {
    yield put(ResellerActions.GetAllResellersError());
    yield put(SnackbarActions.ShowError("Could not retrieve resellers, please try again later."));
  }
}

//Search single reseller

//Search resellers
function* SearchResellersWorker({ request }: ReturnType<typeof ResellerActions.SearchResellersRequest>) {
  try {
    const response: SearchResellersServiceResponse = yield call(searchResellers, request);
    if (response) {
      yield put(ResellerActions.SearchResellersSuccess(response as ISearchResellersResponse));
    } else {
      yield put(ResellerActions.SearchResellersError());
    }
  } catch (error) {
    yield put(ResellerActions.SearchResellersError());
  }
}

//Search single reseller
function* SearchSingleResellerWorker({ request }: ReturnType<typeof ResellerActions.SearchSingleResellerRequest>) {
  try {
    const response: SearchSingleResellerServiceResponse = yield call(searchSingleReseller, request);
    if (response) {
      yield put(ResellerActions.SearchSingleResellerSuccess(response as ISearchSingleResellerResponse));
    } else {
      yield put(ResellerActions.SearchSingleResellerError());
    }
  } catch (error) {
    yield put(ResellerActions.CreateResellerContactError(ErrorReason.Unknown));
  }
}

//Search coupled reseller orders
function* SearchResellerCoupledOrdersWorker({ request }: ReturnType<typeof ResellerActions.SearchResellerCoupledOrdersRequest>) {
  try {
    const response: SearchResellerOrdersServiceResponse = yield call(searchResellerCoupledOrders, request);
    if (response && response.error == ErrorReason.Unknown) {
      yield put(ResellerActions.SearchResellerCoupledOrdersSuccess(response as ISearchResellerCoupledOrdersResponse));
    } else {
      yield put(ResellerActions.SearchResellerCoupledOrdersError());
      yield put(SnackbarActions.ShowError("Could not load coupled orders, please try again later."));
    }
  } catch (error) {
    yield put(ResellerActions.SearchResellerCoupledOrdersError());
    yield put(SnackbarActions.ShowError("Could not load coupled orders, please try again later."));
  }
}

//Admin
function* SearchSingleOrderWorker({ request }: ReturnType<typeof ResellerActions.SearchSingleOrderRequest>) {
  try {
    const response: SearchSingleOrderServiceResponse = yield call(searchSingleOrder, request);
    if (response) {
      yield put(ResellerActions.SearchSingleOrderSuccess(response));
    } else {
      yield put(ResellerActions.SearchSingleOrderError());
    }
  } catch (error) {
    yield put(ResellerActions.SearchSingleOrderError());
  }
}

//Get reseller contact page information auth
function* GetResellerContactPageInformationAuthWorker({
  request,
}: ReturnType<typeof ResellerActions.GetResellerContactPageInformationAuthRequest>) {
  try {
    const response: GetResellerContactPageInformationServiceResponse = yield call(getResellerContactPageInformationAuth, request);
    if (response) {
      yield put(ResellerActions.GetResellerContactPageInformationAuthSuccess(response as IGetResellerContactPageInformtaionResponse));
    } else {
      yield put(ResellerActions.GetResellerContactPageInformationAuthError());
      yield put(SnackbarActions.ShowError("Något gick fel när kontaktinformationen skulle hämtas, vänligen försök igen"));
    }
  } catch (error) {
    yield put(ResellerActions.GetResellerContactPageInformationAuthError());
    yield put(SnackbarActions.ShowError("Något gick fel när kontaktinformationen skulle hämtas, vänligen försök igen"));
  }
}

//Get reseller contact page information
function* GetResellerContactPageInformationWorker({
  request,
}: ReturnType<typeof ResellerActions.GetResellerContactPageInformationRequest>) {
  try {
    const response: GetResellerContactPageInformationServiceResponse = yield call(getResellerContactPageInformation, request);
    if (response) {
      yield put(ResellerActions.GetResellerContactPageInformationSuccess(response as IGetResellerContactPageInformtaionResponse));
    } else {
      yield put(ResellerActions.GetResellerContactPageInformationError());
      yield put(SnackbarActions.ShowError("Något gick fel när kontaktinformationen skulle hämtas, vänligen försök igen"));
    }
  } catch (error) {
    yield put(ResellerActions.GetResellerContactPageInformationError());
    yield put(SnackbarActions.ShowError("Något gick fel när kontaktinformationen skulle hämtas, vänligen försök igen"));
  }
}

//Update reseller contact page information
function* UpdateResellerContactPageInformationWorker({
  request,
}: ReturnType<typeof ResellerActions.UpdateResellerContactPageInformationRequest>) {
  try {
    const response: UpdateResellerContactPageInformationServiceResponse = yield call(updateResellerContactPageInformation, request);
    if (response && response.error === ErrorReason.Unknown) {
      yield put(ResellerActions.UpdateResellerContactPageInformationSuccess(response as IStandardResponse));
      yield put(
        ResellerActions.GetResellerContactPageInformationAuthRequest({
          resellerId: request.resellerId,
        } as IGetResellerContactPageInformtaionRequest)
      );
      yield put(SnackbarActions.ShowSuccess("Kontaktinformationen uppdaterades"));
    } else {
      yield put(ResellerActions.UpdateResellerContactPageInformationError());
    }
  } catch (error) {
    yield put(ResellerActions.UpdateResellerContactPageInformationError());
  }
}

//RESELLER TEXTS
function* GetResellerHomePageTextsWorker({ request }: ReturnType<typeof ResellerActions.GetResellerHomePageTextsRequest>) {
  try {
    const response: GetResellerHomePageTextsServiceResponse = yield call(getResellerHomePageTexts, request);
    if (response) {
      yield put(ResellerActions.GetResellerHomePageTextsSuccess(response));
    } else {
      yield put(ResellerActions.GetResellerHomePageTextsError());
    }
  } catch (error) {
    yield put(ResellerActions.GetResellerHomePageTextsError());
    yield put(SnackbarActions.ShowError("Kunde inte hämta texter för startsida."));
  }
}

function* GetResellerHomePageTextsByCodeWorker({ request }: ReturnType<typeof ResellerActions.GetResellerHomePageTextsByCodeRequest>) {
  try {
    const response: GetResellerHomePageTextsByCodeServiceResponse = yield call(getResellerHomePageTextsByCode, request);
    if (response) {
      yield put(ResellerActions.GetResellerHomePageTextsByCodeSuccess(response));
    } else {
      yield put(ResellerActions.GetResellerHomePageTextsByCodeError());
    }
  } catch (error) {
    yield put(ResellerActions.GetResellerHomePageTextsByCodeError());
    yield put(SnackbarActions.ShowError("Kunde inte hämta texter för startsida."));
  }
}

function* UpdateResellerHomePageTextsWorker({ request }: ReturnType<typeof ResellerActions.UpdateResellerHomePageTextsRequest>) {
  try {
    const response: UpdateResellerHomePageTextsServiceResponse = yield call(updateResellerHomePageTexts, request);
    if (response) {
      yield put(ResellerActions.UpdateResellerHomePageTextsSuccess(response));
      yield put(ResellerActions.GetResellerHomePageTextsRequest({ resellerId: request.resellerId } as IGetResellerHomePageTextsRequest));
      yield put(SnackbarActions.ShowSuccess("Texterna är sparade."));
    } else {
      yield put(ResellerActions.UpdateResellerHomePageTextsError());
      yield put(SnackbarActions.ShowError("Kunde inte uppdatera texter för startsida."));
    }
  } catch (error) {
    yield put(ResellerActions.UpdateResellerHomePageTextsError());
  }
}

function* GetResellerContactsWorker({ request }: ReturnType<typeof ResellerActions.GetResellerContactsRequest>) {
  try {
    const response: GetResellerContactsServiceResponse = yield call(getResellerContacts, request);
    if (response) {
      yield put(ResellerActions.GetResellerContactsSuccess(response));
    } else {
      yield put(ResellerActions.GetResellerContactsError());
      yield put(SnackbarActions.ShowError("Kunde inte hämta kontakterna."));
    }
  } catch (error) {
    yield put(ResellerActions.GetResellerContactsError());
  }
}

function* ValidateResellerEmailWorker({ request }: ReturnType<typeof ResellerActions.ValidateResellerEmailRequest>) {
  try {
    const response: ValidateResellerEmailServiceResponse = yield call(validateResellerEmail, request);
    if (response && response.error === ErrorReason.Unknown) {
      yield put(ResellerActions.ValidateResellerEmailSuccess(response));
      yield put(SnackbarActions.ShowSuccess("Valideringskod skickad till angiven emailadress"));
    } else {
      if (response.error === ErrorReason.ResellerValidationFail) {
        yield put(SnackbarActions.ShowError("Angiven emailadress finns inte upplagd som återförsäljare"));
        yield put(ResellerActions.ValidateResellerEmailError());
      } else {
        yield put(SnackbarActions.ShowError("Något gick fel under valideringen, vänligen försök igen"));
        yield put(ResellerActions.ValidateResellerEmailError());
      }
    }
  } catch (error) {
    yield put(ResellerActions.ValidateResellerEmailError());
    yield put(SnackbarActions.ShowError("Något gick fel under valideringen, vänligen försök igen"));
  }
}

// API:S
export const createReseller: CreateResellerService = async (request: ICreateResellerRequest) => {
  axiosInstance.defaults.baseURL = GetBaseURL() + RESELLER_API;
  return await axiosInstance.post("", request).then((resp) => resp.data);
};

export const getResellerByCode: GetResellerByCodeService = async (request: IGetResellerByCodeRequest) => {
  axiosInstance.defaults.baseURL = GetBaseURL() + RESELLER_API;
  return await axiosInstance.get(`info/${request.code}`).then((resp) => resp.data);
};

export const getResellerLogo: GetResellerLogoService = async (request: IGetResellerLogoRequest) => {
  axiosInstance.defaults.baseURL = GetBaseURL() + RESELLER_API;
  return await axiosInstance.get("logotype", { params: { resellerId: request.resellerId } }).then((resp) => resp.data);
};

export const deleteResellerLogotype: DeleteResellerLogotypeService = async (request: IDeleteResellerLogoRequest) => {
  axiosInstance.defaults.baseURL = GetBaseURL() + RESELLER_API;
  return await axiosInstance.delete(`logotype`, { params: { resellerId: request.resellerId } }).then((x) => x.data);
};

export const getResellerLogoByCode: GetResellerLogoByCodeService = async (request: IGetResellerLogoByCodeRequest) => {
  axiosInstance.defaults.baseURL = GetBaseURL() + RESELLER_API;
  return await axiosInstance.get(`logotype/${request.resellerCode}`).then((resp) => resp.data);
};

export const updateResellerLogo: UpdateResellerLogoService = async (request: IUpdateResellerLogoRequest) => {
  axiosInstance.defaults.baseURL = GetBaseURL() + RESELLER_API;
  return await axiosInstance.put("logotype", request).then((resp) => resp.data);
};

export const getResellerAdminContactInfo: GetResellerAdminContactInfoService = async (request: IGetResellerAdminContactInfoRequest) => {
  axiosInstance.defaults.baseURL = GetBaseURL() + RESELLER_API;
  return await axiosInstance.post("contact/info", request).then((resp) => resp.data);
};

export const getReseller: GetResellerService = async (request: IGetResellerRequest) => {
  axiosInstance.defaults.baseURL = GetBaseURL() + RESELLER_API;
  return await axiosInstance.post("get", request).then((resp) => resp.data);
};

export const updateReseller: UpdateResellerService = async (request: IUpdateResellerRequest) => {
  axiosInstance.defaults.baseURL = GetBaseURL() + RESELLER_API;
  return await axiosInstance.put("", request).then((resp) => resp.data);
};

//Reseller contact
export const createResellerContact: CreateResellerContactService = async (request: ICreateResellerContactRequest) => {
  axiosInstance.defaults.baseURL = GetBaseURL() + RESELLER_API;
  return await axiosInstance.post("contact", request).then((resp) => resp.data);
};

export const getResellerContact: GetResellerContactService = async (request: IGetResellerContactRequest) => {
  axiosInstance.defaults.baseURL = GetBaseURL() + RESELLER_API;
  return await axiosInstance.post("contact/get", request).then((resp) => resp.data);
};

export const updateResellerContact: UpdateResellerContactService = async (request: IUpdateResellerContactRequest) => {
  axiosInstance.defaults.baseURL = GetBaseURL() + RESELLER_API;
  return await axiosInstance.put("contact", request).then((resp) => resp.data);
};

export const getResellers: GetResellersService = async (request: IGetResellersRequest) => {
  axiosInstance.defaults.baseURL = GetBaseURL() + RESELLER_API;
  return await axiosInstance.post("search", request).then((resp) => resp.data);
};

export const getAllResellers: GetAllResellersService = async () => {
  axiosInstance.defaults.baseURL = GetBaseURL() + RESELLER_API;
  return await axiosInstance.get("all").then((resp) => resp.data);
};

export const searchResellers: SearchResellersService = async (request: ISearchResellersRequest) => {
  axiosInstance.defaults.baseURL = GetBaseURL() + RESELLER_API;
  return await axiosInstance.post("search", request).then((resp) => resp.data);
};

export const searchSingleReseller: SearchSingleResellerService = async (request: ISearchSingleResellerRequest) => {
  axiosInstance.defaults.baseURL = GetBaseURL() + RESELLER_API;
  return await axiosInstance.get(`details/${request.resellerId}`).then((resp) => resp.data);
};

export const deleteResellerContact: DeleteResellerContactService = async (request: IDeleteResellerContactRequest) => {
  axiosInstance.defaults.baseURL = GetBaseURL() + RESELLER_API;
  return await axiosInstance.delete(`contact/${request.resellerContactId}`).then((x) => x.data);
};

export const getResellerIncomingOrderEmails: GetResellerIncomingOrderEmailsService = async (
  request: IGetResellerIncomingOrderEmailsRequest
) => {
  axiosInstance.defaults.baseURL = GetBaseURL() + RESELLER_API;
  return await axiosInstance.get(`emails/${request.resellerId}`).then((x) => x.data);
};

export const updateResellerIncomingOrderEmails: UpdateResellerIncomingOrderEmailsService = async (
  request: IUpdateResellerIncomingOrderEmailsRequest
) => {
  axiosInstance.defaults.baseURL = GetBaseURL() + RESELLER_API;
  return await axiosInstance.put(`emails`, request).then((x) => x.data);
};

export const getResellerHomePageImage: GetResellerHomePageImageService = async (request: IGetResellerHomePageImageRequest) => {
  axiosInstance.defaults.baseURL = GetBaseURL() + RESELLER_API;
  return await axiosInstance.get(`homepageimage`, { params: { resellerId: request.resellerId } }).then((x) => x.data);
};

export const getResellerHomePageImageByCode: GetResellerHomePageImageByCodeService = async (
  request: IGetResellerHomePageImageByCodeRequest
) => {
  axiosInstance.defaults.baseURL = GetBaseURL() + RESELLER_API;
  return await axiosInstance.get(`homepageimagebycode`, { params: { code: request.code, isMobile: request.isMobile } }).then((x) => x.data);
};

export const updateResellerHomePageImage: UpdateResellerHomePageImageService = async (request: IUpdateResellerHomePageImageRequest) => {
  axiosInstance.defaults.baseURL = GetBaseURL() + RESELLER_API;
  return await axiosInstance.put(`homepageimage`, request).then((x) => x.data);
};

export const deleteResellerHomePageImage: DeleteResellerHomePageImageService = async (request: IDeleteResellerHomePageImageRequest) => {
  axiosInstance.defaults.baseURL = GetBaseURL() + RESELLER_API;
  return await axiosInstance.delete(`homepageimage`, { params: { resellerId: request.resellerId } }).then((x) => x.data);
};

export const searchResellerCoupledOrders: SearchResellerCoupledOrdersService = async (request: ISearchResellerCoupledOrdersRequest) => {
  axiosInstance.defaults.baseURL = GetBaseURL() + RESELLER_API;
  return await axiosInstance.post(`resellerCoupledOrders`, request).then((x) => x.data);
};

export const searchSingleOrder: SearchSingleOrderService = async (request: ISearchSingleOrderRequest) => {
  axiosInstance.defaults.baseURL = GetBaseURL() + ORDER_API;
  return await axiosInstance.get(`details/${request.orderId}`).then((resp) => resp.data);
};

export const getResellerContactPageInformationAuth: GetResellerContactPageInformationService = async (
  request: IGetResellerContactPageInformtaionRequest
) => {
  axiosInstance.defaults.baseURL = GetBaseURL() + RESELLER_API;
  return await axiosInstance
    .get(`contactPage/auth`, { params: { resellerId: request.resellerId, resellerCode: request.resellerCode } })
    .then((x) => x.data);
};

export const getResellerContactPageInformation: GetResellerContactPageInformationService = async (
  request: IGetResellerContactPageInformtaionRequest
) => {
  axiosInstance.defaults.baseURL = GetBaseURL() + RESELLER_API;
  return await axiosInstance
    .get(`contactPage`, { params: { resellerId: request.resellerId, resellerCode: request.resellerCode } })
    .then((x) => x.data);
};

export const updateResellerContactPageInformation: UpdateResellerContactPageInformationService = async (
  request: IUpdateResellerContactPageInformtaionRequest
) => {
  axiosInstance.defaults.baseURL = GetBaseURL() + RESELLER_API;
  return await axiosInstance.put(`contactPage`, request).then((x) => x.data);
};

export const validateResellerEmail: ValidateResellerEmailService = async (request: IValidateResellerEmailRequest) => {
  axiosInstance.defaults.baseURL = GetBaseURL() + RESELLER_API;
  return await axiosInstance.post("validate/email", request).then((resp) => resp.data);
};

//RESELLER TEXTS
export const getResellerHomePageTexts: GetResellerHomePageTextsService = async (request: IGetResellerHomePageTextsRequest) => {
  axiosInstance.defaults.baseURL = GetBaseURL() + RESELLER_API;
  return await axiosInstance.get(`homepagetexts`, { params: { resellerId: request.resellerId } }).then((x) => x.data);
};
export const getResellerHomePageTextsByCode: GetResellerHomePageTextsByCodeService = async (
  request: IGetResellerHomePageTextsByCodeRequest
) => {
  axiosInstance.defaults.baseURL = GetBaseURL() + RESELLER_API;
  return await axiosInstance.get(`homepagetexts/${request.code}`).then((x) => x.data);
};
export const updateResellerHomePageTexts: UpdateResellerHomePageTextsService = async (request: IUpdateResellerHomePageTextsRequest) => {
  axiosInstance.defaults.baseURL = GetBaseURL() + RESELLER_API;
  return await axiosInstance.put(`homepagetexts`, request).then((x) => x.data);
};

export const getResellerContacts: GetResellerContactsService = async (request: IGetResellerContactsRequest) => {
  axiosInstance.defaults.baseURL = GetBaseURL() + RESELLER_API;
  return await axiosInstance.get(`contacts/${request.resellerId}`).then((x) => x.data);
};

// SERVICES
export interface CreateResellerService {
  (request: ICreateResellerRequest): Promise<ICreateResellerResponse>;
}
export interface GetResellerByCodeService {
  (request: IGetResellerByCodeRequest): Promise<IGetResellerByCodeResponse>;
}
export interface GetResellerLogoService {
  (request: IGetResellerLogoRequest): Promise<IGetResellerLogoResponse>;
}
export interface DeleteResellerLogotypeService {
  (request: IDeleteResellerLogoRequest): Promise<IStandardResponse>;
}
export interface GetResellerLogoByCodeService {
  (request: IGetResellerLogoByCodeRequest): Promise<IGetResellerLogoByCodeResponse>;
}
export interface UpdateResellerLogoService {
  (request: IUpdateResellerLogoRequest): Promise<void>;
}
export interface GetResellerAdminContactInfoService {
  (request: IGetResellerAdminContactInfoRequest): Promise<IGetResellerAdminContactInfoResponse>;
}
export interface GetResellerService {
  (request: IGetResellerRequest): Promise<IGetResellerResponse>;
}
export interface UpdateResellerService {
  (request: IUpdateResellerRequest): Promise<IUpdateResellerResponse>;
}
export interface CreateResellerContactService {
  (request: ICreateResellerContactRequest): Promise<ICreateResellerContactResponse>;
}
export interface GetResellerContactService {
  (request: IGetResellerContactRequest): Promise<IGetResellerContactResponse>;
}
export interface UpdateResellerContactService {
  (request: IUpdateResellerContactRequest): Promise<IUpdateResellerContactResponse>;
}
export interface SearchResellersService {
  (request: ISearchResellersRequest): Promise<IResellerRow[]>;
}
export interface SearchSingleResellerService {
  (request: ISearchSingleResellerRequest): Promise<IResellerDetailsRow>;
}
export interface DeleteResellerContactService {
  (request: IDeleteResellerContactRequest): Promise<void>;
}
export interface GetResellerIncomingOrderEmailsService {
  (request: IGetResellerIncomingOrderEmailsRequest): Promise<IGetResellerIncomingOrderEmailsResponse>;
}
export interface UpdateResellerIncomingOrderEmailsService {
  (request: IUpdateResellerIncomingOrderEmailsRequest): Promise<void>;
}
export interface GetResellerHomePageImageService {
  (request: IGetResellerHomePageImageRequest): Promise<IGetResellerHomePageImageResponse>;
}
export interface GetResellerHomePageImageByCodeService {
  (request: IGetResellerHomePageImageByCodeRequest): Promise<IGetResellerHomePageImageByCodeResponse>;
}
export interface UpdateResellerHomePageImageService {
  (request: IUpdateResellerHomePageImageRequest): Promise<void>;
}
export interface DeleteResellerHomePageImageService {
  (request: IDeleteResellerHomePageImageRequest): Promise<IStandardResponse>;
}
export interface SearchResellerCoupledOrdersService {
  (request: ISearchResellerCoupledOrdersRequest): Promise<ISearchResellerCoupledOrdersResponse>;
}
export interface SearchSingleOrderService {
  (request: ISearchSingleOrderRequest): Promise<IOrderDetailsRow>;
}
export interface GetResellersService {
  (request: IGetResellersRequest): Promise<IGetResellersResponse>;
}
export interface GetResellerContactPageInformationService {
  (request: IGetResellerContactPageInformtaionRequest): Promise<IGetResellerContactPageInformtaionResponse>;
}
export interface UpdateResellerContactPageInformationService {
  (request: IUpdateResellerContactPageInformtaionRequest): Promise<IStandardResponse>;
}
export interface GetResellerHomePageTextsService {
  (request: IGetResellerHomePageTextsRequest): Promise<IGetResellerHomePageTextsResponse>;
}
export interface GetResellerHomePageTextsByCodeService {
  (request: IGetResellerHomePageTextsByCodeRequest): Promise<IGetResellerHomePageTextsByCodeResponse>;
}
export interface UpdateResellerHomePageTextsService {
  (request: IUpdateResellerHomePageTextsRequest): Promise<IStandardResponse>;
}
export interface GetResellerContactsService {
  (request: IGetResellerContactsRequest): Promise<IGetResellerContactsResponse>;
}
export interface ValidateResellerEmailService {
  (request: IValidateResellerEmailRequest): Promise<IValidateResellerEmailResponse>;
}
export interface GetAllResellersService {
  (): Promise<IGetAllResellersResponse>;
}

// RESPONSE
export type CreateResellerServiceResponse = SagaReturnType<typeof createReseller>;
export type GetResellerByCodeServiceResponse = SagaReturnType<typeof getResellerByCode>;
export type GetResellerLogoServiceResponse = SagaReturnType<typeof getResellerLogo>;
export type GetResellerLogoByCodeServiceResponse = SagaReturnType<typeof getResellerLogoByCode>;
export type GetResellerAdminContactInfoServiceResponse = SagaReturnType<typeof getResellerAdminContactInfo>;
export type GetResellerServiceResponse = SagaReturnType<typeof getReseller>;
export type GetResellersServiceResponse = SagaReturnType<typeof getResellers>;
export type UpdateResellerServiceResponse = SagaReturnType<typeof updateReseller>;
export type CreateResellerContactServiceResponse = SagaReturnType<typeof createResellerContact>;
export type GetResellerContactServiceResponse = SagaReturnType<typeof getResellerContact>;
export type UpdateResellerContactServiceResponse = SagaReturnType<typeof updateResellerContact>;
export type SearchResellersServiceResponse = SagaReturnType<typeof searchResellers>;
export type SearchSingleResellerServiceResponse = SagaReturnType<typeof searchSingleReseller>;
export type GetResellerIncomingOrderEmailsServiceResponse = SagaReturnType<typeof getResellerIncomingOrderEmails>;
export type GetResellerHomePageImageServiceResponse = SagaReturnType<typeof getResellerHomePageImage>;
export type GetResellerHomePageImageByCodeServiceResponse = SagaReturnType<typeof getResellerHomePageImageByCode>;
export type DeleteResellerHomePageImageServiceResponse = SagaReturnType<typeof deleteResellerHomePageImage>;
export type DeleteResellerLogoServiceResponse = SagaReturnType<typeof deleteResellerLogotype>;
export type SearchResellerOrdersServiceResponse = SagaReturnType<typeof searchResellerCoupledOrders>;
export type SearchSingleOrderServiceResponse = SagaReturnType<typeof searchSingleOrder>;
export type GetResellerContactPageInformationServiceResponse = SagaReturnType<typeof getResellerContactPageInformationAuth>;
export type UpdateResellerContactPageInformationServiceResponse = SagaReturnType<typeof updateResellerContactPageInformation>;
export type GetResellerHomePageTextsServiceResponse = SagaReturnType<typeof getResellerHomePageTexts>;
export type GetResellerHomePageTextsByCodeServiceResponse = SagaReturnType<typeof getResellerHomePageTextsByCode>;
export type UpdateResellerHomePageTextsServiceResponse = SagaReturnType<typeof updateResellerHomePageTexts>;
export type GetResellerContactsServiceResponse = SagaReturnType<typeof getResellerContacts>;
export type ValidateResellerEmailServiceResponse = SagaReturnType<typeof validateResellerEmail>;
export type GetAllResellersServiceResponse = SagaReturnType<typeof getAllResellers>;
