import createSagaMiddleware from "redux-saga";
import { createStore, applyMiddleware } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from "redux-thunk";
import { combineReducers } from "redux";
import reduxImmutableStateInvariant from "redux-immutable-state-invariant";
import { all, fork } from "redux-saga/effects";
import ProductReducer from "./Modules/Products/reducer";
import ProductWatcher from "./Modules/Products/saga";
import OrderReducer from "./Modules/Orders/reducer";
import OrderWatcher from "./Modules/Orders/saga";
import SnackbarReducer from "./components/Snackbar/reducer";
import AuthenticationReducer from "./Modules/Authentication/reducer";
import AuthenticationWatcher from "./Modules/Authentication/saga";
import InvoiceReducer from "./Modules/Invoice/reducer";
import InvoiceWatcher from "./Modules/Invoice/saga";
import ResellerReducer from "./Modules/Reseller/reducer";
import ResellerWatcher from "./Modules/Reseller/saga";
import CompanyReducer from "./Modules/Company/reducer";
import CompanyWatcher from "./Modules/Company/saga";
import TransactionReducer from "./Modules/Transaction/reducer";
import TransactionWatcher from "./Modules/Transaction/saga";

const appReducers = combineReducers({
  product: ProductReducer,
  order: OrderReducer,
  snackbar: SnackbarReducer,
  authentication: AuthenticationReducer,
  invoice: InvoiceReducer,
  reseller: ResellerReducer,
  company: CompanyReducer,
  transaction: TransactionReducer,
});

function* rootSaga() {
  yield all([
    fork(ProductWatcher),
    fork(OrderWatcher),
    fork(AuthenticationWatcher),
    fork(InvoiceWatcher),
    fork(ResellerWatcher),
    fork(CompanyWatcher),
    fork(TransactionWatcher),
  ]);
}

const sagaMiddleware = createSagaMiddleware();

const store = createStore(
  appReducers,
  composeWithDevTools(applyMiddleware(sagaMiddleware, reduxImmutableStateInvariant()), applyMiddleware(thunk))
);

export default store;

sagaMiddleware.run(rootSaga);

// // Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// // Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
