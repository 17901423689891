import React from "react";
import Button from "@mui/material/Button/Button";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store";
import { LabelKeys } from "../../Utils/enums/labelKeys";
import { OrderActions } from "../../Modules/Orders/actions";
import { FieldNames } from "../../Utils/types";
import { ICSNextPrevStepDesktopProps } from "./types";
import { Typography } from "@mui/material";
import { IsResellerAuth, IsAuth } from "../../Utils/authentication";
import { CookieConstants } from "../../Utils/Labels/types";
import Cookies from "js-cookie";

interface CSNextPrevStepProps {
  hideStepper: boolean;
}

export default function CSNextPrevStep(props1: CSNextPrevStepProps) {
  const dispatch = useDispatch();
  const reducerState = useSelector(
    (s: RootState) => {
      return {
        order: s.order,
      };
    },
    (prev, next) =>
      prev.order.stepperState === next.order.stepperState && prev.order.stepperState.errorSections === next.order.stepperState.errorSections
  );

  function onPrevStepClicked(): void {
    dispatch(
      OrderActions.SetStepperStateRequest({
        fieldName: FieldNames.activeStep,
        value: reducerState.order.stepperState.activeStep - 1,
      })
    );
  }
  function onNextStepClicked(): void {
    dispatch(
      OrderActions.SetStepperStateRequest({
        fieldName: FieldNames.activeStep,
        value: reducerState.order.stepperState.activeStep + 1,
      })
    );
  }

  return (
    <>
      {!props1.hideStepper && (
        <DesktopStepper
          activeStep={reducerState.order.stepperState.activeStep}
          onNextStepClicked={() => onNextStepClicked()}
          onPrevStepClicked={() => onPrevStepClicked()}
        />
      )}
    </>
  );
}

function DesktopStepper(props: ICSNextPrevStepDesktopProps) {
  const { t: translate } = useTranslation();
  const dispatch = useDispatch();
  const isAdmin =
    (IsResellerAuth() &&
      location.pathname.includes(
        `/${translate(LabelKeys.ResellerUrl)}/${translate(LabelKeys.AdminUrl)}/${translate(LabelKeys.OrderUrl)}`
      )) ||
    (IsAuth() && location.pathname.includes(`/${translate(LabelKeys.AdminUrl)}/${translate(LabelKeys.OrderUrl)}`));

  return (
    <div style={{ display: "flex" }}>
      {props.activeStep > 0 && (
        <div
          style={{
            cursor: "pointer",
            border: "1px solid #000",
            width: 118,
            height: 48,
            padding: 1,
          }}
        >
          <Button
            id={"previousBtn"}
            style={{ width: "100%", height: "100%" }}
            disabled={props.activeStep === 0}
            onClick={() => props.onPrevStepClicked()}
          >
            <Typography
              style={{
                color: "#000",
                textTransform: "none",
              }}
            >
              {translate(LabelKeys.Previous)}
            </Typography>
          </Button>
        </div>
      )}
      {props.activeStep <= 4 && props.activeStep > 0 && (
        <div
          style={{
            cursor: "pointer",
            backgroundColor: "#2eac77",
            marginLeft: 10,
            width: 118,
            height: 48,
            padding: 2,
          }}
        >
          <Button
            id={"nextBtn"}
            style={{ width: "100%", height: "100%" }}
            disabled={props.activeStep === 5}
            onClick={() => props.onNextStepClicked()}
          >
            <Typography
              style={{
                color: "#fff",
                textTransform: "none",
              }}
            >
              {translate(LabelKeys.Next)}
            </Typography>
          </Button>
        </div>
      )}
      {props.activeStep > 4 && (
        <div
          style={{
            cursor: "pointer",
            backgroundColor: "#2eac77",
            marginLeft: 16,
            width: 118,
            height: 48,
            padding: 2,
          }}
        >
          <Button
            id="confirmAndPayBtn"
            style={{ width: "100%", height: "100%" }}
            onClick={() =>
              dispatch(
                OrderActions.SetStepperStateRequest({
                  fieldName: FieldNames.readyToPay,
                  value: true,
                })
              )
            }
          >
            <Typography
              style={{
                color: "#fff",
                textTransform: "none",
              }}
            >
              {isAdmin || Cookies.get(CookieConstants.GBVReseller)?.length > 0 ? translate(LabelKeys.OrderBtn) : translate(LabelKeys.Pay)}
            </Typography>
          </Button>
        </div>
      )}
    </div>
  );
}
