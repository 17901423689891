import { Tooltip, IconButton, Dialog, DialogContent, DialogContentText, DialogActions, Button } from "@mui/material";
import clsx from "clsx";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { LabelKeys } from "../../Utils/enums/labelKeys";
import { CSInfoStyles } from "./styles";

interface ICSInfoProps {
  icon?: React.ReactElement;
  placement?:
    | "bottom-end"
    | "bottom-start"
    | "bottom"
    | "left-end"
    | "left-start"
    | "left"
    | "right-end"
    | "right-start"
    | "right"
    | "top-end"
    | "top-start"
    | "top";
  tooltipDialogIsOpen: boolean;
  popupTooltipText: string;
  hoverTooltopText: string;
  onSetTooltipDialogIsOpen: (value: boolean) => void;
  className?: string;
}

export default function CSInfo(props: ICSInfoProps) {
  const { t: translate } = useTranslation();
  const classes = CSInfoStyles();
  const [isHovering, setIsHovering] = useState(false);

  useEffect(() => {}, [isHovering]);

  function GetIcon() {
    return (
      <svg width="20" height="20" fill="currentColor" className="bi bi-question-circle" viewBox="0 0 16 16">
        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
        <path d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286zm1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94z" />
      </svg>
    );
  }

  function GetIconHover() {
    return (
      <svg width="20" height="20" fill="currentColor" className="bi bi-question-circle-fill" viewBox="0 0 16 16">
        <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.496 6.033h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286a.237.237 0 0 0 .241.247zm2.325 6.443c.61 0 1.029-.394 1.029-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94 0 .533.425.927 1.01.927z" />
      </svg>
    );
  }

  return (
    <React.Fragment>
      <Tooltip
        placement={props.placement ?? "right"}
        arrow
        onMouseEnter={() => setIsHovering(true)}
        onMouseLeave={() => setIsHovering(false)}
        leaveDelay={100}
        title={<span style={{ whiteSpace: "pre-line", fontSize: "1rem", color: "#fff" }}>{props.hoverTooltopText}</span>}
        sx={{
          display: {
            xs: "none",
            sm: "none",
            md: "none",
            lg: "block",
            xl: "block",
          },
        }}
        className={clsx(props.className, classes.tooltip)}
      >
        {props.icon ? props.icon : isHovering ? GetIconHover() : GetIcon()}
      </Tooltip>
      <IconButton
        sx={{
          float: "left",
          display: {
            xs: "block",
            sm: "block",
            md: "block",
            lg: "none",
            xl: "none",
          },
          padding: 0,
        }}
        color="secondary"
        className={classes.iconBtn}
        onClick={() => {
          props.onSetTooltipDialogIsOpen(true);
          setIsHovering(true);
        }}
      >
        {props.icon ? props.icon : isHovering ? GetIconHover() : GetIcon()}
      </IconButton>
      <Dialog open={props.tooltipDialogIsOpen} onClose={() => props.onSetTooltipDialogIsOpen(false)}>
        <DialogContent style={{ backgroundColor: "#000" }}>
          <DialogContentText
            style={{
              whiteSpace: "pre-line",
              fontSize: "1rem",
              color: "#fff",
            }}
          >
            {props.popupTooltipText}
          </DialogContentText>
        </DialogContent>
        <DialogActions style={{ backgroundColor: "#000" }}>
          <Button
            color="secondary"
            onClick={() => {
              props.onSetTooltipDialogIsOpen(false);
              setIsHovering(false);
            }}
            autoFocus
            style={{ color: "#fff", textTransform: "none" }}
          >
            {translate(LabelKeys.Ok)}
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
