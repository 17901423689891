import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import { useTranslation } from "react-i18next";
import { Button, IconButton, Paper, Tooltip, Typography, useMediaQuery, Zoom } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import ProcessCircle from "../../../../components/ProcessCircle/ProcessCircle";
import { OrderActions } from "../../../../Modules/Orders/actions";
import { RootState } from "../../../../store";
import { LabelKeys } from "../../../../Utils/enums/labelKeys";
import { GetDateWithTime, OrderLineStatusConverter, OrderStatusConverter, WithoutTime } from "../../../../Utils/extensions";
import { FieldNames, GetCurrentLanguageAsCodeFromLocalStorage } from "../../../../Utils/types";
import {
  ICustomOrderLine,
  searchedOrderOrderLinesHeadCells,
  IAdminOrderOrderDetailsState,
  IOrderLine,
  OrderStatus,
  getTransactionsHeaders,
} from "../../types";
import { Currency, LanguageCode, PaymentMethod } from "../../../../Utils/enums/enums";
import {
  IRefundOrderRequest,
  IResendGiftCardsToPrinterRequest,
  IResendOrderLineRequest,
  IResendOrderRequest,
  IResendOrderToNavRequest,
  IResendReceiptsRequest,
  ISearchSingleOrderRequest,
  IUpdateOrderLineRequest,
} from "../../../../Modules/Orders/types";
import { OrderDetailStyles } from "../../styles";
import EditableTable from "../../../../components/Table/EditableTable";
import Popup from "../../../../components/Popup/Popup";
import RefundOrder from "./Popups/RefundOrder";
import AdminOrderDetailsDotMenu from "../../../../components/Menu/AdminOrderDetailsDotMenu";
import SnackbarActions from "../../../../components/Snackbar/actions";
import CSReadOnlyTable from "../../../../components/Table/CSReadOnlyTable";
import { theme } from "../../../../appTheme";
import { SendBy } from "../../../../components/Delivery/types";
import { OrderType } from "../../../Order/types";

interface IOrderDetailsProps {
  onGoBackToOrderPageClicked?: (orderDetailsOpen: boolean) => void;
}

function OrderDetails(props: IOrderDetailsProps) {
  const [isOrderDetailsDotMenuOpen, setIsOrderDetailsMenuDotMenuOpen] = useState(false);
  const dispatch = useDispatch();
  const classes = OrderDetailStyles();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [adminOrderDetailState, setAdminOrderDetailState] = useState({
    customOrderLines: [],
  } as IAdminOrderOrderDetailsState);
  const { t: translate } = useTranslation();
  const [isRefundOrderDialogOpen, setIsRefundOrderDialogOpen] = useState(false);
  const [clicked, setClicked] = useState(false);
  const isMobile = useMediaQuery(theme.breakpoints.only("xs"));
  const isSm = useMediaQuery(theme.breakpoints.only("sm"));
  const isMd = useMediaQuery(theme.breakpoints.only("md"));
  const isLg = useMediaQuery(theme.breakpoints.only("lg"));
  const isXl = useMediaQuery(theme.breakpoints.only("xl"));
  const reducerState = useSelector(
    (s: RootState) => {
      return {
        order: s.order,
        transaction: s.transaction,
        authentication: s.authentication,
      };
    },
    (prev, next) =>
      prev.order.isLoading === next.order.isLoading &&
      prev.order.adminSelectedOrder === next.order.adminSelectedOrder &&
      prev.transaction.transactions === next.transaction.transactions
  );

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {}, [reducerState.transaction.transactions]);
  useEffect(() => {
    let customOrderLines: ICustomOrderLine[] = [];

    if (reducerState.order?.adminSelectedOrder?.orderLines?.length > 0) {
      reducerState.order?.adminSelectedOrder?.orderLines?.map((item) => {
        const orderLine = {
          id: item.id,
          sentDate: item.sentDate,
          status: OrderLineStatusConverter(item.status, GetCurrentLanguageAsCodeFromLocalStorage()),
          receiver: item.receiver,
          claimCode: item.claimCode,
        };
        customOrderLines.push(orderLine);
      });

      handleFieldOnChange(FieldNames.customOrderLines, customOrderLines);
    }
  }, [reducerState.order?.adminSelectedOrder]);

  const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setIsOrderDetailsMenuDotMenuOpen(!isOrderDetailsDotMenuOpen);
  };

  const handleRefreshMenuClick = () => {
    dispatch(OrderActions.SearchSingleOrderRequest({ orderId: reducerState.order?.adminSelectedOrder?.id } as ISearchSingleOrderRequest));
  };

  function handleFieldOnChange(fieldName: FieldNames, value: ICustomOrderLine[]) {
    setAdminOrderDetailState((state) => ({
      ...state,
      [fieldName]: value,
    }));
  }

  function handleResendOrderToNav() {
    dispatch(OrderActions.ResendOrderToNavRequest({ orderId: reducerState.order?.adminSelectedOrder.id } as IResendOrderToNavRequest));
  }

  function handleResendToGiftCardPrinter() {
    dispatch(
      OrderActions.ResendGiftCardsToPrinterRequest({
        orderId: reducerState.order?.adminSelectedOrder.id,
      } as IResendGiftCardsToPrinterRequest)
    );
  }

  function GetPopupWidth(): string {
    if (isMobile) {
      return "90vw";
    } else if (isSm) {
      return "90vw";
    } else if (isMd) {
      return "70vw";
    } else if (isLg) {
      return "40vw";
    } else {
      return "20vw";
    }
  }

  function handleRefundOrder(data: IRefundOrderRequest): void {
    dispatch(
      OrderActions.RefundOrderRequest({ orderId: reducerState.order.adminSelectedOrder.id, amount: data.amount } as IRefundOrderRequest)
    );
    setIsRefundOrderDialogOpen(false);
    setIsOrderDetailsMenuDotMenuOpen(false);
  }

  function handleResendRowClicked(row: IOrderLine): void {
    dispatch(
      OrderActions.ResendOrderLineRequest({
        orderLineId: row.id,
        orderId: reducerState.order.adminSelectedOrder?.id,
      } as IResendOrderLineRequest)
    );
    setIsOrderDetailsMenuDotMenuOpen(false);
  }

  function handleOnConfirmRowChanges(row: IOrderLine): void {
    dispatch(
      OrderActions.UpdateOrderLineRequest({
        id: row.id,
        receiver: row.receiver,
        orderId: reducerState.order?.adminSelectedOrder?.id,
      } as IUpdateOrderLineRequest)
    );
  }

  function handleResendOrder() {
    const orderId = reducerState.order?.adminSelectedOrder?.id;
    if (orderId) {
      dispatch(
        OrderActions.ResendOrdersRequest({
          orderIds: [orderId],
        } as IResendOrderRequest)
      );
      setIsOrderDetailsMenuDotMenuOpen(false);
    }
  }

  function handleResendReceipt() {
    const orderId = reducerState.order?.adminSelectedOrder?.id;
    if (orderId) {
      dispatch(
        OrderActions.ResendReceiptsRequest({
          orderIds: [orderId],
        } as IResendReceiptsRequest)
      );
      setIsOrderDetailsMenuDotMenuOpen(false);
    }
  }

  function GetVNumberString(numbers: string[]): string {
    return numbers.join("|");
  }

  return (
    <Grid
      container
      item
      xs={12}
      sm={12}
      md={12}
      lg={12}
      xl={12}
      className={classes.root}
      style={{ backgroundColor: "#e3e1de", paddingTop: "25px" }}
      justifyContent="center"
    >
      {reducerState.order?.isLoading && <ProcessCircle />}
      <Grid container item xs={11} sm={11} md={11} lg={11} xl={11}>
        <Grid container item xs={12} sm={12} md={12} lg={12} xl={12} style={{ textAlign: "left", paddingBottom: 20 }}>
          <Grid item xs={2} sm={1} md={1} lg={1} xl={1} style={{ paddingTop: 10 }}>
            <Tooltip
              arrow
              leaveDelay={100}
              title={
                <span
                  style={{
                    whiteSpace: "pre-line",
                    fontSize: "1rem",
                    fontFamily: "Mulish",
                  }}
                >
                  {translate(LabelKeys.GoBack)}
                </span>
              }
            >
              <IconButton id="goBackToAdminOrderPage" onClick={() => props.onGoBackToOrderPageClicked(false)}>
                <svg width="20" height="20" fill="currentColor" className="bi bi-arrow-left" viewBox="0 0 16 16">
                  <path
                    fillRule="evenodd"
                    d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"
                  />
                </svg>
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid item xs={8} sm={9} md={9} lg={9} xl={9}>
            <Typography variant="h2" style={{ paddingTop: 13 }}>
              {translate(LabelKeys.OrderDetails)}
            </Typography>
          </Grid>
          <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
            <IconButton onClick={() => handleRefreshMenuClick()} style={{ float: "right" }}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="25"
                height="25"
                fill="currentColor"
                className="bi bi-arrow-counterclockwise"
                viewBox="0 0 16 16"
              >
                <path d="M8 3a5 5 0 1 1-4.546 2.914.5.5 0 0 0-.908-.417A6 6 0 1 0 8 2v1z" />
                <path d="M8 4.466V.534a.25.25 0 0 0-.41-.192L5.23 2.308a.25.25 0 0 0 0 .384l2.36 1.966A.25.25 0 0 0 8 4.466z" />
              </svg>
            </IconButton>
          </Grid>
          <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
            <IconButton onClick={(e) => handleMenuClick(e)} style={{ float: "right" }}>
              <svg width="25" height="25" fill="currentColor" className="bi bi-three-dots-vertical" viewBox="0 0 16 16">
                <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z" />
              </svg>
            </IconButton>
          </Grid>
        </Grid>

        {isOrderDetailsDotMenuOpen && (
          <AdminOrderDetailsDotMenu
            onEscapeKeyDown={() => setIsOrderDetailsMenuDotMenuOpen(false)}
            open={isOrderDetailsDotMenuOpen}
            onClose={() => setIsOrderDetailsMenuDotMenuOpen(false)}
            resendOrder={() => handleResendOrder()}
            resendReceipt={() => handleResendReceipt()}
            onSetIsRefundOrderDialogOpen={() => setIsRefundOrderDialogOpen(true)}
            resendOrderToNav={() => handleResendOrderToNav()}
            resendToGiftCardPrinter={() => handleResendToGiftCardPrinter()}
            anchorEl={anchorEl}
            showRefundOption={
              reducerState.authentication.signedInUserEmail == "andreas.carlsson@vinga.com" ||
              reducerState.authentication.signedInUserEmail == "alexander.carlsson@vinga.com"
            }
            disableResendNav={!reducerState.order.adminSelectedOrder?.enableResendNav}
            disableResendToPrinter={reducerState.order.adminSelectedOrder.sendBy != SendBy.PhysicalCard}
            disableResendOrder={!reducerState.order.adminSelectedOrder?.enableResendOrder}
            disableResendReceipt={!reducerState.order.adminSelectedOrder?.enableResendReceipt}
            disableRefund={
              reducerState.order.adminSelectedOrder.refundedAmount != 0 ||
              reducerState.order.adminSelectedOrder.status == OrderStatus.Refunded ||
              reducerState.order.adminSelectedOrder.paymentMethod == PaymentMethod.Invoice
            }
          />
        )}
        <Paper style={{ width: "100%", borderRadius: 0, marginTop: 20 }}>
          <Grid
            id="orderDetailsOverView"
            container
            item
            justifyContent="center"
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            style={{
              paddingTop: 10,
              paddingBottom: 30,
              paddingLeft: 20,
              textAlign: "left",
              fontFamily: "Mulish",
            }}
          >
            <Grid item xs={12} sm={3} md={3} lg={3} xl={3} className={classes.orderDetailContainer}>
              <div className={classes.orderDetailOverviewRow}>
                <b>{translate(LabelKeys.OrderId)}</b>: {reducerState.order?.adminSelectedOrder?.id} |{" "}
                {reducerState.order?.adminSelectedOrder?.internalOrderId}
              </div>
              <div className={classes.orderDetailOverviewRow}>
                <b>{translate(LabelKeys.OrderStatus)}</b>:{" "}
                {OrderStatusConverter(reducerState.order?.adminSelectedOrder?.status, GetCurrentLanguageAsCodeFromLocalStorage())}
              </div>
              <div className={classes.orderDetailOverviewRow}>
                <b>{translate(LabelKeys.CreatedDate)}</b>: {GetDateWithTime(reducerState.order.adminSelectedOrder?.createdDate)}
              </div>
              <div className={classes.orderDetailOverviewRow}>
                <b>{translate(LabelKeys.DeliveryDate)}</b>: {GetDateWithTime(reducerState.order.adminSelectedOrder?.orderDate)}
              </div>
              <div className={classes.orderDetailOverviewRow}>
                <b>{translate(LabelKeys.SendBy)}</b>: {translate(SendBy[reducerState.order.adminSelectedOrder?.sendBy]?.toLowerCase())}
              </div>
              <div className={classes.orderDetailOverviewRow}>
                <b>{translate(LabelKeys.OrderType)}</b>:{" "}
                {translate(OrderType[reducerState.order.adminSelectedOrder?.orderType]?.toLowerCase())}
              </div>
              <div className={classes.orderDetailOverviewRow}>
                <b>{translate(LabelKeys.Reseller)}</b>: {reducerState.order.adminSelectedOrder?.resellerName}
              </div>
            </Grid>
            <Grid item xs={12} sm={5} md={5} lg={5} xl={5} className={classes.orderDetailContainer}>
              <div className={classes.orderDetailOverviewRow}>
                <b>{translate(LabelKeys.CompanyName)}</b>: {reducerState.order.adminSelectedOrder?.companyName}
              </div>
              <div className={classes.orderDetailOverviewRow}>
                <b>{translate(LabelKeys.CompanyContactPhoneNumber)}</b>: {reducerState.order.adminSelectedOrder?.companyContactPhoneNumber}
              </div>
              <div className={classes.orderDetailOverviewRow}>
                <b>{translate(LabelKeys.SenderEmail)}</b>: {reducerState.order.adminSelectedOrder?.contactEmail}
              </div>
              <div className={classes.orderDetailOverviewRow}>
                <b>{translate(LabelKeys.OrganisationNumber)}</b>: {reducerState.order.adminSelectedOrder?.organisationNumber}
              </div>
              <div className={classes.orderDetailOverviewRow}>
                <b>{translate(LabelKeys.Name)}</b>: {reducerState.order.adminSelectedOrder?.name}
              </div>
              <div className={classes.orderDetailOverviewRow}>
                <b>{translate(LabelKeys.AddressLine)}</b>: {reducerState.order.adminSelectedOrder?.addressLine}
              </div>
              <div className={classes.orderDetailOverviewRow}>
                <b>{translate(LabelKeys.PostalCode)}</b>: {reducerState.order.adminSelectedOrder?.postalCode}
              </div>
              <div className={classes.orderDetailOverviewRow}>
                <b>{translate(LabelKeys.City)}</b>: {reducerState.order.adminSelectedOrder?.city}
              </div>
              <div className={classes.orderDetailOverviewRow}>
                <b>{translate(LabelKeys.Country)}</b>:{" "}
                {reducerState.order.adminSelectedOrder?.country == "Unknown" ? "" : reducerState.order.adminSelectedOrder?.country}
              </div>
            </Grid>
            <Grid item xs={12} sm={4} md={4} lg={4} xl={4} className={classes.orderDetailContainer}>
              <div className={classes.orderDetailOverviewRow}>
                <b>{translate(LabelKeys.OrderValue)}</b>: {reducerState.order.adminSelectedOrder?.totalOrderValue}{" "}
                {Currency[reducerState.order.adminSelectedOrder?.currency]}
              </div>
              <div className={classes.orderDetailOverviewRow} style={{ textTransform: "capitalize" }}>
                <b>{translate(LabelKeys.PaymentMethod)}</b>:{" "}
                {translate(PaymentMethod[reducerState.order.adminSelectedOrder?.paymentMethod]?.toLowerCase())}
              </div>
              <div className={classes.orderDetailOverviewRow}>
                <b>{translate(LabelKeys.RefundedAmount)}</b>: {reducerState.order.adminSelectedOrder?.refundedAmount}{" "}
                {Currency[reducerState.order.adminSelectedOrder?.currency]}
              </div>
            </Grid>
          </Grid>
        </Paper>
        <Grid container item xs={12} sm={12} md={12} lg={12} xl={12} style={{ paddingTop: 30 }}>
          <Grid item xs={12} sm={10} md={10} lg={10} xl={10}>
            <Typography align="left" variant="h3">
              {translate(LabelKeys.OrderRows)}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={2} md={2} lg={2} xl={2}>
            <Tooltip
              onMouseEnter={() => setClicked(false)}
              leaveDelay={100}
              arrow
              title={
                <div style={{ display: "flex" }}>
                  {!clicked ? (
                    <svg
                      width="20"
                      height="20"
                      fill="currentColor"
                      className="bi bi-clipboard"
                      viewBox="0 0 16 16"
                      style={{ paddingRight: 10 }}
                    >
                      <path d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1v-1z" />
                      <path d="M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h3zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3z" />
                    </svg>
                  ) : (
                    <svg
                      width="20"
                      height="20"
                      fill="currentColor"
                      className="bi bi-clipboard-check"
                      viewBox="0 0 16 16"
                      style={{ paddingRight: 10 }}
                    >
                      <path
                        fillRule="evenodd"
                        d="M10.854 7.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7.5 9.793l2.646-2.647a.5.5 0 0 1 .708 0z"
                      />
                      <path d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1v-1z" />
                      <path d="M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h3zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3z" />
                    </svg>
                  )}
                  <Typography style={{ color: "#fff" }}>
                    {!clicked ? translate(LabelKeys.CopyToClipboard) : translate(LabelKeys.Copied)}
                  </Typography>
                </div>
              }
              TransitionComponent={Zoom}
              placement="top"
              style={{ position: "absolute" }}
            >
              <Button
                style={{
                  height: 46,
                  width: "100%",
                  cursor: "pointer",
                  color: "#fff",
                  borderRadius: 0,
                  backgroundColor: "#000",
                }}
                onClick={() => {
                  let number = GetVNumberString(reducerState.order.adminSelectedOrder.orderLines.map((x) => x.number));
                  if (number?.length <= 0) {
                    dispatch(SnackbarActions.ShowInfo(translate(LabelKeys.NoVNumbersToCopy)));
                  } else {
                    navigator.clipboard.writeText(number);
                    setClicked(true);
                  }
                }}
              >
                {translate(LabelKeys.CopyVNumbers)}
              </Button>
            </Tooltip>
          </Grid>
        </Grid>
        <Grid
          id="orderLines"
          container
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          style={{ paddingTop: 10, paddingBottom: 30, textAlign: "left" }}
        >
          <EditableTable
            columns={searchedOrderOrderLinesHeadCells}
            rows={adminOrderDetailState.customOrderLines}
            enableToolbar={true}
            enableInlineEditMode
            enableInlineResendMode
            editableColumns={["receiver"]}
            onInlineConfirmRowChangesClicked={(row: IOrderLine) => handleOnConfirmRowChanges(row)}
            onInlineResendRowClicked={(row: IOrderLine) => handleResendRowClicked(row)}
            totalItems={reducerState.order.adminOrderRows?.length}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Typography align="left" variant="h3" style={{ paddingTop: 30 }}>
            {translate(LabelKeys.OrderHistory)}
          </Typography>
        </Grid>
        <Grid
          id="orderHistory"
          container
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          style={{ paddingTop: 10, paddingBottom: 30, textAlign: "left" }}
        >
          <CSReadOnlyTable
            columns={getTransactionsHeaders}
            rows={reducerState.transaction.transactions}
            enableRowClick={false}
            maxHeight={600}
            showTimeWithDate={true}
          />
        </Grid>
        {isRefundOrderDialogOpen && (
          <Popup
            onEscapeKeyDown={() => {
              setIsRefundOrderDialogOpen(false);
            }}
            width={GetPopupWidth()}
            content={
              <RefundOrder
                key={"refundOrder"}
                onSave={(data: IRefundOrderRequest) => handleRefundOrder(data)}
                onClose={() => setIsRefundOrderDialogOpen(false)}
              />
            }
            onClose={() => setIsRefundOrderDialogOpen(false)}
            enableCloseIcon={true}
            dialogTitle={translate(LabelKeys.RefundOrder)}
          />
        )}
      </Grid>
    </Grid>
  );
}

export default OrderDetails;
