import { SendBy } from "./../components/Delivery/types";
import { IReceiverEmail } from "./../views/Order/types";
import { OrderLineStatus, OrderStatus } from "../views/Admin/types";
import { IReceiverPhoneNumber } from "../views/Order/types";
import { LanguageCode, ReceiversLimit } from "./enums/enums";
import { ValidateEmail } from "./validations";
import { LITIUM_IMAGE_API_URL } from "./network/axios/urls";

export function GetValuesAsArray(values: string): string[] {
  let valueArray: string[] = [];

  if (values === undefined || values == "") {
    return;
  }

  values?.split("\n")?.map((item) => {
    if (item?.length === 0 && /(\r\n|\n|\r)/gm) {
      //Do nothing if only break in string
    } else {
      valueArray?.push(item);
    }
  });

  return valueArray;
}

export function ValidateDeliveryEmails(value: string): boolean {
  if (value == undefined || value == "") {
    return false;
  }

  let emails: string[] = [];
  emails = GetValuesAsArray(value);

  if (emails.length > ReceiversLimit) return false;

  for (let index = 0; index < emails?.length; index++) {
    const email = emails[index];
    if (!ValidateEmail(email)) {
      return false;
    }
  }

  return true;
}

//Validate phone numbers
export function ValidateDeliveryPhoneNumbers(value: string): boolean {
  if (value === undefined || value === "") {
    return false;
  }

  let phoneNumbers: string[] = [];
  phoneNumbers = GetValuesAsArray(value);

  if (phoneNumbers.length > ReceiversLimit) return false;

  //Loop every number and check length + only digits
  for (let index = 0; index < phoneNumbers?.length; index++) {
    const phoneNumber = phoneNumbers[index];
    if (
      (phoneNumber?.length === 10 && phoneNumber.startsWith("07") && /^\d+$/.test(phoneNumber)) ||
      (phoneNumber?.length === 12 && phoneNumber.startsWith("+46") && /^(([+]46)(7)|07)[0123456789](\d{4})(\d{3})$/.test(phoneNumber))
    ) {
      continue;
    } else return false;
  }
  return true;
}

//Validate phone numbers
export function ValidateOrganisationNumber(value: string): boolean {
  if (value === undefined || value === "") {
    return false;
  }

  if (/^([\d]{6}[-]?[\d]{4})$/.test(value) || /^([\d]{10})$/.test(value)) {
    return true;
  } else return false;
}

export function ValidateCustomerNumber(value: string): boolean {
  if (value == undefined || value?.length == 0) {
    return false;
  }
  return true;
}

//Validate phone numbers
export function ValidateOrderIds(value: string): boolean {
  if (value === undefined || value === "") {
    return false;
  }

  let orderIds: string[] = [];
  orderIds = GetValuesAsArray(value);

  //Loop every number and check length + only digits
  for (let index = 0; index < orderIds?.length; index++) {
    const orderId = orderIds[index];
    if (/^\d+$/.test(orderId)) {
      continue;
    } else return false;
  }
  return true;
}

export function GetDateAndTime(): Date {
  let newDelTime = new Date();

  if (newDelTime.getMinutes() < 15) {
    newDelTime.setMinutes(15);
  } else if (newDelTime.getMinutes() < 30) {
    newDelTime.setMinutes(30);
  } else if (newDelTime.getMinutes() < 45) {
    newDelTime.setMinutes(45);
  } else if (newDelTime.getMinutes() >= 45) {
    newDelTime.setHours(newDelTime.getHours() + 1);
    newDelTime.setMinutes(0);
  }

  return newDelTime;
}

export function WithoutTime(date: Date): string {
  if (date == undefined || date == null) {
    return "";
  } else {
    return new Date(date).toLocaleDateString().split("T")[0];
  }
}

export function GetDateWithTime(date: Date): string {
  let newDate = new Date(date);
  if (newDate == undefined || newDate == null) {
    return "";
  } else {
    const month = newDate?.getMonth() + 1;
    return `${newDate?.getFullYear()}-${month <= 9 ? "0" + month : month}-${
      newDate?.getDate() <= 9 ? "0" + newDate?.getDate() : newDate?.getDate()
    } ${newDate?.getHours() <= 9 ? "0" + newDate?.getHours() : newDate?.getHours()}:${
      newDate?.getMinutes() <= 9 ? "0" + newDate?.getMinutes() : newDate?.getMinutes()
    }`;
  }
}

export function OrderStatusConverter(status: OrderStatus, lang: LanguageCode): string {
  if (lang === LanguageCode.SE)
    switch (status) {
      case OrderStatus.Unknown: {
        return "Okänd";
      }
      case OrderStatus.Created: {
        return "Skapad";
      }
      case OrderStatus.Done: {
        return "Klar";
      }
      case OrderStatus.Failed: {
        return "Misslyckad";
      }
      case OrderStatus.FailedPayment: {
        return "Misslyckad betalning";
      }
      case OrderStatus.Refunded: {
        return "Återbetalad";
      }
      case OrderStatus.Canceled: {
        return "Avbruten";
      }
      case OrderStatus.OrderCreatedNav: {
        return "Skickad till Nav";
      }
      default:
        return "N/A";
    }
  else {
    switch (status) {
      case OrderStatus.Unknown: {
        return "Unknown";
      }
      case OrderStatus.Created: {
        return "Created";
      }
      case OrderStatus.Done: {
        return "Done";
      }
      case OrderStatus.Failed: {
        return "Failed";
      }
      case OrderStatus.FailedPayment: {
        return "Failed payment";
      }
      case OrderStatus.Refunded: {
        return "Refunded";
      }
      case OrderStatus.Canceled: {
        return "Canceled";
      }
      case OrderStatus.OrderCreatedNav: {
        return "Sent to Nav";
      }
      default:
        return "N/A";
    }
  }
}

export function OrderLineStatusConverter(status: OrderLineStatus, lang: LanguageCode): string {
  if (lang === LanguageCode.SE)
    switch (status) {
      case OrderLineStatus.Unknown: {
        return "Okänd";
      }
      case OrderLineStatus.Created: {
        return "Skapad";
      }
      case OrderLineStatus.Sent: {
        return "Skickad";
      }
      case OrderLineStatus.Failed: {
        return "Misslyckad";
      }
      case OrderLineStatus.Canceled: {
        return "Avbruten";
      }
      case OrderLineStatus.FailedPayment: {
        return "Misslyckad betalning";
      }
      case OrderLineStatus.OrderCreatedNav: {
        return "Skickad till Nav";
      }
      default:
        return "N/A";
    }
  else {
    switch (status) {
      case OrderLineStatus.Unknown: {
        return "Unknown";
      }
      case OrderLineStatus.Created: {
        return "Created";
      }
      case OrderLineStatus.Sent: {
        return "Sent";
      }
      case OrderLineStatus.Failed: {
        return "Failed";
      }
      case OrderLineStatus.Canceled: {
        return "Cancelled";
      }
      case OrderLineStatus.FailedPayment: {
        return "Failed payment";
      }
      case OrderLineStatus.OrderCreatedNav: {
        return "Sent to Nav";
      }
      default:
        return "N/A";
    }
  }
}

export function GetButtonStyling(height: number, width: number | "auto"): React.CSSProperties {
  return {
    backgroundColor: "#EEEEF1",
    color: "#000",
    boxShadow: "0px 1px 3px rgba(0, 0, 0, 0.3)",
    borderRadius: 30,
    borderTop: "2px solid white",
    borderLeft: "2px solid white",
    marginBottom: 5,
    height: height,
    width: width,
    fontSize: "1rem",
    textTransform: "uppercase",
  };
}

export interface ReceiverDataObjects {
  receiverPhoneNumbers: IReceiverPhoneNumber[];
  receiverEmails: IReceiverEmail[];
  valid: boolean;
}

export function ValidateAndGetReceiverDataObjects(sendBy: SendBy, data: string): ReceiverDataObjects {
  //Replace all whitespace if there is any
  data = data.replaceAll(" ", "");
  const dataStringLines = data?.split(/\r\n|\n/);
  let returnValue: ReceiverDataObjects = {
    receiverEmails: [] as IReceiverEmail[],
    receiverPhoneNumbers: [] as IReceiverPhoneNumber[],
  } as ReceiverDataObjects;
  let valid: boolean = true;

  if (sendBy == SendBy.SMS) {
    dataStringLines.forEach((element) => {
      let splitedElement = element.split(",");
      if (!ValidateDeliveryPhoneNumbers(splitedElement[0])) {
        valid = false;
      } else {
        returnValue.receiverPhoneNumbers?.push({
          phoneNumber: splitedElement[0],
          firstName: splitedElement[1],
        });
      }
    });
  } else {
    dataStringLines.forEach((element) => {
      let splitedElement = element.split(",");
      if (!ValidateDeliveryEmails(splitedElement[0])) {
        valid = false;
      } else {
        returnValue.receiverEmails?.push({
          email: splitedElement[0],
          firstName: splitedElement[1],
        });
      }
    });
  }

  return {
    valid: valid,
    receiverPhoneNumbers: returnValue.receiverPhoneNumbers,
    receiverEmails: returnValue.receiverEmails,
  } as ReceiverDataObjects;
}

export function GetLitiumImageUrl(imageGuid: string, paramString: string): string {
  const imageAddress = LITIUM_IMAGE_API_URL + imageGuid;
  return paramString?.length > 0 ? imageAddress + paramString : imageAddress;
}

export enum DayOfWeek {
  Sunday = 0,
  Monday = 1,
  Tuesday = 2,
  WednesDay = 3,
  Thursdag = 4,
  Friday = 5,
  Saturday = 6,
}

export function AddBusinessDays(thisDate: Date, daysToAdd: number, holidays: number[]): Date {
  const newDate = new Date(thisDate.getFullYear(), thisDate.getMonth(), thisDate.getDate(), 0, 0, 0, 0);

  for (let i = 0; i < daysToAdd; i++) {
    do {
      newDate.setDate(newDate.getDate() + 1);
    } while (newDate.getDay() == DayOfWeek.Saturday || newDate.getDay() == DayOfWeek.Sunday || holidays.includes(newDate.getTime()));
  }
  newDate.setHours(8, 0, 0, 0);
  return newDate;
}

export function GetEasterSunday(year: number) {
  let f = Math.floor,
    // Golden Number - 1
    G = year % 19,
    C = f(year / 100),
    // related to Epact
    H = (C - f(C / 4) - f((8 * C + 13) / 25) + 19 * G + 15) % 30,
    // number of days from 21 March to the Paschal full moon
    I = H - f(H / 28) * (1 - f(29 / (H + 1)) * f((21 - G) / 11)),
    // weekday for the Paschal full moon
    J = (year + f(year / 4) + I + 2 - C + f(C / 4)) % 7,
    // number of days from 21 March to the Sunday on or before the Paschal full moon
    L = I - J,
    month = 3 + f((L + 40) / 44),
    day = L + 28 - 31 * f(month / 4);

  return new Date(new Date().getFullYear(), month - 1, day);
}

export function GetHolidays(): number[] {
  let holidays: number[];
  holidays = [] as number[];
  let easterSunday = GetEasterSunday(new Date().getFullYear());

  let longFriday = new Date(easterSunday.getFullYear(), easterSunday.getMonth(), easterSunday.getDate(), 0, 0, 0, 0);
  longFriday.setDate(easterSunday.getDate() - 2);
  holidays.push(longFriday.getTime()); // långfredagen,

  let otherDayEaster = new Date(easterSunday.getFullYear(), easterSunday.getMonth(), easterSunday.getDate(), 0, 0, 0, 0);
  otherDayEaster.setDate(easterSunday.getDate() + 1);
  holidays.push(otherDayEaster.getTime()); // annandag påsk

  let christGoesToHeavenDay = new Date(easterSunday.getFullYear(), easterSunday.getMonth(), easterSunday.getDate(), 0, 0, 0, 0);
  christGoesToHeavenDay.setDate(easterSunday.getDate() + 39);
  holidays.push(christGoesToHeavenDay.getTime()); // kristihimmelsfärd

  holidays.push(new Date(new Date().getFullYear(), 0, 1, 0, 0, 0, 0).getTime()); // 1a januari
  holidays.push(new Date(new Date().getFullYear(), 5, 6, 0, 0, 0, 0).getTime()); // nationaldagen
  holidays.push(new Date(new Date().getFullYear(), 11, 24, 0, 0, 0, 0).getTime()); // julafton
  holidays.push(new Date(new Date().getFullYear(), 11, 25, 0, 0, 0, 0).getTime()); // juldagen
  holidays.push(new Date(new Date().getFullYear(), 11, 26, 0, 0, 0, 0).getTime()); // annandag jul
  holidays.push(new Date(new Date().getFullYear(), 11, 31, 0, 0, 0, 0).getTime()); // nyårsafton
  holidays.push(new Date(new Date().getFullYear() + 1, 0, 1, 0, 0, 0, 0).getTime()); // nyårsdagen året efter
  return holidays;
}

export function isNullOrEmpty(value: string): boolean {
  if (value == undefined) {
    return true;
  }
  if (value == "") {
    return true;
  }
  if (value?.length == 0) {
    return true;
  }

  return false;
}
