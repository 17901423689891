import Button from "@mui/material/Button/Button";
import React, { useRef } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import "typeface-mulish";
import { MaxFileSize, AspectRatio, Resolution } from "../../Utils/enums/enums";
import { LabelKeys } from "../../Utils/enums/labelKeys";
import SnackbarActions from "../Snackbar/actions";
import { FileType, IUploadedFile } from "./types";
import ArrowCircleUpOutlinedIcon from "@mui/icons-material/ArrowCircleUpOutlined";

interface FileUploaderProps {
  id?: string;
  fileTypes: string; //Format ".jpeg, .jpg, .png, .csv, .xlsx"
  classNameRoot?: string;
  classNameInput?: string;
  classNameIconBtn?: string;
  aspectRatio?: AspectRatio;
  maxResolution?: Resolution;
  maxFileSizeInBytes?: number;
  isImageUploaded: boolean;
  isCSVFile: boolean;
  btnTitle?: string;
  btnHeight?: number | "auto" | string;
  btnWidth: number | "auto" | string;
  onChange: (file: IUploadedFile | React.ChangeEvent<HTMLInputElement>) => void;
}

const CSFileUploader = (props: FileUploaderProps): any => {
  const inputFile = useRef(null);
  const dispatch = useDispatch();
  const { t: translate } = useTranslation();
  const DefaultFileMaxSizeInBytes = MaxFileSize.OneMB;

  function ValidateFileType(currentFileFormat: string) {
    if (props.fileTypes.replaceAll(" ", "").includes(currentFileFormat) && currentFileFormat !== "" && currentFileFormat !== undefined) {
      return true;
    }
    return false;
  }

  function ValidateImageResolution(currentFile: File): Promise<boolean> {
    if (!currentFile) return;
    let img: HTMLImageElement;
    img = document.createElement("img");
    img.src = URL.createObjectURL(currentFile);
    return new Promise((resolve, reject) => {
      img.onload = () => {
        const currentImageHeight = img.height; //Uploaded image
        const currentImageWidth = img.width; //Uploaded image
        let maxResolutionWidth = 0; //From Props
        let maxResolutionHeight = 0; //From Props

        const values = props.maxResolution.split("x");
        maxResolutionWidth = Number(values[0]); //From Props
        maxResolutionHeight = Number(values[1]); //From Props

        let valid = CompareSizes(currentImageHeight, maxResolutionHeight, currentImageWidth, maxResolutionWidth);
        resolve(valid);
      };

      img.onerror = reject;
    });
  }

  function ValidateImageAspectRatio(currentFile: File): Promise<boolean> {
    if (!currentFile) return;
    let img: HTMLImageElement;
    img = document.createElement("img");
    img.src = URL.createObjectURL(currentFile);
    return new Promise((resolve, reject) => {
      img.onload = () => {
        const currentImageHeight = img.height; //Uploaded image
        const currentImageWidth = img.width; //Uploaded image

        if (currentImageWidth / currentImageHeight < props.aspectRatio && currentImageWidth / currentImageHeight > 0.7) {
          resolve(true);
        } else {
          resolve(false);
        }
      };

      img.onerror = reject;
    });
  }

  function CompareSizes(
    currentImageHeight: number,
    maxResolutionHeight: number,
    currentImageWidth: number,
    maxResolutionWidth: number
  ): boolean {
    if (currentImageHeight > maxResolutionHeight) {
      return false;
    } else if (currentImageWidth > maxResolutionWidth) {
      return false;
    } else {
      return true;
    }
  }

  function GetFileType(currentFileFormat: string): FileType {
    switch (currentFileFormat) {
      case "pdf":
        return FileType.pdf;
      case "png":
        return FileType.png;
      case "jpeg":
        return FileType.jpeg;
      case "jpg":
        return FileType.jpg;
      case "csv":
        return FileType.excel;
      case "xlsx":
        return FileType.xlsx;
      default:
        return FileType.unknown;
    }
  }

  function convertInputFile(e: React.ChangeEvent<HTMLInputElement>): Promise<string> {
    if (!e?.target?.files[0]) return;
    return new Promise((resolve, reject) => {
      let reader = new FileReader();
      reader.onload = () => {
        resolve(reader.result.toString());
      };
      reader.onerror = reject;
      reader.readAsDataURL(e.target?.files[0]);
    });
  }

  function ValidateFileSize(size: number): boolean {
    if ((props.maxFileSizeInBytes && size > props.maxFileSizeInBytes) ?? size > DefaultFileMaxSizeInBytes) {
      return false;
    }
    return true;
  }

  async function handleInputFileChanged(e: React.ChangeEvent<HTMLInputElement>) {
    let currentFile = e.target.files[0];

    if (props.isImageUploaded && props.maxResolution != undefined) {
      let value = await ValidateImageResolution(currentFile);
      if (!value) {
        dispatch(
          SnackbarActions.ShowError(
            translate(LabelKeys.InvalidImageResolution) + " " + props.maxResolution + " " + translate(LabelKeys.Pixels)
          )
        );
        return;
      }
    } //If use of aspect ratio
    else if (props.aspectRatio != undefined) {
      let value = await ValidateImageAspectRatio(currentFile);
      if (!value) {
        dispatch(SnackbarActions.ShowError(`${translate(LabelKeys.InvalidImageAspectRatio)}.`));
        return;
      }
    }

    //Always validate accepted types
    const currentFileFormat = currentFile?.type?.substring(6);
    if (!ValidateFileType(currentFileFormat)) {
      let allowedTypes = props.fileTypes;

      if (props.fileTypes.includes("application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet")) {
        allowedTypes = ".csv,.xlsx";
      }

      dispatch(SnackbarActions.ShowError(translate(LabelKeys.InvalidFileFormat) + allowedTypes.replaceAll(".", " .")));
      return;
    }

    if (!props.isCSVFile) {
      if (!ValidateFileSize(currentFile.size)) {
        dispatch(SnackbarActions.ShowError(translate(LabelKeys.InvalidFileSize)));
        return;
      }

      let convertedFile = await convertInputFile(e);
      props.onChange({
        name: currentFile.name,
        type: GetFileType(currentFileFormat),
        base64: convertedFile,
      } as IUploadedFile);
    } else {
      props.onChange(e);
    }
  }

  const onButtonClick = () => {
    inputFile.current.click();
  };

  return (
    <Button
      startIcon={<ArrowCircleUpOutlinedIcon style={{ fontSize: 30 }} />}
      style={{
        border: "1px solid #000",
        color: "#000",
        textTransform: "unset",
        fontWeight: 600,
        height: props.btnHeight ? props.btnHeight : 50,
        width: props.btnWidth ? props.btnWidth : 150,
      }}
      fullWidth
      className={props.classNameRoot}
      onClick={onButtonClick}
    >
      <input
        id={props.id}
        className={props.classNameInput}
        style={{ display: "none" }}
        accept={props.fileTypes}
        ref={inputFile}
        onChange={(e) => handleInputFileChanged(e)}
        onClick={(event: any) => (event.target.value = null)}
        type="file"
      />
      {props.btnTitle ? props.btnTitle : "Upload file"}
    </Button>
  );
};

export default CSFileUploader;
