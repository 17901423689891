import React, { useEffect, useState } from "react";
import { Button, Grid, Typography } from "@mui/material";
import CSTextField from "../../components/Input/Textfield/CSTextField";
import { FieldNames, GetCurrentLanguageAsText } from "../../Utils/types";
import { ISignInErrorState, ISignInState } from "./types";
import { useTranslation } from "react-i18next";
import { LabelKeys } from "../../Utils/enums/labelKeys";
import { SignInStyles } from "./styles";
import { AuthenticationActions } from "../../Modules/Authentication/actions";
import { ISignInRequest, IValidateSignInEmailRequest } from "../../Modules/Authentication/types";
import { useDispatch, useSelector } from "react-redux";
import { ValidateEmail, ValidateExactStringLength } from "../../Utils/validations";
import { RootState } from "../../store";
import { useNavigate } from "react-router-dom";
import ProcessCircle from "../../components/ProcessCircle/ProcessCircle";
import { IsAuth } from "../../Utils/authentication";

function SignIn() {
  const classes = SignInStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t: translate } = useTranslation();
  const [signInState, setsignInState] = useState({
    email: "",
    validationCode: "",
  } as ISignInState);
  const [signInErrorState, setsignInErrorState] = useState({} as ISignInErrorState);

  const reducerState = useSelector(
    (s: RootState) => {
      return {
        authentication: s.authentication,
      };
    },
    (prev, next) => prev.authentication.isLoading === next.authentication.isLoading
  );

  useEffect(() => {
    if (IsAuth()) {
      navigate(`/${GetCurrentLanguageAsText()}/${translate(LabelKeys.AdminUrl)}`);
    }
  }, []);

  useEffect(() => {
    if (reducerState.authentication.isSignedIn === true) {
      navigate(`/${GetCurrentLanguageAsText()}/${translate(LabelKeys.AdminUrl)}`);
    }
  }, [reducerState.authentication.isSignedIn]);

  function handleFieldOnChange(fieldName: FieldNames, value: string) {
    setsignInState((state) => ({
      ...state,
      [fieldName]: value,
    }));
  }

  function handleSetErrorState(fieldName: FieldNames, value: boolean) {
    setsignInErrorState((state) => ({
      ...state,
      [fieldName]: value,
    }));
  }

  function ValidateAllFields(): boolean {
    let valid = true;
    if (!ValidateEmail(signInState.email)) {
      handleSetErrorState(FieldNames.email, true);
      valid = false;
    } else {
      handleSetErrorState(FieldNames.email, false);
    }
    if (!ValidateExactStringLength(signInState.validationCode, 20)) {
      handleSetErrorState(FieldNames.validationCode, true);
      valid = false;
    } else {
      handleSetErrorState(FieldNames.validationCode, false);
    }

    return valid;
  }

  function handleValidateEmail(): void {
    if (ValidateEmail(signInState.email)) {
      dispatch(
        AuthenticationActions.ValidateSignInEmailRequest({
          email: signInState.email,
        } as IValidateSignInEmailRequest)
      );
    }
  }

  function handleSignInClicked(): void {
    let valid = ValidateAllFields();
    if (valid)
      dispatch(
        AuthenticationActions.SignInRequest({
          email: signInState.email,
          validationCode: signInState.validationCode,
        } as ISignInRequest)
      );
  }

  return (
    <Grid container justifyContent="center">
      <Grid container item className={classes.root} xs={10} sm={5} md={5} lg={3} xl={3} justifyContent="center">
        {reducerState.authentication?.isLoading && <ProcessCircle />}
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className={classes.titleContainer}>
          <Typography variant="h1" className={classes.title}>
            {translate(LabelKeys.Welcome)}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className={classes.emailContainer}>
          <CSTextField
            id="email"
            className={classes.email}
            type="email"
            value={signInState.email}
            onChange={(e) => handleFieldOnChange(FieldNames.email, e.target.value)}
            onBlur={(e) => {
              const valid = ValidateEmail(e.target.value);
              if (!valid) {
                handleSetErrorState(FieldNames.email, true);
              } else {
                handleSetErrorState(FieldNames.email, false);
              }
            }}
            placeholder={translate(LabelKeys.Email)}
            error={signInErrorState.email}
            helperText={signInErrorState.email && translate(LabelKeys.InvalidEmailFormat)}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className={classes.validationCodeContainer}>
          <CSTextField
            id="validationCode"
            type="text"
            className={classes.validationCode}
            value={signInState.validationCode}
            onChange={(e) => handleFieldOnChange(FieldNames.validationCode, e.target.value)}
            onBlur={(e) => {
              const valid = ValidateEmail(e.target.value);
              if (!valid) {
                handleSetErrorState(FieldNames.validationCode, true);
              } else {
                handleSetErrorState(FieldNames.validationCode, false);
              }
            }}
            placeholder={translate(LabelKeys.ValidationCode)}
            error={signInErrorState.password}
            helperText={signInErrorState.password && translate(LabelKeys.InvalidEmailFormat)}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ paddingTop: 5 }}>
          <Typography variant="body2">
            <a
              style={{
                float: "left",
                cursor: "pointer",
                fontFamily: "Mulish",
              }}
              className={classes.resendValidationCode}
              id="validateResellerEmail"
              onClick={() => handleValidateEmail()}
            >
              {translate(LabelKeys.ResendValidationCode)}
            </a>
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Button
            id="signInBtn"
            style={{
              height: 60,
              width: "100%",
              cursor: "pointer",
              color: "#fff",
              display: "flex",
              marginTop: 20,
              borderRadius: 0,
              backgroundColor: "#000",
            }}
            onClick={() => handleSignInClicked()}
          >
            <div style={{ margin: "auto", textTransform: "none" }}>{`${translate(LabelKeys.SignIn)}`}</div>
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default SignIn;
