import React, { useEffect, useState } from "react";
import { VoucherStyle } from "./styles";
import { useNavigate, useParams } from "react-router";
import { RouterParams } from "../../Utils/history/history";
import Grid from "@mui/material/Grid/Grid";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store";
import { ProductActions } from "../../Modules/Products/actions";
import { IGetVoucherRequest, IVoucher } from "../../Modules/Products/types";
import { Typography } from "@mui/material";
import { GetCurrentLanguageAsText } from "../../Utils/types";

export default function Voucher() {
  const params = useParams<RouterParams>();
  const classes = VoucherStyle();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [voucher, setVoucher] = useState({} as IVoucher);

  const reducerState = useSelector(
    (s: RootState) => {
      return {
        product: s.product,
      };
    },
    (prev, next) => prev.product.isLoading === next.product.isLoading && prev.product.voucher === next.product.voucher
  );

  //UseEffects
  useEffect(() => {
    if (params.claimcode) {
      dispatch(
        ProductActions.GetVoucherRequest({
          claimCode: params.claimcode,
        } as IGetVoucherRequest)
      );
    }
  }, [params.claimcode]);

  useEffect(() => {
    if (reducerState.product.voucher?.claimCode !== "" || reducerState.product.voucher?.claimCode !== undefined) {
      setVoucher(reducerState.product.voucher);
    } else {
      navigate(`${GetCurrentLanguageAsText()}/error`);
    }
  }, [reducerState.product.voucher]);

  return (
    <Grid
      id="smsCustomPreview"
      container
      item
      xs={12}
      sm={12}
      md={12}
      lg={12}
      xl={12}
      justifyContent={"center"}
      sx={{
        paddingTop: {
          xs: "25px",
          sm: "50px",
          md: "50px",
          lg: "50px",
          xl: "50px",
        },
      }}
    >
      <Grid
        container
        item
        xs={12}
        sm={4}
        md={4}
        lg={3}
        xl={3}
        style={{
          backgroundColor: "rgb(220,220,220, 0.2)",
        }}
      >
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <img className={classes.image} src={voucher.imageBase64} />
        </Grid>
        <Grid container item xs={12} sm={12} md={12} lg={12} xl={12} className={classes.codeContainer}>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            id="code"
            className={classes.code}
            onClick={() => {
              navigator?.clipboard?.writeText(`${reducerState.product.voucher?.claimCode}`);
            }}
          >
            {`${reducerState.product.voucher?.claimCode}`}
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12} id="clickToCopyText" className={classes.codeClickToCopy}>
            {`Klicka för att kopiera`}
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className={classes.message}>
          <Typography variant="body1">{voucher.message}</Typography>
        </Grid>
        <Grid id="expireDateContainer" item xs={12} sm={12} md={12} lg={12} xl={12} className={classes.expireDate}>
          <Typography variant="body1">{`Giltig till: ${new Date(
            reducerState.product.voucher?.expireDate
          ).toLocaleDateString()}`}</Typography>
        </Grid>
        <Grid id="urlContainer" item xs={12} sm={12} md={12} lg={12} xl={12} className={classes.url}>
          <Typography variant="body1">{`Lös in här: ${reducerState.product.voucher?.voucherUrl}`}</Typography>
        </Grid>
      </Grid>
    </Grid>
  );
}
