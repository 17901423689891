import React, { useEffect } from "react";
import { Grid, Typography, useMediaQuery } from "@mui/material";
import { HowItWorksStyles } from "./styles";
import { LabelKeys } from "../../Utils/enums/labelKeys";
import { useTranslation } from "react-i18next";
import { CheckSystemPartCookie } from "../../Utils/authentication";
import { ImageKeys } from "../../Utils/enums/imageKeys";
import { theme } from "../../appTheme";
import { GetLitiumImageUrl } from "../../Utils/extensions";
import { QueryParamKeys } from "../../Utils/enums/queryParams";

export default function HowItWorks() {
  const classes = HowItWorksStyles();
  const { t: translate } = useTranslation();
  const isMobile = useMediaQuery(theme.breakpoints.only("xs"));
  const isSm = useMediaQuery(theme.breakpoints.only("sm"));

  useEffect(() => {
    CheckSystemPartCookie();
    window.scrollTo(0, 0);
  }, []);

  function GetIcon(path: React.SVGProps<SVGSVGElement>, title: LabelKeys, bodyText: LabelKeys) {
    return (
      <Grid container item xs={12} sm={10} md={10} lg={10} xl={10} justifyContent="center" style={{ marginTop: 40, minHeight: 200 }}>
        <Grid item xs={3} sm={2} md={2} lg={2} xl={2}>
          <div
            style={{
              borderRadius: "50%",
              backgroundColor: "#5b89a2",
              height: isMobile ? 60 : 100,
              width: isMobile ? 60 : 100,
              position: "relative",
              float: "right",
              marginRight: 30,
            }}
          >
            <svg
              width={isMobile ? 30 : 45}
              height={isMobile ? 30 : 45}
              fill="currentColor"
              className="bi bi-gift"
              viewBox="0 0 16 16"
              style={{ color: "#fff", position: "absolute", top: isMobile ? 15 : 27, left: isMobile ? 15 : 28 }}
            >
              {path}
            </svg>
          </div>
        </Grid>
        <Grid item xs={8} sm={7} md={7} lg={7} xl={7} style={{ marginTop: 30 }}>
          <div style={{ width: "100%" }}>
            <Typography variant="h2" align="left">
              {translate(title)}
            </Typography>
            <Typography align="left" style={{ marginTop: 30, fontSize: "1.2rem" }}>
              {translate(bodyText)}
            </Typography>
          </div>
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid container justifyContent="center">
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <div style={{ backgroundColor: "#000", height: "70vh", position: "relative" }}>
          <div style={{ zIndex: 1, position: "relative" }}>
            <img
              style={{ opacity: 0.7 }}
              src={GetLitiumImageUrl(ImageKeys.HowItWorksPage, `${QueryParamKeys.MaxWidth}1850`)}
              className={classes.howItWorksPageImage}
            />
          </div>
          <div className={classes.imageTitleText}>
            <Typography style={{ fontSize: isMobile || isSm ? 40 : 60, color: "#fff", fontFamily: "Butler" }}>
              {translate(LabelKeys.HowEasyItIsSection1TitleText)}
            </Typography>
          </div>
        </div>
      </Grid>
      <Grid
        id="step1Container"
        container
        item
        xs={12}
        sm={12}
        md={12}
        lg={12}
        xl={12}
        className={classes["#section2"]}
        justifyContent="center"
      >
        {GetIcon(
          <path d="M3 2.5a2.5 2.5 0 0 1 5 0 2.5 2.5 0 0 1 5 0v.006c0 .07 0 .27-.038.494H15a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1v7.5a1.5 1.5 0 0 1-1.5 1.5h-11A1.5 1.5 0 0 1 1 14.5V7a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h2.038A2.968 2.968 0 0 1 3 2.506V2.5zm1.068.5H7v-.5a1.5 1.5 0 1 0-3 0c0 .085.002.274.045.43a.522.522 0 0 0 .023.07zM9 3h2.932a.56.56 0 0 0 .023-.07c.043-.156.045-.345.045-.43a1.5 1.5 0 0 0-3 0V3zM1 4v2h6V4H1zm8 0v2h6V4H9zm5 3H9v8h4.5a.5.5 0 0 0 .5-.5V7zm-7 8V7H2v7.5a.5.5 0 0 0 .5.5H7z" />,
          LabelKeys.HowItWorksSection2TitleText,
          LabelKeys.HowItWorksSection2BodyText
        )}
      </Grid>
      <Grid
        id="step2Container"
        container
        item
        xs={12}
        sm={12}
        md={12}
        lg={12}
        xl={12}
        className={classes["#section3"]}
        justifyContent="center"
      >
        {GetIcon(
          <React.Fragment>
            <path d="M2.5 8a5.5 5.5 0 0 1 8.25-4.764.5.5 0 0 0 .5-.866A6.5 6.5 0 1 0 14.5 8a.5.5 0 0 0-1 0 5.5 5.5 0 1 1-11 0z" />
            <path d="M15.354 3.354a.5.5 0 0 0-.708-.708L8 9.293 5.354 6.646a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l7-7z" />
          </React.Fragment>,
          LabelKeys.HowItWorksSection3TitleText,
          LabelKeys.HowItWorksSection3BodyText
        )}
      </Grid>
      <Grid
        id="step3Container"
        container
        item
        xs={12}
        sm={12}
        md={12}
        lg={12}
        xl={12}
        className={classes["#section4"]}
        justifyContent="center"
      >
        {GetIcon(
          <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4Zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1H2Zm13 2.383-4.708 2.825L15 11.105V5.383Zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741ZM1 11.105l4.708-2.897L1 5.383v5.722Z" />,
          LabelKeys.HowItWorksSection4TitleText,
          LabelKeys.HowItWorksSection4BodyText
        )}
      </Grid>
      <Grid
        id="step4Container"
        container
        item
        xs={12}
        sm={12}
        md={12}
        lg={12}
        xl={12}
        className={classes["#section5"]}
        justifyContent="center"
      >
        {GetIcon(
          <path d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0zm4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4zm-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10z" />,
          LabelKeys.HowItWorksSection5TitleText,
          LabelKeys.HowItWorksSection5BodyText
        )}
      </Grid>
      <Grid
        id="step5Container"
        container
        item
        xs={12}
        sm={12}
        md={12}
        lg={12}
        xl={12}
        className={classes["#section6"]}
        justifyContent="center"
      >
        {GetIcon(
          <React.Fragment>
            <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zM2 2a1 1 0 0 0-1 1v1h14V3a1 1 0 0 0-1-1H2zm13 3H1v9a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V5z" />
            <path d="M11 7.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1z" />,
          </React.Fragment>,
          LabelKeys.HowItWorksSection6TitleText,
          LabelKeys.HowItWorksSection6BodyText
        )}
      </Grid>
      <Grid
        id="step6Container"
        container
        item
        xs={12}
        sm={12}
        md={12}
        lg={12}
        xl={12}
        className={classes["#section7"]}
        justifyContent="center"
      >
        {GetIcon(
          <path d="M15.854.146a.5.5 0 0 1 .11.54l-5.819 14.547a.75.75 0 0 1-1.329.124l-3.178-4.995L.643 7.184a.75.75 0 0 1 .124-1.33L15.314.037a.5.5 0 0 1 .54.11ZM6.636 10.07l2.761 4.338L14.13 2.576 6.636 10.07Zm6.787-8.201L1.591 6.602l4.339 2.76 7.494-7.493Z" />,
          LabelKeys.HowItWorksSection7TitleText,
          LabelKeys.HowItWorksSection7BodyText
        )}
      </Grid>
    </Grid>
  );
}
