import axios from "axios";
import Cookies from "js-cookie";
import SnackbarActions from "../../../components/Snackbar/actions";
import { SetSignInValues } from "../../../Modules/Authentication/saga";
import { ISignInResponse } from "../../../Modules/Authentication/types";
import store from "../../../store";
import { SignOut } from "../../authentication";
import { Environment, ErrorReason, LanguageCode } from "../../enums/enums";
import { CookieConstants } from "../../Labels/types";
import { GetCurrentLanguageAsCodeFromLocalStorage, GetCurrentLanguageAsText } from "../../types";
import {
  AUTHENTICATION_API,
  DEVELOPMENT_BACKEND_URL,
  PRODUCTION_BACKEND_URL,
  TEST_BACKEND_URL,
  DEVELOPMENT_FRONTEND_URL,
  TEST_FRONTEND_URL,
  PRODUCTION_FRONTEND_URL,
} from "./urls";

//Add BASE_URL to constants file
export const axiosInstance = axios.create({
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Credentials": true,
    // "Access-Control-Allow-Origin": GetCorsHeaderValue(),
  },
  xsrfCookieName: "XSRF-TOKEN",
  xsrfHeaderName: "X-XSRF-TOKEN",
});

axiosInstance.interceptors.request.use((config) => {
  config.headers["Authorization"] = "Bearer" + Cookies.get(CookieConstants.GBVJwt);
  config.headers["RefreshToken"] = Cookies.get(CookieConstants.GBVRefreshToken);
  return config;
});

axiosInstance.interceptors.response.use(
  (response) => {
    if (response.status === 200 && response.data?.error && response.data?.error !== ErrorReason.Unknown) {
      store.dispatch(SnackbarActions.ShowError(`${GetErrorReasonMessage(response?.data?.error)}`));
    }
    return response;
  },
  async function (error) {
    const originalRequest = error.config;
    //Förutsätter att BE fungerar för att gå in här.
    if (
      error.response &&
      error.response.status === 401 &&
      originalRequest.url === GetBaseURL() + AUTHENTICATION_API + "admin/refresh-token"
    ) {
      SignOut();
      return Promise.reject(error);
    }

    if (error.response && error.response.status === 500) {
      store.dispatch(
        SnackbarActions.ShowError(
          `[${new Date().toLocaleString(GetCurrentLanguageAsText())}] - Hoppsan, någon gick visst fel. Vänligen försök igen!`
        )
      );
      return Promise.reject(error);
    }

    if (error.response && error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      const res = await axiosInstance.post(GetBaseURL() + AUTHENTICATION_API + "admin/refresh-token");
      if (res.status === 200) {
        if (res?.data != undefined) {
          SetSignInValues(res.data as ISignInResponse);
        }
        return axiosInstance(originalRequest);
      }
    }
    return Promise.reject(error);
  }
);

export function GetBaseURL(): string {
  switch (process.env.REACT_APP_ENVIRONMENT) {
    case Environment.Development: {
      return DEVELOPMENT_BACKEND_URL;
    }
    case Environment.Test: {
      return TEST_BACKEND_URL;
    }
    case Environment.Production: {
      return PRODUCTION_BACKEND_URL;
    }
    default: {
      return DEVELOPMENT_BACKEND_URL;
    }
  }
}

// export function GetCorsHeaderValue(): string {
//   switch (process.env.REACT_APP_ENVIRONMENT) {
//     case Environment.Development: {
//       return "http://localhost:5000";
//     }
//     case Environment.Test: {
//       return "https://api.test.giftsbyvinga.com";
//     }
//     case Environment.Production: {
//       return "https://api.giftsbyvinga.com";
//     }
//     default: {
//       return "http://localhost:5000";
//     }
//   }
// }

export function GetFrontendBaseURL(): string {
  switch (process.env.REACT_APP_ENVIRONMENT) {
    case Environment.Development: {
      return DEVELOPMENT_FRONTEND_URL;
    }
    case Environment.Test: {
      return TEST_FRONTEND_URL;
    }
    case Environment.Production: {
      return PRODUCTION_FRONTEND_URL;
    }
    default: {
      return DEVELOPMENT_FRONTEND_URL;
    }
  }
}

function GetErrorReasonMessage(error: ErrorReason) {
  const language = GetCurrentLanguageAsCodeFromLocalStorage();
  let message = "";

  if (language == LanguageCode.SE) {
    message = GetSwedishText(error);
  } else if (language == LanguageCode.EN) {
    message = GetEnglishText(error);
  } else {
    message = GetSwedishText(error);
  }

  return message;
}

function GetSwedishText(error: ErrorReason): string {
  switch (error) {
    case ErrorReason.ResellerValidationFail: {
      return `Den angivna emailadressen är inte kopplad till någon återförsäljare.`;
    }
    case ErrorReason.SwedbankPayCreatePurchaseFail: {
      return `Kunde inte skapa betalning. Vänligen försök igen.`;
    }
    case ErrorReason.ResellerAlreadyExists: {
      return `En återförsäljare med angiven information finns redan.`;
    }
    case ErrorReason.ResellerNull: {
      return `Ingen återförsäljare hittades.`;
    }
    case ErrorReason.InvalidEmail: {
      return `Ogiltig emailadress.`;
    }
    case ErrorReason.ResellerContactAlreadyExists: {
      return `Angiven emailadress för den här återförsäljaren finns redan.`;
    }
    case ErrorReason.ResellerContactNull: {
      return `Ingen återförsäljarkontakt hittades.`;
    }
    case ErrorReason.InvalidImageFormat: {
      return `Ogiltigt filformat.`;
    }
    case ErrorReason.InvalidImageSize: {
      return `Ogiltigt filstorlek.`;
    }
    case ErrorReason.ImageNotFound: {
      return `Bilden kunde inte hittas.`;
    }
    case ErrorReason.OrderLineNotFound: {
      return `Orderraden hittades inte.`;
    }
    case ErrorReason.InvalidPhoneNumber: {
      return `Ogiltigt telefonnummer.`;
    }
    case ErrorReason.NoIsStandardImageUrl: {
      return `Ingen standardbild hittades.`;
    }
    case ErrorReason.ObjectNotFoundInDatabase: {
      return `Objekt hittades inte i databasen.`;
    }
    case ErrorReason.RefundNotPossible: {
      return `Återbetalning ej möjlig.`;
    }
    case ErrorReason.OrderAlreadyHandled: {
      return `Ordern har redan hanterats`;
    }
    case ErrorReason.InvalidOrganisationNumber: {
      return `Felaktigt organisationsnummer`;
    }
    case ErrorReason.OrderNotCreatedInNav: {
      return `Ordern har inte skapats i nav så följade åtgärd kan inte slutföras`;
    }
    case ErrorReason.EmailNotExists: {
      return `Emailadressen existerar inte.`;
    }
    default:
      return "Okänt fel, vänligen försök igen.";
  }
}

function GetEnglishText(error: ErrorReason): string {
  switch (error) {
    case ErrorReason.ResellerValidationFail: {
      return `No valid reseller was found.`;
    }
    case ErrorReason.SwedbankPayCreatePurchaseFail: {
      return `Could not create purchase, please try again later.`;
    }
    case ErrorReason.ResellerAlreadyExists: {
      return `Reseller already exists.`;
    }
    case ErrorReason.ResellerNull: {
      return `No reseller was found.`;
    }
    case ErrorReason.InvalidEmail: {
      return `Invalid email address`;
    }
    case ErrorReason.ResellerContactAlreadyExists: {
      return `Reseller contact already exists.`;
    }
    case ErrorReason.ResellerContactNull: {
      return `Reseller contact was not found.`;
    }
    case ErrorReason.InvalidImageFormat: {
      return `Invalid image format.`;
    }
    case ErrorReason.ImageNotFound: {
      return `Image not found.`;
    }
    case ErrorReason.OrderLineNotFound: {
      return `Order line was not found.`;
    }
    case ErrorReason.InvalidPhoneNumber: {
      return `Invalid phone number.`;
    }
    case ErrorReason.NoIsStandardImageUrl: {
      return `No standard image was found.`;
    }
    case ErrorReason.ObjectNotFoundInDatabase: {
      return `Object was not found in database.`;
    }
    case ErrorReason.RefundNotPossible: {
      return `Refund not possible.`;
    }
    case ErrorReason.OrderAlreadyHandled: {
      return `Order has been handled already`;
    }
    case ErrorReason.InvalidOrganisationNumber: {
      return `Invalid organisation number`;
    }
    case ErrorReason.OrderNotCreatedInNav: {
      return `Order is not created in nav and can therefore not finish this task`;
    }
    default:
      return "Unknown error, please try again later.";
  }
}
