import { AuthenticationActionTypes } from "./actionTypes";
import { Reducer } from "redux";
import { IExternalSystem } from "../../views/Admin/SubViews/ExternalSystem/types";

interface IAuthenticationInitState {
  isLoading: boolean;
  isSignedIn: boolean;
  emailIsValid: boolean;
  isResellerSignedIn: boolean;
  isSystemPartDialogOpen: boolean;
  hasBeenSignedOut: boolean;
  externalSystemDetails: IExternalSystem;
  externalSystemRows: IExternalSystem[];
  signedInUserEmail: string;
}

const authenticationInitState: IAuthenticationInitState = {
  isLoading: false,
  isSignedIn: false,
  emailIsValid: false,
  isResellerSignedIn: false,
  isSystemPartDialogOpen: false,
  hasBeenSignedOut: false,
  externalSystemDetails: {} as IExternalSystem,
  externalSystemRows: [],
  signedInUserEmail: "",
};

const AuthenticationReducer: Reducer<IAuthenticationInitState> = (state = authenticationInitState, action) => {
  switch (action.type) {
    case AuthenticationActionTypes.VALIDATE_SIGN_IN_EMAIL_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case AuthenticationActionTypes.VALIDATE_SIGN_IN_EMAIL_SUCCESS:
      return {
        ...state,
        isLoading: false,
        emailIsValid: true,
      };
    case AuthenticationActionTypes.VALIDATE_SIGN_IN_EMAIL_ERROR:
      return {
        ...state,
        isLoading: false,
      };
    case AuthenticationActionTypes.SIGN_IN_REQUEST:
      return {
        ...state,
        isLoading: true,
        isResellerSignedIn: false,
        hasBeenSignedOut: false,
        signedInUserEmail: action.request.email,
      };
    case AuthenticationActionTypes.SIGN_IN_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isSignedIn: true,
      };
    case AuthenticationActionTypes.SIGN_IN_ERROR:
      return {
        ...state,
        isLoading: false,
        isSignedIn: false,
      };
    case AuthenticationActionTypes.SIGN_IN_RESELLER_REQUEST:
      return {
        ...state,
        isLoading: true,
        isSignedIn: false,
        hasBeenSignedOut: false,
      };
    case AuthenticationActionTypes.SIGN_IN_RESELLER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isResellerSignedIn: true,
      };
    case AuthenticationActionTypes.SIGN_IN_RESELLER_ERROR:
      return {
        ...state,
        isLoading: false,
        isResellerSignedIn: false,
      };
    case AuthenticationActionTypes.SIGN_OUT_REQUEST:
      return {
        ...state,
        isLoading: false,
        hasBeenSignedOut: true,
        isSignedIn: false,
      };
    case AuthenticationActionTypes.SIGN_OUT_RESELLER_REQUEST:
      return {
        ...state,
        isLoading: false,
        hasBeenSignedOut: true,
        isResellerSignedIn: false,
      };
    case AuthenticationActionTypes.SET_IS_SYSTEMPART_DIALOG_OPEN_REQUEST:
      return {
        ...state,
        isLoading: false,
        isSystemPartDialogOpen: action.request,
      };
    case AuthenticationActionTypes.RESET_REDUCER:
      return (state = authenticationInitState);
    case AuthenticationActionTypes.GET_EXTERNAL_SYSTEMS_REQUEST:
      return {
        ...state,
        isLoading: false,
      };
    case AuthenticationActionTypes.GET_EXTERNAL_SYSTEMS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        externalSystemRows: action.response.externalSystems,
      };
    case AuthenticationActionTypes.GET_EXTERNAL_SYSTEMS_ERROR:
      return {
        ...state,
        isLoading: false,
      };
    case AuthenticationActionTypes.GET_EXTERNAL_SYSTEM_DETAILS_REQUEST:
      return {
        ...state,
        isLoading: false,
      };
    case AuthenticationActionTypes.GET_EXTERNAL_SYSTEM_DETAILS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        externalSystemDetails: action.response.externalSystem,
      };
    case AuthenticationActionTypes.GET_EXTERNAL_SYSTEM_DETAILS_ERROR:
      return {
        ...state,
        isLoading: false,
      };
    case AuthenticationActionTypes.ADD_EXTERNAL_SYSTEM_REQUEST:
      return {
        ...state,
        isLoading: false,
      };
    case AuthenticationActionTypes.ADD_EXTERNAL_SYSTEM_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case AuthenticationActionTypes.ADD_EXTERNAL_SYSTEM_ERROR:
      return {
        ...state,
        isLoading: false,
      };
    case AuthenticationActionTypes.UPDATE_EXTERNAL_SYSTEM_REQUEST:
      return {
        ...state,
        isLoading: false,
      };
    case AuthenticationActionTypes.UPDATE_EXTERNAL_SYSTEM_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case AuthenticationActionTypes.UPDATE_EXTERNAL_SYSTEM_ERROR:
      return {
        ...state,
        isLoading: false,
      };
    case AuthenticationActionTypes.SET_SIGNED_IN_USER_EMAIL_REQUEST:
      return {
        ...state,
        isLoading: false,
        signedInUserEmail: action.request.email,
      };

    default:
      return state;
  }
};

export default AuthenticationReducer;
