import React, { useEffect } from "react";
import { Grid, Typography, useMediaQuery } from "@mui/material";
import { CheckSystemPartCookie } from "../../Utils/authentication";
import { useDispatch, useSelector } from "react-redux";
import { ResellerActions } from "../../Modules/Reseller/actions";
import { IGetResellerContactPageInformtaionRequest } from "../../Modules/Reseller/types";
import Cookies from "js-cookie";
import { CookieConstants } from "../../Utils/Labels/types";
import { RootState } from "../../store";
import { theme } from "../../appTheme";
import { ImageKeys } from "../../Utils/enums/imageKeys";
import { GetLitiumImageUrl } from "../../Utils/extensions";
import { useTranslation } from "react-i18next";
import { LabelKeys } from "../../Utils/enums/labelKeys";

export default function Contact() {
  const dispatch = useDispatch();
  const isMobile = useMediaQuery(theme.breakpoints.only("xs"));
  const { t: translate } = useTranslation();
  useEffect(() => {
    window.scrollTo(0, 0);
    CheckSystemPartCookie();
    const resellerCode = Cookies.get(CookieConstants.GBVReseller);
    if (resellerCode) {
      dispatch(
        ResellerActions.GetResellerContactPageInformationRequest({
          resellerCode: resellerCode,
        } as IGetResellerContactPageInformtaionRequest)
      );
    }
  }, []);

  const reducerState = useSelector(
    (s: RootState) => {
      return {
        reseller: s.reseller,
      };
    },
    (prev, next) => prev.reseller.resellerContactPageInformation === next.reseller.resellerContactPageInformation
  );

  return (
    <Grid container item xs={12} sm={12} md={12} lg={12} xl={12} justifyContent="center" style={{ marginTop: 30 }}>
      <Grid container item xs={12} sm={9} md={9} lg={9} xl={9}>
        <Grid id="leftSideContactPage" container item xs={11} sm={6} md={6} lg={6} xl={6}>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            justifyContent="flex-start"
            style={{ display: "flex", paddingLeft: isMobile ? 10 : "" }}
          >
            <Typography variant="h1" style={{ paddingTop: 30, textAlign: "left" }}>
              {translate(LabelKeys.ContactYourReseller)}
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            justifyContent="flex-start"
            style={{ display: "flex", paddingLeft: isMobile ? 10 : "", marginTop: 20 }}
          >
            <Typography variant="h4">{translate(LabelKeys.PhoneNumber)}</Typography>
          </Grid>
          {reducerState.reseller.resellerContactPageInformation?.contactPhoneNumbers?.map((x, index) => (
            <Grid
              key={index}
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              justifyContent="flex-start"
              style={{ display: "flex", paddingLeft: isMobile ? 10 : "" }}
            >
              <Typography key={index} variant="body1">
                {x}
              </Typography>
            </Grid>
          ))}
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            justifyContent="flex-start"
            style={{ display: "flex", paddingLeft: isMobile ? 10 : "", marginTop: 20 }}
          >
            <Typography variant="h4">{translate(LabelKeys.Email2)}</Typography>
          </Grid>
          {reducerState.reseller.resellerContactPageInformation?.contactEmails?.map((x, index) => (
            <Grid
              key={index}
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              justifyContent="flex-start"
              style={{ display: "flex", paddingLeft: isMobile ? 10 : "" }}
            >
              <a key={index} href={`mailto:${x}?subject=GiftsByVinga contact`} style={{ fontFamily: "mulish" }}>
                {x}
              </a>
            </Grid>
          ))}
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            justifyContent="flex-start"
            style={{ display: "flex", paddingLeft: isMobile ? 10 : "", marginTop: 20 }}
          >
            <Typography variant="h4">{translate(LabelKeys.Address)}</Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            justifyContent="flex-start"
            style={{ display: "flex", paddingLeft: isMobile ? 10 : "" }}
          >
            <Typography variant="body1">{reducerState.reseller.resellerContactPageInformation?.addressLine1}</Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            justifyContent="flex-start"
            style={{ display: "flex", paddingLeft: isMobile ? 10 : "" }}
          >
            <Typography variant="body1">{`${reducerState.reseller.resellerContactPageInformation?.postalCode} ${reducerState.reseller.resellerContactPageInformation?.city}`}</Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            justifyContent="flex-start"
            style={{ display: "flex", paddingLeft: isMobile ? 10 : "" }}
          >
            <Typography variant="body1">{reducerState.reseller.resellerContactPageInformation?.country}</Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            justifyContent="flex-start"
            style={{ display: "flex", paddingLeft: isMobile ? 10 : "", marginTop: 20 }}
          >
            <Typography variant="h4">{translate(LabelKeys.Website)}</Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            justifyContent="flex-start"
            style={{ display: "flex", paddingLeft: isMobile ? 10 : "", fontFamily: "mulish" }}
          >
            <a href={`https://${reducerState.reseller.resellerContactPageInformation?.website}`} target="_blank" rel="noreferrer">
              {`${reducerState.reseller.resellerContactPageInformation?.website}`}
            </a>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ minHeight: isMobile ? 0 : 350 }} />
        </Grid>
        <Grid id="rightSideContactPage" container item xs={12} sm={6} md={6} lg={6} xl={6}>
          <Grid
            id="rightContent"
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            justifyContent={isMobile ? "flex-start" : "flex-end"}
            style={{ display: "flex", paddingTop: 10, paddingLeft: isMobile ? 10 : "" }}
          >
            <img
              style={{ objectFit: "cover", maxHeight: 650, maxWidth: "100%" }}
              src={GetLitiumImageUrl(ImageKeys.ContactPageDesktop, "")}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
