import React from "react";
import { Grid, Button, useMediaQuery } from "@mui/material";
import { useTranslation } from "react-i18next";
import { LabelKeys } from "../../Utils/enums/labelKeys";
import { SendBy } from "../Delivery/types";
import MailPDFPreview from "../Preview/MailPDFPreview";
import MailPreview from "../Preview/MailPreview";
import SMSCustomPreview from "../Preview/SMSCustomPreview";
import SMSPreview from "../Preview/SMSPreview";
import { MessageStyles } from "./styles";
import { IImageOption, IProduct } from "../../Modules/Products/types";
import { IUploadedFile } from "../FileUploader/types";
import { useDispatch } from "react-redux";
import { ProductActions } from "../../Modules/Products/actions";
import PhysicalCardPreview from "../Preview/PhysicalCardPreview";
import { theme } from "../../appTheme";

interface IMessagePreviewWrapperProps {
  id: string;
  sendBy: SendBy;
  smsMessage: string;
  emailMessage: string;
  emailPDFMessage: string;
  selectedImage: IImageOption;
  uploadedImage: IUploadedFile;
  customMessage: boolean;
  selectedProduct: IProduct;
  physicalCardSelectedCoverImage?: string;
  physicalCardUploadedCoverImage?: string;
  physicalCardMiddlePageImage?: string;
  onOpenPdfChanged: (value: boolean) => void;
}

export default function MessagePreviewWrapper(props: IMessagePreviewWrapperProps) {
  const { t: translate } = useTranslation();
  const classes = MessageStyles();
  const dispatch = useDispatch();
  const isSm = useMediaQuery(theme.breakpoints.only("sm"));
  const isMd = useMediaQuery(theme.breakpoints.only("md"));

  function handleGetPreviewPDF(): void {
    dispatch(
      ProductActions.GetPreviewPDFRequest({
        message: props?.emailPDFMessage,
        emailHeaderImageId: !props.uploadedImage?.base64 ? props.selectedImage?.imageId : undefined,
        customEmailHeaderImageFile: props?.uploadedImage?.base64,
        customEmailHeaderImageFileType: props.uploadedImage?.type,
        productId: props.selectedProduct?.id,
        language: props.selectedProduct.language,
      })
    );
    props.onOpenPdfChanged(true);
  }

  return (
    <Grid
      container
      item
      xs={12}
      sm={12}
      md={props.sendBy == SendBy.SMS || props.sendBy == SendBy.Email || props.sendBy == SendBy.PhysicalCard ? 6 : 12}
      lg={6}
      xl={6}
      className={classes.previewContainer}
      alignContent="flex-start"
    >
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        lg={12}
        xl={12}
        style={{
          position: "sticky",
          top: 50,
          marginTop: 30,
          right: 0,
        }}
        justifyContent="flex-end"
      >
        {props.sendBy == SendBy.Email ? (
          <Grid
            id="mailPreviewContainer"
            container
            item
            xs={12}
            sm={8}
            md={10}
            lg={9}
            xl={8}
            style={{ float: isSm ? "inherit" : "right" }}
            justifyContent="center"
          >
            <MailPreview
              className={classes.preview}
              emailMessage={props.emailMessage}
              imageUrl={props?.selectedImage?.url ? props?.selectedImage?.url : props?.uploadedImage?.base64}
            />
          </Grid>
        ) : props.sendBy == SendBy.SMS ? (
          !props.customMessage ? (
            <SMSPreview smsMessage={props.smsMessage} />
          ) : (
            <SMSCustomPreview
              bodyText={props.smsMessage}
              imageUrl={props?.selectedImage?.url ? props?.selectedImage?.url : props?.uploadedImage?.base64}
            />
          )
        ) : props.sendBy == SendBy.EmailPDF ? (
          <Grid id="mailPDFPreviewContainer" container justifyContent={isSm || isMd ? "left" : "right"}>
            <Grid
              id="mailPDFPreviewItem"
              item
              container
              xs={12}
              sm={8}
              md={7}
              lg={11}
              xl={10}
              style={{ marginTop: 30 }}
              justifyContent={isSm || isMd ? "left" : "right"}
            >
              <MailPDFPreview
                className={classes.preview}
                productCategory={props.selectedProduct.category}
                bodyText={props.emailPDFMessage}
                imageUrl={props?.selectedImage?.url ? props?.selectedImage?.url : props?.uploadedImage?.base64}
              />
            </Grid>
            <Grid id="mailPDFPreviewBtn" item xs={12} sm={8} md={7} lg={11} xl={10} style={{ marginTop: 30 }}>
              <Button
                style={{
                  border: "1px solid #000",
                  height: 40,
                  width: 200,
                  backgroundColor: "transparent",
                  color: "#000",
                  textTransform: "unset",
                  boxShadow: "0 0 0 0",
                }}
                onClick={() => handleGetPreviewPDF()}
                variant="contained"
              >
                {translate(LabelKeys.PreviewPDF)}
              </Button>
            </Grid>
          </Grid>
        ) : (
          <Grid id="physicalCardPreviewItemOuter" item xs={12} sm={8} md={12} lg={12} xl={8} style={{ marginTop: 30 }}>
            {(props.physicalCardUploadedCoverImage?.length > 0 || props.physicalCardSelectedCoverImage?.length > 0) &&
              props.physicalCardMiddlePageImage != undefined && (
                <PhysicalCardPreview
                  className={classes.preview}
                  physicalCardSelectedCoverImage={props.physicalCardSelectedCoverImage}
                  physicalCardUploadedCoverImage={props.physicalCardUploadedCoverImage}
                  physicalCardMiddlePageImage={props.physicalCardMiddlePageImage}
                />
              )}
          </Grid>
        )}
      </Grid>
    </Grid>
  );
}
