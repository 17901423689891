import { makeStyles } from '@mui/styles';
import { theme } from '../../appTheme';

export const ExampleAssortmentStyles = makeStyles({
  exampleAssortmentContainer: {
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    [theme.breakpoints.only('xs')]: {
      //height: 190,
      display: 'flex',
      flexWrap: 'nowrap',
      overflowX: 'scroll',
      zoom: 0.8,
    },
    [theme.breakpoints.only('sm')]: {
      zoom: 0.817,
    },
    [theme.breakpoints.only('md')]: {
      zoom: 1.09,
    },
  },
});
