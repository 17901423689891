import { FAQItem } from "./types";

export const FAQList = [
  {
    key: "key1",
    title: "Kan jag skicka flera gåvokort till samma telefonnummer?",
    body: 'Absolut! Välj först leveranssätt "SMS". Följ sedan anvisningarna under steget "Mottagare" för att ta reda på hur du går tillväga.',
  },
  {
    key: "key2",
    title: "Kan jag skicka flera gåvokort till samma e-mail-address?",
    body: 'Absolut! Välj först leveranssätt "E-mail". Följ sedan anvisningarna under steget "Mottagare" för att ta reda på hur du går tillväga.',
  },
  {
    key: "key3",
    title: "Vem ansvarar för Gifts by Vinga?",
    body:
      'Tjänsten "Gifts by Vinga" tillhandahålls och underhålls av Vinga of Sweden AB.\n\n' +
      "Vinga of Sweden AB\nRyssnäsgatan 3\n504 64 Borås\nTel. 033-22 60 40\nOrg. 556676-4063",
  },
  {
    key: "key4",
    title: "Hur når gåvokortet mottagaren?",
    body: "Gåvokorten kan antingen skickas ut via SMS eller E-mail.",
  },
  {
    key: "key5",
    title: "Vad kostar det att skicka ett gåvokort?",
    body:
      "Det finns olika valörer att välja och priset bestäms därför baserat på det val du gör. " +
      "Kom ihåg att eventuella tilläggstjänster kostar extra och kan påverka priset på slutraden.",
  },
  {
    key: "key6",
    title: "Vad kan man välja när man löser in gåvokortet?",
    body: "Det beror på kortets valör och typ. Gåvokortet 'Flexible' erbjuder mottagaren att välja fritt från vårt sortiment och det finns även möjlighet att lägga till en summa. Gåvokortet 'Selection' låter mottagaren välja en gåva från ett utvalt sortiment. Sortimentet ser du när du valt kort.",
  },
  {
    key: "key7",
    title: "Var kan jag lösa in mitt gåvokort?",
    body: "Gåvokortet Flexible By Vinga Home löse du in på www.vingahome.com/los-in och Gåvokort 'Selection' löses in på www.getmygift.com/se/inlosen.",
  },
  {
    key: "key8",
    title: "Hur länge är gåvokortet giltigt?",
    body: "Giltighetsdatumet står angivet i det SMS eller e-mail som skicks tillsammans med gåvokortet.",
  },
  {
    key: "key9",
    title: "Vilka telefonnummer kan jag skicka ett gåvokort till?",
    body: 'Alla svenska telefonnummer som kan ta emot SMS och börjar på "07" eller "+46".',
  },
  {
    key: "key10",
    title: "Hur betalar jag för ett gåvokort?",
    body: "Gåvokorten betalas med faktura.",
  },
  {
    key: "key11",
    title: "När levereras gåvokortet?",
    body:
      "Gåvokortet levereras på angivet datum och tid. Det kan även skickas direkt om så önskas. " +
      "Personlig hemsida och faktura kan medföra en längre leveranstid (gäller endast företag).\n",
  },
  {
    key: "key12",
    title: "Får jag kvitto på min betalning?",
    body: 'Självklart! Kvittot skickas till den e-mail som anges i fältet "E-mail" under steget "Avsändare".',
  },
  {
    key: "key13",
    title: "Hur kommer mitt gåvokort se ut?",
    body:
      "När du valt en produkt tillsammans med ett önskat leveranssätt kommer du kunna se en förhandsvisning på sidan.\n" +
      "Tänk på att en viss skillnad kan uppstå i färg och formatering i meddelandet mellan förhandsvisningen och det riktiga gåvokortet.",
  },
  {
    key: "key14",
    title: "Hur hanteras mina personuppgifter?",
    body:
      "Gifts by Vinga samlar endast in den information som är nödvändig för att leverera tjänsten. " +
      "Du kan hitta vår integritetspolicy här - www.vinga.com/se/integritetspolicy",
  },
  {
    key: "key15",
    title: "Får jag någon leveransbekräftelse på min beställning?",
    body: "Nej, vi skickar inte ut någon leveransbekräftelse, däremot skickas en .pdf med alla koder till avsändaren.",
  },
  {
    key: "key16",
    title: "Vilka korttyper kan jag välja?",
    body:
      'Vi erbjuder två olika korttyper. Gåvokort "Flexible By Vinga Home" och Gåvokort "Selection".\n\n' +
      'Gåvokortet "Flexible By Vinga Home" låter mottagaren välja fritt från vårt sortiment och det finns även möjlighet att lägga till en summa om så önskas.\n' +
      'Gåvokortet "Selection" låter mottagaren välja en gåva från ett utvalt sortiment och kortets valör visas inte för mottagaren. Sortimentet ser du när du valt kort',
  },
  {
    key: "key17",
    title: "Jag valde fel leveransdatum, kan jag ändra det i efterhand?",
    body: "Det är tyvärr inte möjligt att ändra leveransdatum efter det att ordern är lagd.",
  },
  {
    key: "key18",
    title: "Varför ändras leveransdatumet när jag väljer personlig hemsida? (Gäller företag)",
    body: "Personlig hemsida kräver manuell handpåläggning, vilket medför extra ledtider.",
  },
] as FAQItem[];
