export enum LabelKeys {
  //Common
  Welcome = "welcome",
  HomePage = "homePage",
  PrivatePage = "privatePage",
  ContactPage = "contactPage",
  CompanyPage = "companyPage",
  AdminOrderPage = "adminOrderPage",
  AdminInvoicePage = "adminInvoicePage",
  AdminResellerPage = "adminResellerPage",
  ResellerPage = "resellerPage",
  FAQPage = "faqPage",
  FAQPageTitle = "faqPageTitle",
  HowItWorksPage = "howItWorksPage",
  ArrivalDate = "arrivalDate",
  NoResults = "noResults",
  ThankYouForYourMessage = "thankYouForYourMessage",
  ContactAnswAsFast = "contactAnswAsFast",
  CreateInvoiceInvalidDateFormat = "createInvoiceInvalidDateFormat",
  Ok = "ok",
  Preview = "preview",
  Active = "active",
  Update = "update",
  Generate = "generate",
  Create = "create",
  ChooseImage = "chooseImage",
  CreatedDate = "createdDate",
  OrderDate = "orderDate",
  OrderStatus = "orderStatus",
  AmountOfReceivers = "amountOfReceivers",
  CreateInvoice = "createInvoice",
  CreateInvoiceBasis = "createInvoiceBasis",
  CreateReseller = "createReseller",
  CreateResellerContact = "createResellerContact",
  CreateResellerContactCustomerNumber = "createResellerContactCustomerNumber",
  CreateResellerContactEmail = "createResellerContactEmail",
  UpdateResellerContactEmail = "updateResellerContactEmail",
  GetResellerContactEmail = "getResellerContactEmail",
  ManyAnswersHere = "manyAnswersHere",
  ChooseImageInfo = "chooseImageInfo",
  Here = "here",
  From = "from",
  To = "to",
  OrderId = "orderId",
  ReadMore = "readMore",
  GoBack = "goBack",
  OrderDetails = "orderDetails",
  CustomerNumber = "customerNumber",
  CustomerNumberToolip = "customerNumberToolip",
  Cancel = "cancel",
  Today = "today",
  SearchOrder = "searchOrder",
  SearchOrderBtn = "searchOrderBtn",
  SearchOrderCustomerNumber = "searchOrderCustomerNumber",
  EmailContent = "emailContent",
  SMSContent = "smsContent",
  SmsCharactersLeft = "smsCharactersLeft",
  WriteMessageHere = "writeMessageHere",
  GoToPayment = "goToPayment",
  Message = "message",
  Subject = "subject",
  Title = "title",
  AcceptsTermsOfUse = "acceptsTermsOfUse",
  ForThisService = "forThisService",
  TermsOfUseUrl = "termsOfUseUrl",
  TermsOfUse = "termsOfUse",
  TermsOfUseFull = "termsOfUseFull",
  Delivery = "delivery",
  FooterAbout = "footerAbout",
  FooterInformation = "footerInformation",
  SendGift = "sendGift",
  BuyNow = "buyNow",
  Popular = "popular",
  SendGiftAs = "sendGiftAs",
  UseCustomMessage = "useCustomMessage",
  ExtraPerSMS = "extraPerSMS",
  ExtraPerEmail = "extraPerEmail",
  ExtraPerSMSReseller = "extraPerSMSReseller",
  ExtraPerEmailReseller = "extraPerEmailReseller",
  ChooseGiftHere = "chooseGiftHere",
  Max = "max",
  Now = "now",
  SpecificTime = "specificTime",
  SignIn = "signIn",
  Username = "username",
  Password = "password",
  AdminUrl = "admin",
  AdminInvoiceUrl = "adminInvoiceUrl",
  AdminOrderUrl = "adminOrderUrl",
  AdminResellerUrl = "adminResellerUrl",
  AdminOrderOrderLineUrl = "adminOrderOrderLineUrl",
  SignInUrl = "signInUrl",
  Resend = "resend",
  ResendOrder = "resendOrder",
  ResendReceipt = "resendReceipt",
  ValidatedAs = "validatedAs",
  TooManyReceivers = "tooManyReceivers",
  Characters = "characters",
  StandardSMSMessagePrivate = "standardSMSMessagePrivate",
  StandardEmailMessagePrivate = "standardEmailMessagePrivate",
  StandardSMSMessageCompany = "standardSMSMessageCompany",
  StandardEmailMessageCompany = "standardEmailMessageCompany",
  StandardSMSMessageReseller = "standardSMSMessageReseller",
  StandardEmailMessageReseller = "standardEmailMessageReseller",
  PreviewVariationInformation = "previewVariationInformation",
  BestRegards = "bestRegards",
  DeliverReceiverPhoneNumberTooltip = "deliverReceiverPhoneNumberTooltip",
  DeliverReceiverEmailTooltip = "deliverReceiverEmailTooltip",
  ReceiverEmailTooltip = "receiverEmailTooltip",
  AddMoreReceivers = "addMoreReceivers",
  LessReceivers = "lessReceivers",
  LessCustomerNumbers = "lessCustomerNumbers",
  AddMoreCustomerNumbers = "addMoreCustomerNumbers",
  LessOrderNumbers = "lessOrderNumbers",
  AddMoreOrderNumbers = "addMoreOrderNumbers",
  UploadPreviewImageBtn = "uploadPreviewImageBtn",
  IncludeFreight = "includeFreight",
  PerUnit = "perUnit",
  Send = "send",
  OrderUrl = "orderUrl",
  ProductsNotExists = "productsNotExists",
  NoSelectedProduct = "noSelectedProduct",
  SignatureInMessage = "signatureInMessage",
  ThankYouForYourOrder = "thankYouForYourOrder",
  CheckMandatoryField = "checkMandatoryField",
  HowItWorks = "howItWorks",
  HowItWorksUrl = "howItWorksUrl",
  FAQ = "faq",
  FAQUrl = "faqUrl",
  ToHomePage = "toHomePage",
  TotalCost = "totalCost",
  ProductCost = "productCost",
  FreightCost = "freightCost",
  AdditionalServicesCost = "additionalServicesCost",
  ValidateResellerEmail = "validateResellerEmail",
  Discount = "discount",
  DiscountInProcent = "discountInProcent",
  MoreQuestions = "moreQuestions",
  Validate = "validate",
  HasMultipleEntries = "hasMultipleEntries",
  OrderNumber = "orderNumber",
  OneEmailPerRow = "oneEmailPerRow",
  DiscountBetween0and100 = "discountBetween0and100",
  FieldCantBeEmpty = "fieldCantBeEmpty",
  HandleOrder = "handleOrder",
  HandleInvoice = "handleInvoice",
  HandleReseller = "handleReseller",
  UpdateReseller = "updateReseller",
  HandleOrderContact = "handleOrderContact",
  HandleInvoiceContact = "handleInvoiceContact",
  HandleResellerContact = "handleResellerContact",
  UpdateResellerContact = "updateResellerContact",
  Get = "get",
  Save = "save",
  SentDate = "sentDate",
  OrderLineId = "orderLineId",
  ImagesMailing = "imagesMailing",
  Id = "id",
  Url = "url",
  Delete = "delete",
  Add = "add",
  GmgPdfFooterText = "gmgPdfFooterText",
  VhPdfFooterText = "vhPdfFooterText",
  FrequentlyAskedQuestions = "frequentlyAskedQuestions",
  HowItWorksHomePageLeftTitle = "howItWorksHomePageLeftTitle",
  HowItWorksHomePageMiddleTitle = "howItWorksHomePageMiddleTitle",
  HowItWorksHomePageRightTitle = "howItWorksHomePageRightTitle",
  HowItWorksHomePageLeftBody = "howItWorksHomePageLeftBody",
  HowItWorksHomePageMiddleBody = "howItWorksHomePageMiddleBody",
  HowItWorksHomePageRightBody = "howItWorksHomePageRightBody",
  SendGiftCard = "sendGiftCard",
  OrderConfirmationUrl = "orderConfirmationUrl",
  OrderConfirmationBody1 = "orderConfirmationBody1",
  OrderConfirmationBody2 = "orderConfirmationBody2",
  OrderConfirmationBody3 = "orderConfirmationBody3",

  //Private
  PrivatePageInfoTitleText = "privatePageInfoTitleText",
  PrivatePageInfoBodyText = "privatePageInfoBodyText",

  //Company
  OrganisationNumber = "organisationNumber",
  Company = "company",
  CompanyInformation = "companyInformation",
  CompanyUrl = "companyUrl",
  CompanyName = "companyName",
  PhoneNumber = "phoneNumber",
  Email = "email",
  EmailPDF = "emailPDF",
  Date = "date",
  Sender = "sender",
  Receiver = "receiver",
  ReceiverName = "receiverName",
  SenderPhoneNumber = "senderPhoneNumber",
  SenderEmail = "senderEmail",
  ReceiverPhoneNumbers = "receiverPhoneNumbers",
  ReceiverEmails = "receiverEmails",
  Marking = "marking",
  CompanyPageInfoTitleText = "companyPageInfoTitleText",
  CompanyPageInfoBodyText = "companyPageInfoBodyText",
  Media = "media",

  //Products
  Product = "product",
  ProductUrl = "productUrl",
  Products = "products",
  Information = "information",

  //Payment
  Payment = "payment",
  PayWith = "payWith",
  PayWithSwish = "payWithSwish",
  PayWithMasterCardVisa = "payWithMasterCardVisa",
  PaymentMethod = "paymentMethod",

  //Validation
  InvalidEmailFormat = "invalidEmailFormat",
  InvalidCustomerNumber = "invalidCustomerNumber",
  InvalidCompanyName = "invalidCompanyName",
  InvalidOrganisationNumber = "invalidOrganisationNumber",
  InvalidReceiverPhoneNumbers = "invalidReceiverPhoneNumbers",
  InvalidReceiverEmailAddresses = "invalidReceiverEmailAddresses",
  InvalidReceiverPhoneNumberNotOnlyDigits = "invalidReceiverPhoneNumberNotOnlyDigits",
  InvalidDateTimeFormat = "invalidDateTimeFormat",
  ContactInvalidMessage = "contactInvalidMessage",
  ContactInvalidSubject = "contactInvalidSubject",
  ValidationError = "validationError",
  InvalidFileFormat = "invalidFileFormat",
  InvalidFileSize = "invalidFileSize",

  //Reseller
  ResellerUrl = "resellerUrl",
  ResellerInformation = "resellerInformation",
  ContactEmail = "contactEmail",
  ValidationCode = "validationCode",
  InvalidValidationCode = "invalidValidationCode",
  ResellerPageInfoTitleText = "resellerPageInfoTitleText",
  ResellerPageInfoBodyText = "resellerPageInfoBodyText",

  //Contact
  ContactUrl = "contactUrl",
  ContactUs = "contactUs",

  //Admin
  InvalidOrderIds = "invalidOrderIds",
  OneOrderIdPerRow = "oneOrderIdPerRow",
  ResendOrders = "resendOrders",
  ResendReceipts = "resendReceipts",

  //How it works
  HowEasyItIsSection1TitleText = "howEasyItIsSection1TitleText",
  HowItWorksSection2TitleText = "howItWorksSection2TitleText",
  HowItWorksSection3TitleText = "howItWorksSection3TitleText",
  HowItWorksSection4TitleText = "howItWorksSection4TitleText",
  HowItWorksSection5TitleText = "howItWorksSection5TitleText",
  HowItWorksSection6TitleText = "howItWorksSection6TitleText",
  HowItWorksSection7TitleText = "howItWorksSection7TitleText",
  HowItWorksSection2BodyText = "howItWorksSection2BodyText",
  HowItWorksSection3BodyText = "howItWorksSection3BodyText",
  HowItWorksSection4BodyText = "howItWorksSection4BodyText",
  HowItWorksSection5BodyText = "howItWorksSection5BodyText",
  HowItWorksSection6BodyText = "howItWorksSection6BodyText",
  HowItWorksSection7BodyText = "howItWorksSection7BodyText",
  AllowedFileTypes = "allowedFileTypes",
  AllowedFileMaxSize = "allowedFileMaxSize",
  AllowedFileFormat = "allowedFileFormat",
  AmountOfMailings = "amountOfMailings",
  InvalidAmountOfMailings = "invalidAmountOfMailings",
  SentToSameReceiver = "sentToSameReceiver",
  SMS = "SMS",
  PreviewVariationInformationPDF = "previewVariationInformationPDF",
  PreviewPDF = "previewPDF",
  GiftCardCode = "giftCardCode",
  ValidThrough = "validThrough",
  AcceptedPDFPreview = "acceptedPDFPreview",
  InvalidImageResolution = "invalidImageResolution",
  Pixels = "pixels",
  FirstName = "firstName",
  LastName = "lastName",
  Tags = "tags",
  Copied = "copied",
  CopyToClipboard = "copyToClipboard",
  UploadReceivers = "uploadReceivers",
  ScrollToTop = "scrollToTop",
  InvalidFileImport = "invalidFileImport",
  AllowedReceiverTypes = "allowedReceiverTypes",
  CouldNotGetReceivers = "couldNotGetReceivers",
  MesageTagInfo = "mesageTagInfo",
  Templates = "templates",
  AdminMediaPage = "adminMediaPage",
  AdminTemplatePage = "adminTemplatePage",
  DeliverReceiverPhoneNumberTooltipPrivate = "deliverReceiverPhoneNumberTooltipPrivate",
  DeliverReceiverEmailTooltipPrivate = "deliverReceiverEmailTooltipPrivate",
  ContactResellerName = "contactResellerName",
  ContactResellerNameInfo = "contactResellerNameInfo",
  TemplateTooltipInfo = "templateTooltipInfo",
  CouldNotSetStandardImage = "couldNotSetStandardImage",
  IsStandard = "isStandard",
  SearchOrderTitle = "searchOrderTitle",
  ClickToCopy = "clickToCopy",
  ResendValidationCode = "resendValidationCode",
  ResellerOrderUrl = "resellerOrderUrl",
  ResellerOrderUrlInfo = "resellerOrderUrlInfo",
  Logo = "logo",
  AllowedFileTypesResellerLogo = "allowedFileTypesResellerLogo",
  AllowedFileFormatResellerLogo = "allowedFileFormatResellerLogo",
  AllowedFileMaxSizeResellerLogo = "allowedFileMaxSizeResellerLogo",
  AutomaticlyAssigned = "automaticlyAssigned",
  CorrectOrganisationNumberFormats = "correctOrganisationNumberFormats",
  CorrectPhoneNumberFormats = "correctPhoneNumberFormats",
  CorrectEmailFormat = "correctEmailFormat",
  GotIt = "gotIt",
  CookieInfo = "cookieInfo",
  CookieConsent = "cookieConsent",
  Private = "private",
  Reseller = "reseller",
  LoginHere = "loginHere",
  Previous = "previous",
  Next = "next",
  TheFinestYouCouldGivePrivate = "theFinestYouCouldGivePrivate",
  TheFinestYouCouldGiveCompany = "theFinestYouCouldGiveCompany",
  TheFinestYouCouldGivePrivateDescription = "theFinestYouCouldGivePrivateDescription",
  TheFinestYouCouldGiveCompanyDescription = "theFinestYouCouldGiveCompanyDescription",
  GettingStarted = "gettingStarted",
  ExampleAssortment = "exampleAssortment",
  SeeWholeAssortment = "seeWholeAssortment",
  SeeWholeAssortmentVH = "seeWholeAssortmentVH",
  ChooseProductPrice = "chooseProductPrice",
  PopularChoice = "popularChoice",
  Design = "design",
  MarkingPlaceholder = "markingPlaceholder",
  ContactResellerNamePlaceholder = "contactResellerNamePlaceholder",
  OrganisationNumberPlaceholder = "organisationNumberPlaceholder",
  CompanyNamePlaceholder = "companyNamePlaceholder",
  ContactResllerEmailPlaceholder = "contactResllerEmailPlaceholder",
  SenderEmailPlaceholder = "senderEmailPlaceholder",
  Client = "client",
  Sum = "sum",
  Amount = "amount",
  Price = "price",
  Totaly = "totaly",
  Vat = "vat",
  IncludingVat = "includingVat",
  ExcludingVat = "excludingVat",
  ChoosePaymentMethod = "choosePaymentMethod",
  Pay = "pay",
  GiftcardVHName = "giftcardVHName",
  ByVingaHome = "byVingaHome",
  GiveAwayGiftcard = "giveAwayGiftcard",
  HomePageContentTitle1 = "homePageContentTitle1",
  HomePageContentTitle2 = "homePageContentTitle2",
  HomePageContentTitle3 = "homePageContentTitle3",
  HomePageContentTitle4 = "homePageContentTitle4",
  HomePageContentTitle5 = "homePageContentTitle5",
  HomePageContentTitle6 = "homePageContentTitle6",
  HomePageContentBody1 = "homePageContentBody1",
  HomePageContentBody2 = "homePageContentBody2",
  HomePageContentBody3 = "homePageContentBody3",
  HomePageContentBody4 = "homePageContentBody4",
  HomePageContentBody5 = "homePageContentBody5",
  HomePageContentBody6 = "homePageContentBody6",
  SustainableProducts = "sustainableProducts",
  NoSelectedCategory = "noSelectedCategory",
  GiftCards = "giftCards",
  OrderingAs = "orderingAs",
  ResellerLogoDescription = "resellerLogoDescription",
  ResellerUrlDescription = "resellerUrlDescription",
  WhoIsOrdering = "whoIsOrdering",
  WhatToGiveAway = "whatToGiveAway",
  StepOneDescription = "stepOneDescription",
  GiftcardVHDescription = "giftcardVHDescription",
  GiftcardGMGDescription = "giftcardGMGDescription",
  ChooseGiftcard = "chooseGiftcard",
  PriceWillAddVat = "priceWillAddVat",
  CreateNewOrder = "createNewOrder",
  GoToHomePage = "goToHomePage",
  ConfirmDeleteResellerLogotype = "confirmDeleteResellerLogotype",
  CouldNotGetResellerCode = "couldNotGetResellerCode",
  NoUploadedLogo = "noUploadedLogo",
  LogoUploaded = "logoUploaded",
  SignOut = "signOut",
  InvoiceAddedDeliveryDays = "invoiceAddedDeliveryDays",
  Contact = "contact",
  Invoice = "invoice",
  CompanyContactPhoneNumber = "companyContactPhoneNumber",
  CompanyContactPhoneNumberPlaceholder = "companyContactPhoneNumberPlaceholder",
  InvalidCompanyContactPhoneNumber = "invalidCompanyContactPhoneNumber",
  NextMonth = "nextMonth",
  PreviousMonth = "previousMonth",
  CustomWebPage = "customWebPage",
  OrderNotCompletedTitle = "orderNotCompletedTitle",
  OrderNotCompletedBody = "orderNotCompletedBody",
  IUnderStand = "iUnderStand",
  NewDate = "newDate",
  OneMoment = "oneMoment",
  InvoiceAgreementHeader = "invoiceAgreementHeader",
  InvoiceAgreement1 = "invoiceAgreement1",
  InvoiceAgreement2 = "invoiceAgreement2",
  CustomWebPageHeader = "customWebPageHeader",
  CustomWebPageAgreement1 = "customWebPageAgreement1",
  FromDate = "fromDate",
  ToDate = "toDate",
  SearchString = "searchString",
  Decline = "decline",
  YouAcceptTheFollowing = "youAcceptTheFollowing",
  MarkAsDone = "markAsDone",
  InvoiceEmail = "invoiceEmail",
  InvoiceEmailPlaceholder = "invoiceEmailPlaceholder",
  YourEmail = "yourEmail",
  Name = "name",
  DeliveryDate = "deliveryDate",
  Code = "code",
  ResellerCode = "code",
  ResellerDetails = "resellerDetails",
  Logotype = "logotype",
  IsActive = "isActive",
  LogotypeType = "LogotypeType",
  SearchResellerTitle = "searchResellerTitle",
  SearchResellerPlaceHolder = "searchResellerPlaceHolder",
  SearchResellersBtn = "searchResellersBtn",
  Edit = "edit",
  EditResellerData = "editResellerData",
  EditResellerLogoUrlData = "editResellerLogoUrlData",
  EditResellerContactPageData = "editResellerContactPageData",
  No = "no",
  Yes = "yes",
  ContactEmails = "contactEmails",
  AddReseller = "addReseller",
  PrePaidInvoice = "prePaidInvoice",
  AddResellerContact = "addResellerContact",
  InvalidResellerContactId = "invalidResellerContactId",
  ConfirmDeleteResellerContact = "confirmDeleteResellerContact",
  DeleteResellerContact = "deleteResellerContact",
  ToDoManually = "toDoManually",
  OrderValue = "orderValue",
  RefundOrder = "refundOrder",
  InvalidRefundAmount = "invalidRefundAmount",
  InvalidRefund = "invalidRefund",
  InvalidOrderId = "invalidOrderId",
  RefundAmount = "refundAmount",
  RefundedAmount = "refundedAmount",
  Confirm = "confirm",
  Order = "order",
  SettingsIncomingOrdersTitle = "settingsIncomingOrdersTitle",
  SettingsIncomingOrdersDescription = "settingsIncomingOrdersDescription",
  Settings = "settings",
  ResellerIncomingOrderEmails = "resellerIncomingOrderEmails",
  ResellerSettings = "resellerSettings",
  SimpleAndPersonal = "simpleAndPersonal",
  GiftCardSelectionHomePageTitle = "giftCardSelectionHomePageTitle",
  GiftCardSelectionHomePageDescription = "giftCardSelectionHomePageDescription",
  ChooseProductPriceVH = "chooseProductPriceVH",
  ChooseProductPriceGMG = "chooseProductPriceGMG",
  SenderAndCustomer = "senderAndCustomer",
  StepThreeTitle = "stepThreeTitle",
  StepThreeDescriptionPrivate = "stepThreeDescriptionPrivate",
  StepThreeDescriptionCompany = "stepThreeDescriptionCompany",
  StepFourTitle = "stepFourTitle",
  StepFourDescriptionPrivate = "stepFourDescriptionPrivate",
  StepFourDescriptionCompany = "stepFourDescriptionCompany",
  StepFiveTitle = "stepFiveTitle",
  StepFiveDescriptionPrivate = "stepFiveDescriptionPrivate",
  StepFiveDescriptionCompany = "stepFiveDescriptionCompany",
  StepSixTitle = "stepSixTitle",
  StepSixDescriptionPrivate = "stepSixDescriptionPrivate",
  StepSixDescriptionCompany = "stepSixDescriptionCompany",
  HomepageImage = "homepageImage",
  ResellerHomePageImageDescription = "resellerHomePageImageDescription",
  NoUploadedHomePageImage = "noUploadedHomePageImage",
  AllowedFileFormatResellerHomePageImage = "allowedFileFormatResellerHomePageImage",
  AllowedFileTypesResellerHomePageImage = "allowedFileTypesResellerHomePageImage",
  AllowedFileMaxSizeResellerHomePageImage = "allowedFileMaxSizeResellerHomePageImage",
  ConfirmDeleteResellerHomePageImage = "confirmDeleteResellerHomePageImage",
  CouldNotGetResellerId = "couldNotGetResellerId",
  ResellerHomePageImage = "resellerHomePageImage",
  Upload = "upload",
  InvalidResellerId = "invalidResellerId",
  InvalidLogoFileFormat = "invalidLogoFileFormat",
  InvalidHomePageImageFileFormat = "invalidHomePageImageFileFormat",
  AcceptedInvoiceTitle = "acceptedInvoiceTitle",
  AcceptedInvoiceBody = "acceptedInvoiceBody",
  InvoiceNotAcceptedTitle = "invoiceNotAcceptedTitle",
  InvoiceNotAcceptedBody = "invoiceNotAcceptedBody",
  DeclinedInvoiceTitle = "declinedInvoiceTitle",
  DeclinedInvoiceBody = "declinedInvoiceBody",
  InvoiceNotDeclinedTitle = "invoiceNotDeclinedTitle",
  InvoiceNotDeclinedBody = "invoiceNotDeclinedBody",
  OrderAlreadyHandled = "orderAlreadyHandled",
  Error = "error",
  Expand = "expand",
  Minimize = "minimize",
  Close = "close",
  Branding = "branding",
  CoupledOrders = "coupledOrders",
  Accept = "accept",
  Deny = "deny",
  Template = "template",
  UnhandledOrders = "unhandledOrders",
  CoupleOrder = "coupleOrder",
  Couple = "couple",
  CouldNotCoupleOrder = "couldNotCoupleOrder",
  HandledByReseller = "handledByReseller",
  HandledByAdmin = "handledByAdmin",
  OrderHistory = "orderHistory",
  Contacts = "contacts",
  Text = "text",
  OrderRows = "orderRows",
  ChangedBy = "changedBy",
  Created = "created",
  Description = "description",
  CopyVNumbers = "copyVNumbers",
  InvalidReceiverEmailSendToSameReceiver = "invalidReceiverEmailSendToSameReceiver",
  NoVNumbersToCopy = "noVNumbersToCopy",
  NoteAllExclVat = "noteAllExclVat",
  NoteAllExclVatPurchase = "noteAllExclVatPurchase",
  MarkingInformation = "markingInformation",
  ResellerContactPageInformation = "resellerContactPageInformation",
  AddressLine = "addressLine",
  PostalCode = "postalCode",
  City = "city",
  Country = "country",
  Website = "website",
  ContactPhoneNumbers = "contactPhoneNumbers",
  ContactInformation = "contactInformation",
  CustomMessageTooltipInfo = "customMessageTooltipInfo",
  CustomWebPageTooltipInfo = "customWebPageTooltipInfo",
  ContactYourReseller = "contactYourReseller",
  Email2 = "email2",
  Address = "address",
  PhysicalCard = "physicalCard",
  Exit = "exit",
  CoverPage = "coverPage",
  MiddlePage = "middlePage",
  BackPage = "backPage",
  WebPage = "webPage",
  Summary = "summary",
  SendToSameReceiverTooltip = "sendToSameReceiverTooltip",
  PriceWillAddVatPrivate = "priceWillAddVatPrivate",
  HomePageHeaderText = "homePageHeaderText",
  HomePageBodyText = "homePageBodyText",
  SaveTexts = "saveTexts",
  HomePageTexts = "homePageTexts",
  Texts = "texts",
  OrderBtn = "orderBtn",
  BodyText = "bodyText",
  PhysicalCardCustomMessage = "physicalCardCustomMessage",
  PhysicalCardTitle = "physicalCardTitle",
  Category = "category",
  CustomMessage = "customMessage",
  Heading = "heading",
  Remove = "remove",
  UploadLogotype = "uploadLogotype",
  RecommendedSize = "recommendedSize",
  Header = "header",
  PhysicalCardHeadingSuggestions = "physicalCardHeadingSuggestions",
  PhysicalCardCustomMessageSuggestions = "physicalCardCustomMessageSuggestions",
  Done = "done",
  InvalidImageAspectRatio = "invalidImageAspectRatio",
  All = "all",
  PhysicalCardThankYou = "physicalCardthankyou",
  PhysicalCardChristmas = "physicalCardchristmas",
  UploadYourOwnImage = "uploadYourOwnImage",
  MakeItPersonaly = "makeItPersonaly",
  ChooseCoverDesign = "chooseCoverDesign",
  NoUploadedCoverImage = "noUploadedCoverImage",
  AmountOfCards = "amountOfCards",
  StepFiveTitlePhysicalCard = "stepFiveTitlePhysicalCard",
  StepFiveDescriptionPhysicalCard = "stepFiveDescriptionPhysicalCard",
  InvalidPostalCode = "invalidPostalCode",
  InvalidAddressLine = "invalidAddressLine",
  InvalidCity = "invalidCity",
  InvalidCountry = "invalidCountry",
  ExternalSystems = "externalSystems",
  ExternalSystemTitle = "externalSystemTitle",
  EditExternalSystem = "editExternalSystem",
  AddExternalSystem = "addExternalSystem",
  Token = "Token",
  SendBy = "sendBy",
  TemplateType = "templateType",
  DownloadDocumentation = "downloadDocumentation",
  PDF = "PDF",
  ResendToNav = "resendToNav",
  ResendGiftCardsToPrinter = "resendGiftCardsToPrinter",
  Sweden = "sweden",
  OrderType = "orderType",
  BrandingUrl = "brandingUrl",
  OrderSubviewUrl = "orderSubviewUrl",
  ContactInformationUrl = "contactInformationUrl",
  SettingsUrl = "settingsUrl",
  InvoiceUrl = "invoiceUrl",
  TemplateUrl = "templateUrl",
  ExternalSystemsUrl = "externalSystemsUrl",
  InvalidName = "invalidName",
  InvalidMarking = "invalidMarking",
  MyOrders = "myOrders",
  ResellerRepresent = "resellerRepresent",
  PhysicalCardEaster = "physicalCardeaster",
  ReceiverCountry = "receiverCountry",
  DeliveryCustomerContactName = "deliveryCustomerContactName",
}
