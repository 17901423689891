import { makeStyles } from '@mui/styles';
import { theme } from '../../appTheme';

export const DialogStyles = makeStyles({
  root: {
    margin: 0,
  },
  closeButton: {
    color: theme.palette.grey[500],
  },
});
