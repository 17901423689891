import makeStyles from "@mui/styles/makeStyles";
import { theme } from "../../appTheme";

export const PrivateStyles = makeStyles({
  homePageImg: {
    objectFit: "cover",
    objectPosition: "center",
    height: "100vh",
    width: "100%",
  },
  giftcardContainer: {
    "&::-webkit-scrollbar": {
      display: "none",
    },
    [theme.breakpoints.only("xs")]: {
      height: 230,
      display: "flex",
      flexWrap: "nowrap",
      overflowX: "scroll",
      zoom: 0.8,
    },
    [theme.breakpoints.only("sm")]: {
      zoom: 0.817,
    },
    [theme.breakpoints.only("md")]: {
      zoom: 1.09,
    },
  },
  selectedCard: {
    height: 140,
    width: 225,
    border: "1px solid #000",
    margin: 5,
    color: "#fff",
    backgroundColor: "#000",
    cursor: "pointer",
    zIndex: 1,
    position: "relative",
  },
  notSelectedCard: {
    height: 140,
    width: 225,
    border: "1px solid #000",
    margin: 5,
    color: "#000",
    backgroundColor: "#fff",
    cursor: "pointer",
    zIndex: 1,
    position: "relative",
  },
  imageTitleText: {
    height: "auto",
    bottom: 75,
    zIndex: 2,
    maxWidth: 724,
    position: "absolute",
    [theme.breakpoints.only("xs")]: {
      paddingLeft: 15,
      bottom: 50,
    },
  },
  imageBodyText: {
    textAlign: "left",
  },
  gettingStartedBtn: {
    paddingTop: 21,
    width: 236,
    [theme.breakpoints.only("xs")]: { width: "92vw" },
  },
  gettingStartedArrowBtn: { [theme.breakpoints.only("xs")]: { float: "left" } },
});
