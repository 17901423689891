import makeStyles from "@mui/styles/makeStyles";
import { theme } from "../../appTheme";

export const MenuBarStyles = makeStyles({
  menubarContainer: {
    float: "left",
  },
});

export const SidebarStyles = makeStyles({
  root: {}, //transition: "all 0.4s"
  content: {},
  header: {},
  body: {},
  childElement: {
    width: "100%",
    backgroundColor: "#e3e1de",
    color: "#000",
    height: 50,
    cursor: "pointer",
    "&:hover": { backgroundColor: "#979797" },
    borderBottom: "1px solid rgba(45, 45, 45, 0.2)",
  },
});

export const ResellerSignInStyles = makeStyles({
  titleContainer: { paddingTop: 50 },
  title: { fontSize: "1rem" },
  contactEmail: { paddingTop: 30 },
  validationCode: { paddingTop: 20 },
  submitResellerSignin: { paddingTop: 20 },
  resendValidationCode: {
    "&:hover": {
      textDecoration: "underline",
    },
  },
});

export const ResellerAdminStyles = makeStyles({
  subviewContent: {
    width: "100%",
    paddingLeft: 40,
    paddingTop: 20,
    paddingRight: 40,
    [theme.breakpoints.only("xs")]: { marginTop: 30 },
    [theme.breakpoints.only("sm")]: { marginTop: 30 },
  },
  submitResellerSignin: {
    paddingTop: 20,
  },
  resellerOrderUrlContainer: {
    cursor: "pointer",
    paddingLeft: 20,
    paddingTop: 15,
    paddingBottom: 15,
    backgroundColor: "#bdb9b2", //"rgb(220,220,220, 0.2)",
    border: "2px solid transparent",
    "&:hover": {
      border: "2px solid orange",
    },
  },
  resellerOrderUrl: {
    height: "auto",
    margin: "auto",
    // [theme.breakpoints.only("sm")]: { height: 50 },
  },
  allowedFileFormatInfo: {
    paddingTop: 10,
  },
  fileUploader: {
    float: "left",
  },
  logoContainer: {
    [theme.breakpoints.only("xs")]: { paddingTop: 30 },
  },
  homePageImageContainer: {
    [theme.breakpoints.only("xs")]: { paddingTop: 30 },
  },
});

export const ResellerAdminOrderDetailsStyles = makeStyles({
  orderDetailContainer: { zoom: 0.85 },
  orderDetailOverviewRow: {
    paddingTop: 7,
    display: "block",
    [theme.breakpoints.only("xs")]: { paddingTop: 5, paddingLeft: 10 },
    [theme.breakpoints.only("sm")]: { paddingTop: 5 },
  },
  root: {
    [theme.breakpoints.only("xs")]: { paddingTop: 10 },
    [theme.breakpoints.only("sm")]: { paddingTop: 40 },
  },
});
