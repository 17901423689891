import React, { useEffect, useState } from "react";
import { Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { GetValuesAsArray } from "../../../Utils/extensions";
import { DeliveryStyles } from "../styles";
import { LabelKeys } from "../../../Utils/enums/labelKeys";
import { FieldNames, StateNames } from "../../../Utils/types";
import CSCheckbox from "../../Input/Forms/CSCheckbox";
import CSTextField from "../../Input/Textfield/CSTextField";
import { WarningAmberOutlined } from "@mui/icons-material";
import { SystemPart } from "../../../Utils/enums/enums";
import CSInfo from "../../Helper/CSInfo";
import { IsResellerAuth, IsAuth } from "../../../Utils/authentication";

interface ISendByEmailPDFProps {
  systemPart: SystemPart;
  receiverEmails: string;
  amountOfMailings: number;
  sendToSameReceiver: boolean;
  amountOfMailingsHasError: boolean;
  receiverEmailPDFsHasError: boolean;
  receiverEmailsPDFSendToSameReceiverHasError: boolean;
  onStateChanged: (
    stateName: StateNames.stepperFiveState,
    value: string | string[] | Date | number | boolean,
    fieldName: FieldNames
  ) => void;
}

export default function SendByEmailPDF(props: ISendByEmailPDFProps) {
  const { t: translate } = useTranslation();
  const classes = DeliveryStyles();
  const [tooltipDialogIsOpen, setTooltipDialogIsOpen] = useState(false);
  const [hasMultipleEntries, setHasMultipleEntries] = useState(false);
  const [amountOfReceivers, setAmountOfReceivers] = useState(0);
  const [sendToSameReceiverDialogIsOpen, setSendToSameReceiverDialogIsOpen] = useState(false);
  const isAdmin =
    (IsResellerAuth() &&
      location.pathname.includes(
        `/${translate(LabelKeys.ResellerUrl)}/${translate(LabelKeys.AdminUrl)}/${translate(LabelKeys.OrderUrl)}`
      )) ||
    (IsAuth() && location.pathname.includes(`/${translate(LabelKeys.AdminUrl)}/${translate(LabelKeys.OrderUrl)}`));

  useEffect(() => {
    CheckMultipleEntries(props.receiverEmails);
    let amount = GetAmountOfReceivers(props.receiverEmails);
    setAmountOfReceivers(amount);
  }, [props.receiverEmails]);

  function handleFieldOnChange(stateName: StateNames.stepperFiveState, value: string | Date | any, fieldName: FieldNames) {
    props.onStateChanged(stateName, value, fieldName);
  }

  function CheckMultipleEntries(value: string): void {
    setHasMultipleEntries(false);
    const values: string[] = [];
    const valueAsArray = GetValuesAsArray(value);

    if (valueAsArray?.length > 0)
      valueAsArray?.forEach((el) => {
        if (values?.find((val) => val === el)) {
          setHasMultipleEntries(true);
          return;
        } else {
          values.push(el);
        }
      });
  }

  function GetAmountOfReceivers(value: string): number {
    let amount = GetValuesAsArray(value)?.length;

    if (amount) {
      return amount;
    } else {
      return 0;
    }
  }

  function handleSendToSameReceiverChanged(e: any): void {
    handleFieldOnChange(StateNames.stepperFiveState, e.target.checked, FieldNames.sendToSameReceiver);
    handleFieldOnChange(StateNames.stepperFiveState, "", FieldNames.receiverEmails);
  }

  return (
    <Grid id="sendByEmailPDFComponent" container item xs={12} sm={11} md={11} lg={11} xl={11} className={classes.sendByEmailPDFComponent}>
      <Grid id="sendToSameReceiverContainer" item xs={12} sm={12} md={12} lg={12} xl={12} style={{ display: "flex" }}>
        <CSCheckbox
          id="sendToSameReceiver"
          label={translate(LabelKeys.SentToSameReceiver)}
          checked={props.sendToSameReceiver}
          onChange={(e: any) => handleSendToSameReceiverChanged(e)}
        />
        <div style={{ paddingLeft: 14 }}>
          <CSInfo
            hoverTooltopText={`${translate(LabelKeys.SendToSameReceiverTooltip)}`}
            popupTooltipText={`${translate(LabelKeys.SendToSameReceiverTooltip)}`}
            tooltipDialogIsOpen={sendToSameReceiverDialogIsOpen}
            onSetTooltipDialogIsOpen={(value) => setSendToSameReceiverDialogIsOpen(value)}
          />
        </div>
      </Grid>
      {props.sendToSameReceiver && (
        <Grid id="amountOfMailingsContainer" item xs={12} sm={12} md={12} lg={12} xl={12} style={{ paddingBottom: 20 }}>
          <CSTextField
            id="amountOfMailings"
            type="text"
            required={props.sendToSameReceiver}
            placeholder={`${translate(LabelKeys.AmountOfMailings)}`}
            value={props.amountOfMailings ?? ""}
            onChange={(e: any) => {
              let value = e.target.value;
              value = value.replaceAll(" ", "");
              if (isNaN(value)) {
                handleFieldOnChange(StateNames.stepperFiveState, props.amountOfMailings, FieldNames.amountOfMailings);
                return;
              }
              if (value <= 0 || value?.length <= 0 || value === undefined) {
                handleFieldOnChange(StateNames.stepperFiveState, "", FieldNames.amountOfMailings);
                return;
              } else {
                handleFieldOnChange(StateNames.stepperFiveState, value, FieldNames.amountOfMailings);
                return;
              }
            }}
            error={props.amountOfMailingsHasError}
            helperText={translate(LabelKeys.InvalidAmountOfMailings)}
          />
        </Grid>
      )}
      <Grid container item xs={12} sm={12} md={12} lg={12} xl={12} style={{ paddingTop: 30 }}>
        <div style={{ display: "flex" }}>
          <div>
            <Typography align="left" variant="body2" style={{ fontWeight: "bold", paddingTop: 3 }}>
              {`${translate(LabelKeys.Receiver)}*`}
            </Typography>
          </div>
          <div style={{ paddingLeft: 14 }}>
            <CSInfo
              hoverTooltopText={
                props.systemPart == SystemPart.Private && !isAdmin
                  ? translate(LabelKeys.DeliverReceiverEmailTooltipPrivate)
                  : translate(LabelKeys.DeliverReceiverEmailTooltip)
              }
              popupTooltipText={
                props.systemPart == SystemPart.Private && !isAdmin
                  ? translate(LabelKeys.DeliverReceiverEmailTooltipPrivate)
                  : translate(LabelKeys.DeliverReceiverEmailTooltip)
              }
              tooltipDialogIsOpen={tooltipDialogIsOpen}
              onSetTooltipDialogIsOpen={(value) => setTooltipDialogIsOpen(value)}
            />
          </div>
        </div>
        <Grid id="receiverEmailsContainer" item xs={12} sm={12} md={12} lg={12} xl={12}>
          <CSTextField
            id="receiverEmailsPDF"
            multiline={!props.sendToSameReceiver}
            rows={props.sendToSameReceiver ? 1 : 10}
            type="email"
            value={props.receiverEmails}
            placeholder={`${translate(LabelKeys.ReceiverEmails)}`}
            onChange={(e) => {
              let value = e.target.value.replaceAll(" ", "");
              handleFieldOnChange(StateNames.stepperFiveState, value, FieldNames.receiverEmails);
            }}
            error={props.receiverEmailPDFsHasError || props.receiverEmailsPDFSendToSameReceiverHasError}
            helperText={
              props.receiverEmailPDFsHasError
                ? translate(LabelKeys.InvalidReceiverEmailAddresses)
                : props.receiverEmailsPDFSendToSameReceiverHasError
                ? translate(LabelKeys.InvalidReceiverEmailSendToSameReceiver)
                : translate(LabelKeys.InvalidReceiverEmailAddresses)
            }
          />
        </Grid>
      </Grid>
      {hasMultipleEntries && !props.sendToSameReceiver && (
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ display: "flex", paddingTop: 3 }}>
          <WarningAmberOutlined style={{ color: "#FFA500" }} />
          <Typography
            variant="body2"
            style={{
              paddingLeft: 10,
              paddingTop: 3,
              color: "#FFA500",
              textAlign: "left",
            }}
          >
            {translate(LabelKeys.HasMultipleEntries)}
          </Typography>
        </Grid>
      )}
      {!props.sendToSameReceiver && (
        <Grid id="amountOfReceiversContainer" container item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Typography variant="body2" className={classes.amountOfReceivers} style={{ fontWeight: "bold" }}>
            {translate(LabelKeys.AmountOfReceivers)}
            {": "}
            {amountOfReceivers}
          </Typography>
        </Grid>
      )}
    </Grid>
  );
}
