import i18n from "i18next";
import detector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import swedish from "./Swedish.json";
// import english from "./English.json";

// the translations
const resources = {
  "1": {
    translation: swedish,
  },
  // en: {
  //   translation: english,
  // },
};

i18n
  .use(detector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    debug: false,
    resources,
    supportedLngs: ["1"], // ['se', 'en']
    fallbackLng: "1",
    keySeparator: false, // we do not use keys in form messages.welcome
    detection: {
      order: ["path", "localStorage"],
      lookupLocalStorage: "giftsbyvinga.localization",
      caches: ["localStorage"],
    },
  });

i18n.languages = ["1"]; //['se', 'en']

export default i18n;
