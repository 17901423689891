import { InvoiceActionTypes } from "./actionTypes";
import { Reducer } from "redux";
import { ErrorReason } from "../../Utils/enums/enums";
import { IAcceptInvoiceResponse, IDeclineInvoiceResponse } from "./types";

interface IInvoiceInitState {
  isLoading: boolean;
  acceptedInvoiceDetails: IAcceptInvoiceResponse;
  declinedInvoiceDetails: IDeclineInvoiceResponse;
}

const invoiceInitState: IInvoiceInitState = {
  isLoading: false,
  acceptedInvoiceDetails: { valid: false, error: ErrorReason.OrderAlreadyHandled } as IAcceptInvoiceResponse,
  declinedInvoiceDetails: {} as IDeclineInvoiceResponse,
};

const InvoiceReducer: Reducer<IInvoiceInitState> = (state = invoiceInitState, action) => {
  switch (action.type) {
    case InvoiceActionTypes.CREATE_INVOICE_BASIS_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case InvoiceActionTypes.CREATE_INVOICE_BASIS_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case InvoiceActionTypes.CREATE_INVOICE_BASIS_ERROR:
      return {
        ...state,
        isLoading: false,
      };
    case InvoiceActionTypes.RESET_REDUCER: {
      return (state = invoiceInitState);
    }
    case InvoiceActionTypes.ACCEPT_INVOICE_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case InvoiceActionTypes.ACCEPT_INVOICE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        acceptedInvoiceDetails: action.response,
      };
    case InvoiceActionTypes.ACCEPT_INVOICE_ERROR:
      return {
        ...state,
        isLoading: false,
      };
    case InvoiceActionTypes.DECLINE_INVOICE_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case InvoiceActionTypes.DECLINE_INVOICE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        declinedInvoiceDetails: action.response,
      };
    case InvoiceActionTypes.DECLINE_INVOICE_ERROR:
      return {
        ...state,
        isLoading: false,
      };

    default:
      return state;
  }
};

export default InvoiceReducer;
