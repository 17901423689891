export interface Label {
  key: string;
  value: string;
}

export enum CookieConstants {
  "GBVJwt" = "GBVJWT",
  "GBVRefreshToken" = "GBVREFRESHTOKEN",
  "GBVReseller" = "GBVRESELLER",
  "CookiesConsent" = "GBVCOOKIESCONSENT",
  "SystemPart" = "SYSTEMPART",
}

export const GTMID = "GTM-TPTS8JN";
