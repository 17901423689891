import * as React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { IFilterOption } from "./types";
import { styled } from "@mui/styles";
import Typography from "@mui/material/Typography/Typography";
import { CSMSDropDownAutocompleteStyles } from "./styles";
import { useEffect, useState } from "react";

interface CSDropdownAutocompleteProps {
  key?: string;
  size?: "small" | "medium";
  maxLength?: number;
  options?: IFilterOption[];
  label?: string;
  value: IFilterOption;
  required?: boolean;
  groupHeaderColor?: string;
  defaultValue?: IFilterOption;
  selectedOption?: IFilterOption;
  className?: string;
  onEnterKeyDown?: () => void;
  onFilterOptionClicked: (selectedOptions: IFilterOption) => void;
}

export default function CSDropdownAutocomplete(props: CSDropdownAutocompleteProps) {
  const [selectedItem, setSelectedItem] = useState(props.value);
  const classes = CSMSDropDownAutocompleteStyles();

  const GroupHeader = styled("div")(() => ({
    position: "sticky",
    top: "-8px",
    padding: "4px 10px",
    color: "#000",
    backgroundColor: props.groupHeaderColor ?? "lightcyan",
  }));

  const GroupItems = styled("ul")({
    padding: 0,
  });

  useEffect(() => {
    if (selectedItem == null) setSelectedItem({ id: undefined, name: undefined, value: undefined });
    props.onFilterOptionClicked(selectedItem);
  }, [selectedItem]);

  return (
    <div style={{ marginTop: 12 }}>
      <Typography variant="body2" style={{ textAlign: "left", paddingBottom: 14, fontWeight: "bold" }}>
        {props.label ? (props.required ? `${props.label}*` : `${props.label}`) : ""}
      </Typography>
      <Autocomplete
        key={props.key && props.key}
        className={classes.root}
        multiple={false}
        size={props.size ?? "small"}
        value={selectedItem}
        clearOnEscape
        isOptionEqualToValue={(option, value) => option?.name == value?.name}
        options={
          props.options && props.options.filter((x) => x.id != -1)?.sort((a, b) => -b?.name?.charAt(0)?.localeCompare(a?.name?.charAt(0)))
        }
        groupBy={(option: any) => option?.name?.charAt(0)}
        getOptionLabel={(option: any) => option?.name ?? ""}
        sx={{ width: "100%" }}
        onChange={(_, value) => setSelectedItem(value as any as IFilterOption)}
        renderInput={(params) => <TextField {...params} style={{ backgroundColor: "#fff" }} />}
        renderGroup={(params) => (
          <li key={params.key}>
            <GroupHeader>{params.group}</GroupHeader>
            <GroupItems>{params.children}</GroupItems>
          </li>
        )}
      />
    </div>
  );
}
